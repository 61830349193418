import * as common from 'domains/admin/tableCols/TableColsCommon'
import * as count from 'domains/admin/tableCols/TableColsCount'
import * as currency from 'domains/admin/tableCols/TableColsCurrency'
import * as date from 'domains/admin/tableCols/TableColsDate'
import * as number from 'domains/admin/tableCols/TableColsNumber'
import * as domains from 'domains/admin/tableCols/domain'

const tableCols = {
  common,
  count,
  currency,
  date,
  number,
  ...domains,
}

export default tableCols
