import { IModelBrand } from 'containers/models/modelBrand'
import { IModelCategory } from 'containers/models/modelCategory'
import { IModelCoupon } from 'containers/models/modelCoupon'
import { IModelCouponGroup } from 'containers/models/modelCouponGroup'
import { IModelCustomerGrade } from 'containers/models/modelCustomerGrade'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { IModelProduct } from 'containers/models/modelProduct'
import { IModelSeller } from 'containers/models/modelSeller'
import { IModelStatus } from 'containers/models/modelStatus'
import { IModelUser } from 'containers/models/modelUser'

export type componentOptionProps = {
  descriptionComponentKey?: string
  tableComponentKey?: string
  extraCols?: string[]
  Component?: JSX.Element
}

type targetModelProps = {
  users?: IModelUser[]
  status?: IModelStatus
  user?: IModelUser
  seller?: IModelSeller
  product?: IModelProduct
  category?: IModelCategory
  brand?: IModelBrand
  orderItems?: IModelOrderItem[]
  orderItem?: IModelOrderItem
  couponGroup?: IModelCouponGroup
  customerGrade?: IModelCustomerGrade
  coupon?: IModelCoupon
}

type subComponentProps = {
  componentOption?: componentOptionProps
  targetModel?: targetModelProps
}

const DescriptionComponent = (key: string, targetModel?: targetModelProps) => {
  if (!key || !targetModel) return undefined
}

const TableComponent = (key: string, targetModel?: targetModelProps, _?: string[]) => {
  if (!key || !targetModel) return undefined
}

const SubComponent = ({ componentOption, targetModel }: subComponentProps) => {
  if (!componentOption) return undefined
  const { descriptionComponentKey, tableComponentKey, Component, extraCols = [] } = componentOption
  if (descriptionComponentKey) {
    return DescriptionComponent(descriptionComponentKey, targetModel)
  }
  if (tableComponentKey) {
    return TableComponent(tableComponentKey, targetModel, extraCols)
  }
  if (Component) {
    return Component
  }
  return undefined
}

export default SubComponent
