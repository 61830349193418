import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const DISPLAY_COLLECTION_QUERY_GQLS = {
  DISPLAY_COLLECTIONS: gql`
    query displayCollections($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      displayCollections(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            displayOrder
            startedAt
            endedAt
            petType
            id
            createdAt
            updatedAt
            name
            collectionType
            isShown
            displayProductInfos {
              totalCount
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  DISPLAY_COLLECTIONS_FOR_LIST: gql`
    query displayCollectionsForList($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      displayCollections(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            displayOrder
            startedAt
            endedAt
            petType
            id
            createdAt
            updatedAt
            name
            collectionType
            isShown
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  DISPLAY_COLLECTIONS_WITH_DISPLAY_PRODUCT_INFOS: gql`
    query displayCollectionsWithDisplayProductInfos(
      $pageInfo: PageInfoInputType
      $filter: GenericScalar
      $orderBy: String
    ) {
      displayCollections(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            displayOrder
            startedAt
            endedAt
            petType
            id
            createdAt
            updatedAt
            name
            collectionType
            isShown
            displayProductInfos {
              totalCount
              edges {
                node {
                  displayOrder
                  startedAt
                  endedAt
                  id
                  product {
                    id
                    name
                    realName
                    mainImage {
                      id
                      thumbnail
                    }
                    brand {
                      id
                      name
                    }
                    status {
                      id
                      value
                      text
                    }
                    defaultProductOption {
                      id
                      price
                      discountPrice
                      customerPrice
                      purchasePrice
                    }
                  }
                  productpromotion {
                    startedAt
                    endedAt
                    createdAt
                    updatedAt
                    id
                    productPromotionType
                    name
                    description
                    discountPrice
                    fitpetFeeRate
                    mileageRewardRate
                    isDelete
                    minOrderAmount
                    maxOrderCount
                    productPromotionCustomerPrice
                    discountRate
                  }
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  DISPLAY_COLLECTION: gql`
    query displayCollection($id: ID!) {
      displayCollection(id: $id) {
        displayOrder
        startedAt
        endedAt
        petType
        id
        createdAt
        updatedAt
        name
        collectionType
        isShown
        description
        backgroundImage
        tabName
        displayProductInfos {
          pageInfo {
            ...pageInfo
          }
          totalCount
          edges {
            cursor
            node {
              displayOrder
              startedAt
              endedAt
              id
              product {
                id
                name
                realName
                mainImage {
                  id
                  thumbnail
                }
                brand {
                  id
                  name
                }
                status {
                  id
                  value
                  text
                }
                defaultProductOption {
                  id
                  price
                  discountPrice
                  customerPrice
                  purchasePrice
                }
              }
              productpromotion {
                startedAt
                endedAt
                createdAt
                updatedAt
                id
                productPromotionType
                name
                description
                discountPrice
                fitpetFeeRate
                mileageRewardRate
                isDelete
                minOrderAmount
                maxOrderCount
                productPromotionCustomerPrice
                discountRate
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default DISPLAY_COLLECTION_QUERY_GQLS
