import { ModelProductOptionSkuDefault } from 'containers/models/base/defaultModels'
import { IModelProductOptionSkuDefault } from 'containers/models/base/defaultModelInterfaces'
import { IModelProductOption } from 'containers/models/modelProductOption'
import { IModelSku } from 'containers/models/modelSku'

export interface IModelProductOptionSku extends IModelProductOptionSkuDefault {
  productOption: IModelProductOption
  sku: IModelSku
}

export default class ModelProductOptionSku extends ModelProductOptionSkuDefault {
  constructor(data: IModelProductOptionSku) {
    super(data)
  }
}
