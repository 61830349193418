import ROUTES from '@constants/constRoutes'
import { MLinkRouterButton, MTextButton, Text16Normal } from 'components/@atomics'
import { IModelSku } from 'containers/models/modelSku'
import useModal, { TypeUseModalProps } from 'containers/hooks/useModal'
import { ModalUpdateSkuInfo } from 'domains/common/modals'
import { message } from 'antd'
import React from 'react'
import { Moment } from 'moment'
import { MModal, MQuery } from 'components'
import { date2string, DATETIME_SECOND_FORMAT } from '@libs/utils/utilDate'
import { listVariablesProps, queryParamsProps } from 'components/MQuery'
import useTable from 'containers/hooks/useMTable'
import { TablePaginationConfig } from 'antd/es/table'
import MTable from 'components/@atomics/MTable'
import tableCols from 'domains/admin/tableCols'

const products = () => {
  return {
    title: '사용 중 상품',
    width: 100,
    render: (record: IModelSku) => {
      return (
        <MLinkRouterButton
          openNewWindow
          href={{
            pathname: ROUTES.ETC.SKU_STOCK_DETAIL,
            query: { id: record._id },
          }}
        >
          [보기]
        </MLinkRouterButton>
      )
    },
  }
}

const skuUpdate = (refetchRef: React.MutableRefObject<Function | null>) => {
  return {
    title: 'SKU 수정',
    width: 100,
    render: (record: IModelSku) => {
      const { id, code, name, stockQuantity } = record
      const { useModalProps } = useModal()
      const handleClick = () => {
        useModalProps.showModal()
        useModalProps.setData({ id, code, name, stockQuantity })
      }
      const onSuccess = () => {
        message.success('정보가 수정되었습니다.')
        if (refetchRef && refetchRef.current) {
          refetchRef.current()
          useModalProps.hideModal()
        }
      }
      return (
        <>
          <MTextButton onClick={handleClick}>[수정]</MTextButton>
          <ModalUpdateSkuInfo useModalProps={useModalProps} onAPISuccess={onSuccess} />
        </>
      )
    },
  }
}

const history = () => {
  type ModalContentProps = {
    id: string
    createdAt: Moment
    useModalProps: TypeUseModalProps<any>
  }
  type ModalSkuHistoryProps = ModalContentProps & {
    useModalProps: TypeUseModalProps<any>
  }
  const ModalSkuHistory = ({ id, createdAt, useModalProps }: ModalSkuHistoryProps) => {
    const pagination: TablePaginationConfig = { position: ['bottomCenter'] }
    const queryParams: queryParamsProps = {
      gqlKey: 'skuHistory',
      dataKey: 'skuHistory',
      variables: {
        filter: {
          sku: id,
        },
        orderBy: '-createdAt',
      },
    }
    const { getTablePagination, setInitVariablesAndReturn, setRefetch } = useTable()
    queryParams.variables = setInitVariablesAndReturn(queryParams.variables as listVariablesProps)

    const Content = () => {
      const getColumns = () => {
        return [
          tableCols.date.dateText({
            title: '최종 수정 일시',
            key: 'createdAt',
            dataIndex: 'createdAt',
            dateFormat: DATETIME_SECOND_FORMAT,
          }),
          tableCols.common.baseText({ title: '작업자', dataIndex: ['fitpetAdmin', 'email'], width: 180 }),
          tableCols.common.baseText({ title: '수정전', dataIndex: 'beforeChanges', width: 250 }),
          tableCols.common.baseText({ title: '수정후', dataIndex: 'afterChanges', width: 250 }),
        ]
      }
      return (
        <>
          <Text16Normal>{`등록일시: ${date2string(createdAt, true)}`}</Text16Normal>
          <MQuery queryParams={{ ...queryParams }} fetchPolicy="no-cache">
            {({ data, refetch }: any) => {
              if (!data) {
                return null
              }
              const { dataKey, gqlKey } = queryParams
              const _dataKey = dataKey || gqlKey
              setRefetch(refetch)
              const { totalCount, data: _data } = data[_dataKey]
              const tablePagination = getTablePagination(pagination, totalCount)
              return <MTable columns={getColumns()} dataSource={_data} pagination={tablePagination} />
            }}
          </MQuery>
        </>
      )
    }
    return (
      <MModal
        title="SKU 변경 히스토리"
        width={1200}
        footerType="MODAL_FOOTER_OK"
        useModalProps={useModalProps}
        ComponentContent={<Content />}
      />
    )
  }
  return {
    title: '변경 이력',
    width: 100,
    render: (record: IModelSku) => {
      const { id, createdAt } = record
      const { useModalProps } = useModal()
      const handleShowClick = () => {
        useModalProps.showModal()
      }
      return (
        <>
          <MTextButton onClick={handleShowClick}>[보기]</MTextButton>
          <ModalSkuHistory id={id} createdAt={createdAt} useModalProps={useModalProps} />
        </>
      )
    },
  }
}

export {
  products, // 사용 중 상품
  skuUpdate, // sku 정보 수정
  history, // sku history
}
