import { USER_MODE } from '@constants/constDomain'
import useUserInfo from 'containers/hooks/useUserInfo'
import { IModelSeller } from 'containers/models/modelSeller'
import { ModalConfirm } from 'domains/common/modals'
import React, { MutableRefObject, useState } from 'react'
import Link from 'next/link'

import * as utilData from '@libs/utils/utilData'
import ROUTES from '@constants/constRoutes'
import { MConfirmModal } from 'components/modals'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { IModelUser } from 'containers/models/modelUser'
import { IModelOrder } from 'containers/models/modelOrder'
import { Text14Normal, MLinkRouterButton, MTextButton, Text14Blue } from 'components/@atomics'
import { ITableColumn, ITableColumnFixedProps } from 'components/@atomics/MTable'
import useModal from 'containers/hooks/useModal'
import MImageIconBase from 'components/@atomics/icons/MImageIconBase'
import { IModelSocialServiceDefault } from 'containers/models/base/defaultModelInterfaces'
import { getSocialIconByUserSocialType } from '@libs/utils/utilCommon'

const createOrDeleteManager = (
  sellerData: IModelSeller,
  refetchRef?: MutableRefObject<Function | null>
): ITableColumn<IModelUser> => {
  return {
    title: '담당자 등록/삭제',
    key: 'createOrDeleteManager',
    width: 80,
    render: (record: IModelUser) => {
      const [isSelectedUser, setIsSelectedUser] = useState<boolean | undefined>(undefined)
      const { useModalProps } = useModal()
      const { users } = sellerData
      if (isSelectedUser === undefined && users) {
        const userIds = users.map((x) => x.id)
        if (userIds.includes(record.id)) {
          setIsSelectedUser(true)
        }
      }
      const buttonText = isSelectedUser ? '[제거]' : '[등록]'
      const gqlKey = isSelectedUser ? 'deleteSellerManager' : 'createSellerManager'
      const variableKey = isSelectedUser ? 'deleteSellerManager' : 'createSellerManager'
      const text = isSelectedUser ? '제거' : '등록'
      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.setData({ user: record, seller: sellerData })
              useModalProps.toggleModal()
            }}
          >
            {buttonText}
          </MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: { gqlKey, variableKey },
              title: `담당자 ${text}`,
              description: `해당 담당자 ${record.name}을/를 셀러 정보에서 ${text}합니다.`,
              confirmMessage: `담당자가 ${text}되었습니다.`,
            }}
            onAPISuccess={() => {
              setIsSelectedUser(!isSelectedUser)
              if (refetchRef && refetchRef.current) refetchRef.current()
            }}
          />
        </>
      )
    },
  }
}

const customerId = (): ITableColumn<any> => {
  return {
    title: '구매자ID',
    key: 'userId',
    width: 100,
    render: (record: IModelOrderItem) => <Text14Normal>{record.order.user?._id}</Text14Normal>,
  }
}

const customerName = (props = {}): ITableColumn<any> => {
  const { dataIndex, fixed = 'left' } = props as ITableColumn<any>
  return {
    title: '구매자명',
    key: 'customerName',
    dataIndex,
    width: 120,
    fixed,
    render: (v: string | IModelOrderItem | IModelOrder, record: IModelUser | any) => {
      let user
      if (dataIndex === 'orderItem') {
        user = (v as IModelOrderItem).order.user
      } else if (dataIndex === 'order') {
        user = (v as IModelOrder).user
      } else {
        user = record
      }
      // return <Text14Normal>{utilData.tableText(user?.name)}</Text14Normal>
      // TODO: change to username -> user
      return <Text14Normal>{utilData.tableText(user?.username)}</Text14Normal>
    },
  }
}

const username = ({
  title = '이메일ID',
  dataIndex = 'username',
  hasLink = false,
  openNewWindow = false,
} = {}): ITableColumn<any> => {
  return {
    title,
    key: 'username',
    dataIndex,
    width: 200,
    render: (v: string | IModelUser, record: IModelUser | any) => {
      let user: IModelUser
      if (dataIndex === 'user') {
        user = v as IModelUser
      } else {
        user = record
      }

      if (hasLink) {
        return (
          <>
            <MLinkRouterButton openNewWindow={openNewWindow} href={`${ROUTES.ACCOUNTS.BASE}/${user?._id}`}>
              <a>[{utilData.tableText(user?.username)}]</a>
            </MLinkRouterButton>
          </>
        )
      }
      return (
        <>
          <Text14Normal>{utilData.tableText(user?.username)}</Text14Normal>
          {user?.userSocialServices?.length > 0 && <br />}
          {user?.userSocialServices?.map((socialService: IModelSocialServiceDefault) => {
            return (
              <MImageIconBase
                // @ts-ignore
                icon={getSocialIconByUserSocialType(socialService.socialType)}
                size={16}
                style={{ display: 'inline' }}
              />
            )
          })}
        </>
      )
    },
  }
}

const email = ({
  title = '이메일',
  dataIndex = 'email',
  hasLink = false,
  openNewWindow = false,
} = {}): ITableColumn<any> => {
  return {
    title,
    key: 'email',
    dataIndex,
    width: 200,
    render: (v: string | IModelUser, record: IModelUser | any) => {
      let user
      if (dataIndex === 'user') {
        user = v as IModelUser
      } else {
        user = record
      }

      if (hasLink) {
        return (
          <MLinkRouterButton openNewWindow={openNewWindow} href={`${ROUTES.PARTNERS.PARTNER_LIST}/${user?._id}`}>
            <Text14Blue>[{utilData.tableText(user?.email)}]</Text14Blue>
          </MLinkRouterButton>
        )
      }
      return (
        <>
          <Text14Normal>{utilData.tableText(user?.email)}</Text14Normal>
        </>
      )
    },
  }
}

const isStaff = (): ITableColumn<any> => {
  return {
    title: '관리자 유무',
    key: 'isStaff',
    width: 100,
    render: (record: IModelUser) => <Text14Normal>{record.isStaff ? '관리자' : 'X'}</Text14Normal>,
  }
}

const leftUserStatus = (): ITableColumn<IModelUser> => {
  return {
    title: '구분',
    dataIndex: 'userStatus',
    width: 100,
    render: (v: string) => {
      const leftStatus: '회원 탈퇴' | '관리자 삭제' = v === 'LEFT' ? '회원 탈퇴' : '관리자 삭제'
      return <Text14Normal>{leftStatus}</Text14Normal>
    },
  }
}

const managerKindText = (sellerData: IModelSeller): ITableColumn<IModelUser> => {
  return {
    title: '매니저 구분',
    key: 'managerKindText',
    width: 120,
    render: (record: IModelUser) => {
      return <Text14Normal>{record.managerKindText(sellerData)}</Text14Normal>
    },
  }
}

// const message = (props = {}): ITableColumn<any> => {
//   const { title = '알림 및 메시지' } = props as ITableColumn<any>
//   return ({
//     title,
//     width: 140,
//     render: (record: IModelUser | IModelProduct) => {
//       // messageReceiverId : 나와 대화하는 상대방의 id (admin: 필요없음, seller: id, influencer: id, user: id)
//       // messageReceiverType : 나와 대화하는 상대방의 type (admin: ADMIN, seller: SELLER, influencer: INFLUENCER, customer: CUSTOMER)
//       // messageReceiverName : 나와 대화하는 상대방의 이름 (admin: 관리자, seller: companyName, influencer: nickname, user: name)
//       let receiverId: string | null = record.id; let receiverName: string; let receiverType: string
//       if (record.__typename === 'UserType') {
//         const user = (record as IModelUser)
//         receiverId = !user.isStaff ? user.id : null
//         receiverName = !user.isStaff ? user.name : '관리자'
//         receiverType = !user.isStaff ? 'CUSTOMER' : 'ADMIN'
//         if (!user.hasRecentMessage) return <Text14Normal>-</Text14Normal>
//       }
//       if (record.__typename === 'ProductType') {
//         receiverName = (record as IModelProduct).seller.companyName
//         receiverType = 'SELLER'
//       }
//       return (
//         <MLinkRouterButton
//           openNewWindow
//           sider={false}
//           href={{
//             pathname: ROUTES.ACCOUNTS.MESSAGE,
//             query: {
//               messageReceiverId: receiverId,
//               messageReceiverType: receiverType!,
//               messageReceiverName: receiverName!,
//             },
//           }}
//         >
//           [메시지]
//         </MLinkRouterButton>
//       )
//     },
//   })
// }

const mobileNumber = ({ dataIndex = 'user' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '연락처',
    dataIndex,
    width: 250,
    render: (value: IModelUser) => {
      return <Text14Normal>{value.mobileNumber}</Text14Normal>
    },
  }
}

const orderCount = () => {
  return {
    title: '주문 건 수',
    key: 'orderCount',
    width: 110,
    render: (record: IModelUser) => {
      return (
        <Link
          href={{
            query: { user: record.id },
            pathname: ROUTES.ORDERS.ORDER_LIST_ALL,
          }}
        >
          <a>
            <Text14Blue>[{utilData.caseCountText(record.orderCount)}]</Text14Blue>
          </a>
        </Link>
      )
    },
  }
}

const setChiefManager = (
  setChiefManagerData: Function,
  // parentUseModalProps: TypeUseModalProps<any>,
  chiefManagerData?: IModelUser
): ITableColumn<IModelUser> => {
  return {
    title: '통합 매니저 등록',
    key: 'setChiefManager',
    width: 80,
    render: (record: IModelUser) => {
      const { useModalProps } = useModal()
      let isSelectedUser = false
      if (chiefManagerData) {
        isSelectedUser = chiefManagerData.id === record.id
      }
      const buttonText = isSelectedUser ? '[등록됨]' : '[등록]'
      const text = isSelectedUser ? '등록 취소' : '등록'
      const description = isSelectedUser ? '통합 매니저 등록을 취소' : '통합 매니저로 등록'

      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.setData({ user: record })
              useModalProps.toggleModal()
            }}
          >
            {buttonText}
          </MTextButton>
          <MConfirmModal
            useModalProps={useModalProps}
            fixedProps={{
              title: `셀러 통합 매니저 ${text}`,
              description: `${record.name}님을 ${description}하시겠습니까?`,
            }}
            onAction={() => {
              if (setChiefManagerData) {
                setChiefManagerData(record)
              }
            }}
          />
        </>
      )
    },
  }
}

const userStatus = (): ITableColumn<IModelUser> => {
  return {
    title: '상태',
    dataIndex: 'userStatusText',
    key: 'userStatus',
    width: 100,
    render: (v: string) => <Text14Normal>{v}</Text14Normal>,
  }
}

const writer = ({ dataIndex = 'user' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '작성자',
    dataIndex,
    width: 250,
    render: (value: IModelUser) => {
      const userMode = useUserInfo.useUserMode()
      let writerText = ''

      if (userMode === USER_MODE.SELLER) {
        const writerName = utilData.maskingText(value.name, 1)
        const writerEmail = utilData.maskingText(value.email, 3)
        writerText = `${utilData.tableText(writerName)} (${utilData.tableText(writerEmail)})`
      } else {
        writerText = `${utilData.tableText(value.name)} (${utilData.tableText(value.email)})`
      }

      return <Text14Normal>{writerText}</Text14Normal>
    },
  }
}

export {
  createOrDeleteManager, // 매니저 등록 또는 제거
  customerId, // 구매자 Id
  customerName, // 구매자명
  username, // 로그인 Id
  email, // 이메일
  isStaff, // 관리자 유무
  leftUserStatus, // 탈퇴 유저 상태
  managerKindText,
  // message, // 알람 및 메시지
  mobileNumber, // 연락처
  orderCount,
  setChiefManager, // 통합 매니저  선택
  userStatus, // 유저 상태
  writer, // 작성자
}
