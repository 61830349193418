import { ModelProductQnaDefault } from 'containers/models/base/defaultModels'
import { IModelProductQnaDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelProductQna extends IModelProductQnaDefault {}

export default class ModelProductQna extends ModelProductQnaDefault {
  constructor(data: IModelProductQna) {
    super(data)
  }
}
