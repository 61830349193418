import ReactDOM from 'react-dom'
import { Modal, Popover } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { FormInstance } from 'antd/es/form'
import styled from 'styled-components'

import { MButton, Text14Orange } from 'components/@atomics'
import { MButtonProps } from 'components/@atomics/buttons/MButton'
import { TypeUseModalProps } from 'containers/hooks/useModal'

export type footerTypeProps = 'MODAL_FOOTER_DEFAULT' | 'MODAL_FOOTER_OK' | 'MODAL_FOOTER_CANCEL'

export type MModalProps<T> = ModalProps & {
  title?: string
  useModalProps: TypeUseModalProps<T>
  ComponentContent: JSX.Element
  onAction?: Function
  onCancelAction?: Function
  footerType?: footerTypeProps
  disableContent?: string
  modalDisable?: boolean
  width?: number
  theme?: 'blackAndWhite'
  form?: FormInstance
  loading?: boolean
  hideModalAfterOnAction?: boolean
}

// footerType
// MODAL_FOOTER_DEFAULT : ok, cancel button shows
// MODAL_FOOTER_OK : ok button shows
// MODAL_FOOTER_CANCEL : cancel button shows

const MModal = ({
  title,
  okText,
  cancelText,
  useModalProps,
  ComponentContent,
  onAction,
  onCancelAction,
  footerType,
  width,
  closable,
  disableContent,
  modalDisable,
  theme,
  form,
  loading,
  hideModalAfterOnAction = true,
}: MModalProps<any>) => {
  const { isShowing, hideModal, maskClosable = false } = useModalProps
  const okBtnProps: MButtonProps = {
    loading,
    disabled: modalDisable,
    type: 'primary',
    style: { marginLeft: footerType === 'MODAL_FOOTER_OK' ? 0 : 6 },
  }

  const handleOk = async () => {
    // console.log('handle ok!')
    if (form) {
      const res = await form.validateFields()
      if (res) {
        await form.submit()
        return
      }
    } else if (onAction) {
      // const result = await onAction()
      // if (result === false) return
      await onAction()
      if (hideModalAfterOnAction) {
        hideModal()
      }
      return
    }
    hideModal()
  }

  const handleCancel = () => {
    if (form) {
      form.resetFields()
    } else if (onCancelAction) {
      onCancelAction()
    }
    hideModal()
  }

  const baseModelFooterOkButton = (
    <StyledPopover
      key="disablePopover"
      content={<Text14Orange>{disableContent}</Text14Orange>}
      visible={modalDisable}
      placement="topRight"
    >
      <MButton theme={theme === 'blackAndWhite' ? 'black' : theme} onClick={() => handleOk()} {...okBtnProps}>
        {okText ?? '확인'}
      </MButton>
    </StyledPopover>
  )

  const baseModelFooterCancelButton = (
    <MButton key="back" theme={theme === 'blackAndWhite' ? 'white' : theme} onClick={() => handleCancel()}>
      {cancelText ?? '취소'}
    </MButton>
  )

  let footer: [JSX.Element, JSX.Element] | JSX.Element = [baseModelFooterCancelButton, baseModelFooterOkButton]
  if (footerType === 'MODAL_FOOTER_OK') footer = baseModelFooterOkButton
  else if (footerType === 'MODAL_FOOTER_CANCEL') footer = baseModelFooterCancelButton

  return isShowing
    ? ReactDOM.createPortal(
        <Modal
          title={title}
          width={width || 700}
          visible={isShowing}
          onOk={() => handleOk()}
          onCancel={() => handleCancel()}
          footer={footer}
          closable={closable || false}
          destroyOnClose
          maskClosable={maskClosable}
        >
          {ComponentContent}
        </Modal>,
        document.body
      )
    : null
}

const StyledPopover = styled(Popover)`
  margin-left: ${(props) => props.visible && '8px'};
`

export default MModal
