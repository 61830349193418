import { FC } from 'react'
import { Text12Normal, Text12Blue, Text14Normal, Text14Blue, Text16Normal, Text16Blue } from 'components/@atomics'
import * as utilData from '@libs/utils/utilData'

type MTotalCountTextProps = {
  totalCount: number
  title: string
  unit: '개' | '건' | '명'
  size?: 12 | 14 | 16
}

const MTotalCountText: FC<MTotalCountTextProps> = ({ totalCount, title, unit, size }) => {
  const Count: string | 0 = utilData.numberWithCommas(totalCount ?? 0)
  if (size === 12)
    return (
      <Text12Normal>
        {title} (총 <Text12Blue>{Count}</Text12Blue>
        {unit})
      </Text12Normal>
    )
  if (size === 14)
    return (
      <Text14Normal>
        {title} (총 <Text14Blue>{Count}</Text14Blue>
        {unit})
      </Text14Normal>
    )
  return (
    <Text16Normal>
      {title} (총 <Text16Blue>{Count}</Text16Blue>
      {unit})
    </Text16Normal>
  )
}

export { MTotalCountText as default }
