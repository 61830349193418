import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const BANNER_QUERY_GQLS = {
  BANNERS_WITH_ALL_BANNER_INFO: gql`
    query bannersWithAllBannerInfo($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      banners(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            displayOrder
            petType
            createdAt
            updatedAt
            allowDisplay
            id
            startedAt
            endedAt
            name
            bannerType
            displayDevice
            bannerTypeInfo
            style
            slimBannerInfo {
              mobileImage {
                ...bannerImage
              }
            }
            topRollBannerInfo {
              mobileImage {
                ...bannerImage
              }
            }
            homeMiddleBannerInfo {
              mobileImage {
                ...bannerImage
              }
            }
            homeBottomBannerInfo {
              mobileImage {
                ...bannerImage
              }
            }
            productTopBannerInfo {
              mobileImage {
                ...bannerImage
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.bannerImage}
  `,
  BANNERS: gql`
    query banners($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      banners(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            ...banner
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.banner}
  `,
  BANNER: gql`
    query banner($id: ID!) {
      banner(id: $id) {
        ...banner
        topRollBannerInfo {
          ...topRollBannerInfo
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
  `,
}

export default BANNER_QUERY_GQLS
