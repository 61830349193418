import { ReactNode } from 'react'
import { Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'

export type MFormItemWrapperProps = FormItemProps & {
  children?: ReactNode
  disabled?: boolean
}

const MFormItemWrapper = (props: MFormItemWrapperProps) => {
  const { children, ...formItemProps } = props
  const { label, rules = [], required } = formItemProps

  if (
    required !== undefined &&
    typeof label !== 'object' &&
    rules.filter((rule: any) => rule.required === true).length === 0
  ) {
    rules.push({ required, message: `${label || '이 항목'}을/를 입력해 주세요!` })
  }

  return (
    <Form.Item labelAlign="left" colon={false} rules={rules} {...formItemProps}>
      {children}
    </Form.Item>
  )
}

export default MFormItemWrapper
