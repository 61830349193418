import { IModelUserCertificationDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelUserCertificationDefault } from 'containers/models/base/defaultModels'

export interface IModelUserCertification extends IModelUserCertificationDefault {}

export default class ModelUserCertification extends ModelUserCertificationDefault {
  constructor(data: IModelUserCertification) {
    super(data)
  }
}
