type MErrorProps = {
  error: string
}

function MError(pros: MErrorProps) {
  const { error } = pros
  return <>{error}</>
}

export default MError
