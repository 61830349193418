import { gql } from '@apollo/client'

const COMMON_QUERY_GQLS = {
  INIT_DATA: gql`
    query initData {
      initData {
        banks {
          code
          name
        }
        shippingCompanies {
          id
          name
          sweetTrackerCode
        }
        customerGrades {
          id
          name
        }
      }
    }
  `,
}

export default COMMON_QUERY_GQLS
