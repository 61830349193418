import { Typography } from 'antd'
import * as utilData from '@libs/utils/utilData'
import { MFormItemWrapper } from 'components/formItems'
import { Text14Blue } from 'components/@atomics'

// const labelFieldStyle = { labelCol: { span: 4 }, wrapperCol: { span: 12 } }
const labelFieldStyle = { labelCol: { span: 4 } }

const MLabeledFileField = ({ label, initUrls, ...props }: { label?: string; initUrls: string[] }) => {
  const values = initUrls.map((x) => {
    if (x) {
      return {
        name: utilData.filenameFromUrl(x),
        url: utilData.getFullUrl(x),
        download: true,
      }
    }
    return { name: '', url: '#', download: false }
  })
  return (
    <MFormItemWrapper {...props} {...labelFieldStyle} label={label}>
      {values &&
        values.map((x) => {
          if (x.download) {
            return (
              <Typography.Link key={x.name} href={x.url} download>
                {x.name}
              </Typography.Link>
            )
          }
          return <Text14Blue key={x.name}>{x.name}</Text14Blue>
        })}
    </MFormItemWrapper>
  )
}

export default MLabeledFileField
