import { Space, Form, message } from 'antd'
import styled from 'styled-components'

import { useState } from 'react'
import { MModal, MQuery } from 'components'
import { SellerTableProcessOrder } from 'domains/seller/tables'
import { DomainModalProps } from 'containers/hooks/useModal'
import { Text14Normal, Text14Red } from 'components/@atomics'
import { Store } from 'rc-field-form/lib/interface'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import MMutation from 'components/MMutation'
import { MutationFunction } from '@apollo/client'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { hasData } from '@libs/utils/utilData'
import { ORDER_STATUS_TYPE } from '@constants/constDomain'
import { queryParamsProps } from 'components/MQuery'

type ACTION_TYPE = 'startShippings' | 'updateShippings'

type ModalShippingOrderProps = {
  orderNumber?: string
  orderCount?: number
  reShipment?: boolean
  orderItems?: IModelOrderItem[]
  actionType: ACTION_TYPE
}

const settings = {
  startShippings: {
    gqlKey: 'startShippings',
    title: '송장입력',
    description: (
      <Text14Normal>
        {'아래 주문건의 송장번호를 입력후 완료하시면 배송중 상태로 변경합니다.\n' +
          '택배사는 해당 상품의 배송 세트에 지정된 택배사로 지정됩니다.\n'}
        주문상태가 <Text14Red>배송준비가 아닌 항목</Text14Red>이 있을 경우 송장번호 입력을 할 수 없습니다.
      </Text14Normal>
    ),
    variableFormat: MUTATION_VARIABLES.START_SHIPPINGS,
  },
  updateShippings: {
    gqlKey: 'updateShippings',
    title: '송장번호 수정',
    description: '아래 주문건의 송장번호를 수정하면 배송추적을 다시 합니다.',
    variableFormat: MUTATION_VARIABLES.UPDATE_SHIPPINGS,
  },
}

const getVariables = (actionType: ACTION_TYPE, values: Store) => {
  const setting = settings[actionType]
  const { variableFormat } = setting
  let variables
  if (actionType === 'startShippings' || actionType === 'updateShippings') {
    const orderItems: { orderItem: string; shippingCompany: string; shippingNumbers: string[] }[] = []
    Object.entries(values).forEach(
      ([shippingId, shippingNumbersTextAntCompanyId]: [
        string,
        {
          [orderItemId: string]: {
            [key: string]: string
            shippingCompanyId: string
            shippingNumber: string
          }
        }
      ]) => {
        if (shippingId && shippingNumbersTextAntCompanyId) {
          Object.entries(shippingNumbersTextAntCompanyId).forEach(
            ([orderItemId, { shippingCompanyId, shippingNumber }]) => {
              if (shippingNumber) {
                const _shippingNumbers = shippingNumber?.split(',').map((x: string) => x.trim())
                orderItems.push({
                  orderItem: orderItemId,
                  shippingCompany: shippingCompanyId,
                  shippingNumbers: _shippingNumbers,
                })
              }
            }
          )
        }
      }
    )
    if (orderItems.length === 0) {
      throw new Error('하나 이상의 송장번호를 입력해 주세요.')
    }
    variables = variableFormat({ orderItems })
  }
  return variables
}

const ModalShippingOrder = ({ useModalProps, onAPISuccess }: DomainModalProps<ModalShippingOrderProps, undefined>) => {
  const { hideModal } = useModalProps
  const { orderItems = [], actionType } = useModalProps.data
  if (!actionType) return null
  const [form] = Form.useForm()
  const setting = settings[actionType]
  const { gqlKey, title, description } = setting
  const [canStartOrUpdateShippingNumber, setCanStartOrUpdateShippingNumber] = useState(false)

  const getFormInitialValues = (_orderItems: IModelOrderItem[]): Store => {
    const initialValues: {
      [key: string]: {
        [key: string]: { shippingNumber: string | undefined; shippingCompanyId: string | undefined }
      }
    } = {}
    if (hasData(_orderItems)) {
      _orderItems.forEach((x) => {
        if (!initialValues[`${x.shipping.id}`]) {
          initialValues[`${x.shipping.id}`] = {}
        }
        if (!initialValues[`${x.shipping.id}`][`${x.id}`]) {
          initialValues[`${x.shipping.id}`][`${x.id}`] = {
            shippingNumber: x.shippingNumbersText,
            shippingCompanyId: x.shippingCompanyId,
          }
        }
      })
    }
    return initialValues
  }

  const onAction = async (mutation: MutationFunction) => {
    try {
      const values = await form.validateFields()
      const variables = getVariables(actionType, values)
      await mutation({ variables })
      hideModal()
    } catch (e) {
      message.warn((e as Error).message)
    }
  }

  const includeOrderStatus: { [index: string]: string[] } = {
    startShippings: [ORDER_STATUS_TYPE.NEW_ORDER, ORDER_STATUS_TYPE.READY_FOR_SHIPPING],
    updateShippings: [ORDER_STATUS_TYPE.SHIPPED, ORDER_STATUS_TYPE.SHIPPING],
  }

  const shippings = orderItems.filter((x) => x.shipping?.id).map((x) => x.shipping?.id)
  const queryParams: queryParamsProps = {
    gqlKey: 'orderItems',
    variables: {
      orderBy: '-createdAt',
      filter: {
        orderStatus: { include: includeOrderStatus[gqlKey] },
        shippings,
      },
    },
  }

  return (
    <MMutation gqlKey={gqlKey as 'startShippings' | 'updateShippings'} onAPISuccess={onAPISuccess}>
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        const Content = () => {
          return (
            <MQuery queryParams={queryParams} fetchPolicy="no-cache">
              {({ data }: any) => {
                const _orderItems = data?.orderItems.data
                // 주문상태가 배송준비(READY_FOR_SHIPPING) 또는 배송중(SHIPPING)이 아닌 항목이 있을 경우 송장 입력을 할 수 없도록 함
                const _canStartOrUpdateShippingNumber =
                  _orderItems.filter((orderItem: IModelOrderItem) => {
                    return ![ORDER_STATUS_TYPE.READY_FOR_SHIPPING, ORDER_STATUS_TYPE.SHIPPING].includes(
                      orderItem.orderStatus
                    )
                  }).length === 0
                setCanStartOrUpdateShippingNumber(_canStartOrUpdateShippingNumber)

                return (
                  <Form form={form} initialValues={getFormInitialValues(_orderItems)}>
                    <StyledSpace direction="vertical" size="middle">
                      <Text14Normal>{description}</Text14Normal>
                      <SellerTableProcessOrder
                        orderItems={_orderItems!}
                        disabledAll={!canStartOrUpdateShippingNumber}
                      />
                    </StyledSpace>
                  </Form>
                )
              }}
            </MQuery>
          )
        }
        return (
          <MModal
            title={title}
            useModalProps={useModalProps}
            ComponentContent={<Content />}
            onAction={() => onAction(mutation)}
            width={1020}
            modalDisable={!canStartOrUpdateShippingNumber}
            disableContent="주문상태를 확인해주세요"
            loading={loading}
            hideModalAfterOnAction={false}
          />
        )
      }}
    </MMutation>
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default ModalShippingOrder
