import { MutableRefObject } from 'react'
import { message } from 'antd'

import { MButton } from 'components/@atomics'
import { IModelProductQna } from 'containers/models/modelProductQna'
import { ModalConfirmTable } from 'domains/common/modals'
import useModal from 'containers/hooks/useModal'

const ButtonDeleteProductQna = ({
  selected,
  refetchRef,
}: {
  selected: MutableRefObject<IModelProductQna[]>
  refetchRef: MutableRefObject<Function | null>
}) => {
  const { useModalProps } = useModal()

  return (
    <>
      <MButton
        onClick={() => {
          const selectedLength: number = selected.current.length
          if (!selectedLength) {
            message.warning('선택된 항목이 없습니다.')
          } else {
            useModalProps.toggleModal()
            useModalProps.setData({ productQnas: selected.current })
          }
        }}
      >
        선택 삭제
      </MButton>
      <ModalConfirmTable
        useModalProps={useModalProps}
        fixedProps={{
          mutationKey: {
            gqlKey: 'deleteProductQuestions',
            variableKey: 'productQnaDeleteRequests',
          },
          title: '상품문의 삭제',
          description: '해당 상품문의를 삭제합니다. 삭제된 상품문의는 복구할 수 없습니다.',
          componentOption: {
            tableComponentKey: 'tableServiceQnaDefault',
          },
        }}
        onAPISuccess={() => {
          message.success('상품 문의가 삭제되었습니다.')
          if (refetchRef.current) {
            refetchRef.current()
          }
        }}
      />
    </>
  )
}

export default ButtonDeleteProductQna
