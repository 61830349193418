import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const EXCEL_REQUEST_QUERY_GQLS = {
  EXCEL_EXPORT_REQUESTS: gql`
    query excelExportRequests($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      excelExportRequests(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...excelExportRequest
            requestUser {
              username
            }
            requestSeller {
              shopName
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.excelExportRequest}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,

  EXCEL_IMPORT_REQUESTS: gql`
    query excelImports($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      excelImportRequests(pageInfo: $pageInfo, filter: $filter) {
        totalCount
        edges {
          node {
            id
            importType
            requestUser {
              name
              username
            }
            couponGroup {
              id
              name
            }
            createdAt
            completedAt
            statusText
            status
          }
        }
        pageInfo {
          ...pageInfo
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default EXCEL_REQUEST_QUERY_GQLS
