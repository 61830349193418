import { ModelSkuDefault } from 'containers/models/base/defaultModels'
import { IModelSkuDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelSku extends IModelSkuDefault {}

export default class ModelSku extends ModelSkuDefault {
  constructor(data: IModelSku) {
    super(data)
  }
}
