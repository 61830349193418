import { FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { FORM_ITEM_TYPES } from '@constants/constForm'
import { IModelCustomerAddress } from 'containers/models/modelCustomerAddress'
import { IModelReturnInfo } from 'containers/models/modelReturnInfo'
import { IModelUser } from 'containers/models/modelUser'
import { FC, MutableRefObject, useState } from 'react'
import { Row, Col, Divider, Descriptions, Form, message } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import Paragraph from 'antd/lib/typography/Paragraph'
import { MutationFunction } from '@apollo/client'
import { MFormItemWrapper, MFormItemHidden, MFormItemInput, MFormItemRadio } from 'components/index'
import { MConfirmModal, MModal } from 'components/modals/index'
import useModal, { DomainModalProps } from 'containers/hooks/useModal'
import { MConfirmModalProps } from 'components/modals/MConfirmModal'
import { Text14Normal, Text14Red } from 'components/@atomics/index'
import { TableOrderItemReturn } from 'domains/common/tables/index'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { DescItemReturnPrice } from 'domains/common/descriptions/index'
import MMutation from 'components/MMutation'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import MQuery from 'components/MQuery'
import * as utilData from '@libs/utils/utilData'
import { MUTATION_KEY2GQLS } from 'containers/gqls'
import { YES_OR_NO_TEXT } from '@constants/constDomain'
import { LinkButtonSellerSupport } from 'domains/common'

type ConfirmModalProps = MConfirmModalProps & {
  returnInfos?: IModelReturnInfo[]
}

type ActionParamsProps = {
  user: IModelUser
  returnInfo: IModelReturnInfo
  customerAddress: IModelCustomerAddress
  orderItems: IModelOrderItem[]
  isExchange?: boolean
  isManual?: boolean
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}

type ReasonType = {
  requestType: string
  responsibility: 'CUSTOMER' | 'SELLER'
  requestMessage: string
}

const FORM_ITEM_REQUEST_TYPE_BY_CUSTOMER = {
  name: FIELD_KEYS.FIELD_REQUEST_TYPE,
  label: '구매자 책임',
}

const FORM_ITEM_REQUEST_TYPE_BY_SELLER = {
  name: FIELD_KEYS.FIELD_REQUEST_TYPE,
  label: '판매자 책임',
}

const FORM_ITEM_REQUEST_MESSAGE = {
  name: FIELD_KEYS.FIELD_REQUEST_MESSAGE,
  label: FIELD_VALUES.FIELD_REQUEST_MESSAGE,
}

const FORM_ITEM_RETURN_SHIPPING_FEE = {
  name: FIELD_KEYS.FIELD_RETURN_SHIPPING_FEE,
  suffix: '원',
  type: FORM_ITEM_TYPES.NUMBER,
}

const ModalConfirmReturnOrder: FC<DomainModalProps<ConfirmModalProps, undefined>> = ({ useModalProps }) => {
  if (!useModalProps.actionParams) return null

  const { user, returnInfo, orderItems, isExchange, isManual, refetchRef, statusFilterRefetchRef }: ActionParamsProps =
    useModalProps.actionParams

  if (!utilData.hasData(orderItems)) return null

  const [showRequestMessage, setShowRequestMessage] = useState(returnInfo.requestType === 'ETC')
  const text = isExchange ? '교환' : '반품'
  const isCustomerResponsibility = returnInfo.responsibility === 'CUSTOMER'
  const [form] = Form.useForm()
  const paymentAmount = orderItems.reduce((prev, next) => {
    return prev + next.amount
  }, 0)
  const initReturnShippingFee = isCustomerResponsibility
    ? orderItems.reduce((prev, next) => {
        return prev > next.returnShippingFee ? prev : next.returnShippingFee
      }, 0)
    : 0
  let gqlKey = isExchange ? 'confirmExchangeReturnInfos' : 'returnedReturnInfos'
  if (!isExchange && isManual) {
    gqlKey = 'confirmReturnInfos'
  }

  const { useModalProps: simpleAlertModalProps } = useModal()
  return (
    <MMutation
      gqlKey={gqlKey as keyof typeof MUTATION_KEY2GQLS}
      onAPISuccess={() => {
        if (refetchRef && refetchRef.current) {
          refetchRef.current()
        }
        if (statusFilterRefetchRef && statusFilterRefetchRef.current) {
          statusFilterRefetchRef.current()
        }
        message.success(`${isManual ? '수동 ' : ''}${text} 완료에 성공하였습니다.`)
      }}
    >
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <MModal
            form={form}
            title={`${isManual ? '수동 ' : ''}${text} 완료`}
            useModalProps={useModalProps}
            width={800}
            loading={loading}
            ComponentContent={
              <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                initialValues={{
                  responsibility: returnInfo.responsibility,
                  requestType: returnInfo.requestType,
                  requestMessage: returnInfo.requestMessage,
                  returnShippingFee: initReturnShippingFee,
                }}
                onFinish={(values) => {
                  let variables

                  // returnInfos의 returnInfo id 설정
                  values[FIELD_KEYS.FIELD_RETURN_INFOS] = [{ returnInfo: returnInfo!.id }]

                  if (isExchange) {
                    const { returnShippingFee, ...rest } = values
                    variables = MUTATION_VARIABLES.CONFIRM_EXCHANGE_RETURN_INFOS({
                      exchangeShippingFee: returnShippingFee,
                      ...rest,
                    })
                  } else {
                    variables = isManual
                      ? MUTATION_VARIABLES.CONFIRM_RETURN_INFOS(values)
                      : MUTATION_VARIABLES.RETURNED_RETURN_INFOS(values)
                  }

                  mutation({ variables })
                }}
              >
                {!isExchange && (
                  <>
                    <Text14Normal>반품 완료 시 환불 금액은 반품 배송비를 차감한 뒤 자동으로 환불됩니다.</Text14Normal>
                    <br />
                    <Text14Red bold>환불이 진행되지 않는 경우에는 핏펫 셀러 지원센터로 문의 부탁드립니다.</Text14Red>
                    <br />
                    <LinkButtonSellerSupport />
                    <Divider />
                  </>
                )}
                <TableOrderItemReturn orderItems={orderItems} />

                <Descriptions bordered column={1} size="small">
                  <Descriptions.Item label="주문자 정보" labelStyle={{ fontWeight: 'bold' }}>
                    {returnInfo.customerAddress
                      ? `${returnInfo.customerAddress.name} / ${returnInfo.customerAddress.mobileNumber}`
                      : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="수거지 정보" labelStyle={{ fontWeight: 'bold' }}>
                    {returnInfo.customerAddress ? returnInfo.customerAddress.fullAddress : '-'}
                  </Descriptions.Item>
                  {!isExchange && (
                    <Descriptions.Item label="환불 계좌정보" labelStyle={{ fontWeight: 'bold' }}>
                      <Text14Normal>{user.fullBankAccount}</Text14Normal>
                    </Descriptions.Item>
                  )}
                </Descriptions>

                <Divider />

                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Text14Normal bold>{`${text} 사유`}</Text14Normal>
                  </Col>
                  <Col span={24}>
                    <Paragraph>
                      <ul>
                        <li>
                          <Text14Normal>{`판매자 책임인 ${text}건에 대하여, 판매자 책임이 아닌 경우에만 ${text} 사유를 수정할 수 있습니다.`}</Text14Normal>
                        </li>
                        <li>
                          <Text14Normal>{`단 ${text} 사유를 변경하고자 하실 경우에는 반드시 구매 고객의 동의를 사전에 구해야 합니다.`}</Text14Normal>
                          <ul>
                            <li>
                              <Text14Normal>{`동의 없이 판매자가 임의로 ${text}사유를 변경할 경우, 핏펫몰 이용약관에 따른 페널티가 부여됩니다.`}</Text14Normal>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Text14Normal>{`${text} 수수료 정책`}</Text14Normal>
                          <ul>
                            <li>
                              <Text14Normal>
                                귀책사유가 판매자 책임인 경우 판매자 부담 (고객: 전액환불 / 판매자 : 정산없음)
                              </Text14Normal>
                            </li>
                            <li>
                              <Text14Normal>
                                귀책사유가 구매자 책임인 경우 고객 부담 (고객 : 반품수수료 차감 후 환불 / 판매자 : 직접
                                정산)
                              </Text14Normal>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </Paragraph>
                  </Col>
                </Row>
                <Divider />
                <MQuery queryParams={{ gqlKey: 'orderItemReturnReasons' }}>
                  {({ data }) => {
                    const reasons = data.orderItemReturnReasons.data.returnReasons as ReasonType[]
                    const customerReasons = reasons.filter(({ responsibility: _r }: ReasonType) => {
                      return _r === 'CUSTOMER'
                    })
                    const sellerReasons = reasons.filter(({ responsibility: _r }: ReasonType) => {
                      return _r === 'SELLER'
                    })
                    const makeOption = ({ requestType, requestMessage }: ReasonType) => ({
                      label: requestMessage,
                      value: requestType,
                    })
                    const onRequestMessageChange = (e: RadioChangeEvent) => {
                      const requestType = e.target.value
                      const { returnShippingFee: formReturnShippingFee, requestType: formRequestType } =
                        form.getFieldsValue()
                      const nextResponsibility = reasons.find(
                        ({ requestType: _requestType }: ReasonType) => _requestType === requestType
                      )?.responsibility
                      simpleAlertModalProps.setData({
                        requestType: e.target.value,
                        responsibility: nextResponsibility,
                        returnShippingFee: nextResponsibility === 'SELLER' ? 0 : formReturnShippingFee,
                        requestMessage: '',
                      })
                      simpleAlertModalProps.toggleModal()
                      return formRequestType
                    }
                    return (
                      <Row>
                        <Col span={18}>
                          <MFormItemHidden name={FIELD_KEYS.FIELD_RESPONSIBILITY} />
                          <MFormItemRadio
                            {...FORM_ITEM_REQUEST_TYPE_BY_CUSTOMER}
                            getValueFromEvent={onRequestMessageChange}
                            options={customerReasons.map(makeOption)}
                          />
                          <MFormItemRadio
                            {...FORM_ITEM_REQUEST_TYPE_BY_SELLER}
                            getValueFromEvent={onRequestMessageChange}
                            options={sellerReasons.map(makeOption)}
                          />
                          {showRequestMessage && <MFormItemInput {...FORM_ITEM_REQUEST_MESSAGE} />}
                          <MFormItemInput
                            {...FORM_ITEM_RETURN_SHIPPING_FEE}
                            label={`${text} 배송비`}
                            disabled={form.getFieldValue('responsibility') === 'SELLER'}
                          />
                        </Col>
                      </Row>
                    )
                  }}
                </MQuery>
                {!isExchange && (
                  <>
                    <Divider />
                    <MFormItemWrapper noStyle shouldUpdate>
                      {() => {
                        const adjustedReturnShippingFee = form.getFieldValue('returnShippingFee')
                        return (
                          <DescItemReturnPrice
                            paymentAmount={paymentAmount}
                            returnShippingFee={adjustedReturnShippingFee}
                          />
                        )
                      }}
                    </MFormItemWrapper>
                  </>
                )}
                <MConfirmModal
                  useModalProps={simpleAlertModalProps}
                  fixedProps={{
                    title: '알림',
                    description: isCustomerResponsibility
                      ? `구매자 책임인 ${text}사유는 변경하실 수 없습니다.`
                      : '구매 고객에게 동의를 받으셨습니까?',
                    okText: isCustomerResponsibility ? undefined : YES_OR_NO_TEXT.YES,
                    cancelText: isCustomerResponsibility ? undefined : YES_OR_NO_TEXT.NO,
                  }}
                  onAction={() => {
                    if (!isCustomerResponsibility) {
                      setShowRequestMessage(simpleAlertModalProps.data.requestType === 'ETC')
                      form.setFieldsValue(simpleAlertModalProps.data)
                    }
                  }}
                />
              </Form>
            }
            maskClosable={false}
          />
        )
      }}
    </MMutation>
  )
}

export default ModalConfirmReturnOrder
