import { FunctionComponent } from 'react'
import { Space } from 'antd'

import { DomainModalProps } from 'containers/hooks/useModal'
import { MModal } from 'components/modals'
import { footerTypeProps } from 'components/modals/MModal'

type ContentProps = {
  description: string | JSX.Element
  Component?: JSX.Element
  // width?: number;
}

export type MFixedProps = ContentProps & {
  title?: string
  closable?: boolean
  okButtonDisable?: boolean
  disableContent?: string
  footerType?: footerTypeProps
  cancelText?: string
  okText?: string
  theme?: 'blackAndWhite'
}

type MConfirmModalCustomProps = {
  width?: number
}

export type MConfirmModalProps = MConfirmModalCustomProps & {
  id: string
}

const Content: FunctionComponent<ContentProps> = ({ description, Component }) => {
  return (
    <>
      {description}
      {Component && (
        <>
          <Space style={{ height: 10 }} />
          {Component}
        </>
      )}
    </>
  )
}

const MConfirmModal = ({
  useModalProps,
  onAction,
  onCancelAction,
  loading,
  fixedProps,
}: DomainModalProps<MConfirmModalProps, MFixedProps>) => {
  const { theme, closable, description, Component, okButtonDisable = false, disableContent } = fixedProps
  const { hideModal, width } = useModalProps
  return (
    <MModal
      theme={theme}
      useModalProps={useModalProps}
      width={width}
      closable={closable}
      modalDisable={okButtonDisable}
      disableContent={disableContent}
      ComponentContent={<Content description={description} Component={Component} />}
      onAction={() => {
        if (onAction) {
          onAction()
          hideModal()
        }
      }}
      onCancelAction={onCancelAction}
      loading={loading}
      {...fixedProps}
    />
  )
}

export default MConfirmModal
