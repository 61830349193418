import { ChangeEvent } from 'react'
import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import styled from 'styled-components'

import { MFormItemWrapper } from 'components/formItems'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'

export type MFormItemInputNumberProps = MFormItemWrapperProps & {
  inputNumberProps?: InputNumberProps
  placeholder?: string
  suffix?: string
  decimalPlaces?: number
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const MFormItemInputNumber = (props: MFormItemInputNumberProps) => {
  const { inputNumberProps, suffix, placeholder, decimalPlaces, disabled, ...otherProps } = props

  let formatter
  let parser
  if (suffix) {
    formatter = (value: number | string | undefined) => {
      if (suffix === '%') {
        return `${value}%`
      }
      return `${value}${suffix}`
    }
    parser = (value: string | undefined) => {
      if (suffix === '%') {
        return value ? value.replace('%', '') : ''
      }
      let _value: number | string = ''

      if (suffix.length > 1) {
        // mm, km 등을 처리
        const splitSuffix = suffix.split('')
        for (let i = 0; i < splitSuffix.length; i += 1) {
          if (i === 0) {
            _value = value!.replace(`${splitSuffix[i]}`, '')
          } else {
            _value = _value!.replace(`${splitSuffix[i]}`, '')
          }
        }
      } else {
        _value = value!.replace(`${suffix}`, '')
      }

      if (!_value) return ''

      // 소수점 처리
      if (_value.toString().includes('.') && decimalPlaces) {
        if (_value.toString().split('.')[1].length > decimalPlaces) {
          _value = parseFloat(_value).toFixed(decimalPlaces)
        }
      }

      // 소수점 이하 제거
      if (decimalPlaces === 0) {
        _value = parseFloat(_value).toFixed(decimalPlaces)
      }

      return _value
    }
  }

  return (
    <MFormItemWrapper {...otherProps}>
      <StyledInputNumber
        placeholder={placeholder}
        formatter={formatter}
        parser={parser}
        disabled={disabled}
        {...inputNumberProps}
      />
    </MFormItemWrapper>
  )
}

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`

export default MFormItemInputNumber
