import { IModelBillingItemDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelBillingItemDefault } from 'containers/models/base/defaultModels'
import { IModelOrderItem } from 'containers/models/modelOrderItem'

export interface IModelBillingItem extends IModelBillingItemDefault {
  orderItem: IModelOrderItem
}

export default class ModelBillingItem extends ModelBillingItemDefault {
  constructor(data: IModelBillingItem) {
    super(data)
  }
}
