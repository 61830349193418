import { TableTransferColumn } from 'components/@atomics/MTableTransfer'
import _ from 'lodash'
import { FC, useRef, useState } from 'react'
import { MFormModalFilter } from 'components'
import { FilterOptionsCommon, FilterOptionsSelect } from 'components/MFormModalFilter'
import { MTableTransfer } from 'components/@atomics'
import MQuery, { queryParamsProps } from 'components/MQuery'
import { IModelUser } from 'containers/models/modelUser'
import { TypeUseModalProps } from 'containers/hooks/useModal'
import { onTransferProps } from 'domains/common/modals/ModalTableTransferSelect'
import { FIELD_KEYS, FIELD_VALUES, TRANSFER_TABLE_PAGE_SIZE } from '@constants/constData'

type TableTransferUserProps = {
  onTransfer: (values: onTransferProps) => void
  chosenValues?: IModelUser[]
  useModalProps: TypeUseModalProps<any>
  disableCheckedNumber?: number
}

const formInputOptions: FilterOptionsCommon[] = [
  { label: '휴대폰번호', name: 'mobileNumber', labelCol: { span: 6 } },
  { label: '이메일', name: 'email', labelCol: { span: 6 } },
  { label: '이름', name: 'name', labelCol: { span: 6 } },
]

const formSelectOptions: FilterOptionsSelect[] = [
  {
    name: FIELD_KEYS.FIELD_AGREE_MARKETING,
    label: '마케팅 수신동의',
    selectOptions: [
      { value: FIELD_KEYS.FIELD_ALL, title: FIELD_VALUES.FIELD_ALL },
      { value: true, title: FIELD_VALUES.FIELD_AGREE },
      { value: false, title: FIELD_VALUES.FIELD_DENY },
    ],
  },
  {
    name: FIELD_KEYS.FIELD_AGREE_NIGHT_MARKETING,
    label: '야간 마케팅 수신동의',
    selectOptions: [
      { value: FIELD_KEYS.FIELD_ALL, title: FIELD_VALUES.FIELD_ALL },
      { value: true, title: FIELD_VALUES.FIELD_AGREE },
      { value: false, title: FIELD_VALUES.FIELD_DENY },
    ],
  },
]

const formDateOptions: FilterOptionsCommon[] = [
  {
    name: FIELD_KEYS.FIELD_DATE_JOINED,
    label: '가입일',
    labelCol: { span: 6 },
  },
  {
    name: FIELD_KEYS.FIELD_LAST_LOGIN,
    label: '최종사용일',
    labelCol: { span: 6 },
  },
]

const rightTableColumns: TableTransferColumn<IModelUser>[] = [
  { dataIndex: 'email', title: '이메일', width: 180 },
  { dataIndex: 'name', title: '이름', width: 120 },
  { dataIndex: 'mobileNumber', title: '휴대폰번호', width: 180 },
]
const leftTableColumns: TableTransferColumn<IModelUser>[] = [
  { dataIndex: '_id', title: '번호', width: 60 },
  { dataIndex: 'agreeMarketing', title: '알림수신', width: 140 },
  ...rightTableColumns,
]

const TableTransferUser: FC<TableTransferUserProps> = ({ onTransfer, useModalProps, chosenValues = [] }) => {
  const [filterVariables, setFilterVariables] = useState<{}>({})
  const usersRef = useRef<IModelUser[]>([])
  const targetUsersRef = useRef<IModelUser[]>(chosenValues || [])
  const excludeUsers = chosenValues.map((user) => user.id)
  const queryParams: queryParamsProps = {
    gqlKey: 'usersByTransferTable',
    variables: {
      filter: { ...filterVariables, excludeUsers, userStatus: { exclude: ['LEFT', 'ADMIN_LEFT'] } },
      pageInfo: { first: TRANSFER_TABLE_PAGE_SIZE },
    },
  }

  const refetchRef = useRef<any>(null)

  const _onTransfer = (nextTargetKeys: string[]) => {
    targetUsersRef.current = usersRef.current.filter((user) => nextTargetKeys.includes(user.id))
    onTransfer({ values: targetUsersRef.current })
  }
  return (
    <>
      <MFormModalFilter
        formInputOptions={formInputOptions}
        formSelectOptions={formSelectOptions}
        formDateOptions={formDateOptions}
        setFilterVariables={setFilterVariables}
        refetchRef={refetchRef}
      />
      <MQuery queryParams={queryParams}>
        {({ data, refetch }: any) => {
          if (refetchRef) {
            refetchRef.current = refetch
          }

          usersRef.current = _.unionBy(data?.users?.data, targetUsersRef.current, 'id')
          const totalCount = data?.users?.totalCount || usersRef.current.length
          const dataSource = usersRef.current.map((user) => ({
            key: user.id,
            _id: user._id,
            email: user.email,
            mobileNumber: user.mobileNumber || '-',
            name: user.name || '-',
            agreeMarketing: user.agreeMarketing ? '동의' : '거부',
          }))

          return (
            <MTableTransfer
              totalCount={totalCount}
              dataSource={dataSource}
              initialTargetKeys={targetUsersRef.current.map((user) => user.id)}
              showSelectAll={false}
              useModalProps={useModalProps}
              onTransfer={_onTransfer}
              listStyle={{}}
              locale={{ itemUnit: '명', itemsUnit: '명' }}
              filterOption={(inputValue: string, option: any) => option?.email?.indexOf(inputValue) > -1}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
            />
          )
        }}
      </MQuery>
    </>
  )
}

export default TableTransferUser
