import { ReactNode } from 'react'
import { Card, Space } from 'antd'
import { CardProps } from 'antd/lib/card'
import styled from 'styled-components'

import { MRequiredMark, Text14Orange, Text14Normal, Text20Normal } from 'components/@atomics'

export type MOuterCardProps = CardProps & {
  title?: ReactNode | string
  subTitle?: ReactNode | string
  requiredMark?: boolean
  requiredText?: string
  children?: ReactNode
}

const MOuterCard = ({ title, subTitle, requiredMark, requiredText, children, ...cardProps }: MOuterCardProps) => {
  const MainTitle = () => <Text20Normal>{title}</Text20Normal>
  const SubTitle = () => <Text14Normal>{subTitle}</Text14Normal>
  return (
    <StyledOuterCard
      {...cardProps}
      title={
        typeof title === 'string' || typeof subTitle === 'string' ? (
          <Space size="middle" style={{ marginLeft: 0 }}>
            {title ? <MainTitle /> : null}
            {subTitle ? <SubTitle /> : null}
            {requiredMark && !requiredText && (
              <Text14Orange>
                [<MRequiredMark style={{ marginLeft: 6 }} />] 는 필수 입력 항목입니다
              </Text14Orange>
            )}
            {!requiredMark && requiredText && <Text14Orange>{requiredText}</Text14Orange>}
          </Space>
        ) : (
          title
        )
      }
    >
      {children}
    </StyledOuterCard>
  )
}

const StyledOuterCard = styled(Card)<MOuterCardProps>`
  margin: 16px 24px;
`

export default MOuterCard
