import { gql } from '@apollo/client'
import PRODUCT_FRAGMENTS from 'containers/gqls/products/product/fragments'

const PRODUCT_OPTION_FRAGMENT = {
  get productOptionGroupMinimum() {
    return gql`
      fragment productOptionGroupMinimum on ProductOptionGroupType {
        id
        name
        isCustomGroup
      }
    `
  },

  get productOptionValueMinimum() {
    return gql`
      fragment productOptionValueMinimum on ProductOptionValueType {
        id
        name
        productOptionGroup {
          ...productOptionGroupMinimum
        }
      }
      ${this.productOptionGroupMinimum}
    `
  },

  get productOptionValuesMinimum() {
    return gql`
      fragment productOptionValuesMinimum on ProductOptionValueTypeConnection {
        edges {
          node {
            ...productOptionValueMinimum
          }
        }
      }
      ${this.productOptionValueMinimum}
    `
  },

  get productOptionMinimum() {
    return gql`
      fragment productOptionMinimum on ProductOptionType {
        id
        productOptionPrice
        purchasePrice
        productOptionSkus {
          pageInfo {
            ...pageInfo
          }
          edges {
            node {
              id
              sku {
                id
                code
                name
                stockQuantity
                purchasePrice
              }
              count
            }
          }
        }
        name
        stockQuantity
        series
        isUse
        isDefault
        productOptionValues {
          ...productOptionValuesMinimum
        }
      }
      ${this.productOptionValuesMinimum}
    `
  },

  get productOptionsMinimum() {
    return gql`
      fragment productOptionsMinimum on ProductOptionTypeConnection {
        edges {
          node {
            ...productOptionMinimum
          }
        }
      }
      ${this.productOptionMinimum}
    `
  },
  get updatedProductOptionsMinimum() {
    return gql`
      fragment updatedProductOptionsMinimum on UpdatedProductOptionTypeConnection {
        edges {
          node {
            ...productOptionMinimum
          }
        }
      }
      ${this.productOptionMinimum}
    `
  },
  get productOptionOnly() {
    return gql`
      fragment productOptionOnly on ProductOptionType {
        id
        product {
          ...productDetail
        }
        productOptionKinds {
          key
          value
        }
      }
      ${PRODUCT_FRAGMENTS.productDetail}
    `
  },
}

export default PRODUCT_OPTION_FRAGMENT
