import { Popover } from 'antd'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { IModelShipping } from 'containers/models/modelShipping'
import { Text14Normal, MButton, Text14Blue, Text14Red } from 'components/@atomics'
import { IModelShippingPreset } from 'containers/models/modelShippingPreset'
import { useSelector } from 'react-redux'
import { RootState } from '@libs/redux/store'
import { ITableColumn } from 'components/@atomics/MTable'

const shippingNumber = ({ fixed = false }: { fixed?: 'left' | 'right' | boolean } = {}): ITableColumn<any> => {
  return {
    title: '송장번호(배송업체)',
    dataIndex: 'shipping',
    key: 'shippingNumber',
    fixed,
    width: 150,
    render: (shipping: IModelShipping, record: IModelOrderItem) => {
      const initData = useSelector((state: RootState) => state.reduxDataReducers.initData)
      const sweetTrackerCode = initData.getSweetTrackerCode(record.shippingCompanyId)
      const shippingCompanyName = initData.getShippingCompanyName(record.shippingCompanyId)
      return (
        <>
          {record.shippingNumbers.map((_shippingNumber) => {
            const shippingNumberAndCompany = `${_shippingNumber} (${shippingCompanyName})`
            return (
              <form action="https://info.sweettracker.co.kr/tracking/1" method="post" target="_blank">
                <div style={{ display: 'none' }}>
                  <input type="text" name="t_key" value={process.env.LOGISTICS_KEY} />
                </div>
                <div className="form-group" style={{ display: 'none' }}>
                  <input type="text" name="t_code" value={sweetTrackerCode} />
                </div>
                <div className="form-group" style={{ display: 'none' }}>
                  <input type="text" name="t_invoice" value={_shippingNumber} />
                </div>
                {shipping.isTrackable ? (
                  <MButton type="link" htmlType="submit" disabled={!shipping.isTrackable}>
                    <Text14Blue>{shippingNumberAndCompany}</Text14Blue>
                  </MButton>
                ) : (
                  <MButton type="link">
                    <Popover placement="top" title="불가 사유" content={shipping.untrackedReason} trigger="click">
                      <Text14Red>{shippingNumberAndCompany}</Text14Red>
                    </Popover>
                  </MButton>
                )}
              </form>
            )
          })}
        </>
      )
    },
  }
}

const shppingId = () => {
  return {
    title: '배송ID',
    dataIndex: 'shipping',
    key: 'shippingId',
    fixed: 'left',
    width: 100,
    render: (shipping: IModelShipping) => {
      return <Text14Normal>{shipping._id}</Text14Normal>
    },
  }
}

const ablePackingText = () => {
  return {
    title: '묶음배송 가능여부',
    dataIndex: ['shipping', 'shippingPreset'],
    key: 'ablePackingText',
    fixed: 'left',
    width: 80,
    render: (shippingPreset: IModelShippingPreset) => {
      return <Text14Normal>{shippingPreset.ablePackingText}</Text14Normal>
    },
  }
}

const checkShippingStatus = () => {
  return {
    title: '배송현황',
    dataIndex: 'shipping',
    key: 'shippingStatus',
    width: 80,
    render: (value: IModelShipping, record: IModelOrderItem) => {
      // 어디서 바뀌는지 모르겠으나 code가 08인 경우, 8(number type으로 변경됨)
      const sweetTrackerCode = String(value.shippingCompany.sweetTrackerCode).padStart(2, '0')
      return (
        <form action="https://info.sweettracker.co.kr/tracking/1" method="post" target="_blank">
          <div style={{ display: 'none' }}>
            <input type="text" name="t_key" value={process.env.LOGISTICS_KEY} />
          </div>
          <div className="form-group" style={{ display: 'none' }}>
            <input type="text" name="t_code" value={sweetTrackerCode} />
          </div>
          <div className="form-group" style={{ display: 'none' }}>
            <input type="text" name="t_invoice" value={record.shippingNumbersText} />
          </div>
          {value.isTrackable ? (
            <MButton type="link" htmlType="submit" disabled={!value.isTrackable}>
              <Text14Blue>[보기]</Text14Blue>
            </MButton>
          ) : (
            <MButton type="link">
              <Popover placement="top" title="불가 사유" content={value.untrackedReason} trigger="click">
                <Text14Red>[불가]</Text14Red>
              </Popover>
            </MButton>
          )}
        </form>
      )
    },
  }
}

export { shippingNumber, shppingId, ablePackingText, checkShippingStatus }
