import { S3_HOST_URL } from '@constants/constData'
import { IModelAdImageDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelAdImageDefault } from 'containers/models/base/defaultModels'

export interface IModelAdImage extends IModelAdImageDefault {
  imageUrl: string
}

export default class ModelAdImage extends ModelAdImageDefault {
  imageUrl = ''

  constructor(data: IModelAdImage) {
    super(data)

    this.imageUrl = this.getImageUrl()
  }

  getImageUrl = () => {
    return `${S3_HOST_URL}/${this.image}`
  }
}
