import { USER_MODE } from '@constants/constDomain'
import _ from 'lodash'
import { NextRouter, SingletonRouter } from 'next/router'

import * as utilLocalStorage from '@libs/utils/utilLocalStorage'
import ROUTES from '@constants/constRoutes'
import useUserInfo from 'containers/hooks/useUserInfo'

export const isMobile = () => window && window.outerWidth <= 480

export const cleanLocalStorage = () => {
  const { cleanUserInfo } = useUserInfo
  cleanUserInfo()
}

export const cleanAndGoToLogin = (router: SingletonRouter | NextRouter) => {
  // localUserToken.remove()
  // localUserMode.remove()
  // localKeepSignin.remove()
  cleanLocalStorage()
  if (router.pathname !== ROUTES.SIGNUP) {
    router.push(ROUTES.LOGIN)
  }
}

export function checkPreviousPageIsDetail(pathname: string) {
  // const dispatch = useDispatch()
  const detail = utilLocalStorage.localDetailPage.load()
  if (_.isEmpty(detail)) {
    if (pathname.includes('[id]')) {
      utilLocalStorage.localDetailPage.save(pathname)
    } else {
      // TODO juyup 일단 주석처리하고 작업 후 확인 필요
      // dispatch(doInitializeMainTableVariables())
      utilLocalStorage.localDetailPage.remove()
    }
  } else {
    if (detail.pathname !== pathname) {
      if (!detail.pathname.includes(pathname)) {
        // dispatch(doInitializeMainTableVariables())
        utilLocalStorage.localDetailPage.remove()
      }
    }
  }
}

export function isAlpha(): boolean {
  return process.env.FITPETMALL_ENV === 'alpha'
}

export function isStaging(): boolean {
  return process.env.FITPETMALL_ENV === 'staging' || process.env.FITPETMALL_ENV === 'rc'
}

export function scrollToBottom(): void {
  setTimeout(() => {
    window.scrollTo({ behavior: 'smooth', top: 5000 })
  })
}

export const isAdmin = (userMode?: string) =>
  userMode ? userMode === USER_MODE.ADMIN : useUserInfo.useUserMode() === USER_MODE.ADMIN

export const getFullAddress = (
  postNumber: string | null | undefined,
  basicAddress: string | null | undefined,
  detailedAddress: string | null | undefined
) => {
  const _postNumber = postNumber ? `(${postNumber})` : ''
  const _basicAddress = basicAddress || ''
  const _detailedAddress = detailedAddress || ''
  return `${_postNumber} ${_basicAddress} ${_detailedAddress}`
}

export const getSocialIconByUserSocialType = (userSocialType: string): string => {
  return `icon-${userSocialType.toLowerCase()}`
}

export const getFullBankAccount = (
  bankName: string | null | undefined,
  accountNumber: string | null | undefined,
  accountHolderName: string | null | undefined
) => {
  const _bankName = bankName || ''
  const _accountNumber = accountNumber || ''
  const _accountHolderName = accountHolderName ? `(예금주: ${accountHolderName})` : ''
  if (_accountNumber === '') {
    return '미입력'
  }
  return `${_bankName} ${_accountNumber} ${_accountHolderName}`
}

export const getLocalSearchKeywords = () => {
  return utilLocalStorage.localSearchKeywords.load()?.map((v: string) => ({ title: v, value: v }))
}
export const setLocalSearchKeywords = (keyword: string[]) => {
  if (keyword.length <= 0) return
  // 중복 키워드 및 공백 제거
  const localKeyword: string[] = utilLocalStorage.localSearchKeywords.load()
  keyword.forEach((k: string) => {
    const _k = k.trim()
    if (_k.length > 0 && !localKeyword.includes(_k)) localKeyword.push(_k)
  })
  utilLocalStorage.localSearchKeywords.save(localKeyword)
}
