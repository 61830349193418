import _ from 'lodash'
import { useState } from 'react'
import { Col, Form, Row, Space } from 'antd'
import { ExpandableConfig } from 'antd/lib/table/interface'
import { TablePaginationConfig } from 'antd/es/table'
import { Store } from 'rc-field-form/lib/interface'
import styled from 'styled-components'
import * as utilDate from '@libs/utils/utilDate'
import { TypeUseModalProps } from 'containers/hooks/useModal'
import { MFormItemInput, MFormItemWrapper, MFormItemDateRangePicker, IFilterOptions } from 'components/formItems'
import { MModal } from 'components/modals'
import { MButton, MTotalCountText, MSelect } from 'components/@atomics'
import MQuery, { listVariablesProps, queryParamsProps } from 'components/MQuery'
import MTable, { ITableColumn } from 'components/@atomics/MTable'
import useTable from 'containers/hooks/useMTable'
import { FormItemSelectBrand, FormItemSelectCategory } from 'domains/admin/formItems'
import { FIELD_KEYS } from '@constants/constData'

type searchInputProps = {
  label: string
  name: string
}

export type modalContentProps = Omit<FormSearchProps, 'setFilterAndDoRefetch'> & {
  title: string
  queryParams: queryParamsProps
  tableColumns: Array<ITableColumn<any>>
  onItemSelect?: (item: any) => void
  expandable?: ExpandableConfig<any>
  width?: number
  okText?: string
  totalCountSize?: 12 | 14 | 16
}

type ModalTableSelectProps = modalContentProps & {
  useModalProps: TypeUseModalProps<any>
}

type selectOptionProps = searchInputProps & {
  options: IFilterOptions
  labelCol?: { span: number }
  multiple?: boolean
  selectWidth?: number
}

type FormSearchProps = {
  searchInputs?: searchInputProps[]
  dateOptions?: searchInputProps[]
  selectOptions?: selectOptionProps[]
  selectBrands?: boolean
  selectCategories?: boolean
  setFilterAndDoRefetch: (variables: object) => void
}

const FormSearch = ({
  searchInputs,
  selectOptions,
  selectBrands,
  selectCategories,
  dateOptions,
  setFilterAndDoRefetch,
}: FormSearchProps) => {
  const [form] = Form.useForm()

  const onFinish = (values: Store) => {
    const originValues = values

    // for date filter
    const dateOptionKeys: string[] = ['createdAt', 'updateApprovedAt', 'dateJoined', 'lastLogin']
    if (!_.isEmpty(dateOptions)) {
      _.forEach(dateOptions, (option) => {
        if (_.includes(dateOptionKeys, option.name)) {
          originValues[`${option.name}After`] = utilDate.date2string(originValues[option.name]?.[0])
          originValues[`${option.name}Before`] = utilDate.date2string(originValues[option.name]?.[1])
          delete originValues[option.name]
        }
      })
    }

    setFilterAndDoRefetch(values)
  }

  const optionsLength: number = (searchInputs?.length ?? 0) + (selectOptions?.length ?? 0) + (dateOptions?.length ?? 0)
  const needWrap: boolean = optionsLength > 3

  return (
    <Form form={form} onFinish={onFinish}>
      <StyledFilterWrapperSpace align="baseline" size={needWrap ? 'large' : 'middle'} needWrap={needWrap}>
        {searchInputs &&
          searchInputs.map((searchInput, index) => {
            const keyIndex = index
            const { label, name } = searchInput
            return <MFormItemInput key={keyIndex} label={label} name={name} />
          })}
        {selectOptions?.map((selectOption) => {
          return (
            <MFormItemWrapper
              key={`select-key-${selectOption.name}`}
              name={selectOption.name}
              label={selectOption.label}
            >
              <MSelect filterOptions={selectOption.options} selectWidth={selectOption.selectWidth} />
            </MFormItemWrapper>
          )
        })}
        {selectBrands && <FormItemSelectBrand />}
        {dateOptions?.map((dateOption) => {
          return <MFormItemDateRangePicker key={`date-${dateOption.name}`} {...dateOption} />
        })}
      </StyledFilterWrapperSpace>
      {selectCategories && (
        <FormItemSelectCategory label="카테고리" name={FIELD_KEYS.FIELD_CATEGORIES} wrapperCol={{ span: 8 }} />
      )}
      <Row justify="end" align="middle">
        <Col>
          <MButton type="primary" htmlType="submit">
            검색
          </MButton>
        </Col>
      </Row>
    </Form>
  )
}

const ModalTableSelect = ({
  title,
  useModalProps,
  width,
  okText,
  queryParams,
  ...restProps
}: ModalTableSelectProps) => {
  const { hideModal } = useModalProps
  const { tableColumns, onItemSelect, searchInputs, selectOptions, selectBrands, selectCategories, dateOptions } =
    restProps
  const [selectedValue, setSelectedValue] = useState<any>()
  const pagination: TablePaginationConfig = { position: ['bottomCenter'] }
  const { getTablePagination, setInitVariablesAndReturn, setRefetch, setFilterAndDoRefetch } = useTable()
  queryParams.variables = setInitVariablesAndReturn(queryParams.variables as listVariablesProps)
  const needFormSearch: boolean =
    !!searchInputs || !!selectOptions || !!dateOptions || !!selectBrands || !!selectCategories

  const formSearchProps: FormSearchProps = {
    searchInputs,
    selectOptions,
    selectBrands,
    selectCategories,
    dateOptions,
    setFilterAndDoRefetch,
  }

  const onRowSelect = (rows: any[]) => {
    setSelectedValue(rows[0])
  }

  const onOk = () => {
    if (onItemSelect && selectedValue) {
      onItemSelect(selectedValue)
    }
    hideModal()
  }

  return (
    <MModal
      title={title}
      useModalProps={useModalProps}
      width={width ?? 700}
      onAction={onOk}
      okText={okText}
      ComponentContent={
        <MQuery queryParams={{ ...queryParams }}>
          {({ data, refetch }: any) => {
            if (!data) {
              return null
            }
            const { dataKey, gqlKey } = queryParams
            const _dataKey = dataKey || gqlKey
            setRefetch(refetch)
            const { totalCount, data: _data } = data[_dataKey]
            const tablePagination = getTablePagination(pagination, totalCount)
            return (
              <>
                {needFormSearch && <FormSearch {...formSearchProps} />}

                <MTotalCountText title="목록" totalCount={totalCount} unit="개" size={restProps.totalCountSize ?? 12} />

                <MTable
                  columns={tableColumns}
                  dataSource={_data}
                  rowSelectionType="radio"
                  onRowSelect={onRowSelect}
                  pagination={tablePagination}
                  expandable={restProps.expandable}
                />
              </>
            )
          }}
        </MQuery>
      }
    />
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledFilterWrapperSpace = styled(({ needWrap, ...rest }) => <Space {...rest} />)`
  flex-wrap: ${(props) => (props.needWrap ? 'wrap' : 'nowrap')};
`

export default ModalTableSelect
