import { MLinkRouterButton, Text12Normal, Text14Normal } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelInformPushQueue } from 'containers/models/modelInformPushQueue'
import { Image, Space } from 'antd'
import * as utilDate from '@libs/utils/utilDate'
import React from 'react'
import ROUTES from '@constants/constRoutes'

const detailButton = (): ITableColumn<any> => {
  return {
    title: '상세',
    width: 150,
    render: ({ _id }: IModelInformPushQueue) => {
      return (
        <MLinkRouterButton
          sider={false}
          openNewWindow
          href={{
            pathname: ROUTES.ACCOUNTS.PUSH_DETAIL,
            query: { id: _id },
          }}
          buttonType="button"
        >
          상세보기
        </MLinkRouterButton>
      )
    },
  }
}

const sendAt = () => {
  return {
    title: '발송일시',
    width: 150,
    render: ({ isReserved, sendAt: _sendAt }: IModelInformPushQueue) => {
      return (
        <Space direction="vertical" size={0}>
          {isReserved ? <Text14Normal bold>[예약]</Text14Normal> : null}
          <Text12Normal>{utilDate.date2string(_sendAt, true)}</Text12Normal>
        </Space>
      )
    },
  }
}

const imageThumbnail = (): ITableColumn<any> => {
  return {
    title: '이미지 섬네일',
    width: 60,
    render: (record: IModelInformPushQueue) => {
      const { imageUrl } = record
      return <Image preview={false} src={imageUrl} alt="push image" width={40} height={40} />
    },
  }
}

const titleAndDescription = () => {
  return {
    title: '메인제목\n서브내용',
    width: 150,
    render: (record: IModelInformPushQueue) => {
      const {
        push: { title, text },
      } = record
      return (
        <Space direction="vertical" size={0}>
          <Text12Normal bold>{title}</Text12Normal>
          <Text12Normal>{text}</Text12Normal>
        </Space>
      )
    },
  }
}

const linkButton = (): ITableColumn<any> => {
  return {
    title: '링크',
    width: 150,
    render: ({ push: { url } }: IModelInformPushQueue) => {
      return (
        <MLinkRouterButton sider={false} openNewWindow href={url} buttonType="button">
          이동
        </MLinkRouterButton>
      )
    },
  }
}

const userGrades = () => {
  return {
    title: '수신 회원 등급',
    width: 150,
    render: ({ grades }: IModelInformPushQueue) => {
      return <>{grades.map((grade) => grade.name).join(', ')}</>
    },
  }
}

const successCountPerTotal = () => {
  return {
    title: '발송성공개수/총유저수',
    key: 'successCountPerTotal',
    width: 150,
    render: ({ sentSuccessCount, totalUserCount }: IModelInformPushQueue) => {
      return (
        <Text12Normal>
          {sentSuccessCount}/{totalUserCount}
        </Text12Normal>
      )
    },
  }
}

const readCountPerTotal = () => {
  return {
    title: '수신회원 읽음',
    key: 'readCountPerTotal',
    width: 150,
    render: ({ readCount, totalReadCount }: IModelInformPushQueue) => {
      return (
        <Text12Normal>
          {readCount}/{totalReadCount}
        </Text12Normal>
      )
    },
  }
}

export {
  detailButton,
  sendAt,
  imageThumbnail,
  titleAndDescription,
  linkButton,
  userGrades,
  successCountPerTotal,
  readCountPerTotal,
}
