import { MTextButton } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelFaq } from 'containers/models/modelFaq'

const categoryText = () => {
  return {
    title: '구분',
    dataIndex: 'category',
    key: 'category',
    width: 50,
    render: (_: string, record: IModelFaq) => {
      return <p>{record.category}</p>
    },
  }
}

const title = ({ setFaqDetail }: { setFaqDetail: Function }): ITableColumn<any> => {
  return {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    width: 200,
    render: (_: string, record: IModelFaq) => {
      return (
        <MTextButton
          onClick={async () => {
            await setFaqDetail(record)
          }}
        >
          {record.title}
        </MTextButton>
      )
    },
  }
}

export {
  title, // 제목,
  categoryText, // 구분
}
