import { Space, Form, Modal } from 'antd'
import { IModelCancelReason } from 'containers/models/modelCancelReason'
import styled from 'styled-components'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import * as utilForm from '@libs/utils/utilForm'
import { MModal, MFormItemSelect, MFormItemTextArea, MFormItemInput } from 'components'
import { DomainModalProps } from 'containers/hooks/useModal'
import { Text14Normal } from 'components/@atomics'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import MMutation from 'components/MMutation'
import MQuery from 'components/MQuery'
import { MutationFunction } from '@apollo/client'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { TableOrderItemDefault } from 'domains/common/tables'
import { useState } from 'react'
import { MUTATION_KEY2GQLS } from 'containers/gqls'

export type ModalDenyWithMessageProps = {
  orderItems: IModelOrderItem[]
  gqlKey: string
}

const FORM_DENY_WITH_MESSAGE_ITEM_OPTIONS = {
  CANCEL_GUIDE_MESSAGE: {
    name: 'cancelGuideMessage',
    textAreaProps: {
      placeholder: '구매자에게 전하실 내용',
      maxLength: 1024,
      showCount: true,
      autoSize: { minRows: 5 },
    },
  },
}

const settings: { [index: string]: any } = {
  cancelOrderItems: {
    title: '판매취소',
    description: `* 이미 배송이 시작된 주문건은 송장번호 입력 후 [배송관리현황] 메뉴에서 반품처리를 진행해주세요.\n
    * 판매 취소 완료 시 자동 환불되며, 자동환불이 불가한 주문건은 핏펫몰 담당자가 직접 환불처리를 진행합니다.\n
    * 판매취소 사유가 다를 경우 각각 취소해주세요.
    `,
    variablesFormat: MUTATION_VARIABLES.CANCEL_ORDER_ITEMS,
    placeholder: '판매취소 사유 입력',
    cascadedModalProps: {
      title: '판매 취소 안내',
      content: '판매 취소 시 되돌릴 수 없습니다.\n계속하시겠습니까?',
      cancelText: '취소',
      okText: '확인',
    },
  },
}

const ModalDenyWithMessage = ({
  useModalProps,
  onAPISuccess,
}: DomainModalProps<ModalDenyWithMessageProps, undefined>) => {
  const { orderItems, gqlKey } = useModalProps.data
  if (!gqlKey) return null
  if (!orderItems || orderItems.length <= 0) return null

  const { confirm } = Modal
  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState()
  const [showRequestMessage, setShowRequestMessage] = useState(false)
  const setting = settings[gqlKey]
  const { title, description, variablesFormat, placeholder, cascadedModalProps } = setting

  const onFinish = async (values: any, mutation: MutationFunction) => {
    const _ = orderItems.map((orderItem) => ({ orderItem: orderItem.id }))
    const variables = variablesFormat({
      orderItems: _,
      cancelGuideMessage: values.cancelGuideMessage,
      requestType: values.requestType,
      requestMessage: values.requestMessage,
    })
    // console.log('variables', variables)
    await mutation({ variables })
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo)
  }

  const onAction = async () => {
    setFormValues(await form.validateFields())
    if (cascadedModalProps) {
      confirm({
        ...cascadedModalProps,
        onCancel: () => useModalProps.showModal(),
        onOk: () => form.submit(),
      })
    } else {
      form.submit()
    }
  }

  const Content = () => {
    return (
      <MMutation
        gqlKey={gqlKey as keyof typeof MUTATION_KEY2GQLS}
        onAPISuccess={(res) => {
          if (onAPISuccess) {
            onAPISuccess(res)
            useModalProps.hideModal()
          }
        }}
        onAPIError={() => useModalProps.hideModal()}
      >
        {(mutation: MutationFunction) => {
          return (
            <Form
              form={form}
              onFinish={async () => {
                await onFinish(formValues, mutation)
              }}
              onFinishFailed={onFinishFailed}
            >
              <StyledSpace direction="vertical" size="middle">
                <Text14Normal>{description}</Text14Normal>
                <TableOrderItemDefault orderItems={orderItems} />
                <MQuery queryParams={{ gqlKey: 'orderItemCancelReasons' }}>
                  {({ data }) => {
                    const cancelReasons: IModelCancelReason[] = data.orderItemCancelReasons.data
                      .cancelReasons as IModelCancelReason[]
                    return (
                      <MFormItemSelect
                        required
                        label="판매취소 사유"
                        labelCol={{ span: 4 }}
                        placeholder={placeholder}
                        name="requestType"
                        selectOptions={utilForm.makeSelectOptions(
                          cancelReasons
                            .filter((x) => x.requestType !== 'AUTO_VBANK')
                            .map((x) => {
                              return { title: x.requestMessage, value: x.requestType }
                            })
                        )}
                        onChange={(value) => {
                          setShowRequestMessage(value === 'ETC')
                          if (value !== 'ETC') {
                            form.setFieldsValue({ requestMessage: '' })
                          }
                        }}
                      />
                    )
                  }}
                </MQuery>
                {showRequestMessage && (
                  <MFormItemInput name="requestMessage" label="기타 사유" labelCol={{ span: 4 }} />
                )}
                {gqlKey === 'cancelOrderItems' && (
                  <MFormItemTextArea {...FORM_DENY_WITH_MESSAGE_ITEM_OPTIONS.CANCEL_GUIDE_MESSAGE} />
                )}
              </StyledSpace>
            </Form>
          )
        }}
      </MMutation>
    )
  }

  return (
    <MModal
      title={title}
      useModalProps={useModalProps}
      ComponentContent={<Content />}
      onAction={onAction}
      width={800}
    />
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default ModalDenyWithMessage
