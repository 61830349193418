import { IModelBrandDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelBrandDefault } from 'containers/models/base/defaultModels'
import { IModelStatus } from 'containers/models/modelStatus'

export interface IModelBrand extends IModelBrandDefault {
  updateStatus: IModelStatus
  status: IModelStatus
}

export default class ModelBrand extends ModelBrandDefault {
  constructor(data: IModelBrand) {
    super(data)
  }
}
