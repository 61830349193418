import { FORM_ITEM_RULES } from '@constants/constForm'
import { Space, Form } from 'antd'
import { IModelReturnReason } from 'containers/models/modelReturnReason'
import styled from 'styled-components'
import { Store, ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { MModal, MFormItemHidden, MFormItemSelect, MFormItemTextArea, MFormItemInput } from 'components'
import { DomainModalProps, TypeUseModalProps } from 'containers/hooks/useModal'
import { Text14Normal } from 'components/@atomics'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import MMutation from 'components/MMutation'
import MQuery from 'components/MQuery'
import { MutationFunction } from '@apollo/client'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import * as utilData from '@libs/utils/utilData'
import DescOrderItem from 'domains/common/descriptions/DescOrderItem'
import _ from 'lodash'
import { useState } from 'react'

export type ModalProps = {
  orderItems: IModelOrderItem[]
  gqlKey: string
}

const FORM_MODAL_PROCESS_CUSTOMER_ADDRESS_ORDER_ITEM_OPTIONS = {
  GUIDE_MESSAGE: {
    name: 'guideMessage',
    textAreaProps: {
      placeholder: '구매자에게 전하실 내용',
      maxLength: 1024,
      showCount: true,
      autoSize: { minRows: 5 },
    },
  },
}

const settings: { [index: string]: any } = {
  returnOrderItems: {
    title: '반품처리',
    description: `* 본인들의 반품프로세스를 주문자 또는 수령자에게 직접 안내해주세요 (반품배송지 안내 및 수거방법 등)
    * 구매자 책임인 경우 반품배송비는 자동적으로 결제금액에서 차감되니, 구매자에게 별도로 반품비를 요청하지 말아주세요.
    * 확인 버튼 클릭시 [반품수거중] 상태로 변경되며, 이후 프로세스는 [반품 관리]에서 진행가능합니다.`,
    variablesFormat: MUTATION_VARIABLES.REQUEST_RETURN_ORDER_ITEMS,
  },
}

type ModalReturnOrderItemProps<T = undefined> = DomainModalProps<T, undefined> & {
  useModalDaumPostcodeProps: TypeUseModalProps<T>
}

let targetSearchAddress: string

const ModalReturnOrderItem = ({
  useModalProps,
  useModalDaumPostcodeProps,
  onAPISuccess,
}: ModalReturnOrderItemProps<ModalProps>) => {
  const { orderItems, gqlKey } = useModalProps.data
  if (!gqlKey) return null
  if (!utilData.hasData(orderItems)) return null

  // console.log('gqlKey=', gqlKey)
  const [showRequestMessage, setShowRequestMessage] = useState(false)
  const [form] = Form.useForm()
  const setting = settings[gqlKey]
  const { shippingPreset } = orderItems[0].productOption.product
  const { order } = orderItems[0]
  const { title, description, variablesFormat } = setting

  const { setActionParams, actionParams } = useModalDaumPostcodeProps
  if (utilData.hasData(actionParams)) {
    if (targetSearchAddress) {
      form.setFieldsValue({ [targetSearchAddress]: actionParams })
      setActionParams({})
    }
  }

  const initialValues = {
    customerAddress: {
      ...order.customerAddress,
      senderName: order.customerAddress.name,
    },
    sellerAddress: shippingPreset.returnAddress,
  }

  const onFinish = (values: Store, mutation: MutationFunction) => {
    const variables = variablesFormat({
      orderItems: orderItems.map((orderItem) => ({
        orderItem: orderItem.id,
      })),
      ...values,
    })
    // console.log(variables)
    mutation({ variables })
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo)
  }

  const Content = () => {
    return (
      <MMutation gqlKey={gqlKey as 'returnOrderItems'} onAPISuccess={onAPISuccess}>
        {(mutation: MutationFunction) => {
          return (
            <Form
              form={form}
              onFinish={(values) => onFinish(values, mutation)}
              onFinishFailed={onFinishFailed}
              initialValues={initialValues}
            >
              <StyledSpace direction="vertical" size="middle">
                <Text14Normal>{description}</Text14Normal>
                {orderItems.map((orderItem) => (
                  <DescOrderItem orderItem={orderItem} />
                ))}
                <MQuery queryParams={{ gqlKey: 'orderItemReturnReasons' }}>
                  {({ data }) => {
                    const reasons = data.orderItemReturnReasons.data.returnReasons as IModelReturnReason[]
                    const onChange = (v: string) => {
                      const index = _.findIndex(reasons, (x) => {
                        return x.requestType === v
                      })
                      form.setFieldsValue({ responsibility: reasons[index].responsibility })
                      setShowRequestMessage(v === 'ETC')
                      if (v !== 'ETC') {
                        form.setFieldsValue({ requestMessage: '' })
                      }
                    }
                    return (
                      <>
                        <MFormItemSelect
                          required
                          label="반품 사유"
                          labelCol={{ span: 4 }}
                          name="requestType"
                          selectOptions={reasons.map((x) => ({
                            title: `${x.requestMessage}(${x.responsibilityText})`,
                            value: x.requestType,
                          }))}
                          onChange={onChange}
                          rules={[FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED]}
                        />
                        <MFormItemHidden name="responsibility" />
                      </>
                    )
                  }}
                </MQuery>
                {showRequestMessage && (
                  <MFormItemInput name="requestMessage" label="기타 사유" labelCol={{ span: 4 }} />
                )}
                <MFormItemTextArea {...FORM_MODAL_PROCESS_CUSTOMER_ADDRESS_ORDER_ITEM_OPTIONS.GUIDE_MESSAGE} />
              </StyledSpace>
            </Form>
          )
        }}
      </MMutation>
    )
  }

  return <MModal form={form} title={title} useModalProps={useModalProps} ComponentContent={<Content />} />
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default ModalReturnOrderItem
