// https://github.com/piotrwitek/typesafe-actions
// https://jonir227.github.io/develop/2019/10/13/Typescript%EC%97%90%EC%84%9C-redux-actions-%EB%8C%80%EC%B2%B4%ED%95%98%EA%B8%B0.html

import _ from 'lodash'
import produce from 'immer'
import { createAction, handleActions } from 'redux-actions'

import { FIELD_KEYS } from '@constants/constData'
import * as utilData from '@libs/utils/utilData'
import { userRoleProps } from 'containers/hooks/useUserInfo'
import { IModelCategory } from 'containers/models/modelCategory'

const CHANGE_PAGE_LIST_DATA = 'ui/CHANGE_PAGE_LIST_DATA'
const INITIALIZE_FORM_FILTER = 'ui/INITIALIZE_FORM_FILTER'
const INITIALIZE_MAIN_TABLE_VARIABLES = 'ui/INITIALIZE_MAIN_TABLE_VARIABLES'
const REFETCH_MAIN_TABLE = 'ui/REFETCH_MAIN_TABLE'
const SET_MAIN_TABLE_FILTERS = 'ui/SET_MAIN_TABLE_FILTERS'
const SET_MAIN_TABLE_INITIAL_VARIABLES = 'ui/SET_MAIN_TABLE_INITIAL_VARIABLES'
const SET_MAIN_TABLE_SORT = 'ui/SET_MAIN_TABLE_SORT'
const SET_MAIN_TABLE_VARIABLES = 'ui/SET_MAIN_TABLE_VARIABLES'
const SET_SELECTED_CATEGORIES = 'ui/SET_SELECTED_CATEGORIES'
const SET_USER_ROLE = 'ui/SET_USER_ROLE'
const INITIALIZE_ROW_SELECTION = 'ui/INITIALIZE_ROW_SELECTION'

export const doChangePageListData = createAction(CHANGE_PAGE_LIST_DATA, (listData: ListDataProps) => listData)
export const doInitializeFormFilter = createAction(INITIALIZE_FORM_FILTER)
export const doInitializeMainTableVariables = createAction(INITIALIZE_MAIN_TABLE_VARIABLES)
export const doRefetchMainTable = createAction(REFETCH_MAIN_TABLE)
export const doSetMainTableFilters = createAction(SET_MAIN_TABLE_FILTERS, (filter: { [key: string]: any }) => filter)
export const doSetMainTableInitialVariables = createAction(
  SET_MAIN_TABLE_INITIAL_VARIABLES,
  (variables: any) => variables
)
export const doSetMainTableSort = createAction(SET_MAIN_TABLE_SORT, (orderBy: string) => orderBy)
export const doSetMainTableVariables = createAction(SET_MAIN_TABLE_VARIABLES, (variables: any) => variables)
export const doSetSelectedCategories = createAction(SET_SELECTED_CATEGORIES, (category: IModelCategory) => category)
export const doSetUserRole = createAction(SET_USER_ROLE, (userRole: userRoleProps | undefined) => userRole)
export const doInitializeRowSelection = createAction(INITIALIZE_ROW_SELECTION)

export const listTypes = {
  DEFAULT: 'DEFAULT',
  BY_TITLE: 'BY_TITLE',
  BY_PRODUCT_COUNT: 'BY_PRODUCT_COUNT',
  BY_SEARCH: 'BY_SEARCH',
}

type ListDataProps = {
  listType: string
  title?: string
  searchWord?: string
}

export type ListStatusFilterProps = {
  [key: string]: any
  status: string
  requestType?: string
}

export type ReduxUIState = {
  userRole?: userRoleProps
  initFormFilter: boolean
  initRowSelection: boolean
  listData: ListDataProps
  listStatusFilter: ListStatusFilterProps
  mainTableInitialVariables: any
  mainTableVariables: any
  refetchMainTableToggle: boolean
  selectedCategories: Array<IModelCategory>
  tablePagination: object
}

export const initialState: ReduxUIState = {
  userRole: undefined,
  initFormFilter: false,
  initRowSelection: false,
  listData: { listType: listTypes.DEFAULT, title: '', searchWord: '' },
  listStatusFilter: { [FIELD_KEYS.FIELD_STATUS]: FIELD_KEYS.FIELD_TOTAL },
  mainTableInitialVariables: {},
  mainTableVariables: {},
  refetchMainTableToggle: false,
  selectedCategories: [],
  tablePagination: {},
}

export default handleActions<ReduxUIState, any>(
  {
    [SET_USER_ROLE]: (state, { payload: userRole }) =>
      produce(state, (draft) => {
        draft.userRole = userRole
      }),
    [INITIALIZE_MAIN_TABLE_VARIABLES]: (state) =>
      produce(state, (draft) => {
        draft.mainTableVariables = draft.mainTableInitialVariables
      }),
    [SET_MAIN_TABLE_INITIAL_VARIABLES]: (state, { payload: variables }) =>
      produce(state, (draft) => {
        draft.mainTableInitialVariables = variables
        draft.mainTableVariables = variables
      }),
    [SET_MAIN_TABLE_VARIABLES]: (state, { payload: variables }) =>
      produce(state, (draft) => {
        draft.mainTableVariables = variables
      }),
    [SET_MAIN_TABLE_SORT]: (state, { payload: orderBy }) =>
      produce(state, (draft) => {
        draft.mainTableVariables = {
          ...draft.mainTableInitialVariables,
          orderBy,
        }
      }),
    [SET_MAIN_TABLE_FILTERS]: (state, { payload: filter }) =>
      produce(state, (draft) => {
        draft.mainTableVariables = {
          ...draft.mainTableInitialVariables,
          filter,
        }
      }),
    [INITIALIZE_FORM_FILTER]: (state) =>
      produce(state, (draft) => {
        draft.initFormFilter = !draft.initFormFilter
      }),
    [REFETCH_MAIN_TABLE]: (state) =>
      produce(state, (draft) => {
        draft.refetchMainTableToggle = !draft.refetchMainTableToggle
        // clean undefined fields
        draft.listStatusFilter = utilData.cleanObject(draft.listStatusFilter) as ListStatusFilterProps
      }),
    [CHANGE_PAGE_LIST_DATA]: (state, { payload: listData }) =>
      produce(state, (draft) => {
        draft.listData = listData
      }),
    [SET_SELECTED_CATEGORIES]: (state, { payload: tag }) =>
      produce(state, (draft) => {
        const equalCategoryIndex = _.findIndex(draft.selectedCategories, { id: tag.id })
        if (equalCategoryIndex > -1) {
          // 동일한 레벨의 카테고리 선택시, 하위 모든 자식 카테고리들 삭제
          draft.selectedCategories = _.slice(draft.selectedCategories, 0, equalCategoryIndex + 1)
        } else {
          // 하위 레벨의 카테고리 선택시, 카테고리 추가
          const index = _.findIndex(draft.selectedCategories, (selectedCategory) => {
            if (selectedCategory.parentCategory?.id === tag.parentCategory.id) {
              return true
            }
            return _.isEqual(selectedCategory.parentCategory, tag.parentCategory)
          })
          if (index > -1) {
            draft.selectedCategories = _.slice(draft.selectedCategories, 0, index)
            draft.selectedCategories.push(tag)
          } else {
            draft.selectedCategories.push(tag)
          }
        }
      }),
    [INITIALIZE_ROW_SELECTION]: (state) =>
      produce(state, (draft) => {
        draft.initRowSelection = !draft.initRowSelection
      }),
  },
  initialState
)
