import styled from 'styled-components'
import { Form, Space, message as antdMessage } from 'antd'
import { MutationFunction } from '@apollo/client'

import { FORM_ITEM_RULES } from '@constants/constForm'
import { FIELD_KEYS } from '@constants/constData'
import { MFormItemInput } from 'components/formItems'
import { MModal } from 'components/modals'
import { DomainModalProps } from 'containers/hooks/useModal'
import MMutation from 'components/MMutation'
import { Text14Orange } from 'components/@atomics'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { MModalProps } from 'components/modals/MModal'
import useUserInfo from 'containers/hooks/useUserInfo'

const ModalChangePassword = ({ useModalProps }: DomainModalProps<MModalProps<any>, undefined>) => {
  const whoamiData = useUserInfo.useWhoamiData()
  if (!whoamiData?.user?.data?.id) return null
  const id = whoamiData?.user?.data?.id
  const [form] = Form.useForm()
  const { hideModal } = useModalProps

  const { getFieldValue } = form
  const ruleCheckConfirmPassword = FORM_ITEM_RULES.FORM_ITEM_RULE_VALUE_CONDITIONAL_REQUIRED({
    getFieldValue,
    key: FIELD_KEYS.FIELD_CONFIRM_PASSWORD,
    msg: '비밀번호를 입력해주세요',
  })
  const ruleCheckPassword = FORM_ITEM_RULES.FORM_ITEM_RULE_VALUE_CONDITIONAL_REQUIRED({
    getFieldValue,
    key: FIELD_KEYS.FIELD_PASSWORD,
    msg: '비밀번호를 다시 한번 입력해주세요',
  })

  const onFinish = async (values: { newPassword: string; oldPassword: string }, mutation: MutationFunction) => {
    // console.log(values)
    const { newPassword, oldPassword } = values
    const variables = MUTATION_VARIABLES.UPDATE_USER_PASSWORD_BY_FITPET_ADMIN({ id, newPassword, oldPassword })
    await mutation({ variables })
    hideModal()
  }

  const onAPISuccess = () => {
    antdMessage.success('비밀번호가 변경되었습니다.')
  }

  const Content = () => {
    return (
      <MMutation gqlKey="updateUserPasswordByFitpetAdmin" onAPISuccess={onAPISuccess}>
        {(mutation: MutationFunction) => {
          return (
            <Form form={form} onFinish={(values) => onFinish(values, mutation)}>
              <StyledSpace direction="vertical" size="middle">
                <MFormItemInput
                  name="oldPassword"
                  type="password"
                  placeholder="이전 비밀번호"
                  rules={[FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED]}
                />
                <MFormItemInput
                  name="newPassword"
                  type="password"
                  placeholder="새로운 비밀번호"
                  extra={
                    <Text14Orange>
                      * 영문대문자/영문소문자/숫자/특수문자 중 2개 이상 포함, 10~16자리 이하로 설정할 수 있습니다.
                    </Text14Orange>
                  }
                  rules={[
                    FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED,
                    FORM_ITEM_RULES.FORM_ITEM_RULE_PASSWORD_FORMAT,
                    ruleCheckConfirmPassword,
                  ]}
                />
                <MFormItemInput
                  name="confirmPassword"
                  type="password"
                  placeholder="새로운 비밀번호 확인"
                  rules={[
                    FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED,
                    FORM_ITEM_RULES.FORM_ITEM_RULE_CONFIRM_PASSWORD,
                    ruleCheckPassword,
                  ]}
                />
              </StyledSpace>
            </Form>
          )
        }}
      </MMutation>
    )
  }

  return (
    <MModal
      title="비밀번호 변경"
      width={400}
      useModalProps={useModalProps}
      ComponentContent={<Content />}
      form={form}
    />
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default ModalChangePassword
