import React from 'react'
import { UpCircleOutlined } from '@ant-design/icons'
import MIconButton from 'components/@atomics/buttons/MIconButton'

type MArrowUpButtonProps = {
  onClick: () => void
}

const MArrowUpButton: React.FC<MArrowUpButtonProps> = (props) => {
  return <MIconButton {...props} Icon={UpCircleOutlined} />
}

export default MArrowUpButton
