import useMQuery from 'containers/hooks/useMQuery'
import { QueryHookOptions } from '@apollo/client/react/types/types'

export const useServerConfig = (configType?: string) => {
  const option: QueryHookOptions = {
    fetchPolicy: 'no-cache',
  }
  const filter = { configType }
  const { data, refetch } = useMQuery('serverConfigs', { filter }, undefined, option)

  const serverConfigs = data && data.serverConfigs ? data.serverConfigs.data : []

  return {
    serverConfigs,
    refetch,
  }
}

export default useServerConfig
