import { IModelStatusHistoryDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelStatusHistoryDefault } from 'containers/models/base/defaultModels'
import { IModelStatus } from 'containers/models/modelStatus'

export interface IModelStatusHistory extends IModelStatusHistoryDefault {
  status: IModelStatus
  name: string
}

export default class ModelStatusHistory extends ModelStatusHistoryDefault {
  constructor(data: IModelStatusHistory) {
    super(data)
  }
}
