import { queryParamsProps } from 'components/MQuery'
import { ReactElement } from 'react'
import MMainTable, { MMainTableProps } from 'components/MMainTable'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelSellerStatisticColumnDefault } from 'containers/models/base/defaultModelInterfaces'
import tableCols from 'domains/admin/tableCols'
import { MListSortSelect } from 'components'

const tableColumns: ITableColumn<IModelSellerStatisticColumnDefault>[] = [
  tableCols.common.baseText({ title: '셀러명', dataIndex: 'sellerName', width: 140, fixed: true }),
  tableCols.common.baseText({ title: '브랜드명', dataIndex: 'brandName', width: 140, fixed: true }),
  tableCols.currency.currencyText({ title: '결제금액', dataIndex: 'totalAmount' }),
  tableCols.currency.currencyText({ title: '판매가', dataIndex: 'totalSoldAmount' }),
  tableCols.currency.currencyText({ title: '쿠폰', dataIndex: 'totalCouponDiscountAmount' }),
  // tableCols.statistics.totalMileageDiscountAmount(),
  tableCols.common.baseText({ title: '구매자수', dataIndex: 'totalCustomerCount', width: 120 }),
  tableCols.common.baseText({ title: '주문수', dataIndex: 'totalOrderCount', width: 120 }),
  tableCols.common.baseText({ title: '구매상품수', dataIndex: 'totalSoldCount', width: 120 }),
  tableCols.currency.currencyText({ title: '객단가(판매가/주문수)', dataIndex: 'guestUnitPrice', width: 160 }),
  tableCols.currency.currencyText({ title: '평균 상품금액', dataIndex: 'avgProductPrice' }),
]

const MainTableStatisticSeller = (): ReactElement<MMainTableProps> => {
  const queryParams: queryParamsProps = {
    gqlKey: 'sellerAnalysis',
    dataKey: 'sellerSalesStatistics',
    variables: { orderBy: '-totalAmount' },
  }

  return (
    <MMainTable
      TitleSet={{ title: '셀러 분석', unit: '건' }}
      columns={tableColumns}
      queryParams={queryParams}
      HeaderRightButtons={[
        <MListSortSelect filterOptions={['FIELD_SORT_TOTAL_AMOUNT_DESC', 'FIELD_SORT_TOTAL_AMOUNT']} />,
      ]}
      multipleHeaderRow
    />
  )
}

export default MainTableStatisticSeller
