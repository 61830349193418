import _ from 'lodash'
import { useRef } from 'react'
import { listVariablesProps } from 'components/MQuery'
import { DEFAULT_PAGE_SIZE, FIELD_KEYS, TABLE_PAGINATION_CONFIG } from '@constants/constData'
import usePageInfoRef from 'containers/hooks/usePageInfoRef'
import { TablePaginationConfig } from 'antd/lib/table'
import * as utilData from '@libs/utils/utilData'

const useTable = () => {
  // to use 'getTablePageInfoVariables' function statically
  const { getTablePageInfoVariables: _getTablePageInfoVariables } = usePageInfoRef()
  const variablesRef = useRef<listVariablesProps | undefined>(undefined)
  const currentPageRef = useRef<number>(1)
  const pageSizeRef = useRef<number>(DEFAULT_PAGE_SIZE)
  const refetchRef = useRef<Function | null>(null)

  const setPageSize = (pageSize: number) => {
    pageSizeRef.current = pageSize
  }

  const setCurrentPage = (page: number) => {
    currentPageRef.current = page
  }

  const setVariables = (variables?: listVariablesProps) => {
    variablesRef.current = variables
  }

  const setInitVariablesAndReturn = (variables?: listVariablesProps) => {
    // 이미 variable 셋팅이 된 경우 다시 초기값을 세팅하지 않음
    if (variablesRef.current) return getVariables()
    setPageSize(10)
    setVariables(variables)
    return getVariables()
  }

  const setRefetch = (refetch: Function) => {
    refetchRef.current = refetch
  }

  const setFilterAndDoRefetch = (variables: object) => {
    const prevVariables = variablesRef.current
    const prevFilter = prevVariables?.filter || {}

    if (_.has(variables, FIELD_KEYS.FIELD_CATEGORIES)) {
      let categories: { value: string; label: string }[]
      // @ts-ignore
      if (utilData.hasData(variables[FIELD_KEYS.FIELD_CATEGORIES])) {
        // @ts-ignore
        categories = variables[FIELD_KEYS.FIELD_CATEGORIES].map((category) => category.value)
        _.set(variables, FIELD_KEYS.FIELD_CATEGORIES, categories)
      }
    }

    setCurrentPage(1)
    setVariables({ filter: { ...prevFilter, ...variables } })
    if (refetchRef && refetchRef.current) refetchRef.current(getVariables())
  }

  const onPageChange = (page: number) => {
    setCurrentPage(page)
    if (refetchRef && refetchRef.current) refetchRef.current(getVariables())
  }

  const getTablePagination = (pagination: false | TablePaginationConfig, totalCount?: number) => {
    if (!pagination) return false
    return {
      ...TABLE_PAGINATION_CONFIG,
      ...pagination,
      pageSize: pageSizeRef.current,
      onChange: (page: number) => onPageChange(page),
      current: currentPageRef.current,
      total: totalCount || 0,
    }
  }

  const getVariables = (): listVariablesProps => {
    const pageInfo = getTablePageInfoVariables()
    return {
      ...variablesRef.current,
      ...pageInfo,
    }
  }

  const getTablePageInfoVariables = () => {
    return _getTablePageInfoVariables(currentPageRef.current, pageSizeRef.current)
  }

  return {
    getTablePagination,
    getTablePageInfoVariables,
    setVariables,
    setInitVariablesAndReturn,
    setRefetch,
    setFilterAndDoRefetch,
  }
}

export default useTable
