import * as utilData from '@libs/utils/utilData'
import { IModelReturnInfo } from 'containers/models/modelReturnInfo'
import { FC, MutableRefObject } from 'react'
import { Row, Col, Typography, Divider, Descriptions, message } from 'antd'
import { DomainModalProps } from 'containers/hooks/useModal'
import { Text12Normal, Text14Normal, Text14Red, Text16Normal } from 'components/@atomics/index'
import { ModalConfirm } from 'domains/common/modals/index'
import { MConfirmModalProps } from 'components/modals/MConfirmModal'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { TableOrderItemReturn } from 'domains/common/tables/index'
import { LinkButtonSellerSupport } from 'domains/common'

const { Paragraph } = Typography

type ConfirmModalProps = MConfirmModalProps & {
  returnInfos?: IModelReturnInfo[]
}

type ActionParamsProps = {
  returnInfo: IModelReturnInfo
  orderItems: IModelOrderItem[]
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}

const ModalAcceptExchangeReturnOrder: FC<DomainModalProps<ConfirmModalProps, undefined>> = ({ useModalProps }) => {
  if (!useModalProps.actionParams) return null

  const { returnInfo, orderItems, refetchRef, statusFilterRefetchRef }: ActionParamsProps = useModalProps.actionParams

  if (!utilData.hasData(orderItems)) return null

  const paymentAmount = orderItems!.reduce((prev, next) => {
    return prev + next.amount
  }, 0)
  const returnShippingFee =
    returnInfo.responsibility === 'CUSTOMER'
      ? orderItems!.reduce((prev, next) => {
          return prev > next.returnShippingFee ? prev : next.returnShippingFee
        }, 0)
      : 0

  const RenderContent = () => {
    const { requestMessage = '-' } = returnInfo
    return (
      <>
        <Row>
          <Col>
            <Text14Normal>상품회수는 판매자님께서 직접 택배사에 접수해 주세요.</Text14Normal>
            <br />
            <Text14Red bold>셀러는 고객에게 배송비를 직접 청구하지 않습니다.</Text14Red>
          </Col>
        </Row>

        <Divider />
        <TableOrderItemReturn orderItems={orderItems!} />
        <br />

        <Row>
          <Col>
            <Descriptions bordered column={1} size="small">
              <Descriptions.Item label="주문자 정보" labelStyle={{ fontWeight: 'bold' }}>
                {returnInfo.customerAddress
                  ? `${returnInfo.customerAddress?.name} / ${returnInfo.customerAddress?.mobileNumber}`
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="수거지 정보" labelStyle={{ fontWeight: 'bold' }}>
                {returnInfo.customerAddress
                  ? `${returnInfo.customerAddress?.basicAddress} ${returnInfo.customerAddress?.detailedAddress}`
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="교환 사유" labelStyle={{ fontWeight: 'bold' }}>
                {requestMessage}
              </Descriptions.Item>
              <Descriptions.Item label="금액 정보" labelStyle={{ fontWeight: 'bold' }}>
                <Descriptions bordered column={1} size="small">
                  <Descriptions.Item label="결제금액" labelStyle={{ fontWeight: 'bold' }}>
                    {utilData.currencyText(paymentAmount)}
                  </Descriptions.Item>
                  <Descriptions.Item label="반품배송비" labelStyle={{ fontWeight: 'bold' }}>
                    {utilData.currencyText(returnShippingFee)}
                  </Descriptions.Item>
                </Descriptions>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Divider />

        <Row gutter={[8, 8]} style={{ marginTop: 20 }}>
          <Col>
            <Text16Normal bold>잠깐! 교환 진행 전 아래 주의 사항을 확인해 주세요.</Text16Normal>
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          <Col>
            <Paragraph>
              <ul>
                <li>
                  <Row>
                    <Col>
                      <Text14Normal>교환 귀책 사유가 판매자 책임일 경우 배송비는 판매자가 부담합니다.</Text14Normal>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col>
                      <Text14Red bold>
                        교환 귀책 사유가 구매자 책임이고 배송비를 별도 청구해야 하는 경우에는 핏펫 셀러 지원센터로
                        문의해 주세요.
                      </Text14Red>
                      <br />
                      <LinkButtonSellerSupport />
                    </Col>
                  </Row>
                </li>
                <li>
                  <Text14Normal>직접 택배사에 수거 요청을 진행해 주세요.</Text14Normal>
                  <ul>
                    <li>
                      <Text14Normal>
                        반품배송지로 상품을 보내지 않을 경우 고객님께 택배비 부담이 있음을 안내해야 합니다.
                      </Text14Normal>
                    </li>
                    <li>
                      <Text14Normal>
                        이를 제대로 안내하지 않아 잘못된 배송지로 반품이 되면 해당 배송비는 판매자님이 부담해야 합니다.
                      </Text14Normal>
                    </li>
                  </ul>
                </li>
              </ul>
            </Paragraph>
          </Col>
        </Row>

        <Divider />
        <Row>
          <Col>
            <Paragraph>
              <ul>
                <li>
                  <Text12Normal>확인 버튼 클릭시 교환수거중 상태로 변경됩니다.</Text12Normal>
                </li>
                <li>
                  <Text12Normal>
                    교환사유 및 반품배송비는 상품 검수 후 교환 완료 단계에서 변경하실 수 있습니다.
                  </Text12Normal>
                </li>
              </ul>
            </Paragraph>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <ModalConfirm
      useModalProps={{ width: 800, maskClosable: false, ...useModalProps }}
      onAPISuccess={async () => {
        if (refetchRef && refetchRef.current) {
          refetchRef.current()
        }
        if (statusFilterRefetchRef && statusFilterRefetchRef.current) {
          statusFilterRefetchRef.current()
        }
        await message.success('교환 접수 처리에 성공하였습니다.')
      }}
      fixedProps={{
        mutationKey: {
          gqlKey: 'acceptExchangeReturnInfos',
          variableKey: 'acceptExchangeReturnInfosRequest',
        },
        disableContent: '주의사항을 고객에게 안내하셨나요?',
        title: '교환 접수 처리',
        description: '',
        componentOption: {
          Component: <RenderContent />,
        },
      }}
    />
  )
}

export default ModalAcceptExchangeReturnOrder
