import { IModelCancelReasonDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelCancelReasonDefault } from 'containers/models/base/defaultModels'

export interface IModelCancelReason extends IModelCancelReasonDefault {}

export default class ModelCancelReason extends ModelCancelReasonDefault {
  constructor(data: IModelCancelReason) {
    super(data)
  }
}
