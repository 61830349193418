import { gql } from '@apollo/client'

export const SKU_MUTATION_GQLS = {
  CREATE_SKUS_BY_EXCEL: gql`
    mutation createSkusByExcel($input: BaseImportByExcelInput!) {
      createSkusByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  UPDATE_SKUS_BY_EXCEL: gql`
    mutation updateSkusByExcel($input: BaseImportByExcelInput!) {
      updateSkusByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  REQUEST_SKU_EXCEL_FILE: gql`
    mutation requestSkuExcelFile($input: RequestExcelFileInput!) {
      requestSkuExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  CREATE_OR_UPDATE_SKUS_BY_EXCEL: gql`
    mutation createOrUpdateSkusByExcel($input: BaseImportByExcelInput!) {
      createOrUpdateSkusByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  UPDATE_SKU: gql`
    mutation updateSku($id: ID!, $input: UpdateSkuInput!) {
      updateSku(id: $id, input: $input) {
        sku {
          id
        }
      }
    }
  `,
}

export default SKU_MUTATION_GQLS
