import { DatePicker } from 'antd'
import { antdPickerLocale } from 'components/formItems'
import { DatePickerProps } from 'antd/lib/date-picker'
import { DATE_FORMAT, DATETIME_SECOND_FORMAT } from '@libs/utils/utilDate'

type MDatePickerProps = DatePickerProps & {
  timeFormat?: boolean
}

const MDatePicker = (props: MDatePickerProps) => {
  const { timeFormat = false, ...datePickerProps } = props
  return (
    <DatePicker
      locale={antdPickerLocale}
      format={timeFormat ? DATETIME_SECOND_FORMAT : DATE_FORMAT}
      {...datePickerProps}
    />
  )
}

export default MDatePicker
