import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const COUPON_GROUP_MUTATION_GQLS = {
  CREATE_COUPON_GROUP: gql`
    mutation createCouponGroup($input: CouponGroupInput!) {
      createCouponGroup(input: $input) {
        couponGroup {
          ...couponGroup
        }
      }
    }
    ${DEFAULT_FRAGMENTS.couponGroup}
  `,
  DELETE_COUPON_GROUP: gql`
    mutation deleteCouponGroup($id: ID!) {
      deleteCouponGroup(id: $id) {
        couponGroup {
          ...couponGroup
        }
      }
    }
    ${DEFAULT_FRAGMENTS.couponGroup}
  `,
  DELETE_COUPON_GROUP_WITH_UNUSED_COUPONS: gql`
    mutation deleteCouponGroupWithUnusedCoupons($id: ID!) {
      deleteCouponGroupWithUnusedCoupons(id: $id) {
        couponGroup {
          ...couponGroup
        }
      }
    }
    ${DEFAULT_FRAGMENTS.couponGroup}
  `,
  ISSUE_MANUAL_COUPONS: gql`
    mutation issueManualCoupons($input: IssueManualCouponInput!) {
      issueManualCoupons(input: $input) {
        coupons {
          ...coupon
          couponGroup {
            ...couponGroup
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.coupon}
    ${DEFAULT_FRAGMENTS.couponGroup}
  `,
  UPDATE_COUPON_GROUP: gql`
    mutation updateCouponGroup($id: ID!, $input: CouponGroupInput!) {
      updateCouponGroup(id: $id, input: $input) {
        couponGroup {
          id
        }
      }
    }
  `,
  UPDATE_COUPON_GROUP_STATUS: gql`
    mutation updateCouponGroupStatus($id: ID!, $input: UpdateCouponGroupStatusInput!) {
      updateCouponGroupStatus(id: $id, input: $input) {
        couponGroup {
          id
        }
      }
    }
  `,
}

export default COUPON_GROUP_MUTATION_GQLS
