import { Row, Col } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { Text14Normal, MRequiredMark } from 'components/@atomics'
import * as utilData from '@libs/utils/utilData'

type MArrowEditTextProps = {
  label: string
  originText: string
  editedText?: string
  noRequire?: true
  twoLine?: true
}

export type OptionsArrowTextEditProps = { [index: string]: MArrowEditTextProps }

const MArrowEditText = ({ label, originText, editedText, noRequire, twoLine }: MArrowEditTextProps) => {
  const Arrow: JSX.Element = (
    <StyledArrowCol>
      <ArrowRightOutlined />
    </StyledArrowCol>
  )
  const EditedText: JSX.Element = (
    <Col>
      <Text14Normal>{utilData.tableText(editedText || originText)}</Text14Normal>
    </Col>
  )

  return (
    <>
      <StyledFirstRow>
        <StyledLabelCol>
          {!noRequire && <MRequiredMark />}
          <Text14Normal>{label}</Text14Normal>
        </StyledLabelCol>
        <StyledOriginTextCol>
          <Text14Normal>{utilData.tableText(originText)}</Text14Normal>
        </StyledOriginTextCol>
        {!twoLine && (
          <>
            {Arrow}
            {EditedText}
          </>
        )}
      </StyledFirstRow>
      {twoLine && (
        <StyledSecondRow>
          {Arrow}
          {EditedText}
        </StyledSecondRow>
      )}
    </>
  )
}

const StyledFirstRow = styled(Row)`
  margin-bottom: 30px;
`

const StyledSecondRow = styled(Row)`
  margin-left: 140px;
  margin-bottom: 30px;
`

const StyledLabelCol = styled(Col)`
  width: 140px;
`

const StyledArrowCol = styled(Col)`
  margin-right: 20px;
`

const StyledOriginTextCol = styled(Col)`
  width: 300px;
`

export { MArrowEditText as default }
