import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'
import COUPON_GROUP_FRAGMENTS from 'containers/gqls/promotions/couponGroup/fragments'
import USER_FRAGMENTS from 'containers/gqls/accounts/user/fragments'

export const COUPON_GROUP_QUERY_GQLS = {
  COUPON_GROUPS: gql`
    query couponGroups($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      couponGroups(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...couponGroupNode
            informKakaoQueue {
              ...informKakaoQueue
            }
          }
          cursor
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${COUPON_GROUP_FRAGMENTS.couponGroupNode}
    ${DEFAULT_FRAGMENTS.informKakaoQueue}
  `,
  COUPON_GROUP: gql`
    query couponGroup($id: ID!) {
      couponGroup(id: $id) {
        ...couponGroup
        fitpetAdmin {
          ...user
        }
        includedBrands {
          ...brandConnection
        }
        includedProducts {
          ...productConnection
        }
        includedCategories {
          ...categoryConnection
        }
        excludedBrands {
          ...brandConnection
        }
        excludedProducts {
          ...productConnection
        }
        excludedCategories {
          ...categoryConnection
        }
        couponGroupCustomerGrades {
          ...customerGradeConnection
        }
        informKakaoQueue {
          ...informKakaoQueue
        }
        ad {
          ...ad
        }
      }
    }
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.informKakaoQueue}
    ${DEFAULT_FRAGMENTS.ad}
  `,
  COUPONS: gql`
    query coupons($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      coupons(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          cursor
          node {
            ...coupon
            user {
              ...userBasicInfo
            }
            couponGroup {
              ...couponGroupNode
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.coupon}
    ${USER_FRAGMENTS.userBasicInfo}
    ${COUPON_GROUP_FRAGMENTS.couponGroupNode}
  `,
  COUPONS_WITHOUT_COVERAGE: gql`
    query couponsWithoutCoverage($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      coupons(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          cursor
          node {
            ...coupon
            user {
              ...userBasicInfo
            }
            couponGroup {
              ...couponGroup
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.coupon}
    ${USER_FRAGMENTS.userBasicInfo}
    ${DEFAULT_FRAGMENTS.couponGroup}
  `,
  USER_COUPON_GROUP_MODAL_TABLE: gql`
    query userCouponGroupModalTable($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      couponGroups(pageInfo: $pageInfo, filter: $filter) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            id
            name
            createdAt
            discountRate
            discountAmount
          }
          cursor
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  USER_COUPON_TABLE: gql`
    query userCouponTable($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      coupons(pageInfo: $pageInfo, filter: $filter) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            id
            createdAt
            startedAt
            expiredAt
            usedStatus
            couponGroup {
              id
              name
              issueType
              issueStartedAt
              issueStatus
              discountType
              discountRate
              discountAmount
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  COUPON_KAKAO_TEMPLATES: gql`
    query couponKakaoTemplates {
      couponKakaoTemplates {
        ...couponKakaoTemplate
      }
    }
    ${DEFAULT_FRAGMENTS.couponKakaoTemplate}
  `,
}

export default COUPON_GROUP_QUERY_GQLS
