import { ModalConfirm } from 'domains/common/modals'
import { FC, ReactElement, useEffect, useRef } from 'react'
import { Row } from 'antd'
import { useRouter } from 'next/router'

import { MMainTableProps } from 'components/MMainTable'
import { queryParamsProps } from 'components/MQuery'
import { MInfoCard, MMainTable } from 'components'
import * as utilData from '@libs/utils/utilData'
import tableCols from 'domains/admin/tableCols'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelCustomerGrade } from 'containers/models/modelCustomerGrade'
import { MButton, MTextButton, Text14Normal } from 'components/@atomics'
import ROUTES from '@constants/constRoutes'
import { useDispatch } from 'react-redux'
import { doSetMainTableSort } from '@libs/redux/reduxUI'
import { DataResult, DataResults } from 'containers/gqls/data'
import useModal from 'containers/hooks/useModal'

const HeaderLeftButton = () => {
  const router = useRouter()
  return (
    <MButton type="primary" onClick={() => router.push({ pathname: ROUTES.ACCOUNTS.CUSTOMER_GRADE_ADD })}>
      회원 등급 등록
    </MButton>
  )
}

const renderColCondition = (
  minOrderAmount: number,
  index: number | undefined,
  customerGrades: IModelCustomerGrade[],
  conditionText: string
) => {
  if (!customerGrades) return <>-</>
  if (conditionText === '수동') return <>{conditionText}</>
  if (!minOrderAmount) return <>없음</>

  const minAmount: string = utilData.tenThousandCurrencyText(minOrderAmount)
  const nextIdx = index ? index + 1 : 1
  if (customerGrades?.length === nextIdx) return <>{utilData.tableText(`${minAmount} 이상`)}</>

  const nextGrade = customerGrades && customerGrades[nextIdx]
  const maxAmount: string = utilData.tenThousandCurrencyText(nextGrade?.minOrderAmount)
  return <>{utilData.tableText(`${minAmount} 이상 ~ ${maxAmount} 미만`)}</>
}

const MainTableCustomerGradeList: FC = (): ReactElement<MMainTableProps> => {
  const router = useRouter()
  const dataRef = useRef<{ [index: string]: DataResults | DataResult } | null>(null)
  const dispatch = useDispatch()
  const refetchRef = useRef<Function | null>(null)

  useEffect(() => {
    dispatch(doSetMainTableSort('minOrderAmount'))
  }, [])

  const tableColumns = (): Array<ITableColumn<IModelCustomerGrade>> => {
    return [
      tableCols.common.baseText({ title: '등급 순서', dataIndex: 'series', width: 80, fixed: true }),
      tableCols.common.baseText({ title: '등급명', dataIndex: 'name', width: 80 }),
      tableCols.customerGrade.gradeUserCount(),
      {
        title: '등급 조건',
        dataIndex: 'minOrderAmount',
        key: 'minOrderAmount',
        width: 300,
        render: (minOrderAmount: number, _, index) =>
          renderColCondition(
            minOrderAmount,
            index,
            dataRef.current?.customerGrades.data as IModelCustomerGrade[],
            _.conditionText
          ),
      },
      tableCols.number.percentText({ title: '포인트 적립', dataIndex: 'rewardRate' }),
      tableCols.customerGrade.coupons(),
      tableCols.customerGrade.activationStatus(),
      tableCols.customerGrade.edit({ router }),
      {
        title: '삭제',
        dataIndex: 'id',
        key: 'delete',
        render: (id: string, record: IModelCustomerGrade) => {
          const { useModalProps } = useModal()
          const hasUserCount: boolean = !!record?.userCount
          if (!hasUserCount) {
            return (
              <>
                <MTextButton
                  onClick={() => {
                    useModalProps.toggleModal()
                    useModalProps.setData({ id })
                  }}
                >
                  [삭제]
                </MTextButton>
                <ModalConfirm
                  onAPISuccess={() => {
                    if (refetchRef && refetchRef.current) {
                      refetchRef.current()
                    }
                  }}
                  useModalProps={useModalProps}
                  fixedProps={{
                    mutationKey: {
                      gqlKey: 'deleteCustomerGrade',
                      variableKey: 'id',
                    },
                    title: '등급 삭제 안내',
                    description: '해당 등급을 삭제합니다.\n등급 삭제 시 관련 정보 전체가 삭제됩니다.',
                    confirmMessage: '등급이 삭제되었습니다',
                  }}
                />
              </>
            )
          }
          return <>-</>
        },
      },
    ]
  }

  const queryParams: queryParamsProps = {
    gqlKey: 'customerGradeTable',
    dataKey: 'customerGrades',
    variables: { orderBy: '-createdAt' },
  }

  return (
    <>
      <MMainTable
        TitleSet={{ title: '회원 등급 목록', unit: '개' }}
        columns={tableColumns()}
        HeaderLeftButtons={[<HeaderLeftButton />]}
        queryParams={queryParams}
        dataRef={dataRef}
        sortBySeries
        scroll={300}
        refetchRef={refetchRef}
      />
      <MInfoCard
        color="blue"
        withIcon={false}
        mainContent={
          <div>
            <Row>
              <Text14Normal>* 회원 등급 삭제 시 주의 사항</Text14Normal>
            </Row>
            <Row>
              <Text14Normal>- 등급이 적용되어 있는 회원이 있는 경우 해당 등급은 삭제할 수 없습니다.</Text14Normal>
            </Row>
            <Row>
              <Text14Normal>- 등급 평가 후 조건에 따라 등급 하향 조정이 가능합니다.</Text14Normal>
            </Row>
          </div>
        }
      />
    </>
  )
}

export default MainTableCustomerGradeList
