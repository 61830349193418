import { RETURN_RESPONSIBILITY_TYPE } from '@constants/constDomain'
import { IModelReturnReasonDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelReturnReasonDefault } from 'containers/models/base/defaultModels'

export interface IModelReturnReason extends IModelReturnReasonDefault {
  responsibilityText: string
}

export default class ModelReturnReason extends ModelReturnReasonDefault {
  responsibilityText: string = ''

  constructor(data: IModelReturnReason) {
    super(data)
    this.responsibilityText = this.getResponsibilityText()
  }

  private getResponsibilityText = (): string => {
    switch (this.responsibility) {
      case RETURN_RESPONSIBILITY_TYPE.ADMIN:
        return '관리자'
      case RETURN_RESPONSIBILITY_TYPE.ALL:
        return '전체'
      case RETURN_RESPONSIBILITY_TYPE.CUSTOMER:
        return '구매자'
      case RETURN_RESPONSIBILITY_TYPE.SELLER:
        return '판매자'
      default:
        return '-'
    }
  }
}
