import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const PRODUCT_QNA_MUTATION_GQLS = {
  ANSWER_PRODUCT_QUESTION: gql`
    mutation answerProductQuestion($id: ID!, $input: AnswerQuestionInput!) {
      answerProductQuestion(id: $id, input: $input) {
        productQna {
          ...productQna
          user {
            id
            name
            email
            mobileNumber
          }
          product {
            id
            name
            seller {
              id
              shopName
            }
          }
          seller {
            id
            shopName
          }
          answeredUser {
            id
            name
            email
            isStaff
            sellers {
              edges {
                node {
                  id
                  shopName
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.productQna}
  `,
  DELETE_PRODUCT_QUESTIONS: gql`
    mutation deleteProductQuestions($input: DeleteQnasInput!) {
      deleteProductQuestions(input: $input) {
        count
      }
    }
  `,
}

export default PRODUCT_QNA_MUTATION_GQLS
