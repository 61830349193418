import { CSSProperties, ReactNode, useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { TabsProps } from 'antd/lib/tabs'
import styled, { CSSObject } from 'styled-components'

import { Text16Normal, Text16Blue } from 'components/@atomics/texts/TextBase'
import { ConditionalWrapper } from '@libs/utils/utilData'

type TabPaneItemProps = {
  title: string
  key?: string
  paneComponent: ReactNode
}

type MTabsProps = TabsProps & {
  defaultActiveKey?: string
  tabPaneStyle?: CSSObject
  initKey?: string
}

// Note yoon: tabPaneItems type을 MTabsProps 선언하면, styled에서 type error를 발생해서, 어쩔 수 없이 이렇게 type 선언함
const MTabs = (props: MTabsProps & { tabPaneItems: TabPaneItemProps[] }) => {
  const { defaultActiveKey, tabPaneStyle, tabPaneItems, initKey, ...tabPaneProps } = props
  const [tabKey, setTabKey] = useState<string>(tabPaneItems[0]?.key as string)

  useEffect(() => {
    if (initKey) {
      setTabKey(initKey)
    }
  }, [initKey])

  return (
    <StyledTabs
      {...tabPaneProps}
      type="card"
      size="large"
      activeKey={tabKey}
      defaultActiveKey={defaultActiveKey || tabPaneItems[0].key}
      tabBarStyle={{ marginBottom: 0 }}
      onChange={(activeKey: string) => setTabKey(activeKey)}
    >
      {tabPaneItems.map((item) => {
        return (
          <Tabs.TabPane
            tab={
              <ConditionalWrapper condition={tabKey === item.key} wrapper={[<Text16Blue />, <Text16Normal />]}>
                {item.title}
              </ConditionalWrapper>
            }
            key={item.key}
            style={tabPaneStyle as CSSProperties}
          >
            {item.paneComponent}
          </Tabs.TabPane>
        )
      })}
    </StyledTabs>
  )
}

const StyledTabs = styled(Tabs)<MTabsProps>`
  margin: 24px;
  .ant-card > div {
    ${(props) => props.tabPaneStyle}
  }
`

export default MTabs
