import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const SHIPPING_FRAGMENTS = {
  get shippingOnly() {
    return gql`
      fragment shippingOnly on ShippingType {
        id
        shippingCompany {
          id
          name
          sweetTrackerCode
          iamportCode
        }
        shippingNumbers
        sentAt
        receivedAt
        shippingType
        shippingTypeText
        isTrackable
        untrackedReason
        couponInfo
      }
    `
  },
  get shippingPresetOnly() {
    return gql`
      fragment shippingPresetOnly on ShippingPresetType {
        id
        name
        shippingFee
        shippingCompany {
          id
          name
        }
        isPackage
        isPrepayment
        returnFee
        warehouseAddress {
          id
          postNumber
          basicAddress
          detailedAddress
        }
        returnAddress {
          id
          postNumber
          basicAddress
          detailedAddress
        }
      }
    `
  },
  get customerAddressWithUser() {
    return gql`
      fragment customerAddressWithUser on CustomerAddressType {
        id
        postNumber
        basicAddress
        detailedAddress
        name
        mobileNumber
        message
      }
    `
  },
  get shippingPreset() {
    return gql`
      fragment shippingPreset on ShippingPresetType {
        ...shippingPresetOnly
        warehouseAddress {
          id
          postNumber
          basicAddress
          detailedAddress
        }
        returnAddress {
          id
          postNumber
          basicAddress
          detailedAddress
        }
        shippingPresetProducts {
          pageInfo {
            ...pageInfo
          }
          edges {
            node {
              id
            }
            cursor
          }
          totalCount
        }
      }
      ${this.shippingPresetOnly}
      ${DEFAULT_FRAGMENTS.pageInfo}
    `
  },
}

export default SHIPPING_FRAGMENTS
