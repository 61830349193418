import { gql } from '@apollo/client'
import ORDER_FRAGMENTS from 'containers/gqls/orders/order/fragments'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const ORDER_QUERY_GQLS = {
  ORDER: gql`
    query order($id: ID!) {
      order(id: $id) {
        ...orderDetail
      }
    }
    ${ORDER_FRAGMENTS.orderDetail}
  `,
  ORDER_ITEM_COUNT: gql`
    query orderItemCount {
      orderItemCount {
        unconfirmedCount
        newOrderCount
        readyForShippingCount
        shippingCount
        shippedCount
        cancelRequestedCount
        canceledCount
        confirmedCount
        returnCount
        returnRequestedCount
        returnUnderReturnCount
        returnReturnedCount
        returnConfirmedCount
        exchangeRequestedCount
        exchangeUnderReturnCount
        exchangeConfirmedCount
      }
    }
  `,
  ORDER_ITEMS: gql`
    query orderItems($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      orderItems(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            id
            orderItemNumber
            orderStatus
            orderStatusText
            quantity
            couponDiscountPrice
            orderCouponDiscountPrice
            mileageDiscountPrice
            productPromotionCustomerAmount
            amount
            checkedAt
            confirmedAt
            cancelRequestedAt
            returnRequestedAt
            createdAt
            canceledAt
            couponInfo
            order {
              id
              orderNumber
              couponDiscountAmount
              orderCouponDiscountAmount
              shippingCouponDiscountAmount
              couponInfo
              user {
                id
                name
                email
                mobileNumber
                grade {
                  id
                  name
                }
                accountNumber
                accountHolderName
                bankName
                bankCode
              }
              customerAddress {
                ...customerAddress
              }
              totalItemQuantity
            }
            approvedTransaction {
              id
              approvedAt
            }
            productOption {
              id
              name
              product {
                id
                name
                supplyType
                brand {
                  id
                  name
                }
              }
              productOptionKinds {
                key
                value
              }
            }
            shipping {
              ...shipping
              shippingCompany {
                id
                name
                sweetTrackerCode
              }
              shippingPreset {
                isPackage
              }
            }
            refundInfo {
              id
              returnShippingFee
            }
            exchangedOrderItem {
              id
              orderItemNumber
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  ORDER_ITEMS_RETURN: gql`
    query orderItemsReturn($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      orderItems(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            id
            orderItemNumber
            orderStatus
            orderStatusText
            quantity
            couponDiscountPrice
            mileageDiscountPrice
            productPromotionCustomerAmount
            amount
            returnShippingFee
            refundAmount
            returnAcceptedAt
            returnReturnedAt
            returnConfirmedAt
            returnRejectedAt
            returnRejectMessage
            returnRequestMessage
            returnResponsibility
            checkedAt
            confirmedAt
            cancelRequestedAt
            returnRequestedAt
            createdAt
            couponInfo
            productOption {
              product {
                id
                name
                brand {
                  id
                  name
                }
                mainImage {
                  ...productImage
                }
                shippingPreset {
                  id
                  returnFee
                }
              }
              name
              productOptionKinds {
                key
                value
              }
            }
            brand {
              id
              name
            }
            approvedTransaction {
              id
              pgTid
              payMethodType
              applyNumber
              approvedAt
            }
            refundShippingInfo {
              id
              amount
              fee
              extraFee
              discountPrice
              couponDiscountPrice
              mileageDiscountPrice
              returnShippingFee
            }
            shipping {
              id
              amount
              isRefundable
            }
            order {
              id
              orderNumber
              couponDiscountAmount
              orderCouponDiscountAmount
              shippingCouponDiscountAmount
              couponInfo
              user {
                id
                name
                email
                grade {
                  id
                  name
                }
                accountNumber
                accountHolderName
                bankName
                bankCode
              }
              customerAddress {
                ...customerAddress
              }
            }
            returnInfo {
              customerAddress {
                ...customerAddress
              }
              returnInfoReturnInfoImages {
                edges {
                  node {
                    image
                    id
                    thumbnail
                  }
                }
              }
              ...returnInfo
            }
            refundInfo {
              accountNumber
              accountHolderName
              bankCode
              bankName
              id
            }
          }
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.returnInfo}
  `,
  ORDER_ITEM_CANCEL_REASONS: gql`
    query orderItemCancelReasons {
      orderItemCancelReasons {
        cancelReasons {
          ...cancelReason
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelReason}
  `,
  ORDER_ITEM_RETURN_REASONS: gql`
    query orderItemReturnReasons {
      orderItemReturnReasons {
        returnReasons {
          ...returnReason
        }
      }
    }
    ${DEFAULT_FRAGMENTS.returnReason}
  `,
  SHIPPING_TRACK: gql`
    query shippingTrack($orderItem: ID!) {
      shippingTrack(orderItem: $orderItem) {
        shipping {
          id
          couponInfo
          shippingNumbers
          shippingCompany {
            id
            name
            sweetTrackerCode
          }
        }
        shippingTrack
      }
    }
  `,
}

export default ORDER_QUERY_GQLS
