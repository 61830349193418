import { message } from 'antd'
import { IModelBrand } from 'containers/models/modelBrand'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { IModelProduct } from 'containers/models/modelProduct'
import { IModelSeller } from 'containers/models/modelSeller'
import { IModelStatus } from 'containers/models/modelStatus'
import { Store } from 'rc-field-form/lib/interface'
import { MutationFunction } from '@apollo/client'

import { DomainModalProps } from 'containers/hooks/useModal'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { MConfirmInputModal } from 'components/modals'
import MMutation from 'components/MMutation'
import {
  MConfirmInputModalInputOptionProps,
  MConfirmInputModalProps,
  MFixedProps,
} from 'components/modals/MConfirmInputModal'
import SubComponent, { componentOptionProps } from 'domains/common/SubComponentProvider'
import { IModelReturnInfoDefault } from 'containers/models/base/defaultModelInterfaces'
import { MUTATION_KEY2GQLS } from 'containers/gqls'
import { IModelReview } from 'containers/models/modelReview'
import { IModelReviewGroup } from 'containers/models/modelReviewGroup'
import { IModelServerConfig } from 'containers/models/modelServerConfig'

type mutationOptionProps = {
  gqlKey: string
  variableKey: string
}

type ConfirmModalProps = MConfirmInputModalProps & {
  product?: IModelProduct
  seller?: IModelSeller
  brand?: IModelBrand
  orderItem?: IModelOrderItem
  status?: IModelStatus
  orderItems?: IModelOrderItem[]
  returnInfos?: IModelReturnInfoDefault[]
  review?: IModelReview
  reviewGroup?: IModelReviewGroup
  serverConfig?: IModelServerConfig
}

type FixedProps = MFixedProps & {
  mutationKey: mutationOptionProps
  title: string
  description: string
  inputOption: MConfirmInputModalInputOptionProps
  componentOption?: componentOptionProps
  confirmMessage?: string
}

export type ModalConfirmInputProps = DomainModalProps<ConfirmModalProps, FixedProps>

const ModalConfirmInput = ({ useModalProps, onAction, onAPISuccess, onChange, fixedProps }: ModalConfirmInputProps) => {
  const { data } = useModalProps
  const { product, seller, brand, orderItems, status, ids, returnInfos, review, reviewGroup, serverConfig } = data
  const { mutationKey, title, description, inputOption, componentOption, disableContent, okButtonDisable } = fixedProps
  const { gqlKey, variableKey } = mutationKey

  if (!gqlKey) return null
  if (
    !product &&
    !seller &&
    !brand &&
    !orderItems &&
    !status &&
    !ids &&
    !returnInfos &&
    !review &&
    !reviewGroup &&
    !serverConfig
  ) {
    return null
  }

  const getVariables = (values: Store) => {
    // if (variableKey === 'idsWithFeeRate') {
    //   return MUTATION_VARIABLES.UPDATE_AD_PRODUCT_INFOS_FEE_RATE({
    //     ids,
    //     fitpetFeeRate: (values.fitpetFeeRate ? values.fitpetFeeRate / 100 : undefined),
    //   })
    // }
    if (variableKey === 'rejectUpdateRequest' || variableKey === 'rejectRequest') {
      return MUTATION_VARIABLES.REJECT_UPDATE_REQUEST({ id: status!.id, message: values.message })
    }
    if (variableKey === 'productRejectRequest') {
      return MUTATION_VARIABLES.REJECT_REQUEST({ id: product!.status.id, message: values.message })
    }
    if (variableKey === 'sellerRejectRequest') {
      return MUTATION_VARIABLES.REJECT_REQUEST({ id: seller!.status?.id!, message: values.message })
    }
    if (variableKey === 'brandRejectRequest') {
      return MUTATION_VARIABLES.REJECT_REQUEST({ id: brand!.status?.id!, message: values.message })
    }
    if (variableKey === 'rejectReturnInfosRequest') {
      return MUTATION_VARIABLES.REJECT_RETURN_INFOS({
        returnInfos: returnInfos!.map((item: IModelReturnInfoDefault) => ({ returnInfo: item.id })),
        rejectMessage: values.message,
      })
    }
    if (variableKey === 'rejectExchangeReturnInfosRequest') {
      return MUTATION_VARIABLES.REJECT_EXCHANGE_RETURN_INFOS({
        returnInfos: returnInfos!.map((item: IModelReturnInfoDefault) => ({ returnInfo: item.id })),
        rejectMessage: values.message,
      })
    }
    if (variableKey === 'confirmReturnInfosRequest') {
      return MUTATION_VARIABLES.CONFIRM_RETURN_INFOS({
        returnInfos: returnInfos!.map((item: IModelReturnInfoDefault) => ({ returnInfo: item.id })),
        returnShippingFee: values.returnShippingFee,
      })
    }
    if (variableKey === 'updateReviewGroupProducts') {
      return MUTATION_VARIABLES.UPDATE_REVIEW_GROUP_PRODUCTS({ id: reviewGroup!.id, name: values.reviewGroupName })
    }
    if (variableKey === 'updateReview') {
      return MUTATION_VARIABLES.UPDATE_REVIEW({ id: review!.id, weight: values.weight })
    }
    return {}
  }

  return (
    <MMutation
      gqlKey={gqlKey as keyof typeof MUTATION_KEY2GQLS}
      onAPISuccess={(res) => {
        if (onAPISuccess) onAPISuccess(res)
        if (fixedProps.confirmMessage) {
          message.success({ content: fixedProps.confirmMessage })
        }
      }}
    >
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <MConfirmInputModal
            useModalProps={useModalProps}
            onChange={onChange}
            onFormAction={async (values) => {
              const variables = getVariables(values)
              await mutation({ variables })
              if (onAction) {
                onAction()
              }
            }}
            fixedProps={{
              title,
              okButtonDisable,
              description,
              inputOption,
              disableContent,
              Component: SubComponent({ componentOption, targetModel: data }),
            }}
            loading={loading}
          />
        )
      }}
    </MMutation>
  )
}

export default ModalConfirmInput
