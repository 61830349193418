import React from 'react'

import { Text14Normal } from 'components/@atomics'
import { ITableColumn, ITableColumnTitleDataIndexProps, ITableColumnCountTextProps } from 'components/@atomics/MTable'
import * as utilData from '@libs/utils/utilData'

const countText = ({
  title,
  dataIndex,
  width,
  countType = 'caseCount',
}: ITableColumnCountTextProps): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: Array.isArray(dataIndex) ? dataIndex[dataIndex.length - 1] : dataIndex!,
    width: width ?? 70,
    render: (v: number) => {
      if (countType === 'numberCount') return <Text14Normal>{utilData.numberCountText(v)}</Text14Normal>
      if (countType === 'caseCount') return <Text14Normal>{utilData.caseCountText(v)}</Text14Normal>
      if (countType === 'personCount') return <Text14Normal>{utilData.personCountText(v)}</Text14Normal>
      if (countType === 'currencyText') return <Text14Normal>{utilData.currencyText(v)}</Text14Normal>
      return <Text14Normal>{v}</Text14Normal>
    },
  }
}

const numberCountText = (props: ITableColumnTitleDataIndexProps): ITableColumn<any> =>
  countText({ ...props, countType: 'numberCount' })
const caseCountText = (props: ITableColumnTitleDataIndexProps): ITableColumn<any> =>
  countText({ ...props, countType: 'caseCount' })
const personCountText = (props: ITableColumnTitleDataIndexProps): ITableColumn<any> =>
  countText({ ...props, countType: 'personCount' })
const currencyCountText = (props: ITableColumnTitleDataIndexProps): ITableColumn<any> =>
  countText({ ...props, countType: 'currencyText' })

export {
  countText, // 카운트
  numberCountText, // '개'
  currencyCountText, // '원'
  caseCountText, // '건'
  personCountText, // '명'
}
