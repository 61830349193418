import { keysToCamel } from '@libs/utils/utilData'
import _ from 'lodash'
import moment from 'moment'
import * as utilDate from '@libs/utils/utilDate'
import { IModelAdImage } from 'containers/models/modelAdImage'
import { IModelAdDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelAdDefault } from 'containers/models/base/defaultModels'
import { AD_STATUS, AD_STATUS_TEXT } from '@constants/constDomain'
import { IModelDisplayCollection } from 'containers/models/modelDisplayCollection'
import { FIELD_KEYS } from '@constants/constData'
import { isDateAfter, isDateBefore } from '@libs/utils/utilDate'

export interface IModelAd extends IModelAdDefault {
  adDisplayCollectionProductsCount: number
  adDisplayCollections: IModelDisplayCollection[]
  adImages: IModelAdImage[]
  adProgressPeriodText: string
  adStatus: AD_STATUS
  adStatusText: AD_STATUS_TEXT
  isAlways: boolean
  isShownText: string
  orderedAdDisplayCollections: IModelDisplayCollection[]
  styleData: { coverBackgroundColor: string }
}

export default class ModelAd extends ModelAdDefault {
  adDisplayCollectionProductsCount: number = 0
  adProgressPeriodText: string = ''
  adStatus: string = ''
  adStatusText: string = ''
  isAlways: boolean = false
  isShownText: string = ''
  orderedAdDisplayCollections: IModelDisplayCollection[] = []
  styleData: { coverBackgroundColor: string } = { coverBackgroundColor: '' }

  constructor(data: IModelAd) {
    super(data)
    this.adDisplayCollectionProductsCount = this.getAdDisplayCollectionProductCounts()
    // startedAt와 endedAt이 null이면 상시노출임
    this.adProgressPeriodText =
      this.startedAt || this.endedAt
        ? `${utilDate.date2string(this.startedAt)} ~ ${utilDate.date2string(this.endedAt)}`
        : '상시 노출'
    this.adStatus = this.getAdStatus()
    this.adStatusText = AD_STATUS_TEXT[this.adStatus as keyof typeof AD_STATUS]
    this.isAlways = !this.startedAt && !this.endedAt
    this.isShownText = this.isShown ? '노출' : '비노출'
    this.orderedAdDisplayCollections = this.getOrderedAdDisplayCollections([FIELD_KEYS.FIELD_DISPLAY_ORDER])
    this.styleData = this.getStyleData()
  }

  getAdStatus = () => {
    const today = moment(Date.now())
    const startedAt = moment(this.startedAt)
    const endedAt = moment(this.endedAt)

    if (!startedAt.isValid() && !endedAt.isValid()) {
      return AD_STATUS.AD_ONGOING
    }
    if (startedAt.isValid() && isDateBefore(today, startedAt)) {
      return AD_STATUS.AD_BEFORE
    }
    if (endedAt.isValid() && isDateAfter(today, endedAt)) {
      return AD_STATUS.AD_AFTER
    }
    return AD_STATUS.AD_ONGOING
  }

  getOrderedAdDisplayCollections = (orderBy: string[]) => {
    return _.orderBy(this.adDisplayCollections, [orderBy], ['asc']) as IModelDisplayCollection[]
  }

  getAdDisplayCollectionProductCounts = () => {
    let productCount: number = 0
    const adDisplayCollections: IModelDisplayCollection[] = this.orderedAdDisplayCollections
    for (let i = adDisplayCollections.length - 1; i >= 0; i -= 1) {
      if (adDisplayCollections[i]?.displayProductInfos?.length) {
        // @ts-ignore yoon - totalCount는 기본 제공값이나 IModelAdProductInfo[]에 정의하기 어려움
        productCount += adDisplayCollections[i].displayProductInfos.totalCount
      }
    }
    return productCount
  }

  getStyleData = () => {
    const parsedStyle = keysToCamel(JSON.parse(this.style || 'null'))
    return parsedStyle || { coverBackgroundColor: '' }
  }
}
