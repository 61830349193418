import { ReactElement } from 'react'
import { Space } from 'antd'
import { SpaceProps } from 'antd/lib/space'
import styled, { CSSProperties } from 'styled-components'

type MRequiredMarkProps = {
  style?: CSSProperties
}

const MRequiredMark = ({ style }: MRequiredMarkProps): ReactElement<SpaceProps> => {
  return <StyledRequiredMark style={style}>*</StyledRequiredMark>
}

const StyledRequiredMark = styled(({ ...rest }) => <Space {...rest} />)<SpaceProps>`
  ${(props) => props.style};
  margin-right: 6px;
  line-height: 1;
  color: #ff4d4f;
`

export default MRequiredMark
