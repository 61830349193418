import { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { MFormItemWrapper } from 'components/formItems'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { MFormBelowComment, MFormImageUpload } from 'components/@atomics/forms'
import { deepcopy } from '@libs/utils/utilData'
import { FC } from 'react'

type imageUploadType = {
  path: string | ArrayBuffer | null
  file?: File
}

type MFormItemImageUploadProps = {
  maxUploadNumber?: number
  id?: string
  comment?: string
  defaultImages?: string | string[]
  onImageUpdated: (imageFiles: File[]) => void
  disabled?: boolean
} & MFormItemWrapperProps

const MFormItemImageUpload: FC<MFormItemImageUploadProps> = ({
  id,
  comment,
  maxUploadNumber = 1,
  defaultImages,
  onImageUpdated,
  disabled = false,
  ...formItemWrapper
}) => {
  const [imageList, setImageList] = useState<imageUploadType[]>([{ path: '' }])

  useEffect(() => {
    let _imageList: imageUploadType[]
    if (defaultImages && defaultImages.length > 0 && imageList.length === 1 && imageList[0].path === '') {
      if (typeof defaultImages === 'string') {
        _imageList = [{ path: defaultImages }]
      } else {
        _imageList = defaultImages.map((image) => ({ path: image }))
      }
      _setImageList(_imageList)
      _onImageUpdated(_imageList)
    }
  }, [defaultImages])

  const _setImageList = (_imageList: imageUploadType[]) => {
    if (maxUploadNumber > _imageList.length && _imageList.findIndex((image) => image.path === '') === -1) {
      setImageList(_imageList.concat({ path: '' }))
    } else {
      setImageList(deepcopy(_imageList))
    }
  }

  const _onImageUpdated = (_imageList: imageUploadType[]) => {
    const imageFiles: any[] = _imageList.map((i) => i.file || i.path)
    onImageUpdated(imageFiles)
  }

  const onImageLoad = (index: number, base64: string | ArrayBuffer | null, file: File) => {
    let image = imageList[index]

    image = {
      ...image,
      path: base64,
      file,
    }

    imageList[index] = image
    _setImageList(imageList)
    _onImageUpdated(imageList)
  }

  const onImageDelete = (deleteIndex: number) => {
    if (disabled) return
    const newImageList = imageList.filter((_image, index) => index !== deleteIndex)
    _setImageList(newImageList)
    _onImageUpdated(newImageList)
  }

  return (
    <>
      <MFormItemWrapper {...formItemWrapper}>
        <Row gutter={16}>
          {imageList &&
            imageList.map((image, index) => {
              return (
                <Col key={index}>
                  <MFormImageUpload
                    id={id}
                    index={index}
                    imageSrc={image.path}
                    onImageLoad={(base64, file) => onImageLoad(index, base64, file)}
                    onImageDelete={() => onImageDelete(index)}
                    disabled={disabled}
                  />
                </Col>
              )
            })}
        </Row>
        <div style={{ height: 20 }} />
        <MFormBelowComment comment={comment} />
      </MFormItemWrapper>
    </>
  )
}

export default MFormItemImageUpload
