import React, { ReactElement } from 'react'
import { Space } from 'antd'
import styled from 'styled-components'

import { MModal } from 'components'
import { DomainModalProps } from 'containers/hooks/useModal'
import { Text14Normal } from 'components/@atomics'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import MMutation from 'components/MMutation'
import { MutationFunction } from '@apollo/client'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { TableOrderItemDefault } from 'domains/common/tables'

type gqlKeyProps = 'checkOrderItems'

export type ModalProcessOrderProps = {
  orderItems: IModelOrderItem[]
  onAPISuccess: Function
  gqlKey: gqlKeyProps
}

const settings: { [index: string]: any } = {
  checkOrderItems: {
    title: '주문확인',
    description: '아래 주문건을 배송준비중 상태로 변경합니다',
    variablesFormat: MUTATION_VARIABLES.CHECK_ORDER_ITEMS,
  },
}

const ModalProcessOrder = ({
  useModalProps,
  onAPISuccess,
}: DomainModalProps<ModalProcessOrderProps, undefined>): ReactElement | null => {
  const { orderItems, gqlKey }: { orderItems: IModelOrderItem[]; gqlKey: gqlKeyProps } = useModalProps.data
  const { hideModal } = useModalProps
  if (!gqlKey) return null
  if (!orderItems || orderItems.length <= 0) return null

  const setting = settings[gqlKey]
  const { title, description, variablesFormat } = setting

  const onAction = async (mutation: MutationFunction) => {
    const variables = variablesFormat({
      orderItems: orderItems.map((orderItem) => ({ orderItem: orderItem.id })),
    })
    // console.log(variables)
    await mutation({ variables })
    hideModal()
  }

  const Content = () => {
    // const currentState = title ? `${title} 수거중` : '배송준비중'
    return (
      <>
        <StyledSpace direction="vertical" size="middle">
          <Text14Normal>{description}</Text14Normal>
          <TableOrderItemDefault orderItems={orderItems} />
        </StyledSpace>
      </>
    )
  }

  return (
    <MMutation gqlKey={gqlKey} onAPISuccess={onAPISuccess}>
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <MModal
            title={title}
            useModalProps={useModalProps}
            width={700}
            ComponentContent={<Content />}
            onAction={() => onAction(mutation)}
            loading={loading}
          />
        )
      }}
    </MMutation>
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default ModalProcessOrder
