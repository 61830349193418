import React from 'react'
import { NamePath } from 'rc-field-form/es/interface'
import { Checkbox } from 'antd'
import { CheckboxOptionType } from 'antd/lib/checkbox'

import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { MFormItemWrapper } from 'components/formItems'

const ALL = 'ALL'

type MFormItemCheckboxGroupProps = {
  options: CheckboxOptionType[]
  onChange?: (value: any) => void
  hasAllCheck?: boolean
  name: NamePath
} & MFormItemWrapperProps

const MFormItemCheckboxGroup: React.FC<MFormItemCheckboxGroupProps> = ({
  options,
  onChange,
  hasAllCheck = false,
  initialValue: _initialValue = [],
  name,
  ...formItemWrapper
}) => {
  const allOptions = [ALL, ...options.map((opt) => opt.value as string)]
  const initialValue = hasAllCheck ? allOptions : _initialValue
  return (
    <MFormItemWrapper noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const prev = getFieldValue(name) || []
        const indeterminate =
          typeof prev.filter === 'function' &&
          prev.length !== 0 &&
          prev.filter((v: string) => v !== ALL).length !== options.length

        return (
          <MFormItemWrapper
            initialValue={initialValue}
            {...(hasAllCheck && {
              normalize: (values, prevValues = []) => {
                const checkAllAtPrev = prevValues.indexOf(ALL) !== -1
                const checkAllAtCurrent = values.indexOf(ALL) !== -1
                const everyOptionChecked = values.filter((v: string) => v !== ALL).length === options.length
                let nextValueList: string[] = values
                if (
                  (!checkAllAtPrev && checkAllAtCurrent) ||
                  (!checkAllAtPrev && !checkAllAtCurrent && everyOptionChecked)
                ) {
                  nextValueList = allOptions
                } else if (checkAllAtPrev && !checkAllAtCurrent) {
                  nextValueList = []
                } else if (checkAllAtPrev && !everyOptionChecked) {
                  nextValueList = values.filter((value: string) => value !== ALL)
                }
                return nextValueList
              },
            })}
            name={name}
            {...formItemWrapper}
          >
            <Checkbox.Group onChange={onChange}>
              {hasAllCheck ? (
                <Checkbox value={ALL} indeterminate={indeterminate}>
                  전체
                </Checkbox>
              ) : null}
              {options.map(({ label, value, ...otherProps }) => {
                return (
                  <Checkbox key={`${label}-${value}`} value={value} {...otherProps}>
                    {label}
                  </Checkbox>
                )
              })}
            </Checkbox.Group>
          </MFormItemWrapper>
        )
      }}
    </MFormItemWrapper>
  )
}

export default MFormItemCheckboxGroup
