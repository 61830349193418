import { Row, Col, Tag } from 'antd'
import { IModelSeller } from 'containers/models/modelSeller'
import styled from 'styled-components'

import { Text12Normal } from 'components/@atomics'
import { IModelBrand } from 'containers/models/modelBrand'

type TagsSelectedProps = {
  label: string
  selectedValues?: any[]
  marginLeftOffset?: number
  onItemRemoved: (id: string) => void
  type?: string
  tagClosable?: boolean
}

const TagsSelected = ({
  label,
  selectedValues,
  marginLeftOffset,
  onItemRemoved,
  type,
  tagClosable = true,
}: TagsSelectedProps) => {
  if (!selectedValues || selectedValues.length === 0) {
    return null
  }

  const setTagName = (val: IModelSeller | IModelBrand): string => {
    if (type === 'seller') {
      return (val as IModelSeller).shopName!
    }
    return (val as IModelBrand).name
  }

  return (
    <SelectedValueSection>
      <Row style={{ marginTop: `${type === 'seller' && '-40px'}` }}>
        <Col style={{ width: marginLeftOffset }} />
        <Col>
          <Text12Normal>선택된 {label}:</Text12Normal>
        </Col>
      </Row>
      <Row>
        <Col style={{ width: marginLeftOffset }} />
        {selectedValues.map((val: any) => (
          <Col key={val.id}>
            <Tag closable={tagClosable} onClose={() => onItemRemoved(val.id)}>
              {setTagName(val)}
            </Tag>
          </Col>
        ))}
      </Row>
    </SelectedValueSection>
  )
}

const SelectedValueSection = styled.div`
  //margin-top: -20px;
  //margin-bottom: 20px;
`

export default TagsSelected
