import { gql } from '@apollo/client'
import CUSTOMER_GRADE_FRAGMENTS from 'containers/gqls/configurations/customerGrade/fragments'

export const CUSTOMER_GRADE_MUTATION_GQLS = {
  CREATE_CUSTOMER_GRADE: gql`
    ${CUSTOMER_GRADE_FRAGMENTS.customerGradeOnly}
    mutation createCustomerGrade($input: CustomerGradeInput!) {
      createCustomerGrade(input: $input) {
        customerGrade {
          ...customerGradeOnly
        }
      }
    }
  `,
  DELETE_CUSTOMER_GRADE: gql`
    mutation deleteCustomerGrade($id: ID!) {
      deleteCustomerGrade(id: $id) {
        customerGrade {
          id
        }
      }
    }
  `,
  UPDATE_CUSTOMER_GRADE: gql`
    ${CUSTOMER_GRADE_FRAGMENTS.customerGradeOnly}
    mutation updateCustomerGrade($id: ID!, $input: CustomerGradeInput!) {
      updateCustomerGrade(id: $id, input: $input) {
        customerGrade {
          ...customerGradeOnly
        }
      }
    }
  `,
  ACTIVATE_CUSTOMER_GRADE: gql`
    mutation activateCustomerGrade($id: ID!, $input: ActivateCustomerGradeInput!) {
      activateCustomerGrade(id: $id, input: $input) {
        customerGrade {
          id
        }
      }
    }
  `,
}

export default CUSTOMER_GRADE_MUTATION_GQLS
