import { FIELD_KEYS } from '@constants/constData'
import * as utilData from '@libs/utils/utilData'
import { Form } from 'antd'
import { useState } from 'react'
import { TypeUseModalProps } from 'containers/hooks/useModal'
import {
  TableTransferProduct,
  TableTransferUser,
  TableTransferCategory,
  TableTransferBrand,
  TableTransferSku,
} from 'domains/common/tableTransfers'
import { MModal } from 'components/modals'
import { TRANSFER_TYPE } from '@constants/constDomain'

export type onTransferProps = {
  // key: string;
  values: any[]
}

export const TRANSFER_TYPE_FIELD_NAMES = [
  { type: TRANSFER_TYPE.BRAND, key: 'Brands', value: '브랜드' },
  { type: TRANSFER_TYPE.CATEGORY, key: 'Categories', value: '카테고리' },
  { type: TRANSFER_TYPE.PRODUCT, key: 'Products', value: '상품' },
  { type: TRANSFER_TYPE.PRODUCT_EVENT, key: 'ProductEvents', value: '상품' },
  { type: TRANSFER_TYPE.SKU, key: 'Skus', value: 'SKU' },
  { type: TRANSFER_TYPE.USER, key: 'Users', value: '회원' },
]

export const getTransferTypeFieldName = (type: TRANSFER_TYPE) => {
  return TRANSFER_TYPE_FIELD_NAMES.find((keyType) => keyType.type === type)?.value || ''
}

export const getTransferTypeFieldKey = (type: TRANSFER_TYPE) => {
  return TRANSFER_TYPE_FIELD_NAMES.find((keyType) => keyType.type === type)?.key || ''
}

export type modalTableTransferContentProps = {
  transferType: TRANSFER_TYPE
  disableContent?: string
  disableCheckedNumber?: number
}

type ModalTableTransferSelectProps = modalTableTransferContentProps & {
  useModalProps: TypeUseModalProps<any>
  onTransferAction: (selectedValues: any[]) => void
  chosenValues?: any[]
}

const ModalTableTransferSelect = ({
  transferType,
  chosenValues = [],
  useModalProps,
  disableContent,
  onTransferAction,
}: ModalTableTransferSelectProps) => {
  const [selectedValues, setSelectedValues] = useState<any[]>(chosenValues)
  const label = getTransferTypeFieldName(transferType)
  const { modalDisable } = useModalProps.data
  const [form] = Form.useForm()

  const setModalWidth = (): number => {
    if ([TRANSFER_TYPE.PRODUCT, TRANSFER_TYPE.PRODUCT_EVENT].includes(transferType as TRANSFER_TYPE)) return 1500
    if ([TRANSFER_TYPE.USER, TRANSFER_TYPE.SKU].includes(transferType as TRANSFER_TYPE)) return 1400
    return 1000
  }

  const onTransfer = ({ values }: onTransferProps) => {
    setSelectedValues(values)
  }

  const renderModalContent = () => {
    switch (transferType) {
      case TRANSFER_TYPE.BRAND:
        return <TableTransferBrand onTransfer={onTransfer} chosenValues={chosenValues} />
      case TRANSFER_TYPE.PRODUCT:
      case TRANSFER_TYPE.PRODUCT_EVENT:
        return (
          <TableTransferProduct
            onTransfer={onTransfer}
            colType={transferType === TRANSFER_TYPE.PRODUCT_EVENT ? 'productEvents' : undefined}
            chosenValues={chosenValues}
          />
        )
      case TRANSFER_TYPE.CATEGORY:
        return <TableTransferCategory onTransfer={onTransfer} chosenValues={chosenValues} />
      case TRANSFER_TYPE.USER:
        return <TableTransferUser useModalProps={useModalProps} onTransfer={onTransfer} chosenValues={chosenValues} />
      case TRANSFER_TYPE.SKU:
        return (
          <Form
            form={form}
            onFinish={(values) => {
              if (utilData.hasData(values)) {
                // update sku 수량값 (format: { {skuId}: { count: '{입력값}' } })
                selectedValues.forEach((value) => {
                  value[FIELD_KEYS.FIELD_COUNT] = values[value.id][FIELD_KEYS.FIELD_COUNT]
                })
                onTransferAction(selectedValues)
              }

              form!.resetFields()
              useModalProps.hideModal()
            }}
          >
            <TableTransferSku useModalProps={useModalProps} onTransfer={onTransfer} chosenValues={chosenValues} />
          </Form>
        )
      default:
        return null
    }
  }

  return (
    <MModal
      form={transferType === TRANSFER_TYPE.SKU ? form : undefined}
      title={`${label} 선택`}
      width={setModalWidth()}
      useModalProps={useModalProps}
      modalDisable={modalDisable}
      disableContent={disableContent}
      onAction={() => {
        onTransferAction(selectedValues)
      }}
      ComponentContent={<>{renderModalContent()}</>}
    />
  )
}

export default ModalTableTransferSelect
