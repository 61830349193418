import _ from 'lodash'
import { userRoleProps } from 'containers/hooks/useUserInfo'

const KEY = {
  DETAIL_PAGE: 'DETAIL_PAGE',
  DEVICE_UUID: 'DEVICE_UUID',
  KEEP_SIGNIN: 'KEEP_SIGNIN',
  LOGIN_STORE_ID: 'LOGIN_STORE_ID',
  REMEMBER_USER_ID: 'REMEMBER_USER_ID',
  USER_ROLE: 'USER_ROLE',
  USER_TOKEN: 'USER_TOKEN',
  SEARCH_KEYWORDS: 'SEARCH_KEYWORDS',
}

function save(k: string, v: any) {
  if (typeof localStorage === 'undefined') return
  localStorage.setItem(k, JSON.stringify(v))
}

function load(k: string) {
  if (typeof localStorage === 'undefined') return {}
  const value = localStorage.getItem(k)
  // console.log(t1)
  // console.log(typeof t1)
  if (value && value !== 'undefined') {
    return JSON.parse(value)
  }
  return {}
}

function remove(k: string) {
  if (typeof localStorage === 'undefined') return
  localStorage.removeItem(k)
}

export const localUserToken = {
  save: (v: string) => save(KEY.USER_TOKEN, v),
  load: () => {
    const res = load(KEY.USER_TOKEN)
    return _.isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.USER_TOKEN),
}

export const localKeepSignin = {
  save: (v: string) => save(KEY.KEEP_SIGNIN, v),
  load: () => {
    const res = load(KEY.KEEP_SIGNIN)
    return _.isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.KEEP_SIGNIN),
}

export const localUserRole = {
  save: (v: userRoleProps) => save(KEY.USER_ROLE, v),
  load: () => {
    const res = load(KEY.USER_ROLE)
    return _.isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.USER_ROLE),
}

export const localRememberUserId = {
  save: (v: string) => save(KEY.REMEMBER_USER_ID, v),
  load: () => {
    const res = load(KEY.REMEMBER_USER_ID)
    return _.isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.REMEMBER_USER_ID),
}

export const localDetailPage = {
  save: (pathname: string) => {
    const splitPathname = pathname.split('/')
    if (splitPathname[splitPathname.length - 1] === '[id]') {
      save(KEY.DETAIL_PAGE, { isDetail: true, pathname })
    }
  },
  load: (): { isDetail: boolean; pathname: string } => {
    const res = load(KEY.DETAIL_PAGE)
    return _.isEmpty(res) ? {} : res
  },
  remove: () => remove(KEY.DETAIL_PAGE),
}

export const localDeviceUuid = {
  save: (v: string) => save(KEY.DEVICE_UUID, v),
  load: (): string | undefined => {
    const res = load(KEY.DEVICE_UUID)
    return _.isEmpty(res) ? undefined : res
  },
  remove: () => remove(KEY.DEVICE_UUID),
}
export const localSearchKeywords = {
  save: (v: string[]) => save(KEY.SEARCH_KEYWORDS, v),
  load: (): string[] => {
    const res = load(KEY.SEARCH_KEYWORDS)
    return _.isEmpty(res) ? [] : res
  },
  remove: () => remove(KEY.SEARCH_KEYWORDS),
}
