import { ReactNode } from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { UrlObject } from 'url'
import styled from 'styled-components'

import { themes } from '@constants'
import { Text14Normal } from 'components/@atomics'

type LinkRouterProps = Omit<LinkProps, 'href'> &
  Omit<ButtonProps, 'href'> & {
    children: ReactNode
    buttonType?: 'text' | 'button' | 'backButton'
    sider?: boolean
    disable?: boolean
    openNewWindow?: boolean
    href?: UrlObject | string
  }

function MLinkRouterButton({
  type,
  children,
  buttonType = 'text',
  sider = true,
  disable,
  openNewWindow = false,
  style,
  href,
  ...otherProps
}: LinkRouterProps) {
  const aStyle = !sider ? { color: themes.colors.blue } : {}
  const router = useRouter()
  const linkComponent = (
    <Link passHref={!!openNewWindow} href={href!} {...otherProps}>
      {openNewWindow ? (
        <a target="_blank" rel="noreferrer noopener" style={aStyle}>
          {children}
        </a>
      ) : (
        <a style={aStyle}>{children}</a>
      )}
    </Link>
  )

  switch (buttonType) {
    case 'button':
      return (
        <StyledButton type={type} disabled={disable}>
          {linkComponent}
        </StyledButton>
      )
    case 'backButton':
      return (
        <StyledButton type={type} disabled={disable} style={style} onClick={() => router.back()}>
          {children}
        </StyledButton>
      )
    case 'text':
    default:
      if (disable) return <Text14Normal>{children}</Text14Normal>
      return <>{linkComponent}</>
  }
}

const StyledButton = styled(Button)`
  a {
    text-decoration: none;
  }
`

export default MLinkRouterButton
