import { FC } from 'react'
import styled from 'styled-components'
import DOMPurify from 'dompurify'

type SanitizedHtmlDisplayProps = {
  dirtyHtmlString: string
}

const SanitizedHtmlDisplay: FC<SanitizedHtmlDisplayProps> = ({ dirtyHtmlString }) => {
  let _cleanHtmlString: string

  if (typeof window !== 'undefined') {
    _cleanHtmlString = DOMPurify.sanitize(dirtyHtmlString, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    })
  } else {
    _cleanHtmlString = dirtyHtmlString
  }
  return (
    <StyledContainer
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: _cleanHtmlString }}
    />
  )
}

const StyledContainer = styled.div`
  overflow: hidden;
  line-height: 1.6;
  /* white-space: pre-wrap; */

  img {
    width: 100%;
    height: auto;
  }

  .ql-align-center {
    text-align: center;
  }
`

export default SanitizedHtmlDisplay
