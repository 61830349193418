import { FunctionComponent } from 'react'
import { Space, Form, Button, Input } from 'antd'

import { Text14Normal, MButton } from 'components/@atomics'
import { MFormItemWrapper } from 'components/formItems'

type MFormItemFieldProps = {
  name: string
  fieldKey: string
  placeholder: string
}

type MFormListAddFieldProps = {
  formItemFieldFirst: MFormItemFieldProps
  formItemFieldSecond: MFormItemFieldProps
  mainText: string
}

const MFormListAddField: FunctionComponent<MFormListAddFieldProps> = ({
  formItemFieldFirst,
  formItemFieldSecond,
  mainText,
}) => {
  return (
    <Form.List name="users">
      {(fields, { add, remove }) => {
        const subjectRow = (
          <Space size={200}>
            <Text14Normal>{mainText}명</Text14Normal>
            <Text14Normal>{mainText}값</Text14Normal>
          </Space>
        )

        return (
          <>
            <MFormItemWrapper label="옵션 입력">
              <MButton onClick={() => add()}>
                <Text14Normal>{mainText} 추가하기</Text14Normal>
              </MButton>
            </MFormItemWrapper>
            {fields.length === 0 || subjectRow}
            {fields.map((field) => (
              <Space key={field.key} style={{ display: 'flex' }} align="start" size={36}>
                <MFormItemWrapper
                  {...field}
                  name={[field.name, formItemFieldFirst.name]}
                  fieldKey={[field.fieldKey, formItemFieldFirst.fieldKey]}
                  style={{ width: '200px' }}
                >
                  <Input placeholder={formItemFieldFirst.placeholder} />
                </MFormItemWrapper>
                <MFormItemWrapper
                  {...field}
                  name={[field.name, formItemFieldSecond.name]}
                  fieldKey={[field.fieldKey, formItemFieldSecond.fieldKey]}
                  style={{ width: '600px' }}
                >
                  <Input placeholder={formItemFieldSecond.placeholder} />
                </MFormItemWrapper>
                <Button onClick={() => remove(field.name)} />
              </Space>
            ))}
          </>
        )
      }}
    </Form.List>
  )
}

export default MFormListAddField
