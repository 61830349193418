import { DatePicker, Input } from 'antd'

import { DATE_FORMAT, DATETIME_FORMAT } from '@libs/utils/utilDate'
import { MFormItemWrapper, antdPickerLocale } from 'components'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { FIELD_KEYS } from '@constants/constData'
import { FORM_ITEM_RULES } from '@constants/constForm'
import { useMemo } from 'react'
import { CALENDAR_PICKER_TYPE } from 'domains/common/modals/ModalRequestExcelFile'

type MFormItemDateRangePickerProps = MFormItemWrapperProps & {
  rangePickerType?: 'date' | 'datetime' | FIELD_KEYS.FIELD_DATE | FIELD_KEYS.FIELD_DATETIME | string
  disabled?: boolean
  onChange?: (values: any, formatString: [string, string]) => void
  isShowTime?: boolean
  allowEmpty?: [boolean, boolean]
  picker?: string
}

const MFormItemDateRangePicker = (props: MFormItemDateRangePickerProps) => {
  const {
    name,
    label,
    disabled,
    onChange,
    rangePickerType = 'date',
    allowEmpty,
    rules: _rules = [],
    required,
    picker,
    validateTrigger,
    ...formItemWrapper
  } = props
  const placeholderFormat = useMemo(() => {
    if (picker === CALENDAR_PICKER_TYPE.M) {
      return antdPickerLocale.monthFormat
    } else {
      if (rangePickerType === 'datetime') {
        return DATETIME_FORMAT
      }
      return DATE_FORMAT
    }
  }, [])
  const isShowTime = rangePickerType === 'datetime'

  const requiredRules = required ? [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED] : []
  const rules = [...requiredRules, ..._rules]

  return (
    <MFormItemWrapper label={label} {...formItemWrapper}>
      <Input.Group compact>
        <MFormItemWrapper noStyle name={name} rules={rules} validateTrigger={validateTrigger}>
          {/**@ts-ignore */}
          <DatePicker.RangePicker
            locale={antdPickerLocale}
            format={placeholderFormat}
            onChange={onChange}
            disabled={disabled}
            showTime={isShowTime && { format: 'HH:mm' }}
            allowEmpty={allowEmpty}
            //@ts-ignore
            picker={picker}
          />
        </MFormItemWrapper>
      </Input.Group>
    </MFormItemWrapper>
  )
}

export default MFormItemDateRangePicker
