import { ModelReviewImageDefault } from 'containers/models/base/defaultModels'
import { IModelReviewImageDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelReviewImage extends IModelReviewImageDefault {}

export default class ModelReviewImage extends ModelReviewImageDefault {
  constructor(data: IModelReviewImage) {
    super(data)
  }
}
