import { Menu, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import React, { ReactNode, ReactElement, useState } from 'react'
import { Text14A65 } from 'components/@atomics'

type MDropdownProps = {
  wordProps: {
    text: string
  }
  menu: ReactNode | null
}

const DummyMenu = () => {
  return (
    <Menu>
      <Menu.Item key="0">
        <a href="http://www.uconnec.com/">유커넥</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="http://www.celetter.com/">셀레터</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  )
}

const MDropdown = (props: MDropdownProps) => {
  // TODO: 나중에 hook으로 분리할 것을 고려 (useDropdown)
  const [menuVisible, setMenuVisible] = useState(false)

  const {
    wordProps: { text = 'Loading...' },
    menu = DummyMenu,
  } = props

  return (
    <StyledDropdown
      overlay={React.cloneElement(menu as ReactElement, { setMenuVisible })}
      trigger={['click']}
      onVisibleChange={(flag) => setMenuVisible(flag)}
      visible={menuVisible}
    >
      {/* TODO: consider make <a /> atomic component */}
      <a role="button" onClick={(e) => e.preventDefault()} tabIndex={0} onKeyDown={() => {}}>
        <Text14A65>
          {text} <DownOutlined />
        </Text14A65>
      </a>
    </StyledDropdown>
  )
}

const StyledDropdown = styled(Dropdown)`
  padding: 0 10px;
`

export default MDropdown
