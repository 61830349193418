import { FC } from 'react'
import styled, { CSSObject } from 'styled-components'

export type iconNameType =
  | 'lock'
  | 'qna-answer'
  | 'close'
  | 'close-black'
  | 'close-white'
  | 'plus-circle'
  | 'minus-circle'
  | 'chevron-up'
  | 'chevron-down'
  | 'coverflow-chevron-left'
  | 'coverflow-chevron-right'
  | 'chevron-right'
  | 'chevron-right-black'
  | 'logo-kakaopay'
  | 'logo-npay'
  | 'logo-payco'
  | 'logo-toss'
  | 'hamburger-menu'
  | 'cart'
  | 'search'
  | 'home'
  | 'fitpet'
  | 'wishlist'
  | 'product'
  | 'my'
  | 'home-active'
  | 'fitpet-active'
  | 'wishlist-active'
  | 'product-active'
  | 'my-active'
  | 'undo'
  | 'filter'
  | 'backspace'
  | 'notice'
  | 'arrow-left'
  | 'arrow-right'
  | 'photo'
  | 'icon-apple'
  | 'icon-email'
  | 'icon-naver'
  | 'icon-kakao'
  | 'download'
  | 'youtube'
  | 'instagram'
  | 'facebook'
  | 'twitter'
  | 'twitch'
  | 'afreecatv'
  | 'b'
  | 'web'
  | 'chat'
  | 'home-black'

export type ImageIconBaseProps = {
  icon?: iconNameType
  size?: number
  style?: CSSObject
}

const getIconImage = (name?: iconNameType): any => {
  switch (name) {
    case 'lock':
      return '/icons/lock@3x.png'
    case 'qna-answer':
      return '/icons/qna-answer@3x.png'
    case 'close':
      return '/icons/exit-button-gray@3x.png'
    case 'close-black':
      return '/icons/exit-button-black@3x.png'
    case 'close-white':
      return '/icons/exit-button-black-copy@3x.png'
    case 'minus-circle':
      return '/icons/minus-circle@3x.png'
    case 'plus-circle':
      return '/icons/plus-circle@3x.png'
    case 'coverflow-chevron-left':
      return '/icons/coverflow-chevron-left.png'
    case 'coverflow-chevron-right':
      return '/icons/coverflow-chevron-right.png'
    case 'chevron-up':
      return '/icons/chevron-up@3x.png'
    case 'chevron-down':
      return '/icons/chevron-down@3x.png'
    case 'chevron-right':
      return '/icons/chevron-right@3x.png'
    case 'chevron-right-black':
      return '/icons/chevron-right-black@3x.png'
    case 'logo-kakaopay':
      return '/icons/logo-kakaopay@3x.png'
    case 'logo-npay':
      return '/icons/logo-npay@3x.png'
    case 'logo-payco':
      return '/icons/logo-payco@3x.png'
    case 'logo-toss':
      return '/icons/logo-toss@3x.png'
    case 'hamburger-menu':
      return '/icons/icon-menu@3x.png'
    case 'cart':
      return '/icons/icon-cart@3x.png'
    case 'search':
      return '/icons/icon-search@3x.png'
    case 'home':
      return '/icons/btn-home@3x.png'
    case 'fitpet':
      return '/icons/btn-wb@3x.png'
    case 'wishlist':
      return '/icons/btn-wishlist@3x.png'
    case 'product':
      return '/icons/btn-product@3x.png'
    case 'my':
      return '/icons/btn-my@3x.png'
    case 'home-active':
      return '/icons/btn-home-active@3x.png'
    case 'fitpet-active':
      return '/icons/btn-wb-active@3x.png'
    case 'wishlist-active':
      return '/icons/btn-wishlist-active@3x.png'
    case 'product-active':
      return '/icons/btn-product-active@3x.png'
    case 'my-active':
      return '/icons/btn-my-active@3x.png'
    case 'undo':
      return '/icons/undo@3x.png'
    case 'filter':
      return '/icons/filter@3x.png'
    case 'backspace':
      return '/icons/backspace@3x.png'
    case 'notice':
      return '/icons/notice@3x.png'
    case 'photo':
      return '/icons/photo@3x.png'
    case 'icon-apple':
      return '/icons/brands/icon-apple@3x.png'
    case 'icon-email':
      return '/icons/brands/icon-email@3x.png'
    case 'icon-naver':
      return '/icons/brands/icon-naver@3x.png'
    case 'icon-kakao':
      return '/icons/brands/icon-kakao@3x.png'
    case 'download':
      return '/icons/download@3x.png'
    case 'youtube':
      return '/icons/brands/youtube@3x.png'
    case 'instagram':
      return '/icons/brands/instagram@3x.png'
    case 'facebook':
      return '/icons/brands/facebook@3x.png'
    case 'twitter':
      return '/icons/brands/twitter@3x.png'
    case 'twitch':
      return '/icons/brands/twitch@3x.png'
    case 'afreecatv':
      return '/icons/brands/afreeca@3x.png'
    case 'b':
      return '/icons/b@3x.png'
    case 'web':
      return '/icons/web@3x.png'
    case 'chat':
      return '/icons/chat@3x.png'
    case 'home-black':
      return '/icons/icon-home@3x.png'
    // svg
    case 'arrow-left':
      return '/icons/svg/left-slide-big-white.svg'
    case 'arrow-right':
      return '/icons/svg/right-slide-big-white.svg'
    default:
      return '/icons/lock@3x.png'
  }
}

const MImageIconBase: FC<ImageIconBaseProps> = ({ icon, style, size = 12 }) => {
  const _iconImage = getIconImage(icon)

  return <StyledImage className={icon} src={_iconImage} alt={icon} width={size} height={size} style={style} />
}

const StyledImage = styled.img`
  object-fit: contain;
  object-position: center;
`

export default MImageIconBase
