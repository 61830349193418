import { queryParamsProps } from 'components/MQuery'
import { ReactElement } from 'react'
import MMainTable, { MMainTableProps } from 'components/MMainTable'
import { ITableColumn } from 'components/@atomics/MTable'
import tableCols from 'domains/admin/tableCols'
import { IModelTermStatisticColumn } from 'containers/models/modelTermStatisticColumn'

const dateColMap = {
  daily: tableCols.date.dateText({
    title: 'Daily Feedback',
    dataIndex: 'truncatedAt',
    key: 'truncatedAt',
    width: 160,
    fixed: true,
  }),
  weekly: tableCols.date.dateRangeText({ title: 'Weekly Feedback', dataIndex: 'truncatedAt', width: 220, fixed: true }),
  monthly: tableCols.date.monthSimpleText({ title: 'Monthly Feedback', dataIndex: 'truncatedAt', fixed: true }),
}

const tableColumns = (type: 'daily' | 'weekly' | 'monthly'): ITableColumn<IModelTermStatisticColumn>[] => [
  dateColMap[type],
  {
    title: '매출',
    children: [
      tableCols.statistics.totalAmount(),
      tableCols.statistics.totalSoldAmount(),
      tableCols.statistics.totalCouponDiscountAmount(),
      tableCols.statistics.totalMileageDiscountAmount(),
      tableCols.common.baseText({
        title: '구매자수',
        dataIndex: 'totalCustomerCount',
        suffix: '명',
        width: 140,
      }),
      tableCols.common.baseText({
        title: '주문수',
        dataIndex: 'totalOrderCount',
        suffix: '건',
        width: 140,
      }),
      tableCols.common.baseText({
        title: '구매 상품수',
        dataIndex: 'totalSoldCount',
        suffix: '건',
        width: 140,
      }),
      tableCols.common.baseText({
        title: '객단가(판매가/주문수)',
        dataIndex: 'guestUnitPrice',
        suffix: '원',
        width: 160,
      }),
      tableCols.common.baseText({
        title: '평균 상품금액',
        dataIndex: 'avgProductPrice',
        suffix: '원',
        width: 160,
      }),
    ],
  },
  {
    title: '회원',
    children: [
      tableCols.common.baseText({
        title: '총회원수',
        dataIndex: 'totalUserCount',
        suffix: '명',
        width: 120,
      }),
      tableCols.common.baseText({
        title: '신규',
        dataIndex: 'signInUserCount',
        suffix: '명',
        width: 120,
      }),
      tableCols.common.baseText({
        title: '탈퇴',
        dataIndex: 'leftUserCount',
        suffix: '명',
        width: 120,
      }),
    ],
  },
]

const MainTableStatisticDaily = (): ReactElement<MMainTableProps> => {
  const queryParams: queryParamsProps = {
    gqlKey: 'dailyReport',
    dataKey: 'dailyStatistics',
  }

  return (
    <MMainTable
      TitleSet={{ title: '일별 통계', unit: '건' }}
      columns={tableColumns('daily')}
      queryParams={queryParams}
      pageSizing={false}
      multipleHeaderRow
    />
  )
}

const MainTableStatisticWeekly = (): ReactElement<MMainTableProps> => {
  const queryParams: queryParamsProps = {
    gqlKey: 'weeklyReport',
    dataKey: 'weeklyStatistics',
  }

  return (
    <MMainTable
      TitleSet={{ title: '주별 통계', unit: '건' }}
      columns={tableColumns('weekly')}
      queryParams={queryParams}
      pageSizing={false}
      multipleHeaderRow
    />
  )
}

const MainTableStatisticMonthly = (): ReactElement<MMainTableProps> => {
  const queryParams: queryParamsProps = {
    gqlKey: 'monthlyReport',
    dataKey: 'monthlyStatistics',
  }

  return (
    <MMainTable
      TitleSet={{ title: '월별 통계', unit: '건' }}
      columns={tableColumns('monthly')}
      queryParams={queryParams}
      pageSizing={false}
      multipleHeaderRow
    />
  )
}

export { MainTableStatisticDaily, MainTableStatisticWeekly, MainTableStatisticMonthly }
