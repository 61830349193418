import { FIELD_KEYS } from '@constants/constData'
import useMMutation from 'containers/hooks/useMMutation'
import { NamePath } from 'rc-field-form/lib/interface'
import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'
import { Form, Input } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { SortableHandle } from 'react-sortable-hoc'
import * as utilData from '@libs/utils/utilData'
import { Text14Normal } from 'components/@atomics'
import { ITableColumn, ITableColumnTitleDataIndexKeyProps } from 'components/@atomics/MTable'
import styled from 'styled-components'
import { themes } from '@constants/themes'
import { PET_TYPE, PET_TYPE_TEXT } from '@constants/constDomain'

const baseText = (props = {}) => {
  const { title, width = 180, dataIndex, suffix, fixed } = props as ITableColumn<any>
  return {
    title,
    dataIndex,
    key: Array.isArray(dataIndex) ? dataIndex[dataIndex.length - 1] : dataIndex!,
    width,
    fixed,
    suffix,
    render: (v: string) => {
      return (
        <Text14Normal>
          {utilData.tableText(v)}
          {!!v && suffix}
        </Text14Normal>
      )
    },
  }
}

const petTypeText = (props = {}) => {
  const { title = '펫타입', width = 140, dataIndex = 'petType', fixed } = props as ITableColumn<any>
  return {
    title,
    dataIndex,
    key: Array.isArray(dataIndex) ? dataIndex[dataIndex.length - 1] : dataIndex!,
    width,
    fixed,
    render: (_petType: PET_TYPE) => {
      return <Text14Normal>{utilData.tableText(PET_TYPE_TEXT[_petType])}</Text14Normal>
    },
  }
}

const baseTextFromModel = ({
  title,
  dataIndex,
  key,
  width,
  fixed,
  suffix,
}: ITableColumnTitleDataIndexKeyProps): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key,
    width: width || 140,
    fixed,
    suffix,
    render: (v: any) => {
      if (v) {
        return (
          <Text14Normal>
            {utilData.tableText(v[key])}
            {!!v && suffix}
          </Text14Normal>
        )
      }
      return <Text14Normal>-</Text14Normal>
    },
  }
}

const ID = (): ITableColumn<any> => {
  return {
    title: '번호',
    dataIndex: '_id',
    key: '_id',
    width: 90,
    fixed: 'left',
  }
}

const sort = (props: { disabled?: boolean } & ITableColumn<any>): { className: string } & ITableColumn<any> => {
  const { dataIndex, key, width = 60, disabled = false } = props
  return {
    fixed: 'left',
    title: '순서',
    dataIndex,
    key,
    width,
    className: 'drag-visible',
    render: (name: string) => {
      const DragHandle = !disabled
        ? SortableHandle(() => (
            <Text14Normal style={{ cursor: 'pointer' }}>
              <MenuOutlined style={{ color: '#999', marginRight: 8 }} />
              {utilData.tableText(name)}
            </Text14Normal>
          ))
        : () => <Text14Normal>{utilData.tableText(name)}</Text14Normal>
      return <DragHandle />
    },
  }
}

const editableCol = ({
  title,
  dataIndex,
  editable,
  width,
  align,
  inputType,
}: {
  title: ReactNode | NamePath
  editable?: boolean
  inputType?: string
} & Omit<ITableColumn<any>, 'title'>) => {
  return {
    title,
    dataIndex,
    editable,
    width,
    align: align || 'center',
    inputType,
  }
}

const editableSort = ({
  dataIndex = FIELD_KEYS.FIELD_DISPLAY_ORDER,
  key = FIELD_KEYS.FIELD_DISPLAY_ORDER,
  width = 92,
  refetchRef,
  editable = true,
  gqlKey = 'updateDisplayOrder',
}: {
  refetchRef?: MutableRefObject<Function | null>
  editable?: boolean
  gqlKey?: 'updateDisplayOrder' | 'updateDisplayOrderForBanner'
} & ITableColumn<any>): ITableColumn<any> => {
  return {
    title: '순서',
    dataIndex,
    key,
    width,
    render: (value: string, record: any) => {
      const [updateDisplayOrderMutation] = useMMutation(gqlKey)
      const [form] = Form.useForm()
      const [editing, setEditing] = useState(false)
      const formItemName = `${dataIndex}-${record._id}`
      const inputRef = useRef<Input>(null)

      const submitForm = (event: any) => {
        event.preventDefault()

        if (form.getFieldValue(formItemName) !== value) {
          form.submit()
        }

        setEditing(!editing)
      }

      useEffect(() => {
        if (editing) {
          inputRef.current!.focus()
        }
      }, [editing])

      return (
        <>
          {editing && (
            <Form
              form={form}
              initialValues={{ [formItemName]: value }}
              onFinish={(values) => {
                // @ts-ignore
                updateDisplayOrderMutation({
                  variables: {
                    id: record.id,
                    input: { displayOrder: values[formItemName] },
                  },
                })
                  .then((result: { [key: string]: any }) => {
                    let displayOrder
                    if (gqlKey === 'updateDisplayOrder') {
                      displayOrder = result.data?.updateDisplayOrder.displayOrder as number
                    } else {
                      displayOrder = result.data.updateDisplayOrderForBanner.displayOrder as number
                    }
                    form.setFieldsValue({ [formItemName]: displayOrder })
                    if (refetchRef && refetchRef.current) {
                      refetchRef.current()
                    }
                  })
                  .catch(() => {
                    form.setFieldsValue({ [formItemName]: value })
                  })
                  .finally(() => {
                    setEditing(!editing)
                  })
              }}
            >
              <Form.Item name={formItemName} style={{ marginBottom: 0 }}>
                <Input type="number" ref={inputRef} onPressEnter={submitForm} onBlur={submitForm} maxLength={10} />
              </Form.Item>
            </Form>
          )}

          {!editing && (
            <>
              {editable ? (
                <StyledText14Normal
                  onClick={() => {
                    if (editable) setEditing(!editing)
                  }}
                >
                  {utilData.tableText(value)}
                </StyledText14Normal>
              ) : (
                value
              )}
            </>
          )}
        </>
      )
    },
  }
}

const indexDescNumber = ({ fixed = false }: { fixed?: 'left' | 'right' | boolean } = {}) => {
  return {
    title: '번호',
    dataIndex: 'indexDescNumber',
    width: 100,
    fixed,
    render: (idxNumber: number) => {
      return <Text14Normal>{idxNumber}</Text14Normal>
    },
  }
}

const indexAscNumber = () => {
  return {
    title: '번호',
    dataIndex: 'indexAscNumber',
    width: 100,
    render: (idxNumber: number) => {
      return <Text14Normal>{idxNumber}</Text14Normal>
    },
  }
}

const StyledText14Normal = styled(Text14Normal)`
  :hover {
    border-radius: 2px;
    border: 1px solid ${themes.colors.greyd4};
    cursor: pointer;
    padding: 4px 28px 4px 8px;
  }
`

export {
  ID, // id
  baseText, // 값이 없을 땐 '-'를 return하는 text
  petTypeText, // 펫타입
  baseTextFromModel, // 1 depth model에서 baseText 사용
  sort, // Table Row의 순서를 변경(MTable의 dragRowKey, onDragRowAction props 참고)
  editableCol,
  editableSort,
  indexDescNumber,
  indexAscNumber,
}
