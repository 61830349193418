import { ReactNode } from 'react'
import { Card } from 'antd'
import { CardProps } from 'antd/lib/card'
import styled from 'styled-components'

type MCardProps = CardProps & {
  actions?: ReactNode[]
  removeactiondivider?: boolean | string
  children?: ReactNode
}

const MPageCarded = (props: MCardProps) => {
  const { actions, removeactiondivider = true, children, ...cardProps } = props
  return (
    <StyledCard
      {...cardProps}
      actions={actions}
      removeactiondivider={removeactiondivider.toString()}
      bodyStyle={{ overflow: 'auto', height: actions ? 'calc(150vh - 342px)' : 'calc(100vh - 272px)' }}
    >
      {children}
    </StyledCard>
  )
}

const StyledCard = styled(Card)<MCardProps>`
  background-color: #f0f2f5;

  .ant-card-actions {
    padding: 8px 24px;
    border-top: ${(props) => props.removeactiondivider && 'none'};
  }
`

export default MPageCarded
