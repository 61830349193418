import { Text14Normal } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelBillingItem } from 'containers/models/modelBillingItem'
import { IModelProduct } from 'containers/models/modelProduct'
import { IModelSellerBilling } from 'containers/models/modelSellerBilling'
import * as utilData from '@libs/utils/utilData'

const name = ({
  dataIndex = 'sellerBilling',
  fixed = false,
  width = 150,
}: ITableColumn<IModelBillingItem> = {}): ITableColumn<any> => {
  return {
    title: '브랜드',
    dataIndex,
    key: 'brandName',
    width,
    fixed,
    render: (value: IModelSellerBilling | IModelProduct) => {
      return <Text14Normal>{utilData.tableText(value?.brand.name)}</Text14Normal>
    },
  }
}

export {
  name, // 브랜드명
}
