import { MutableRefObject } from 'react'
import useModal from 'containers/hooks/useModal'
import { MButton } from 'components/@atomics'
import { IModelCoupon } from 'containers/models/modelCoupon'
import { ModalConfirm } from 'domains/common/modals'
import { message, message as antdMessage } from 'antd'
import { COUPON_STATUS } from '@constants/constDomain'

const ButtonDeleteCoupons = ({
  selected,
  clearSelected,
  refetchRef,
}: {
  selected: IModelCoupon[]
  clearSelected: Function
  refetchRef: MutableRefObject<Function | null>
}) => {
  const { useModalProps } = useModal()

  return (
    <>
      <MButton
        disabled={selected.length <= 0}
        type="primary"
        onClick={() => {
          if (selected.filter((item) => item.usedStatus !== COUPON_STATUS.USED).length === 0) {
            message.warning('사용한 쿠폰은 삭제할 수 없습니다.')
          } else {
            useModalProps.toggleModal()
            useModalProps.setData({ coupons: selected })
          }
        }}
        width={100}
      >
        선택 삭제
      </MButton>
      <ModalConfirm
        useModalProps={useModalProps}
        fixedProps={{
          mutationKey: {
            gqlKey: 'deleteCoupons',
            variableKey: 'deleteCoupons',
          },
          title: '쿠폰 선택 삭제',
          description: '선택한 쿠폰을 삭제하시겠습니까?\n고객 쿠폰 리스트에서 해당 쿠폰이 삭제됩니다',
        }}
        onAPISuccess={() => {
          antdMessage.success('쿠폰이 삭제되었습니다.')
          clearSelected()
          if (refetchRef && refetchRef.current) refetchRef.current()
        }}
      />
    </>
  )
}

export default ButtonDeleteCoupons
