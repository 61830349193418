import { MFormItemSelect } from 'components/formItems'
import MQuery from 'components/MQuery'
import { MFormItemSelectProps } from 'components/formItems/MFormItemSelect'
import { IModelBrand } from 'containers/models/modelBrand'

const FormItemSelectBrand = () => {
  return (
    <MQuery queryParams={{ gqlKey: 'selectBrands', dataKey: 'brands' }}>
      {({ data }) => {
        const brands = data?.brands?.data as IModelBrand[]
        const brandOption: MFormItemSelectProps = {
          label: '브랜드',
          name: 'brandName',
          placeholder: '브랜드 선택',
          selectWidth: 180,
          selectOptions: brands.map((brand: IModelBrand) => ({ value: brand.id, title: brand.name })),
        }

        return <MFormItemSelect {...brandOption} />
      }}
    </MQuery>
  )
}

export default FormItemSelectBrand
