import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { themes } from '@constants'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { SCREEN_MEDIA_QUERY } from '@constants/constLayout'

export type MButtonProps = ButtonProps & {
  width?: number | string
  height?: number
  theme?: 'white' | 'black'
  fontSize?: number | { xs: number; md: number }
}

const MButton: FunctionComponent<MButtonProps> = ({ children, width, height, theme, fontSize, ...buttonProps }) => {
  const { type } = buttonProps
  const additionalStyle = type === 'link' && {
    style: { padding: 0 },
  }
  return (
    <StyledButton {...buttonProps} {...additionalStyle} width={width} height={height} theme={theme} fontSize={fontSize}>
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)<MButtonProps>`
  width: ${({ width }) => {
    if (!width) {
      return undefined
    }
    if (typeof width === 'number') {
      return `${width}px`
    }
    return width
  }};
  height: ${({ height }) => height}px;
  span {
    font-size: ${({ fontSize }) => {
      if (typeof fontSize === 'number') {
        return `${fontSize}px`
      }
      return undefined
    }};
    @media ${SCREEN_MEDIA_QUERY.xs} {
      font-size: ${({ fontSize }) => {
        if (!fontSize) {
          return undefined
        }
        if (typeof fontSize === 'number') {
          return `${fontSize}px`
        }
        return `${fontSize.xs}px`
      }};
    }
    @media ${SCREEN_MEDIA_QUERY.md} {
      font-size: ${({ fontSize }) => {
        if (!fontSize) {
          return undefined
        }
        if (typeof fontSize === 'number') {
          return `${fontSize}px`
        }
        return `${fontSize.md}px`
      }};
    }
  }
  ${(props) =>
    props.theme === 'black' &&
    `
    span {
      color: ${themes.colors.white}
    };
    background-color: ${props.disabled ? themes.colors.greyc0 : themes.colors.black33};
    border: ${props.disabled ? themes.colors.greyc0 : `1px solid ${themes.colors.black33}`};
    &:disabled {
      background-color: ${themes.colors.greyd9};
    };
    &:hover {
      background-color: ${props.disabled ? themes.colors.greyc0 : themes.colors.blackAlpha100};
      border: ${props.disabled ? themes.colors.greyc0 : `1px solid ${themes.colors.black33}`};
    };
    &:focus{
      background-color: ${props.disabled ? themes.colors.greyc0 : themes.colors.black33};
      border: ${props.disabled ? themes.colors.greyc0 : `1px solid ${themes.colors.blackAlpha100}`};
    }
  `}
  ${(props) =>
    props.theme === 'white' &&
    `
    span {
      color: ${themes.colors.black33}
    };
    background-color: ${props.disabled ? themes.colors.greyc0 : themes.colors.white};
    border: ${props.disabled ? themes.colors.greyc0 : `1px solid ${themes.colors.greyb2}`};
    &:disabled {
      background-color: ${themes.colors.greyd9};
    };
    &:hover {
      background-color: ${props.disabled ? themes.colors.greyc0 : themes.colors.whitef0};
      border: ${props.disabled ? themes.colors.greyc0 : `1px solid ${themes.colors.greyb2}`};
    };
    &:focus{
      background-color: ${props.disabled ? themes.colors.greyc0 : themes.colors.white};
      border: ${props.disabled ? themes.colors.greyc0 : `1px solid ${themes.colors.greyb2}`};
    }
  `}
`

export default MButton
