import { IModelFitpetLinkDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelFitpetLinkDefault } from 'containers/models/base/defaultModels'

export interface IModelFitpetLink extends IModelFitpetLinkDefault {}

export default class ModelFitpetLink extends ModelFitpetLinkDefault {
  constructor(data: IModelFitpetLink) {
    super(data)
  }
}
