import _ from 'lodash'
import * as utilDate from '@libs/utils/utilDate'
import { getDisplayPeriodText, getDisplayStatus } from '@libs/utils/utilDate'
import { IModelDisplayCollectionDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelDisplayCollectionDefault } from 'containers/models/base/defaultModels'
import {
  DISPLAY_COLLECTION_TYPE,
  DISPLAY_COLLECTION_TYPE_TEXT,
  DISPLAY_STATUS,
  DISPLAY_STATUS_TEXT,
} from '@constants/constDomain'
import { IModelDisplayProductInfo } from 'containers/models/modelDisplayProductInfo'

export interface IModelDisplayCollection extends IModelDisplayCollectionDefault {
  collectionTypeText: string
  createdAtText: string
  displayPeriodText: string
  displayProductInfos: IModelDisplayProductInfo[]
  displayStatus: DISPLAY_STATUS
  displayStatusText: DISPLAY_STATUS_TEXT
  orderedDisplayProductInfos: IModelDisplayProductInfo[]
  updatedAtText: string
  isAlways: boolean
}

export default class ModelDisplayCollection extends ModelDisplayCollectionDefault {
  collectionTypeText = ''
  createdAtText = ''
  displayPeriodText = ''
  displayStatus = ''
  displayStatusText = ''
  orderedDisplayProductInfos: IModelDisplayProductInfo[] = []
  updatedAtText = ''
  isAlways: boolean = false

  constructor(data: IModelDisplayCollection) {
    super(data)

    this.collectionTypeText = DISPLAY_COLLECTION_TYPE_TEXT[this.collectionType as keyof typeof DISPLAY_COLLECTION_TYPE]
    this.displayStatus = getDisplayStatus(this.startedAt, this.endedAt)
    this.displayStatusText = DISPLAY_STATUS_TEXT[this.displayStatus as keyof typeof DISPLAY_STATUS]
    this.displayPeriodText = getDisplayPeriodText(this.startedAt, this.endedAt, true)
    this.createdAtText = utilDate.date2string(this.createdAt)
    this.updatedAtText = utilDate.date2string(this.updatedAt)
    this.orderedDisplayProductInfos = this.getOrderedDisplayProductInfos(['displayOrder'])
    this.isAlways = !this.startedAt && !this.endedAt
  }

  getOrderedDisplayProductInfos(orderBy: string[]) {
    return _.orderBy(this.displayProductInfos, [orderBy], ['asc']) as IModelDisplayProductInfo[]
  }
}
