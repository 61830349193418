import { IModelCategory } from 'containers/models/modelCategory'
import { useState, useRef, ReactNode, ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { Tag, Input, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { doSetSelectedCategories } from '@libs/redux/reduxUI'
import { Text14A65 } from 'components/@atomics'

export interface ITagItem {
  id: string
  name: string
  parent?: ITagItem | {}
  __typename: string
}

type TypeTagItem = ITagItem & IModelCategory

export type onTagActionProps = {
  actionType: 'create' | 'update'
  tag?: TypeTagItem
  name?: string
}

export type MTagGroupProps = {
  tags: Array<TypeTagItem>
  onTagAction?: (props: onTagActionProps) => void
  children?: ReactElement<any> | ReactNode
  readonly?: boolean
}

function MTagGroup(props: MTagGroupProps) {
  const { tags, onTagAction, children, readonly = false } = props
  const [selectedTag, setSelectedTag] = useState<TypeTagItem | null>(null)
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')
  const inputRef = useRef<Input>(null)
  const editInputRef = useRef<Input>(null)
  const dispatch = useDispatch()

  const handleInputConfirm = (e: any) => {
    if (inputValue !== '') {
      if (onTagAction) {
        onTagAction({ actionType: 'create', name: e.target.value })
      }
    }
    setInputVisible(false)
    setInputValue('')
  }

  const handleEditInputConfirm = () => {
    tags[editInputIndex].name = editInputValue
    if (editInputValue !== '') {
      if (onTagAction) {
        onTagAction({ actionType: 'update', tag: tags[editInputIndex] })
      }
    }
    setEditInputIndex(-1)
    setEditInputValue('')
  }

  return (
    <>
      {tags.map((tag, index) => {
        // show edit input box when double click tag
        if (editInputIndex === index) {
          return (
            <StyledInput
              ref={editInputRef}
              key={tag.id}
              type="text"
              size="small"
              value={editInputValue}
              onChange={(e) => {
                setEditInputValue(e.target.value)
              }}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          )
        }

        // set Tag
        const isLongTag = tag.name.length > 20
        const tagElem = (
          <StyledCheckableTag
            key={tag.id}
            checked={selectedTag ? selectedTag.id === tag.id : false}
            onChange={(checked) => {
              if (checked) {
                setSelectedTag(tag)
                if (tag.__typename === 'CategoryType') {
                  dispatch(doSetSelectedCategories(tag))
                }
              }
            }}
          >
            <span
              onDoubleClick={async () => {
                await setEditInputIndex(index)
                await setEditInputValue(tag.name)
                if (editInputRef.current) {
                  editInputRef.current.focus()
                }
              }}
            >
              <Text14A65 style={{ color: selectedTag && selectedTag.id === tag.id ? '#fff' : undefined }}>
                {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
              </Text14A65>
            </span>
          </StyledCheckableTag>
        )
        return isLongTag ? (
          <Tooltip title={tag.name} key={tag.id}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}

      {!readonly && inputVisible && (
        // show input box for new tag
        <StyledInput
          ref={inputRef}
          type="text"
          size="small"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!readonly && !inputVisible && (
        // add tag button
        <Tag
          onClick={() => {
            setInputVisible(true)
            if (inputRef.current) {
              inputRef.current.focus()
            }
          }}
        >
          <PlusOutlined />
        </Tag>
      )}
      {children}
    </>
  )
}

const StyledInput = styled(Input)`
  max-width: 120px;
  margin-right: 8px;
  vertical-align: top;
`

const StyledCheckableTag = styled(Tag.CheckableTag)`
  border-radius: 4px;
  text-align: center;
  border: solid 1px ${(props) => props.theme.colors.border};
  user-select: none;
`

export default MTagGroup
