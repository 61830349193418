import { ITableColumn, ITableColumnFixedHasLinkProps } from 'components/@atomics/MTable'
import { MButton } from 'components/@atomics'
import { IModelSellerBilling } from 'containers/models/modelSellerBilling'
import { IModelExcelRequestDefault } from 'containers/models/base/defaultModelInterfaces'
import { DownloadOutlined } from '@ant-design/icons'
import React from 'react'
import { getFullUrl } from '@libs/utils/utilData'

const downloadExcelFile = ({
  title = '엑셀 다운로드',
  dataIndex = undefined,
  width = 200,
}: ITableColumnFixedHasLinkProps & { userType?: 'admin' | 'seller' } = {}): ITableColumn<IModelSellerBilling> => {
  return {
    title,
    dataIndex,
    width,
    render: (excelRequest: IModelExcelRequestDefault) => {
      return (
        <MButton
          type="default"
          icon={<DownloadOutlined />}
          href={getFullUrl(excelRequest.excelFile)}
          disabled={excelRequest.status !== 'COMPLETE'}
          download
        >
          엑셀 다운로드
        </MButton>
      )
    },
  }
}

export { downloadExcelFile }
