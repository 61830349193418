import { gql } from '@apollo/client'

const DEFAULT_FRAGMENTS: { [index: string]: any } = {
  get pageInfo() {
    return gql`
      fragment pageInfo on PageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    `
  },
  get adImage() {
    return gql`
      fragment adImage on AdImageType {
        id
        image
        displayType
      }
    `
  },
  get ad() {
    return gql`
      fragment ad on AdType {
        id
        displayOrder
        startedAt
        endedAt
        petType
        createdAt
        updatedAt
        name
        description
        slug
        adType
        isShown
        style
        isFinished
        isCouponDownloaded
      }
    `
  },
  get asInfo() {
    return gql`
      fragment asInfo on AsInfoType {
        id
        name
        telephoneNumber
      }
    `
  },
  get bank() {
    return gql`
      fragment bank on BankType {
        name
        code
      }
    `
  },
  get bannerImage() {
    return gql`
      fragment bannerImage on BannerImageType {
        image
        id
        index
        displayDevice
        link
      }
    `
  },
  get bannerInfo() {
    return gql`
      fragment bannerInfo on BannerInfoType {
        adId
        adName
        adSlug
      }
    `
  },
  get banner() {
    return gql`
      fragment banner on BannerType {
        displayOrder
        petType
        createdAt
        updatedAt
        id
        isAlways
        startedAt
        endedAt
        name
        bannerType
        isPluralDisplay
        allowDisplay
        displayDevice
        bannerTypeInfo
        style
      }
    `
  },
  get billingItem() {
    return gql`
      fragment billingItem on BillingItemType {
        createdAt
        updatedAt
        quantity
        fitpetFeeRate
        mileageRewardRate
        id
        billingType
        sellerFee
        fitpetFee
        memo
        originalPrice
        discountPrice
        productPromotionDiscountPrice
        orderCouponDiscountPrice
        couponDiscountPrice
        mileageDiscountPrice
        isSellerBilled
        payMethodType
        supplyAmount
        promotionAmount
        sellerSupplyAmount
        sellerPromotionAmount
      }
    `
  },
  get brand() {
    return gql`
      fragment brand on BrandType {
        displayOrder
        petType
        createdAt
        updatedAt
        id
        code
        name
        requestedAt
        approvedAt
        updateApprovedAt
        listImage
        homeImage
        backgroundImage
        wishedCount
        isWished
      }
    `
  },
  get cacheProduct() {
    return gql`
      fragment cacheProduct on CacheProductType {
        totalCount
        edges
        pageInfo
      }
    `
  },
  get cancelReason() {
    return gql`
      fragment cancelReason on CancelReasonType {
        requestType
        requestMessage
        isUserVisible
      }
    `
  },
  get cancelTransaction() {
    return gql`
      fragment cancelTransaction on CancelTransactionType {
        createdAt
        updatedAt
        id
        accountNumber
        accountHolderName
        bankCode
        bankName
        cancelTransactionStatus
        errorCode
        errorMessage
        cancelableAmount
        cancelAmount
        remainAmount
        message
        canceledAt
        responseData
      }
    `
  },
  get cartItemHistory() {
    return gql`
      fragment cartItemHistory on CartItemHistoryType {
        createdAt
        updatedAt
        id
        quantity
      }
    `
  },
  get cartItem() {
    return gql`
      fragment cartItem on CartItemType {
        createdAt
        updatedAt
        id
        quantity
      }
    `
  },
  get category() {
    return gql`
      fragment category on CategoryType {
        displayOrder
        id
        name
        code
        text
        isVisible
        description
        listImage
        subProductCount
        slug
      }
    `
  },
  get checkTokenAssociation() {
    return gql`
      fragment checkTokenAssociation on CheckTokenAssociationType {
        text
        tokenType
      }
    `
  },
  get couponGroup() {
    return gql`
      fragment couponGroup on CouponGroupType {
        createdAt
        updatedAt
        id
        couponGroupStatus
        eventType
        couponType
        name
        description
        canConjunctUse
        hasEventSms
        issueType
        issueCount
        maxIssueCount
        maxDuplicateIssueCount
        issueStartedAt
        issueEndedAt
        hasDuplicateIssueFirst
        usingPeriod
        usingStartedAt
        usingEndedAt
        expiredAlertDay
        discountType
        discountRate
        discountAmount
        minOrderAmount
        maxDiscountAmount
        coverageType
        coverageOsType
        code
        issueStatus
        eventTypeDisplay
        isDeletable
        hasMaxIssueCount
        hasUsingPeriod
        hasExpiredAlert
        hasMinOrderAmount
        coverageExcludeType
        canIssue
        canUseOnlyFirstOrder
      }
    `
  },
  get couponKakaoTemplate() {
    return gql`
      fragment couponKakaoTemplate on CouponKakaoTemplateType {
        name
        title
        message
        parameters
      }
    `
  },
  get coupon() {
    return gql`
      fragment coupon on CouponType {
        createdAt
        updatedAt
        id
        code
        registeredAt
        startedAt
        expiredAt
        usedAt
        message
        usedStatus
        issuedFrom
        issueCount
      }
    `
  },
  get coverflowBannerInfo() {
    return gql`
      fragment coverflowBannerInfo on CoverflowBannerInfoType {
        adId
        adName
        adSlug
        kindText
      }
    `
  },
  get coverflow() {
    return gql`
      fragment coverflow on CoverflowType {
        adId
        adName
        adSlug
        pcTitle
        pcSubtitle
        pcDescription
        pcProductNameText
        pcBottomText
        mobileTitle
        mobileSubtitle
        mobileDescription
        mobileProductNameText
        mobileBottomText
      }
    `
  },
  get customerAddress() {
    return gql`
      fragment customerAddress on CustomerAddressType {
        postNumber
        basicAddress
        detailedAddress
        id
        name
        mobileNumber
        message
      }
    `
  },
  get customerGrade() {
    return gql`
      fragment customerGrade on CustomerGradeType {
        createdAt
        updatedAt
        id
        series
        name
        minOrderAmount
        maxOrderAmount
        minOrderCount
        maxOrderCount
        rewardRate
        isActivated
        gradeType
        code
        userCount
        conditionText
        pushUserCount
        pushMarketingUserCount
      }
    `
  },
  get customerTemplateAddress() {
    return gql`
      fragment customerTemplateAddress on CustomerTemplateAddressType {
        id
        postNumber
        basicAddress
        detailedAddress
        isDefault
        templateName
        name
        mobileNumber
        message
      }
    `
  },
  get dashboardBilling() {
    return gql`
      fragment dashboardBilling on DashboardBillingType {
        totalSellerAmount
      }
    `
  },
  get dashboardBoard() {
    return gql`
      fragment dashboardBoard on DashboardBoardType {
        productQnaCount
        orderQnaCount
        serviceQnaCount
        reviewCount
      }
    `
  },
  get dashboardOrderTimeseries() {
    return gql`
      fragment dashboardOrderTimeseries on DashboardOrderTimeseriesType {
        id
        label
      }
    `
  },
  get dashboardOrder() {
    return gql`
      fragment dashboardOrder on DashboardOrderType {
        unconfirmedCount
        newOrderCount
        readyForShippingCount
        shippingCount
        shippedCount
        cancelRequestedCount
        returnRequestedCount
        confirmedCount
      }
    `
  },
  get dashboardProduct() {
    return gql`
      fragment dashboardProduct on DashboardProductType {
        sellerCount
        brandCount
        productCount
      }
    `
  },
  get dashboardUser() {
    return gql`
      fragment dashboardUser on DashboardUserType {
        wishedProductCount
        wishedBrandCount
      }
    `
  },
  get defaultReturnChangeGuide() {
    return gql`
      fragment defaultReturnChangeGuide on DefaultReturnChangeGuideType {
        value
      }
    `
  },
  get designersBannerInfo() {
    return gql`
      fragment designersBannerInfo on DesignersBannerInfoType {
        adId
        adName
        adSlug
        brandText
      }
    `
  },
  get device() {
    return gql`
      fragment device on DeviceType {
        id
        uuid
        token
        refreshToken
        pushToken
        platformType
        osVersion
        modelNumber
        appVersion
        lastLoggedAt
        keepSignIn
        expiredAt
      }
    `
  },
  get displayCollection() {
    return gql`
      fragment displayCollection on DisplayCollectionType {
        displayOrder
        startedAt
        endedAt
        petType
        createdAt
        updatedAt
        id
        name
        collectionType
        isShown
        backgroundImage
        description
      }
    `
  },
  get displayProductInfo() {
    return gql`
      fragment displayProductInfo on DisplayProductInfoType {
        displayOrder
        startedAt
        endedAt
        id
      }
    `
  },
  get excelExportRequest() {
    return gql`
      fragment excelExportRequest on ExcelExportRequestType {
        id
        requestName
        requestUserType
        createdAt
        completedAt
        excelFile
        status
      }
    `
  },
  get faq() {
    return gql`
      fragment faq on FaqType {
        createdAt
        updatedAt
        id
        title
        body
        category
        receiverType
      }
    `
  },
  get fitpetLink() {
    return gql`
      fragment fitpetLink on FitpetLinkType {
        createdAt
        updatedAt
        id
        fullUrl
        shortenUrl
        clicks
      }
    `
  },
  get homeSection() {
    return gql`
      fragment homeSection on HomeSectionType {
        name
        homeSectionType
        isShown
        bannerType
        banners
        displayCollection
        weeklyBestCollections
        brands
      }
    `
  },
  get home() {
    return gql`
      fragment home on HomeType {
        topSlimBanners
        topRollBanners
        homeMiddleBanners
        homeBottomBanners
        brands
        displayCollections
      }
    `
  },
  get inactiveUser() {
    return gql`
      fragment inactiveUser on InactiveUserType {
        accountNumber
        accountHolderName
        bankCode
        bankName
        id
        username
        email
        mobileNumber
        name
        agreeNightMarketing
        agreeMarketing
        inactiveType
      }
    `
  },
  get influencerAddress() {
    return gql`
      fragment influencerAddress on InfluencerAddressType {
        postNumber
        basicAddress
        detailedAddress
        id
      }
    `
  },
  get influencerStatisticColumn() {
    return gql`
      fragment influencerStatisticColumn on InfluencerStatisticColumnType {
        totalAmount
        totalSoldAmount
        totalCouponDiscountAmount
        totalMileageDiscountAmount
        totalCustomerCount
        totalOrderCount
        totalSoldCount
        guestUnitPrice
        avgProductPrice
        influencerName
      }
    `
  },
  get influencerStatusCount() {
    return gql`
      fragment influencerStatusCount on InfluencerStatusCountType {
        totalCount
        requestCount
        approvedCount
        rejectedCount
        suspendCount
        bannedCount
        deletedCount
      }
    `
  },
  get influencer() {
    return gql`
      fragment influencer on InfluencerType {
        createdAt
        updatedAt
        accountNumber
        accountHolderName
        bankCode
        bankName
        id
        nickname
        homepageUrl
        youtubeUrl
        instagramUrl
        facebookUrl
        twitterUrl
        twitchUrl
        afreecatvUrl
        blogUrl
        etcUrl
        profileImage
        followerLevel
        registrationNumber
        bankBookImage
        influencerType
        fitHeight
        fitWeight
        fitShoesSize
        requestedAt
        approvedAt
        updateApprovedAt
      }
    `
  },
  get informEmailAttachment() {
    return gql`
      fragment informEmailAttachment on InformEmailAttachmentType {
        id
        file
      }
    `
  },
  get informEmailQueue() {
    return gql`
      fragment informEmailQueue on InformEmailQueueType {
        id
        emails
        subject
        title
        text
        sendAt
        templateName
      }
    `
  },
  get informKakaoQueue() {
    return gql`
      fragment informKakaoQueue on InformKakaoQueueType {
        id
        template
        data
        sendAt
        isSent
        sentSuccessCount
        sentFailCount
      }
    `
  },
  get informPushQueue() {
    return gql`
      fragment informPushQueue on InformPushQueueType {
        id
        sendAt
        pushQueueStatus
        targetKey
        target
        memo
        targetInfo
        sentSuccessCount
        totalUserCount
        readCount
        totalReadCount
        pushQueueStatusDisplay
      }
    `
  },
  get informSmsHistory() {
    return gql`
      fragment informSmsHistory on InformSmsHistoryType {
        createdAt
        updatedAt
        id
        mobileNumbers
        text
        resultStatus
        resultMessage
      }
    `
  },
  get informSmsQueue() {
    return gql`
      fragment informSmsQueue on InformSmsQueueType {
        id
        mobileNumbers
        text
        sendAt
      }
    `
  },
  get leaveReason() {
    return gql`
      fragment leaveReason on LeaveReasonType {
        leaveReasons
      }
    `
  },
  get mileageHistory() {
    return gql`
      fragment mileageHistory on MileageHistoryType {
        createdAt
        updatedAt
        id
        mileageType
        message
        subMessage
        beforeMileage
        afterMileage
        mileageDiff
        expiredAt
      }
    `
  },
  get mileagePolicy() {
    return gql`
      fragment mileagePolicy on MileagePolicyType {
        mileageUnusedDay
        mileageMinHoldingAmount
        mileageMinOrderAmount
        mileageMinUsageAmount
        mileageMaxDiscountRate
      }
    `
  },
  get mileageRewardPolicy() {
    return gql`
      fragment mileageRewardPolicy on MileageRewardPolicyType {
        reviewRate
        orderConfirmRate
        imageReviewRate
      }
    `
  },
  get mileage() {
    return gql`
      fragment mileage on MileageType {
        id
        point
      }
    `
  },
  get minAppVersion() {
    return gql`
      fragment minAppVersion on MinAppVersionType {
        minAppVersion
        normalAppVersion
        latestAppVersion
        platform
      }
    `
  },
  get notice() {
    return gql`
      fragment notice on NoticeType {
        createdAt
        updatedAt
        id
        title
        body
        isImportant
        receiverType
        kind
        attachment
      }
    `
  },
  get orderItemAdminNote() {
    return gql`
      fragment orderItemAdminNote on OrderItemAdminNoteType {
        createdAt
        updatedAt
        id
        note
      }
    `
  },
  get orderItemCount() {
    return gql`
      fragment orderItemCount on OrderItemCountType {
        unconfirmedCount
        newOrderCount
        readyForShippingCount
        shippingCount
        shippedCount
        cancelRequestedCount
        canceledCount
        confirmedCount
        returnCount
        returnRequestedCount
        returnUnderReturnCount
        returnReturnedCount
        returnConfirmedCount
        exchangeRequestedCount
        exchangeUnderReturnCount
        exchangeConfirmedCount
      }
    `
  },
  get orderItem() {
    return gql`
      fragment orderItem on OrderItemType {
        createdAt
        updatedAt
        id
        quantity
        originalPrice
        discountPrice
        productPromotionDiscountPrice
        couponDiscountPrice
        orderCouponDiscountPrice
        mileageDiscountPrice
        fitpetFeeRate
        mileageRewardRate
        orderItemNumber
        productName
        orderStatus
        couponInfo
        checkedAt
        sentAt
        receivedAt
        confirmedAt
        cancelResponsibility
        cancelUser
        cancelRequestType
        cancelRequestMessage
        cancelRejectMessage
        cancelGuideMessage
        cancelRequestedAt
        cancelRejectedAt
        canceledAt
        refundAmount
        customerPrice
        originalAmount
        productDiscountPrice
        productDiscountAmount
        customerAmount
        orderStatusText
        productPromotionCustomerPrice
        productPromotionCustomerAmount
        productPromotionCouponCustomerAmount
        productPromotionCustomerPriceExists
        productPromotionCustomerAmountExists
        amountWithoutOrderCoupon
        amount
        returnRequestedAt
        returnAcceptedAt
        returnReturnedAt
        returnConfirmedAt
        returnRejectedAt
        returnRejectMessage
        returnRequestMessage
        returnResponsibility
        returnGuideMessage
        mileage
        reviewMileage
        imageReviewMileage
        orderConfirmMileage
        returnShippingFee
        exchangeShippingFee
        hasUserReview
        vbankName
        vbankAccountHolderName
        vbankAccountNumber
        refundable
        reviewWritableDatetime
        canPartialCancel
      }
    `
  },
  get orderRefundInfo() {
    return gql`
      fragment orderRefundInfo on OrderRefundInfoType {
        originalAmount
        productDiscountAmount
        productPromotionDiscountAmount
        couponDiscountAmount
        mileageDiscountAmount
        orderCouponDiscountAmount
        soldAmount
        totalRefundAmount
        returnShippingFeeAmount
        refundShippingFeeAmount
      }
    `
  },
  get order() {
    return gql`
      fragment order on OrderType {
        createdAt
        updatedAt
        id
        orderNumber
        originalAmount
        productDiscountAmount
        productPromotionDiscountAmount
        couponDiscountAmount
        orderCouponDiscountAmount
        mileageDiscountAmount
        shippingFees
        shippingExtraFees
        shippingDiscountAmount
        shippingMileageDiscountAmount
        shippingCouponDiscountAmount
        couponInfo
        productPromotionCustomerAmount
        amount
        shippingAmount
        mileage
        imageReviewMileage
        reviewMileage
        orderConfirmMileage
        totalItemQuantity
        canPartialCancel
      }
    `
  },
  get popularSearchTerm() {
    return gql`
      fragment popularSearchTerm on PopularSearchTermType {
        createdAt
        updatedAt
        id
        series
        term
      }
    `
  },
  get popupBannerInfo() {
    return gql`
      fragment popupBannerInfo on PopupBannerInfoType {
        adId
        adName
        adSlug
        mobileTitle
        mobileSubtitle
      }
    `
  },
  get productCategory() {
    return gql`
      fragment productCategory on ProductCategoryType {
        id
      }
    `
  },
  get productImage() {
    return gql`
      fragment productImage on ProductImageType {
        image
        id
        displayType
        thumbnail
      }
    `
  },
  get productOptionGroup() {
    return gql`
      fragment productOptionGroup on ProductOptionGroupType {
        id
        name
        isCustomGroup
        realName
        isShownProductName
      }
    `
  },
  get productOptionKind() {
    return gql`
      fragment productOptionKind on ProductOptionKindType {
        key
        value
      }
    `
  },
  get productOptionSku() {
    return gql`
      fragment productOptionSku on ProductOptionSkuType {
        id
        count
      }
    `
  },
  get productOption() {
    return gql`
      fragment productOption on ProductOptionType {
        id
        series
        price
        discountPrice
        isDefault
        isUse
        isDelete
        name
        customerPrice
        difference
        productPromotionCustomerPrice
        productPromotionDiscountPrice
        productOptionPrice
        stockQuantity
        purchasePrice
        productId
      }
    `
  },
  get productOptionValue() {
    return gql`
      fragment productOptionValue on ProductOptionValueType {
        id
        name
      }
    `
  },
  get productPromotion() {
    return gql`
      fragment productPromotion on ProductPromotionType {
        startedAt
        endedAt
        createdAt
        updatedAt
        id
        productPromotionType
        name
        description
        discountPrice
        fitpetFeeRate
        mileageRewardRate
        isDelete
        minOrderAmount
        maxOrderCount
        productPromotionCustomerPrice
        discountRate
        defaultFitpetFeeRate
        boughtCount
      }
    `
  },
  get productQna() {
    return gql`
      fragment productQna on ProductQnaType {
        createdAt
        updatedAt
        id
        questionBody
        answerBody
        answeredAt
        isSecret
        answeredUserType
        zendeskTicketId
        isAnswered
        isAdmin
        username
      }
    `
  },
  get productStatisticByInfluencerColumn() {
    return gql`
      fragment productStatisticByInfluencerColumn on ProductStatisticByInfluencerColumnType {
        influencerName
        influencerFeeRate
        influencerAmount
        brandName
        thumbnail
        productName
        productId
        totalCustomerCount
        totalSoldCount
        totalSoldAmount
      }
    `
  },
  get productStatisticBySellerColumn() {
    return gql`
      fragment productStatisticBySellerColumn on ProductStatisticBySellerColumnType {
        sellerName
        fitpetFeeRate
        fitpetAmount
        brandName
        thumbnail
        productName
        productId
        totalCustomerCount
        totalSoldCount
        totalSoldAmount
      }
    `
  },
  get productStatusCount() {
    return gql`
      fragment productStatusCount on ProductStatusCountType {
        updateRequestCount
        updateRejectedCount
        totalCount
        requestCount
        rejectedCount
        sellingCount
        preSellingCount
        soldOutCount
        suspendCount
        endSellingCount
        bannedCount
        deletedCount
      }
    `
  },
  get product() {
    return gql`
      fragment product on ProductType {
        displayOrder
        startedAt
        endedAt
        petType
        createdAt
        updatedAt
        id
        isShownNaver
        isShownDaum
        isShownFacebook
        isShownGoogle
        isShown
        isSoldOut
        isStockSoldOut
        name
        searchKeywords
        productNumber
        supplyType
        description
        correspondent
        fitpetFeeRate
        weight
        point
        productOptionType
        shippingGuide
        shippingNotice
        requestedAt
        approvedAt
        updateApprovedAt
        deletedAt
        realName
        reviewCount
        averageReviewScore
        stockQuantity
        price
        customerPrice
        productPromotionStartedAt
        productPromotionEndedAt
        productPromotionCustomerPrice
        productPromotionDiscountPrice
        productPromotionDiscountRate
        finalDiscountPrice
        finalDiscountRate
        discountRate
        discountPrice
        canDownloadCoupon
        couponDiscountPrice
        couponDiscountRate
        soldQuantity
        purchasePrice
        wishedCount
        isWished
        isTimesale
        mileage
        imageReviewMileage
        reviewMileage
        orderConfirmMileage
        isRealSoldOut
        isSingleOption
        reviewMileageRewardRate
        confirmMileageRewardRate
      }
    `
  },
  get push() {
    return gql`
      fragment push on PushType {
        id
        title
        text
        data
        pushType
        image
        url
        appUrl
        appData
        pushTypeDisplay
      }
    `
  },
  get pushUserHistory() {
    return gql`
      fragment pushUserHistory on PushUserHistoryType {
        createdAt
        updatedAt
        id
        deviceInfo
        isSuccess
        isRead
        readAt
      }
    `
  },
  get refundInfo() {
    return gql`
      fragment refundInfo on RefundInfoType {
        accountNumber
        accountHolderName
        bankCode
        bankName
        id
        originalPrice
        productDiscountPrice
        productPromotionDiscountPrice
        couponDiscountPrice
        mileageDiscountPrice
        orderCouponDiscountPrice
        returnShippingFee
        refundAmount
      }
    `
  },
  get refundShippingInfo() {
    return gql`
      fragment refundShippingInfo on RefundShippingInfoType {
        fee
        extraFee
        discountPrice
        couponDiscountPrice
        mileageDiscountPrice
        accountNumber
        accountHolderName
        bankCode
        bankName
        id
        returnShippingFee
        sumFee
        amountWithoutMileage
        amount
      }
    `
  },
  get remoteArea() {
    return gql`
      fragment remoteArea on RemoteAreaType {
        id
        address
        extraFee
      }
    `
  },
  get returnInfoImage() {
    return gql`
      fragment returnInfoImage on ReturnInfoImageType {
        image
        id
        thumbnail
      }
    `
  },
  get returnInfo() {
    return gql`
      fragment returnInfo on ReturnInfoType {
        createdAt
        updatedAt
        id
        responsibility
        returnUser
        returnShippingFee
        fitpetAdminName
        guideMessage
        requestType
        requestMessage
        rejectMessage
        acceptedAt
        returnedAt
        confirmedAt
        rejectedAt
      }
    `
  },
  get returnReason() {
    return gql`
      fragment returnReason on ReturnReasonType {
        requestType
        requestMessage
        responsibility
        isUserVisible
      }
    `
  },
  get reviewGroup() {
    return gql`
      fragment reviewGroup on ReviewGroupType {
        id
        name
        averageScore
        totalCount
        productCount
      }
    `
  },
  get reviewImage() {
    return gql`
      fragment reviewImage on ReviewImageType {
        image
        id
        thumbnail
      }
    `
  },
  get review() {
    return gql`
      fragment review on ReviewType {
        createdAt
        updatedAt
        id
        checkAdminAt
        productOptionName
        reviewBody
        answerBody
        answeredAt
        score
        weight
        isDelete
        isPhotoReview
        bestAt
        bestSeries
        mileage
        isAnswered
        isFirstReviewProduct
        username
        isLiked
        mileageText
        likedCount
      }
    `
  },
  get searchHistory() {
    return gql`
      fragment searchHistory on SearchHistoryType {
        petType
        createdAt
        updatedAt
        id
        searchKeyword
      }
    `
  },
  get sellerAddress() {
    return gql`
      fragment sellerAddress on SellerAddressType {
        postNumber
        basicAddress
        detailedAddress
        id
      }
    `
  },
  get sellerBilling() {
    return gql`
      fragment sellerBilling on SellerBillingType {
        billedAt
        baseAt
        confirmedAt
        billingType
        customerAmount
        couponDiscountAmount
        mileageDiscountAmount
        sellerAmount
        fitpetAmount
        id
        fitpetSupplyAmount
        fitpetPromotionAmount
        sellerSupplyAmount
        customerReturnFees
        customerShippingFees
        shippingDiscountAmount
        shippingCouponDiscountAmount
        shippingMileageDiscountAmount
        nextBillingAt
        billingStartedAt
        billingEndedAt
        sellerTotalAmount
        supplyAmount
        valuedAddedTaxAmount
      }
    `
  },
  get sellerStatisticColumn() {
    return gql`
      fragment sellerStatisticColumn on SellerStatisticColumnType {
        totalAmount
        totalSoldAmount
        totalCouponDiscountAmount
        totalMileageDiscountAmount
        totalCustomerCount
        totalOrderCount
        totalSoldCount
        guestUnitPrice
        avgProductPrice
        sellerName
        brandName
      }
    `
  },
  get sellerStatusCount() {
    return gql`
      fragment sellerStatusCount on SellerStatusCountType {
        totalCount
        requestCount
        approvedCount
        rejectedCount
        suspendCount
        bannedCount
        deletedCount
        updateRequestCount
        updateRejectedCount
      }
    `
  },
  get sellerTemplateAddress() {
    return gql`
      fragment sellerTemplateAddress on SellerTemplateAddressType {
        postNumber
        basicAddress
        detailedAddress
        isDefault
        id
      }
    `
  },
  get seller() {
    return gql`
      fragment seller on SellerType {
        createdAt
        updatedAt
        accountNumber
        accountHolderName
        bankCode
        bankName
        id
        companyName
        shopName
        companyOwnerName
        businessRegistrationNumber
        companyId
        postNumber
        basicAddress
        detailedAddress
        homepage
        businessCondition
        businessItem
        businessRegistration
        mailOrderReportNumber
        mailOrderReportRegistration
        bankBookImage
        telephoneNumber
        fitpetFeeRate
        accountManagerName
        accountManagerTelephoneNumber
        taxEmail
        notificationManagerName
        notificationEmail
        notificationMobileNumber
        returnExchangeGuide
        requestedAt
        approvedAt
        updateApprovedAt
        isFollowed
        soldQuantity
        soldQuantityLastMonth
        soldAmount
        productCount
        isFitpet
      }
    `
  },
  get sellerUser() {
    return gql`
      fragment sellerUser on SellerUserType {
        id
        authType
      }
    `
  },
  get serverConfig() {
    return gql`
      fragment serverConfig on ServerConfigType {
        startedAt
        endedAt
        createdAt
        updatedAt
        id
        configType
        key
        value
        description
      }
    `
  },
  get serviceQnaImage() {
    return gql`
      fragment serviceQnaImage on ServiceQnaImageType {
        image
        id
        thumbnail
      }
    `
  },
  get serviceQna() {
    return gql`
      fragment serviceQna on ServiceQnaType {
        createdAt
        updatedAt
        questionBody
        answerBody
        answeredAt
        id
        incomingEmail
        mobileNumber
        category
        questionType
        answeredUserType
        isAnswered
        isAdmin
        isPhoto
      }
    `
  },
  get shippingCompany() {
    return gql`
      fragment shippingCompany on ShippingCompanyType {
        id
        name
        sweetTrackerCode
        iamportCode
        isDelete
      }
    `
  },
  get shippingPreset() {
    return gql`
      fragment shippingPreset on ShippingPresetType {
        id
        name
        shippingFee
        freeShippingBaseAmount
        returnFee
        isPackage
        isPrepayment
        guide
        notice
        applyRemoteAreaFee
        shippingDeadline
      }
    `
  },
  get shippingTrack() {
    return gql`
      fragment shippingTrack on ShippingTrackType {
        shippingTrack
      }
    `
  },
  get shipping() {
    return gql`
      fragment shipping on ShippingType {
        id
        fee
        extraFee
        discountPrice
        couponDiscountPrice
        mileageDiscountPrice
        shippingType
        shippingNumbers
        sentAt
        receivedAt
        couponInfo
        shippingTypeText
        isTrackable
        untrackedReason
        amount
        sumFee
        isFitpet
        isRefundable
        shippingCompanyAndNumbers
      }
    `
  },
  get skuHistory() {
    return gql`
      fragment skuHistory on SkuHistoryType {
        createdAt
        updatedAt
        id
        beforeChanges
        afterChanges
      }
    `
  },
  get sku() {
    return gql`
      fragment sku on SkuType {
        createdAt
        updatedAt
        id
        code
        name
        stockQuantity
        purchasePrice
        price
        discountPrice
        barcodeNumber
        sabangnetProductNumber
        customerPrice
      }
    `
  },
  get slimBannerInfo() {
    return gql`
      fragment slimBannerInfo on SlimBannerInfoType {
        adId
        adName
        adSlug
      }
    `
  },
  get socialService() {
    return gql`
      fragment socialService on SocialServiceType {
        createdAt
        updatedAt
        socialType
        accessTokenIssuedAt
        lastLoggedAt
        id
      }
    `
  },
  get statusHistory() {
    return gql`
      fragment statusHistory on StatusHistoryType {
        createdAt
        updatedAt
        id
        requestType
        value
        message
      }
    `
  },
  get status() {
    return gql`
      fragment status on StatusType {
        createdAt
        updatedAt
        id
        requestType
        value
        text
        recentMessage
      }
    `
  },
  get subRollBannerInfo() {
    return gql`
      fragment subRollBannerInfo on SubRollBannerInfoType {
        adId
        adName
        adSlug
        title
        pcDescription
        mobileDescription
      }
    `
  },
  get termStatisticColumn() {
    return gql`
      fragment termStatisticColumn on TermStatisticColumnType {
        totalUserCount
        signInUserCount
        leftUserCount
        totalAmount
        totalSoldAmount
        totalCouponDiscountAmount
        totalMileageDiscountAmount
        totalCustomerCount
        totalOrderCount
        totalSoldCount
        guestUnitPrice
        avgProductPrice
        truncatedAt
      }
    `
  },
  get timeseriesValue() {
    return gql`
      fragment timeseriesValue on TimeseriesValueType {
        x
        y
      }
    `
  },
  get topRollBannerInfo() {
    return gql`
      fragment topRollBannerInfo on TopRollBannerInfoType {
        adId
        adName
        adSlug
        eventTypeText
        description
        subjectText
        benefitText
      }
    `
  },
  get totalInfluencerStatistic() {
    return gql`
      fragment totalInfluencerStatistic on TotalInfluencerStatisticType {
        totalInfluencerCount
        totalSoldCount
        totalSoldAmount
      }
    `
  },
  get totalSalesStatistic() {
    return gql`
      fragment totalSalesStatistic on TotalSalesStatisticType {
        totalAmount
        totalSoldAmount
        totalCouponDiscountAmount
        totalMileageDiscountAmount
        totalCustomerCount
        totalOrderCount
        totalSoldCount
        guestUnitPrice
        avgProductPrice
      }
    `
  },
  get totalSellerStatistic() {
    return gql`
      fragment totalSellerStatistic on TotalSellerStatisticType {
        totalSellerCount
        totalOrderCount
        totalSoldCount
        totalSoldAmount
      }
    `
  },
  get transaction() {
    return gql`
      fragment transaction on TransactionType {
        createdAt
        updatedAt
        id
        device
        payMethodType
        isEscrow
        merchantCode
        merchantUid
        iamportUid
        pgTid
        pgApprovedTid
        name
        amount
        taxFreeAmount
        cardQuota
        errorCode
        errorMessage
        transactionStatus
        pgProviderType
        payAppType
        startedAt
        approvedAt
        receiptUrl
        applyNumber
        vbankAccountNumber
        vbankBankCode
        vbankBankName
        vbankAccountHolderName
        vbankIssuedAt
        vbankEndedAt
        bankAccountNumber
        bankCode
        bankName
        bankAccountHolderName
        responseData
        webhookData
        createdAtMillis
        transactionStatusText
        paymentText
        tossPayToken
      }
    `
  },
  get updatedBrand() {
    return gql`
      fragment updatedBrand on UpdatedBrandType {
        listImage
        backgroundImage
      }
    `
  },
  get updatedProduct() {
    return gql`
      fragment updatedProduct on UpdatedProductType {
        statusValue
      }
    `
  },
  get updatedSeller() {
    return gql`
      fragment updatedSeller on UpdatedSellerType {
        businessRegistration
        mailOrderReportRegistration
        bankBookImage
      }
    `
  },
  get userCertification() {
    return gql`
      fragment userCertification on UserCertificationType {
        createdAt
        updatedAt
        id
        mobileNumber
        email
        confirmedAt
        certType
        expiredAt
      }
    `
  },
  get userCount() {
    return gql`
      fragment userCount on UserCountType {
        count
      }
    `
  },
  get userInfoAccount() {
    return gql`
      fragment userInfoAccount on UserInfoAccountType {
        loginAccountType
        lastLoggedAt
        createdAt
      }
    `
  },
  get userInfo() {
    return gql`
      fragment userInfo on UserInfoType {
        id
        email
        userStatus
      }
    `
  },
  get userSubscription() {
    return gql`
      fragment userSubscription on UserSubscriptionType {
        startedAt
        endedAt
        createdAt
        updatedAt
        id
        totalRound
        subscriptionStatus
        subscriptionInfo
      }
    `
  },
  get user() {
    return gql`
      fragment user on UserType {
        createdAt
        updatedAt
        accountNumber
        accountHolderName
        bankCode
        bankName
        id
        lastLogin
        isSuperuser
        firstName
        lastName
        email
        isStaff
        isActive
        dateJoined
        username
        name
        inactivatedAt
        activatedAt
        leftAt
        mobileNumber
        gradeUpdatedAt
        invitationCode
        inviteCount
        birthday
        gender
        updateApprovedAt
        passwordUpdatedAt
        godoId
        leftReason
        leftMessage
        agreeNightMarketing
        agreeNightMarketingAt
        agreeMarketing
        agreeMarketingAt
        agreeLocationBasedService
        agreeLocationBasedServiceAt
        agreeThirdPartyProvision
        agreeThirdPartyProvisionAt
        userStatus
        signInCount
        signInFailCount
        lastSignInFailedAt
        joinedPlatform
        displayPetType
        boughtDeal100
        sellerCount
        approvalSellerCount
        isSeller
        isInfluencer
        orderCount
        ongoingOrderCount
        reviewCount
        writableReviewCount
        cartItemCount
        cartItemQuantity
        orderAmount
        mileagePoint
        couponCount
        userStatusText
        hasEmailAccount
        lastOrderedAt
        unusedMileage
      }
    `
  },
  get weeklyBestBannerInfo() {
    return gql`
      fragment weeklyBestBannerInfo on WeeklyBestBannerInfoType {
        adId
        adName
        adSlug
        kindText
      }
    `
  },
  get adImageConnection() {
    return gql`
      fragment adImageConnection on AdImageTypeConnection {
        edges {
          node {
            ...adImage
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.adImage}
      ${this.pageInfo}
    `
  },
  get adConnection() {
    return gql`
      fragment adConnection on AdTypeConnection {
        edges {
          node {
            ...ad
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.ad}
      ${this.pageInfo}
    `
  },
  get asInfoConnection() {
    return gql`
      fragment asInfoConnection on AsInfoTypeConnection {
        edges {
          node {
            ...asInfo
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.asInfo}
      ${this.pageInfo}
    `
  },
  get bannerImageConnection() {
    return gql`
      fragment bannerImageConnection on BannerImageTypeConnection {
        edges {
          node {
            ...bannerImage
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.bannerImage}
      ${this.pageInfo}
    `
  },
  get bannerConnection() {
    return gql`
      fragment bannerConnection on BannerTypeConnection {
        edges {
          node {
            ...banner
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.banner}
      ${this.pageInfo}
    `
  },
  get billingItemConnection() {
    return gql`
      fragment billingItemConnection on BillingItemTypeConnection {
        edges {
          node {
            ...billingItem
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.billingItem}
      ${this.pageInfo}
    `
  },
  get brandConnection() {
    return gql`
      fragment brandConnection on BrandTypeConnection {
        edges {
          node {
            ...brand
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.brand}
      ${this.pageInfo}
    `
  },
  get cancelTransactionConnection() {
    return gql`
      fragment cancelTransactionConnection on CancelTransactionTypeConnection {
        edges {
          node {
            ...cancelTransaction
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.cancelTransaction}
      ${this.pageInfo}
    `
  },
  get cartItemHistoryConnection() {
    return gql`
      fragment cartItemHistoryConnection on CartItemHistoryTypeConnection {
        edges {
          node {
            ...cartItemHistory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.cartItemHistory}
      ${this.pageInfo}
    `
  },
  get cartItemConnection() {
    return gql`
      fragment cartItemConnection on CartItemTypeConnection {
        edges {
          node {
            ...cartItem
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.cartItem}
      ${this.pageInfo}
    `
  },
  get categoryConnection() {
    return gql`
      fragment categoryConnection on CategoryTypeConnection {
        edges {
          node {
            ...category
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.category}
      ${this.pageInfo}
    `
  },
  get couponGroupConnection() {
    return gql`
      fragment couponGroupConnection on CouponGroupTypeConnection {
        edges {
          node {
            ...couponGroup
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.couponGroup}
      ${this.pageInfo}
    `
  },
  get couponConnection() {
    return gql`
      fragment couponConnection on CouponTypeConnection {
        edges {
          node {
            ...coupon
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.coupon}
      ${this.pageInfo}
    `
  },
  get customerAddressConnection() {
    return gql`
      fragment customerAddressConnection on CustomerAddressTypeConnection {
        edges {
          node {
            ...customerAddress
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.customerAddress}
      ${this.pageInfo}
    `
  },
  get customerGradeConnection() {
    return gql`
      fragment customerGradeConnection on CustomerGradeTypeConnection {
        edges {
          node {
            ...customerGrade
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.customerGrade}
      ${this.pageInfo}
    `
  },
  get customerTemplateAddressConnection() {
    return gql`
      fragment customerTemplateAddressConnection on CustomerTemplateAddressTypeConnection {
        edges {
          node {
            ...customerTemplateAddress
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.customerTemplateAddress}
      ${this.pageInfo}
    `
  },
  get deviceConnection() {
    return gql`
      fragment deviceConnection on DeviceTypeConnection {
        edges {
          node {
            ...device
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.device}
      ${this.pageInfo}
    `
  },
  get displayCollectionConnection() {
    return gql`
      fragment displayCollectionConnection on DisplayCollectionTypeConnection {
        edges {
          node {
            ...displayCollection
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.displayCollection}
      ${this.pageInfo}
    `
  },
  get displayProductInfoConnection() {
    return gql`
      fragment displayProductInfoConnection on DisplayProductInfoTypeConnection {
        edges {
          node {
            ...displayProductInfo
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.displayProductInfo}
      ${this.pageInfo}
    `
  },
  get excelExportRequestConnection() {
    return gql`
      fragment excelExportRequestConnection on ExcelExportRequestTypeConnection {
        edges {
          node {
            ...excelExportRequest
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.excelExportRequest}
      ${this.pageInfo}
    `
  },
  get faqConnection() {
    return gql`
      fragment faqConnection on FaqTypeConnection {
        edges {
          node {
            ...faq
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.faq}
      ${this.pageInfo}
    `
  },
  get fitpetLinkConnection() {
    return gql`
      fragment fitpetLinkConnection on FitpetLinkTypeConnection {
        edges {
          node {
            ...fitpetLink
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.fitpetLink}
      ${this.pageInfo}
    `
  },
  get influencerStatisticColumnConnection() {
    return gql`
      fragment influencerStatisticColumnConnection on InfluencerStatisticColumnTypeConnection {
        edges {
          node {
            ...influencerStatisticColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.influencerStatisticColumn}
      ${this.pageInfo}
    `
  },
  get influencerConnection() {
    return gql`
      fragment influencerConnection on InfluencerTypeConnection {
        edges {
          node {
            ...influencer
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.influencer}
      ${this.pageInfo}
    `
  },
  get informEmailAttachmentConnection() {
    return gql`
      fragment informEmailAttachmentConnection on InformEmailAttachmentTypeConnection {
        edges {
          node {
            ...informEmailAttachment
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.informEmailAttachment}
      ${this.pageInfo}
    `
  },
  get informEmailQueueConnection() {
    return gql`
      fragment informEmailQueueConnection on InformEmailQueueTypeConnection {
        edges {
          node {
            ...informEmailQueue
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.informEmailQueue}
      ${this.pageInfo}
    `
  },
  get informPushQueueConnection() {
    return gql`
      fragment informPushQueueConnection on InformPushQueueTypeConnection {
        edges {
          node {
            ...informPushQueue
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.informPushQueue}
      ${this.pageInfo}
    `
  },
  get informSmsHistoryConnection() {
    return gql`
      fragment informSmsHistoryConnection on InformSmsHistoryTypeConnection {
        edges {
          node {
            ...informSmsHistory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.informSmsHistory}
      ${this.pageInfo}
    `
  },
  get informSmsQueueConnection() {
    return gql`
      fragment informSmsQueueConnection on InformSmsQueueTypeConnection {
        edges {
          node {
            ...informSmsQueue
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.informSmsQueue}
      ${this.pageInfo}
    `
  },
  get mileageHistoryConnection() {
    return gql`
      fragment mileageHistoryConnection on MileageHistoryTypeConnection {
        edges {
          node {
            ...mileageHistory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.mileageHistory}
      ${this.pageInfo}
    `
  },
  get mileageConnection() {
    return gql`
      fragment mileageConnection on MileageTypeConnection {
        edges {
          node {
            ...mileage
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.mileage}
      ${this.pageInfo}
    `
  },
  get noticeConnection() {
    return gql`
      fragment noticeConnection on NoticeTypeConnection {
        edges {
          node {
            ...notice
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.notice}
      ${this.pageInfo}
    `
  },
  get orderItemAdminNoteConnection() {
    return gql`
      fragment orderItemAdminNoteConnection on OrderItemAdminNoteTypeConnection {
        edges {
          node {
            ...orderItemAdminNote
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.orderItemAdminNote}
      ${this.pageInfo}
    `
  },
  get orderItemConnection() {
    return gql`
      fragment orderItemConnection on OrderItemTypeConnection {
        edges {
          node {
            ...orderItem
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.orderItem}
      ${this.pageInfo}
    `
  },
  get orderConnection() {
    return gql`
      fragment orderConnection on OrderTypeConnection {
        edges {
          node {
            ...order
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.order}
      ${this.pageInfo}
    `
  },
  get popularSearchTermConnection() {
    return gql`
      fragment popularSearchTermConnection on PopularSearchTermTypeConnection {
        edges {
          node {
            ...popularSearchTerm
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.popularSearchTerm}
      ${this.pageInfo}
    `
  },
  get productCategoryConnection() {
    return gql`
      fragment productCategoryConnection on ProductCategoryTypeConnection {
        edges {
          node {
            ...productCategory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.productCategory}
      ${this.pageInfo}
    `
  },
  get productImageConnection() {
    return gql`
      fragment productImageConnection on ProductImageTypeConnection {
        edges {
          node {
            ...productImage
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.productImage}
      ${this.pageInfo}
    `
  },
  get productOptionSkuConnection() {
    return gql`
      fragment productOptionSkuConnection on ProductOptionSkuTypeConnection {
        edges {
          node {
            ...productOptionSku
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.productOptionSku}
      ${this.pageInfo}
    `
  },
  get productOptionConnection() {
    return gql`
      fragment productOptionConnection on ProductOptionTypeConnection {
        edges {
          node {
            ...productOption
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.productOption}
      ${this.pageInfo}
    `
  },
  get productOptionValueConnection() {
    return gql`
      fragment productOptionValueConnection on ProductOptionValueTypeConnection {
        edges {
          node {
            ...productOptionValue
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.productOptionValue}
      ${this.pageInfo}
    `
  },
  get productPromotionConnection() {
    return gql`
      fragment productPromotionConnection on ProductPromotionTypeConnection {
        edges {
          node {
            ...productPromotion
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.productPromotion}
      ${this.pageInfo}
    `
  },
  get productQnaConnection() {
    return gql`
      fragment productQnaConnection on ProductQnaTypeConnection {
        edges {
          node {
            ...productQna
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.productQna}
      ${this.pageInfo}
    `
  },
  get productStatisticByInfluencerColumnConnection() {
    return gql`
      fragment productStatisticByInfluencerColumnConnection on ProductStatisticByInfluencerColumnTypeConnection {
        edges {
          node {
            ...productStatisticByInfluencerColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.productStatisticByInfluencerColumn}
      ${this.pageInfo}
    `
  },
  get productStatisticBySellerColumnConnection() {
    return gql`
      fragment productStatisticBySellerColumnConnection on ProductStatisticBySellerColumnTypeConnection {
        edges {
          node {
            ...productStatisticBySellerColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.productStatisticBySellerColumn}
      ${this.pageInfo}
    `
  },
  get productConnection() {
    return gql`
      fragment productConnection on ProductTypeConnection {
        edges {
          node {
            ...product
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.product}
      ${this.pageInfo}
    `
  },
  get pushUserHistoryConnection() {
    return gql`
      fragment pushUserHistoryConnection on PushUserHistoryTypeConnection {
        edges {
          node {
            ...pushUserHistory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.pushUserHistory}
      ${this.pageInfo}
    `
  },
  get refundShippingInfoConnection() {
    return gql`
      fragment refundShippingInfoConnection on RefundShippingInfoTypeConnection {
        edges {
          node {
            ...refundShippingInfo
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.refundShippingInfo}
      ${this.pageInfo}
    `
  },
  get remoteAreaConnection() {
    return gql`
      fragment remoteAreaConnection on RemoteAreaTypeConnection {
        edges {
          node {
            ...remoteArea
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.remoteArea}
      ${this.pageInfo}
    `
  },
  get returnInfoImageConnection() {
    return gql`
      fragment returnInfoImageConnection on ReturnInfoImageTypeConnection {
        edges {
          node {
            ...returnInfoImage
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.returnInfoImage}
      ${this.pageInfo}
    `
  },
  get returnInfoConnection() {
    return gql`
      fragment returnInfoConnection on ReturnInfoTypeConnection {
        edges {
          node {
            ...returnInfo
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.returnInfo}
      ${this.pageInfo}
    `
  },
  get reviewGroupConnection() {
    return gql`
      fragment reviewGroupConnection on ReviewGroupTypeConnection {
        edges {
          node {
            ...reviewGroup
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.reviewGroup}
      ${this.pageInfo}
    `
  },
  get reviewImageConnection() {
    return gql`
      fragment reviewImageConnection on ReviewImageTypeConnection {
        edges {
          node {
            ...reviewImage
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.reviewImage}
      ${this.pageInfo}
    `
  },
  get reviewConnection() {
    return gql`
      fragment reviewConnection on ReviewTypeConnection {
        edges {
          node {
            ...review
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.review}
      ${this.pageInfo}
    `
  },
  get searchHistoryConnection() {
    return gql`
      fragment searchHistoryConnection on SearchHistoryTypeConnection {
        edges {
          node {
            ...searchHistory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.searchHistory}
      ${this.pageInfo}
    `
  },
  get sellerAddressConnection() {
    return gql`
      fragment sellerAddressConnection on SellerAddressTypeConnection {
        edges {
          node {
            ...sellerAddress
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.sellerAddress}
      ${this.pageInfo}
    `
  },
  get sellerBillingConnection() {
    return gql`
      fragment sellerBillingConnection on SellerBillingTypeConnection {
        edges {
          node {
            ...sellerBilling
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.sellerBilling}
      ${this.pageInfo}
    `
  },
  get sellerStatisticColumnConnection() {
    return gql`
      fragment sellerStatisticColumnConnection on SellerStatisticColumnTypeConnection {
        edges {
          node {
            ...sellerStatisticColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.sellerStatisticColumn}
      ${this.pageInfo}
    `
  },
  get sellerTemplateAddressConnection() {
    return gql`
      fragment sellerTemplateAddressConnection on SellerTemplateAddressTypeConnection {
        edges {
          node {
            ...sellerTemplateAddress
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.sellerTemplateAddress}
      ${this.pageInfo}
    `
  },
  get sellerConnection() {
    return gql`
      fragment sellerConnection on SellerTypeConnection {
        edges {
          node {
            ...seller
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.seller}
      ${this.pageInfo}
    `
  },
  get sellerUserConnection() {
    return gql`
      fragment sellerUserConnection on SellerUserTypeConnection {
        edges {
          node {
            ...sellerUser
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.sellerUser}
      ${this.pageInfo}
    `
  },
  get serverConfigConnection() {
    return gql`
      fragment serverConfigConnection on ServerConfigTypeConnection {
        edges {
          node {
            ...serverConfig
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.serverConfig}
      ${this.pageInfo}
    `
  },
  get serviceQnaImageConnection() {
    return gql`
      fragment serviceQnaImageConnection on ServiceQnaImageTypeConnection {
        edges {
          node {
            ...serviceQnaImage
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.serviceQnaImage}
      ${this.pageInfo}
    `
  },
  get serviceQnaConnection() {
    return gql`
      fragment serviceQnaConnection on ServiceQnaTypeConnection {
        edges {
          node {
            ...serviceQna
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.serviceQna}
      ${this.pageInfo}
    `
  },
  get shippingCompanyConnection() {
    return gql`
      fragment shippingCompanyConnection on ShippingCompanyTypeConnection {
        edges {
          node {
            ...shippingCompany
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.shippingCompany}
      ${this.pageInfo}
    `
  },
  get shippingPresetConnection() {
    return gql`
      fragment shippingPresetConnection on ShippingPresetTypeConnection {
        edges {
          node {
            ...shippingPreset
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.shippingPreset}
      ${this.pageInfo}
    `
  },
  get shippingConnection() {
    return gql`
      fragment shippingConnection on ShippingTypeConnection {
        edges {
          node {
            ...shipping
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.shipping}
      ${this.pageInfo}
    `
  },
  get skuHistoryConnection() {
    return gql`
      fragment skuHistoryConnection on SkuHistoryTypeConnection {
        edges {
          node {
            ...skuHistory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.skuHistory}
      ${this.pageInfo}
    `
  },
  get skuConnection() {
    return gql`
      fragment skuConnection on SkuTypeConnection {
        edges {
          node {
            ...sku
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.sku}
      ${this.pageInfo}
    `
  },
  get socialServiceConnection() {
    return gql`
      fragment socialServiceConnection on SocialServiceTypeConnection {
        edges {
          node {
            ...socialService
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.socialService}
      ${this.pageInfo}
    `
  },
  get statusHistoryConnection() {
    return gql`
      fragment statusHistoryConnection on StatusHistoryTypeConnection {
        edges {
          node {
            ...statusHistory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.statusHistory}
      ${this.pageInfo}
    `
  },
  get statusConnection() {
    return gql`
      fragment statusConnection on StatusTypeConnection {
        edges {
          node {
            ...status
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.status}
      ${this.pageInfo}
    `
  },
  get termStatisticColumnConnection() {
    return gql`
      fragment termStatisticColumnConnection on TermStatisticColumnTypeConnection {
        edges {
          node {
            ...termStatisticColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.termStatisticColumn}
      ${this.pageInfo}
    `
  },
  get transactionConnection() {
    return gql`
      fragment transactionConnection on TransactionTypeConnection {
        edges {
          node {
            ...transaction
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.transaction}
      ${this.pageInfo}
    `
  },
  get updatedProductOptionConnection() {
    return gql`
      fragment updatedProductOptionConnection on UpdatedProductOptionTypeConnection {
        edges {
          node {
            ...updatedProductOption
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.updatedProductOption}
      ${this.pageInfo}
    `
  },
  get userSubscriptionConnection() {
    return gql`
      fragment userSubscriptionConnection on UserSubscriptionTypeConnection {
        edges {
          node {
            ...userSubscription
          }
        }
        pageInfo {
          ...pageInfo
        }
      }
      ${this.userSubscription}
      ${this.pageInfo}
    `
  },
  get userConnection() {
    return gql`
      fragment userConnection on UserTypeConnection {
        edges {
          node {
            ...user
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
      ${this.user}
      ${this.pageInfo}
    `
  },
}

export default DEFAULT_FRAGMENTS
