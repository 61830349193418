import { Image } from 'antd'

import * as utilData from '@libs/utils/utilData'
import { SERVICE_QNA_CATEGORY_TEXT } from '@constants/constDomain'
import { MLinkRouterButton, Text14Normal } from 'components/@atomics'
import { ITableColumn, ITableColumnFixedProps } from 'components/@atomics/MTable'
import { IModelServiceQna } from 'containers/models/modelServiceQna'
import { IModelProductImage } from 'containers/models/modelProductImage'
import { IModelProduct } from 'containers/models/modelProduct'
import { IModelSeller } from 'containers/models/modelSeller'

const category = ({ dataIndex = 'category' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '문의유형',
    dataIndex,
    width: 140,
    render: (_: string) => {
      const ORDER_QNA_CATEGORY_KEY = _ as keyof typeof SERVICE_QNA_CATEGORY_TEXT
      return <Text14Normal>{SERVICE_QNA_CATEGORY_TEXT[ORDER_QNA_CATEGORY_KEY]}</Text14Normal>
    },
  }
}

const seller = ({ dataIndex = 'seller' } = {}): ITableColumn<any> => {
  return {
    title: '상품 셀러',
    dataIndex,
    width: 100,
    render: (v: IModelSeller) => {
      return <Text14Normal>{utilData.tableText(v?.shopName)}</Text14Normal>
    },
  }
}

const productImage = ({ title = '이미지', fixed = false, width = 60 } = {}): ITableColumn<any> => {
  return {
    title,
    fixed,
    width,
    render: (v: IModelServiceQna) => {
      const productImages: IModelProductImage[] = v.orderItem?.productOption?.product
        ?.productProductImages as IModelProductImage[]
      let mainImageThumbnail: string = productImages?.find((img) => img.displayType === 'MAIN')?.thumbnail as string
      if (mainImageThumbnail) {
        mainImageThumbnail = utilData.getFullUrl(mainImageThumbnail)
      } else {
        mainImageThumbnail = '/sample/sample_profile.png'
      }
      return <Image preview={false} src={mainImageThumbnail} alt="product main image" width={40} height={40} />
    },
  }
}

const productCode = () => {
  return {
    title: '상품코드',
    key: 'id',
    width: 150,
    render: (v: IModelServiceQna) => {
      const product: IModelProduct = v?.orderItem?.productOption?.product as IModelProduct
      return (
        <MLinkRouterButton openNewWindow href={`${process.env.FRONT_WEB_DOMAIN}/products/${product?._id}`}>
          {product?._id}
        </MLinkRouterButton>
      )
    },
  }
}

export {
  category, // 문의유형
  seller, // 셀러
  productImage, // 상품 이미지
  productCode, // 상품 코드
}
