import { FC } from 'react'
import { TreeSelect } from 'antd'
import { MFormItemWrapper } from 'components/formItems'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { FIELD_KEYS } from '@constants/constData'
import { TypeCategoryData } from 'domains/admin/formItems/FormItemSelectCategory'

export type MFormItemTreeSelectProps = {
  treeData: TypeCategoryData[]
  disabled?: boolean
  onChange?: (value: any) => void
  placeholder?: string
} & MFormItemWrapperProps

const MFormItemTreeSelect: FC<MFormItemTreeSelectProps> = ({
  treeData,
  disabled = false,
  onChange,
  placeholder,
  ...formItemWrapper
}) => {
  return (
    <MFormItemWrapper {...formItemWrapper}>
      <TreeSelect
        allowClear
        treeCheckable
        treeCheckStrictly
        treeDefaultExpandAll
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        treeData={treeData}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        treeNodeLabelProp={FIELD_KEYS.FIELD_TREE_NODE_LABEL}
        treeNodeFilterProp={FIELD_KEYS.FIELD_TREE_NODE_LABEL}
      />
    </MFormItemWrapper>
  )
}

export default MFormItemTreeSelect
