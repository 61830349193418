import produce from 'immer'
import { IModelInitData } from 'containers/models/modelInitData'
import { createAction, handleActions } from 'redux-actions'
import { IModelFakeWhoami } from 'containers/models/modelFakeWhoami'
// import ModelUser, { IModelUser } from 'containers/models/modelUser'
// import { localWhoami } from '@libs/utils/utilCommon'

const SET_USER_DATA = 'data/SET_USER_DATA'
const SET_INIT_DATA = 'data/SET_INIT_DATA'

export const doSetFakeWhoamiData = createAction(SET_USER_DATA, (data: IModelFakeWhoami) => data)
export const doSetInitData = createAction(SET_INIT_DATA, (data: any) => data)

export type ReduxDataState = {
  fakeWhoamiData?: IModelFakeWhoami
  initData: IModelInitData
}

const initialState: ReduxDataState = {
  // fakeWhoamiData: new ModelFakeWhoami(localWhoami.load()),
  fakeWhoamiData: undefined,
  initData: {} as IModelInitData,
}

export default handleActions<ReduxDataState, any>(
  {
    [SET_USER_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.fakeWhoamiData = data
      }),
    [SET_INIT_DATA]: (state, { payload: data }) =>
      produce(state, (draft) => {
        draft.initData = data
      }),
  },
  initialState
)
