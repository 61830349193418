import { ReactNode, CSSProperties } from 'react'
import { Form, Space } from 'antd'
import styled from 'styled-components'
import { Store, ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { FIELD_KEYS } from '@constants/constData'
import { DomainModalProps } from 'containers/hooks/useModal'
import { MFormItemInput, MFormItemInputNumber, MFormItemTextArea } from 'components/formItems'
import { MModal } from 'components/modals'
import { Text14Normal } from 'components/@atomics'

export type MConfirmInputModalInputOptionProps = {
  type: 'input' | 'textarea' | 'number'
  name: string
  label?: string
  placeholder?: string
  suffix?: string
  inputStyle?: CSSProperties
  required?: boolean
}

type ContentProps = {
  description: string | ReactNode
  disableContent?: string
  inputOption?: MConfirmInputModalInputOptionProps
  Component?: JSX.Element
}

export type MConfirmInputModalProps = {
  ids?: string[]
}

export type MFixedProps = ContentProps & {
  title: 'text' | string
  okButtonDisable?: boolean
}

const MConfirmInputModal = ({
  useModalProps,
  onFormAction,
  onChange,
  fixedProps,
  loading,
}: DomainModalProps<MConfirmInputModalProps, MFixedProps>) => {
  const {
    title,
    description,
    inputOption,
    Component,
    disableContent = '반려 사유를 입력해 주세요.',
    okButtonDisable = false,
  } = fixedProps
  const { hideModal } = useModalProps
  let FormItemInput
  switch (inputOption && inputOption.type) {
    case 'input':
      FormItemInput = MFormItemInput
      break
    case 'textarea':
      FormItemInput = MFormItemTextArea
      break
    case 'number':
      FormItemInput = MFormItemInputNumber
      break
    default:
      break
  }

  const [form] = Form.useForm()

  const onFinish = (values: Store) => {
    if (onFormAction) {
      onFormAction(values)
    }
    hideModal()
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo)
  }

  const handleOnChange = (e: any) => {
    if (onChange) onChange(e)
  }

  return (
    <Form
      form={form}
      initialValues={{ [FIELD_KEYS.FIELD_MESSAGE]: undefined }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onChange={handleOnChange}
    >
      <MModal
        title={title}
        useModalProps={useModalProps}
        form={form}
        modalDisable={okButtonDisable}
        disableContent={disableContent}
        ComponentContent={
          <StyledSpace direction="vertical" size="middle">
            <Text14Normal>{description}</Text14Normal>
            {Component && Component}
            {FormItemInput && <FormItemInput {...inputOption} />}
          </StyledSpace>
        }
        loading={loading}
      />
    </Form>
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default MConfirmInputModal
