import useScrollLock from '@reboot/hooks/useScrollLock'
import { MouseEventHandler, ReactNode, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { ModalProps } from '../../hooks/useModal'

interface Props {
  children: ReactNode
  modalProps: ModalProps
}

const BaseModal = ({ children, modalProps }: Props) => {
  const [portal, setPortal] = useState<HTMLElement | null>(null)

  useScrollLock(true)
  const contentRef = useRef(null)

  useEffect(() => {
    setPortal(document.getElementById('modal-root'))
  }, [])

  if (!portal) {
    return null
  }

  const handleDimClick: MouseEventHandler = (e) => {
    if (e.target === contentRef.current) {
      e.stopPropagation()
      modalProps.hideModal()
    }
  }

  return ReactDOM.createPortal(
    <Container>
      <Overlay />
      <Content ref={contentRef} onClick={handleDimClick}>
        {children}
      </Content>
    </Container>,
    portal
  )
}

export default BaseModal

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 1000vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
`
