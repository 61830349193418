import DaumPostcode, { AddressData } from 'react-daum-postcode'

import MModal, { MModalProps } from 'components/modals/MModal'
import { DomainModalProps } from 'containers/hooks/useModal'

const handleResult = (data: AddressData) => {
  let fullAddress = data.address
  let extraAddress = ''

  if (data.addressType === 'R') {
    if (data.bname !== '') {
      extraAddress += data.bname
    }
    if (data.buildingName !== '') {
      extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
    }
    fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
  }
  // console.log(fullAddress) // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
  return { basicAddress: fullAddress, postNumber: data.zonecode }
}

const ModalDaumPostcode = ({ useModalProps }: DomainModalProps<MModalProps<any>, undefined>) => {
  const { setActionParams, hideModal } = useModalProps
  const Content = () => {
    return (
      <DaumPostcode
        onComplete={(data) => {
          setActionParams(handleResult(data))
          hideModal()
        }}
      />
    )
  }

  return (
    <MModal
      title="우편번호/주소 검색"
      useModalProps={useModalProps}
      ComponentContent={<Content />}
      footerType="MODAL_FOOTER_CANCEL"
    />
  )
}

export default ModalDaumPostcode
