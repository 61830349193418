import { FC } from 'react'
import styled from 'styled-components'
import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'

type MFormInput = {
  width?: number
  onChange?: (value: any) => void
} & InputProps

const MFormInput: FC<MFormInput> = ({
  width,
  placeholder,
  suffix,
  prefix,
  style,
  onChange,
  disabled,
  type,
  ...inputProps
}) => {
  return (
    <StyledInput
      width={width}
      placeholder={placeholder}
      suffix={suffix}
      prefix={prefix}
      style={style}
      onChange={onChange}
      disabled={disabled}
      type={type}
      {...inputProps}
    />
  )
}

// @ts-ignore
const StyledInput = styled(Input)`
  ${(props: InputProps) => setInputStyle(props)}
`

const setInputStyle = (props: InputProps) => {
  const defaultStyle = `
      width: ${props.width}px;
      margin-right: ${props.width ? 5 : 0}px;
      
      .ant-input-prefix {
        margin-right: ${props.prefix ? 20 : 0}px;
      }
    `
  if (props.style) {
    return props.style
  }
  return defaultStyle
}

export default MFormInput
