import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const CUSTOMER_GRADE_QUERY_GQLS = {
  CUSTOMER_GRADE: gql`
    query customerGrade($id: ID!) {
      customerGrade(id: $id) {
        ...customerGrade
        fitpetAdmin {
          name
        }
        couponGroups {
          edges {
            node {
              ...couponGroup
            }
          }
        }
        userCount
        gradeUsers {
          totalCount
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.couponGroup}
  `,
  CUSTOMER_GRADE_TABLE: gql`
    query customerGradeTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      customerGrades(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            id
            series
            name
            userCount
            rewardRate
            createdAt
            isActivated
            minOrderAmount
            maxOrderAmount
            minOrderCount
            maxOrderCount
            conditionText
            couponGroups {
              edges {
                node {
                  id
                  name
                  discountType
                  discountAmount
                  discountRate
                }
              }
            }
            gradeUsers {
              totalCount
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  SELECT_CUSTOMER_GRADES: gql`
    query selectCustomerGrades {
      customerGrades {
        edges {
          node {
            id
            name
            pushUserCount
            pushMarketingUserCount
          }
        }
      }
    }
  `,
}

export default CUSTOMER_GRADE_QUERY_GQLS
