import { MFormItemWrapper } from 'components'
import { queryParamsProps } from 'components/MQuery'
import { useRef, FC } from 'react'
import { message, Radio, Form } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { MutationFunction } from '@apollo/client'

import { MILEAGE_TYPE_TEXT, MILEAGE_TYPE } from '@constants/constDomain'
import { IModelUser } from 'containers/models/modelUser'
import MMainTable from 'components/MMainTable'
import { ITableColumn } from 'components/@atomics/MTable'
import tableCols from 'domains/admin/tableCols'
import { MConfirmModal } from 'components/modals'
import { MFormItemInfo, MFormItemInput, MFormItemSelect } from 'components/formItems'
import { MButton, Text14Normal } from 'components/@atomics'
import MMutation from 'components/MMutation'
import useModal from 'containers/hooks/useModal'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { IModelMileageHistory } from 'containers/models/modelMileageHistory'

const MILEAGE_TYPE_OPTIONS = [
  { value: MILEAGE_TYPE.ADMIN, title: MILEAGE_TYPE_TEXT[MILEAGE_TYPE.ADMIN] },
  { value: MILEAGE_TYPE.ORDER_ITEM_CONFIRM_CANCELED, title: MILEAGE_TYPE_TEXT[MILEAGE_TYPE.ORDER_ITEM_CANCELED] },
  { value: MILEAGE_TYPE.UNUSED, title: MILEAGE_TYPE_TEXT[MILEAGE_TYPE.UNUSED] },
]

type ConfirmMileageModalProps = {
  form: FormInstance<any>
}

const ConfirmMileageModal: FC<ConfirmMileageModalProps> = ({ form }) => {
  const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 10 },
  }
  return (
    <Form initialValues={{ isPlus: 'true' }} form={form}>
      <MFormItemInfo label="지급/차감 여부" {...formItemLayout}>
        <MFormItemWrapper name="isPlus" style={{ marginBottom: 0 }}>
          <Radio.Group>
            <Radio value="true">지급(+)</Radio>
            <Radio value="false">차감(-)</Radio>
          </Radio.Group>
        </MFormItemWrapper>
      </MFormItemInfo>

      <MFormItemInput
        name="price"
        label="금액"
        type="number"
        suffix="원"
        rules={[{ required: true, message: '금액을 설정해주세요.' }]}
        {...formItemLayout}
      />

      <MFormItemSelect
        placeholder="선택"
        name="mileageType"
        label="지급/차감 사유"
        selectOptions={MILEAGE_TYPE_OPTIONS}
        rules={[{ required: true, message: '사유를 선택해주세요.' }]}
        {...formItemLayout}
      />
    </Form>
  )
}

const tableColumns: Array<ITableColumn<any>> = [
  tableCols.common.ID(),
  tableCols.common.baseText({ title: '지금액', dataIndex: 'provideText' }),
  {
    title: '유효기간',
    render: (v: IModelMileageHistory) => {
      const { expiredAtText, deductionText } = v
      const displayText = deductionText === '' ? expiredAtText : '-'
      return <Text14Normal>{displayText}</Text14Normal>
    },
  },
  tableCols.common.baseText({ title: '차감액', dataIndex: 'deductionText' }),
  tableCols.common.baseText({ title: '잔액', dataIndex: 'afterMileage' }),
  tableCols.common.baseText({ title: '지급/차감 일시', dataIndex: 'createdAtText' }),
  tableCols.common.baseText({ title: '처리자', dataIndex: 'fitpetAdminName' }),
  tableCols.common.baseText({ title: '사유', dataIndex: 'mileageTypeText' }),
]

const MainTableMileageHistoryList = ({
  data,
  refetchUserDetail,
}: {
  data: IModelUser
  refetchUserDetail?: Function
}) => {
  const [form] = Form.useForm()
  const { useModalProps } = useModal()
  const refetchRef = useRef<any>(null)

  const queryParams: queryParamsProps = {
    gqlKey: 'mileageHistories',
    variables: {
      filter: { user: data.id },
      orderBy: '-createdAt',
    },
  }

  return (
    <>
      <MMainTable
        HeaderLeftButtons={[
          <MButton
            type="primary"
            onClick={() => {
              useModalProps.toggleModal()
            }}
          >
            적립금 지급/차감
          </MButton>,
        ]}
        TitleSet={{ title: '전체 목록', unit: '건' }}
        columns={tableColumns}
        queryParams={queryParams}
        refetchRef={refetchRef}
        // exportExcel={false}
        listButton
      />
      <MMutation
        gqlKey="updateMileage"
        onAPISuccess={() => {
          if (refetchRef?.current) {
            message.success('적립금이 지급/차감 되었습니다.')
            form.resetFields()
            refetchRef.current()
            if (refetchUserDetail) {
              refetchUserDetail()
            }
          }
        }}
      >
        {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
          return (
            // TODO yoon: ModalConfirm 으로 변경 필요
            <MConfirmModal
              useModalProps={useModalProps}
              onAction={async () => {
                const result = await form.validateFields()
                if (result) {
                  const isPlus: boolean = JSON.parse(form.getFieldValue('isPlus'))
                  const price: number = Number(form.getFieldValue('price'))
                  const mileageType: string = form.getFieldValue('mileageType')

                  const point = isPlus ? price : -price
                  if (data.mileagePoint + point < 0) {
                    message.error('보유 마일리지보다 더 차감할 수 없습니다.')
                    return
                  }
                  const variables = MUTATION_VARIABLES.UPDATE_MILEAGE({
                    id: data.id,
                    point,
                    mileageType,
                  })
                  if (point && mileageType) {
                    await mutation({ variables })
                  }
                }
              }}
              fixedProps={{
                title: '적립금 지급/차감',
                description: '',
                Component: <ConfirmMileageModal form={form} />,
              }}
              loading={loading}
            />
          )
        }}
      </MMutation>
    </>
  )
}

export default MainTableMileageHistoryList
