import { Checkbox } from 'antd'

type MCheckBoxSingleProps = {
  text: string
  onChange?: (value: boolean) => void
  checked?: boolean
  disabled?: boolean
}

const MCheckBoxSingle: React.FC<MCheckBoxSingleProps> = (props) => {
  const { text, onChange, checked, disabled } = props
  return (
    <Checkbox
      checked={checked}
      disabled={disabled}
      onChange={({ target }) => {
        if (onChange) {
          onChange(target.checked)
        }
      }}
    >
      {text}
    </Checkbox>
  )
}

export default MCheckBoxSingle
