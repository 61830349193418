import { USER_MODE } from '@constants/constDomain'
import useUserInfo from 'containers/hooks/useUserInfo'
import _ from 'lodash'
import { TypeSkuWithCount } from 'domains/common/formItems/FormItemSkus'
import React, { useRef, useState } from 'react'
import { FIELD_KEYS, FIELD_VALUES, TRANSFER_TABLE_PAGE_SIZE } from '@constants/constData'
import { FORM_ITEM_RULES } from '@constants/constForm'
import { MFormItemInputNumber, MFormModalFilter } from 'components'
import { MTableTransfer, Text14Normal } from 'components/@atomics'
import { TableTransferColumn } from 'components/@atomics/MTableTransfer'
import { FilterOptionsCommon } from 'components/MFormModalFilter'
import MQuery, { queryParamsProps } from 'components/MQuery'
import { TypeUseModalProps } from 'containers/hooks/useModal'
import { onTransferProps } from 'domains/common/modals/ModalTableTransferSelect'
import * as utilData from '@libs/utils/utilData'

type TableTransferSkuProps = {
  onTransfer: (values: onTransferProps) => void
  useModalProps: TypeUseModalProps<any>
  chosenValues?: TypeSkuWithCount[]
  disableCheckedNumber?: number
}

const formInputOptions: FilterOptionsCommon[] = [
  { label: FIELD_VALUES.FIELD_SKU_CODE, name: FIELD_KEYS.FIELD_CODE, labelCol: { span: 6 } },
  { label: FIELD_VALUES.FIELD_SKU_NAME, name: FIELD_KEYS.FIELD_NAME, labelCol: { span: 6 } },
]

const leftTableColumns: TableTransferColumn<TypeSkuWithCount>[] = [
  { dataIndex: '_id', title: 'ID' },
  {
    title: FIELD_VALUES.FIELD_SKU_CODE,
    dataIndex: FIELD_KEYS.FIELD_CODE,
    render: (value: TypeSkuWithCount[FIELD_KEYS.FIELD_CODE]) => {
      return (
        <Text14Normal
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}
        >
          {value}
        </Text14Normal>
      )
    },
  },
  {
    title: FIELD_VALUES.FIELD_SKU_NAME,
    dataIndex: FIELD_KEYS.FIELD_NAME,
    render: (value: TypeSkuWithCount[FIELD_KEYS.FIELD_NAME]) => {
      return (
        <Text14Normal
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}
        >
          {value}
        </Text14Normal>
      )
    },
  },
  {
    title: FIELD_VALUES.FIELD_STOCK_QUANTITY,
    dataIndex: FIELD_KEYS.FIELD_STOCK_QUANTITY,
    width: 100,
    render: (value: TypeSkuWithCount[FIELD_KEYS.FIELD_STOCK_QUANTITY]) => {
      return <Text14Normal>{utilData.numberCountText(value)}</Text14Normal>
    },
  },
]

const rightTableColumns: TableTransferColumn<TypeSkuWithCount>[] = [
  ...leftTableColumns,
  {
    title: '수량',
    width: 100,
    render: (record) => {
      return (
        <MFormItemInputNumber
          name={[record.id, FIELD_KEYS.FIELD_COUNT]}
          style={{ margin: 'auto' }}
          initialValue={record.count !== 0 ? record.count : undefined}
          inputNumberProps={{ min: 1 }}
          rules={[FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED]}
        />
      )
    },
  },
]

const TableTransferSku: React.FC<TableTransferSkuProps> = ({ onTransfer, useModalProps, chosenValues }) => {
  const [filterVariables, setFilterVariables] = useState<{}>({})
  const unionSkusRef = useRef<TypeSkuWithCount[]>([])
  const targetSkusRef = useRef<TypeSkuWithCount[]>(chosenValues || [])
  // chosenValues를 useRef에 적용해야 chosenValues이 변경되더라도, 두 번 query 요청 안함.
  // 두번 요청 하는 경우, 서버에서 ConnectionResetError: [Errno 54] Connection reset by peer 에러 발생
  const excludeSkus = useRef<string[]>(chosenValues!.map((sku) => sku.id))
  const refetchRef = useRef<any>(null)
  const { currentRoleInfo } = useUserInfo.useCurrentUserData()
  const queryParams: queryParamsProps = {
    gqlKey: 'skus',
    variables: {
      filter: {
        ...filterVariables,
        seller: currentRoleInfo.type === USER_MODE.SELLER ? currentRoleInfo.key : useModalProps.actionParams.seller,
        excludeSkus: excludeSkus.current,
      },
      pageInfo: { first: TRANSFER_TABLE_PAGE_SIZE },
    },
  }

  return (
    <>
      <MFormModalFilter
        formInputOptions={formInputOptions}
        setFilterVariables={setFilterVariables}
        refetchRef={refetchRef}
      />
      <MQuery queryParams={queryParams}>
        {({ data, refetch }: any) => {
          if (refetchRef) {
            refetchRef.current = refetch
          }

          unionSkusRef.current = _.unionBy(data?.skus?.data, targetSkusRef.current, 'id')
          const totalCount = data?.skus?.totalCount || unionSkusRef.current.length
          const skusDataSource = unionSkusRef.current.map((sku) => {
            const skuData = {
              key: sku.id,
              id: sku.id,
              _id: sku._id,
              code: sku.code,
              name: sku.name || '-',
              purchasePrice: sku.purchasePrice,
              stockQuantity: sku.stockQuantity,
              count: 0,
            }

            // update count
            chosenValues?.forEach((chosenSku) => {
              if (chosenSku.id === skuData.id) {
                skuData.count = chosenSku.count
                return skuData
              }
            })

            return skuData
          })

          return (
            <MTableTransfer
              useModalProps={useModalProps}
              totalCount={totalCount}
              dataSource={skusDataSource}
              initialTargetKeys={targetSkusRef.current.map((sku) => sku.id)}
              showSelectAll={false}
              onTransfer={(nextTargetKeys: string[]) => {
                targetSkusRef.current = unionSkusRef.current.filter((sku) => nextTargetKeys.includes(sku.id))
                onTransfer({ values: targetSkusRef.current })
              }}
              listStyle={{}}
              locale={{ itemUnit: '건', itemsUnit: '건' }}
              filterOption={(inputValue: string, option: TypeSkuWithCount) => {
                return option?.code?.indexOf(inputValue) > -1 || option?.name?.indexOf(inputValue) > -1
              }}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
            />
          )
        }}
      </MQuery>
    </>
  )
}

export default TableTransferSku
