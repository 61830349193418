import { MLinkRouterButton } from 'components/@atomics'
import ROUTES from '@constants/constRoutes'
import React from 'react'

const RouteToExcelDownloadList = () => {
  return (
    <MLinkRouterButton href={`${ROUTES.ETC.EXCEL_REQUEST_LIST}`} buttonType="button">
      엑셀 다운로드 바로가기
    </MLinkRouterButton>
  )
}

export default RouteToExcelDownloadList
