import { gql } from '@apollo/client'
import USER_FRAGMENTS from 'containers/gqls/accounts/user/fragments'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const USER_MUTATION_GQLS = {
  TOKEN_SIGNIN: gql`
    mutation tokenSignin($input: TokenSigninInput!) {
      tokenSignin(input: $input) {
        user {
          ...userSignupSignin
        }
        device {
          token
        }
      }
    }
    ${USER_FRAGMENTS.userSignupSignin}
  `,
  EMAIL_SIGNIN: gql`
    mutation emailSignin($input: EmailSigninInput!) {
      emailSignin(input: $input) {
        user {
          ...userSignupSignin
        }
        device {
          token
        }
      }
    }
    ${USER_FRAGMENTS.userSignupSignin}
  `,
  EMAIL_SIGNUP: gql`
    mutation emailSignup($input: EmailSignupInput!) {
      emailSignup(input: $input) {
        user {
          ...userSignupSignin
        }
        device {
          token
        }
      }
    }
    ${USER_FRAGMENTS.userSignupSignin}
  `,
  CREATE_USER: gql`
    mutation createUser($input: CreateUserInput!) {
      createUser(input: $input) {
        user {
          ...user
        }
      }
    }
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_USER_BY_FITPET_ADMIN: gql`
    mutation updateUserByFitpetAdmin($id: ID!, $input: UpdateUserByFitpetAdminInput!) {
      updateUserByFitpetAdmin(id: $id, input: $input) {
        user {
          ...userBasicInfo
        }
      }
    }
    ${USER_FRAGMENTS.userBasicInfo}
  `,
  UPDATE_USER_BY_CUSTOMER: gql`
    mutation updateUserByCustomer($id: ID!, $input: UpdateUserByCustomerInput!) {
      updateUserByCustomer(id: $id, input: $input) {
        user {
          ...userBasicInfo
        }
      }
    }
    ${USER_FRAGMENTS.userBasicInfo}
  `,
  RESET_PASSWORD: gql`
    mutation resetPassword($id: ID!, $input: ResetPasswordInput!) {
      resetPassword(id: $id, input: $input) {
        user {
          ...user
        }
      }
    }
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_USER_PASSWORD_BY_FITPET_ADMIN: gql`
    mutation updateUserPasswordByFitpetAdmin($id: ID!, $input: UpdateUserPasswordByFitpetAdminInput!) {
      updateUserPasswordByFitpetAdmin(id: $id, input: $input) {
        user {
          ...userBasicInfo
        }
      }
    }
    ${USER_FRAGMENTS.userBasicInfo}
  `,
  ADD_ADMIN_PERMISSION: gql`
    mutation addAdminPermission($id: ID!) {
      addAdminPermission(id: $id) {
        user {
          id
          isStaff
        }
      }
    }
  `,
  REMOVE_ADMIN_PERMISSION: gql`
    mutation removeAdminPermission($id: ID!) {
      removeAdminPermission(id: $id) {
        user {
          id
          isStaff
        }
      }
    }
  `,
  FIND_ID: gql`
    mutation findId($input: UserCertificationConfirmInput!) {
      findId(input: $input) {
        userInfo {
          id
          email
        }
      }
    }
  `,
  CONFIRM_USER_CERT_NUMBER: gql`
    mutation confirmUserCertNumber($id: ID!, $input: ConfirmUserCertNumberInput!) {
      confirmUserCertNumber(id: $id, input: $input) {
        userInfo {
          ...userInfo
          accounts {
            loginAccountType
            lastLoggedAt
          }
        }
        userCertification {
          ...userCertification
        }
      }
    }
    ${DEFAULT_FRAGMENTS.userInfo}
    ${DEFAULT_FRAGMENTS.userCertification}
  `,
  SEND_USER_CERT_NUMBER: gql`
    mutation sendUserCertNumber($input: SendUserCertNumberInput!) {
      sendUserCertNumber(input: $input) {
        userCertification {
          ...userCertification
        }
      }
    }
    ${DEFAULT_FRAGMENTS.userCertification}
  `,
  UPDATE_REFUND_ACCOUNT: gql`
    mutation updateRefundAccount($id: ID!, $input: RefundAccountInput!) {
      updateRefundAccount(id: $id, input: $input) {
        user {
          accountNumber
          accountHolderName
          bankCode
          id
        }
      }
    }
  `,
  UPDATE_MOBILE_NUMBER: gql`
    mutation updateMobileNumberDefault($id: ID!, $input: UserCertificationConfirmInput!) {
      updateMobileNumber(id: $id, input: $input) {
        user {
          id
          mobileNumber
        }
      }
    }
  `,
}

export default USER_MUTATION_GQLS
