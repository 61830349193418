import { gql } from '@apollo/client'

const EXCEL_REQUEST_MUTATION_GQLS = {
  CANCEL_ISSUING_MANUAL_COUPONS: gql`
    mutation cancelIssuingManualCoupons($id: ID!) {
      cancelIssuingManualCoupons(id: $id) {
        excelImportRequest {
          id
        }
      }
    }
  `,
  REQUEST_PRODUCT_EXCEL_FILE_BY_ADMIN: gql`
    mutation requestProductExcelFileByAdmin($input: RequestExcelFileInput!) {
      requestProductExcelFileByAdmin(input: $input) {
        isSuccess
        __typename
      }
    }
  `,
  REQUEST_AUDIT_EXCEL: gql`
    mutation requestAuditDataExcelFile($input: AuditRequestExcelFileInput!) {
      requestAuditDataExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  REQUEST_READY_FOR_SHIPPING_ORDER_FITPET_WMS_EXCEL_FILE: gql`
    mutation requestReadyForShippingOrderFitpetWmsExcelFile($input: RequestExcelFileInput!) {
      requestReadyForShippingOrderFitpetWmsExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  REQUEST_MEMBERSHIP_USER_EXCEL_FILE: gql`
    mutation requestMembershipUserExcelFile($input: RequestExcelFileInput!) {
      requestMembershipUserExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
}

export default EXCEL_REQUEST_MUTATION_GQLS
