import { FunctionComponent } from 'react'
import { Card, Row, Col } from 'antd'
import { CardProps } from 'antd/lib/card'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled, { CSSObject } from 'styled-components'

type MInfoCardProps = CardProps & {
  mainContent: JSX.Element
  rightContent?: JSX.Element
  rightContents?: JSX.Element[]
  subContents?: JSX.Element[]
  style?: CSSObject
  type?: string
  color?: 'blue' | 'yellow' | 'red'
  onCardClick?: () => void
  withIcon?: boolean
}

const MInfoCard: FunctionComponent<MInfoCardProps> = ({
  style,
  color = 'yellow',
  mainContent,
  rightContent,
  rightContents,
  subContents,
  type,
  onCardClick,
  withIcon = true,
}) => {
  const getTitle = (content: JSX.Element) => content?.props.children[0]
  return (
    <StyledCard color={color} type={type} style={style} onClick={onCardClick}>
      <Row justify="space-between" align="middle">
        <Row>
          <Col>
            {withIcon && <StyledIcon color={color} />}
            {mainContent}
          </Col>
        </Row>
        {rightContents &&
          rightContents.map((content) => {
            return <Col key={`key-right-${getTitle(content)}`}>{content}</Col>
          })}
        {!rightContents && rightContent && <Col>{rightContent}</Col>}
      </Row>
      {subContents && (
        <Row style={{ marginTop: 12, marginLeft: 30 }}>
          {subContents.map((content) => {
            return (
              <Col span={6} key={`key-sub-${getTitle(content)}`}>
                {content}
              </Col>
            )
          })}
        </Row>
      )}
    </StyledCard>
  )
}

const StyledCard = styled(({ color, ...rest }) => <Card color={color} {...rest} />)<CardProps & { color?: string }>`
  background-color: ${({ color }) => {
    if (color === 'blue') return '#e6f7ff'
    if (color === 'red') return '#fff1ef'
    return '#fffbe6'
  }};
  margin: ${(props) => props.type !== 'inner' && '16px 24px 16px 24px'};
`

const StyledIcon = styled(({ color, ...rest }) => <InfoCircleOutlined color={color} {...rest} />)<{ color?: string }>`
  background-color: ${({ color }) => {
    if (color === 'blue') return '#1890ff'
    if (color === 'red') return '#ff4d4f'
    return '#e8d000'
  }};
  color: white;
  border-radius: 50%;
  font-size: 15px;
  margin-right: 12px;
`

export default MInfoCard
