import tableCols from 'domains/admin/tableCols'
import { queryParamsProps } from 'components/MQuery'
import { IModelCouponGroup } from 'containers/models/modelCouponGroup'
import { MutableRefObject, useRef } from 'react'
// import { MListSortSelect, MMainTable } from 'components'
import { MMainTable } from 'components'
import { ITableColumn } from 'components/@atomics/MTable'

const tableColumns = (refetchRef: MutableRefObject<Function | null>): Array<ITableColumn<IModelCouponGroup>> => {
  return [
    tableCols.common.ID(),
    tableCols.couponGroup.couponGroupName(),
    tableCols.date.createdAtText({ title: '등록일' }),
    tableCols.common.baseTextFromModel({ title: '발급자', dataIndex: 'fitpetAdmin', key: 'name', width: 100 }),

    tableCols.couponGroup.usingPeriod(),
    tableCols.common.baseText({ title: '쿠폰 유형', dataIndex: 'couponTypeText', width: 100 }),
    tableCols.common.baseText({ title: '사용 범위', dataIndex: 'coverageOsTypeText', width: 100 }),
    tableCols.couponGroup.benefit(),
    tableCols.couponGroup.issueType({ isShownIssueButton: true }),
    tableCols.count.caseCountText({ title: '발급수', dataIndex: 'issueCount' }),
    tableCols.couponGroup.issueStatus(),
    tableCols.couponGroup.suspendButton(refetchRef),
    // TODO: 추후 쿠폰 수정 기획 예정
    // tableCols.couponGroup.editButton(),
    tableCols.couponGroup.deleteButton(refetchRef),
  ]
}

type MainTableCouponGroupListProps = {}

const MainTableCouponGroupList: React.FC<MainTableCouponGroupListProps> = () => {
  const refetchRef = useRef<Function | null>(null)
  const queryParams: queryParamsProps = {
    gqlKey: 'couponGroups',
    variables: { orderBy: '-createdAt' },
  }

  return (
    <MMainTable
      TitleSet={{ title: '쿠폰 목록', unit: '개' }}
      columns={tableColumns(refetchRef)}
      queryParams={queryParams}
      refetchRef={refetchRef}
    />
  )
}

export default MainTableCouponGroupList
