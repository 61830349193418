import { EXCEL_UPLOAD_MUTATION_KEY2GQLS } from 'containers/gqls'
import React, { FC, MutableRefObject } from 'react'
import useModal from 'containers/hooks/useModal'
import { MButton } from 'components/@atomics'
import { UploadOutlined } from '@ant-design/icons'
import ModalUploadExcelFile from 'domains/common/modals/ModalUploadExcelFile'

export type ButtonUploadExcelProps = {
  title: string
  uploadExcelGqlKey: keyof typeof EXCEL_UPLOAD_MUTATION_KEY2GQLS
  externalInputVariables?: {}
  uploadButtonText?: string
  guideMessage?: string
  failedGuideMessage?: string
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}

const ButtonUploadExcel: FC<ButtonUploadExcelProps> = ({
  title,
  uploadExcelGqlKey,
  externalInputVariables,
  uploadButtonText = '엑셀 업로드',
  guideMessage,
  failedGuideMessage,
  refetchRef,
  statusFilterRefetchRef,
}) => {
  const { useModalProps: uploadExcelFileModalProps } = useModal()
  const handleExcelUploadClick = () => {
    uploadExcelFileModalProps.toggleModal()
  }
  return (
    <>
      <MButton type="primary" onClick={() => handleExcelUploadClick()} icon={<UploadOutlined />}>
        {uploadButtonText}
      </MButton>
      <ModalUploadExcelFile
        useModalProps={uploadExcelFileModalProps}
        fixedProps={{
          uploadExcelGqlKey,
          externalInputVariables,
          title,
          guideMessage,
          failedGuideMessage,
          refetchRef,
          statusFilterRefetchRef,
        }}
      />
    </>
  )
}

export default ButtonUploadExcel
