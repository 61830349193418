import { USER_MODE } from '@constants/constDomain'
import { IModelReviewImage } from 'containers/models/modelReviewImage'
import { useEffect, useState } from 'react'
import { Row, Col, Form, Tag, message } from 'antd'
import { MutationFunction } from '@apollo/client'

import * as utilData from '@libs/utils/utilData'
import * as utilDate from '@libs/utils/utilDate'
import { MFormItemTextArea } from 'components'
import MMutation from 'components/MMutation'
import { Text14Normal, MButton, MLinkRouterButton, Text14Red } from 'components/@atomics'
import { MDescriptionsItemOptions } from 'components/descriptions/MDescriptionsTable'
import { ConditionalWrapper } from '@libs/utils/utilData'
import useUserInfo from 'containers/hooks/useUserInfo'
import ROUTES from '@constants/constRoutes'
import { IModelReview } from 'containers/models/modelReview'
import { IModelProductQna } from 'containers/models/modelProductQna'
import MSingleImage from 'components/@atomics/etc/MSingleImage'

// base..
const baseText = ({ key, label, content }: MDescriptionsItemOptions) => {
  return {
    key,
    label,
    content: <Text14Normal>{utilData.tableText(content as string | number)}</Text14Normal>,
  }
}

const baseTextDate = ({ key, label, content, withTime = true }: MDescriptionsItemOptions) => {
  return {
    key,
    label,
    content: <Text14Normal>{utilDate.date2string(content as Date, withTime)}</Text14Normal>,
  }
}

const strippedString = ({ content }: MDescriptionsItemOptions) => {
  const stripped = content.replace(/(<([^>]+)>)/gi, '') ?? content
  return {
    key: 'questionBody',
    label: '내용',
    content: <div>{stripped}</div>,
  }
}

const formComment = ({ key, label, content, refetchRef, setCurrentQna }: MDescriptionsItemOptions) => {
  const [buttonDisable, setButtonDisable] = useState<boolean>(false)
  const [form] = Form.useForm()
  const styleTextArea = { minHeight: '200px', verticalAlign: 'middle' }
  let gqlKey: 'updateReview' | 'answerProductQuestion' = 'updateReview'
  if (key === 'review') {
    gqlKey = 'updateReview'
  }
  if (key === 'productQna') {
    gqlKey = 'answerProductQuestion'
  }

  const setDisable = (value: string) => setButtonDisable(!value)

  useEffect(() => {
    form.setFieldsValue({ answerBody: content?.answerBody })
    setDisable(content.answerBody)
  }, [JSON.stringify(content)])

  return {
    key: 'comment',
    label: label || '댓글',
    content: (
      <Form form={form} onValuesChange={(changedValues) => setDisable(changedValues.answerBody)}>
        <MFormItemTextArea
          name="answerBody"
          textAreaProps={{
            maxLength: 500,
            style: styleTextArea,
          }}
        />
        <Row justify="end">
          <MMutation
            gqlKey={gqlKey}
            onAPISuccess={(resultData) => {
              message.success('답변이 등록되었습니다.')
              if (refetchRef) {
                refetchRef.current()
              }
              if (setCurrentQna) {
                setCurrentQna(resultData?.productQna?.data as IModelProductQna)
              }
            }}
          >
            {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
              return (
                <MButton
                  type="primary"
                  disabled={buttonDisable}
                  onClick={() => {
                    if (content.id === undefined) {
                      alert('등록 할 글을 선택해주세요.')
                      return false
                    }
                    const answerBody = form.getFieldValue('answerBody')
                    mutation({
                      variables: {
                        id: content.id,
                        input: {
                          answerBody,
                        },
                      },
                    })
                  }}
                  loading={loading}
                >
                  {content?.isAnswered ? '변경 저장' : '답변 등록'}
                </MButton>
              )
            }}
          </MMutation>
        </Row>
      </Form>
    ),
  }
}

// Products...
const productCode = ({ contents }: MDescriptionsItemOptions) => {
  return {
    key: 'id',
    label: '상품코드',
    content: (
      <MLinkRouterButton href={`${process.env.FRONT_WEB_DOMAIN}/products/${contents._id}`} openNewWindow>
        {contents._id ?? '-'}
      </MLinkRouterButton>
    ),
  }
}

const productCodeToShop = ({ content }: MDescriptionsItemOptions) => {
  const fakeLink = `${process.env.FRONT_WEB_DOMAIN}/products/${content._id}`
  return {
    key: 'id',
    label: '상품코드',
    content: content._id ? (
      <MLinkRouterButton href={fakeLink} openNewWindow>
        {content._id}
      </MLinkRouterButton>
    ) : (
      <>-</>
    ),
  }
}

// Reviews...
const writer = ({ content, hasLink }: MDescriptionsItemOptions & { hasLink?: boolean }) => {
  const userMode = useUserInfo.useUserMode()
  let writerText = ''

  if (userMode === USER_MODE.SELLER) {
    const writerName = utilData.maskingText(content.name, 1)
    const writerEmail = utilData.maskingText(content.email, 3)
    writerText = `${utilData.tableText(writerName)} (${utilData.tableText(writerEmail)})`
  } else {
    writerText = `${utilData.tableText(content.name)} (${utilData.tableText(content.email)})`
  }

  return {
    key: 'writer',
    label: '작성자',
    content:
      userMode === USER_MODE.ADMIN && hasLink ? (
        <MLinkRouterButton href={`${ROUTES.ACCOUNTS.USER_LIST}/${content._id}`} openNewWindow>
          {writerText}
        </MLinkRouterButton>
      ) : (
        <Text14Normal>{writerText}</Text14Normal>
      ),
  }
}

const reviewBody = ({ content }: MDescriptionsItemOptions) => {
  return {
    key: 'reviewBody',
    label: '리뷰내용',
    content: (
      <Row>
        {content.isFirstReviewProduct && (
          <Tag color="volcano" closable={false}>
            첫 리뷰
          </Tag>
        )}
        <Col>
          <Text14Normal>{utilData.tableText(content.reviewBody)}</Text14Normal>
        </Col>
      </Row>
    ),
  }
}

const reviewImage = ({ content }: MDescriptionsItemOptions) => {
  return {
    key: 'reviewReviewImages',
    label: '파일 첨부',
    content: (
      <>
        {content.reviewReviewImages.map((_reviewImage: IModelReviewImage) => (
          <MSingleImage key={_reviewImage.id} content={utilData.getFullUrl(_reviewImage.image)} />
        ))}
      </>
    ),
  }
}

const reviewPoint = ({ content }: MDescriptionsItemOptions) => {
  const { reviewMileageHistories } = content as IModelReview

  return {
    key: 'reviewPoint',
    label: '적립금',
    content: reviewMileageHistories
      .map((mileageHistory) => {
        return `${mileageHistory.mileageTypeText} : ${mileageHistory.provideText}`
      })
      .join(', '),
  }
}

const questionBody = ({ contents }: MDescriptionsItemOptions) => {
  return {
    key: 'questionBody',
    label: '내용',
    content: (
      <Row gutter={[12, 0]}>
        <Col>
          <Text14Normal>{utilData.tableText(contents?.questionBody)}</Text14Normal>
        </Col>
      </Row>
    ),
  }
}

const answerBody = ({ contents }: MDescriptionsItemOptions) => {
  return {
    key: 'questionBody',
    label: '내용',
    content: (
      <Row gutter={[12, 0]}>
        <Col>
          <Text14Normal>{utilData.tableText(contents?.answerBody)}</Text14Normal>
        </Col>
      </Row>
    ),
  }
}

const isAnswered = ({ content }: MDescriptionsItemOptions) => {
  return {
    key: 'isAnswered',
    label: '처리상태',
    content: (
      <ConditionalWrapper
        condition={content as boolean}
        wrapper={[<Text14Normal />, <Text14Red />]}
        children={content ? '답변완료' : '미답변'}
      />
    ),
  }
}

const answeredUser = ({ contents }: MDescriptionsItemOptions) => {
  const answeredSellerShopName = contents?.seller?.shopName ?? '-'
  const answeredUsername = contents?.answeredUser?.name ?? '-'
  const answeredUserEmail = contents?.answeredUser?.email
  const isSellerAnswered = contents?.answeredUserType === 'SELLER'
  return {
    key: 'answeredUser',
    label: '답변 작성자',
    content: !contents?.isAnswered ? (
      <Text14Normal>-(-)</Text14Normal>
    ) : (
      <Text14Normal>
        {isSellerAnswered ? answeredSellerShopName : '웨이브바이 관리자'}(
        {contents?.answeredUser?.name === '' ? answeredUserEmail : answeredUsername})
      </Text14Normal>
    ),
  }
}

export {
  baseText, // baseText
  baseTextDate, // baseText date
  writer, // 작성자
  reviewBody, // 리뷰내용
  formComment, // 댓글 작성
  reviewImage, // 첨부 파일
  reviewPoint, // 적립금
  productCode, // 상품 코드
  productCodeToShop, // 상품 코드, 쇼핑몰 상세로
  strippedString, // string to html tag
  questionBody, // 문의 내용
  answerBody, // 답변 내용 (readonly)
  isAnswered, // 처리 상태
  answeredUser, // 답변 작성자
}
