import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const COUPON_GROUP_FRAGMENTS = {
  get couponGroupNode() {
    return gql`
      fragment couponGroupNode on CouponGroupType {
        ...couponGroup
        fitpetAdmin {
          name
        }
        includedBrands {
          edges {
            node {
              id
              name
            }
          }
        }
        includedProducts {
          edges {
            node {
              id
              name
            }
          }
        }
        includedCategories {
          edges {
            node {
              id
              name
            }
          }
        }
        excludedBrands {
          edges {
            node {
              id
              name
            }
          }
        }
        excludedProducts {
          edges {
            node {
              id
              name
            }
          }
        }
        excludedCategories {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      ${DEFAULT_FRAGMENTS.couponGroup}
    `
  },
}

export default COUPON_GROUP_FRAGMENTS
