import { queryParamsProps } from 'components/MQuery'
import { FC, MutableRefObject, useRef } from 'react'
import { useRouter } from 'next/router'

import * as utilData from '@libs/utils/utilData'
import { FIELD_SORT_KEYS } from '@constants/constData'
import ROUTES from '@constants/constRoutes'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelBrand } from 'containers/models/modelBrand'
import { MListSortSelect, MMainTable } from 'components'
import tableCols from 'domains/admin/tableCols'
import { ButtonDeleteBrand } from 'domains/common/tableButtons'
import { MButton, MLinkRouterButton, Text14Blue, Text14Normal } from 'components/@atomics'

const tableColumns = (
  refetchRef: MutableRefObject<Function | null>,
  readOnly: boolean,
  editSort: boolean
): Array<ITableColumn<IModelBrand>> => {
  return [
    tableCols.common.ID(),
    // TODO yoon: series 수정 방식이 drag & drop 방식에서 input으로 바뀜. 다시 drag & drop 변경되면 아래 주석 제거
    // tableCols.common.sort({ width: 100, dataIndex: 'series', key: 'series' }),
    tableCols.common.editableSort({ width: 100, refetchRef, editable: editSort }),
    ...(readOnly
      ? []
      : [
          {
            title: '수정',
            key: 'edit',
            width: 100,
            render: (record: IModelBrand) => {
              return (
                <MLinkRouterButton sider={false} openNewWindow href={`${ROUTES.PARTNERS.BRAND_LIST}/${record._id}`}>
                  <Text14Blue>[수정]</Text14Blue>
                </MLinkRouterButton>
              )
            },
          },
        ]),
    tableCols.status.approveRequest({
      modelKey: 'brand',
      variableKey: 'brandStatusId',
      descriptionComponentKey: 'descriptionBrand',
    }),
    tableCols.status.rejectRequest({
      modelKey: 'brand',
      variableKey: 'brandRejectRequest',
      descriptionComponentKey: 'descriptionBrand',
    }),
    {
      title: '브랜드명',
      key: 'name',
      width: 180,
      render: (_: string, record: IModelBrand) => {
        return <Text14Normal>{utilData.tableText(record.name)}</Text14Normal>
      },
    },
    tableCols.common.petTypeText({ dataIndex: 'petType' }),
    tableCols.seller.sellersShopName(),
    tableCols.status.statusText({ title: '운영상태' }),
    tableCols.status.statusText({ title: '신청상태', dataIndex: 'updateStatus' }),
    {
      title: '정보변경 신청',
      key: 'edit',
      width: 100,
      render: (record: IModelBrand) => {
        if (record.updateStatus?.value === 'REQUEST') {
          return (
            <MLinkRouterButton sider={false} href={`${ROUTES.PARTNERS.BRAND_LIST}/${record._id}`}>
              <Text14Blue>[보기]</Text14Blue>
            </MLinkRouterButton>
          )
        }
        return <>-</>
      },
    },
    tableCols.date.createdAtText({ title: '등록일' }),
    tableCols.date.updateApprovedAtText({ title: '수정일' }),
  ]
}

type MainTableBrandListProps = {
  readOnly?: boolean
  editSort?: boolean
}

const MainTableBrandList: FC<MainTableBrandListProps> = ({ readOnly = false, editSort = true }) => {
  const router = useRouter()
  const selected = useRef<[]>([])
  const refetchRef = useRef<Function | null>(null)
  const queryParams: queryParamsProps = {
    gqlKey: 'brands',
    variables: {
      orderBy: FIELD_SORT_KEYS.FIELD_SORT_CREATED_AT_DESC,
    },
  }
  return (
    <MMainTable
      rowSelectionType={readOnly ? undefined : 'checkbox'}
      onRowSelect={(rows: []) => {
        selected.current = rows
      }}
      columns={tableColumns(refetchRef, readOnly, editSort)}
      HeaderLeftButtons={[
        ...(readOnly
          ? []
          : [
              <ButtonDeleteBrand selected={selected} refetchRef={refetchRef} />,
              <MButton
                type="primary"
                onClick={() => {
                  router.push({ pathname: ROUTES.PARTNERS.BRAND_ADD })
                }}
                width={100}
              >
                브랜드 등록
              </MButton>,
            ]),
      ]}
      HeaderRightButtons={[
        <MListSortSelect
          filterOptions={[
            'FIELD_SORT_CREATED_AT_DESC',
            'FIELD_SORT_CREATED_AT',
            'FIELD_SORT_DISPLAY_ORDER',
            'FIELD_SORT_DISPLAY_ORDER_DESC',
          ]}
        />,
      ]}
      TitleSet={{ title: '브랜드 목록', unit: '개' }}
      queryParams={queryParams}
      refetchRef={refetchRef}
    />
  )
}

export default MainTableBrandList
