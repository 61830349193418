import { useState } from 'react'
import {
  EditOutlined,
  SolutionOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  EllipsisOutlined,
} from '@ant-design/icons'
import { MMenuSider } from 'components/@atomics'
import { RcMenuInfo } from 'domains/common/menus'
import ROUTES, { ROUTE_KEYS } from '@constants/constRoutes'
import { redirectManageProductUrlV3 } from '@libs/utils/utilRedirect'

const sellerMenuSiderConfig = [
  {
    id: ROUTE_KEYS.PRODUCTS,
    title: '상품 관리',
    icon: <EditOutlined />,
    children: [
      { id: ROUTE_KEYS.PRODUCT_LIST, title: '상품 리스트', url: ROUTES.PRODUCTS.PRODUCT_LIST },
      { id: ROUTE_KEYS.PRODUCT_LIST_DELETE, title: '삭제 상품 리스트', url: ROUTES.PRODUCTS.PRODUCT_LIST_DELETE },
      { id: `${ROUTE_KEYS.PRODUCTS}-add`, title: '상품 등록', url: ROUTES.PRODUCTS.PRODUCT_ADD },
      {
        id: ROUTE_KEYS.PRODUCT_EXCEL_UPLOAD,
        title: '엑셀 업로드/임시 등록 관리',
        url: ROUTES.PRODUCTS.PRODUCT_EXCEL_UPLOAD,
      },
      { id: ROUTE_KEYS.SELLER_CATEGORY, title: '카테고리 보기', url: ROUTES.PRODUCTS.SELLER_CATEGORY },
    ],
  },
  {
    id: ROUTE_KEYS.ORDERS,
    title: '주문 관리',
    icon: <SolutionOutlined />,
    children: [
      { id: ROUTE_KEYS.ORDER_LIST_ALL, title: '전체 주문 조회', url: ROUTES.ORDERS.ORDER_LIST_ALL },
      {
        id: ROUTE_KEYS.ORDER_LIST_UNCONFIRMED_PAYMENT,
        title: '미결제 확인',
        url: ROUTES.ORDERS.ORDER_LIST_UNCONFIRMED_PAYMENT,
      },
      { id: ROUTE_KEYS.ORDER_LIST_NEW_ORDER, title: '신규 주문 / 출고 관리', url: ROUTES.ORDERS.ORDER_LIST_NEW_ORDER },
      { id: ROUTE_KEYS.ORDER_LIST_SHIPPING, title: '배송현황 관리', url: ROUTES.ORDERS.ORDER_LIST_SHIPPING },
      { id: ROUTE_KEYS.ORDER_LIST_CONFIRMED, title: '구매확정 내역', url: ROUTES.ORDERS.ORDER_LIST_CONFIRMED },
      { id: ROUTE_KEYS.ORDER_LIST_CANCELED, title: '취소 관리', url: ROUTES.ORDERS.ORDER_LIST_CANCELED },
      { id: ROUTE_KEYS.ORDER_LIST_RETURN, title: '반품 관리', url: ROUTES.ORDERS.ORDER_LIST_RETURN },
      { id: ROUTE_KEYS.ORDER_LIST_EXCHANGE, title: '교환 관리', url: ROUTES.ORDERS.ORDER_LIST_EXCHANGE },
    ],
  },
  {
    id: ROUTE_KEYS.BILLINGS,
    title: '정산 관리',
    icon: <CheckCircleOutlined />,
    children: [{ id: ROUTE_KEYS.BILLING_LIST, title: '정산 리스트', url: ROUTES.BILLINGS.BILLING_LIST }],
  },
  {
    id: ROUTE_KEYS.BOARDS,
    title: '문의/리뷰 관리',
    icon: <WarningOutlined />,
    children: [
      { id: ROUTE_KEYS.BOARD_LIST_PRODUCT_QNA, title: '상품 문의 리스트', url: ROUTES.BOARDS.BOARD_LIST_PRODUCT_QNA },
      { id: ROUTE_KEYS.BOARD_LIST_REVIEW, title: '리뷰 리스트', url: ROUTES.BOARDS.BOARD_LIST_REVIEW },
    ],
  },
  {
    id: ROUTE_KEYS.STATISTICS,
    title: '통계',
    icon: <CheckCircleOutlined />,
    children: [
      {
        id: ROUTE_KEYS.ANALYSIS_PRODUCT_BY_SELLER,
        title: '상품 분석',
        url: ROUTES.STATISTICS.ANALYSIS_PRODUCT_BY_SELLER,
      },
    ],
  },
  {
    id: ROUTE_KEYS.CONFIGURATIONS,
    title: '환경설정',
    icon: <WarningOutlined />,
    children: [
      { id: ROUTE_KEYS.SELLER_INFORMATION, title: '셀러정보', url: ROUTES.CONFIGURATIONS.SELLER_INFORMATION },
      {
        id: ROUTE_KEYS.SELLER_INFORMATION_CHANGE,
        title: '셀러정보 변경신청',
        url: ROUTES.CONFIGURATIONS.SELLER_INFORMATION_CHANGE,
      },
      { id: ROUTE_KEYS.BRAND_INFORMATION, title: '브랜드정보', url: ROUTES.CONFIGURATIONS.BRAND_INFORMATION },
      { id: ROUTE_KEYS.SELLER_NOTICE, title: '셀러 공지사항', url: ROUTES.CONFIGURATIONS.SELLER_NOTICE },
    ],
  },
  {
    id: ROUTE_KEYS.ETC,
    title: '기타 관리',
    icon: <EllipsisOutlined />,
    children: [{ id: ROUTE_KEYS.EXCEL_REQUEST_LIST, title: '엑셀 다운로드', url: ROUTES.ETC.EXCEL_REQUEST_LIST }],
  },
]

const MenuSiderSeller = () => {
  const [curKey, setCurKey] = useState('')
  const handleClick = (e: RcMenuInfo): void => setCurKey(e.key as string)

  return <MMenuSider curSelectedKeys={curKey} onClick={handleClick} menuConfig={sellerMenuSiderConfig} />
}

export default MenuSiderSeller
