import { MutationFunction } from '@apollo/client'
import * as utilDate from '@libs/utils/utilDate'
import { Switch } from 'antd'
import MMutation from 'components/MMutation'
import { Text14Normal } from 'components/@atomics'
import { ITableColumn, ITableColumnFixedHasLinkProps } from 'components/@atomics/MTable'
import { IModelBillingItem } from 'containers/models/modelBillingItem'
import { PAYMENT_TYPE } from '@constants/constDomain'
import { IModelSellerBilling } from 'containers/models/modelSellerBilling'
import { memo } from 'react'
import { ButtonRequestExcel } from 'domains/common/tableButtons'
import { POLICY_DATA } from '@constants/constData'

const targetMonth = ({
  title = '정산대상월',
  dataIndex = 'baseAt',
  fixed = 'left',
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<IModelSellerBilling> => {
  return {
    title,
    dataIndex,
    width: 160,
    fixed,
    render: (_: string) => {
      return (
        <Text14Normal>
          {utilDate.setDateOfMonth(_, 'first')} - {utilDate.setDateOfMonth(_, 'last')}
        </Text14Normal>
      )
    },
  }
}

const paymentType = ({
  title = '결제방법',
  dataIndex = 'paymentType',
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<IModelBillingItem> => {
  return {
    title,
    dataIndex,
    width: 160,
    render: (_: string) => {
      return <Text14Normal>{PAYMENT_TYPE[_ as keyof typeof PAYMENT_TYPE] || '-'}</Text14Normal>
    },
  }
}

const downloadBillingItemExcel = ({
  title = '엑셀 다운',
  isSeller = false,
  dataIndex = undefined,
  fixed = false,
}: ITableColumnFixedHasLinkProps & { isSeller?: boolean } = {}): ITableColumn<IModelSellerBilling> => {
  const MemoizedButtonRequestExcel = memo(ButtonRequestExcel)

  const getGqlKey = () => {
    if (isSeller) {
      return 'requestSellerBillingItemExcelFile'
    }
    return 'requestAdminBillingItemExcelFile'
  }
  const getFilter = (billing: IModelSellerBilling) => {
    const billedAtAfter = utilDate.getDateOfMonth(billing.billedAt, 'first')
    const billedAtBefore = utilDate.getDateOfMonth(billing.billedAt, 'last')
    const sellerBilling = billing as IModelSellerBilling
    return {
      seller: sellerBilling.seller.id,
      brand_name: sellerBilling.brand.name,
      sellerBilledAtAfter: billedAtAfter,
      sellerBilledAtBefore: billedAtBefore,
    }
  }
  return {
    title,
    dataIndex,
    width: 130,
    fixed,
    render: (billing: IModelSellerBilling) => {
      return (
        <MemoizedButtonRequestExcel
          maxRowCount={POLICY_DATA.USER_EXCEL_FILE_MAX_ROW_COUNT}
          title={title}
          requestExcelGqlKey={getGqlKey()}
          defaultRowCount={100000}
          requestButtonText={title}
          filter={getFilter(billing)}
        />
      )
    },
  }
}

const confirmBilling = ({
  title = '정산 확인',
  dataIndex = 'isBilled',
  refetchRef = null,
}: ITableColumnFixedHasLinkProps & { refetchRef?: any } = {}): ITableColumn<IModelSellerBilling> => {
  return {
    title,
    dataIndex,
    width: 100,
    render: (value: boolean, record: IModelSellerBilling) => {
      return (
        <MMutation
          gqlKey="confirmSellerBilling"
          onAPISuccess={() => {
            if (refetchRef.current) {
              refetchRef.current()
            }
          }}
        >
          {(mutation: MutationFunction, { loading }: { loading: boolean }) => (
            <Switch
              checked={value}
              onClick={(checked) => {
                mutation({
                  variables: { id: record?.id, input: { isConfirm: checked } },
                })
              }}
              loading={loading}
            />
          )}
        </MMutation>
      )
    },
  }
}

const isConfirmed = ({ title = '정산여부', dataIndex = 'confirmedAt' } = {}): ITableColumn<IModelSellerBilling> => {
  return {
    title,
    dataIndex,
    width: 100,
    render: (value: string) => {
      return <Text14Normal>{!value ? '미정산' : '정산완료'}</Text14Normal>
    },
  }
}

export {
  confirmBilling, // 정산 확인
  downloadBillingItemExcel, // 정산 아이템 엑셀 다운로드
  paymentType, // 결제방법
  targetMonth, // 정산대상월
  isConfirmed, // 정산여부
}
