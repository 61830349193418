import { ModalConfirmInput } from 'domains/common/modals'
import { FC, useState } from 'react'
import { ModalConfirmInputProps } from 'domains/common/modals/ModalConfirmInput'
import { statusRequestProps } from 'domains/admin/tableCols/domain/TableColsStatus'

const ModalConfirmInputReject: FC<statusRequestProps & ModalConfirmInputProps> = ({
  descriptionComponentKey,
  refetchRef,
  variableKey,
  modelKey,
  useModalProps,
  fixedProps,
}) => {
  const [hasValue, setHasValue] = useState<boolean>(false)

  const setConfirmMessage = (titleKey: 'seller' | 'brand' | 'product' | 'status') => {
    let title: '셀러' | '브랜드' | '상품' | '' = ''
    if (titleKey === 'seller') title = '셀러'
    if (titleKey === 'brand') title = '브랜드'
    if (titleKey === 'product') title = '상품'
    return `${title} 신청이 반려되었습니다.`
  }

  return (
    <ModalConfirmInput
      useModalProps={useModalProps}
      fixedProps={{
        ...fixedProps,
        mutationKey: { gqlKey: 'rejectRequest', variableKey },
        componentOption: { descriptionComponentKey },
        inputOption: {
          name: 'message',
          type: 'input',
          label: '반려사유:',
          placeholder: '반려 사유를 입력해 주세요.',
        },
        confirmMessage: setConfirmMessage(modelKey),
        okButtonDisable: !hasValue,
      }}
      onAPISuccess={() => {
        if (refetchRef?.current) refetchRef.current()
      }}
      onChange={(e) => {
        if (e.target.value) {
          setHasValue(true)
        } else {
          setHasValue(false)
        }
      }}
    />
  )
}

export { ModalConfirmInputReject as default }
