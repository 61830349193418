import { CSSProperties } from 'react'
import { Divider } from 'antd'
import { DividerProps } from 'antd/lib/divider'
import styled from 'styled-components'

export type MDividerProps = DividerProps & {
  margin?: number
  hiddenDash?: boolean
  style?: CSSProperties
}

const MDivider = ({ margin, hiddenDash, style, ...dividerProps }: MDividerProps) => {
  return (
    <StyledDivider
      {...dividerProps}
      margin={margin}
      style={{ ...style, borderStyle: hiddenDash ? 'hidden' : undefined }}
    />
  )
}

const StyledDivider = styled(Divider)<MDividerProps>`
  margin-top: ${(props) => props.margin}px;
  margin-bottom: ${(props) => props.margin}px;

  .ant-divider-horizontal {
    margin-top: ${(props) => props.margin}px;
    margin-bottom: ${(props) => props.margin}px;
  }
`
export default MDivider
