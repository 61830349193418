import * as utilCommon from '@libs/utils/utilCommon'
import * as utilData from '@libs/utils/utilData'
import { IModelSellerDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelSellerDefault } from 'containers/models/base/defaultModels'
import { IModelUpdatedSeller } from 'containers/models/modelUpdatedSeller'
import { IModelUser } from 'containers/models/modelUser'
import { IModelBrand } from 'containers/models/modelBrand'
import { IModelShippingPreset } from 'containers/models/modelShippingPreset'
import { IModelStatus } from 'containers/models/modelStatus'
import { IModelStatusHistory } from 'containers/models/modelStatusHistory'
import { IModelAsInfo } from 'containers/models/modelAsInfo'

export interface IModelSeller extends IModelSellerDefault {
  // variables type
  asTelephoneNumber: string
  chiefManager: IModelUser
  chiefManagerName: string
  fullAddress: string
  preprocessValues: {
    originValues: IModelSeller
    initialValues: IModelSeller
  }
  sellerBrands: IModelBrand[]
  status: IModelStatus
  statusHistories: IModelStatusHistory[]
  userShippingPresets: IModelShippingPreset[]
  users: IModelUser[]
  updateStatus: IModelStatus
  updateInfo: IModelUpdatedSeller
  sellerAsInfos: IModelAsInfo[]
  sabangnetToken: string
}

export default class ModelSeller extends ModelSellerDefault {
  asTelephoneNumber: string = ''
  chiefManagerName = ''
  fullAddress: string = ''
  preprocessValues: { originValues: IModelSeller; initialValues: IModelSeller }

  constructor(data: IModelSeller) {
    super(data)

    this.asTelephoneNumber = this.getAsTelephoneNumber()
    this.chiefManagerName = this.chiefManager ? this.chiefManager.name : ''
    this.fullAddress = utilCommon.getFullAddress(this.postNumber, this.basicAddress, this.detailedAddress)
    this.preprocessValues = this.getPreprocessValues()
    this.userShippingPresets = this.userShippingPresets
      ? this.userShippingPresets.map((shippingPresetData, index) => {
          if (index === 0) {
            // @ts-ignore
            shippingPresetData.isDefault = true
          }
          return shippingPresetData
        })
      : []
  }

  private getAsTelephoneNumber = () => {
    if (!this.sellerAsInfos) return ''

    return this.sellerAsInfos.length > 0 ? this.sellerAsInfos[0].telephoneNumber : ''
  }

  private getPreprocessValues = () => {
    const seller = utilData.deepcopy(this)
    const { seller: updateSellerData, asInfo: updateAsInfo } = seller.updateInfo
    const { sellerAsInfos: updateSellerAsInfos, ...updateValues } = updateSellerData || seller
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sellerAsInfos, ...values } = seller
    values.asTelephoneNumber = seller.asTelephoneNumber

    if (updateAsInfo) {
      updateValues.asTelephoneNumber = updateAsInfo.telephoneNumber
    } else {
      updateValues.asTelephoneNumber =
        updateSellerAsInfos && updateSellerAsInfos.length > 0 ? updateSellerAsInfos[0].telephoneNumber : ''
    }
    updateValues.businessRegistration = values.businessRegistration
    updateValues.mailOrderReportRegistration = values.mailOrderReportRegistration
    updateValues.bankBookImage = values.bankBookImage

    return { originValues: values, initialValues: updateValues }
  }
}
