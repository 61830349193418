import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const DASHBOARD_QUERY_GQLS = {
  DASHBOARD_BY_ADMIN: gql`
    query dashboardByAdmin {
      dashboard {
        dashboardBoard {
          reviewCount
        }
        dashboardProduct {
          sellerCount
          brandCount
          productCount
        }
        dashboardOrder {
          ...dashboardOrder
          timeseries {
            id
            data {
              x
              y
            }
          }
        }
        dashboardNotice {
          partnerNotices {
            id
            createdAt
            title
            isImportant
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.dashboardOrder}
  `,
  DASHBOARD_BY_SELLER: gql`
    query dashboardBySeller($seller: String) {
      dashboard(seller: $seller) {
        dashboardProduct {
          brandCount
          productCount
        }
        dashboardUser {
          wishedProductCount
          wishedBrandCount
        }
        dashboardBilling {
          totalSellerAmount
        }
        dashboardOrder {
          ...dashboardOrder
          timeseries {
            id
            data {
              x
              y
            }
          }
        }
        dashboardNotice {
          partnerNotices {
            id
            createdAt
            title
            isImportant
          }
          partnerImportantNotice {
            id
            createdAt
            title
            isImportant
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.dashboardOrder}
  `,
}

export default DASHBOARD_QUERY_GQLS
