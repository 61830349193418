import { S3_HOST_URL } from '@constants/constData'
import { MutationFunction } from '@apollo/client'
import { MButton } from 'components/@atomics'
import { store } from '@libs/redux/store'
import MMutation from 'components/MMutation'
import useUserInfo from 'containers/hooks/useUserInfo'
import { USER_MODE } from '@constants/constDomain'

const ButtonBillingItemExcelFileDownload = () => {
  const userMode = useUserInfo.useUserMode()

  return (
    <MMutation
      gqlKey={userMode === USER_MODE.ADMIN ? 'requestAdminBillingItemExcelFile' : 'requestSellerBillingItemExcelFile'}
      onAPISuccess={(data) => {
        const { excelLink } = data as unknown as { excelLink: string }
        const url = `${S3_HOST_URL}/${excelLink}`
        window.open(url)
      }}
      onError={() => {}}
    >
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <MButton
            loading={loading}
            onClick={() => {
              const { mainTableVariables } = store.getState().reduxUIReducers
              mutation({
                variables: {
                  input: {
                    filter: mainTableVariables.filter,
                    pageInfo: mainTableVariables.pageInfo,
                  },
                },
              })
            }}
          >
            엑셀 다운
          </MButton>
        )
      }}
    </MMutation>
  )
}

export default ButtonBillingItemExcelFileDownload
