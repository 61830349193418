import { ModelProductOptionValueDefault } from 'containers/models/base/defaultModels'
import { IModelProductOptionValueDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelProductOptionValue extends IModelProductOptionValueDefault {}

export default class ModelProductOptionValue extends ModelProductOptionValueDefault {
  constructor(data: IModelProductOptionValue) {
    super(data)
  }
}
