import { IModelDisplayProductInfoDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelDisplayProductInfoDefault } from 'containers/models/base/defaultModels'
import { IModelProduct } from 'containers/models/modelProduct'
import { getDisplayPeriodText } from '@libs/utils/utilDate'

export interface IModelDisplayProductInfo extends IModelDisplayProductInfoDefault {
  product: IModelProduct
  marginRate?: number
  displayPeriodText?: string
  promotionPeriodText?: string
}

export default class ModelDisplayProductInfo extends ModelDisplayProductInfoDefault {
  marginRate?: number = undefined
  displayPeriodText?: string = undefined
  promotionPeriodText?: string = undefined
  constructor(data: IModelDisplayProductInfo) {
    super(data)
    this.marginRate = getMarginRate(data)
    this.displayPeriodText = getDisplayPeriodText(data.startedAt, data.endedAt, true)
    this.promotionPeriodText = getPromotionPeriodText(data)
  }
}

const getMarginRate = (data: IModelDisplayProductInfo) => {
  if (!data.product || !data.product.defaultProductOption || !data.productpromotion) {
    return undefined
  }
  return 1 - data.product.defaultProductOption.purchasePrice / data.productpromotion.productPromotionCustomerPrice
}

const getPromotionPeriodText = (data: IModelDisplayProductInfo) => {
  if (!data.productpromotion) {
    return undefined
  }
  return getDisplayPeriodText(data.productpromotion.startedAt, data.productpromotion.endedAt, true, '상시 할인')
}
