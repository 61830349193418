import { PAY_METHOD_TYPE, PAY_METHOD_TYPE_TEXT } from '@constants/constDomain'
import { ModelTransactionDefault } from 'containers/models/base/defaultModels'
import { IModelTransactionDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelTransaction extends IModelTransactionDefault {
  payMethodTypeText: PAY_METHOD_TYPE_TEXT
}

export default class ModelTransaction extends ModelTransactionDefault {
  payMethodTypeText: PAY_METHOD_TYPE_TEXT = PAY_METHOD_TYPE_TEXT.BOOKNLIFE

  constructor(data: IModelTransaction) {
    super(data)
    this.payMethodTypeText = PAY_METHOD_TYPE_TEXT[this.payMethodType as PAY_METHOD_TYPE]
  }
}
