import { Row, Col } from 'antd'
import { Text14Normal } from 'components/@atomics'
import { ITableColumn, ITableColumnFixedHasLinkProps } from 'components/@atomics/MTable'
import { IModelBillingItem } from 'containers/models/modelBillingItem'
import * as utilData from '@libs/utils/utilData'

const sellerFee = ({
  title = '정산 금액',
  dataIndex = undefined,
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'sellerFee',
    width: 150,
    render: (billingItem: IModelBillingItem) => {
      const { sellerFee: _sellerFee, isSellerBilled } = billingItem
      const rounded = Math.round(_sellerFee)
      return (
        <Col>
          <Row>
            <Text14Normal>{utilData.currencyText(rounded)}</Text14Normal>
          </Row>
          <Row>
            <Text14Normal>({!isSellerBilled ? '미정산' : '완료'})</Text14Normal>
          </Row>
        </Col>
      )
    },
  }
}

const fitpetCommission = ({
  title = '판매금액x수수료율',
  dataIndex = undefined,
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'fitpetCommission',
    width: 130,
    render: (billingItem: IModelBillingItem) => {
      const {
        fitpetFee: _fitpetFee,
        couponDiscountPrice: _couponDiscountPrice,
        mileageDiscountPrice: _mileageDiscountPrice,
      } = billingItem
      const commission = _fitpetFee + _couponDiscountPrice + _mileageDiscountPrice
      const rounded = Math.round(commission)
      return (
        <Col>
          <Row>
            <Text14Normal>{utilData.currencyText(rounded)}</Text14Normal>
          </Row>
        </Col>
      )
    },
  }
}

// const refundFee = ({ title = '반품 배송비', dataIndex = undefined }: ITableColumnFixedHasLinkProps = {}): ITableColumn<any> => {
//   return (
//     {
//       title,
//       dataIndex,
//       key: 'refundFee',
//       width: 150,
//       render: (billingItem: IModelBillingItem) => {
//         const { billingType: _billingType, sellerFee: _sellerFee } = billingItem
//         const rounded = Math.round(_billingType === 'BILLING_ITEM_RETURN_PRICE' ? _sellerFee : 0)
//         return (
//           <Col>
//             <Row>
//               <Text14Normal>{utilData.currencyText(rounded)}</Text14Normal>
//             </Row>
//           </Col>
//         )
//       },
//     }
//   )
// }

export {
  sellerFee, // 정산 금액 셀러
  fitpetCommission, // 핏펫몰의 수수료(쿠폰, 마일리지를 합친 값. 즉 {판매금 * 핏펫몰 수수료}에서 소수점이하를 버린 값
  // refundFee, // 환불 수수료. billingType이 BILLING_ITEM_RETURN_PRICE인 경우 sellerFee가 환불 수수료임
}
