import MMainTable from 'components/MMainTable'
import { queryParamsProps } from 'components/MQuery'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelBillingItem } from 'containers/models/modelBillingItem'
import tableCols from 'domains/admin/tableCols'
import { ButtonBillingItemExcelFileDownload } from 'domains/common'
import { FIELD_SORT_KEYS } from '@constants/constData'

const noLinkFix = { fixed: false, hasLink: false }
const displayCurrency = { option: { displayCurrency: true } }

const tableColumns: ITableColumn<IModelBillingItem>[] = [
  tableCols.order.orderNumber({ dataIndex: ['orderItem', 'order'] }),
  tableCols.orderItem.orderItemNumber({ dataIndex: 'orderItem' }),
  tableCols.date.dateFromModel({ title: '구매확정일', dataIndex: 'orderItem', key: 'confirmedAt' }),
  tableCols.date.dateFromModel({ title: '반품완료일', dataIndex: 'orderItem', key: 'returnConfirmedAt' }),
  tableCols.date.dateFromModel({ title: '정산확정일', dataIndex: 'sellerBilling', key: 'billedAt' }),
  tableCols.brand.name(),
  tableCols.product.productName({ title: '주문상품', dataIndex: 'orderItem', width: 300 }),
  tableCols.common.baseText({ title: '수량', dataIndex: ['orderItem', 'quantity'], width: 100 }),
  tableCols.currency.currencyText({ title: '총 판매금액', dataIndex: 'customerAmount', ...displayCurrency }),
  tableCols.billing.paymentType({ title: '결제방법' }),
  {
    title: '핏펫',
    children: [
      tableCols.currency.currencyText({ title: '쿠폰할인', dataIndex: 'couponDiscountPrice' }),
      tableCols.currency.currencyText({ title: '적립금 할인', dataIndex: 'mileageDiscountPrice' }),
      tableCols.currency.currencyText({ title: '공급대가(매출)', dataIndex: 'supplyAmount', ...displayCurrency }),
      tableCols.currency.currencyText({ title: '판매촉진비', dataIndex: 'promotionAmount', ...displayCurrency }),
    ],
  },
  {
    title: '셀러',
    children: [
      tableCols.seller.sellerShop({ title: '셀러', dataIndex: 'sellerBilling', ...noLinkFix }),
      tableCols.number.percentText({ title: '수수료(%)', dataIndex: ['orderItem', 'fitpetFeeRate'], width: 120 }),
      tableCols.billingItem.fitpetCommission({ title: '판매금액x수수료율' }),
      tableCols.currency.currencyText({ title: '매출', dataIndex: 'sellerSupplyAmount', ...displayCurrency }),
      tableCols.currency.currencyText({ title: '매입', dataIndex: 'sellerPromotionAmount', ...displayCurrency }),
      tableCols.billingItem.sellerFee({ title: '셀러 정산금액', ...noLinkFix }),
    ],
  },
]

const MainTableBillingListByProduct = () => {
  const queryParams: queryParamsProps = {
    gqlKey: 'billingItemsList',
    dataKey: 'billingItems',
    variables: {
      filter: { sellerFeeGte: 1 },
      orderBy: FIELD_SORT_KEYS.FIELD_SORT_CREATED_AT_DESC,
    },
  }

  return (
    <MMainTable
      TitleSet={{ title: '정산 목록', unit: '개' }}
      columns={tableColumns}
      queryParams={queryParams}
      multipleHeaderRow
      HeaderRightButtons={[<ButtonBillingItemExcelFileDownload />]}
    />
  )
}

export default MainTableBillingListByProduct
