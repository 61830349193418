import { gql } from '@apollo/client'

export const PRODUCT_PROMOTION_MUTATION_GQLS = {
  CREATE_PRODUCT_PROMOTION: gql`
    mutation createProductPromotion($input: CreateProductPromotionInput!) {
      createProductPromotion(input: $input) {
        productPromotion {
          id
        }
      }
    }
  `,
  UPDATE_PRODUCT_PROMOTION: gql`
    mutation updateProductPromotion($id: ID!, $input: UpdateProductPromotionInput!) {
      updateProductPromotion(id: $id, input: $input) {
        productPromotion {
          id
        }
      }
    }
  `,
  UPDATE_PRODUCT_PROMOTIONS: gql`
    mutation updateProductPromotions($input: UpdateProductPromotionsInput!) {
      updateProductPromotions(input: $input) {
        count
      }
    }
  `,
  DELETE_PRODUCT_PROMOTION: gql`
    mutation deleteProductPromotion($id: ID!) {
      deleteProductPromotion(id: $id) {
        productPromotion {
          id
        }
      }
    }
  `,
}

export default PRODUCT_PROMOTION_MUTATION_GQLS
