import { useRouter } from 'next/router'

import * as utilData from '@libs/utils/utilData'
import ROUTES from '@constants/constRoutes'
import { COUPON_GROUP_STATUS_TYPE, COUPON_ISSUE_TYPE } from '@constants/constDomain'
import ModelCouponGroup from 'containers/models/modelCouponGroup'
import { ITableColumn, ITableColumnFixedProps } from 'components/@atomics/MTable'
import { MLinkRouterButton, MTextButton, Text14Blue, Text14Normal } from 'components/@atomics'
import useModal from 'containers/hooks/useModal'
import { ModalConfirm } from 'domains/common/modals'

const couponGroupName = ({ dataIndex = 'name' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '쿠폰명',
    dataIndex,
    key: 'name',
    render: (name: any, record: ModelCouponGroup) => {
      return (
        <MLinkRouterButton
          href={{
            pathname: ROUTES.PROMOTIONS.COUPON_DETAIL,
            query: { id: record._id },
          }}
          openNewWindow
        >
          {name}
        </MLinkRouterButton>
      )
    },
  }
}

const benefit = ({ dataIndex = 'benefitText' } = {}): ITableColumn<any> => {
  return {
    title: '혜택',
    dataIndex,
    key: 'benefitText',
    render: (value: any) => {
      return value ? <>{value}</> : <>{utilData.tableText('-')}</>
    },
  }
}

const usingPeriod = (): ITableColumn<any> => {
  return {
    title: '사용 기간',
    dataIndex: 'hasUsingPeriod',
    key: 'hasUsingPeriod',
    render: (_value: any, record: ModelCouponGroup) => {
      return record ? <>{record.usingPeriodText}</> : <>{utilData.tableText('-')}</>
    },
  }
}

const issueStatus = (): ITableColumn<any> => {
  return {
    title: '발급상태',
    dataIndex: 'issueStatusText',
    key: 'issueStatusText',
    render: (value: string) => {
      return <Text14Normal>{value}</Text14Normal>
    },
  }
}

const issueType = ({ dataIndex = 'issueType', isShownIssueButton = false } = {}): ITableColumn<any> => {
  return {
    title: '발급방식',
    dataIndex,
    key: 'issueType',
    width: 100,
    render: (_: COUPON_ISSUE_TYPE, record: ModelCouponGroup) => {
      const router = useRouter()
      return (
        <>
          <Text14Normal>{record.issueTypeText}</Text14Normal>
          {record.isIssuableManual && isShownIssueButton ? (
            <MTextButton
              onClick={() =>
                router.push({
                  pathname: ROUTES.PROMOTIONS.COUPON_ISSUE,
                  query: { id: record._id },
                })
              }
            >
              <Text14Blue>[발급하기]</Text14Blue>
            </MTextButton>
          ) : null}
        </>
      )
    },
  }
}

const suspendButton = (refetchRef: { current: Function | null }): ITableColumn<any> => {
  return {
    title: '발급중지',
    dataIndex: 'couponGroupStatus',
    key: 'id',
    render: (couponGroupStatus: COUPON_GROUP_STATUS_TYPE, record: ModelCouponGroup) => {
      const { useModalProps } = useModal()
      const isSuspended = couponGroupStatus === COUPON_GROUP_STATUS_TYPE.SUSPENDED
      const titles = {
        mainText: isSuspended ? '중지 해제' : '중지',
        get modalTitle() {
          return `쿠폰 발급 ${this.mainText}`
        },
        get modalDescription() {
          return `${record.name}의 발급을 ${this.mainText} 하시겠습니까?`
        },
      }

      const handleClick = () => {
        useModalProps.setData({ couponGroup: record })
        useModalProps.showModal()
      }

      return (
        <>
          <MTextButton onClick={handleClick}>[{titles.mainText}]</MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: {
                gqlKey: 'updateCouponGroupStatus',
                variableKey: 'updateCouponGroupStatus',
              },
              title: titles.modalTitle,
              description: titles.modalDescription,
            }}
            onAPISuccess={() => {
              if (refetchRef && refetchRef.current) refetchRef.current()
            }}
          />
        </>
      )
    },
  }
}

const deleteButton = (refetchRef: { current: Function | null }): ITableColumn<any> => {
  return {
    title: '삭제',
    dataIndex: 'id',
    key: 'delete',
    render: (_: number, record: ModelCouponGroup) => {
      const { useModalProps } = useModal()
      const handleClick = () => {
        useModalProps.setData({ couponGroup: record })
        useModalProps.showModal()
      }

      return record.isDeletable ? (
        <>
          <MTextButton onClick={handleClick}>[삭제]</MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: {
                gqlKey: 'deleteCouponGroupWithUnusedCoupons',
                variableKey: 'deleteCouponGroup',
              },
              title: '쿠폰 삭제',
              description: '해당 쿠폰을 삭제하시겠습니까? \n 미사용 발급 쿠폰도 일괄 삭제됩니다.',
            }}
            onAPISuccess={() => {
              if (refetchRef && refetchRef.current) refetchRef.current()
            }}
          />
        </>
      ) : null
    },
  }
}

export {
  couponGroupName,
  benefit,
  usingPeriod,
  issueType,
  issueStatus,
  suspendButton,
  // editButton,
  deleteButton,
}
