import styled from 'styled-components'
import { SCREEN_MEDIA_QUERY } from '@constants/constLayout'
import { themes } from '@constants'

type responsiveNumberType = {
  xs: number
  md: number
}

// export const TextBase = styled.span<IStyledFontFormatter>`
//   color: ${(props) => props.fontFormat.color};
//   font-size: ${(props) => props.fontFormat.size};
// `

// export const Text14Base = styled.span<IStyledFontFormatter>`
//   ${themes.fonts.font14}
//   color: ${(props) => props.fontFormat.color};
// `

// how to use
// <Text14Normal>
//   {text}
// </Text14Normal>

type TextBaseProps = {
  bold?: boolean
  textAlign?: 'left' | 'center' | 'right'
  align?: 'center' | 'left' | 'right'
  color?: fontColorType
  size?: fontSizeType | fontResponsiveSizeType
  weight?: fontWeightType | fontResponsiveWeightType
  lineThrough?: boolean
  underline?: boolean
  ellipsis?: boolean
  letterSpacing?: number | responsiveNumberType
}

export const colorBlack = `color: ${themes.colors.black};`
export const colorBlack33 = `color: ${themes.colors.black33};`
export const colorA65 = `color: ${themes.colors.blackAlpha65};`
export const colorA45 = `color: ${themes.colors.blackAlpha45};`
export const colorA25 = `color: ${themes.colors.blackAlpha25};`
export const colorWhite = 'color: white;'
export const colorBlue = `color: ${themes.colors.blue};`
export const colorRed = `color: ${themes.colors.red};`
export const colorPurple = `color: ${themes.colors.purple}`
export const colorOrange = `color: ${themes.colors.orange};`
export const colorGrey55 = `color: ${themes.colors.grey55}`
export const colorGrey81 = `color: ${themes.colors.grey81}`
export const colorGrey97 = `color: ${themes.colors.grey97}`
export const colorGrey99 = `color: ${themes.colors.grey99}`

export const Text12Normal = styled.span<TextBaseProps>`
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  ${colorBlack}
  ${themes.fonts.font12}
  white-space: pre-line;
`
export const Text12A65 = styled(Text12Normal)`
  ${colorA65}
`
export const Text12A45 = styled(Text12Normal)`
  ${colorA45}
`
export const Text12A25 = styled(Text12Normal)`
  ${colorA25}
`
export const Text12White = styled(Text12Normal)`
  ${colorWhite}
`
export const Text12Blue = styled(Text12Normal)`
  ${colorBlue}
`
export const Text12Red = styled(Text12Normal)`
  ${colorRed}
`
export const Text12Grey55 = styled(Text12Normal)`
  ${colorGrey55}
`
export const Text12Grey81 = styled(Text12Normal)`
  ${colorGrey81}
`

export const Text10Normal = styled(Text12Normal)`
  ${colorBlack}
  ${themes.fonts.font10}
`
export const Text10A25 = styled(Text10Normal)`
  ${colorA25}
`

export const Text14Normal = styled(Text12Normal)`
  ${colorBlack}
  ${themes.fonts.font14}
  white-space: pre-line;
`
export const Text14Black33 = styled(Text14Normal)`
  ${colorBlack33}
`
export const Text14A65 = styled(Text14Normal)`
  ${colorA65}
`
export const Text14A45 = styled(Text14Normal)`
  ${colorA45}
`
export const Text14A25 = styled(Text14Normal)`
  ${colorA25}
`
export const Text14White = styled(Text14Normal)`
  ${colorWhite}
`
export const Text14Blue = styled(Text14Normal)`
  ${colorBlue}
`
export const Text14Red = styled(Text14Normal)`
  ${colorRed}
`
export const Text14Purple = styled(Text14Normal)`
  ${colorPurple}
`
export const Text14Orange = styled(Text14Normal)`
  ${colorOrange}
`
export const Text14G97 = styled(Text14Normal)`
  ${colorGrey97}
`
export const Text14G99 = styled(Text14Normal)`
  ${colorGrey99}
`
export const Text14G55 = styled(Text14Normal)`
  ${colorGrey55}
`

export const Text16Normal = styled(Text12Normal)`
  ${themes.fonts.font16}
`
export const Text16A65 = styled(Text16Normal)`
  ${colorA65}
`
export const Text16A45 = styled(Text16Normal)`
  ${colorA45}
`
export const Text16A25 = styled(Text16Normal)`
  ${colorA25}
`
export const Text16Black33 = styled(Text16Normal)`
  ${colorBlack33}
`
export const Text16White = styled(Text16Normal)`
  ${colorWhite}
`
export const Text16Blue = styled(Text16Normal)`
  ${colorBlue}
`
export const Text16Red = styled(Text16Normal)`
  ${colorRed}
`

export const Text18Normal = styled(Text12Normal)`
  ${themes.fonts.font18}
`

export const Text20Normal = styled(Text12Normal)`
  ${themes.fonts.font20}
`
export const Text20A65 = styled(Text20Normal)`
  ${colorA65}
`
export const Text20A45 = styled(Text20Normal)`
  ${colorA45}
`
export const Text20A25 = styled(Text20Normal)`
  ${colorA25}
`
export const Text20White = styled(Text20Normal)`
  ${colorWhite}
`
export const Text20Blue = styled(Text20Normal)`
  ${colorBlue}
`

export const Text24Normal = styled(Text12Normal)`
  ${themes.fonts.font24}
`
export const Text24A65 = styled(Text24Normal)`
  ${colorA65}
`
export const Text24A45 = styled(Text24Normal)`
  ${colorA45}
`
export const Text24A25 = styled(Text24Normal)`
  ${colorA25}
`
export const Text24White = styled(Text24Normal)`
  ${colorWhite}
`
export const Text24Blue = styled(Text24Normal)`
  ${colorBlue}
`

export const Text30Normal = styled(Text12Normal)`
  ${themes.fonts.font30}
`
export const Text30A65 = styled(Text30Normal)`
  ${colorA65}
`
export const Text30A45 = styled(Text30Normal)`
  ${colorA45}
`
export const Text30A25 = styled(Text30Normal)`
  ${colorA25}
`
export const Text30White = styled(Text30Normal)`
  ${colorWhite}
`
export const Text30Blue = styled(Text30Normal)`
  ${colorBlue}
`

export const Text40Normal = styled(Text12Normal)`
  ${themes.fonts.font40}
`

export type fontSizeType = 10 | 11 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 34 | 36 | 40 | 48 | 60 | 68
export type fontWeightType = 'thin' | 'normal' | 'medium' | 'bold'
type fontColorType =
  | 'default'
  | 'black'
  | 'blackA85'
  | 'blackA100'
  | 'black33'
  | 'white'
  | 'greyc0'
  | 'greyd5'
  | 'greyfa'
  | 'grey81'
  | 'greyb2'
  | 'grey97'
  | 'grey99'
  | 'greyd4'
  | 'red'
  | 'blue'

export type fontResponsiveSizeType = {
  xs: fontSizeType
  md: fontSizeType
}

export type fontResponsiveWeightType = {
  xs: fontWeightType
  md: fontWeightType
}

// export type TextBaseProps = {
//   align?: 'center' | 'left' | 'right';
//   color?: fontColorType;
//   size?: fontSizeType | fontResponsiveSizeType;
//   weight?: fontWeightType | fontResponsiveWeightType;
//   lineThrough?: boolean;
//   underline?: boolean;
//   ellipsis?: boolean;
//   letterSpacing?: number | responsiveNumberType;
// }

const Text = styled.span<TextBaseProps>`
  text-overflow: ellipsis;
  margin: 0px;
  line-height: 1.4;
  white-space: pre-line !important;
  color: ${({ color }) => {
    if (color === 'black') {
      return themes.colors.blackAlpha100
    }
    if (color === 'blackA85') {
      return themes.colors.blackAlpha85
    }
    if (color === 'blackA100') {
      return themes.colors.blackAlpha100
    }
    if (color === 'black33') {
      return themes.colors.black33
    }
    if (color === 'white') {
      return themes.colors.white
    }
    if (color === 'greyc0') {
      return themes.colors.greyc0
    }
    if (color === 'greyd4') {
      return themes.colors.greyd4
    }
    if (color === 'greyfa') {
      return themes.colors.greyfa
    }
    if (color === 'grey81') {
      return themes.colors.grey81
    }
    if (color === 'greyb2') {
      return themes.colors.greyb2
    }
    if (color === 'greyd5') {
      return themes.colors.greyd5
    }
    if (color === 'grey97') {
      return themes.colors.grey97
    }
    if (color === 'grey99') {
      return themes.colors.grey99
    }
    if (color === 'red') {
      return themes.colors.red
    }
    if (color === 'blue') {
      return themes.colors.blue
    }
    return themes.colors.blackAlpha100
  }};
  letter-spacing: ${({ letterSpacing }) => {
    if (!letterSpacing) {
      return 'normal'
    }

    if (typeof letterSpacing === 'number') {
      return `${letterSpacing}px`
    }

    return `${letterSpacing.md}px`
  }};
  font-size: ${({ size }) => {
    if (!size) {
      return '16px'
    }
    if (typeof size === 'number') {
      return `${size}px`
    }
    return `${size.md}px`
  }};
  font-weight: ${({ weight }) => {
    if (weight === 'thin') {
      return 300
    }
    if (weight === 'medium') {
      return 500
    }
    if (weight === 'bold') {
      return 700
    }
    return 400
  }};
  font-weight: ${({ weight }) => {
    if (!weight) {
      return 400
    }
    let _weight
    if (typeof weight === 'string') {
      _weight = weight
    } else {
      _weight = weight.md
    }
    if (_weight === 'thin') {
      return 300
    }
    if (_weight === 'medium') {
      return 500
    }
    if (_weight === 'bold') {
      return 700
    }
    return 400
  }};
  text-align: ${({ align }) => align || 'left'};
  text-decoration-line: ${({ lineThrough, underline }) => {
    if (lineThrough) {
      return 'line-through'
    }
    if (underline) {
      return 'underline'
    }
    return 'none'
  }};
  white-space: ${({ ellipsis }) => {
    if (ellipsis) {
      return 'nowrap'
    }
    return 'inherit'
  }};
  overflow: ${({ ellipsis }) => {
    if (ellipsis) {
      return 'hidden'
    }
    return 'inherit'
  }};

  @media ${SCREEN_MEDIA_QUERY.xs} {
    font-size: ${({ size }) => {
      if (!size) {
        return '16px'
      }
      if (typeof size === 'number') {
        return `${size}px`
      }
      return `${size.xs}px`
    }};

    font-weight: ${({ weight }) => {
      if (!weight) {
        return 400
      }
      let _weight
      if (typeof weight === 'string') {
        _weight = weight
      } else {
        _weight = weight.xs
      }
      if (_weight === 'thin') {
        return 300
      }
      if (_weight === 'medium') {
        return 500
      }
      if (_weight === 'bold') {
        return 700
      }
      return 400
    }};
    letter-spacing: ${({ letterSpacing }) => {
      if (!letterSpacing) {
        return 'normal'
      }

      if (typeof letterSpacing === 'number') {
        return `${letterSpacing}px`
      }

      return `${letterSpacing.xs}px`
    }};
  }
`

export const TextEllipsis = styled(Text)`
  font-size: 14px;
  color: ${themes.colors.blue};
  white-space: pre !important;
  display: block;
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  align-items: middle;
`

export default Text
