import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'
import NOTICE_FRAGMENTS from 'containers/gqls/configurations/notice/fragments'

const NOTICE_QUERY_GQLS = {
  NOTICES: gql`
    query notices($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      notices(pageInfo: $pageInfo, filter: $filter) {
        ...notices
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${NOTICE_FRAGMENTS.notices}
  `,
  CUSTOMER_NOTICE_TABLE: gql`
    query customerNoticeTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String, $search: String) {
      notices(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy, search: $search) {
        edges {
          node {
            id
            title
            body
            receiverType
            kind
            attachment
            isImportant
            createdAt
            user {
              id
              email
              name
            }
          }
        }
        totalCount
        pageInfo {
          ...pageInfo
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  FORM_NOTICE: gql`
    query formNotice($id: ID!) {
      notice(id: $id) {
        id
        title
        body
        receiverType
        kind
        attachment
        isImportant
        createdAt
        user {
          id
          email
          name
        }
      }
    }
  `,
  PARTNER_NOTICE_TABLE: gql`
    query partnerNoticeTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String, $search: String) {
      notices(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy, search: $search) {
        edges {
          node {
            id
            title
            body
            attachment
            isImportant
            createdAt
            kind
            receiverType
            user {
              id
              email
              name
            }
          }
        }
        totalCount
        pageInfo {
          ...pageInfo
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default NOTICE_QUERY_GQLS
