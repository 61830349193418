import { IModelOrderItemReturnReasonDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelOrderItemReturnReasonDefault } from 'containers/models/base/defaultModels'
import { IModelReturnReason } from 'containers/models/modelReturnReason'

export interface IModelOrderItemReturnReason extends IModelOrderItemReturnReasonDefault {
  returnReasons: IModelReturnReason[]
}

export default class ModelOrderItemReturnReason extends ModelOrderItemReturnReasonDefault {
  constructor(data: IModelOrderItemReturnReason) {
    super(data)
  }
}
