import { ReactElement } from 'react'

import { MLinkRouterButton } from 'components/@atomics'
import ROUTES from '@constants/constRoutes'
import { MMainTableProps } from 'components/MMainTable'
import { MMainTable } from 'components'
import { ITableColumn } from 'components/@atomics/MTable'
import tableCols from 'domains/admin/tableCols'
import { queryParamsProps } from 'components/MQuery'

const tableColumns = (): Array<ITableColumn<any>> => {
  return [
    tableCols.common.ID(),
    tableCols.push.detailButton(),
    tableCols.push.sendAt(),
    tableCols.push.imageThumbnail(),
    tableCols.push.titleAndDescription(),
    tableCols.push.linkButton(),
    tableCols.common.baseText({ title: '푸시 종류', dataIndex: ['push', 'pushTypeDisplay'] }),
    tableCols.push.userGrades(),
    tableCols.push.successCountPerTotal(),
    tableCols.push.readCountPerTotal(),
  ]
}

const MainTablePushList = (): ReactElement<MMainTableProps> => {
  const queryParams: queryParamsProps = {
    gqlKey: 'informPushQueues',
  }

  return (
    <MMainTable
      TitleSet={{ title: '발송 내역 테이블 표시항목', unit: '건' }}
      HeaderLeftButtons={[
        <MLinkRouterButton buttonType="button" type="primary" href={ROUTES.ACCOUNTS.PUSH_SEND}>
          푸시 신규 발송
        </MLinkRouterButton>,
      ]}
      columns={tableColumns()}
      queryParams={queryParams}
    />
  )
}

export default MainTablePushList
