import _ from 'lodash'
import * as utilDate from '@libs/utils/utilDate'
import { ORDER_STATUS_TYPE } from '@constants/constDomain'
import { IModelOrderDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelOrderDefault } from 'containers/models/base/defaultModels'
import { IModelCustomerAddress } from 'containers/models/modelCustomerAddress'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { IModelUser } from 'containers/models/modelUser'
import { IModelTransaction } from 'containers/models/modelTransaction'
import { IModelShipping } from 'containers/models/modelShipping'
import { hasData } from '@libs/utils/utilData'
import { IModelCoupon } from 'containers/models/modelCoupon'
import ModelCouponInfo, { IModelCouponInfo } from 'containers/models/modelCouponInfo'

export interface IModelOrder extends IModelOrderDefault {
  approvedTransaction: IModelTransaction
  canceledOrderItems: IModelOrderItem[]
  couponInfoData: IModelCouponInfo
  customerAddress: IModelCustomerAddress
  orderCoupons: IModelCoupon[]
  orderOrderItems: IModelOrderItem[]
  paidAtText: string
  returnedOrderItems: IModelOrderItem[]
  exchangedOrderItems: IModelOrderItem[]
  shippings: IModelShipping[]
  totalCouponDiscountAmount: number
  user: IModelUser
  shippingCouponNameText: string
  orderItemCouponNameText: string
}

export default class ModelOrder extends ModelOrderDefault {
  canceledOrderItems: IModelOrderItem[] = []
  couponInfoData: IModelCouponInfo = {} as IModelCouponInfo
  paidAtText: string = ''
  returnedOrderItems: IModelOrderItem[] = [] as IModelOrderItem[]
  exchangedOrderItems: IModelOrderItem[] = [] as IModelOrderItem[]
  totalCouponDiscountAmount: number = 0
  shippingCouponNameText: string = ''
  orderItemCouponNameText: string = ''

  constructor(data: IModelOrder) {
    super(data)
    this.setOrderItemRowSize()

    this.paidAtText = utilDate.date2string(this.approvedTransaction.approvedAt)
    this.canceledOrderItems = getCanceledOrderItems(this)
    this.couponInfoData = this.couponInfo
      ? new ModelCouponInfo(this.couponInfo, this.couponDiscountAmount)
      : ({} as IModelCouponInfo)
    this.paidAtText = utilDate.date2string(this.approvedTransaction.approvedAt)
    this.returnedOrderItems = getReturnedOrderItems(this)
    this.exchangedOrderItems = getExchangedOrderItems(this)
    this.totalCouponDiscountAmount =
      this.couponDiscountAmount + this.orderCouponDiscountAmount + this.shippingCouponDiscountAmount
    this.shippingCouponNameText = getShippingCouponNameText(this as unknown as IModelOrder)
    this.orderItemCouponNameText = getOrderItemCouponNameText(this as unknown as IModelOrder)
  }

  private setOrderItemRowSize = () => {
    const orderItemGroupMap: { [index: string]: IModelOrderItem[] } = {}
    const orderItemsOrderById = _.orderBy(this.orderOrderItems, ['_id'], ['asc']) as IModelOrderItem[]

    // 동일한 brand id를 grouping
    orderItemsOrderById.forEach((orderItem) => {
      const { brand } = orderItem
      orderItemGroupMap[brand.id] = orderItemGroupMap[brand.id]
        ? orderItemGroupMap[brand.id].concat([orderItem])
        : [orderItem]
    })

    // brand id grouping 길이에 따라 rowSize 설정
    Object.entries(orderItemGroupMap).forEach(([key, value]) => {
      const item = orderItemsOrderById.find((orderItem) => {
        return orderItem.brand.id === key
      }) ?? { rowSize: 0 }

      item.rowSize = value.length
    })
  }
}

const getCanceledOrderItems = (data: ModelOrder) => {
  if (!data.orderOrderItems) {
    return []
  }

  return data.orderOrderItems.filter((orderItem) => {
    return (
      orderItem.orderStatus === ORDER_STATUS_TYPE.CANCEL_REQUESTED ||
      orderItem.orderStatus === ORDER_STATUS_TYPE.CANCELED
    )
  }) as IModelOrderItem[]
}

const getReturnedOrderItems = (data: ModelOrder) => {
  if (!data.orderOrderItems) {
    return []
  }

  return data.orderOrderItems.filter((orderItem) => {
    return (
      orderItem.orderStatus === ORDER_STATUS_TYPE.RETURN_REQUESTED ||
      orderItem.orderStatus === ORDER_STATUS_TYPE.RETURN_UNDER_RETURN ||
      orderItem.orderStatus === ORDER_STATUS_TYPE.RETURN_RETURNED ||
      orderItem.orderStatus === ORDER_STATUS_TYPE.RETURN_CONFIRMED
    )
  }) as IModelOrderItem[]
}

const getExchangedOrderItems = (data: ModelOrder) => {
  if (!data.orderOrderItems) {
    return []
  }

  return data.orderOrderItems.filter((orderItem) => {
    return (
      orderItem.orderStatus === ORDER_STATUS_TYPE.EXCHANGE_REQUESTED ||
      orderItem.orderStatus === ORDER_STATUS_TYPE.EXCHANGE_UNDER_RETURN ||
      orderItem.orderStatus === ORDER_STATUS_TYPE.EXCHANGE_CONFIRMED
    )
  }) as IModelOrderItem[]
}

const getShippingCouponNameText = (data: IModelOrder) => {
  if (hasData(data.shippings)) {
    const shippingCouponNames = data.shippings.filter((x) => x.couponInfoData.name).map((x) => x.couponInfoData.name)
    return shippingCouponNames.join(', ')
  }
  return ''
}

const getOrderItemCouponNameText = (data: IModelOrder) => {
  if (hasData(data.orderOrderItems)) {
    const orderItemCouponNames = data.orderOrderItems
      .filter((x) => x.couponInfoData.name)
      .map((x) => x.couponInfoData.name)
    return orderItemCouponNames.join(', ')
  }
  return ''
}
