import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { Descriptions } from 'antd'

const DescOrderItem = ({ orderItem }: { orderItem: IModelOrderItem }) => {
  const { order, orderItemNumber, productOption } = orderItem
  const {
    product: { name: productName },
  } = productOption
  return (
    <Descriptions title="주문내역" bordered column={1}>
      <Descriptions.Item label="주문번호">{order.orderNumber}</Descriptions.Item>
      <Descriptions.Item label="상품주문번호">{orderItemNumber}</Descriptions.Item>
      {/* <Descriptions.Item label="상품명">{productOption.product.name}</Descriptions.Item> */}
      <Descriptions.Item label="상품명">{productName}</Descriptions.Item>
    </Descriptions>
  )
}

export default DescOrderItem
