import { Store } from 'rc-field-form/lib/interface'
import { Form, Space } from 'antd'
import { TablePaginationConfig } from 'antd/es/table'

import { TypeUseModalProps } from 'containers/hooks/useModal'
import { MModal } from 'components/modals'
import { MFormItemInput } from 'components/formItems'
import MQuery, { listVariablesProps, queryParamsProps } from 'components/MQuery'
import MTable, { ITableColumn } from 'components/@atomics/MTable'
import useTable from 'containers/hooks/useMTable'
import { MButton, Text12Blue, Text12Normal } from 'components/@atomics'
import { footerTypeProps } from 'components/modals/MModal'

type searchInputProps = {
  label: string
  name: string
}

export type modalLayoutProps = {
  rowSelectionType?: 'radio'
  footerType?: footerTypeProps
}

export type modalContentProps = {
  title: string
  queryParams: queryParamsProps
  tableColumns: Array<ITableColumn<any>>
  onItemSelect?: (item: any) => void
  okText?: string
  searchInputs?: searchInputProps[]
}

type ModalTableSelectProps = modalContentProps & {
  useModalProps: TypeUseModalProps<any>
  modalLayout?: modalLayoutProps
  // refetchRef?: MutableRefObject<Function | null>;
}

type FormSearchProps = {
  searchInputs?: searchInputProps[]
  setFilterAndDoRefetch: (variables: object) => void
}

const FormSearch = ({ searchInputs, setFilterAndDoRefetch }: FormSearchProps) => {
  const onFinish = (values: Store) => {
    setFilterAndDoRefetch(values)
  }
  return (
    <Form name="filterSearch" onFinish={onFinish}>
      <Space align="baseline" size="middle">
        {searchInputs &&
          searchInputs.map((searchInput, index) => {
            const keyIndex = index
            const { label, name } = searchInput
            return <MFormItemInput key={keyIndex} label={label} name={name} />
          })}
        <MButton type="primary" htmlType="submit" width={68}>
          검색
        </MButton>
      </Space>
    </Form>
  )
}

const ModalFunctionalTable = ({
  useModalProps,
  // 현재 단순 toggle 액션밖에 없어 setState로 처리하여 refetch가 필요없으나 나중에 모달 테이블 갱신을 위해 필요할 수도 있음
  // refetchRef,
  title,
  searchInputs,
  tableColumns,
  queryParams,
  okText,
}: ModalTableSelectProps) => {
  const pagination: TablePaginationConfig = { position: ['bottomCenter'] }
  const { getTablePagination, setInitVariablesAndReturn, setRefetch, setFilterAndDoRefetch } = useTable()
  queryParams.variables = setInitVariablesAndReturn(queryParams.variables as listVariablesProps)

  return (
    <MModal
      title={title}
      okText={okText}
      useModalProps={useModalProps}
      width={900}
      footerType="MODAL_FOOTER_OK"
      ComponentContent={
        <>
          <MQuery queryParams={{ ...queryParams }}>
            {({ data, refetch }: any) => {
              if (!data) {
                return null
              }
              const { dataKey, gqlKey } = queryParams
              const _dataKey = dataKey || gqlKey
              setRefetch(refetch)
              const { totalCount, data: _data } = data[_dataKey]
              const tablePagination = getTablePagination(pagination, totalCount)
              return (
                <>
                  {searchInputs && (
                    <FormSearch searchInputs={searchInputs} setFilterAndDoRefetch={setFilterAndDoRefetch} />
                  )}
                  <Text12Normal>
                    목록 (총 <Text12Blue>{totalCount}개)</Text12Blue>
                  </Text12Normal>
                  <MTable columns={tableColumns} dataSource={_data} pagination={tablePagination} />
                </>
              )
            }}
          </MQuery>
        </>
      }
    />
  )
}

export default ModalFunctionalTable
