import { FunctionComponent } from 'react'
import { Col, Radio, Row } from 'antd'
import styled from 'styled-components'

import { themes } from '@constants'
import { FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { USER_GENDER } from '@constants/constDomain'
import { MFormItemWrapper } from 'components/formItems'

const FORM_ITEM_PROPS_GENDER = {
  label: FIELD_VALUES.FIELD_GENDER,
  name: FIELD_KEYS.FIELD_GENDER,
  rules: [],
}

const GENDER_OPTIONS = [
  { label: '여성', value: USER_GENDER.FEMALE },
  { label: '남성', value: USER_GENDER.MALE },
]

export type MFormItemSelectGenderProps = {
  theme?: 'blackAndWhite'
}

const FormItemSelectGender: FunctionComponent<MFormItemSelectGenderProps> = ({ theme }) => {
  return (
    <MFormItemWrapper {...FORM_ITEM_PROPS_GENDER}>
      <Radio.Group size="large" style={{ width: '100%' }}>
        <Row justify="space-between" gutter={[10, 10]}>
          {GENDER_OPTIONS.map((option) => (
            <Col key={option.value} span={12}>
              <StyledRadioButton
                theme={theme}
                style={{ width: '100%', textAlign: 'center', fontSize: 14 }}
                value={option.value}
              >
                {option.label}
              </StyledRadioButton>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </MFormItemWrapper>
  )
}

// TODO: set css for black and white
const StyledRadioButton = styled(Radio.Button)<MFormItemSelectGenderProps>`
  // ${(props) =>
    props.theme === 'blackAndWhite' &&
    `
  //   .ant-radio-button-wrapper {
  //     color: ${themes.colors.red};
  //   }
  //   span.ant-radio-button-checked span {
  //     color: ${themes.colors.red};
  //   };
  //   background-color: ${props.disabled ? themes.colors.greyc0 : themes.colors.black33};
  //   border: ${props.disabled ? themes.colors.greyc0 : `1px solid ${themes.colors.black33}`};
  //   &:disabled {
  //     background-color: ${themes.colors.greyd9};
  //   };
  //   &:hover {
  //     background-color: ${props.disabled ? themes.colors.greyc0 : themes.colors.blackAlpha100};
  //     border: ${props.disabled ? themes.colors.greyc0 : `1px solid ${themes.colors.black33}`};
  //   };
  //   &:focus{
  //     background-color: ${props.disabled ? themes.colors.greyc0 : themes.colors.black33};
  //     border: ${props.disabled ? themes.colors.greyc0 : `1px solid ${themes.colors.blackAlpha100}`};
  //   }
  // `}
`

export default FormItemSelectGender
