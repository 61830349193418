import { Text14Normal } from 'components/@atomics'
import { ITableColumn, ITableColumnTitleDataIndexProps } from 'components/@atomics/MTable'
import * as utilData from '@libs/utils/utilData'

const percentText = ({ title, dataIndex, fixed, width = 100 }: ITableColumnTitleDataIndexProps): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: Array.isArray(dataIndex) ? dataIndex[dataIndex.length - 1] : dataIndex!,
    fixed,
    width,
    render: (value: number) => {
      return <Text14Normal>{`${utilData.tableNumberText((value * 100).toFixed(2))}%`}</Text14Normal>
    },
  }
}

const rateText = ({ title, dataIndex, fixed, width = 100 }: ITableColumnTitleDataIndexProps): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: Array.isArray(dataIndex) ? dataIndex[dataIndex.length - 1] : dataIndex!,
    fixed,
    width,
    render: (v: number) => {
      const rate = (v * 100).toFixed(2)
      return <Text14Normal>{`${rate}`}</Text14Normal>
    },
  }
}

export {
  percentText, // 카운트
  rateText, // 할인율, % 제외
}
