import { ModelReturnInfoDefault } from 'containers/models/base/defaultModels'
import { IModelReturnInfoDefault } from 'containers/models/base/defaultModelInterfaces'
import { IModelCustomerAddress } from 'containers/models/modelCustomerAddress'
import { IModelReturnImageInfo } from 'containers/models/modelReturnInfoImage'

export interface IModelReturnInfo extends IModelReturnInfoDefault {
  customerAddress: IModelCustomerAddress
  returnInfoReturnInfoImages: IModelReturnImageInfo[]
}

export default class ModelReturnInfo extends ModelReturnInfoDefault {
  constructor(data: IModelReturnInfo) {
    super(data)
  }
}
