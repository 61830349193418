import { IModelSlimBannerInfoDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelSlimBannerInfoDefault } from 'containers/models/base/defaultModels'

export interface IModelSlimBannerInfo extends IModelSlimBannerInfoDefault {}

export default class ModelSlimBannerInfo extends ModelSlimBannerInfoDefault {
  constructor(data: IModelSlimBannerInfo) {
    super(data)
  }
}
