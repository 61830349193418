import { IModelProductImageDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelProductImageDefault } from 'containers/models/base/defaultModels'
import { S3_HOST_URL } from '@constants/constData'

export interface IModelProductImage extends IModelProductImageDefault {
  imageUrl: string
  thumbnailUrl: string
}

export default class ModelProductImage extends ModelProductImageDefault {
  imageUrl: string = ''
  thumbnailUrl: string = ''

  constructor(data: IModelProductImage) {
    super(data)

    this.imageUrl = `${S3_HOST_URL}/${this.image}`
    this.thumbnailUrl = `${S3_HOST_URL}/${this.thumbnail}`
  }
}
