import { MutableRefObject } from 'react'
import { MButton } from 'components/@atomics'
import { message } from 'antd'
import useModal from 'containers/hooks/useModal'
import { hasData } from '@libs/utils/utilData'
import { ModalConfirmTable } from 'domains/common/modals'

type ButtonDeleteMultipleSelectedProps = {
  target: 'reviews' | 'incompleteProducts' | 'displayProductInfos'
  refetchRef: MutableRefObject<Function | null>
  selectedRef: MutableRefObject<any[]>
}

type TypeModalData = {
  [key: string]: {
    [key: string]: string | { [key: string]: string }
    mutationKey: {
      gqlKey: 'deleteReviews' | 'deleteIncompleteProducts' | 'deleteDisplayProductInfos'
      variableKey: string
    }
    title: string
  }
}

const modalData: TypeModalData = {
  reviews: {
    mutationKey: {
      gqlKey: 'deleteReviews',
      variableKey: 'deleteReviews',
    },
    title: '리뷰 삭제',
    description: '해당 리뷰를 삭제합니다. 삭제된 리뷰는 복구할 수 없습니다.',
    componentOption: {
      tableComponentKey: 'tableReviewDefault',
    },
  },
  incompleteProducts: {
    mutationKey: {
      gqlKey: 'deleteIncompleteProducts',
      variableKey: 'deleteIncompleteProducts',
    },
    title: '임시등록상품 삭제',
    description: '해당 임시등록상품을 삭제합니다. 삭제된 임시등록상품은 복구할 수 없습니다.',
    componentOption: {
      tableComponentKey: 'tableProductDefault',
    },
  },
  displayProductInfos: {
    mutationKey: {
      gqlKey: 'deleteDisplayProductInfos',
      variableKey: 'deleteDisplayProductInfos',
    },
    title: '상품 삭제',
    description: '해당 상품을 삭제합니다. 삭제된 상품은 복구할 수 없습니다.',
    componentOption: {
      tableComponentKey: 'tableDisplayProductInfoDefault',
    },
  },
}

const ButtonDeleteMultipleSelected = ({ target, refetchRef, selectedRef }: ButtonDeleteMultipleSelectedProps) => {
  const { useModalProps } = useModal()
  const _target = target === 'incompleteProducts' ? 'products' : target
  return (
    <>
      <MButton
        onClick={() => {
          if (hasData(selectedRef.current)) {
            useModalProps.toggleModal()
            useModalProps.setData({ [_target]: selectedRef.current })
          } else {
            message.warn('선택된 항목이 없습니다.')
          }
        }}
      >
        선택 삭제
      </MButton>

      <ModalConfirmTable
        useModalProps={useModalProps}
        fixedProps={modalData[target]}
        onAPISuccess={() => {
          if (refetchRef.current) {
            message.warn('선택된 항목이 삭제되었습니다.')
            refetchRef.current()
          }
        }}
      />
    </>
  )
}

export default ButtonDeleteMultipleSelected
