import { FunctionComponent, ReactNode, CSSProperties } from 'react'
import styled from 'styled-components'
import { Tag } from 'antd'
import { LiteralUnion } from 'antd/lib/_util/type'
import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors'

interface MTagProps {
  className?: string
  color?: LiteralUnion<PresetColorType | PresetStatusColorType, string>
  closable?: boolean
  visible?: boolean
  onClose?: Function
  style?: CSSProperties
  icon?: ReactNode
  placeholder?: string
  width?: number
  children: ReactNode
}

const MTag: FunctionComponent<MTagProps> = ({ closable, style, width, children }) => {
  return (
    <StyledTag closable={closable} style={style} width={width}>
      {children}
    </StyledTag>
  )
}

const StyledTag = styled(Tag)<MTagProps>`
  background: white;
  border: none;
  width: ${(props) => props.width}px;
  padding: 0;
  margin-top: 6px;
`

export default MTag
