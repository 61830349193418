import { FunctionComponent, MutableRefObject, useRef, useState } from 'react'
import { queryParamsProps } from 'components/MQuery'
import tableCols from 'domains/admin/tableCols'
import { IModelCoupon } from 'containers/models/modelCoupon'
import { MMainTable } from 'components'
import { ITableColumn } from 'components/@atomics/MTable'
import { ButtonDeleteCoupons, ButtonDeleteCouponsByGroup } from 'domains/admin/tableButtons'
import { DataResult, DataResults } from 'containers/gqls/data'
import { useDispatch } from 'react-redux'
import { doInitializeRowSelection } from '@libs/redux/reduxUI'
import { ModelCouponGroup } from 'containers/models'
import { ButtonUploadExcel } from 'domains/common/tableButtons'
import { COUPON_ISSUE_TYPE } from '@constants/constDomain'

type MainTableIssuedCouponListProps = {
  selectedCouponGroup: ModelCouponGroup
}

const tableColumns = (refetchRef: MutableRefObject<Function | null>): Array<ITableColumn<IModelCoupon>> => {
  return [
    tableCols.common.ID(),
    tableCols.user.username({ dataIndex: 'user' }),
    tableCols.common.baseText({ title: '이름', dataIndex: ['user', 'name'] }),
    tableCols.common.baseTextFromModel({ title: '등급', dataIndex: ['user', 'grade'], key: 'name' }),
    tableCols.date.createdAtText({ title: '발급일' }),
    tableCols.date.expiredAtText({ title: '만료일' }),
    tableCols.date.usedAtText({ title: '사용일' }),
    tableCols.common.baseText({ title: '발급 처리', dataIndex: 'issuedFrom' }),
    tableCols.common.baseText({ title: '상태', dataIndex: 'usedStatusText' }),
    tableCols.coupon.deleteButton(refetchRef),
  ]
}

const HeaderLeftButtons = (
  couponGroup: ModelCouponGroup,
  selected: IModelCoupon[],
  clearSelected: Function,
  refetchRef: MutableRefObject<Function | null>,
  dataRef: MutableRefObject<{ [index: string]: DataResults | DataResult } | null>
) => {
  return [
    <ButtonDeleteCouponsByGroup
      couponGroup={couponGroup}
      clearSelected={clearSelected}
      refetchRef={refetchRef}
      dataRef={dataRef}
    />,
    <ButtonDeleteCoupons selected={selected} clearSelected={clearSelected} refetchRef={refetchRef} />,
    ...(couponGroup.issueType === COUPON_ISSUE_TYPE.MANUAL
      ? [
          <ButtonUploadExcel
            title={`${couponGroup.name} 발급 엑셀 파일 업로드`}
            uploadExcelGqlKey="issueManualCouponsByExcel"
            guideMessage={`${couponGroup.name} 쿠폰을 발급 하시겠습니까?`}
            failedGuideMessage={`${couponGroup.name} 쿠폰 발급 등록에 실패했습니다. 업로드하신 파일에서 아래 내용 수정 후 다시 진행해 주세요.`}
            externalInputVariables={{ id: couponGroup.id }}
            refetchRef={refetchRef}
          />,
        ]
      : []),
  ]
}

const MainTableIssuedCouponList: FunctionComponent<MainTableIssuedCouponListProps> = ({ selectedCouponGroup }) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState<[]>([])
  const clearSelected = () => {
    dispatch(doInitializeRowSelection())
    setSelected([])
  }
  const refetchRef = useRef<Function | null>(null)
  const dataRef = useRef<{ [index: string]: DataResults | DataResult } | null>(null)
  const onRowSelect = (rows: []) => {
    setSelected(rows)
  }
  const variables = {
    filter: { couponGroup: selectedCouponGroup.id },
    orderBy: '-createdAt',
  }
  const queryParams: queryParamsProps = {
    gqlKey: 'coupons',
    variables,
  }

  return (
    <MMainTable
      TitleSet={{ title: '쿠폰 발급 목록', unit: '개' }}
      columns={tableColumns(refetchRef)}
      queryParams={queryParams}
      refetchRef={refetchRef}
      HeaderLeftButtons={HeaderLeftButtons(selectedCouponGroup, selected, clearSelected, refetchRef, dataRef)}
      onRowSelect={onRowSelect}
      rowSelectionType="checkbox"
      dataRef={dataRef}
    />
  )
}

export default MainTableIssuedCouponList
