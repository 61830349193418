import { MutableRefObject } from 'react'
import { message } from 'antd'

import { MButton } from 'components/@atomics'
import { IModelProduct } from 'containers/models/modelProduct'
import { ModalConfirmTable } from 'domains/common/modals'
import useModal from 'containers/hooks/useModal'

const ButtonSoldOutProducts = ({
  selected,
  refetchRef,
  statusFilterRefetchRef,
}: {
  selected: MutableRefObject<IModelProduct[]>
  refetchRef: MutableRefObject<Function | null>
  statusFilterRefetchRef: MutableRefObject<Function | null>
}) => {
  const { useModalProps } = useModal()
  return (
    <>
      <MButton
        type="primary"
        onClick={() => {
          const selectedLength: number = selected.current.length
          if (!selectedLength) {
            message.warning('선택된 항목이 없습니다.')
          } else {
            useModalProps.toggleModal()
            useModalProps.setData({ products: selected.current, selectedLength })
          }
        }}
        width={90}
      >
        품절처리
      </MButton>
      <ModalConfirmTable
        useModalProps={useModalProps}
        fixedProps={{
          mutationKey: {
            gqlKey: 'updateProductsSoldOut',
            variableKey: 'productUpdateProductsSoldOut',
          },
          title: '상품 품절처리',
          componentOption: {
            tableComponentKey: 'tableProductDefault',
            extraCols: ['isRealSoldOut'],
          },
        }}
        onAPISuccess={() => {
          message.success('품절이 완료되었습니다.')
          if (refetchRef.current) {
            refetchRef.current()
          }
          if (statusFilterRefetchRef.current) {
            statusFilterRefetchRef.current()
          }
        }}
      />
    </>
  )
}

export default ButtonSoldOutProducts
