import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const SKU_QUERY_GQLS = {
  SKU: gql`
    query sku($id: ID!) {
      sku(id: $id) {
        createdAt
        updatedAt
        id
        seller {
          id
          companyName
          shopName
        }
        code
        name
        stockQuantity
        purchasePrice
        price
        discountPrice
        barcodeNumber
        sabangnetProductNumber
        productOptions {
          pageInfo {
            ...pageInfo
          }
          totalCount
          edges {
            cursor
            node {
              id
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  SKUS: gql`
    query skus($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      skus(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            createdAt
            updatedAt
            id
            seller {
              id
              companyName
              shopName
            }
            code
            name
            stockQuantity
            purchasePrice
            price
            discountPrice
            customerPrice
            barcodeNumber
            sabangnetProductNumber
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  SKU_HISTORY: gql`
    query skuHistory($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      skuHistory(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            id
            createdAt
            updatedAt
            beforeChanges
            afterChanges
            fitpetAdmin {
              email
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default SKU_QUERY_GQLS
