import { Row, Col } from 'antd'
import { FormInstance } from 'antd/es/form'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { MFormItemSelect, MFormItemWrapper } from 'components/formItems'
import MQuery, { queryParamsProps } from 'components/MQuery'
import { IModelCategory } from 'containers/models/modelCategory'
import { RootState } from '@libs/redux/store'
import { hasData } from '@libs/utils/utilData'

const makeSelectOption = (data: IModelCategory[] | undefined) => {
  if (!data) return null
  return data.map((x) => {
    return { value: x.id, title: x.name }
  })
}

const CategorySelect = ({
  form,
  setTrigger,
  depth,
  parentCategory,
}: {
  form: FormInstance
  setTrigger: Function
  depth: string
  parentCategory?: string
}) => {
  const queryParams: queryParamsProps = {
    gqlKey: 'categoriesForFilter',
    variables: { filter: { isRootCategory: depth === 'first', parentCategory } },
  }
  const category = form.getFieldValue('category')
  if (!category && depth !== 'first') return null
  return (
    <MQuery queryParams={queryParams} fetchPolicy="network-only">
      {({ data }) => {
        const selectOptions = makeSelectOption(data?.categories?.data)
        if (!selectOptions || !hasData(selectOptions)) return null
        return (
          <MFormItemSelect
            name={['category', depth]}
            selectOptions={selectOptions}
            getValueFromEvent={(value) => {
              setTrigger((prev: boolean) => !prev)
              // console.log(depth, value)
              if (depth === 'first') {
                form.setFieldsValue({
                  category: { ...category, second: undefined, third: undefined, forth: undefined },
                })
              } else if (depth === 'second') {
                form.setFieldsValue({ category: { ...category, third: undefined, forth: undefined } })
              } else if (depth === 'third') {
                form.setFieldsValue({ category: { ...category, forth: undefined } })
              }
              return value
            }}
          />
        )
      }}
    </MQuery>
  )
}

type MFormItemProductCategoriesProps = {
  form: FormInstance
  labelCol?: { span: number }
}

const MFormItemProductCategories = (props: MFormItemProductCategoriesProps) => {
  const { form, ...restProps } = props
  const initFormFilter = useSelector((state: RootState) => state.reduxUIReducers.initFormFilter)
  const [trigger, setTrigger] = useState<boolean>(false)
  useEffect(() => {
    // console.log('초기화!!')
    form.setFieldsValue({ category: { first: undefined, second: undefined, third: undefined, forth: undefined } })
    setTrigger(!trigger)
  }, [initFormFilter])

  const category = form.getFieldValue('category')
  const { first, second, third } = category

  return (
    <MFormItemWrapper label="카테고리" {...restProps}>
      <Row gutter={16}>
        <Col span={6}>
          <CategorySelect form={form} setTrigger={setTrigger} depth="first" />
        </Col>
        {first && (
          <Col span={6}>
            <CategorySelect form={form} setTrigger={setTrigger} depth="second" parentCategory={first} />
          </Col>
        )}
        {second && (
          <Col span={6}>
            <CategorySelect form={form} setTrigger={setTrigger} depth="third" parentCategory={second} />
          </Col>
        )}
        {third && (
          <Col span={6}>
            <CategorySelect form={form} setTrigger={setTrigger} depth="forth" parentCategory={third} />
          </Col>
        )}
      </Row>
    </MFormItemWrapper>
  )
}

export default MFormItemProductCategories
