import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const BILLING_QUERY_GQLS = {
  SELLER_BILLINGS_LIST: gql`
    query sellerBillingsList($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellerBillings(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...sellerBilling
            shippingBillingAmount
            seller {
              id
              shopName
            }
            brand {
              id
              name
            }
          }
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.sellerBilling}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default BILLING_QUERY_GQLS
