import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const PRODUCT_QNA_QUERY_GQLS = {
  PRODUCT_QNA_LIST: gql`
    query productQnaList($pageInfo: PageInfoInputType, $filter: GenericScalar, $seller: String, $orderBy: String) {
      productQnas(pageInfo: $pageInfo, filter: $filter, seller: $seller, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...productQna
            user {
              id
              name
              email
              mobileNumber
            }
            answeredUser {
              id
              name
              email
              isStaff
              sellers {
                edges {
                  node {
                    id
                    shopName
                  }
                }
              }
            }
            answeredUserType
            seller {
              id
              shopName
            }
            product {
              id
              name
              realName
              seller {
                id
                shopName
                chiefManager {
                  id
                  name
                }
              }
            }
          }
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productQna}
  `,
}

export default PRODUCT_QNA_QUERY_GQLS
