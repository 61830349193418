import { ITableColumn } from 'components/@atomics/MTable'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import tableCols from 'domains/admin/tableCols'
import { MTable } from 'components/@atomics'

const TableOrderItemReturn = ({ orderItems }: { orderItems: IModelOrderItem[] }) => {
  const tableColumns: Array<ITableColumn<IModelOrderItem>> = [
    tableCols.orderItem.productMainImage(),
    // tableCols.product.productMainImage({ dataIndex: ['productImage', 'thumbnailUrl'] }),
    tableCols.common.baseTextFromModel({ title: '브랜드', dataIndex: 'brand', key: 'name', width: 70 }),
    tableCols.common.baseText({ title: '상품명', dataIndex: ['productOption', 'product', 'name'] }),
    // TODO juyup product option 값 있을 때 데이터 변환해서 보여줄 것
    // tableCols.common.baseText({ title: '옵션', dataIndex: 'productOption', width: 50 }),
    tableCols.currency.currencyText({
      title: '총 상품금액(판매가)',
      dataIndex: 'productPromotionCustomerAmount',
      option: { displayCurrency: true },
    }),
    tableCols.common.baseText({ title: '수량', dataIndex: 'quantity', width: 50 }),
    tableCols.orderItem.returnStatus(),
  ]
  return <MTable columns={tableColumns} dataSource={orderItems} scroll={600} pagination={false} />
}

export default TableOrderItemReturn
