import { FunctionComponent } from 'react'
import { Space } from 'antd'
import { FormItemProps } from 'antd/lib/form'

import { MFormItemWrapper, IFilterOptions, MFormItemLabelProps } from 'components/formItems'
import { MSelect } from 'components/@atomics'
import { SelectCustomProps } from 'components/@atomics/MSelect'

export type MFormItemSelectProps = SelectCustomProps &
  MFormItemLabelProps & {
    selectOptions?: IFilterOptions
    selectMultipleOptions?: boolean
    placeholder?: string
    formItemProps?: FormItemProps
    disabled?: boolean
  }

const MFormItemSelect: FunctionComponent<MFormItemSelectProps> = ({
  label,
  name,
  selectOptions,
  selectMultipleOptions = false,
  selectWidth,
  required,
  placeholder,
  onChange,
  disabled,
  showSearch,
  rules,
  defaultValue,
  ...formItemProps
}) => {
  return (
    <MFormItemWrapper label={label} name={name} required={required} rules={rules} {...formItemProps}>
      {!selectMultipleOptions ? (
        <MSelect
          filterOptions={selectOptions}
          selectWidth={selectWidth}
          placeholder={placeholder}
          onSelect={onChange}
          disabled={disabled}
          showSearch={showSearch}
          defaultValue={defaultValue}
        />
      ) : (
        <Space size={16}>
          <MSelect
            filterOptions={selectOptions}
            selectWidth={selectWidth}
            placeholder={placeholder}
            onSelect={onChange}
            disabled={disabled}
          />
          <MSelect
            filterOptions={selectOptions}
            selectWidth={selectWidth}
            placeholder={placeholder}
            onSelect={onChange}
            disabled={disabled}
          />
          <MSelect
            filterOptions={selectOptions}
            selectWidth={selectWidth}
            placeholder={placeholder}
            onSelect={onChange}
            disabled={disabled}
          />
          <MSelect
            filterOptions={selectOptions}
            selectWidth={selectWidth}
            placeholder={placeholder}
            onSelect={onChange}
            disabled={disabled}
          />
        </Space>
      )}
    </MFormItemWrapper>
  )
}

export default MFormItemSelect
