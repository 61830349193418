import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const BRAND_MUTATION_GQLS = {
  CREATE_BRAND_BY_FITPET_ADMIN: gql`
    mutation createBrandByFitpetAdmin($input: BrandByFitpetAdminInput!) {
      createBrandByFitpetAdmin(input: $input) {
        brand {
          ...brand
          sellers {
            edges {
              node {
                id
                shopName
              }
            }
          }
          status {
            ...status
          }
          updateStatus {
            ...status
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.status}
  `,
  CREATE_BRAND_BY_SELLER: gql`
    mutation createBrandBySeller($input: BrandBySellerInput!) {
      createBrandBySeller(input: $input) {
        brand {
          ...brand
          sellers {
            edges {
              node {
                id
                shopName
              }
            }
          }
          status {
            ...status
          }
          updateStatus {
            ...status
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.status}
  `,
  UPDATE_BRAND_BY_FITPET_ADMIN: gql`
    mutation updateBrandByFitpetAdmin($id: ID!, $input: BrandByFitpetAdminInput!) {
      updateBrandByFitpetAdmin(id: $id, input: $input) {
        brand {
          ...brand
          sellers {
            edges {
              node {
                id
                shopName
              }
            }
          }
          status {
            ...status
          }
          updateStatus {
            ...status
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.status}
  `,
  UPDATE_BRAND_BY_SELLER: gql`
    mutation updateBrandBySeller($id: ID!, $input: BrandBySellerInput!) {
      updateBrandBySeller(id: $id, input: $input) {
        brand {
          ...brand
          sellers {
            edges {
              node {
                id
                shopName
              }
            }
          }
          status {
            ...status
          }
          updateStatus {
            ...status
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.status}
  `,
}

export default BRAND_MUTATION_GQLS
