import { Key } from 'antd/lib/table/interface'
import React, { useEffect, useState } from 'react'
import { MFormTableEditableCell, MFormTableEditableRow } from 'components/@atomics/forms/MFormTableEditableRowAndCell'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import { deepcopy } from '@libs/utils/utilData'
import { FIELD_KEYS } from '@constants/constData'

export type MTableEditableProps = {
  rowKey: string
  columns: any[]
  dataSource: any
  onDataSourceUpdated: (newDateSource: any) => void
  onRowSelect?: Function
  hideSelectAll?: boolean
  rowSelectionType?: 'checkbox' | 'radio' | undefined
} & TableProps<any>

const MTableEditable: React.FC<MTableEditableProps> = ({
  columns,
  dataSource,
  onDataSourceUpdated,
  rowKey = FIELD_KEYS.FIELD_SERIES,
  // rowSelectionFilter,
  onRowSelect,
  // hideSelectAll,
  rowSelectionType,
  ...tableProps
}) => {
  const [rowKeys, setRowKeys] = useState<Key[]>()
  useEffect((): void => {
    if (rowKeys) {
      const filteredSelectedRows = dataSource.filter((data: any) => rowKeys.includes(data.id))
      if (onRowSelect) {
        onRowSelect(filteredSelectedRows)
      }
    }
  }, [rowKeys])

  const components = {
    body: {
      row: MFormTableEditableRow,
      cell: MFormTableEditableCell,
    },
  }

  const _handleSave = (row: any) => {
    const newData = deepcopy(dataSource)
    const index = newData.findIndex((item: any) => row[rowKey] === item[rowKey])
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    onDataSourceUpdated(newData)
  }

  const _handleSort = (currentSeries: number, targetSeries: number) => {
    const newData = deepcopy(dataSource)
    const sortingRowIdx = newData.findIndex((item: any) => item[rowKey] === currentSeries)
    const targetRowIdx = newData.findIndex((item: any) => item[rowKey] === targetSeries)

    // 순서가 처음이거나 마지막일 경우는 처리하지 않음
    if (targetRowIdx === -1) {
      return
    }

    const sortingRow = newData[sortingRowIdx]
    const targetRow = newData[targetRowIdx]

    newData[sortingRowIdx] = {
      ...targetRow,
      [rowKey]: currentSeries,
    }

    newData[targetRowIdx] = {
      ...sortingRow,
      [rowKey]: targetSeries,
    }

    onDataSourceUpdated(newData)
  }

  const _columns = columns.map((col) => {
    if (col.editable) {
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          inputType: col.inputType,
          handleSave: _handleSave,
        }),
      }
    }
    if (col.sortable) {
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          sortable: col.sortable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSort: _handleSort,
        }),
      }
    }
    return col
  })

  return (
    <Table
      rowSelection={{
        type: rowSelectionType,
        selectedRowKeys: rowKeys,
        onChange: (selectedRowKeys: Key[]) => {
          setRowKeys(selectedRowKeys)
        },
      }}
      components={components}
      rowKey={rowKey}
      rowClassName={() => 'editable-row'}
      bordered
      dataSource={dataSource}
      columns={_columns}
      {...tableProps}
    />
  )
}

export default MTableEditable
