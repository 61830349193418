import { ModelTotalSalesStatisticDefault } from 'containers/models/base/defaultModels'
import { IModelTotalSalesStatisticDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelTotalSalesStatistic extends IModelTotalSalesStatisticDefault {}

export default class ModelTotalSalesStatistic extends ModelTotalSalesStatisticDefault {
  constructor(data: IModelTotalSalesStatistic) {
    super(data)
  }
}
