import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const DEFAULT_MUTATION_GQLS = {
  CREATE_CART_ITEM: gql`
    mutation createCartItemDefault($input: CreateCartItemInput!) {
      createCartItem(input: $input) {
        cartItem {
          ...cartItem
          productOption {
            ...productOption
          }
          user {
            ...user
          }
          productPromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItem}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CREATE_CART_ITEMS: gql`
    mutation createCartItemsDefault($input: CreateCartItemsInput!) {
      createCartItems(input: $input) {
        cartItems {
          ...cartItem
          productOption {
            ...productOption
          }
          user {
            ...user
          }
          productPromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItem}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_CART_ITEMS: gql`
    mutation deleteCartItemsDefault($input: DeleteCartItemsInput!) {
      deleteCartItems(input: $input) {
        count
      }
    }
  `,
  UPDATE_CART_ITEM: gql`
    mutation updateCartItemDefault($id: ID!, $input: UpdateCartItemInput!) {
      updateCartItem(id: $id, input: $input) {
        cartItem {
          ...cartItem
          productOption {
            ...productOption
          }
          user {
            ...user
          }
          productPromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItem}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.user}
  `,
  REFRESH_CART_ITEMS: gql`
    mutation refreshCartItemsDefault($id: ID!) {
      refreshCartItems(id: $id) {
        cartItems {
          ...cartItem
          productOption {
            ...productOption
          }
          user {
            ...user
          }
          productPromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItem}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CREATE_CUSTOMER_GRADE: gql`
    mutation createCustomerGradeDefault($input: CustomerGradeInput!) {
      createCustomerGrade(input: $input) {
        customerGrade {
          ...customerGrade
          couponGroups {
            ...couponGroupConnection
          }
          fitpetAdmin {
            ...user
          }
          gradeUsers {
            ...userConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  UPDATE_CUSTOMER_GRADE: gql`
    mutation updateCustomerGradeDefault($id: ID!, $input: CustomerGradeInput!) {
      updateCustomerGrade(id: $id, input: $input) {
        customerGrade {
          ...customerGrade
          couponGroups {
            ...couponGroupConnection
          }
          fitpetAdmin {
            ...user
          }
          gradeUsers {
            ...userConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  DELETE_CUSTOMER_GRADE: gql`
    mutation deleteCustomerGradeDefault($id: ID!) {
      deleteCustomerGrade(id: $id) {
        customerGrade {
          ...customerGrade
          couponGroups {
            ...couponGroupConnection
          }
          fitpetAdmin {
            ...user
          }
          gradeUsers {
            ...userConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  ACTIVATE_CUSTOMER_GRADE: gql`
    mutation activateCustomerGradeDefault($id: ID!, $input: ActivateCustomerGradeInput!) {
      activateCustomerGrade(id: $id, input: $input) {
        customerGrade {
          ...customerGrade
          couponGroups {
            ...couponGroupConnection
          }
          fitpetAdmin {
            ...user
          }
          gradeUsers {
            ...userConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CREATE_NOTICE: gql`
    mutation createNoticeDefault($input: NoticeInput!) {
      createNotice(input: $input) {
        notice {
          ...notice
          user {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.notice}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_NOTICE: gql`
    mutation updateNoticeDefault($id: ID!, $input: NoticeInput!) {
      updateNotice(id: $id, input: $input) {
        notice {
          ...notice
          user {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.notice}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_NOTICES: gql`
    mutation deleteNoticesDefault($input: ModelDeletesInput!) {
      deleteNotices(input: $input) {
        count
      }
    }
  `,
  CREATE_FAQ: gql`
    mutation createFaqDefault($input: FaqInput!) {
      createFaq(input: $input) {
        faq {
          ...faq
          user {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.faq}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_FAQ: gql`
    mutation updateFaqDefault($id: ID!, $input: FaqInput!) {
      updateFaq(id: $id, input: $input) {
        faq {
          ...faq
          user {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.faq}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_FAQS: gql`
    mutation deleteFaqsDefault($input: ModelDeletesInput!) {
      deleteFaqs(input: $input) {
        count
      }
    }
  `,
  CREATE_COUPON_GROUP: gql`
    mutation createCouponGroupDefault($input: CouponGroupInput!) {
      createCouponGroup(input: $input) {
        couponGroup {
          ...couponGroup
          fitpetAdmin {
            ...user
          }
          ad {
            ...ad
          }
          includedBrands {
            ...brandConnection
          }
          includedProducts {
            ...productConnection
          }
          includedCategories {
            ...categoryConnection
          }
          excludedBrands {
            ...brandConnection
          }
          excludedProducts {
            ...productConnection
          }
          excludedCategories {
            ...categoryConnection
          }
          informkakaoqueue {
            ...informKakaoQueue
          }
          couponGroupCoupons {
            ...couponConnection
          }
          couponGroupCustomerGrades {
            ...customerGradeConnection
          }
          informKakaoQueue {
            ...informKakaoQueue
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informKakaoQueue}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_COUPON_GROUP: gql`
    mutation updateCouponGroupDefault($id: ID!, $input: CouponGroupInput!) {
      updateCouponGroup(id: $id, input: $input) {
        couponGroup {
          ...couponGroup
          fitpetAdmin {
            ...user
          }
          ad {
            ...ad
          }
          includedBrands {
            ...brandConnection
          }
          includedProducts {
            ...productConnection
          }
          includedCategories {
            ...categoryConnection
          }
          excludedBrands {
            ...brandConnection
          }
          excludedProducts {
            ...productConnection
          }
          excludedCategories {
            ...categoryConnection
          }
          informkakaoqueue {
            ...informKakaoQueue
          }
          couponGroupCoupons {
            ...couponConnection
          }
          couponGroupCustomerGrades {
            ...customerGradeConnection
          }
          informKakaoQueue {
            ...informKakaoQueue
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informKakaoQueue}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_COUPON_GROUP_STATUS: gql`
    mutation updateCouponGroupStatusDefault($id: ID!, $input: UpdateCouponGroupStatusInput!) {
      updateCouponGroupStatus(id: $id, input: $input) {
        couponGroup {
          ...couponGroup
          fitpetAdmin {
            ...user
          }
          ad {
            ...ad
          }
          includedBrands {
            ...brandConnection
          }
          includedProducts {
            ...productConnection
          }
          includedCategories {
            ...categoryConnection
          }
          excludedBrands {
            ...brandConnection
          }
          excludedProducts {
            ...productConnection
          }
          excludedCategories {
            ...categoryConnection
          }
          informkakaoqueue {
            ...informKakaoQueue
          }
          couponGroupCoupons {
            ...couponConnection
          }
          couponGroupCustomerGrades {
            ...customerGradeConnection
          }
          informKakaoQueue {
            ...informKakaoQueue
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informKakaoQueue}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_COUPON_GROUP: gql`
    mutation deleteCouponGroupDefault($id: ID!) {
      deleteCouponGroup(id: $id) {
        couponGroup {
          ...couponGroup
          fitpetAdmin {
            ...user
          }
          ad {
            ...ad
          }
          includedBrands {
            ...brandConnection
          }
          includedProducts {
            ...productConnection
          }
          includedCategories {
            ...categoryConnection
          }
          excludedBrands {
            ...brandConnection
          }
          excludedProducts {
            ...productConnection
          }
          excludedCategories {
            ...categoryConnection
          }
          informkakaoqueue {
            ...informKakaoQueue
          }
          couponGroupCoupons {
            ...couponConnection
          }
          couponGroupCustomerGrades {
            ...customerGradeConnection
          }
          informKakaoQueue {
            ...informKakaoQueue
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informKakaoQueue}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_COUPON: gql`
    mutation deleteCouponDefault($id: ID!) {
      deleteCoupon(id: $id) {
        coupon {
          ...coupon
          couponGroup {
            ...couponGroup
          }
          user {
            ...user
          }
          fitpetAdmin {
            ...user
          }
          order {
            ...order
          }
          orderItem {
            ...orderItem
          }
          shipping {
            ...shipping
          }
          usedOrderItem {
            ...orderItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.coupon}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_COUPONS: gql`
    mutation deleteCouponsDefault($input: ModelDeletesInput!) {
      deleteCoupons(input: $input) {
        count
      }
    }
  `,
  DELETE_COUPONS_BY_GROUP: gql`
    mutation deleteCouponsByGroupDefault($id: ID!) {
      deleteCouponsByGroup(id: $id) {
        couponGroup {
          ...couponGroup
          fitpetAdmin {
            ...user
          }
          ad {
            ...ad
          }
          includedBrands {
            ...brandConnection
          }
          includedProducts {
            ...productConnection
          }
          includedCategories {
            ...categoryConnection
          }
          excludedBrands {
            ...brandConnection
          }
          excludedProducts {
            ...productConnection
          }
          excludedCategories {
            ...categoryConnection
          }
          informkakaoqueue {
            ...informKakaoQueue
          }
          couponGroupCoupons {
            ...couponConnection
          }
          couponGroupCustomerGrades {
            ...customerGradeConnection
          }
          informKakaoQueue {
            ...informKakaoQueue
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informKakaoQueue}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  REQUEST_COUPON_EXCEL_FILE: gql`
    mutation requestCouponExcelFileDefault($input: RequestExcelFileInput!) {
      requestCouponExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  ISSUE_MANUAL_COUPONS: gql`
    mutation issueManualCouponsDefault($input: IssueManualCouponInput!) {
      issueManualCoupons(input: $input) {
        coupons {
          ...coupon
          couponGroup {
            ...couponGroup
          }
          user {
            ...user
          }
          fitpetAdmin {
            ...user
          }
          order {
            ...order
          }
          orderItem {
            ...orderItem
          }
          shipping {
            ...shipping
          }
          usedOrderItem {
            ...orderItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.coupon}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ISSUE_MANUAL_COUPONS_BY_EXCEL: gql`
    mutation issueManualCouponsByExcelDefault($input: IssueManualCouponsByExcelInput!) {
      issueManualCouponsByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  ISSUE_COUPON_CODE: gql`
    mutation issueCouponCodeDefault($input: IssueCouponCodeInput!) {
      issueCouponCode(input: $input) {
        coupon {
          ...coupon
          couponGroup {
            ...couponGroup
          }
          user {
            ...user
          }
          fitpetAdmin {
            ...user
          }
          order {
            ...order
          }
          orderItem {
            ...orderItem
          }
          shipping {
            ...shipping
          }
          usedOrderItem {
            ...orderItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.coupon}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.user}
  `,
  REGISTER_COUPON: gql`
    mutation registerCouponDefault($input: RegisterCouponInput!) {
      registerCoupon(input: $input) {
        coupon {
          ...coupon
          couponGroup {
            ...couponGroup
          }
          user {
            ...user
          }
          fitpetAdmin {
            ...user
          }
          order {
            ...order
          }
          orderItem {
            ...orderItem
          }
          shipping {
            ...shipping
          }
          usedOrderItem {
            ...orderItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.coupon}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DOWNLOAD_EVENT_COUPONS: gql`
    mutation downloadEventCouponsDefault($input: DownloadEventCouponsInput!) {
      downloadEventCoupons(input: $input) {
        coupons {
          ...coupon
          couponGroup {
            ...couponGroup
          }
          user {
            ...user
          }
          fitpetAdmin {
            ...user
          }
          order {
            ...order
          }
          orderItem {
            ...orderItem
          }
          shipping {
            ...shipping
          }
          usedOrderItem {
            ...orderItem
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.coupon}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CREATE_SLIM_BANNER: gql`
    mutation createSlimBannerDefault($input: BannerInput!) {
      createSlimBanner(input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  CREATE_TOP_ROLL_BANNER: gql`
    mutation createTopRollBannerDefault($input: BannerInput!) {
      createTopRollBanner(input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  CREATE_HOME_MIDDLE_BANNER: gql`
    mutation createHomeMiddleBannerDefault($input: BannerInput!) {
      createHomeMiddleBanner(input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  UPDATE_HOME_MIDDLE_BANNER: gql`
    mutation updateHomeMiddleBannerDefault($id: ID!, $input: BannerInput!) {
      updateHomeMiddleBanner(id: $id, input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  CREATE_HOME_BOTTOM_BANNER: gql`
    mutation createHomeBottomBannerDefault($input: BannerInput!) {
      createHomeBottomBanner(input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  UPDATE_HOME_BOTTOM_BANNER: gql`
    mutation updateHomeBottomBannerDefault($id: ID!, $input: BannerInput!) {
      updateHomeBottomBanner(id: $id, input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  CREATE_PRODUCT_TOP_BANNER: gql`
    mutation createProductTopBannerDefault($input: BannerInput!) {
      createProductTopBanner(input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  UPDATE_PRODUCT_TOP_BANNER: gql`
    mutation updateProductTopBannerDefault($id: ID!, $input: BannerInput!) {
      updateProductTopBanner(id: $id, input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  UPDATE_DISPLAY_ORDER_FOR_BANNER: gql`
    mutation updateDisplayOrderForBannerDefault($id: ID!, $input: UpdateDisplayOrderInput!) {
      updateDisplayOrderForBanner(id: $id, input: $input) {
        id
        displayOrder
      }
    }
  `,
  CREATE_MULTI_LINE_BANNER: gql`
    mutation createHomeMultiLineBannerDefault($input: BannerInput!) {
      createHomeMultiLineBanner(input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  UPDATE_MULTI_LINE_BANNER: gql`
    mutation updateHomeMultiLineBannerDefault($id: ID!, $input: BannerInput!) {
      updateHomeMultiLineBanner(id: $id, input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  UPDATE_SLIM_BANNER: gql`
    mutation updateSlimBannerDefault($id: ID!, $input: BannerInput!) {
      updateSlimBanner(id: $id, input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  UPDATE_TOP_ROLL_BANNER: gql`
    mutation updateTopRollBannerDefault($id: ID!, $input: BannerInput!) {
      updateTopRollBanner(id: $id, input: $input) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  DELETE_BANNERS: gql`
    mutation deleteBannersDefault($input: DeleteBannersInput!) {
      deleteBanners(input: $input) {
        count
      }
    }
  `,
  ALLOW_BANNER: gql`
    mutation allowBannerDefault($id: ID!) {
      allowBanner(id: $id) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  DISALLOW_BANNER: gql`
    mutation disallowBannerDefault($id: ID!) {
      disallowBanner(id: $id) {
        banner {
          ...banner
          bannerImages {
            ...bannerImageConnection
          }
          topSlimBannerInfo {
            ...slimBannerInfo
          }
          slimBannerInfo {
            ...slimBannerInfo
          }
          popupBannerInfo {
            ...popupBannerInfo
          }
          topRollBannerInfo {
            ...topRollBannerInfo
          }
          designersBannerInfo {
            ...designersBannerInfo
          }
          weeklyBestBannerInfo {
            ...weeklyBestBannerInfo
          }
          coverflowBannerInfo {
            ...coverflowBannerInfo
          }
          brandRollBannerInfo {
            ...subRollBannerInfo
          }
          apparelCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          apparelRollBannerInfo {
            ...subRollBannerInfo
          }
          accessoriesCoverflowBannerInfo {
            ...coverflowBannerInfo
          }
          accessoriesRollBannerInfo {
            ...subRollBannerInfo
          }
          homeMiddleBannerInfo {
            ...bannerInfo
          }
          homeBottomBannerInfo {
            ...bannerInfo
          }
          productTopBannerInfo {
            ...bannerInfo
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  CREATE_AD: gql`
    mutation createAdDefault($input: CreateAdInput!) {
      createAd(input: $input) {
        ad {
          ...ad
          adCouponGroups {
            ...couponGroupConnection
          }
          adImages {
            ...adImageConnection
          }
          adDisplayCollections {
            ...displayCollectionConnection
          }
          mainImage {
            ...adImage
          }
          couponGroups {
            ...couponGroupConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.adImage}
    ${DEFAULT_FRAGMENTS.adImageConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayCollectionConnection}
  `,
  UPDATE_AD: gql`
    mutation updateAdDefault($id: ID!, $input: UpdateAdInput!) {
      updateAd(id: $id, input: $input) {
        ad {
          ...ad
          adCouponGroups {
            ...couponGroupConnection
          }
          adImages {
            ...adImageConnection
          }
          adDisplayCollections {
            ...displayCollectionConnection
          }
          mainImage {
            ...adImage
          }
          couponGroups {
            ...couponGroupConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.adImage}
    ${DEFAULT_FRAGMENTS.adImageConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayCollectionConnection}
  `,
  FINISH_AD: gql`
    mutation finishAdDefault($id: ID!) {
      finishAd(id: $id) {
        ad {
          ...ad
          adCouponGroups {
            ...couponGroupConnection
          }
          adImages {
            ...adImageConnection
          }
          adDisplayCollections {
            ...displayCollectionConnection
          }
          mainImage {
            ...adImage
          }
          couponGroups {
            ...couponGroupConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.adImage}
    ${DEFAULT_FRAGMENTS.adImageConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayCollectionConnection}
  `,
  DELETE_AD: gql`
    mutation deleteAdDefault($id: ID!) {
      deleteAd(id: $id) {
        ad {
          ...ad
          adCouponGroups {
            ...couponGroupConnection
          }
          adImages {
            ...adImageConnection
          }
          adDisplayCollections {
            ...displayCollectionConnection
          }
          mainImage {
            ...adImage
          }
          couponGroups {
            ...couponGroupConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.adImage}
    ${DEFAULT_FRAGMENTS.adImageConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayCollectionConnection}
  `,
  DELETE_ADS: gql`
    mutation deleteAdsDefault($input: ModelDeletesInput!) {
      deleteAds(input: $input) {
        count
      }
    }
  `,
  CREATE_DISPLAY_COLLECTION: gql`
    mutation createDisplayCollectionDefault($input: CreateDisplayCollectionInput!) {
      createDisplayCollection(input: $input) {
        displayCollection {
          ...displayCollection
          ad {
            ...ad
          }
          displayCollectionDisplayProductInfos {
            ...displayProductInfoConnection
          }
          displayProductInfos {
            ...displayProductInfoConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
  `,
  UPDATE_DISPLAY_COLLECTION: gql`
    mutation updateDisplayCollectionDefault($id: ID!, $input: UpdateDisplayCollectionInput!) {
      updateDisplayCollection(id: $id, input: $input) {
        displayCollection {
          ...displayCollection
          ad {
            ...ad
          }
          displayCollectionDisplayProductInfos {
            ...displayProductInfoConnection
          }
          displayProductInfos {
            ...displayProductInfoConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
  `,
  UPDATE_DATE_OF_DISPLAY_COLLECTION: gql`
    mutation updateDateOfDisplayCollectionDefault($id: ID!, $input: UpdateDisplayCollectionInput!) {
      updateDateOfDisplayCollection(id: $id, input: $input) {
        displayCollection {
          ...displayCollection
          ad {
            ...ad
          }
          displayCollectionDisplayProductInfos {
            ...displayProductInfoConnection
          }
          displayProductInfos {
            ...displayProductInfoConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
  `,
  DELETE_DISPLAY_COLLECTION: gql`
    mutation deleteDisplayCollectionDefault($id: ID!) {
      deleteDisplayCollection(id: $id) {
        displayCollection {
          ...displayCollection
          ad {
            ...ad
          }
          displayCollectionDisplayProductInfos {
            ...displayProductInfoConnection
          }
          displayProductInfos {
            ...displayProductInfoConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
  `,
  DELETE_DISPLAY_COLLECTIONS: gql`
    mutation deleteDisplayCollectionsDefault($input: ModelDeletesInput!) {
      deleteDisplayCollections(input: $input) {
        count
      }
    }
  `,
  CREATE_DISPLAY_PRODUCT_INFO: gql`
    mutation createDisplayProductInfoDefault($input: CreateDisplayProductInfoInput!) {
      createDisplayProductInfo(input: $input) {
        displayProductInfo {
          ...displayProductInfo
          product {
            ...product
          }
          displayCollection {
            ...displayCollection
          }
          productpromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  CREATE_DISPLAY_PRODUCT_INFOS: gql`
    mutation createDisplayProductInfosDefault($input: CreateDisplayProductInfosInput!) {
      createDisplayProductInfos(input: $input) {
        count
        displayProductInfo {
          ...displayProductInfo
          product {
            ...product
          }
          displayCollection {
            ...displayCollection
          }
          productpromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  CREATE_DISPLAY_PRODUCT_INFOS_WITH_PRODUCT_PROMOTIONS: gql`
    mutation createDisplayProductInfosWithProductPromotionsDefault(
      $input: CreateDisplayProductInfosWithProductPromotionsInput!
    ) {
      createDisplayProductInfosWithProductPromotions(input: $input) {
        productPromotions {
          ...productPromotion
          product {
            ...product
          }
          displayProductInfo {
            ...displayProductInfo
          }
          productPromotionOrderItems {
            ...orderItemConnection
          }
          productPromotionCartItems {
            ...cartItemConnection
          }
          productPromotionCartItemHistories {
            ...cartItemHistoryConnection
          }
        }
        displayProductInfos {
          ...displayProductInfo
          product {
            ...product
          }
          displayCollection {
            ...displayCollection
          }
          productpromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  UPDATE_DISPLAY_PRODUCT_INFO: gql`
    mutation updateDisplayProductInfoDefault($id: ID!, $input: UpdateDisplayProductInfoInput!) {
      updateDisplayProductInfo(id: $id, input: $input) {
        displayProductInfo {
          ...displayProductInfo
          product {
            ...product
          }
          displayCollection {
            ...displayCollection
          }
          productpromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  DELETE_DISPLAY_PRODUCT_INFO: gql`
    mutation deleteDisplayProductInfoDefault($id: ID!) {
      deleteDisplayProductInfo(id: $id) {
        displayProductInfo {
          ...displayProductInfo
          product {
            ...product
          }
          displayCollection {
            ...displayCollection
          }
          productpromotion {
            ...productPromotion
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  DELETE_DISPLAY_PRODUCT_INFOS: gql`
    mutation deleteDisplayProductInfosDefault($input: ModelDeletesInput!) {
      deleteDisplayProductInfos(input: $input) {
        count
      }
    }
  `,
  CREATE_PRODUCT_PROMOTION: gql`
    mutation createProductPromotionDefault($input: CreateProductPromotionInput!) {
      createProductPromotion(input: $input) {
        productPromotion {
          ...productPromotion
          product {
            ...product
          }
          displayProductInfo {
            ...displayProductInfo
          }
          productPromotionOrderItems {
            ...orderItemConnection
          }
          productPromotionCartItems {
            ...cartItemConnection
          }
          productPromotionCartItemHistories {
            ...cartItemHistoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  UPDATE_PRODUCT_PROMOTION: gql`
    mutation updateProductPromotionDefault($id: ID!, $input: UpdateProductPromotionInput!) {
      updateProductPromotion(id: $id, input: $input) {
        productPromotion {
          ...productPromotion
          product {
            ...product
          }
          displayProductInfo {
            ...displayProductInfo
          }
          productPromotionOrderItems {
            ...orderItemConnection
          }
          productPromotionCartItems {
            ...cartItemConnection
          }
          productPromotionCartItemHistories {
            ...cartItemHistoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  UPDATE_PRODUCT_PROMOTIONS: gql`
    mutation updateProductPromotionsDefault($input: UpdateProductPromotionsInput!) {
      updateProductPromotions(input: $input) {
        count
      }
    }
  `,
  DELETE_PRODUCT_PROMOTION: gql`
    mutation deleteProductPromotionDefault($id: ID!) {
      deleteProductPromotion(id: $id) {
        productPromotion {
          ...productPromotion
          product {
            ...product
          }
          displayProductInfo {
            ...displayProductInfo
          }
          productPromotionOrderItems {
            ...orderItemConnection
          }
          productPromotionCartItems {
            ...cartItemConnection
          }
          productPromotionCartItemHistories {
            ...cartItemHistoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  CREATE_PRODUCT_PROMOTIONS_BY_EXCEL: gql`
    mutation createProductPromotionsByExcelDefault($input: CreateProductPromotionsByExcelInput!) {
      createProductPromotionsByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  REQUEST_DISPLAY_PRODUCT_PROMOTION_EXCEL_FILE: gql`
    mutation requestDisplayProductPromotionExcelFileDefault($input: RequestExcelFileInput!) {
      requestDisplayProductPromotionExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  CONFIRM_SELLER_BILLING: gql`
    mutation confirmSellerBillingDefault($id: ID!, $input: ConfirmBillingInput!) {
      confirmSellerBilling(id: $id, input: $input) {
        sellerBilling {
          ...sellerBilling
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          sellerBillingItems {
            ...billingItemConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBilling}
  `,
  REQUEST_ADMIN_BILLING_ITEM_EXCEL_FILE: gql`
    mutation requestAdminBillingItemExcelFileDefault($input: RequestExcelFileInput!) {
      requestAdminBillingItemExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  REQUEST_SELLER_BILLING_ITEM_EXCEL_FILE: gql`
    mutation requestSellerBillingItemExcelFileDefault($input: RequestExcelFileInput!) {
      requestSellerBillingItemExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  CREATE_SHIPPING_COMPANY: gql`
    mutation createShippingCompanyDefault($input: ShippingCompanyInput!) {
      createShippingCompany(input: $input) {
        shippingCompany {
          ...shippingCompany
          shippingCompanyShippings {
            ...shippingConnection
          }
          shippingCompanyPresets {
            ...shippingPresetConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
  `,
  UPDATE_SHIPPING_COMPANY: gql`
    mutation updateShippingCompanyDefault($id: ID!, $input: ShippingCompanyInput!) {
      updateShippingCompany(id: $id, input: $input) {
        shippingCompany {
          ...shippingCompany
          shippingCompanyShippings {
            ...shippingConnection
          }
          shippingCompanyPresets {
            ...shippingPresetConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
  `,
  DELETE_SHIPPING_COMPANY: gql`
    mutation deleteShippingCompanyDefault($id: ID!) {
      deleteShippingCompany(id: $id) {
        shippingCompany {
          ...shippingCompany
          shippingCompanyShippings {
            ...shippingConnection
          }
          shippingCompanyPresets {
            ...shippingPresetConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
  `,
  CREATE_SHIPPING_PRESET: gql`
    mutation createShippingPresetDefault($input: CreateShippingPresetInput!) {
      createShippingPreset(input: $input) {
        shippingPreset {
          ...shippingPreset
          seller {
            ...seller
          }
          shippingCompany {
            ...shippingCompany
          }
          warehouseAddress {
            ...sellerAddress
          }
          returnAddress {
            ...sellerAddress
          }
          shippingPresetProducts {
            ...productConnection
          }
          shippingPresetOrderItems {
            ...shippingConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  UPDATE_SHIPPING_PRESET: gql`
    mutation updateShippingPresetDefault($id: ID!, $input: UpdateShippingPresetInput!) {
      updateShippingPreset(id: $id, input: $input) {
        shippingPreset {
          ...shippingPreset
          seller {
            ...seller
          }
          shippingCompany {
            ...shippingCompany
          }
          warehouseAddress {
            ...sellerAddress
          }
          returnAddress {
            ...sellerAddress
          }
          shippingPresetProducts {
            ...productConnection
          }
          shippingPresetOrderItems {
            ...shippingConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  DELETE_SHIPPING_PRESET: gql`
    mutation deleteShippingPresetDefault($id: ID!) {
      deleteShippingPreset(id: $id) {
        shippingPreset {
          ...shippingPreset
          seller {
            ...seller
          }
          shippingCompany {
            ...shippingCompany
          }
          warehouseAddress {
            ...sellerAddress
          }
          returnAddress {
            ...sellerAddress
          }
          shippingPresetProducts {
            ...productConnection
          }
          shippingPresetOrderItems {
            ...shippingConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  START_SHIPPINGS: gql`
    mutation startShippingsDefault($input: ShippingsInput!) {
      startShippings(input: $input) {
        shippings {
          ...shipping
          shippingPreset {
            ...shippingPreset
          }
          shippingCompany {
            ...shippingCompany
          }
          shippingMileageHistories {
            ...mileageHistoryConnection
          }
          shippingCoupons {
            ...couponConnection
          }
          shippingOrderItems {
            ...orderItemConnection
          }
          shippingReturnInfos {
            ...returnInfoConnection
          }
          shippingRefundShippingInfos {
            ...refundShippingInfoConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
          availableCoupons {
            ...couponConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.refundShippingInfoConnection}
    ${DEFAULT_FRAGMENTS.returnInfoConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  UPDATE_SHIPPINGS: gql`
    mutation updateShippingsDefault($input: ShippingsInput!) {
      updateShippings(input: $input) {
        shippings {
          ...shipping
          shippingPreset {
            ...shippingPreset
          }
          shippingCompany {
            ...shippingCompany
          }
          shippingMileageHistories {
            ...mileageHistoryConnection
          }
          shippingCoupons {
            ...couponConnection
          }
          shippingOrderItems {
            ...orderItemConnection
          }
          shippingReturnInfos {
            ...returnInfoConnection
          }
          shippingRefundShippingInfos {
            ...refundShippingInfoConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
          availableCoupons {
            ...couponConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.refundShippingInfoConnection}
    ${DEFAULT_FRAGMENTS.returnInfoConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  ESTIMATE_ORDER: gql`
    mutation estimateOrderDefault($input: EstimateOrderInput!) {
      estimateOrder(input: $input) {
        order {
          ...order
          user {
            ...user
          }
          device {
            ...device
          }
          customerAddress {
            ...customerAddress
          }
          approvedTransaction {
            ...transaction
          }
          orderMileageHistories {
            ...mileageHistoryConnection
          }
          orderCoupons {
            ...couponConnection
          }
          orderOrderItems {
            ...orderItemConnection
          }
          orderTransactions {
            ...transactionConnection
          }
          orderCancelTransactions {
            ...cancelTransactionConnection
          }
          orderRefundInfo {
            ...orderRefundInfo
          }
          shippings {
            ...shippingConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.orderRefundInfo}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CREATE_ORDER: gql`
    mutation createOrderDefault($input: OrderInput!) {
      createOrder(input: $input) {
        order {
          ...order
          user {
            ...user
          }
          device {
            ...device
          }
          customerAddress {
            ...customerAddress
          }
          approvedTransaction {
            ...transaction
          }
          orderMileageHistories {
            ...mileageHistoryConnection
          }
          orderCoupons {
            ...couponConnection
          }
          orderOrderItems {
            ...orderItemConnection
          }
          orderTransactions {
            ...transactionConnection
          }
          orderCancelTransactions {
            ...cancelTransactionConnection
          }
          orderRefundInfo {
            ...orderRefundInfo
          }
          shippings {
            ...shippingConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.orderRefundInfo}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_ORDER_CUSTOMER_ADDRESS: gql`
    mutation updateOrderCustomerAddressDefault($id: ID!, $input: UpdateOrderCustomerAddressInput!) {
      updateOrderCustomerAddress(id: $id, input: $input) {
        order {
          ...order
          user {
            ...user
          }
          device {
            ...device
          }
          customerAddress {
            ...customerAddress
          }
          approvedTransaction {
            ...transaction
          }
          orderMileageHistories {
            ...mileageHistoryConnection
          }
          orderCoupons {
            ...couponConnection
          }
          orderOrderItems {
            ...orderItemConnection
          }
          orderTransactions {
            ...transactionConnection
          }
          orderCancelTransactions {
            ...cancelTransactionConnection
          }
          orderRefundInfo {
            ...orderRefundInfo
          }
          shippings {
            ...shippingConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.orderRefundInfo}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CHECK_ORDER_ITEMS: gql`
    mutation checkOrderItemsDefault($input: OrderItemsInput!) {
      checkOrderItems(input: $input) {
        orderItems {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  CONFIRM_ORDER_ITEM: gql`
    mutation confirmOrderItemDefault($id: ID!) {
      confirmOrderItem(id: $id) {
        orderItem {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  CANCEL_ORDER_ITEMS: gql`
    mutation cancelOrderItemsDefault($input: CancelOrderItemsInput!) {
      cancelOrderItems(input: $input) {
        orderItems {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  CONFIRM_CANCEL_ORDER_ITEM: gql`
    mutation confirmCancelOrderItemDefault($id: ID!) {
      confirmCancelOrderItem(id: $id) {
        orderItem {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  REQUEST_RETURN_ORDER_ITEMS: gql`
    mutation requestReturnOrderItemsDefault($input: RequestReturnOrderItemsInput!) {
      requestReturnOrderItems(input: $input) {
        orderItems {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  ACCEPT_RETURN_INFOS: gql`
    mutation acceptReturnInfosDefault($input: ReturnInfosInput!) {
      acceptReturnInfos(input: $input) {
        returnInfos {
          ...returnInfo
          customerAddress {
            ...customerAddress
          }
          shipping {
            ...shipping
          }
          returnInfoMileageHistories {
            ...mileageHistoryConnection
          }
          returnInfoOrderItems {
            ...orderItemConnection
          }
          returnInfoReturnInfoImages {
            ...returnInfoImageConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.returnInfoImageConnection}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  CONFIRM_RETURN_INFOS: gql`
    mutation confirmReturnInfosDefault($input: ConfirmReturnInfosInput!) {
      confirmReturnInfos(input: $input) {
        returnInfos {
          ...returnInfo
          customerAddress {
            ...customerAddress
          }
          shipping {
            ...shipping
          }
          returnInfoMileageHistories {
            ...mileageHistoryConnection
          }
          returnInfoOrderItems {
            ...orderItemConnection
          }
          returnInfoReturnInfoImages {
            ...returnInfoImageConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.returnInfoImageConnection}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  REJECT_RETURN_INFOS: gql`
    mutation rejectReturnInfosDefault($input: RejectReturnInfosInput!) {
      rejectReturnInfos(input: $input) {
        returnInfos {
          ...returnInfo
          customerAddress {
            ...customerAddress
          }
          shipping {
            ...shipping
          }
          returnInfoMileageHistories {
            ...mileageHistoryConnection
          }
          returnInfoOrderItems {
            ...orderItemConnection
          }
          returnInfoReturnInfoImages {
            ...returnInfoImageConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.returnInfoImageConnection}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  UPDATE_RETURN_INFO_CUSTOMER_ADDRESS: gql`
    mutation updateReturnInfoCustomerAddressDefault($id: ID!, $input: UpdateReturnInfoCustomerAddressInput!) {
      updateReturnInfoCustomerAddress(id: $id, input: $input) {
        returnInfo {
          ...returnInfo
          customerAddress {
            ...customerAddress
          }
          shipping {
            ...shipping
          }
          returnInfoMileageHistories {
            ...mileageHistoryConnection
          }
          returnInfoOrderItems {
            ...orderItemConnection
          }
          returnInfoReturnInfoImages {
            ...returnInfoImageConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.returnInfoImageConnection}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  RETURNED_RETURN_INFOS: gql`
    mutation returnedReturnInfosDefault($input: ReturnedReturnInfosInput!) {
      returnedReturnInfos(input: $input) {
        returnInfos {
          ...returnInfo
          customerAddress {
            ...customerAddress
          }
          shipping {
            ...shipping
          }
          returnInfoMileageHistories {
            ...mileageHistoryConnection
          }
          returnInfoOrderItems {
            ...orderItemConnection
          }
          returnInfoReturnInfoImages {
            ...returnInfoImageConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.returnInfoImageConnection}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  RETURN_ORDER_ITEMS: gql`
    mutation returnOrderItemsDefault($input: ReturnOrderItemsInput!) {
      returnOrderItems(input: $input) {
        orderItems {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  CREATE_TRANSACTION: gql`
    mutation createTransactionDefault($input: CreateTransactionInput!) {
      createTransaction(input: $input) {
        transaction {
          ...transaction
          order {
            ...order
          }
          fitpetAdmin {
            ...user
          }
          approvedTransactionOrder {
            ...order
          }
          transactionCancelTransactions {
            ...cancelTransactionConnection
          }
          cancelTransactions {
            ...cancelTransactionConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.user}
  `,
  APPROVE_TRANSACTION: gql`
    mutation approveTransactionDefault($id: ID!) {
      approveTransaction(id: $id) {
        transaction {
          ...transaction
          order {
            ...order
          }
          fitpetAdmin {
            ...user
          }
          approvedTransactionOrder {
            ...order
          }
          transactionCancelTransactions {
            ...cancelTransactionConnection
          }
          cancelTransactions {
            ...cancelTransactionConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CHECK_UNCONFIRMED_TRANSACTION: gql`
    mutation checkUnconfirmedTransactionDefault($id: ID!) {
      checkUnconfirmedTransaction(id: $id) {
        isSuccess
        transaction {
          ...transaction
          order {
            ...order
          }
          fitpetAdmin {
            ...user
          }
          approvedTransactionOrder {
            ...order
          }
          transactionCancelTransactions {
            ...cancelTransactionConnection
          }
          cancelTransactions {
            ...cancelTransactionConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.user}
  `,
  REQUEST_ORDER_EXCEL_FILE: gql`
    mutation requestOrderExcelFileDefault($input: RequestExcelFileInput!) {
      requestOrderExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  REQUEST_READY_FOR_SHIPPING_ORDER_EXCEL_FILE: gql`
    mutation requestReadyForShippingOrderExcelFileDefault($input: RequestExcelFileInput!) {
      requestReadyForShippingOrderExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  REQUEST_READY_FOR_SHIPPING_ORDER_WMS_EXCEL_FILE: gql`
    mutation requestReadyForShippingOrderWmsExcelFileDefault($input: RequestExcelFileInput!) {
      requestReadyForShippingOrderWmsExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  UPDATE_SHIPPING_BY_EXCEL: gql`
    mutation updateShippingByExcelDefault($input: BaseImportByExcelInput!) {
      updateShippingByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  CREATE_ORDER_ITEM_ADMIN_NOTE: gql`
    mutation createOrderItemAdminNoteDefault($input: CreateOrderItemAdminNoteInput!) {
      createOrderItemAdminNote(input: $input) {
        orderItemAdminNote {
          ...orderItemAdminNote
          orderItem {
            ...orderItem
          }
          fitpetAdmin {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNote}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_ORDER_ITEM_ADMIN_NOTE: gql`
    mutation updateOrderItemAdminNoteDefault($id: ID!, $input: UpdateOrderItemAdminNoteInput!) {
      updateOrderItemAdminNote(id: $id, input: $input) {
        orderItemAdminNote {
          ...orderItemAdminNote
          orderItem {
            ...orderItem
          }
          fitpetAdmin {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNote}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_ORDER_ITEM_ADMIN_NOTE: gql`
    mutation deleteOrderItemAdminNoteDefault($id: ID!) {
      deleteOrderItemAdminNote(id: $id) {
        orderItemAdminNote {
          ...orderItemAdminNote
          orderItem {
            ...orderItem
          }
          fitpetAdmin {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNote}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CREATE_REMOTE_AREA_BY_EXCEL: gql`
    mutation createRemoteAreaByExcelDefault($input: BaseImportByExcelInput!) {
      createRemoteAreaByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  CREATE_REMOTE_AREA: gql`
    mutation createRemoteAreaDefault($input: RemoteAreaInput!) {
      createRemoteArea(input: $input) {
        remoteArea {
          ...remoteArea
        }
      }
    }
    ${DEFAULT_FRAGMENTS.remoteArea}
  `,
  UPDATE_REMOTE_AREA: gql`
    mutation updateRemoteAreaDefault($id: ID!, $input: RemoteAreaInput!) {
      updateRemoteArea(id: $id, input: $input) {
        remoteArea {
          ...remoteArea
        }
      }
    }
    ${DEFAULT_FRAGMENTS.remoteArea}
  `,
  DELETE_REMOTE_AREA: gql`
    mutation deleteRemoteAreaDefault($id: ID!) {
      deleteRemoteArea(id: $id) {
        remoteArea {
          ...remoteArea
        }
      }
    }
    ${DEFAULT_FRAGMENTS.remoteArea}
  `,
  REQUEST_EXCHANGE_ORDER_ITEMS: gql`
    mutation requestExchangeOrderItemsDefault($input: RequestExchangeOrderItemsInput!) {
      requestExchangeOrderItems(input: $input) {
        orderItems {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  REJECT_EXCHANGE_RETURN_INFOS: gql`
    mutation rejectExchangeReturnInfosDefault($input: RejectExchangeReturnInfosInput!) {
      rejectExchangeReturnInfos(input: $input) {
        returnInfos {
          ...returnInfo
          customerAddress {
            ...customerAddress
          }
          shipping {
            ...shipping
          }
          returnInfoMileageHistories {
            ...mileageHistoryConnection
          }
          returnInfoOrderItems {
            ...orderItemConnection
          }
          returnInfoReturnInfoImages {
            ...returnInfoImageConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.returnInfoImageConnection}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  ACCEPT_EXCHANGE_RETURN_INFOS: gql`
    mutation acceptExchangeReturnInfosDefault($input: ReturnInfosInput!) {
      acceptExchangeReturnInfos(input: $input) {
        returnInfos {
          ...returnInfo
          customerAddress {
            ...customerAddress
          }
          shipping {
            ...shipping
          }
          returnInfoMileageHistories {
            ...mileageHistoryConnection
          }
          returnInfoOrderItems {
            ...orderItemConnection
          }
          returnInfoReturnInfoImages {
            ...returnInfoImageConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
        }
        newOrderItems {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.returnInfoImageConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  CONFIRM_EXCHANGE_RETURN_INFOS: gql`
    mutation confirmExchangeReturnInfosDefault($input: ConfirmExchangeReturnInfosInput!) {
      confirmExchangeReturnInfos(input: $input) {
        returnInfos {
          ...returnInfo
          customerAddress {
            ...customerAddress
          }
          shipping {
            ...shipping
          }
          returnInfoMileageHistories {
            ...mileageHistoryConnection
          }
          returnInfoOrderItems {
            ...orderItemConnection
          }
          returnInfoReturnInfoImages {
            ...returnInfoImageConnection
          }
          billingitemSet {
            ...billingItemConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.returnInfoImageConnection}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  EXCHANGE_ORDER_ITEMS: gql`
    mutation exchangeOrderItemsDefault($input: ExchangeOrderItemsInput!) {
      exchangeOrderItems(input: $input) {
        newOrderItems {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
        orderItems {
          ...orderItem
          order {
            ...order
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          productOption {
            ...productOption
          }
          shipping {
            ...shipping
          }
          productPromotion {
            ...productPromotion
          }
          exchangedOrderItem {
            ...orderItem
          }
          refundInfo {
            ...refundInfo
          }
          refundShippingInfo {
            ...refundShippingInfo
          }
          returnInfo {
            ...returnInfo
          }
          orderItemServiceQnas {
            ...serviceQnaConnection
          }
          orderItemReviews {
            ...reviewConnection
          }
          orderItemMileageHistories {
            ...mileageHistoryConnection
          }
          orderItemCoupons {
            ...couponConnection
          }
          orderItemOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          orderitem {
            ...orderItem
          }
          canceltransaction {
            ...cancelTransaction
          }
          billingitemSet {
            ...billingItemConnection
          }
          productImage {
            ...productImage
          }
          returnAddress {
            ...sellerAddress
          }
          approvedTransaction {
            ...transaction
          }
          approvedCancelTransaction {
            ...cancelTransaction
          }
          adminNotes {
            ...orderItemAdminNoteConnection
          }
          availableCoupons {
            ...couponConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  ASK_PRODUCT_QUESTION: gql`
    mutation askProductQuestionDefault($input: AskProductQuestionInput!) {
      askProductQuestion(input: $input) {
        productQna {
          ...productQna
          product {
            ...product
          }
          productOption {
            ...productOption
          }
          user {
            ...user
          }
          answeredUser {
            ...user
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productQna}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ANSWER_PRODUCT_QUESTION: gql`
    mutation answerProductQuestionDefault($id: ID!, $input: AnswerQuestionInput!) {
      answerProductQuestion(id: $id, input: $input) {
        productQna {
          ...productQna
          product {
            ...product
          }
          productOption {
            ...productOption
          }
          user {
            ...user
          }
          answeredUser {
            ...user
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productQna}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_PRODUCT_QUESTION: gql`
    mutation deleteProductQuestionDefault($id: ID!) {
      deleteProductQuestion(id: $id) {
        productQna {
          ...productQna
          product {
            ...product
          }
          productOption {
            ...productOption
          }
          user {
            ...user
          }
          answeredUser {
            ...user
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productQna}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_PRODUCT_QUESTIONS: gql`
    mutation deleteProductQuestionsDefault($input: DeleteQnasInput!) {
      deleteProductQuestions(input: $input) {
        count
      }
    }
  `,
  ASK_SERVICE_QUESTION: gql`
    mutation askServiceQuestionDefault($input: AskServiceQuestionInput!) {
      askServiceQuestion(input: $input) {
        serviceQna {
          ...serviceQna
          orderItem {
            ...orderItem
          }
          user {
            ...user
          }
          answeredUser {
            ...user
          }
          serviceQnaImages {
            ...serviceQnaImageConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ANSWER_SERVICE_QUESTION: gql`
    mutation answerServiceQuestionDefault($id: ID!, $input: AnswerQuestionInput!) {
      answerServiceQuestion(id: $id, input: $input) {
        serviceQna {
          ...serviceQna
          orderItem {
            ...orderItem
          }
          user {
            ...user
          }
          answeredUser {
            ...user
          }
          serviceQnaImages {
            ...serviceQnaImageConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_SERVICE_QUESTION: gql`
    mutation deleteServiceQuestionDefault($id: ID!) {
      deleteServiceQuestion(id: $id) {
        serviceQna {
          ...serviceQna
          orderItem {
            ...orderItem
          }
          user {
            ...user
          }
          answeredUser {
            ...user
          }
          serviceQnaImages {
            ...serviceQnaImageConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_SERVICE_QUESTIONS: gql`
    mutation deleteServiceQuestionsDefault($input: DeleteQnasInput!) {
      deleteServiceQuestions(input: $input) {
        count
      }
    }
  `,
  CREATE_REVIEW: gql`
    mutation createReviewDefault($input: CreateReviewInput!) {
      createReview(input: $input) {
        review {
          ...review
          user {
            ...user
          }
          product {
            ...product
          }
          orderItem {
            ...orderItem
          }
          answeredUser {
            ...user
          }
          likedUsers {
            ...userConnection
          }
          reviewReviewImages {
            ...reviewImageConnection
          }
          reviewMileageHistories {
            ...mileageHistoryConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.review}
    ${DEFAULT_FRAGMENTS.reviewImageConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CREATE_BULK_REVIEW_BY_FITPET_ADMIN_EXCEL: gql`
    mutation createBulkReviewByFitpetAdminExcelDefault($input: BaseImportByExcelInput!) {
      createBulkReviewByFitpetAdminExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  UPDATE_REVIEW: gql`
    mutation updateReviewDefault($id: ID!, $input: UpdateReviewInput!) {
      updateReview(id: $id, input: $input) {
        review {
          ...review
          user {
            ...user
          }
          product {
            ...product
          }
          orderItem {
            ...orderItem
          }
          answeredUser {
            ...user
          }
          likedUsers {
            ...userConnection
          }
          reviewReviewImages {
            ...reviewImageConnection
          }
          reviewMileageHistories {
            ...mileageHistoryConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.review}
    ${DEFAULT_FRAGMENTS.reviewImageConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  DELETE_REVIEW: gql`
    mutation deleteReviewDefault($id: ID!) {
      deleteReview(id: $id) {
        review {
          ...review
          user {
            ...user
          }
          product {
            ...product
          }
          orderItem {
            ...orderItem
          }
          answeredUser {
            ...user
          }
          likedUsers {
            ...userConnection
          }
          reviewReviewImages {
            ...reviewImageConnection
          }
          reviewMileageHistories {
            ...mileageHistoryConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.review}
    ${DEFAULT_FRAGMENTS.reviewImageConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  DELETE_REVIEWS: gql`
    mutation deleteReviewsDefault($input: DeleteReviewsInput!) {
      deleteReviews(input: $input) {
        count
      }
    }
  `,
  CREATE_BEST_REVIEWS: gql`
    mutation createBestReviewsDefault($input: CreateBestReviewsInput!) {
      createBestReviews(input: $input) {
        reviews {
          ...review
          user {
            ...user
          }
          product {
            ...product
          }
          orderItem {
            ...orderItem
          }
          answeredUser {
            ...user
          }
          likedUsers {
            ...userConnection
          }
          reviewReviewImages {
            ...reviewImageConnection
          }
          reviewMileageHistories {
            ...mileageHistoryConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.review}
    ${DEFAULT_FRAGMENTS.reviewImageConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  TOGGLE_REVIEW_LIKED: gql`
    mutation toggleReviewLikedDefault($id: ID!) {
      toggleReviewLiked(id: $id) {
        review {
          ...review
          user {
            ...user
          }
          product {
            ...product
          }
          orderItem {
            ...orderItem
          }
          answeredUser {
            ...user
          }
          likedUsers {
            ...userConnection
          }
          reviewReviewImages {
            ...reviewImageConnection
          }
          reviewMileageHistories {
            ...mileageHistoryConnection
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.review}
    ${DEFAULT_FRAGMENTS.reviewImageConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  UPDATE_REVIEW_GROUP_PRODUCTS: gql`
    mutation updateReviewGroupProductsDefault($id: ID!, $input: UpdateReviewGroupProductsInput!) {
      updateReviewGroupProducts(id: $id, input: $input) {
        reviewGroup {
          ...reviewGroup
          reviewGroupProducts {
            ...productConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
  `,
  CLEAR_HOME_CACHE: gql`
    mutation clearHomeCacheDefault($id: ID) {
      clearHomeCache(id: $id) {
        result
      }
    }
  `,
  CLEAR_BANNER_CACHE: gql`
    mutation clearBannerCacheDefault($id: ID) {
      clearBannerCache(id: $id) {
        result
      }
    }
  `,
  CLEAR_BEST_CACHE: gql`
    mutation clearBestCacheDefault($id: ID) {
      clearBestCache(id: $id) {
        result
      }
    }
  `,
  UPDATE_MILEAGE_CONFIG: gql`
    mutation updateMileageConfigDefault($input: MileageConfigInput!) {
      updateMileageConfig(input: $input) {
        serverConfigs {
          ...serverConfig
        }
      }
    }
    ${DEFAULT_FRAGMENTS.serverConfig}
  `,
  CREATE_SERVER_CONFIG: gql`
    mutation createServerConfigDefault($input: ServerConfigInput!) {
      createServerConfig(input: $input) {
        serverConfig {
          ...serverConfig
        }
      }
    }
    ${DEFAULT_FRAGMENTS.serverConfig}
  `,
  UPDATE_SERVER_CONFIG: gql`
    mutation updateServerConfigDefault($id: ID!, $input: ServerConfigInput!) {
      updateServerConfig(id: $id, input: $input) {
        serverConfig {
          ...serverConfig
        }
      }
    }
    ${DEFAULT_FRAGMENTS.serverConfig}
  `,
  DELETE_SERVER_CONFIG: gql`
    mutation deleteServerConfigDefault($id: ID!) {
      deleteServerConfig(id: $id) {
        serverConfig {
          ...serverConfig
        }
      }
    }
    ${DEFAULT_FRAGMENTS.serverConfig}
  `,
  UPDATE_DISPLAY_ORDER: gql`
    mutation updateDisplayOrderDefault($id: ID!, $input: UpdateDisplayOrderInput!) {
      updateDisplayOrder(id: $id, input: $input) {
        id
        displayOrder
      }
    }
  `,
  UPLOAD_SEARCH_TOKEN: gql`
    mutation uploadSearchTokenDefault($input: UploadSearchTokenInput!) {
      uploadSearchToken(input: $input) {
        isSuccess
      }
    }
  `,
  ASSOCIATE_SEARCH_TOKEN: gql`
    mutation associateSearchTokenDefault($input: TokenTypeInput!) {
      associateSearchToken(input: $input) {
        isSuccess
      }
    }
  `,
  UPDATE_MIN_APP_VERSION: gql`
    mutation updateMinAppVersionDefault($input: MinAppVersionInput!) {
      updateMinAppVersion(input: $input) {
        minAppVersion {
          ...minAppVersion
        }
      }
    }
    ${DEFAULT_FRAGMENTS.minAppVersion}
  `,
  CREATE_PRODUCT_BY_FITPET_ADMIN: gql`
    mutation createProductByFitpetAdminDefault($input: ProductInput!) {
      createProductByFitpetAdmin(input: $input) {
        product {
          ...product
          originalProduct {
            ...product
          }
          reviewGroup {
            ...reviewGroup
          }
          asInfo {
            ...asInfo
          }
          categories {
            ...categoryConnection
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedProduct
          }
          updateStatus {
            ...status
          }
          defaultProductOption {
            ...productOption
          }
          shippingPreset {
            ...shippingPreset
          }
          wishedProductUsers {
            ...userConnection
          }
          productQnas {
            ...productQnaConnection
          }
          productReviews {
            ...reviewConnection
          }
          productProductCategories {
            ...productCategoryConnection
          }
          copiedProduct {
            ...productConnection
          }
          productProductImages {
            ...productImageConnection
          }
          productProductOptions {
            ...productOptionConnection
          }
          productInfluencers {
            ...influencerConnection
          }
          includedProductsCouponGroups {
            ...couponGroupConnection
          }
          excludedProductsCouponGroups {
            ...couponGroupConnection
          }
          displayproductinfoSet {
            ...displayProductInfoConnection
          }
          productProductPromotions {
            ...productPromotionConnection
          }
          reviews {
            ...reviewConnection
          }
          productPromotion {
            ...productPromotion
          }
          downloadCouponGroups {
            ...couponGroupConnection
          }
          productCoupons {
            ...couponConnection
          }
          brandCoupons {
            ...couponConnection
          }
          categoryCoupons {
            ...couponConnection
          }
          mainImage {
            ...productImage
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CREATE_PRODUCT_BY_SELLER: gql`
    mutation createProductBySellerDefault($input: ProductInput!) {
      createProductBySeller(input: $input) {
        product {
          ...product
          originalProduct {
            ...product
          }
          reviewGroup {
            ...reviewGroup
          }
          asInfo {
            ...asInfo
          }
          categories {
            ...categoryConnection
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedProduct
          }
          updateStatus {
            ...status
          }
          defaultProductOption {
            ...productOption
          }
          shippingPreset {
            ...shippingPreset
          }
          wishedProductUsers {
            ...userConnection
          }
          productQnas {
            ...productQnaConnection
          }
          productReviews {
            ...reviewConnection
          }
          productProductCategories {
            ...productCategoryConnection
          }
          copiedProduct {
            ...productConnection
          }
          productProductImages {
            ...productImageConnection
          }
          productProductOptions {
            ...productOptionConnection
          }
          productInfluencers {
            ...influencerConnection
          }
          includedProductsCouponGroups {
            ...couponGroupConnection
          }
          excludedProductsCouponGroups {
            ...couponGroupConnection
          }
          displayproductinfoSet {
            ...displayProductInfoConnection
          }
          productProductPromotions {
            ...productPromotionConnection
          }
          reviews {
            ...reviewConnection
          }
          productPromotion {
            ...productPromotion
          }
          downloadCouponGroups {
            ...couponGroupConnection
          }
          productCoupons {
            ...couponConnection
          }
          brandCoupons {
            ...couponConnection
          }
          categoryCoupons {
            ...couponConnection
          }
          mainImage {
            ...productImage
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  UPDATE_PRODUCT_BY_FITPET_ADMIN: gql`
    mutation updateProductByFitpetAdminDefault($id: ID!, $input: ProductInput!) {
      updateProductByFitpetAdmin(id: $id, input: $input) {
        product {
          ...product
          originalProduct {
            ...product
          }
          reviewGroup {
            ...reviewGroup
          }
          asInfo {
            ...asInfo
          }
          categories {
            ...categoryConnection
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedProduct
          }
          updateStatus {
            ...status
          }
          defaultProductOption {
            ...productOption
          }
          shippingPreset {
            ...shippingPreset
          }
          wishedProductUsers {
            ...userConnection
          }
          productQnas {
            ...productQnaConnection
          }
          productReviews {
            ...reviewConnection
          }
          productProductCategories {
            ...productCategoryConnection
          }
          copiedProduct {
            ...productConnection
          }
          productProductImages {
            ...productImageConnection
          }
          productProductOptions {
            ...productOptionConnection
          }
          productInfluencers {
            ...influencerConnection
          }
          includedProductsCouponGroups {
            ...couponGroupConnection
          }
          excludedProductsCouponGroups {
            ...couponGroupConnection
          }
          displayproductinfoSet {
            ...displayProductInfoConnection
          }
          productProductPromotions {
            ...productPromotionConnection
          }
          reviews {
            ...reviewConnection
          }
          productPromotion {
            ...productPromotion
          }
          downloadCouponGroups {
            ...couponGroupConnection
          }
          productCoupons {
            ...couponConnection
          }
          brandCoupons {
            ...couponConnection
          }
          categoryCoupons {
            ...couponConnection
          }
          mainImage {
            ...productImage
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  UPDATE_PRODUCT_BY_SELLER: gql`
    mutation updateProductBySellerDefault($id: ID!, $input: ProductInput!) {
      updateProductBySeller(id: $id, input: $input) {
        product {
          ...product
          originalProduct {
            ...product
          }
          reviewGroup {
            ...reviewGroup
          }
          asInfo {
            ...asInfo
          }
          categories {
            ...categoryConnection
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedProduct
          }
          updateStatus {
            ...status
          }
          defaultProductOption {
            ...productOption
          }
          shippingPreset {
            ...shippingPreset
          }
          wishedProductUsers {
            ...userConnection
          }
          productQnas {
            ...productQnaConnection
          }
          productReviews {
            ...reviewConnection
          }
          productProductCategories {
            ...productCategoryConnection
          }
          copiedProduct {
            ...productConnection
          }
          productProductImages {
            ...productImageConnection
          }
          productProductOptions {
            ...productOptionConnection
          }
          productInfluencers {
            ...influencerConnection
          }
          includedProductsCouponGroups {
            ...couponGroupConnection
          }
          excludedProductsCouponGroups {
            ...couponGroupConnection
          }
          displayproductinfoSet {
            ...displayProductInfoConnection
          }
          productProductPromotions {
            ...productPromotionConnection
          }
          reviews {
            ...reviewConnection
          }
          productPromotion {
            ...productPromotion
          }
          downloadCouponGroups {
            ...couponGroupConnection
          }
          productCoupons {
            ...couponConnection
          }
          brandCoupons {
            ...couponConnection
          }
          categoryCoupons {
            ...couponConnection
          }
          mainImage {
            ...productImage
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CREATE_PRODUCTS_BY_EXCEL: gql`
    mutation createProductsByExcelDefault($input: BaseImportByExcelInput!) {
      createProductsByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  UPDATE_PRODUCT_SEARCH_KEYWORDS_BY_EXCEL: gql`
    mutation updateProductSearchKeywordsByExcelDefault($input: BaseImportByExcelInput!) {
      updateProductSearchKeywordsByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  COPY_PRODUCT: gql`
    mutation copyProductDefault($id: ID!) {
      copyProduct(id: $id) {
        product {
          ...product
          originalProduct {
            ...product
          }
          reviewGroup {
            ...reviewGroup
          }
          asInfo {
            ...asInfo
          }
          categories {
            ...categoryConnection
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedProduct
          }
          updateStatus {
            ...status
          }
          defaultProductOption {
            ...productOption
          }
          shippingPreset {
            ...shippingPreset
          }
          wishedProductUsers {
            ...userConnection
          }
          productQnas {
            ...productQnaConnection
          }
          productReviews {
            ...reviewConnection
          }
          productProductCategories {
            ...productCategoryConnection
          }
          copiedProduct {
            ...productConnection
          }
          productProductImages {
            ...productImageConnection
          }
          productProductOptions {
            ...productOptionConnection
          }
          productInfluencers {
            ...influencerConnection
          }
          includedProductsCouponGroups {
            ...couponGroupConnection
          }
          excludedProductsCouponGroups {
            ...couponGroupConnection
          }
          displayproductinfoSet {
            ...displayProductInfoConnection
          }
          productProductPromotions {
            ...productPromotionConnection
          }
          reviews {
            ...reviewConnection
          }
          productPromotion {
            ...productPromotion
          }
          downloadCouponGroups {
            ...couponGroupConnection
          }
          productCoupons {
            ...couponConnection
          }
          brandCoupons {
            ...couponConnection
          }
          categoryCoupons {
            ...couponConnection
          }
          mainImage {
            ...productImage
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  DELETE_INCOMPLETE_PRODUCTS: gql`
    mutation deleteIncompleteProductsDefault($input: ModelDeletesInput!) {
      deleteIncompleteProducts(input: $input) {
        count
      }
    }
  `,
  REQUEST_PRODUCT_EXCEL_FILE: gql`
    mutation requestProductExcelFileDefault($input: RequestExcelFileInput!) {
      requestProductExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  UPDATE_PRODUCTS_SOLD_OUT: gql`
    mutation updateProductsSoldOutDefault($input: ProductsSoldOutInput!) {
      updateProductsSoldOut(input: $input) {
        products {
          ...product
          originalProduct {
            ...product
          }
          reviewGroup {
            ...reviewGroup
          }
          asInfo {
            ...asInfo
          }
          categories {
            ...categoryConnection
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedProduct
          }
          updateStatus {
            ...status
          }
          defaultProductOption {
            ...productOption
          }
          shippingPreset {
            ...shippingPreset
          }
          wishedProductUsers {
            ...userConnection
          }
          productQnas {
            ...productQnaConnection
          }
          productReviews {
            ...reviewConnection
          }
          productProductCategories {
            ...productCategoryConnection
          }
          copiedProduct {
            ...productConnection
          }
          productProductImages {
            ...productImageConnection
          }
          productProductOptions {
            ...productOptionConnection
          }
          productInfluencers {
            ...influencerConnection
          }
          includedProductsCouponGroups {
            ...couponGroupConnection
          }
          excludedProductsCouponGroups {
            ...couponGroupConnection
          }
          displayproductinfoSet {
            ...displayProductInfoConnection
          }
          productProductPromotions {
            ...productPromotionConnection
          }
          reviews {
            ...reviewConnection
          }
          productPromotion {
            ...productPromotion
          }
          downloadCouponGroups {
            ...couponGroupConnection
          }
          productCoupons {
            ...couponConnection
          }
          brandCoupons {
            ...couponConnection
          }
          categoryCoupons {
            ...couponConnection
          }
          mainImage {
            ...productImage
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CREATE_CATEGORY: gql`
    mutation createCategoryDefault($input: CategoryInput!) {
      createCategory(input: $input) {
        category {
          ...category
          parentCategory {
            ...category
          }
          categoryChildren {
            ...categoryConnection
          }
          categoryProductCategories {
            ...productCategoryConnection
          }
          products {
            ...productConnection
          }
          includedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          excludedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          coupons {
            ...couponConnection
          }
          includedBrands {
            ...brandConnection
          }
          subCategories {
            ...categoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.category}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
  `,
  UPDATE_CATEGORY: gql`
    mutation updateCategoryDefault($id: ID!, $input: CategoryInput!) {
      updateCategory(id: $id, input: $input) {
        category {
          ...category
          parentCategory {
            ...category
          }
          categoryChildren {
            ...categoryConnection
          }
          categoryProductCategories {
            ...productCategoryConnection
          }
          products {
            ...productConnection
          }
          includedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          excludedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          coupons {
            ...couponConnection
          }
          includedBrands {
            ...brandConnection
          }
          subCategories {
            ...categoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.category}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
  `,
  UPDATE_CATEGORY_IMAGE: gql`
    mutation updateCategoryImageDefault($id: ID!, $input: CategoryImageInput!) {
      updateCategoryImage(id: $id, input: $input) {
        category {
          ...category
          parentCategory {
            ...category
          }
          categoryChildren {
            ...categoryConnection
          }
          categoryProductCategories {
            ...productCategoryConnection
          }
          products {
            ...productConnection
          }
          includedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          excludedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          coupons {
            ...couponConnection
          }
          includedBrands {
            ...brandConnection
          }
          subCategories {
            ...categoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.category}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
  `,
  DELETE_CATEGORY: gql`
    mutation deleteCategoryDefault($id: ID!) {
      deleteCategory(id: $id) {
        category {
          ...category
          parentCategory {
            ...category
          }
          categoryChildren {
            ...categoryConnection
          }
          categoryProductCategories {
            ...productCategoryConnection
          }
          products {
            ...productConnection
          }
          includedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          excludedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          coupons {
            ...couponConnection
          }
          includedBrands {
            ...brandConnection
          }
          subCategories {
            ...categoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.category}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
  `,
  TOGGLE_CATEGORY_VISIBLE: gql`
    mutation toggleCategoryVisibleDefault($id: ID!) {
      toggleCategoryVisible(id: $id) {
        category {
          ...category
          parentCategory {
            ...category
          }
          categoryChildren {
            ...categoryConnection
          }
          categoryProductCategories {
            ...productCategoryConnection
          }
          products {
            ...productConnection
          }
          includedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          excludedCategoriesCouponGroups {
            ...couponGroupConnection
          }
          coupons {
            ...couponConnection
          }
          includedBrands {
            ...brandConnection
          }
          subCategories {
            ...categoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.category}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
  `,
  CREATE_BRAND_BY_FITPET_ADMIN: gql`
    mutation createBrandByFitpetAdminDefault($input: BrandByFitpetAdminInput!) {
      createBrandByFitpetAdmin(input: $input) {
        brand {
          ...brand
          sellers {
            ...sellerConnection
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedBrand
          }
          updateStatus {
            ...status
          }
          wishedBrandUsers {
            ...userConnection
          }
          brandProducts {
            ...productConnection
          }
          includedBrandsCouponGroups {
            ...couponGroupConnection
          }
          excludedBrandsCouponGroups {
            ...couponGroupConnection
          }
          brandOrderItems {
            ...orderItemConnection
          }
          brandBillings {
            ...sellerBillingConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedBrand}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CREATE_BRAND_BY_SELLER: gql`
    mutation createBrandBySellerDefault($input: BrandBySellerInput!) {
      createBrandBySeller(input: $input) {
        brand {
          ...brand
          sellers {
            ...sellerConnection
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedBrand
          }
          updateStatus {
            ...status
          }
          wishedBrandUsers {
            ...userConnection
          }
          brandProducts {
            ...productConnection
          }
          includedBrandsCouponGroups {
            ...couponGroupConnection
          }
          excludedBrandsCouponGroups {
            ...couponGroupConnection
          }
          brandOrderItems {
            ...orderItemConnection
          }
          brandBillings {
            ...sellerBillingConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedBrand}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  UPDATE_BRAND_BY_FITPET_ADMIN: gql`
    mutation updateBrandByFitpetAdminDefault($id: ID!, $input: BrandByFitpetAdminInput!) {
      updateBrandByFitpetAdmin(id: $id, input: $input) {
        brand {
          ...brand
          sellers {
            ...sellerConnection
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedBrand
          }
          updateStatus {
            ...status
          }
          wishedBrandUsers {
            ...userConnection
          }
          brandProducts {
            ...productConnection
          }
          includedBrandsCouponGroups {
            ...couponGroupConnection
          }
          excludedBrandsCouponGroups {
            ...couponGroupConnection
          }
          brandOrderItems {
            ...orderItemConnection
          }
          brandBillings {
            ...sellerBillingConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedBrand}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  UPDATE_BRAND_BY_SELLER: gql`
    mutation updateBrandBySellerDefault($id: ID!, $input: BrandBySellerInput!) {
      updateBrandBySeller(id: $id, input: $input) {
        brand {
          ...brand
          sellers {
            ...sellerConnection
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedBrand
          }
          updateStatus {
            ...status
          }
          wishedBrandUsers {
            ...userConnection
          }
          brandProducts {
            ...productConnection
          }
          includedBrandsCouponGroups {
            ...couponGroupConnection
          }
          excludedBrandsCouponGroups {
            ...couponGroupConnection
          }
          brandOrderItems {
            ...orderItemConnection
          }
          brandBillings {
            ...sellerBillingConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedBrand}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CREATE_POPULAR_SEARCH_TERM: gql`
    mutation createPopularSearchTermDefault($input: PopularSearchTermInput!) {
      createPopularSearchTerm(input: $input) {
        popularSearchTerm {
          ...popularSearchTerm
        }
      }
    }
    ${DEFAULT_FRAGMENTS.popularSearchTerm}
  `,
  CREATE_OR_UPDATE_SKUS_BY_EXCEL: gql`
    mutation createOrUpdateSkusByExcelDefault($input: BaseImportByExcelInput!) {
      createOrUpdateSkusByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  REQUEST_SKU_EXCEL_FILE: gql`
    mutation requestSkuExcelFileDefault($input: RequestExcelFileInput!) {
      requestSkuExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
  UPDATE_SKU: gql`
    mutation updateSkuDefault($id: ID!, $input: UpdateSkuInput!) {
      updateSku(id: $id, input: $input) {
        sku {
          ...sku
          seller {
            ...seller
          }
          createdUser {
            ...user
          }
          updatedUser {
            ...user
          }
          productOptions {
            ...productOptionConnection
          }
          skuSkuHistories {
            ...skuHistoryConnection
          }
          skuProductOptionSkus {
            ...productOptionSkuConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productOptionSkuConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sku}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_PRODUCTS_SELLING: gql`
    mutation updateProductsSellingDefault($input: ProductsSellingInput!) {
      updateProductsSelling(input: $input) {
        products {
          ...product
          originalProduct {
            ...product
          }
          reviewGroup {
            ...reviewGroup
          }
          asInfo {
            ...asInfo
          }
          categories {
            ...categoryConnection
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedProduct
          }
          updateStatus {
            ...status
          }
          defaultProductOption {
            ...productOption
          }
          shippingPreset {
            ...shippingPreset
          }
          wishedProductUsers {
            ...userConnection
          }
          productQnas {
            ...productQnaConnection
          }
          productReviews {
            ...reviewConnection
          }
          productProductCategories {
            ...productCategoryConnection
          }
          copiedProduct {
            ...productConnection
          }
          productProductImages {
            ...productImageConnection
          }
          productProductOptions {
            ...productOptionConnection
          }
          productInfluencers {
            ...influencerConnection
          }
          includedProductsCouponGroups {
            ...couponGroupConnection
          }
          excludedProductsCouponGroups {
            ...couponGroupConnection
          }
          displayproductinfoSet {
            ...displayProductInfoConnection
          }
          productProductPromotions {
            ...productPromotionConnection
          }
          reviews {
            ...reviewConnection
          }
          productPromotion {
            ...productPromotion
          }
          downloadCouponGroups {
            ...couponGroupConnection
          }
          productCoupons {
            ...couponConnection
          }
          brandCoupons {
            ...couponConnection
          }
          categoryCoupons {
            ...couponConnection
          }
          mainImage {
            ...productImage
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  APPROVE_PRODUCTS_SELLING: gql`
    mutation approveProductsSellingDefault($input: ApproveProductsSellingInput!) {
      approveProductsSelling(input: $input) {
        products {
          ...product
          originalProduct {
            ...product
          }
          reviewGroup {
            ...reviewGroup
          }
          asInfo {
            ...asInfo
          }
          categories {
            ...categoryConnection
          }
          seller {
            ...seller
          }
          brand {
            ...brand
          }
          status {
            ...status
          }
          updateInfo {
            ...updatedProduct
          }
          updateStatus {
            ...status
          }
          defaultProductOption {
            ...productOption
          }
          shippingPreset {
            ...shippingPreset
          }
          wishedProductUsers {
            ...userConnection
          }
          productQnas {
            ...productQnaConnection
          }
          productReviews {
            ...reviewConnection
          }
          productProductCategories {
            ...productCategoryConnection
          }
          copiedProduct {
            ...productConnection
          }
          productProductImages {
            ...productImageConnection
          }
          productProductOptions {
            ...productOptionConnection
          }
          productInfluencers {
            ...influencerConnection
          }
          includedProductsCouponGroups {
            ...couponGroupConnection
          }
          excludedProductsCouponGroups {
            ...couponGroupConnection
          }
          displayproductinfoSet {
            ...displayProductInfoConnection
          }
          productProductPromotions {
            ...productPromotionConnection
          }
          reviews {
            ...reviewConnection
          }
          productPromotion {
            ...productPromotion
          }
          downloadCouponGroups {
            ...couponGroupConnection
          }
          productCoupons {
            ...couponConnection
          }
          brandCoupons {
            ...couponConnection
          }
          categoryCoupons {
            ...couponConnection
          }
          mainImage {
            ...productImage
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CREATE_USER: gql`
    mutation createUserDefault($input: CreateUserInput!) {
      createUser(input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  UPDATE_USER_BY_FITPET_ADMIN: gql`
    mutation updateUserByFitpetAdminDefault($id: ID!, $input: UpdateUserByFitpetAdminInput!) {
      updateUserByFitpetAdmin(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  UPDATE_USER_BY_CUSTOMER: gql`
    mutation updateUserByCustomerDefault($id: ID!, $input: UpdateUserByCustomerInput!) {
      updateUserByCustomer(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  UPDATE_USER_PASSWORD_BY_FITPET_ADMIN: gql`
    mutation updateUserPasswordByFitpetAdminDefault($id: ID!, $input: UpdateUserPasswordByFitpetAdminInput!) {
      updateUserPasswordByFitpetAdmin(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  UPDATE_MOBILE_NUMBER: gql`
    mutation updateMobileNumberDefault($id: ID!, $input: UserCertificationConfirmInput!) {
      updateMobileNumber(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  ADD_ADMIN_PERMISSION: gql`
    mutation addAdminPermissionDefault($id: ID!) {
      addAdminPermission(id: $id) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  REMOVE_ADMIN_PERMISSION: gql`
    mutation removeAdminPermissionDefault($id: ID!) {
      removeAdminPermission(id: $id) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  WISH_PRODUCT_BY_CUSTOMER: gql`
    mutation wishProductByCustomerDefault($input: WishProductByCustomerInput!) {
      wishProductByCustomer(input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  WISH_BRAND_BY_CUSTOMER: gql`
    mutation wishBrandByCustomerDefault($input: WishBrandByCustomerInput!) {
      wishBrandByCustomer(input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  UPDATE_REFUND_ACCOUNT: gql`
    mutation updateRefundAccountDefault($id: ID!, $input: RefundAccountInput!) {
      updateRefundAccount(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  UPDATE_DISPLAY_PET_TYPE: gql`
    mutation updateDisplayPetTypeDefault($id: ID!, $input: UpdateDisplayPetTypeInput!) {
      updateDisplayPetType(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  CREATE_INFLUENCER_BY_FITPET_ADMIN: gql`
    mutation createInfluencerByFitpetAdminDefault($input: InfluencerInput!) {
      createInfluencerByFitpetAdmin(input: $input) {
        influencer {
          ...influencer
          address {
            ...influencerAddress
          }
          status {
            ...status
          }
          followingSellers {
            ...sellerConnection
          }
          wishedProducts {
            ...productConnection
          }
          user {
            ...user
          }
          sellerSet {
            ...sellerConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.influencerAddress}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CREATE_INFLUENCER_BY_USER_REQUEST: gql`
    mutation createInfluencerByUserRequestDefault($input: InfluencerInput!) {
      createInfluencerByUserRequest(input: $input) {
        influencer {
          ...influencer
          address {
            ...influencerAddress
          }
          status {
            ...status
          }
          followingSellers {
            ...sellerConnection
          }
          wishedProducts {
            ...productConnection
          }
          user {
            ...user
          }
          sellerSet {
            ...sellerConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.influencerAddress}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_INFLUENCER: gql`
    mutation updateInfluencerDefault($id: ID!, $input: InfluencerInput!) {
      updateInfluencer(id: $id, input: $input) {
        influencer {
          ...influencer
          address {
            ...influencerAddress
          }
          status {
            ...status
          }
          followingSellers {
            ...sellerConnection
          }
          wishedProducts {
            ...productConnection
          }
          user {
            ...user
          }
          sellerSet {
            ...sellerConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.influencerAddress}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.user}
  `,
  FOLLOW_SELLER: gql`
    mutation followSellerDefault($input: FollowInput!) {
      followSeller(input: $input) {
        seller {
          ...seller
          users {
            ...userConnection
          }
          status {
            ...status
          }
          blockedInfluencers {
            ...influencerConnection
          }
          updateInfo {
            ...updatedSeller
          }
          updateStatus {
            ...status
          }
          sellerProducts {
            ...productConnection
          }
          sellerBrands {
            ...brandConnection
          }
          sellerSkus {
            ...skuConnection
          }
          sellerInfluencers {
            ...influencerConnection
          }
          sellerUsers {
            ...sellerUserConnection
          }
          sellerAsInfos {
            ...asInfoConnection
          }
          sellerAddresses {
            ...sellerTemplateAddressConnection
          }
          sellerExcelRequests {
            ...excelExportRequestConnection
          }
          sellerOrderItems {
            ...orderItemConnection
          }
          userShippingPresets {
            ...shippingPresetConnection
          }
          sellerBillings {
            ...sellerBillingConnection
          }
          chiefManager {
            ...user
          }
          sellerTemplateAddresses {
            ...sellerTemplateAddressConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfoConnection}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedSeller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  WISH_PRODUCT: gql`
    mutation wishProductDefault($input: WishProductInput!) {
      wishProduct(input: $input) {
        influencer {
          ...influencer
          address {
            ...influencerAddress
          }
          status {
            ...status
          }
          followingSellers {
            ...sellerConnection
          }
          wishedProducts {
            ...productConnection
          }
          user {
            ...user
          }
          sellerSet {
            ...sellerConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.influencerAddress}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CREATE_SELLER_BY_FITPET_ADMIN: gql`
    mutation createSellerByFitpetAdminDefault($input: CreateSellerByFitpetAdminInput!) {
      createSellerByFitpetAdmin(input: $input) {
        seller {
          ...seller
          users {
            ...userConnection
          }
          status {
            ...status
          }
          blockedInfluencers {
            ...influencerConnection
          }
          updateInfo {
            ...updatedSeller
          }
          updateStatus {
            ...status
          }
          sellerProducts {
            ...productConnection
          }
          sellerBrands {
            ...brandConnection
          }
          sellerSkus {
            ...skuConnection
          }
          sellerInfluencers {
            ...influencerConnection
          }
          sellerUsers {
            ...sellerUserConnection
          }
          sellerAsInfos {
            ...asInfoConnection
          }
          sellerAddresses {
            ...sellerTemplateAddressConnection
          }
          sellerExcelRequests {
            ...excelExportRequestConnection
          }
          sellerOrderItems {
            ...orderItemConnection
          }
          userShippingPresets {
            ...shippingPresetConnection
          }
          sellerBillings {
            ...sellerBillingConnection
          }
          chiefManager {
            ...user
          }
          sellerTemplateAddresses {
            ...sellerTemplateAddressConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfoConnection}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedSeller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CREATE_SELLER_BY_USER_REQUEST: gql`
    mutation createSellerByUserRequestDefault($input: CreateSellerByUserRequestInput!) {
      createSellerByUserRequest(input: $input) {
        seller {
          ...seller
          users {
            ...userConnection
          }
          status {
            ...status
          }
          blockedInfluencers {
            ...influencerConnection
          }
          updateInfo {
            ...updatedSeller
          }
          updateStatus {
            ...status
          }
          sellerProducts {
            ...productConnection
          }
          sellerBrands {
            ...brandConnection
          }
          sellerSkus {
            ...skuConnection
          }
          sellerInfluencers {
            ...influencerConnection
          }
          sellerUsers {
            ...sellerUserConnection
          }
          sellerAsInfos {
            ...asInfoConnection
          }
          sellerAddresses {
            ...sellerTemplateAddressConnection
          }
          sellerExcelRequests {
            ...excelExportRequestConnection
          }
          sellerOrderItems {
            ...orderItemConnection
          }
          userShippingPresets {
            ...shippingPresetConnection
          }
          sellerBillings {
            ...sellerBillingConnection
          }
          chiefManager {
            ...user
          }
          sellerTemplateAddresses {
            ...sellerTemplateAddressConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfoConnection}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedSeller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  UPDATE_SELLER_BY_FITPET_ADMIN: gql`
    mutation updateSellerByFitpetAdminDefault($id: ID!, $input: UpdateSellerByFitpetAdminInput!) {
      updateSellerByFitpetAdmin(id: $id, input: $input) {
        seller {
          ...seller
          users {
            ...userConnection
          }
          status {
            ...status
          }
          blockedInfluencers {
            ...influencerConnection
          }
          updateInfo {
            ...updatedSeller
          }
          updateStatus {
            ...status
          }
          sellerProducts {
            ...productConnection
          }
          sellerBrands {
            ...brandConnection
          }
          sellerSkus {
            ...skuConnection
          }
          sellerInfluencers {
            ...influencerConnection
          }
          sellerUsers {
            ...sellerUserConnection
          }
          sellerAsInfos {
            ...asInfoConnection
          }
          sellerAddresses {
            ...sellerTemplateAddressConnection
          }
          sellerExcelRequests {
            ...excelExportRequestConnection
          }
          sellerOrderItems {
            ...orderItemConnection
          }
          userShippingPresets {
            ...shippingPresetConnection
          }
          sellerBillings {
            ...sellerBillingConnection
          }
          chiefManager {
            ...user
          }
          sellerTemplateAddresses {
            ...sellerTemplateAddressConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfoConnection}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedSeller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  UPDATE_SELLER_BY_USER_REQUEST: gql`
    mutation updateSellerByUserRequestDefault($id: ID!, $input: UpdateSellerInput!) {
      updateSellerByUserRequest(id: $id, input: $input) {
        seller {
          ...seller
          users {
            ...userConnection
          }
          status {
            ...status
          }
          blockedInfluencers {
            ...influencerConnection
          }
          updateInfo {
            ...updatedSeller
          }
          updateStatus {
            ...status
          }
          sellerProducts {
            ...productConnection
          }
          sellerBrands {
            ...brandConnection
          }
          sellerSkus {
            ...skuConnection
          }
          sellerInfluencers {
            ...influencerConnection
          }
          sellerUsers {
            ...sellerUserConnection
          }
          sellerAsInfos {
            ...asInfoConnection
          }
          sellerAddresses {
            ...sellerTemplateAddressConnection
          }
          sellerExcelRequests {
            ...excelExportRequestConnection
          }
          sellerOrderItems {
            ...orderItemConnection
          }
          userShippingPresets {
            ...shippingPresetConnection
          }
          sellerBillings {
            ...sellerBillingConnection
          }
          chiefManager {
            ...user
          }
          sellerTemplateAddresses {
            ...sellerTemplateAddressConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfoConnection}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedSeller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  UPDATE_SELLER_NOTIFICATION: gql`
    mutation updateSellerNotificationDefault($id: ID!, $input: SellerNotificationInput!) {
      updateSellerNotification(id: $id, input: $input) {
        seller {
          ...seller
          users {
            ...userConnection
          }
          status {
            ...status
          }
          blockedInfluencers {
            ...influencerConnection
          }
          updateInfo {
            ...updatedSeller
          }
          updateStatus {
            ...status
          }
          sellerProducts {
            ...productConnection
          }
          sellerBrands {
            ...brandConnection
          }
          sellerSkus {
            ...skuConnection
          }
          sellerInfluencers {
            ...influencerConnection
          }
          sellerUsers {
            ...sellerUserConnection
          }
          sellerAsInfos {
            ...asInfoConnection
          }
          sellerAddresses {
            ...sellerTemplateAddressConnection
          }
          sellerExcelRequests {
            ...excelExportRequestConnection
          }
          sellerOrderItems {
            ...orderItemConnection
          }
          userShippingPresets {
            ...shippingPresetConnection
          }
          sellerBillings {
            ...sellerBillingConnection
          }
          chiefManager {
            ...user
          }
          sellerTemplateAddresses {
            ...sellerTemplateAddressConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfoConnection}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedSeller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  EMAIL_SIGNUP: gql`
    mutation emailSignupDefault($input: EmailSignupInput!) {
      emailSignup(input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
        device {
          ...device
          user {
            ...user
          }
          socialService {
            ...socialService
          }
          deviceOrders {
            ...orderConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialService}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  EMAIL_SIGNIN: gql`
    mutation emailSigninDefault($input: EmailSigninInput!) {
      emailSignin(input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
        device {
          ...device
          user {
            ...user
          }
          socialService {
            ...socialService
          }
          deviceOrders {
            ...orderConnection
          }
        }
        isReactivate
        prevLoggedAt
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialService}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  TOKEN_SIGNIN: gql`
    mutation tokenSigninDefault($input: TokenSigninInput!) {
      tokenSignin(input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
        device {
          ...device
          user {
            ...user
          }
          socialService {
            ...socialService
          }
          deviceOrders {
            ...orderConnection
          }
        }
        prevLoggedAt
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialService}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  SOCIAL_SIGNUP: gql`
    mutation socialSignupDefault($input: SocialSignupInput!) {
      socialSignup(input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
        device {
          ...device
          user {
            ...user
          }
          socialService {
            ...socialService
          }
          deviceOrders {
            ...orderConnection
          }
        }
        prevLoggedAt
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialService}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  SOCIAL_SIGNIN: gql`
    mutation socialSigninDefault($input: SocialSigninInput!) {
      socialSignin(input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
        device {
          ...device
          user {
            ...user
          }
          socialService {
            ...socialService
          }
          deviceOrders {
            ...orderConnection
          }
        }
        isReactivate
        prevLoggedAt
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialService}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  SIGN_OUT: gql`
    mutation signOutDefault($input: SignOutInput!) {
      signOut(input: $input) {
        signOut
      }
    }
  `,
  LINK_ACCOUNT_BY_SOCIAL: gql`
    mutation linkAccountBySocialDefault($id: ID!, $input: LinkAccountBySocialInput!) {
      linkAccountBySocial(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  LINK_ACCOUNT_BY_EMAIL: gql`
    mutation linkAccountByEmailDefault($id: ID!, $input: LinkAccountByEmailInput!) {
      linkAccountByEmail(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  APPROVE_REQUEST: gql`
    mutation approveRequestDefault($id: ID!) {
      approveRequest(id: $id) {
        status {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  APPROVE_UPDATE_REQUEST: gql`
    mutation approveUpdateRequestDefault($id: ID!) {
      approveUpdateRequest(id: $id) {
        status {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  REJECT_REQUEST: gql`
    mutation rejectRequestDefault($id: ID!, $input: StatusInput!) {
      rejectRequest(id: $id, input: $input) {
        status {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  REJECT_UPDATE_REQUEST: gql`
    mutation rejectUpdateRequestDefault($id: ID!, $input: StatusInput!) {
      rejectUpdateRequest(id: $id, input: $input) {
        status {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SUSPEND_REQUEST: gql`
    mutation suspendRequestDefault($id: ID!) {
      suspendRequest(id: $id) {
        status {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  BAN_REQUEST: gql`
    mutation banRequestDefault($id: ID!, $input: StatusInput!) {
      banRequest(id: $id, input: $input) {
        status {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_REQUEST: gql`
    mutation deleteRequestDefault($id: ID!) {
      deleteRequest(id: $id) {
        status {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  APPROVE_REQUESTS: gql`
    mutation approveRequestsDefault($input: StatusesInput!) {
      approveRequests(input: $input) {
        statuses {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  REJECT_REQUESTS: gql`
    mutation rejectRequestsDefault($input: StatusesWithMessageInput!) {
      rejectRequests(input: $input) {
        statuses {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SUSPEND_REQUESTS: gql`
    mutation suspendRequestsDefault($input: StatusesInput!) {
      suspendRequests(input: $input) {
        statuses {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  BAN_REQUESTS: gql`
    mutation banRequestsDefault($input: StatusesWithMessageInput!) {
      banRequests(input: $input) {
        statuses {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_REQUESTS: gql`
    mutation deleteRequestsDefault($input: StatusesInput!) {
      deleteRequests(input: $input) {
        statuses {
          ...status
          product {
            ...product
          }
          productUpdateStatus {
            ...product
          }
          brand {
            ...brand
          }
          brandUpdateStatus {
            ...brand
          }
          influencer {
            ...influencer
          }
          seller {
            ...seller
          }
          sellerUpdateStatus {
            ...seller
          }
          statusStatusHistories {
            ...statusHistoryConnection
          }
          sellerChiefManager {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CREATE_SELLER_MANAGER: gql`
    mutation createSellerManagerDefault($input: SellerManagerInput!) {
      createSellerManager(input: $input) {
        sellerUser {
          ...sellerUser
          user {
            ...user
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerUser}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_SELLER_MANAGER: gql`
    mutation deleteSellerManagerDefault($input: SellerManagerInput!) {
      deleteSellerManager(input: $input) {
        sellerUser {
          ...sellerUser
          user {
            ...user
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerUser}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CHANGE_CHIEF_SELLER_MANAGER: gql`
    mutation changeChiefSellerManagerDefault($input: SellerManagerInput!) {
      changeChiefSellerManager(input: $input) {
        sellerUser {
          ...sellerUser
          user {
            ...user
          }
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerUser}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CREATE_SELLER_TEMPLATE_ADDRESS: gql`
    mutation createSellerTemplateAddressDefault($input: CreateSellerTemplateAddressInput!) {
      createSellerTemplateAddress(input: $input) {
        sellerTemplateAddress {
          ...sellerTemplateAddress
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddress}
  `,
  UPDATE_SELLER_TEMPLATE_ADDRESS: gql`
    mutation updateSellerTemplateAddressDefault($id: ID!, $input: UpdateSellerTemplateAddressInput!) {
      updateSellerTemplateAddress(id: $id, input: $input) {
        sellerTemplateAddress {
          ...sellerTemplateAddress
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddress}
  `,
  DELETE_SELLER_TEMPLATE_ADDRESS: gql`
    mutation deleteSellerTemplateAddressDefault($id: ID!) {
      deleteSellerTemplateAddress(id: $id) {
        sellerTemplateAddress {
          ...sellerTemplateAddress
          seller {
            ...seller
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddress}
  `,
  CREATE_CUSTOMER_TEMPLATE_ADDRESS: gql`
    mutation createCustomerTemplateAddressDefault($input: CreateCustomerTemplateAddressInput!) {
      createCustomerTemplateAddress(input: $input) {
        customerTemplateAddress {
          ...customerTemplateAddress
          user {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_CUSTOMER_TEMPLATE_ADDRESS: gql`
    mutation updateCustomerTemplateAddressDefault($id: ID!, $input: UpdateCustomerTemplateAddressInput!) {
      updateCustomerTemplateAddress(id: $id, input: $input) {
        customerTemplateAddress {
          ...customerTemplateAddress
          user {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DELETE_CUSTOMER_TEMPLATE_ADDRESS: gql`
    mutation deleteCustomerTemplateAddressDefault($id: ID!) {
      deleteCustomerTemplateAddress(id: $id) {
        customerTemplateAddress {
          ...customerTemplateAddress
          user {
            ...user
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_MILEAGE: gql`
    mutation updateMileageDefault($id: ID!, $input: MileageInput!) {
      updateMileage(id: $id, input: $input) {
        mileage {
          ...mileage
          user {
            ...user
          }
          mileageMileageHistories {
            ...mileageHistoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  UPDATE_MILEAGE_BY_EXCEL: gql`
    mutation updateMileageByExcelDefault($input: BaseImportByExcelInput!) {
      updateMileageByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  CREATE_AS_INFO: gql`
    mutation createAsInfoDefault($input: CreateAsInfoInput!) {
      createAsInfo(input: $input) {
        asInfo {
          ...asInfo
          seller {
            ...seller
          }
          productSet {
            ...productConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
  `,
  UPDATE_AS_INFO: gql`
    mutation updateAsInfoDefault($id: ID!, $input: UpdateAsInfoInput!) {
      updateAsInfo(id: $id, input: $input) {
        asInfo {
          ...asInfo
          seller {
            ...seller
          }
          productSet {
            ...productConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
  `,
  DELETE_AS_INFO: gql`
    mutation deleteAsInfoDefault($id: ID!) {
      deleteAsInfo(id: $id) {
        asInfo {
          ...asInfo
          seller {
            ...seller
          }
          productSet {
            ...productConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
  `,
  SEND_SMS: gql`
    mutation sendSmsDefault($input: SendSmsInput!) {
      sendSms(input: $input) {
        informSmsQueue {
          ...informSmsQueue
        }
      }
    }
    ${DEFAULT_FRAGMENTS.informSmsQueue}
  `,
  SEND_EMAIL: gql`
    mutation sendEmailDefault($input: SendEmailInput!) {
      sendEmail(input: $input) {
        informEmailQueue {
          ...informEmailQueue
          informEmailQueueInformEmailAttachments {
            ...informEmailAttachmentConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.informEmailAttachmentConnection}
    ${DEFAULT_FRAGMENTS.informEmailQueue}
  `,
  SEND_USER_CERT_NUMBER: gql`
    mutation sendUserCertNumberDefault($input: SendUserCertNumberInput!) {
      sendUserCertNumber(input: $input) {
        userCertification {
          ...userCertification
        }
      }
    }
    ${DEFAULT_FRAGMENTS.userCertification}
  `,
  CONFIRM_USER_CERT_NUMBER: gql`
    mutation confirmUserCertNumberDefault($id: ID!, $input: ConfirmUserCertNumberInput!) {
      confirmUserCertNumber(id: $id, input: $input) {
        userInfo {
          ...userInfo
          accounts {
            ...userInfoAccount
          }
        }
        userCertification {
          ...userCertification
        }
      }
    }
    ${DEFAULT_FRAGMENTS.userCertification}
    ${DEFAULT_FRAGMENTS.userInfo}
    ${DEFAULT_FRAGMENTS.userInfoAccount}
  `,
  RESET_PASSWORD: gql`
    mutation resetPasswordDefault($id: ID!, $input: ResetPasswordInput!) {
      resetPassword(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  CHECK_EMAIL_DUPLICATED: gql`
    mutation checkEmailDuplicatedDefault($input: CheckEmailDuplicatedInput!) {
      checkEmailDuplicated(input: $input) {
        isDuplicated
      }
    }
  `,
  UPDATE_AGREE_TERMS: gql`
    mutation updateAgreeTermsDefault($id: ID!, $input: UpdateAgreeTermsInput!) {
      updateAgreeTerms(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  LEAVE_USER: gql`
    mutation leaveUserDefault($id: ID!, $input: LeaveUserInput!) {
      leaveUser(id: $id, input: $input) {
        user {
          ...user
          influencer {
            ...influencer
          }
          grade {
            ...customerGrade
          }
          mileage {
            ...mileage
          }
          inviteUser {
            ...user
          }
          wishedProducts {
            ...productConnection
          }
          wishedBrands {
            ...brandConnection
          }
          invitedUser {
            ...userConnection
          }
          inactiveuser {
            ...inactiveUser
          }
          userProductQnas {
            ...productQnaConnection
          }
          answeredUserProductQnas {
            ...productQnaConnection
          }
          userServiceQnas {
            ...serviceQnaConnection
          }
          answeredUserServiceQnas {
            ...serviceQnaConnection
          }
          userReviews {
            ...reviewConnection
          }
          answeredUserReviews {
            ...reviewConnection
          }
          likedReviews {
            ...reviewConnection
          }
          createdUserSkus {
            ...skuConnection
          }
          updatedUserSkus {
            ...skuConnection
          }
          fitpetAdminSkuHistories {
            ...skuHistoryConnection
          }
          sellers {
            ...sellerConnection
          }
          userSellerUsers {
            ...sellerUserConnection
          }
          userDevices {
            ...deviceConnection
          }
          requestUserStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminStatusHistories {
            ...statusHistoryConnection
          }
          fitpetAdminMileageHistories {
            ...mileageHistoryConnection
          }
          userCustomerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          userSocialServices {
            ...socialServiceConnection
          }
          fitpetAdminInformPushQueues {
            ...informPushQueueConnection
          }
          userPushUserHistories {
            ...pushUserHistoryConnection
          }
          userExcelRequests {
            ...excelExportRequestConnection
          }
          userOrders {
            ...orderConnection
          }
          fitpetAdminCouponGroups {
            ...couponGroupConnection
          }
          userCoupons {
            ...couponConnection
          }
          fitpetAdminCoupons {
            ...couponConnection
          }
          userSubscriptions {
            ...userSubscriptionConnection
          }
          fitpetAdminOrderItemAdminNotes {
            ...orderItemAdminNoteConnection
          }
          fitpetAdminTransactions {
            ...transactionConnection
          }
          adminCustomerGrade {
            ...customerGradeConnection
          }
          userNotice {
            ...noticeConnection
          }
          userFaqs {
            ...faqConnection
          }
          cartItems {
            ...cartItemConnection
          }
          cartItemHistories {
            ...cartItemHistoryConnection
          }
          coupons {
            ...couponConnection
          }
          customerTemplateAddresses {
            ...customerTemplateAddressConnection
          }
          recentOrderCustomerAddress {
            ...customerAddress
          }
          inactiveUser {
            ...inactiveUser
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  LEAVE_USERS_BY_FITPET_ADMIN: gql`
    mutation leaveUsersByFitpetAdminDefault($input: LeaveUsersByFitpetAdminInput!) {
      leaveUsersByFitpetAdmin(input: $input) {
        count
      }
    }
  `,
  DELETE_INACTIVE_USERS: gql`
    mutation deleteInactiveUsersDefault($input: DeleteInactiveUsersInput!) {
      deleteInactiveUsers(input: $input) {
        count
      }
    }
  `,
  SEND_PUSH: gql`
    mutation sendPushDefault($input: SendPushInput!) {
      sendPush(input: $input) {
        informPushQueue {
          ...informPushQueue
          push {
            ...push
          }
          users {
            ...userConnection
          }
          fitpetAdmin {
            ...user
          }
          grades {
            ...customerGradeConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informPushQueue}
    ${DEFAULT_FRAGMENTS.push}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  READ_PUSH: gql`
    mutation readPushDefault($id: ID!) {
      readPush(id: $id) {
        push {
          ...push
          informpushqueue {
            ...informPushQueue
          }
          pushPushUserHistories {
            ...pushUserHistoryConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.informPushQueue}
    ${DEFAULT_FRAGMENTS.push}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
  `,
  UPDATE_PUSH: gql`
    mutation updatePushDefault($id: ID!, $input: SendPushInput!) {
      updatePush(id: $id, input: $input) {
        informPushQueue {
          ...informPushQueue
          push {
            ...push
          }
          users {
            ...userConnection
          }
          fitpetAdmin {
            ...user
          }
          grades {
            ...customerGradeConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informPushQueue}
    ${DEFAULT_FRAGMENTS.push}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  DELETE_PUSH: gql`
    mutation deletePushDefault($id: ID!) {
      deletePush(id: $id) {
        informPushQueue {
          ...informPushQueue
          push {
            ...push
          }
          users {
            ...userConnection
          }
          fitpetAdmin {
            ...user
          }
          grades {
            ...customerGradeConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informPushQueue}
    ${DEFAULT_FRAGMENTS.push}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  REQUEST_USER_EXCEL_FILE: gql`
    mutation requestUserExcelFileDefault($input: RequestExcelFileInput!) {
      requestUserExcelFile(input: $input) {
        isSuccess
      }
    }
  `,
}

export default DEFAULT_MUTATION_GQLS
