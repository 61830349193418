import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Radio } from 'antd'
import { RadioProps } from 'antd/lib/radio'

interface MRadioButtonProps extends RadioProps {
  children?: React.ReactNode
  width?: number
  marginRight?: number
}

const MRadioButton: FunctionComponent<MRadioButtonProps> = ({ children, width, marginRight, ...RadioButtonProps }) => {
  return (
    <StyledRadioButton width={width} marginRight={marginRight} {...RadioButtonProps}>
      {children}
    </StyledRadioButton>
  )
}

const StyledRadioButton = styled(Radio.Button)<MRadioButtonProps>`
  width: ${(props) => props.width}px;
  display: inline-flex;
  justify-content: center;
  border-radius: 4px !important;
`

export default MRadioButton
