import { gql } from '@apollo/client'
import ORDER_FRAGMENTS from 'containers/gqls/orders/order/fragments'

const SHIPPING_MUTATION_GQLS = {
  UPDATE_ORDER_CUSTOMER_ADDRESS: gql`
    mutation updateOrderCustomerAddress($id: ID!, $input: UpdateOrderCustomerAddressInput!) {
      updateOrderCustomerAddress(id: $id, input: $input) {
        order {
          ...orderOnly
        }
      }
    }
    ${ORDER_FRAGMENTS.orderOnly}
  `,
  START_SHIPPINGS: gql`
    mutation startShippings($input: ShippingsInput!) {
      startShippings(input: $input) {
        shippings {
          id
        }
      }
    }
  `,
  UPDATE_SHIPPINGS: gql`
    mutation updateShippings($input: ShippingsInput!) {
      updateShippings(input: $input) {
        shippings {
          id
        }
      }
    }
  `,
  UPDATE_SHIPPING_BY_EXCEL: gql`
    mutation updateShippingByExcel($input: BaseImportByExcelInput!) {
      updateShippingByExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
}

export default SHIPPING_MUTATION_GQLS
