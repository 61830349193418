import { Transfer, Table } from 'antd'
import difference from 'lodash/difference'
import { TransferProps } from 'antd/lib/transfer'
import { TypeUseModalProps } from 'containers/hooks/useModal'
import { ColumnType } from 'rc-table/lib/interface'
import { FC, useState } from 'react'

export type TableTransferColumn<RecordType> = {
  dataIndex?: string
  title?: string
  width?: number
  render?: ColumnType<RecordType>['render']
}

type MTableTransferProps<RecordType> = {
  leftColumns: TableTransferColumn<RecordType>[]
  rightColumns: TableTransferColumn<RecordType>[]
  onTransfer?: (selectedKeys: string[]) => void
  disableCheckedNumber?: number
  useModalProps?: TypeUseModalProps<any>
  totalCount?: number
  initialTargetKeys?: string[]
} & TransferProps<any>

const MTableTransfer: FC<MTableTransferProps<any>> = ({
  leftColumns,
  rightColumns,
  onTransfer,
  disableCheckedNumber,
  useModalProps,
  totalCount: _totalCount,
  initialTargetKeys = [],
  ...transferProps
}) => {
  const totalCount = _totalCount || transferProps.dataSource.length
  const [targetKeys, setTargetKeys] = useState<string[]>(initialTargetKeys)

  const onChange = (nextTargetKeys: string[]) => {
    disableComplete(nextTargetKeys.length)
    setTargetKeys(nextTargetKeys)
    if (onTransfer) {
      onTransfer(nextTargetKeys)
    }
  }

  const disableComplete = (count: number = 0) => {
    if (!useModalProps) {
      return
    }
    const { modalDisable } = useModalProps?.data
    if (disableCheckedNumber) {
      if (count > disableCheckedNumber! && !modalDisable) {
        useModalProps!.setData({ modalDisable: true })
      }
      if (count < disableCheckedNumber! + 1 && modalDisable) {
        useModalProps!.setData({ modalDisable: false })
      }
    }
  }

  // const onSelectChange = (sourceSelectedKeys: string[]) => {}

  return (
    <Transfer
      {...transferProps}
      showSelectAll={false}
      targetKeys={targetKeys}
      onChange={onChange}
      // onSelectChange={onSelectChange}
      {...(totalCount && {
        selectAllLabels: [
          ({ selectedCount }) => (
            <span>
              {' '}
              {selectedCount} of {totalCount}{' '}
            </span>
          ),
          ({ selectedCount }) => (
            <span>
              {' '}
              {selectedCount} of {totalCount}{' '}
            </span>
          ),
        ],
      })}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const rowSelection = {
          getCheckboxProps: (item: any) => {
            return { disabled: listDisabled || item.disabled }
          },
          onSelectAll(selected: any, selectedRows: any) {
            const treeSelectedKeys = selectedRows
              .filter((item: any) => item && !item.disabled)
              .map(({ key }: any) => key)
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys)
            onItemSelectAll(diffKeys, selected)
          },
          onSelect({ key }: any, selected: any) {
            onItemSelect(key, selected)
          },
          selectedRowKeys: listSelectedKeys,
        }

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            onRow={({ key, disabled: itemDisabled }: { key: any; disabled: boolean }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return
                onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
            pagination={{ position: ['bottomCenter'] }}
          />
        )
      }}
    </Transfer>
  )
}

export default MTableTransfer
