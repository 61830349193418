import { IModelProduct } from 'containers/models/modelProduct'
import { IModelProductOptionSku } from 'containers/models/modelProductOptionSku'
import { IModelProductOptionValue } from 'containers/models/modelProductOptionValue'
import { IModelProductOptionDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelProductOptionDefault } from 'containers/models/base/defaultModels'
import { IModelSku } from 'containers/models/modelSku'

export interface IModelProductOption extends IModelProductOptionDefault {
  optionGroupsText: string
  optionValuesText: string
  product: IModelProduct
  productOptionKindsText: string
  productOptionSkus: IModelProductOptionSku[]
  productOptionValues: IModelProductOptionValue[]
  simpleProductOptionKinds: string
  skus: IModelSku[]
}

export default class ModelProductOption extends ModelProductOptionDefault {
  productOptionKindsText: string = ''
  simpleProductOptionKinds: string = ''
  optionGroupsText: string
  optionValuesText: string

  constructor(data: IModelProductOption) {
    super(data)
    this.simpleProductOptionKinds = this.getSimpleOptionText()
    this.productOptionKindsText = this.getOptionKindText()
    this.optionGroupsText = this.getOptionGroupsText()
    this.optionValuesText = this.getOptionValuesText()
  }

  private getOptionKindText = () => {
    const { productOptionKinds } = this
    if (!productOptionKinds || !productOptionKinds.length) return ''

    const optionKey: string = productOptionKinds[0].key.toString()
    const optionValue: string = productOptionKinds[0].value.toString()
    if (optionValue?.indexOf(':') !== -1) return optionValue.replace(':', ' / ')

    const kindText: string = `${optionKey} : ${optionValue}`
    return kindText
  }

  private getSimpleOptionText = () => {
    const { productOptionKinds } = this

    if (!productOptionKinds || !productOptionKinds.length) return '/'

    // convert : to /
    const optionValue: string = productOptionKinds[0].value.toString()
    if (optionValue?.indexOf(':') !== -1) {
      return optionValue.replace(':', ' / ')
    }

    return optionValue
  }

  private getOptionGroupsText = () => {
    const { productOptionKinds } = this
    return productOptionKinds?.reduce((acc, current) => {
      return acc ? `${acc}/${current.key}` : current.key
    }, '')
  }

  private getOptionValuesText = () => {
    const { productOptionKinds } = this
    return productOptionKinds?.reduce((acc, current) => {
      return acc ? `${acc}/${current.value}` : current.value
    }, '')
  }
}
