import _ from 'lodash'
import { MutationFunction } from '@apollo/client'
import { message } from 'antd'

import * as utilData from '@libs/utils/utilData'
import { IModelBrand } from 'containers/models/modelBrand'
import { IModelNotice } from 'containers/models/modelNotice'
import { IModelReview } from 'containers/models/modelReview'
import { IModelProductQna } from 'containers/models/modelProductQna'
import { IModelUser } from 'containers/models/modelUser'
import { DomainModalProps } from 'containers/hooks/useModal'
import { MConfirmTableModal } from 'components/modals'
import MMutation from 'components/MMutation'
import { MConfirmTableModalFixedProps, MConfirmTableModalProps } from 'components/modals/MConfirmTableModal'
import { IModelProduct } from 'containers/models/modelProduct'
import SubComponent from 'domains/common/SubComponentProvider'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { IModelDisplayProductInfo } from 'containers/models/modelDisplayProductInfo'
import { MUTATION_KEY2GQLS } from 'containers/gqls'

type mutationOptionProps = {
  gqlKey:
    | 'approveRequests'
    | 'deleteDisplayProductInfos'
    | 'deleteIncompleteProducts'
    | 'deleteNotices'
    | 'deleteProductQuestions'
    | 'deleteRequests'
    | 'deleteReviews'
    | 'suspendRequests'
    | 'updateProductsSoldOut'
    | 'leaveUsersByFitpetAdmin'
    | 'deleteInactiveUsers'
    | 'deleteServiceQuestions'
  variableKey: string
}

type componentOptionProps = {
  descriptionComponentKey?: string
  tableComponentKey?: string
  extraCols?: string[]
  Component?: JSX.Element
}

type FixedProps = MConfirmTableModalFixedProps & {
  mutationKey: mutationOptionProps
  componentOption?: componentOptionProps
}

export type ModalConfirmTableProps = MConfirmTableModalProps & {
  brands?: IModelBrand[]
  displayProductInfos?: IModelDisplayProductInfo[]
  notices?: IModelNotice[]
  productQnas?: IModelProductQna[]
  products?: IModelProduct[]
  reviews?: IModelReview[]
  users?: IModelUser[]
}

const ModalConfirmTable = ({
  useModalProps,
  fixedProps,
  onAction,
  onAPISuccess,
}: DomainModalProps<ModalConfirmTableProps, FixedProps>) => {
  const { isShowing, data } = useModalProps
  const { brands, notices, users, products, reviews, productQnas, displayProductInfos } = data
  let possibleProducts: IModelProduct[] | undefined
  const { mutationKey, title, description, componentOption } = fixedProps
  const { gqlKey, variableKey } = mutationKey
  if (!gqlKey) return null
  if (
    isShowing &&
    !(
      utilData.hasData(products) ||
      utilData.hasData(brands) ||
      utilData.hasData(notices) ||
      utilData.hasData(users) ||
      utilData.hasData(reviews) ||
      utilData.hasData(productQnas) ||
      utilData.hasData(displayProductInfos)
    )
  ) {
    return null
  }

  const setProducts: Map<string, IModelProduct[]> = new Map()
    .set('productApproveRequests', products)
    .set(
      'productUnsuspendRequests',
      _.filter(products, (product) => product.status.value === 'SUSPEND')
    )
    .set(
      'productDeleteRequests',
      _.filter(products, (product) => product.status.value !== 'DELETED')
    )
    .set(
      'productSuspendRequests',
      _.filter(products, (product) => product.status.value !== 'SUSPEND')
    )
    .set('productUpdateProductsSoldOut', _.filter(products, ['isSoldOut', false]))

  if (utilData.hasData(products)) {
    possibleProducts = setProducts.get(variableKey)
  }

  const setDescriptions = (): string | undefined => {
    if (utilData.hasData(products)) {
      if (data.selectedLength !== possibleProducts?.length) {
        if (variableKey === 'productUpdateProductsSoldOut')
          return `선택된 목록 중 품절처리 가능한 상품 ${possibleProducts!.length}개를 품절처리합니다.`
        if (variableKey === 'productDeleteRequests')
          return `선택된 목록 중 삭제가능한 상품 ${
            possibleProducts!.length
          }개를 삭제합니다. 삭제된 상품은 삭제 리스트로 이동합니다.`
        if (variableKey === 'productSuspendRequests')
          return `선택된 목록 중 판매중지 가능한 상품 ${possibleProducts!.length}개를 판매중지합니다.`
        if (variableKey === 'productUnsuspendRequests')
          return `선택된 목록 중 판매재개 가능한 상품 ${possibleProducts!.length}개를 판매재개합니다.`
      } else {
        if (variableKey === 'productUpdateProductsSoldOut') return '선택된 상품을 품절 처리합니다.'
        if (variableKey === 'productDeleteRequests')
          return '해당 상품을 삭제합니다. 삭제된 상품은 삭제 리스트로 이동합니다.'
        if (variableKey === 'productSuspendRequests') return '선택된 상품을 판매 중지합니다.'
      }
    }
    return description
  }

  const getVariables = () => {
    if (variableKey === 'productApproveRequests' || variableKey === 'productUnsuspendRequests') {
      return MUTATION_VARIABLES.APPROVE_REQUESTS({ statuses: _.map(possibleProducts, (product) => product.status.id) })
    }
    if (variableKey === 'productDeleteRequests') {
      return MUTATION_VARIABLES.DELETE_REQUESTS({ statuses: _.map(possibleProducts, (product) => product.status.id) })
    }
    if (variableKey === 'productSuspendRequests') {
      return MUTATION_VARIABLES.SUSPEND_REQUESTS({ statuses: _.map(possibleProducts, (product) => product.status.id) })
    }
    if (variableKey === 'productUpdateProductsSoldOut') {
      return MUTATION_VARIABLES.UPDATE_PRODUCTS_SOLD_OUT({ isSoldOut: true, products: _.map(possibleProducts, 'id') })
    }
    if (variableKey === 'brandDeleteRequests') {
      return MUTATION_VARIABLES.DELETE_REQUESTS({ statuses: _.map(brands, (brand) => brand.status.id) })
    }
    if (variableKey === 'noticeDeleteRequests') {
      return MUTATION_VARIABLES.DELETE_NOTICES({ ids: _.map(notices, (notice) => notice.id) })
    }
    if (variableKey === 'userDeleteRequests') {
      return MUTATION_VARIABLES.LEAVE_USERS_BY_FITPET_ADMIN({ ids: _.map(users, (user) => user.id) })
    }
    if (variableKey === 'productQnaDeleteRequests') {
      return MUTATION_VARIABLES.DELETE_PRODUCT_QUESTIONS({ ids: _.map(productQnas, (productQna) => productQna.id) })
    }
    if (variableKey === 'deleteReviews') {
      return MUTATION_VARIABLES.DELETE_REVIEWS({ ids: reviews!.map((x) => x.id) })
    }
    if (variableKey === 'deleteIncompleteProducts') {
      return MUTATION_VARIABLES.DELETE_INCOMPLETE_PRODUCTS({ ids: products!.map((x) => x.id) })
    }
    if (variableKey === 'inactiveUserDeleteRequests') {
      return MUTATION_VARIABLES.DELETE_INACTIVE_USERS({
        ids: users!.filter((user) => user.inactiveuser.id != null).map((user) => user.inactiveuser.id),
      })
    }
    if (variableKey === 'deleteDisplayProductInfos') {
      return MUTATION_VARIABLES.DELETE_DISPLAY_PRODUCT_INFOS({ ids: displayProductInfos!.map((x) => x.id) })
    }
  }

  const _onAction = async (mutation: MutationFunction) => {
    const variables = getVariables()
    if (variableKey === 'inactiveUserDeleteRequests' && variables.input.ids.length === 0) {
      message.warning('이미 삭제된 유저입니다.')
      return
    }

    await mutation({ variables })
    if (onAction) {
      // onAPISuccess 에서 사용하려고 했지만, 호출이 안됨?
      onAction()
    }
  }

  const _onAPISuccess = (res: any) => {
    if (onAPISuccess) onAPISuccess(res)
  }

  return (
    <MMutation gqlKey={gqlKey as keyof typeof MUTATION_KEY2GQLS} onAPISuccess={_onAPISuccess}>
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <MConfirmTableModal
            useModalProps={useModalProps}
            onAction={() => _onAction(mutation)}
            fixedProps={{
              title,
              description: setDescriptions(),
              loading,
              Component: SubComponent({ componentOption, targetModel: data }),
            }}
          />
        )
      }}
    </MMutation>
  )
}

export default ModalConfirmTable
