import { MTable } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import tableCols from 'domains/admin/tableCols'

const SellerTableProcessOrder = ({
  orderItems,
  disabledAll,
}: {
  orderItems: IModelOrderItem[]
  disabledAll: boolean
}) => {
  const tableColumns: Array<ITableColumn<IModelOrderItem>> = [
    tableCols.common.baseTextFromModel({ title: '주문번호', dataIndex: 'order', key: 'orderNumber' }),
    tableCols.common.baseText({ title: '상품주문번호', dataIndex: 'orderItemNumber' }),
    tableCols.common.baseText({ title: '상품명', dataIndex: ['productOption', 'product', 'name'] }),
    tableCols.order.orderStatusText(),
    tableCols.orderItem.formItemSelectShippingCompany(disabledAll),
    tableCols.orderItem.formItemShippingNumber(disabledAll),
  ]
  return <MTable columns={tableColumns} dataSource={orderItems} scroll={600} pagination={false} />
}

export default SellerTableProcessOrder
