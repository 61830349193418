import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { ReactNode } from 'react'
import MQuery from 'components/MQuery'
import { MFormItemTreeSelect } from 'components'
import { IModelCategory } from 'containers/models/modelCategory'
import { FIELD_KEYS } from '@constants/constData'

export type TypeCategoryData = {
  value?: string | number
  title?: ReactNode
  label?: ReactNode
  key?: string | number
  disabled?: boolean
  disableCheckbox?: boolean
  checkable?: boolean
  children?: TypeCategoryData[]
  [prop: string]: any
}

export const convertCategoryIntoTreeData = (categories: IModelCategory[]): TypeCategoryData[] => {
  const topCategories = categories.filter((category) => !category.parentCategory?.id)
  const treeDataList: TypeCategoryData[] = []

  const setChildCategories = (
    parentCategoryId: string | number | undefined,
    allCategories: IModelCategory[],
    parentTreeData: TypeCategoryData
  ) => {
    const childCategories = allCategories.filter((childCategory) => {
      return childCategory?.parentCategory?.id === parentCategoryId
    })
    if (!childCategories) return

    childCategories.forEach((category) => {
      const _treeData: TypeCategoryData = {
        key: category.id,
        label: category.name,
        value: category.id,
        [FIELD_KEYS.FIELD_TREE_NODE_LABEL]: category.text,
      }
      const hasChildren =
        allCategories.findIndex((childCategory) => childCategory?.parentCategory?.id === category.id) > -1
      if (hasChildren) {
        _treeData.children = []
      }
      parentTreeData?.children?.push(_treeData)
      setChildCategories(category.id, allCategories, _treeData)
    })
  }

  topCategories.forEach((category) => {
    const treeData = {
      key: category.id,
      label: category.name,
      value: category.id,
      [FIELD_KEYS.FIELD_TREE_NODE_LABEL]: category.text,
      children: [],
    }
    treeDataList.push(treeData)
  })

  treeDataList.forEach((treeData) => {
    setChildCategories(treeData?.value, categories, treeData)
  })
  return treeDataList
}

const FormItemSelectCategory = ({
  disabled,
  required,
  onLoad,
  label,
  ...formItemWrapperProps
}: MFormItemWrapperProps & {
  onLoad?: Function
}) => {
  return (
    <MQuery
      queryParams={{
        gqlKey: 'categoriesForTreeSelect',
        variables: { pageInfo: { isUnlimited: true } },
      }}
      onAPISuccess={(data: any) => {
        if (onLoad) {
          onLoad(data?.categories?.data)
        }
      }}
    >
      {({ data }) => {
        const categories = data.categories.data
        const treeData = convertCategoryIntoTreeData(categories)
        return (
          <MFormItemTreeSelect
            treeData={treeData}
            disabled={disabled}
            required={required}
            label={label}
            placeholder="카테고리를 선택해주세요"
            {...formItemWrapperProps}
          />
        )
      }}
    </MQuery>
  )
}

export default FormItemSelectCategory
