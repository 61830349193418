import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const REVIEW_QUERY_GQLS = {
  REVIEW_LIST: gql`
    query reviewList($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      reviews(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...review
            user {
              id
              name
              email
              mobileNumber
            }
            product {
              id
              name
              realName
              reviewGroup {
                id
              }
            }
            orderItem {
              id
              order {
                id
                orderNumber
              }
            }
            reviewReviewImages {
              edges {
                node {
                  id
                  image
                  thumbnail
                }
              }
            }
            reviewMileageHistories {
              edges {
                node {
                  id
                  mileageType
                  afterMileage
                  beforeMileage
                  mileage {
                    id
                    point
                  }
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.review}
  `,
  REVIEW_GROUP_LIST: gql`
    query reviewGroupList($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      reviewGroups(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            id
            name
            productCount
            totalCount
            averageScore
            reviewGroupProducts {
              edges {
                node {
                  id
                  name
                  isRealSoldOut
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default REVIEW_QUERY_GQLS
