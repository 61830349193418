import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const BANNER_MUTATION_GQLS = {
  CREATE_SLIM_BANNER: gql`
    mutation createSlimBanner($input: BannerInput!) {
      createSlimBanner(input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  CREATE_TOP_ROLL_BANNER: gql`
    mutation createTopRollBanner($input: BannerInput!) {
      createTopRollBanner(input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  UPDATE_SLIM_BANNER: gql`
    mutation updateSlimBanner($id: ID!, $input: BannerInput!) {
      updateSlimBanner(id: $id, input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  UPDATE_TOP_ROLL_BANNER: gql`
    mutation updateTopRollBanner($id: ID!, $input: BannerInput!) {
      updateTopRollBanner(id: $id, input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  CREATE_HOME_MIDDLE_BANNER: gql`
    mutation createHomeMiddleBannerDefault($input: BannerInput!) {
      createHomeMiddleBanner(input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  UPDATE_HOME_MIDDLE_BANNER: gql`
    mutation updateHomeMiddleBannerDefault($id: ID!, $input: BannerInput!) {
      updateHomeMiddleBanner(id: $id, input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  CREATE_HOME_BOTTOM_BANNER: gql`
    mutation createHomeBottomBannerDefault($input: BannerInput!) {
      createHomeBottomBanner(input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  UPDATE_HOME_BOTTOM_BANNER: gql`
    mutation updateHomeBottomBannerDefault($id: ID!, $input: BannerInput!) {
      updateHomeBottomBanner(id: $id, input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  CREATE_PRODUCT_TOP_BANNER: gql`
    mutation createProductTopBannerDefault($input: BannerInput!) {
      createProductTopBanner(input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  UPDATE_PRODUCT_TOP_BANNER: gql`
    mutation updateProductTopBannerDefault($id: ID!, $input: BannerInput!) {
      updateProductTopBanner(id: $id, input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  CREATE_HOME_MULTI_LINE_BANNER: gql`
    mutation createHomeMultiLineBanner($input: BannerInput!) {
      createHomeMultiLineBanner(input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  UPDATE_HOME_MULTI_LINE_BANNER: gql`
    mutation updateHomeMultiLineBanner($id: ID!, $input: BannerInput!) {
      updateHomeMultiLineBanner(id: $id, input: $input) {
        banner {
          ...banner
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
  `,
  DELETE_BANNERS: gql`
    mutation deleteBanners($input: DeleteBannersInput!) {
      deleteBanners(input: $input) {
        count
      }
    }
  `,
  UPDATE_DISPLAY_ORDER_FOR_BANNER: gql`
    mutation updateDisplayOrderForBanner($id: ID!, $input: UpdateDisplayOrderInput!) {
      updateDisplayOrderForBanner(id: $id, input: $input) {
        id
        displayOrder
      }
    }
  `,
}

export default BANNER_MUTATION_GQLS
