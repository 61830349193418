import { FC } from 'react'
import { Checkbox } from 'antd'
import { MFormItemWrapper } from 'components/formItems'
import { CSSObject } from 'styled-components'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'

type MFormItemCheckboxItemProps = MFormItemWrapperProps & {
  text: string | JSX.Element
  onChange?: (value: boolean) => void
  checked?: boolean
  disabled?: boolean
  style?: CSSObject
}

const MFormItemCheckboxSingle: FC<MFormItemCheckboxItemProps> = (props) => {
  const { text, onChange, disabled, name, ...restProps } = props
  return (
    <MFormItemWrapper
      name={name}
      valuePropName="checked"
      getValueFromEvent={(e) => {
        return e.target.checked
      }}
      {...restProps}
    >
      <Checkbox
        disabled={disabled}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.checked)
          }
        }}
      >
        {text}
      </Checkbox>
    </MFormItemWrapper>
  )
}

export default MFormItemCheckboxSingle
