import { useState } from 'react'
import {
  CheckCircleOutlined,
  DashboardOutlined,
  EditOutlined,
  EllipsisOutlined,
  GiftOutlined,
  InfoCircleOutlined,
  PhoneOutlined,
  SolutionOutlined,
  StarOutlined,
  TableOutlined,
  WarningOutlined,
} from '@ant-design/icons'

import { MMenuSider } from 'components/@atomics'
import { RcMenuInfo } from 'domains/common/menus'
import ROUTES, { ROUTE_KEYS } from '@constants/constRoutes'
import { redirectManageProductUrlV3 } from '@libs/utils/utilRedirect'

const adminMenuSiderConfig = [
  {
    id: ROUTE_KEYS.PARTNERS,
    title: '파트너 관리',
    icon: <DashboardOutlined />,
    children: [
      { id: ROUTE_KEYS.PARTNER_LIST, title: '전체 사용자 리스트', url: ROUTES.PARTNERS.PARTNER_LIST },
      { id: ROUTE_KEYS.SELLER_REQUEST_LIST, title: '셀러 신청 리스트', url: ROUTES.PARTNERS.SELLER_REQUEST_LIST },
      { id: ROUTE_KEYS.SELLER_LIST, title: '셀러 리스트', url: ROUTES.PARTNERS.SELLER_LIST },
      { id: ROUTE_KEYS.BRAND_LIST, title: '브랜드 정보 관리', url: ROUTES.PARTNERS.BRAND_LIST },
    ],
  },
  {
    id: ROUTE_KEYS.CUSTOMERS,
    title: '회원 관리',
    icon: <PhoneOutlined />,
    children: [
      { id: ROUTE_KEYS.USER_LIST, title: '회원 리스트', url: ROUTES.ACCOUNTS.USER_LIST },
      { id: ROUTE_KEYS.LEFT_USER_LIST, title: '탈퇴 회원 리스트', url: ROUTES.ACCOUNTS.LEFT_USER_LIST },
      // 론칭 때 회원 등급 관리 제외 -> 등록 및 삭제 로직 확인
      // { id: ROUTE_KEYS.CUSTOMER_GRADE, title: '회원 등급 관리', url: ROUTES.ACCOUNTS.CUSTOMER_GRADE },
      { id: ROUTE_KEYS.EMAIL, title: '메일 발송', url: ROUTES.ACCOUNTS.EMAIL },
      { id: ROUTE_KEYS.SMS, title: 'SMS 발송', url: ROUTES.ACCOUNTS.SMS },
      // { id: ROUTE_KEYS.PUSH_LIST, title: '푸시 발송 관리', url: ROUTES.ACCOUNTS.PUSH_LIST },
      // { id: ROUTE_KEYS.PUSH_SEND, title: '푸시 신규발송', url: ROUTES.ACCOUNTS.PUSH_SEND },
    ],
  },
  {
    id: ROUTE_KEYS.MEMBERSHIP,
    title: '멤버십 관리',
    icon: <StarOutlined />,
    children: [
      {
        id: ROUTE_KEYS.MEMBERSHIP_USER_LIST,
        title: '회원 리스트',
        url: ROUTES.MEMBERSHIP.MEMBERSHIP_USER_LIST,
      },
    ],
  },
  {
    id: ROUTE_KEYS.PRODUCTS,
    title: '상품 관리',
    icon: <EditOutlined />,
    children: [
      { id: ROUTE_KEYS.PRODUCT_LIST_REQUEST, title: '상품 신청 리스트', url: ROUTES.PRODUCTS.PRODUCT_LIST_REQUEST },
      { id: ROUTE_KEYS.PRODUCT_LIST, title: '상품 리스트', url: ROUTES.PRODUCTS.PRODUCT_LIST },
      { id: ROUTE_KEYS.PRODUCT_LIST_DELETE, title: '삭제 상품 리스트', url: ROUTES.PRODUCTS.PRODUCT_LIST_DELETE },
      { id: ROUTE_KEYS.PRODUCT_ADD, title: '상품 등록', url: ROUTES.PRODUCTS.PRODUCT_ADD },
      {
        id: ROUTE_KEYS.PRODUCT_EXCEL_UPLOAD,
        title: '엑셀 업로드/임시 등록 관리',
        url: ROUTES.PRODUCTS.PRODUCT_EXCEL_UPLOAD,
      },
      { id: ROUTE_KEYS.CATEGORY, title: '카테고리 관리', url: ROUTES.PRODUCTS.CATEGORY },
    ],
  },
  {
    id: ROUTE_KEYS.ORDERS,
    title: '주문 관리',
    icon: <SolutionOutlined />,
    children: [
      { id: ROUTE_KEYS.ORDER_LIST_ALL, title: '전체 주문 조회', url: ROUTES.ORDERS.ORDER_LIST_ALL },
      {
        id: ROUTE_KEYS.ORDER_LIST_UNCONFIRMED_PAYMENT,
        title: '미결제 확인',
        url: ROUTES.ORDERS.ORDER_LIST_UNCONFIRMED_PAYMENT,
      },
      { id: ROUTE_KEYS.ORDER_LIST_NEW_ORDER, title: '신규 주문 / 출고 관리', url: ROUTES.ORDERS.ORDER_LIST_NEW_ORDER },
      { id: ROUTE_KEYS.ORDER_LIST_SHIPPING, title: '배송현황 관리', url: ROUTES.ORDERS.ORDER_LIST_SHIPPING },
      { id: ROUTE_KEYS.ORDER_LIST_CONFIRMED, title: '구매확정 내역', url: ROUTES.ORDERS.ORDER_LIST_CONFIRMED },
      { id: ROUTE_KEYS.ORDER_LIST_CANCELED, title: '취소 관리', url: ROUTES.ORDERS.ORDER_LIST_CANCELED },
      { id: ROUTE_KEYS.ORDER_LIST_RETURN, title: '반품 관리', url: ROUTES.ORDERS.ORDER_LIST_RETURN },
      { id: ROUTE_KEYS.ORDER_LIST_EXCHANGE, title: '교환 관리', url: ROUTES.ORDERS.ORDER_LIST_EXCHANGE },
    ],
  },
  {
    id: ROUTE_KEYS.BILLINGS,
    title: '정산 관리',
    icon: <CheckCircleOutlined />,
    children: [
      { id: ROUTE_KEYS.SELLER_BILLING_LIST, title: '셀러 정산 리스트', url: ROUTES.BILLINGS.SELLER_BILLING_LIST },
    ],
  },
  {
    id: ROUTE_KEYS.PROMOTIONS,
    title: '프로모션 관리',
    icon: <GiftOutlined />,
    children: [
      { id: ROUTE_KEYS.PROMOTION_COUPON_ADD, title: '쿠폰 등록', url: ROUTES.PROMOTIONS.COUPON_ADD },
      { id: ROUTE_KEYS.PROMOTION_COUPON_LIST, title: '쿠폰 리스트', url: ROUTES.PROMOTIONS.COUPON_LIST },
      {
        id: ROUTE_KEYS.PROMOTION_COUPON_LIST_ISSUED,
        title: '쿠폰 발급 리스트',
        url: ROUTES.PROMOTIONS.COUPON_LIST_ISSUED,
      },
      {
        id: ROUTE_KEYS.EXCEL_IMPORT_REQUESTS_COUPON,
        title: '쿠폰 엑셀 등록 현황',
        url: ROUTES.PROMOTIONS.EXCEL_IMPORT_REQUESTS_COUPON,
      },
      { id: ROUTE_KEYS.PROMOTION_MILEAGE_CONFIG, title: '적립금 관리', url: ROUTES.PROMOTIONS.MILEAGE_CONFIG },
      {
        id: ROUTE_KEYS.PROMOTION_MILEAGE_HISTORY_LIST,
        title: '적립금 내역 관리',
        url: ROUTES.PROMOTIONS.MILEAGE_HISTORY_LIST,
      },
    ],
  },
  {
    id: ROUTE_KEYS.BOARDS,
    title: '문의/리뷰 관리',
    icon: <WarningOutlined />,
    children: [
      { id: ROUTE_KEYS.BOARD_LIST_PRODUCT_QNA, title: '상품 문의 리스트', url: ROUTES.BOARDS.BOARD_LIST_PRODUCT_QNA },
      { id: ROUTE_KEYS.BOARD_LIST_REVIEW_GROUP, title: '리뷰 그룹 리스트', url: ROUTES.BOARDS.BOARD_LIST_REVIEW_GROUP },
      { id: ROUTE_KEYS.BOARD_LIST_REVIEW, title: '리뷰 리스트', url: ROUTES.BOARDS.BOARD_LIST_REVIEW },
    ],
  },
  {
    id: ROUTE_KEYS.STATISTICS,
    title: '통계',
    icon: <CheckCircleOutlined />,
    children: [
      { id: ROUTE_KEYS.DAILY_REPORT, title: '일간 리포트', url: ROUTES.STATISTICS.DAILY_REPORT },
      { id: ROUTE_KEYS.WEEKLY_REPORT, title: '주간 리포트', url: ROUTES.STATISTICS.WEEKLY_REPORT },
      { id: ROUTE_KEYS.MONTHLY_REPORT, title: '월간 리포트', url: ROUTES.STATISTICS.MONTHLY_REPORT },
      { id: ROUTE_KEYS.ANALYSIS_SELLER, title: '셀러 분석', url: ROUTES.STATISTICS.ANALYSIS_SELLER },
      {
        id: ROUTE_KEYS.ANALYSIS_PRODUCT_BY_SELLER,
        title: '셀러별 상품 분석',
        url: ROUTES.STATISTICS.ANALYSIS_PRODUCT_BY_SELLER,
      },
    ],
  },
  {
    id: ROUTE_KEYS.NOTICES,
    title: '게시판 관리',
    icon: <InfoCircleOutlined />,
    children: [
      { id: ROUTE_KEYS.NOTICE_LIST_SELLER, title: '셀러 공지사항 관리', url: ROUTES.NOTICES.NOTICE_LIST_SELLER },
    ],
  },
  {
    id: ROUTE_KEYS.LAYOUTS,
    title: '홈 관리',
    icon: <TableOutlined />,
    children: [
      { id: ROUTE_KEYS.LAYOUT_EVENT_LIST, title: '이벤트 관리', url: ROUTES.LAYOUTS.EVENT_LIST },
      { id: ROUTE_KEYS.LAYOUT_BANNER_LIST, title: '배너 리스트', url: ROUTES.LAYOUTS.LAYOUT_BANNER_LIST },
      // { id: ROUTE_KEYS.LAYOUT_BRAND_LIST, title: '브랜드 리스트', url: ROUTES.LAYOUTS.LAYOUT_BRAND_LIST },
      {
        id: ROUTE_KEYS.LAYOUT_CATEGORY_EXPOSE_LIST,
        title: '카테고리 노출 관리',
        url: ROUTES.LAYOUTS.LAYOUT_CATEGORY_EXPOSE_LIST,
      },
      {
        id: ROUTE_KEYS.LAYOUT_DISPLAY_COLLECTION_LIST,
        title: '섹션 관리',
        url: ROUTES.LAYOUTS.LAYOUT_DISPLAY_COLLECTION_LIST,
      },
      {
        id: ROUTE_KEYS.LAYOUT_TODAY_SPECIAL_PRICE_LIST,
        title: 'Today 특가 관리',
        url: ROUTES.LAYOUTS.LAYOUT_TODAY_SPECIAL_PRICE_LIST,
      },
      { id: ROUTE_KEYS.HOME_BRANDS, title: '브랜드 홈 노출 관리', url: ROUTES.LAYOUTS.HOME_BRANDS },
    ],
  },
  {
    id: ROUTE_KEYS.ETC,
    title: '기타 관리',
    icon: <EllipsisOutlined />,
    children: [
      { id: ROUTE_KEYS.EXCEL_REQUEST_LIST, title: '엑셀 다운로드', url: ROUTES.ETC.EXCEL_REQUEST_LIST },
      { id: ROUTE_KEYS.REMOTE_AREA_LIST, title: '도서산간 배송비 관리', url: ROUTES.ETC.REMOTE_AREA_LIST },
      { id: ROUTE_KEYS.MANAGE_SEARCH_TOKEN, title: '검색 사전 관리', url: ROUTES.ETC.MANAGE_SEARCH_TOKEN },
      { id: ROUTE_KEYS.SKU_STOCK_LIST, title: 'SKU 재고 관리', url: ROUTES.ETC.SKU_STOCK_LIST },
      { id: ROUTE_KEYS.EXCEL_REQUEST_ORDER, title: '재무용 주문내역 다운로드', url: ROUTES.ETC.EXCEL_REQUEST_ORDER },
      { id: ROUTE_KEYS.APP_VERSION, title: '앱 버전 관리', url: ROUTES.ETC.APP_VERSION },
    ],
  },
]

const MenuSiderAdmin = () => {
  const [curKey, setCurKey] = useState('')
  const handleClick = (e: RcMenuInfo): void => {
    const pathname = `/${e.keyPath.reverse().join('/')}`

    redirectManageProductUrlV3(pathname)

    setCurKey(e.key as string)
  }

  return <MMenuSider curSelectedKeys={curKey} onClick={handleClick} menuConfig={adminMenuSiderConfig} />
}

export default MenuSiderAdmin
