import DataBase, { IDataBase } from 'containers/gqls/data/dataBase'

export type pageInfoProps = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string
  endCursor: string
}

export interface IDataResults extends IDataBase {
  pageInfo?: pageInfoProps
  data: any
  totalCount: number | undefined
}

export interface IResultData<QueryName extends string | number, DataModel> {
  result: {
    [key in QueryName]: {
      data: DataModel[]
      totalCount?: number
      pageInfo?: any
    }
  }
}

export default class DataResults extends DataBase implements IDataResults {
  pageInfo: pageInfoProps | undefined
  totalCount: number | undefined = 0

  setData(data: any) {
    this.data = DataBase.getListModel(data)
    this.pageInfo = data.pageInfo
    this.totalCount = data.totalCount
    return this
  }
}
