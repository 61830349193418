import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'
import USER_FRAGMENTS from 'containers/gqls/accounts/user/fragments'

const MILEAGE_FRAGMENTS = {
  get mileageOnly() {
    return gql`
      fragment mileageOnly on MileageType {
        id
        point
        user {
          ...userOnly
        }
        mileageMileageHistories {
          ...mileageHistoryList
        }
      }
      ${USER_FRAGMENTS.userOnly}
      ${this.mileageHistoryList}
    `
  },
  get mileageHistoryOnly() {
    return gql`
      fragment mileageHistoryOnly on MileageHistoryType {
        ...mileageHistory
        mileage {
          id
        }
        order {
          id
        }
        review {
          id
        }
        fitpetAdmin {
          id
        }
      }
      ${DEFAULT_FRAGMENTS.mileageHistory}
    `
  },
  get mileageHistoryList() {
    return gql`
      fragment mileageHistoryList on MileageHistoryTypeConnection {
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...mileageHistoryOnly
          }
          cursor
        }
        totalCount
      }
      ${DEFAULT_FRAGMENTS.pageInfo}
      ${this.mileageHistoryOnly}
    `
  },
}

export default MILEAGE_FRAGMENTS
