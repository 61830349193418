import {
  ActivateCustomerGradeInput,
  AnswerQuestionInput,
  ApproveProductsSellingInput,
  AskProductQuestionInput,
  AskServiceQuestionInput,
  BannerInput,
  BaseImportByExcelInput,
  BrandByFitpetAdminInput,
  BrandBySellerInput,
  CancelOrderItemsInput,
  CategoryImageInput,
  CategorySlugInput,
  CategoryInput,
  CheckEmailDuplicatedInput,
  ConfirmBillingInput,
  ConfirmExchangeReturnInfosInput,
  ConfirmReturnInfosInput,
  ConfirmUserCertNumberInput,
  CouponGroupInput,
  CreateAdInput,
  CreateAsInfoInput,
  CreateBestReviewsInput,
  CreateCartItemInput,
  CreateCartItemsInput,
  CreateCustomerTemplateAddressInput,
  CreateDisplayCollectionInput,
  CreateDisplayProductInfoInput,
  CreateDisplayProductInfosInput,
  CreateDisplayProductInfosWithProductPromotionsInput,
  CreateOrderItemAdminNoteInput,
  CreateProductPromotionInput,
  CreateProductPromotionsByExcelInput,
  CreateReviewInput,
  CreateSellerByFitpetAdminInput,
  CreateSellerByUserRequestInput,
  CreateSellerTemplateAddressInput,
  CreateShippingPresetInput,
  CreateTransactionInput,
  CreateUserInput,
  CustomerGradeInput,
  DeleteBannersInput,
  DeleteCartItemsInput,
  DeleteInactiveUsersInput,
  DeleteQnasInput,
  DeleteReviewsInput,
  DownloadEventCouponsInput,
  EmailSigninInput,
  EmailSignupInput,
  EstimateOrderInput,
  ExchangeOrderItemsInput,
  FaqInput,
  FollowInput,
  InfluencerInput,
  IssueCouponCodeInput,
  IssueManualCouponInput,
  IssueManualCouponsByExcelInput,
  LeaveUserInput,
  LeaveUsersByFitpetAdminInput,
  LinkAccountByEmailInput,
  LinkAccountBySocialInput,
  MileageConfigInput,
  MileageInput,
  MinAppVersionInput,
  ModelDeletesInput,
  NoticeInput,
  OrderInput,
  OrderItemsInput,
  PopularSearchTermInput,
  ProductInput,
  ProductsSellingInput,
  ProductsSoldOutInput,
  RefundAccountInput,
  RegisterCouponInput,
  RejectExchangeReturnInfosInput,
  RejectReturnInfosInput,
  RemoteAreaInput,
  RequestExcelFileInput,
  RequestExchangeOrderItemsInput,
  RequestReturnOrderItemsInput,
  ResetPasswordInput,
  ReturnInfosInput,
  ReturnOrderItemsInput,
  ReturnedReturnInfosInput,
  SellerManagerInput,
  SellerNotificationInput,
  SendEmailInput,
  SendPushInput,
  SendSmsInput,
  SendUserCertNumberInput,
  ServerConfigInput,
  ShippingCompanyInput,
  ShippingsInput,
  SignOutInput,
  SocialSigninInput,
  SocialSignupInput,
  StatusInput,
  StatusesInput,
  StatusesWithMessageInput,
  TokenSigninInput,
  TokenTypeInput,
  UpdateAdInput,
  UpdateAgreeTermsInput,
  UpdateAsInfoInput,
  UpdateCartItemInput,
  UpdateCouponGroupStatusInput,
  UpdateCustomerTemplateAddressInput,
  UpdateDisplayCollectionInput,
  UpdateDisplayOrderInput,
  UpdateDisplayPetTypeInput,
  UpdateDisplayProductInfoInput,
  UpdateOrderCustomerAddressInput,
  UpdateOrderItemAdminNoteInput,
  UpdateProductPromotionInput,
  UpdateProductPromotionsInput,
  UpdateReturnInfoCustomerAddressInput,
  UpdateReviewGroupProductsInput,
  UpdateReviewInput,
  UpdateSellerByFitpetAdminInput,
  UpdateSellerInput,
  UpdateSellerTemplateAddressInput,
  UpdateShippingPresetInput,
  UpdateSkuInput,
  UpdateUserByCustomerInput,
  UpdateUserByFitpetAdminInput,
  UpdateUserPasswordByFitpetAdminInput,
  UploadSearchTokenInput,
  UserCertificationConfirmInput,
  WishBrandByCustomerInput,
  WishProductByCustomerInput,
  WishProductInput,
} from 'containers/gqls/generated/globalTypes'

import * as utilData from '@libs/utils/utilData'

const parameterize = (params: { [index: string]: any }) => {
  const _: { [index: string]: string | object } = {}
  const input: { [index: string]: string | boolean | object | null } = {}

  Object.entries(params).forEach(([k, v]) => {
    if (v || v === 0) {
      if (k === 'id') {
        _[k] = v
      } else {
        input[k] = v
      }
    } else if (v === null) {
      if (k === 'startedAt' || k === 'endedAt') {
        input[k] = null
      }
    } else {
      if (typeof v === 'boolean') {
        input[k] = false
      }
    }
  })
  _.input = input
  return utilData.deepcopy(_)
}

type ID = { id: string }

const MUTATION_VARIABLES = {
  ACCEPT_EXCHANGE_RETURN_INFOS: (params: ReturnInfosInput) => parameterize(params),
  ACCEPT_RETURN_INFOS: (params: ReturnInfosInput) => parameterize(params),
  ACTIVATE_CUSTOMER_GRADE: (params: ID & ActivateCustomerGradeInput) => parameterize(params),
  ADD_ADMIN_PERMISSION: (params: ID) => parameterize(params),
  ALLOW_BANNER: (params: ID) => parameterize(params),
  ANSWER_PRODUCT_QUESTION: (params: ID & AnswerQuestionInput) => parameterize(params),
  ANSWER_SERVICE_QUESTION: (params: ID & AnswerQuestionInput) => parameterize(params),
  APPROVE_PRODUCTS_SELLING: (params: ApproveProductsSellingInput) => parameterize(params),
  APPROVE_REQUEST: (params: ID) => parameterize(params),
  APPROVE_REQUESTS: (params: StatusesInput) => parameterize(params),
  APPROVE_TRANSACTION: (params: ID) => parameterize(params),
  APPROVE_UPDATE_REQUEST: (params: ID) => parameterize(params),
  ASK_PRODUCT_QUESTION: (params: AskProductQuestionInput) => parameterize(params),
  ASK_SERVICE_QUESTION: (params: AskServiceQuestionInput) => parameterize(params),
  ASSOCIATE_SEARCH_TOKEN: (params: TokenTypeInput) => parameterize(params),
  BAN_REQUEST: (params: ID & StatusInput) => parameterize(params),
  BAN_REQUESTS: (params: StatusesWithMessageInput) => parameterize(params),
  CANCEL_ORDER_ITEMS: (params: CancelOrderItemsInput) => parameterize(params),
  CHANGE_CHIEF_SELLER_MANAGER: (params: SellerManagerInput) => parameterize(params),
  CHECK_EMAIL_DUPLICATED: (params: CheckEmailDuplicatedInput) => parameterize(params),
  CHECK_ORDER_ITEMS: (params: OrderItemsInput) => parameterize(params),
  CHECK_UNCONFIRMED_TRANSACTION: (params: ID) => parameterize(params),
  CLEAR_BANNER_CACHE: (params: ID) => parameterize(params),
  CLEAR_BEST_CACHE: (params: ID) => parameterize(params),
  CLEAR_HOME_CACHE: (params: ID) => parameterize(params),
  CONFIRM_CANCEL_ORDER_ITEM: (params: ID) => parameterize(params),
  CONFIRM_EXCHANGE_RETURN_INFOS: (params: ConfirmExchangeReturnInfosInput) => parameterize(params),
  CONFIRM_ORDER_ITEM: (params: ID) => parameterize(params),
  CONFIRM_RETURN_INFOS: (params: ConfirmReturnInfosInput) => parameterize(params),
  CONFIRM_SELLER_BILLING: (params: ID & ConfirmBillingInput) => parameterize(params),
  CONFIRM_USER_CERT_NUMBER: (params: ID & ConfirmUserCertNumberInput) => parameterize(params),
  COPY_PRODUCT: (params: ID) => parameterize(params),
  COMPLETE_SHIPPING_ORDER_ITEMS: (params: OrderItemsInput) => parameterize(params),
  CREATE_AD: (params: CreateAdInput) => parameterize(params),
  CREATE_AS_INFO: (params: CreateAsInfoInput) => parameterize(params),
  CREATE_BEST_REVIEWS: (params: CreateBestReviewsInput) => parameterize(params),
  CREATE_BRAND_BY_FITPET_ADMIN: (params: BrandByFitpetAdminInput) => parameterize(params),
  CREATE_BRAND_BY_SELLER: (params: BrandBySellerInput) => parameterize(params),
  CREATE_BULK_REVIEW_BY_FITPET_ADMIN_EXCEL: (params: BaseImportByExcelInput) => parameterize(params),
  CREATE_CART_ITEM: (params: CreateCartItemInput) => parameterize(params),
  CREATE_CART_ITEMS: (params: CreateCartItemsInput) => parameterize(params),
  CREATE_CATEGORY: (params: CategoryInput) => parameterize(params),
  CREATE_COUPON_GROUP: (params: CouponGroupInput) => parameterize(params),
  CREATE_CUSTOMER_GRADE: (params: CustomerGradeInput) => parameterize(params),
  CREATE_CUSTOMER_TEMPLATE_ADDRESS: (params: CreateCustomerTemplateAddressInput) => parameterize(params),
  CREATE_DISPLAY_COLLECTION: (params: CreateDisplayCollectionInput) => parameterize(params),
  CREATE_DISPLAY_PRODUCT_INFO: (params: CreateDisplayProductInfoInput) => parameterize(params),
  CREATE_DISPLAY_PRODUCT_INFOS: (params: CreateDisplayProductInfosInput) => parameterize(params),
  CREATE_DISPLAY_PRODUCT_INFOS_WITH_PRODUCT_PROMOTIONS: (params: CreateDisplayProductInfosWithProductPromotionsInput) =>
    parameterize(params),
  CREATE_FAQ: (params: FaqInput) => parameterize(params),
  CREATE_HOME_BOTTOM_BANNER: (params: BannerInput) => parameterize(params),
  CREATE_HOME_MIDDLE_BANNER: (params: BannerInput) => parameterize(params),
  CREATE_INFLUENCER_BY_FITPET_ADMIN: (params: InfluencerInput) => parameterize(params),
  CREATE_INFLUENCER_BY_USER_REQUEST: (params: InfluencerInput) => parameterize(params),
  CREATE_MULTI_LINE_BANNER: (params: BannerInput) => parameterize(params),
  UPDATE_HOME_MULTI_LINE_BANNER: (params: BannerInput) => parameterize(params),
  CREATE_HOME_MULTI_LINE_BANNER: (params: BannerInput) => parameterize(params),
  CREATE_NOTICE: (params: NoticeInput) => parameterize(params),
  CREATE_ORDER: (params: OrderInput) => parameterize(params),
  CREATE_ORDER_ITEM_ADMIN_NOTE: (params: CreateOrderItemAdminNoteInput) => parameterize(params),
  CREATE_OR_UPDATE_SKUS_BY_EXCEL: (params: BaseImportByExcelInput) => parameterize(params),
  CREATE_POPULAR_SEARCH_TERM: (params: PopularSearchTermInput) => parameterize(params),
  CREATE_PRODUCTS_BY_EXCEL: (params: BaseImportByExcelInput) => parameterize(params),
  CREATE_PRODUCT_BY_FITPET_ADMIN: (params: ProductInput) => parameterize(params),
  CREATE_PRODUCT_BY_SELLER: (params: ProductInput) => parameterize(params),
  CREATE_PRODUCT_PROMOTION: (params: CreateProductPromotionInput) => parameterize(params),
  CREATE_PRODUCT_PROMOTIONS_BY_EXCEL: (params: CreateProductPromotionsByExcelInput) => parameterize(params),
  CREATE_PRODUCT_TOP_BANNER: (params: BannerInput) => parameterize(params),
  CREATE_REMOTE_AREA: (params: RemoteAreaInput) => parameterize(params),
  CREATE_REMOTE_AREA_BY_EXCEL: (params: BaseImportByExcelInput) => parameterize(params),
  CREATE_REVIEW: (params: CreateReviewInput) => parameterize(params),
  CREATE_SELLER_BY_FITPET_ADMIN: (params: CreateSellerByFitpetAdminInput) => parameterize(params),
  CREATE_SELLER_BY_USER_REQUEST: (params: CreateSellerByUserRequestInput) => parameterize(params),
  CREATE_SELLER_MANAGER: (params: SellerManagerInput) => parameterize(params),
  CREATE_SELLER_TEMPLATE_ADDRESS: (params: CreateSellerTemplateAddressInput) => parameterize(params),
  CREATE_SERVER_CONFIG: (params: ServerConfigInput) => parameterize(params),
  CREATE_SHIPPING_COMPANY: (params: ShippingCompanyInput) => parameterize(params),
  CREATE_SHIPPING_PRESET: (params: CreateShippingPresetInput) => parameterize(params),
  CREATE_SLIM_BANNER: (params: BannerInput) => parameterize(params),
  CREATE_TOP_ROLL_BANNER: (params: BannerInput) => parameterize(params),
  CREATE_TRANSACTION: (params: CreateTransactionInput) => parameterize(params),
  CREATE_USER: (params: CreateUserInput) => parameterize(params),
  DELETE_AD: (params: ID) => parameterize(params),
  DELETE_ADS: (params: ModelDeletesInput) => parameterize(params),
  DELETE_AS_INFO: (params: ID) => parameterize(params),
  DELETE_BANNERS: (params: DeleteBannersInput) => parameterize(params),
  DELETE_CART_ITEMS: (params: DeleteCartItemsInput) => parameterize(params),
  DELETE_CATEGORY: (params: ID) => parameterize(params),
  DELETE_COUPON: (params: ID) => parameterize(params),
  DELETE_COUPONS: (params: ModelDeletesInput) => parameterize(params),
  DELETE_COUPONS_BY_GROUP: (params: ID) => parameterize(params),
  DELETE_COUPON_GROUP: (params: ID) => parameterize(params),
  DELETE_CUSTOMER_GRADE: (params: ID) => parameterize(params),
  DELETE_CUSTOMER_TEMPLATE_ADDRESS: (params: ID) => parameterize(params),
  DELETE_DISPLAY_COLLECTION: (params: ID) => parameterize(params),
  DELETE_DISPLAY_COLLECTIONS: (params: ModelDeletesInput) => parameterize(params),
  DELETE_DISPLAY_PRODUCT_INFO: (params: ID) => parameterize(params),
  DELETE_DISPLAY_PRODUCT_INFOS: (params: ModelDeletesInput) => parameterize(params),
  DELETE_FAQS: (params: ModelDeletesInput) => parameterize(params),
  DELETE_INACTIVE_USERS: (params: DeleteInactiveUsersInput) => parameterize(params),
  DELETE_INCOMPLETE_PRODUCTS: (params: ModelDeletesInput) => parameterize(params),
  DELETE_NOTICES: (params: ModelDeletesInput) => parameterize(params),
  DELETE_ORDER_ITEM_ADMIN_NOTE: (params: ID) => parameterize(params),
  DELETE_PRODUCT_PROMOTION: (params: ID) => parameterize(params),
  DELETE_PRODUCT_QUESTION: (params: ID) => parameterize(params),
  DELETE_PRODUCT_QUESTIONS: (params: DeleteQnasInput) => parameterize(params),
  DELETE_PUSH: (params: ID) => parameterize(params),
  DELETE_REMOTE_AREA: (params: ID) => parameterize(params),
  DELETE_REQUEST: (params: ID) => parameterize(params),
  DELETE_REQUESTS: (params: StatusesInput) => parameterize(params),
  DELETE_REVIEW: (params: ID) => parameterize(params),
  DELETE_REVIEWS: (params: DeleteReviewsInput) => parameterize(params),
  DELETE_SELLER_MANAGER: (params: SellerManagerInput) => parameterize(params),
  DELETE_SELLER_TEMPLATE_ADDRESS: (params: ID) => parameterize(params),
  DELETE_SERVER_CONFIG: (params: ID) => parameterize(params),
  DELETE_SERVICE_QUESTION: (params: ID) => parameterize(params),
  DELETE_SERVICE_QUESTIONS: (params: DeleteQnasInput) => parameterize(params),
  DELETE_SHIPPING_COMPANY: (params: ID) => parameterize(params),
  DELETE_SHIPPING_PRESET: (params: ID) => parameterize(params),
  DISALLOW_BANNER: (params: ID) => parameterize(params),
  DOWNLOAD_EVENT_COUPONS: (params: DownloadEventCouponsInput) => parameterize(params),
  EMAIL_SIGNIN: (params: EmailSigninInput) => parameterize(params),
  EMAIL_SIGNUP: (params: EmailSignupInput) => parameterize(params),
  ESTIMATE_ORDER: (params: EstimateOrderInput) => parameterize(params),
  EXCHANGE_ORDER_ITEMS: (params: ExchangeOrderItemsInput) => parameterize(params),
  FIND_ID: (params: UserCertificationConfirmInput) => parameterize(params),
  FINISH_AD: (params: ID) => parameterize(params),
  FOLLOW_SELLER: (params: FollowInput) => parameterize(params),
  ISSUE_COUPON_CODE: (params: IssueCouponCodeInput) => parameterize(params),
  ISSUE_MANUAL_COUPONS: (params: IssueManualCouponInput) => parameterize(params),
  ISSUE_MANUAL_COUPONS_BY_EXCEL: (params: IssueManualCouponsByExcelInput) => parameterize(params),
  LEAVE_USER: (params: ID & LeaveUserInput) => parameterize(params),
  LEAVE_USERS_BY_FITPET_ADMIN: (params: LeaveUsersByFitpetAdminInput) => parameterize(params),
  LINK_ACCOUNT_BY_EMAIL: (params: ID & LinkAccountByEmailInput) => parameterize(params),
  LINK_ACCOUNT_BY_SOCIAL: (params: ID & LinkAccountBySocialInput) => parameterize(params),
  READ_PUSH: (params: ID) => parameterize(params),
  REFRESH_CART_ITEMS: (params: ID) => parameterize(params),
  REGISTER_COUPON: (params: RegisterCouponInput) => parameterize(params),
  REJECT_EXCHANGE_RETURN_INFOS: (params: RejectExchangeReturnInfosInput) => parameterize(params),
  REJECT_REQUEST: (params: ID & StatusInput) => parameterize(params),
  REJECT_REQUESTS: (params: StatusesWithMessageInput) => parameterize(params),
  REJECT_RETURN_INFOS: (params: RejectReturnInfosInput) => parameterize(params),
  REJECT_UPDATE_REQUEST: (params: ID & StatusInput) => parameterize(params),
  REMOVE_ADMIN_PERMISSION: (params: ID) => parameterize(params),
  REQUEST_ADMIN_BILLING_ITEM_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  REQUEST_COUPON_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  REQUEST_DISPLAY_PRODUCT_PROMOTION_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  REQUEST_EXCHANGE_ORDER_ITEMS: (params: RequestExchangeOrderItemsInput) => parameterize(params),
  REQUEST_ORDER_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  REQUEST_PRODUCT_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  REQUEST_READY_FOR_SHIPPING_ORDER_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  REQUEST_READY_FOR_SHIPPING_ORDER_FOR_WMS_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  REQUEST_RETURN_ORDER_ITEMS: (params: RequestReturnOrderItemsInput) => parameterize(params),
  REQUEST_SELLER_BILLING_ITEM_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  REQUEST_SKU_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  REQUEST_USER_EXCEL_FILE: (params: RequestExcelFileInput) => parameterize(params),
  RESET_PASSWORD: (params: ID & ResetPasswordInput) => parameterize(params),
  RETURNED_RETURN_INFOS: (params: ReturnedReturnInfosInput) => parameterize(params),
  RETURN_ORDER_ITEMS: (params: ReturnOrderItemsInput) => parameterize(params),
  SEND_EMAIL: (params: SendEmailInput) => parameterize(params),
  SEND_PUSH: (params: SendPushInput) => parameterize(params),
  SEND_SMS: (params: SendSmsInput) => parameterize(params),
  SEND_USER_CERT_NUMBER: (params: SendUserCertNumberInput) => parameterize(params),
  SIGN_OUT: (params: SignOutInput) => parameterize(params),
  SOCIAL_SIGNIN: (params: SocialSigninInput) => parameterize(params),
  SOCIAL_SIGNUP: (params: SocialSignupInput) => parameterize(params),
  START_SHIPPINGS: (params: ShippingsInput) => parameterize(params),
  SUSPEND_REQUEST: (params: ID) => parameterize(params),
  SUSPEND_REQUESTS: (params: StatusesInput) => parameterize(params),
  TOGGLE_CATEGORY_VISIBLE: (params: ID) => parameterize(params),
  TOGGLE_REVIEW_LIKED: (params: ID) => parameterize(params),
  TOKEN_SIGNIN: (params: TokenSigninInput) => parameterize(params),
  UPDATE_AD: (params: ID & UpdateAdInput) => parameterize(params),
  UPDATE_AGREE_TERMS: (params: ID & UpdateAgreeTermsInput) => parameterize(params),
  UPDATE_AS_INFO: (params: ID & UpdateAsInfoInput) => parameterize(params),
  UPDATE_BRAND_BY_FITPET_ADMIN: (params: ID & BrandByFitpetAdminInput) => parameterize(params),
  UPDATE_BRAND_BY_SELLER: (params: ID & BrandBySellerInput) => parameterize(params),
  UPDATE_CART_ITEM: (params: ID & UpdateCartItemInput) => parameterize(params),
  UPDATE_CATEGORY: (params: ID & CategoryInput) => parameterize(params),
  UPDATE_CATEGORY_IMAGE: (params: ID & CategoryImageInput) => parameterize(params),
  UPDATE_CATEGORY_SLUG: (params: ID & CategorySlugInput) => parameterize(params),
  UPDATE_COUPON_GROUP: (params: ID & CouponGroupInput) => parameterize(params),
  UPDATE_COUPON_GROUP_STATUS: (params: ID & UpdateCouponGroupStatusInput) => parameterize(params),
  UPDATE_CUSTOMER_GRADE: (params: ID & CustomerGradeInput) => parameterize(params),
  UPDATE_CUSTOMER_TEMPLATE_ADDRESS: (params: ID & UpdateCustomerTemplateAddressInput) => parameterize(params),
  UPDATE_DATE_OF_DISPLAY_COLLECTION: (params: ID & UpdateDisplayCollectionInput) => parameterize(params),
  UPDATE_DISPLAY_COLLECTION: (params: ID & UpdateDisplayCollectionInput) => parameterize(params),
  UPDATE_DISPLAY_ORDER: (params: ID & UpdateDisplayOrderInput) => parameterize(params),
  UPDATE_DISPLAY_ORDER_FOR_BANNER: (params: ID & UpdateDisplayOrderInput) => parameterize(params),
  UPDATE_DISPLAY_PET_TYPE: (params: ID & UpdateDisplayPetTypeInput) => parameterize(params),
  UPDATE_DISPLAY_PRODUCT_INFO: (params: ID & UpdateDisplayProductInfoInput) => parameterize(params),
  UPDATE_FAQ: (params: ID & FaqInput) => parameterize(params),
  UPDATE_HOME_BOTTOM_BANNER: (params: ID & BannerInput) => parameterize(params),
  UPDATE_HOME_MIDDLE_BANNER: (params: ID & BannerInput) => parameterize(params),
  UPDATE_INFLUENCER: (params: ID & InfluencerInput) => parameterize(params),
  UPDATE_MILEAGE: (params: ID & MileageInput) => parameterize(params),
  UPDATE_MILEAGE_BY_EXCEL: (params: BaseImportByExcelInput) => parameterize(params),
  UPDATE_MILEAGE_CONFIG: (params: MileageConfigInput) => parameterize(params),
  UPDATE_MIN_APP_VERSION: (params: MinAppVersionInput) => parameterize(params),
  UPDATE_MOBILE_NUMBER: (params: ID & UserCertificationConfirmInput) => parameterize(params),
  UPDATE_MULTI_LINE_BANNER: (params: ID & BannerInput) => parameterize(params),
  UPDATE_NOTICE: (params: ID & NoticeInput) => parameterize(params),
  UPDATE_ORDER_CUSTOMER_ADDRESS: (params: ID & UpdateOrderCustomerAddressInput) => parameterize(params),
  UPDATE_ORDER_ITEM_ADMIN_NOTE: (params: ID & UpdateOrderItemAdminNoteInput) => parameterize(params),
  UPDATE_PRODUCTS_SELLING: (params: ProductsSellingInput) => parameterize(params),
  UPDATE_PRODUCTS_SOLD_OUT: (params: ProductsSoldOutInput) => parameterize(params),
  UPDATE_PRODUCT_BY_FITPET_ADMIN: (params: ID & ProductInput) => parameterize(params),
  UPDATE_PRODUCT_BY_SELLER: (params: ID & ProductInput) => parameterize(params),
  UPDATE_PRODUCT_PROMOTION: (params: ID & UpdateProductPromotionInput) => parameterize(params),
  UPDATE_PRODUCT_PROMOTIONS: (params: UpdateProductPromotionsInput) => parameterize(params),
  UPDATE_PRODUCT_SEARCH_KEYWORDS_BY_EXCEL: (params: BaseImportByExcelInput) => parameterize(params),
  UPDATE_PRODUCT_TOP_BANNER: (params: ID & BannerInput) => parameterize(params),
  UPDATE_PUSH: (params: ID & SendPushInput) => parameterize(params),
  UPDATE_REFUND_ACCOUNT: (params: ID & RefundAccountInput) => parameterize(params),
  UPDATE_REMOTE_AREA: (params: ID & RemoteAreaInput) => parameterize(params),
  UPDATE_RETURN_INFO_CUSTOMER_ADDRESS: (params: ID & UpdateReturnInfoCustomerAddressInput) => parameterize(params),
  UPDATE_REVIEW: (params: ID & UpdateReviewInput) => parameterize(params),
  UPDATE_REVIEW_GROUP_PRODUCTS: (params: ID & UpdateReviewGroupProductsInput) => parameterize(params),
  UPDATE_SELLER_BY_FITPET_ADMIN: (params: ID & UpdateSellerByFitpetAdminInput) => parameterize(params),
  UPDATE_SELLER_BY_USER_REQUEST: (params: ID & UpdateSellerInput) => parameterize(params),
  UPDATE_SELLER_NOTIFICATION: (params: ID & SellerNotificationInput) => parameterize(params),
  UPDATE_SELLER_TEMPLATE_ADDRESS: (params: ID & UpdateSellerTemplateAddressInput) => parameterize(params),
  UPDATE_SERVER_CONFIG: (params: ID & ServerConfigInput) => parameterize(params),
  UPDATE_SHIPPINGS: (params: ShippingsInput) => parameterize(params),
  UPDATE_SHIPPING_BY_EXCEL: (params: BaseImportByExcelInput) => parameterize(params),
  UPDATE_SHIPPING_COMPANY: (params: ID & ShippingCompanyInput) => parameterize(params),
  UPDATE_SHIPPING_PRESET: (params: ID & UpdateShippingPresetInput) => parameterize(params),
  UPDATE_SKU: (params: ID & UpdateSkuInput) => parameterize(params),
  UPDATE_SLIM_BANNER: (params: ID & BannerInput) => parameterize(params),
  UPDATE_TOP_ROLL_BANNER: (params: ID & BannerInput) => parameterize(params),
  UPDATE_USER_BY_CUSTOMER: (params: ID & UpdateUserByCustomerInput) => parameterize(params),
  UPDATE_USER_BY_FITPET_ADMIN: (params: ID & UpdateUserByFitpetAdminInput) => parameterize(params),
  UPDATE_USER_PASSWORD_BY_FITPET_ADMIN: (params: ID & UpdateUserPasswordByFitpetAdminInput) => parameterize(params),
  UPLOAD_SEARCH_TOKEN: (params: UploadSearchTokenInput) => parameterize(params),
  WISH_BRAND_BY_CUSTOMER: (params: WishBrandByCustomerInput) => parameterize(params),
  WISH_PRODUCT_BY_CUSTOMER: (params: WishProductByCustomerInput) => parameterize(params),
  WISH_PRODUCT_BY_INFLUENCER: (params: WishProductInput) => parameterize(params),
}

export default MUTATION_VARIABLES
