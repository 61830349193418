import {
  ITableColumn,
  ITableColumnTitleDataIndexKeyProps,
  ITableDateColumn,
  ITableColumnDateAtProps,
} from 'components/@atomics/MTable'
import {
  date2string,
  dateSort,
  addDay,
  date2stringSimple,
  datetime2stringWithFormat,
  DATE_FORMAT,
  DATETIME_FORMAT,
} from '@libs/utils/utilDate'
import { Text14Normal } from 'components/@atomics'

// rule1. if key !== dataIndex text will be value[dataindex][key]
const dateText = ({
  title,
  dataIndex,
  key,
  fixed,
  width = 140,
  sort,
  dateFormat = DATE_FORMAT,
}: ITableDateColumn<any>): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key,
    width,
    fixed,
    render: (v: string | any) => {
      if (!v) return <Text14Normal>-</Text14Normal>
      if (key === dataIndex) return <Text14Normal>{datetime2stringWithFormat(v, dateFormat)}</Text14Normal>
      return <Text14Normal>{datetime2stringWithFormat(v[key], dateFormat)}</Text14Normal>
    },
    sorter: sort ? (a: any, b: any) => dateSort(a[dataIndex!], b[dataIndex!]) : null,
    sortDirections: sort ? ['descend', 'ascend'] : null,
    showSorterTooltip: false,
  }
}

const dateRangeText = ({ title, dataIndex, key, fixed, width = 200 }: ITableColumn<any>): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key,
    width,
    fixed,
    render: (v: Date) => {
      const oneWeekAfter: string = date2string(addDay(7, v)) as string
      if (!v) return <Text14Normal>-</Text14Normal>
      if (key === dataIndex)
        return (
          <Text14Normal>
            {date2string(v)} ~ {oneWeekAfter}
          </Text14Normal>
        )
      return (
        <Text14Normal>
          {date2string(v)} ~ {oneWeekAfter}
        </Text14Normal>
      )
    },
  }
}

const monthSimpleText = ({ title, dataIndex, key, fixed, width = 180 }: ITableColumn<any>): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key,
    width,
    fixed,
    render: (v: Date) => {
      if (!v) return <Text14Normal>-</Text14Normal>
      if (key === dataIndex) return <Text14Normal>{date2stringSimple(v, 'month')}</Text14Normal>
      return <Text14Normal>{date2stringSimple(v, 'month')}</Text14Normal>
    },
  }
}

const dateFromModel = ({
  title,
  dataIndex,
  key,
  fixed,
  width,
}: ITableColumnTitleDataIndexKeyProps): ITableColumn<any> => dateText({ title, dataIndex, key, fixed, width })
const checkedAtText = ({ title, dateFormat = DATETIME_FORMAT }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, dataIndex: 'checkedAt', key: 'checkedAt', dateFormat })
const approvedAtText = ({ title, dateFormat }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, dataIndex: 'approvedAt', key: 'approvedAt', dateFormat })
const updateApprovedAtText = ({ title, dateFormat }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, dataIndex: 'updateApprovedAt', key: 'updateApprovedAt', dateFormat })
const createdAtText = ({ title, fixed, width, dateFormat }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, fixed, width, dataIndex: 'createdAt', key: 'createdAt', dateFormat })
const dateJoinedText = ({ title, dateFormat }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, dataIndex: 'dateJoined', key: 'dateJoined', dateFormat })
const inactivatedAtText = ({ title, dateFormat }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, dataIndex: 'inactivatedAt', key: 'inactivatedAt', dateFormat })
const leftAtText = ({ title, dateFormat }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, dataIndex: 'leftAt', key: 'leftAt', dateFormat })
const billedAtText = ({ title, fixed, dateFormat }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, dataIndex: 'billedAt', key: 'billedAt', fixed, dateFormat })
const expiredAtText = ({ title, fixed, dateFormat }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, dataIndex: 'expiredAt', key: 'expiredAt', fixed, dateFormat })
const usedAtText = ({ title, fixed, dateFormat }: ITableColumnDateAtProps): ITableColumn<any> =>
  dateText({ title, dataIndex: 'usedAt', key: 'usedAt', fixed, dateFormat })

export {
  dateText, // dateText
  dateFromModel, // dateFromModel
  dateRangeText, // dateRangeText
  monthSimpleText, // monthSimpleText
  checkedAtText, // 발주 확인일
  createdAtText, // 결제일, 캠페인 생성일
  dateJoinedText, // 가입일
  inactivatedAtText, // 휴면 전환일
  leftAtText, // 탈퇴일
  billedAtText, // 정산일
  approvedAtText, // 승인일, 등록일
  updateApprovedAtText, // 수정일
  expiredAtText,
  usedAtText,
}
