import { gql } from '@apollo/client'
import PRODUCT_OPTION_FRAGMENT from 'containers/gqls/products/productOption/fragments'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const PRODUCT_FRAGMENTS = {
  get productDetail() {
    return gql`
      fragment productDetail on ProductType {
        ...product
        seller {
          id
          companyName
          shopName
          companyOwnerName
        }
        shippingPreset {
          id
          returnAddress {
            postNumber
            basicAddress
          }
        }
        categories {
          edges {
            node {
              ...category
            }
          }
        }
        defaultProductOption {
          id
          purchasePrice
          productOptionSkus {
            pageInfo {
              ...pageInfo
            }
            edges {
              node {
                id
                productOption {
                  id
                }
                sku {
                  id
                  code
                  name
                  stockQuantity
                  purchasePrice
                }
                count
              }
            }
          }
        }
        asInfo {
          id
        }
        originalProduct {
          id
        }
        brand {
          ...brand
        }
        status {
          ...status
        }
        updateStatus {
          ...status
        }
        updateInfo {
          product {
            categories {
              edges {
                node {
                  ...category
                }
              }
            }
            seller {
              id
              companyName
              shopName
              companyOwnerName
            }
            brand {
              ...brand
            }
            asInfo {
              id
            }
            shippingPreset {
              id
              returnAddress {
                id
                postNumber
                basicAddress
              }
            }
            defaultProductOption {
              id
              purchasePrice
              productOptionSkus {
                pageInfo {
                  ...pageInfo
                }
                edges {
                  node {
                    id
                    productOption {
                      id
                    }
                    sku {
                      id
                      code
                      name
                      stockQuantity
                      purchasePrice
                    }
                    count
                  }
                }
              }
            }
            ...product
          }
          productOptions(pageInfo: { isUnlimited: true }) {
            ...updatedProductOptionsMinimum
          }
          productImages {
            ...productImage
          }
        }
        productProductOptions(includeNotUse: true, pageInfo: { isUnlimited: true }) {
          ...productOptionsMinimum
        }
        productProductImages {
          edges {
            node {
              ...productImage
            }
          }
        }
      }
      ${DEFAULT_FRAGMENTS.product}
      ${DEFAULT_FRAGMENTS.brand}
      ${DEFAULT_FRAGMENTS.status}
      ${DEFAULT_FRAGMENTS.category}
      ${PRODUCT_OPTION_FRAGMENT.updatedProductOptionsMinimum}
      ${PRODUCT_OPTION_FRAGMENT.productOptionsMinimum}
      ${DEFAULT_FRAGMENTS.productImage}
      ${DEFAULT_FRAGMENTS.pageInfo}
    `
  },
  get productListNode() {
    return gql`
      fragment productListNode on ProductType {
        id
        name
        realName
        price
        customerPrice
        seller {
          id
          shopName
        }
        status {
          ...status
        }
        updateStatus {
          ...status
        }
        brand {
          id
          name
        }
        stockQuantity
        createdAt
        updateApprovedAt
        deletedAt
        isSoldOut
        isShown
        productProductImages {
          edges {
            node {
              image
              id
              displayType
              thumbnail(productImageType: "SMALL")
            }
          }
        }
      }
      ${DEFAULT_FRAGMENTS.status}
    `
  },
  get products() {
    return gql`
      fragment products on ProductTypeConnection {
        totalCount
        edges {
          node {
            ...productListNode
          }
          cursor
        }
      }
      ${this.productListNode}
    `
  },
  // todo 변경정보 상세에 쓰일 query, 필드 추가 필요, by bale
  get productUpdateInfo() {
    return gql`
      fragment productUpdateInfo on ProductType {
        isShown
        isShownNaver
        isShownFacebook
        name
        categories {
          edges {
            node {
              id
            }
          }
        }
        brand {
          id
        }
        shippingPreset {
          id
        }
        shippingGuide
        price
        customerPrice
        purchasePrice
        defaultProductOption {
          productOptionProductOptionSkus {
            edges {
              node {
                count
                sku {
                  id
                }
              }
            }
          }
        }
        startedAt
        endedAt
      }
    `
  },
}

export default PRODUCT_FRAGMENTS
