import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const BILLING_ITEM_QUERY_GQLS = {
  BILLING_ITEMS_LIST: gql`
    query billingItems($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      billingItems(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...billingItem
            sellerBilling {
              id
              billedAt
              nextBillingAt
              confirmedAt
              sellerAmount
              seller {
                id
                shopName
                fitpetFeeRate
              }
              brand {
                id
                name
              }
            }
            orderItem {
              id
              orderItemNumber
              quantity
              confirmedAt
              returnConfirmedAt
              fitpetFeeRate
              order {
                id
                orderNumber
              }
              productOption {
                id
                stockQuantity
                price
                productOptionKinds {
                  key
                  value
                }
                product {
                  id
                  name
                }
              }
            }
          }
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.billingItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default BILLING_ITEM_QUERY_GQLS
