import { IModelExcelExportRequestDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelExcelExportRequestDefault } from 'containers/models/base/defaultModels'

export default class ModelExcelExportRequest extends ModelExcelExportRequestDefault {
  statusText: '생성 완료' | '오류' | '생성 중'

  constructor(data: IModelExcelExportRequestDefault) {
    super(data)
    this.statusText = this.getStatusText(this.status)
  }

  private getStatusText = (status: string) => {
    switch (status) {
      case 'COMPLETE':
        return '생성 완료'
      case 'ERROR':
        return '오류'
      case 'IN_PROGRESS':
        return '생성 중'
      default:
        return '오류'
    }
  }
}
