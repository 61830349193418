import { MFormItemWrapper } from 'components/formItems'
import { MImageUpload, Text12Blue } from 'components/@atomics'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'

type MFormItemFileUploadProps = MFormItemWrapperProps & {
  comment?: string
  option?: { width: string | number; height: string | number }
  maxCount?: number
  disabled?: boolean
  accept?: string
}

const MFormItemSingleImageUpload = (props: MFormItemFileUploadProps) => {
  const { comment, name, option, maxCount = 1, disabled, accept, ...restProps } = props
  return (
    <MFormItemWrapper
      name={name}
      valuePropName="fileList"
      getValueFromEvent={(e) => {
        if (maxCount <= 1) {
          const file = e.fileList.slice(-1)[0]
          if (file) {
            if (file.originFileObj) {
              return file.originFileObj
            }
            return file
          }
          return ''
        }
        return e.fileList.slice(-maxCount).map((x: any) => (x.originFileObj ? x.originFileObj : x))
      }}
      extra={<Text12Blue>{comment}</Text12Blue>}
      {...restProps}
    >
      <MImageUpload name={name?.toString()} option={option} maxCount={maxCount} disabled={disabled} accept={accept} />
    </MFormItemWrapper>
  )
}

export default MFormItemSingleImageUpload
