import { ReactElement } from 'react'
import tableCols from 'domains/admin/tableCols'
import { ITableColumn } from 'components/@atomics/MTable'
import { MMainTableProps } from 'components/MMainTable'
import { IModelServerConfigDefault } from 'containers/models/base/defaultModelInterfaces'
import { MOuterCard, MTable } from 'components/@atomics'

const tableColumns = (refetch: Function): Array<ITableColumn<IModelServerConfigDefault>> => {
  return [
    tableCols.mileageConfig.mileageConfigTypeDisplay(),
    tableCols.mileageConfig.mileageValue(),
    tableCols.mileageConfig.pointEdit(refetch),
    tableCols.mileageConfig.applyStatus(),
    tableCols.mileageConfig.suspendButton(refetch),
  ]
}

type MainTableMileageConfigListProps = {
  mileageConfigs: IModelServerConfigDefault[]
  refetch: Function
}
const MainTableMileageConfigList = ({
  mileageConfigs,
  refetch,
}: MainTableMileageConfigListProps): ReactElement<MMainTableProps> => {
  return (
    <MOuterCard>
      <MTable columns={tableColumns(refetch)} dataSource={mileageConfigs} />
    </MOuterCard>
  )
}

export default MainTableMileageConfigList
