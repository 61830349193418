import { MutableRefObject } from 'react'
import useModal from 'containers/hooks/useModal'
import { MButton } from 'components/@atomics'
import { ModalConfirm } from 'domains/common/modals'
import ModelCouponGroup from 'containers/models/modelCouponGroup'
import { message as antdMessage } from 'antd'
import { DataResult, DataResults } from 'containers/gqls/data'
import { hasData } from '@libs/utils/utilData'
import { IModelCoupon } from 'containers/models/modelCoupon'

const ButtonDeleteCouponsByGroup = ({
  couponGroup,
  clearSelected,
  refetchRef,
  dataRef,
}: {
  couponGroup: ModelCouponGroup
  clearSelected: Function
  refetchRef: MutableRefObject<Function | null>
  dataRef: MutableRefObject<{ [index: string]: DataResults | DataResult } | null>
}) => {
  const { useModalProps } = useModal()
  const coupons = dataRef?.current?.coupons.data

  const hasDeletableItem = () => {
    if (hasData(coupons)) {
      return coupons.filter((x: IModelCoupon) => !x.usedAt).length > 0
    }
    return false
  }

  return (
    <>
      <MButton
        disabled={!hasDeletableItem()}
        type="primary"
        onClick={() => {
          useModalProps.toggleModal()
          useModalProps.setData({ couponGroup })
        }}
        width={100}
      >
        전체 삭제
      </MButton>
      <ModalConfirm
        useModalProps={useModalProps}
        fixedProps={{
          mutationKey: {
            gqlKey: 'deleteCouponsByGroup',
            variableKey: 'deleteCouponsByGroup',
          },
          title: '쿠폰 전체 삭제',
          description: '쿠폰을 삭제하시겠습니까?\n고객 쿠폰 리스트에서 해당 쿠폰이 삭제됩니다',
        }}
        onAPISuccess={() => {
          antdMessage.success('쿠폰이 삭제되었습니다.')
          clearSelected()
          if (refetchRef && refetchRef.current) refetchRef.current()
        }}
      />
    </>
  )
}

export default ButtonDeleteCouponsByGroup
