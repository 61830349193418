import { gql } from '@apollo/client'
import NOTICE_FRAGMENTS from 'containers/gqls/configurations/notice/fragments'

const NOTICE_MUTATION_GQLS = {
  CREATE_NOTICE: gql`
    mutation createNotice($input: NoticeInput!) {
      createNotice(input: $input) {
        notice {
          ...noticeOnly
        }
      }
    }
    ${NOTICE_FRAGMENTS.noticeOnly}
  `,
  UPDATE_NOTICE: gql`
    mutation updateNotice($id: ID!, $input: NoticeInput!) {
      updateNotice(id: $id, input: $input) {
        notice {
          ...noticeOnly
        }
      }
    }
    ${NOTICE_FRAGMENTS.noticeOnly}
  `,
}

export default NOTICE_MUTATION_GQLS
