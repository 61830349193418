import { Row } from 'antd'
import { NextRouter } from 'next/router'

import * as utilData from '@libs/utils/utilData'
import { themes } from '@constants'
import { IModelCouponGroup } from 'containers/models/modelCouponGroup'
import { Text14Blue, MLinkRouterButton } from 'components/@atomics'
import ROUTES from '@constants/constRoutes'

const gradeUserCount = () => {
  return {
    title: '회원수',
    dataIndex: 'userCount',
    render: (userCount: number) => <>{utilData.personCountText(userCount || 0)}</>,
  }
}

const activationStatus = () => {
  return {
    title: '적용 상태',
    dataIndex: 'isActivated',
    render: (isActivated: boolean) => {
      const color = isActivated ? themes.colors.black : themes.colors.red
      const text = utilData.tableText(isActivated ? '적용 중' : '미적용')
      return <p style={{ color }}>{text}</p>
    },
  }
}

const coupons = () => {
  return {
    title: '등급 쿠폰',
    dataIndex: 'couponGroups',
    render: (couponGroups: IModelCouponGroup[]) => {
      if (!utilData.hasData(couponGroups)) return <>-</>
      return (
        <div>
          {couponGroups.map((coupon: IModelCouponGroup) => {
            return (
              <Row key={coupon.id}>
                <MLinkRouterButton
                  href={{
                    pathname: ROUTES.PROMOTIONS.COUPON_DETAIL,
                    query: { id: coupon._id },
                  }}
                  openNewWindow
                >
                  {utilData.tableText(coupon.benefitText)}
                </MLinkRouterButton>
              </Row>
            )
          })}
        </div>
      )
    },
  }
}

const edit = ({ router }: { router: NextRouter }) => {
  return {
    title: '수정',
    dataIndex: '_id',
    key: 'edit',
    width: 70,
    render: (_id: string) => {
      const href = `${ROUTES.ACCOUNTS.CUSTOMER_GRADE}/${_id}/edit`
      return (
        <a
          href={href}
          onClick={(e) => {
            e.preventDefault()
            router.push({
              pathname: href,
            })
          }}
        >
          <Text14Blue>[수정]</Text14Blue>
        </a>
        // <MLinkRouterButton
        //   href={ROUTES.ACCOUNTS.CUSTOMER_GRADE_EDIT}
        //   as={`${ROUTES.ACCOUNTS.CUSTOMER_GRADE}/${_id}/edit`}
        // >
        //   <Text14Blue>[수정]</Text14Blue>
        // </MLinkRouterButton>
      )
    },
  }
}

export { gradeUserCount, activationStatus, coupons, edit }
