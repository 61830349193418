import { FC, useState } from 'react'
import { FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { MainTableCouponGroupGradeSelectList } from 'domains/admin/mainTables'
import { MModal, MFormFilter } from 'components'
import { MFormFilterProps } from 'components/MFormFilter'
import { TypeUseModalProps } from 'containers/hooks/useModal'
import { IModelCouponGroup } from 'containers/models/modelCouponGroup'

type ModalCustomerGradeCouponGroupSelectProps = {
  useModalProps: TypeUseModalProps<any>
  onSubmit: (coupon: IModelCouponGroup) => void
}

const formFilterCouponGroupData: MFormFilterProps = {
  initial: [
    {
      // @ts-ignore - FILTER_TYPES.SEARCH를 사용하게 되면 error 발생함
      type: 'search',
      selectOptions: [{ value: FIELD_KEYS.FIELD_NAME, title: FIELD_VALUES.FIELD_COUPON_GROUP_NAME }],
    },
  ],
}

const ModalCustomerGradeCouponGroupSelect: FC<ModalCustomerGradeCouponGroupSelectProps> = ({
  useModalProps,
  onSubmit,
}) => {
  const [selectedCoupon, setSelectedCoupon] = useState<IModelCouponGroup>()
  const { hideModal } = useModalProps

  const _onSubmit = () => {
    if (selectedCoupon) {
      onSubmit(selectedCoupon)
      hideModal()
      return
    }
    // eslint-disable-next-line no-alert
    alert('(임시 Alert) 쿠폰을 먼저 선택해 주세요.')
  }
  return (
    <MModal
      title="회원 등급 쿠폰 선택"
      width={800}
      useModalProps={useModalProps}
      ComponentContent={
        <>
          <MFormFilter {...formFilterCouponGroupData} />
          <MainTableCouponGroupGradeSelectList onCouponSelected={setSelectedCoupon} />
        </>
      }
      onAction={_onSubmit}
    />
  )
}

export default ModalCustomerGradeCouponGroupSelect
