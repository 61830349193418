import React from 'react'
import { Text14Normal } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelProduct } from 'containers/models/modelProduct'

const option = ({
  dataIndex = 'productProductOptions',
}: ITableColumn<IModelProduct> = {}): ITableColumn<IModelProduct> => {
  return {
    title: '옵션',
    dataIndex,
    key: 'option',
    width: 150,
    render: (_: any, record: IModelProduct) => {
      if (record.hasOption) return <Text14Normal>사용 안 함</Text14Normal>
      return <Text14Normal>옵션 보기</Text14Normal>
    },
  }
}

export {
  option, // 상품 옵션
}
