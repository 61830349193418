import { gql } from '@apollo/client'

export const APP_VERSION_QUERY_GQLS = {
  MIN_APP_VERSION: gql`
    query minAppVersion($platform: String!) {
      minAppVersion(platform: $platform) {
        minAppVersion
        normalAppVersion
        latestAppVersion
        platform
      }
    }
  `,
}

export default APP_VERSION_QUERY_GQLS
