import * as utilDate from '@libs/utils/utilDate'
import _ from 'lodash'
import { Moment } from 'moment'
import { UploadFile } from 'antd/lib/upload/interface'
import * as utilData from '@libs/utils/utilData'
import { IFilterOptions, IFilterStringOptions, IRadioCheckboxOptions } from 'components'
import { FIELD_KEYS, FIELD_SORT_KEYS, FIELD_SORT_VALUES, FIELD_VALUES } from '@constants/constData'
import { PET_TYPE, PET_TYPE_TEXT } from '@constants/constDomain'
import { TypeCategoryData } from 'domains/admin/formItems/FormItemSelectCategory'
import { Omit } from '@libs/utils/utilTs'

const getUploadFile = (x: string | UploadFile | File, uid = -1) => {
  if (typeof x === 'string') {
    return {
      uid,
      name: utilData.getFilename(x),
      status: 'done',
      url: utilData.getFullUrl(x),
    }
  }
  return {
    uid,
    status: 'done',
    originFileObj: x,
    name: x.name,
  }
}

export const setUploadFileList = (fileList: UploadFile[]) => {
  if (!fileList) {
    return []
  }
  const _fileList = []
  // console.log(fileList)
  if (Array.isArray(fileList)) {
    fileList.forEach((x, index) => {
      const uploadFile = getUploadFile(x, index - 1)
      if (uploadFile) _fileList.push(uploadFile)
    })
  } else {
    const uploadFile = getUploadFile(fileList)
    if (uploadFile) _fileList.push(uploadFile)
  }
  return _fileList
}

export const unsetFields = (targetObject: object, fields: string[]) => {
  for (let idx = 0; idx < fields.length; idx += 1) {
    _.unset(targetObject, fields[idx])
  }
}

export const setRangeDate = (
  dateType: FIELD_KEYS.FIELD_DATE | FIELD_KEYS.FIELD_DATETIME,
  targetObject: object,
  periodDate: [Moment, Moment],
  targetKeys: [startedAtKey: string | string[], endedAtKey: string | string[]]
) => {
  if (!utilData.hasData(periodDate)) return
  let startedAt = periodDate[0]
  let endedAt = periodDate[1]

  if (dateType === FIELD_KEYS.FIELD_DATE) {
    /*
     * 서버에 보내줄 때는 시간과 함께 보내줘야 함
     * 참고:
     *  - moment().startOf(String): https://momentjscom.readthedocs.io/en/latest/moment/03-manipulating/03-start-of/
     *  - moment().endOf(String): https://momentjscom.readthedocs.io/en/latest/moment/03-manipulating/04-end-of/
     * */
    if (startedAt) {
      startedAt = periodDate[0].startOf('day').local() // 해당 지역의 시작 시간을 설정
    }
    if (endedAt) {
      endedAt = periodDate[1].endOf('day') // endedAt는 23:59:59.999 이렇게 설정되기 때문에 local() 사용할 필요 없음
    }
  } else if (dateType === FIELD_KEYS.FIELD_DATETIME) {
    if (startedAt) {
      startedAt = periodDate[0].startOf('minute').local() // 해당 지역의 시작 시간을 설정
    }
    if (endedAt) {
      endedAt = periodDate[1].endOf('minute') // endedAt는 23:59:59.999 이렇게 설정되기 때문에 local() 사용할 필요 없음
    }
  }
  if (startedAt) {
    if (startedAt?.isValid()) {
      _.set(targetObject, targetKeys[0], startedAt)
    }
  } else {
    _.set(targetObject, targetKeys[0], null)
  }
  if (endedAt) {
    if (endedAt?.isValid()) {
      _.set(targetObject, targetKeys[1], endedAt)
    }
  } else {
    _.set(targetObject, targetKeys[1], null)
  }
}

export const getFile = (file: Array<UploadFile<any>> | string) => {
  if (typeof file === 'object') {
    return file[0].originFileObj
  }
  return file
}

export const setFile = (targetObject: object, field: string | string[], file: Array<UploadFile<any>> | string) => {
  if (!file) return

  if (file.length === 0) {
    _.set(targetObject, field, undefined)
    return
  }

  _.set(targetObject, field, getFile(file))
}

export const makeFormFieldData = (field: string) => {
  return { name: FIELD_KEYS[field as keyof typeof FIELD_KEYS], label: FIELD_VALUES[field as keyof typeof FIELD_VALUES] }
}

export const makeSelectOption = (field: string) => {
  return {
    value: FIELD_KEYS[field as keyof typeof FIELD_KEYS],
    title: FIELD_VALUES[field as keyof typeof FIELD_VALUES],
  }
}

export const makeSortSelectOption = (field: string) => {
  return {
    value: FIELD_SORT_KEYS[field as keyof typeof FIELD_SORT_KEYS],
    title: FIELD_SORT_VALUES[field as keyof typeof FIELD_SORT_VALUES],
  }
}

export const makeSelectOptions = (
  selectOptions: IFilterOptions | IFilterStringOptions,
  useSortKey?: boolean
): IFilterOptions => {
  if (selectOptions.length > 0 && typeof selectOptions[0] === 'string') {
    return (selectOptions as string[]).map((x: string) => {
      if (useSortKey) {
        return makeSortSelectOption(x)
      }
      return makeSelectOption(x)
    })
  }
  return selectOptions as IFilterOptions
}

export const makeRadioCheckboxOption = ({ field, disabled = false }: { field: string; disabled?: boolean }) => {
  return {
    value: FIELD_KEYS[field as keyof typeof FIELD_KEYS],
    label: FIELD_VALUES[field as keyof typeof FIELD_VALUES],
    disabled,
  }
}

export const makeRadioCheckboxOptions = (checkboxOptions: IRadioCheckboxOptions | IFilterStringOptions) => {
  if (checkboxOptions.length > 0 && typeof checkboxOptions[0] === 'string') {
    return (checkboxOptions as string[]).map((field: string) => makeRadioCheckboxOption({ field }))
  }
  return (checkboxOptions as IRadioCheckboxOptions).map((ele) => makeRadioCheckboxOption(ele))
}

type rawSelectType = { [key: string]: string }
export function makeRadioOptions<Type extends rawSelectType>(
  arg: Type
): Array<{ value: keyof typeof arg; label: Type[keyof Type] }> {
  type keyType = keyof typeof arg
  const keys: Array<keyType> = Object.keys(arg)
  return keys.map((key) => ({
    label: arg[key],
    value: key,
  }))
}

export function makeSelectOptions2<Type extends rawSelectType>(
  arg: Type
): Array<{ title: Type[keyof Type]; value: keyof typeof arg }> {
  type keyType = keyof typeof arg
  const keys: Array<keyType> = Object.keys(arg)
  return keys.map((key) => ({
    title: arg[key],
    value: key,
  }))
}

export function makeCheckboxOptions2<Type extends rawSelectType>(
  arg: Type
): Array<{ value: keyof typeof arg; title: Type[keyof Type] }> {
  type keyType = keyof typeof arg
  const keys: Array<keyType> = Object.keys(arg)
  return keys.map((key) => ({
    title: arg[key],
    value: key,
  }))
}

export const getPetTypeFromCategories = (categories: { label: string } & Omit<TypeCategoryData, 'label'>[]) => {
  const categoryLabelList = categories.map((category) => category.label)
  const catTypeCategoryLabelList = categoryLabelList.filter((label: string) => label.includes(PET_TYPE_TEXT.CAT))
  const dogTypeCategoryLabelList = categoryLabelList.filter((label: string) => label.includes(PET_TYPE_TEXT.DOG))
  const isCatType = catTypeCategoryLabelList.length > 0
  const isDogType = dogTypeCategoryLabelList.length > 0
  let petType = PET_TYPE.ALL

  if (isCatType && isDogType) {
    petType = PET_TYPE.ALL
  } else {
    if (isCatType) {
      petType = PET_TYPE.CAT
    }

    if (isDogType) {
      petType = PET_TYPE.DOG
    }
  }

  return petType as PET_TYPE
}

export const getAfterAndBeforeDatetime = (
  name: string,
  value: [Moment | Date | string | undefined, Moment | Date | string | undefined]
) => {
  if (!value[0] && !value[1]) {
    return {
      [`${name}After`]: undefined,
      [`${name}Before`]: undefined,
    }
  }

  const rangeDatetime: { [key: string]: string } = {}
  const [after, before] = value
  if (after) {
    rangeDatetime[`${name}After`] = utilDate.datetime2utcString(after)
  }
  if (before) {
    rangeDatetime[`${name}Before`] = utilDate.datetime2utcString(utilDate.addSecond(-1, utilDate.addDay(1, before)))
  }
  return rangeDatetime
}
