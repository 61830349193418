import { IModelBrand } from 'containers/models/modelBrand'
import { MTableTransfer } from 'components/@atomics'
import MQuery from 'components/MQuery'
import { onTransferProps } from 'domains/common/modals/ModalTableTransferSelect'
import { datetime2stringWithFormat } from '@libs/utils/utilDate'
import { TRANSFER_TABLE_PAGE_SIZE } from '@constants/constData'
import _ from 'lodash'
import { MFormModalFilter } from 'components'
import { FC, useRef, useState } from 'react'
import { FilterOptionsCommon } from 'components/MFormModalFilter'

type TableTransferBrandProps = {
  onTransfer: (values: onTransferProps) => void
  chosenValues?: IModelBrand[]
}

const formInputOptions: FilterOptionsCommon[] = [{ label: '브랜드명', name: 'name', labelCol: { span: 6 } }]

const rightTableColumns = [
  { dataIndex: '_id', title: 'ID' },
  { dataIndex: 'name', title: '브랜드명' },
]
const leftTableColumns = [...rightTableColumns, { dataIndex: 'approvedAt', title: '등록일' }]

const TableTransferBrand: FC<TableTransferBrandProps> = ({ onTransfer, chosenValues = [] }) => {
  const [filterVariables, setFilterVariables] = useState<{}>({})
  const brandsRef = useRef<IModelBrand[]>([])
  const targetBrandsRef = useRef<IModelBrand[]>([])
  const excludeBrands = chosenValues.map((x) => x.id)
  const _onTransfer = (nextTargetKeys: string[]) => {
    targetBrandsRef.current = brandsRef.current.filter((brand) => nextTargetKeys.includes(brand.id))
    onTransfer({ values: targetBrandsRef.current })
  }

  const refetchRef = useRef<any>(null)

  return (
    <>
      <MFormModalFilter
        refetchRef={refetchRef}
        setFilterVariables={setFilterVariables}
        formInputOptions={formInputOptions}
      />
      <MQuery
        queryParams={{
          gqlKey: 'selectBrands',
          dataKey: 'brands',
          variables: { filter: { ...filterVariables, excludeBrands }, pageInfo: { first: TRANSFER_TABLE_PAGE_SIZE } },
        }}
      >
        {({ data, refetch }: any) => {
          if (refetchRef) {
            refetchRef.current = refetch
          }
          brandsRef.current = _.unionBy(data?.brands?.data, targetBrandsRef.current, 'id')
          const totalCount = data?.brands?.totalCount || brandsRef.current.length
          const dataSource = brandsRef.current.map((brand) => ({
            key: brand.id,
            _id: brand._id,
            name: brand.name,
            approvedAt: datetime2stringWithFormat(brand.approvedAt),
          }))
          return (
            <MTableTransfer
              totalCount={totalCount}
              dataSource={dataSource}
              initialTargetKeys={targetBrandsRef.current.map((b) => b.id)}
              showSelectAll={false}
              onTransfer={_onTransfer}
              listStyle={{}}
              filterOption={(inputValue: string, option: any) => option?.name?.indexOf(inputValue) > -1}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
              locale={{
                itemUnit: '선택된 브랜드',
                itemsUnit: '전체 브랜드',
                searchPlaceholder: '카테고리 명',
              }}
            />
          )
        }}
      </MQuery>
    </>
  )
}

export default TableTransferBrand
