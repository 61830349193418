import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const DISPLAY_PRODUCT_INFO_QUERY_GQLS = {
  DISPLAY_PRODUCT_INFOS: gql`
    query displayProductInfos($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      displayProductInfos(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            displayOrder
            startedAt
            endedAt
            id
            product {
              id
              name
              brand {
                id
                name
              }
              mainImage {
                id
                thumbnail
              }
              status {
                id
                value
                text
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default DISPLAY_PRODUCT_INFO_QUERY_GQLS
