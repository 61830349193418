import { NamePath } from 'rc-field-form/lib/interface'
import { MFormItemCheckboxGroup, MFormItemWrapper } from 'components/formItems'
import MQuery from 'components/MQuery'
import { Text12Blue } from 'components/@atomics'
import { FIELD_KEYS } from '@constants/constData'
import { IModelCustomerGrade } from 'containers/models/modelCustomerGrade'

type MFormItemCustomerGradesProps = {
  required?: boolean
  hasAllCheck?: boolean
  showCount?: boolean
  name?: NamePath
}

const MFormItemCustomerGrades = ({
  showCount = false,
  name: formItemName = FIELD_KEYS.FIELD_GRADES,
  ...otherProps
}: MFormItemCustomerGradesProps) => {
  return (
    <MQuery queryParams={{ gqlKey: 'selectCustomerGrades' }}>
      {({ data }: any) => {
        const customerGradesData = data?.customerGrades.data as IModelCustomerGrade[]
        const gradeOptions = customerGradesData.map(({ id, name }) => {
          return {
            label: name,
            value: id,
          }
        })

        return (
          <MFormItemCheckboxGroup
            name={formItemName}
            label="회원 등급"
            options={gradeOptions}
            {...(showCount
              ? {
                  extra: (
                    <MFormItemWrapper
                      noStyle
                      shouldUpdate={(prevValues, currentValues) => {
                        return (
                          prevValues.grades.length !== currentValues.grades.length ||
                          prevValues.isMarketing !== currentValues.isMarketing
                        )
                      }}
                    >
                      {({ getFieldValue }) => {
                        const checkedValues = getFieldValue(FIELD_KEYS.FIELD_GRADES)
                        const isMarketing = getFieldValue('isMarketing')

                        const totalCount =
                          customerGradesData
                            .filter(({ id }) => checkedValues.indexOf(id) !== -1)
                            .reduce((prev: number, { pushUserCount, pushMarketingUserCount }) => {
                              const _count = isMarketing ? pushMarketingUserCount : pushUserCount
                              return prev + _count
                            }, 0) || 0
                        return <Text12Blue>총 {totalCount}명</Text12Blue>
                      }}
                    </MFormItemWrapper>
                  ),
                }
              : {})}
            {...otherProps}
          />
        )
      }}
    </MQuery>
  )
}

export default MFormItemCustomerGrades
