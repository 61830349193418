import * as utilCommon from '@libs/utils/utilCommon'
import { ModelSellerAddressDefault } from 'containers/models/base/defaultModels'
import { IModelSellerAddressDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelSellerAddress extends IModelSellerAddressDefault {
  fullAddress: string
}

export default class ModelSellerAddress extends ModelSellerAddressDefault {
  fullAddress: string = ''

  constructor(data: IModelSellerAddress) {
    super(data)
    this.fullAddress = utilCommon.getFullAddress(this.postNumber, this.basicAddress, this.detailedAddress)
  }
}
