import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import styled from 'styled-components'

import { UseIconToggleButtonProps } from 'containers/hooks/useIconToggleButton'

type MToggleButtonProps = ButtonProps & {
  filledText: JSX.Element | string
  outlinedText?: JSX.Element | string
  setClickEvent: () => void
  useIconToggleButtonProps: UseIconToggleButtonProps
}

const MToggleButton = (props: MToggleButtonProps) => {
  const { filledText, outlinedText, setClickEvent, useIconToggleButtonProps, ...buttonProps } = props
  const { isFill, toggleButton } = useIconToggleButtonProps
  return (
    <StyledButton
      type="primary"
      onClick={(e) => {
        e.stopPropagation()
        toggleButton()
        setClickEvent()
      }}
      ghost={isFill}
      {...buttonProps}
    >
      {isFill ? outlinedText : filledText}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  border-radius: 4px;
`

export default MToggleButton
