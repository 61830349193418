import { IModelUpdatedSellerDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelUpdatedSellerDefault } from 'containers/models/base/defaultModels'

export interface IModelUpdatedSeller extends IModelUpdatedSellerDefault {}

export default class ModelUpdatedSeller extends ModelUpdatedSellerDefault {
  constructor(data: IModelUpdatedSeller) {
    super(data)
  }
}
