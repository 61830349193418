import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const BRAND_QUERY_GQLS = {
  SELECT_BRANDS: gql`
    query selectBrands($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      brands(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            id
            name
            approvedAt
          }
        }
      }
    }
  `,
  BRANDS: gql`
    query brands($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      brands(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            ...brand
            status {
              id
              text
              value
              requestType
            }
            updateStatus {
              id
              text
              value
              requestType
            }
            sellers {
              edges {
                node {
                  id
                  shopName
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
  `,
  BRAND: gql`
    query brand($id: ID!) {
      brand(id: $id) {
        ...brand
        status {
          ...status
        }
        updateStatus {
          ...status
        }
        updateInfo {
          ...updatedBrand
          brand {
            ...brand
          }
        }
        sellers {
          edges {
            node {
              id
              shopName
              companyName
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedBrand}
  `,
}

export default BRAND_QUERY_GQLS
