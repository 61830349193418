import { ReactNode } from 'react'
import { MFormItemWrapper } from 'components/formItems'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'

import { Text14Normal } from 'components/@atomics'

type MFormItemInfoProps = MFormItemWrapperProps & {
  text?: string
  children?: ReactNode
}

const MFormItemInfo = (props: MFormItemInfoProps) => {
  const { text, children, ...formItemWrapperProps } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { rules: _, ...restFormItemWrapperProps } = formItemWrapperProps
  return (
    <MFormItemWrapper {...restFormItemWrapperProps}>
      {!children && text && <Text14Normal>{text}</Text14Normal>}
      {children && !text && children}
    </MFormItemWrapper>
  )
}

export default MFormItemInfo
