import _ from 'lodash'
import { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Space } from 'antd'
import { SpaceProps } from 'antd/lib/space'
import styled from 'styled-components'

import { MOuterCard, Text14A45, Text24Normal, Text24Blue } from 'components/@atomics'
import { FIELD_KEYS } from '@constants/constData'
import { doInitializeFormFilter, doInitializeMainTableVariables, doSetMainTableFilters } from '@libs/redux/reduxUI'
import * as utilData from '@libs/utils/utilData'
import { RootState, store } from '@libs/redux/store'

type Unit = '건' | '명'
export type StatusFilterData = {
  title: string
  key: string
  count: number
}

type CustomSpaceProps = {
  spaceSize?: number | undefined
  maxCount?: number
}

type TitlesProps = CustomSpaceProps & {
  titleData: string[]
}

type MStatusFilterProps = CustomSpaceProps & {
  data: StatusFilterData[]
  unit?: Unit
  statusKey: string
}

const defaultStatusFilterData: StatusFilterData[] = [
  { title: '전체', key: 'all', count: 0 },
  { title: '옵션1', key: 'default1', count: 0 },
  { title: '옵션2', key: 'default2', count: 0 },
  { title: '옵션3', key: 'default3', count: 0 },
]

const Titles: FunctionComponent<TitlesProps> = ({ titleData, spaceSize, maxCount }) => {
  return (
    <Space size={spaceSize} style={{ cursor: 'default' }}>
      {titleData.map((x: string) => {
        return (
          <WrapperText key={x} maxCount={maxCount}>
            <Text14A45>{x}</Text14A45>
          </WrapperText>
        )
      })}
    </Space>
  )
}

const Counts: FunctionComponent<MStatusFilterProps> = ({ data, unit, spaceSize, maxCount, statusKey }) => {
  const [selected, setSelected] = useState('')
  const mainTableVariables = useSelector((state: RootState) => state.reduxUIReducers.mainTableVariables)
  const updatedStatus = mainTableVariables?.filter?.[statusKey]?.include

  useEffect(() => {
    let detected = false
    if (Array.isArray(updatedStatus)) {
      if (updatedStatus.length === 1) {
        data.forEach((x) => {
          if (utilData.toUpperSnake(x.key) === updatedStatus[0]) {
            setSelected(x.key)
            detected = true
          }
        })
      }
    }
    if (!detected) setSelected(data[0].key)
  }, [JSON.stringify(updatedStatus)])

  const dispatch = useDispatch()
  const handleClick = async (key: string) => {
    const variables = store.getState().reduxUIReducers.mainTableInitialVariables
    setSelected(key)
    dispatch(doInitializeFormFilter())
    if (key === FIELD_KEYS.FIELD_ALL) {
      dispatch(doInitializeMainTableVariables())
    } else {
      dispatch(
        doSetMainTableFilters({
          ...variables.filter,
          [statusKey]: { include: [utilData.toUpperSnake(key)] },
        })
      )
    }
  }
  if (selected === '') setSelected(data[0].key)
  return (
    <Space size={spaceSize} style={{ cursor: 'pointer' }}>
      {data.map((x: StatusFilterData) => {
        const { count, key } = x
        const TextComponent = selected === key ? Text24Blue : Text24Normal
        return (
          <WrapperText maxCount={maxCount} key={key}>
            <TextComponent onClick={() => handleClick(key)}>
              {unit === '명' ? utilData.personCountText(count) : utilData.caseCountText(count!)}
            </TextComponent>
          </WrapperText>
        )
      })}
    </Space>
  )
}

export default function MStatusFilter({ data, unit = '건', spaceSize, statusKey }: MStatusFilterProps) {
  const _data = data || defaultStatusFilterData
  const titleData: string[] = _.map(_data, 'title')
  const countData: number[] = _.map(_data, 'count')
  const maxCount: number = _.max(countData)!
  const filterLength: number = _data?.length

  const setSpaceSize = (): number => {
    let responsibleSize: number = spaceSize!
    if (filterLength > 3) responsibleSize = spaceSize! / 1.2
    if (filterLength > 5) responsibleSize = spaceSize! / 1.6
    if (filterLength > 7) responsibleSize = spaceSize! / 2.4
    if (filterLength > 9) responsibleSize = spaceSize! / 3
    return responsibleSize
  }

  return (
    <MOuterCard>
      <Space direction="vertical" size="small">
        <Titles titleData={titleData} spaceSize={setSpaceSize()} maxCount={maxCount} />
        <Counts data={_data} spaceSize={setSpaceSize()} maxCount={maxCount} unit={unit} statusKey={statusKey} />
      </Space>
    </MOuterCard>
  )
}

const setWidth = (props: any): string => {
  if (props.maxCount > 10000) return '110px'
  if (props.maxCount > 1000) return '100px'
  return '74px'
}

const WrapperText = styled(Space)<SpaceProps & CustomSpaceProps>`
  width: ${(props) => setWidth(props)};
`
