import { FC } from 'react'
import { Descriptions } from 'antd'
import { Text16Normal } from 'components/@atomics'
import * as utilData from '@libs/utils/utilData'

type DescItemReturnPriceProps = {
  title?: string
  paymentAmount: number
  returnShippingFee: number
}

const DescItemReturnPrice: FC<DescItemReturnPriceProps> = ({
  title = '환불 금액 정보',
  paymentAmount,
  returnShippingFee,
}) => {
  const expectedRefundAmount = Math.max(paymentAmount - returnShippingFee, 0)
  return (
    <Descriptions bordered column={1} size="small" title={title}>
      <Descriptions.Item label="결제금액" labelStyle={{ fontWeight: 'bold' }}>
        {utilData.currencyText(paymentAmount)}
      </Descriptions.Item>
      <Descriptions.Item label="반품배송비" labelStyle={{ fontWeight: 'bold' }}>
        {utilData.currencyText(returnShippingFee)}
      </Descriptions.Item>
      <Descriptions.Item label="환불예정금액" labelStyle={{ fontWeight: 'bold' }}>
        <Text16Normal bold>
          {utilData.currencyText(paymentAmount >= returnShippingFee ? expectedRefundAmount : paymentAmount)}
        </Text16Normal>
      </Descriptions.Item>
    </Descriptions>
  )
}

export default DescItemReturnPrice
