import { ModelTotalSellerStatisticDefault } from 'containers/models/base/defaultModels'
import { IModelTotalSellerStatisticDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelTotalSellerStatistic extends IModelTotalSellerStatisticDefault {}

export default class ModelTotalSellerStatistic extends ModelTotalSellerStatisticDefault {
  constructor(data: IModelTotalSellerStatistic) {
    super(data)
  }
}
