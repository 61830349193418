import { FC, CSSProperties, ReactNode } from 'react'
import { Radio } from 'antd'
import { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { MFormBelowComment } from 'components/@atomics/forms'
import { MFormItemWrapper } from 'components/formItems'
import { CheckboxOptionType } from 'antd/lib/checkbox/Group'

export type MFormItemRadioProps = {
  onChange?: (value: any) => void
  options?: Array<CheckboxOptionType>
  comment?: string
  defaultValue?: any
  value?: any
  direction?: 'vertical' | 'horizontal'
  children?: ReactNode
  style?: CSSProperties
  buttonStyle?: RadioGroupProps['buttonStyle']
  wrapperStyle?: CSSProperties
  disabled?: boolean
} & MFormItemWrapperProps

const MFormItemRadio: FC<MFormItemRadioProps> = ({
  onChange,
  options,
  value,
  comment,
  children,
  direction,
  style,
  buttonStyle,
  wrapperStyle,
  disabled = false,
  ...formItemWrapper
}) => {
  const renderOptions = () => {
    if (children) {
      return children
    }
    const itemStyle = direction === 'vertical' ? { display: 'block' } : undefined

    return options?.map(({ label, value: _value, disabled: _disabled }: CheckboxOptionType) => {
      const props = { key: `${_value}`, value: _value, disabled: _disabled, style: itemStyle }
      return buttonStyle ? <Radio.Button {...props}>{label}</Radio.Button> : <Radio {...props}>{label}</Radio>
    })
  }
  const { labelCol } = formItemWrapper

  return (
    <MFormItemWrapper
      {...formItemWrapper}
      style={wrapperStyle}
      extra={comment && <MFormBelowComment comment={comment} colOffset={labelCol?.span} />}
    >
      <Radio.Group
        style={style}
        buttonStyle={buttonStyle}
        value={value}
        disabled={disabled}
        onChange={({ target }: RadioChangeEvent) => onChange && onChange(target.value)}
      >
        {renderOptions()}
      </Radio.Group>
    </MFormItemWrapper>
  )
}
export default MFormItemRadio
