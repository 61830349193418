import _ from 'lodash'
import React, { MutableRefObject } from 'react'
import { MutationFunction } from '@apollo/client'
import { Image, Space, message as antdMessage } from 'antd'

import { MModal, MConfirmModal } from 'components/modals'
import { Text14Normal, MTextButton, MLinkRouterButton, Text14Blue, Text12Blue, MButton } from 'components/@atomics'
import { ITableColumn, ITableColumnFixedOnlyProps, ITableColumnTitleDataIndexProps } from 'components/@atomics/MTable'
import { IModelProduct } from 'containers/models/modelProduct'
import MMutation from 'components/MMutation'
import { IModelStatus } from 'containers/models/modelStatus'
import useModal from 'containers/hooks/useModal'
import { Store } from 'rc-field-form/lib/interface'
import ROUTES from '@constants/constRoutes'
import * as utilDate from '@libs/utils/utilDate'
import * as utilData from '@libs/utils/utilData'
import { STATUS } from '@constants/constDomain'
import { getFullUrl } from '@libs/utils/utilData'
import MQuery from '../../../../components/MQuery'

const productEdit = ({ fixed = 'left', query }: ITableColumnFixedOnlyProps = {}) => {
  return {
    title: '수정',
    dataIndex: '_id',
    key: 'edit',
    width: 70,
    fixed,
    render: (_id: string, r: IModelProduct) => {
      const hasUpdateInfo = utilData.hasData(r) && utilData.hasData(r.updateInfo) && r?.updateStatus?.isRequest
      const pathname = hasUpdateInfo
        ? `${ROUTES.PRODUCTS.BASE}/${_id}/info-change`
        : `${ROUTES.PRODUCTS.BASE}/${_id}/edit`
      const href = { pathname, query }
      return (
        <MLinkRouterButton href={href} openNewWindow>
          <Text14Blue>[수정]</Text14Blue>
        </MLinkRouterButton>
      )
    },
  }
}

const productName = ({
  dataIndex = 'name' as string | string[],
  title = '상품명',
  hasLink = true,
  width = 120,
} = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'productName',
    width,
    render: (_value: any, product: IModelProduct) => {
      if (!hasLink) {
        return <Text14Normal>{product.realName}</Text14Normal>
      }
      const href = product.hasUpdateInfo
        ? `${ROUTES.PRODUCTS.BASE}/${product._id}/info-change`
        : `${ROUTES.PRODUCTS.BASE}/${product._id}/edit`
      return (
        <MLinkRouterButton href={href} openNewWindow>
          <Text14Blue>{product.realName}</Text14Blue>
        </MLinkRouterButton>
      )
    },
  }
}

const productCustomerPrice = ({
  dataIndex = 'customerPrice',
  title = '판매가(원)',
  width = 120,
} = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'customerPrice',
    width,
    render: (value: any) => {
      return <Text14Normal>{utilData.numberWithCommas(value)}</Text14Normal>
    },
  }
}

const productCopy = ({ fixed = 'left' }: ITableColumnFixedOnlyProps = {}) => {
  return {
    title: '복사',
    dataIndex: 'id',
    key: 'copy',
    width: 80,
    fixed,
    render: (id: string) => {
      const handleClick = (mutation: MutationFunction) => {
        mutation({ variables: { id } })
      }
      return (
        <MMutation
          gqlKey="copyProduct"
          onAPISuccess={() => {
            antdMessage.success(
              '상품이 복사되었습니다. 복사된 상품은 "엑셀 업로드/임시 등록 관리 > 미등록 상품 목록"에서 확인할 수 있습니다.'
            )
          }}
        >
          {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
            return (
              <MButton type="link" onClick={() => handleClick(mutation)} loading={loading}>
                <Text14Blue>[복사]</Text14Blue>
              </MButton>
            )
          }}
        </MMutation>
      )
    },
  }
}

const productCode = ({ dataIndex = '_id', width = 150, link = false } = {}): ITableColumn<any> => {
  return {
    title: '상품코드',
    key: 'id',
    link,
    dataIndex,
    width,
    render: (value: string) => {
      if (link) {
        const productDetailUrl = `${process.env.FRONT_WEB_DOMAIN}/products/${value}`
        return (
          <MLinkRouterButton href={productDetailUrl} openNewWindow>
            {value}
          </MLinkRouterButton>
        )
      }
      return <Text14Normal>{utilData.tableText(value)}</Text14Normal>
    },
  }
}

// const productSoldQuantity = () => {
//   return (
//     {
//       title: '판매건수',
//       dataIndex: 'soldQuantity',
//       key: 'soldQuantity',
//       width: 100,
//       render: (v: number, record: IModelProduct) => {
//         // const router = useRouter()
//         // const { id } = record
//         if (v <= 0) {
//           return <Text14Normal>{utilData.caseCountText(v)}</Text14Normal>
//         }
//         return (
//           <MTextButton
//             // TODO: 정산리스트로 가야 함 fix 필요
//             // onClick={() => router.push({ pathname: ROUTES.CAMPAIGNS.CAMPAIGN_LIST, query: { [queryType]: id } })}
//           >
//             {utilData.caseCountText(v)}
//           </MTextButton>
//         )
//       },
//     }
//   )
// }

const productCreatedAtModifiedAt = ({ dataIndex = 'createdAt' } = {}): ITableColumn<any> => {
  return {
    title: '등록일/수정일',
    key: 'createdAt',
    dataIndex,
    width: 220,
    render: (value: string, record: IModelProduct) => {
      return (
        <Space direction="vertical">
          <Text14Normal>{utilDate.date2string(value)}</Text14Normal>
          <Text14Normal>{record.updateApprovedAtText}</Text14Normal>
        </Space>
      )
    },
  }
}

const productSeller = ({ dataIndex = 'product' } = {}): ITableColumn<any> => {
  return {
    title: '상품 셀러',
    dataIndex,
    width: 100,
    render: (v: IModelProduct) => {
      return <Text14Normal>{utilData.tableText(v?.seller?.shopName)}</Text14Normal>
    },
  }
}

const suspendSale = (
  refetchRef: MutableRefObject<Function | null>,
  statusFilterRefetchRef: MutableRefObject<Function | null>
) => {
  return {
    title: '판매중지',
    dataIndex: 'status',
    width: 120,
    render: (status: IModelStatus) => {
      const { useModalProps } = useModal()
      const isSuspend = status.value === 'SUSPEND'
      const statusText = isSuspend ? '해제' : '중지'
      const descriptionText = isSuspend ? '판매 중지를 해제' : '판매를 중지'
      const gqlKey = isSuspend ? 'approveRequest' : 'suspendRequest'
      const fixedProps = {
        title: `상품 ${statusText}`,
        description: <>해당 상품 {descriptionText}하시겠습니까?</>,
      }

      return (
        <MMutation
          gqlKey={gqlKey}
          onAPISuccess={() => {
            if (refetchRef.current) refetchRef.current()
            if (statusFilterRefetchRef.current) statusFilterRefetchRef.current()
          }}
        >
          {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
            const onActions = (values?: Store) => {
              mutation({
                variables: { id: status.id, input: { message: values?.message } },
              })
            }
            return (
              <>
                <MTextButton onClick={() => useModalProps.toggleModal()}>[{statusText}]</MTextButton>
                {/* TODO yoon: ModalConfirm 으로 변경 필요 */}
                <MConfirmModal
                  useModalProps={useModalProps}
                  fixedProps={fixedProps}
                  loading={loading}
                  onAction={() => onActions()}
                />
              </>
            )
          }}
        </MMutation>
      )
    },
  }
}

const productMainImage = ({
  title = '이미지',
  dataIndex = ['mainImage', 'thumbnail'] as string | string[],
  fixed = false,
  width = 70,
} = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    width,
    fixed,
    render: (value: string, record: IModelProduct) => {
      if (value) {
        return (
          <div>
            <a href={`${process.env.FRONT_WEB_DOMAIN}/products/${record._id}`} rel="noreferrer" target="_blank">
              <Image preview={false} src={getFullUrl(value)} width={50} height={50} />
            </a>
          </div>
        )
      }
      return <Text14Normal>-</Text14Normal>
    },
  }
}

const stockQuantity = (): ITableColumn<any> => {
  return {
    title: '재고수량',
    dataIndex: 'stockQuantity',
    width: 100,
    render: (v: number) => {
      if (v < 0) return <Text14Normal>∞</Text14Normal>
      return <Text14Normal>{utilData.numberCountText(v)}</Text14Normal>
    },
  }
}

const openProductInfoChangePage = () => {
  return {
    title: '정보 변경 신청',
    width: 70,
    render: (record: IModelProduct) => {
      const isRequest = !_.isEmpty(record.updateStatus) && record.updateStatus.value === STATUS.REQUEST
      return (
        <>
          {!isRequest && <>-</>}
          {isRequest && (
            <MLinkRouterButton href={ROUTES.PRODUCTS.PRODUCT_INFO_CHANGE.replace('[id]', record._id)} openNewWindow>
              <Text12Blue>[보기]</Text12Blue>
            </MLinkRouterButton>
          )}
        </>
      )
    },
  }
}

const openProductInfoChangeModal = () => {
  return {
    title: '정보 변경 신청',
    width: 70,
    render: (record: IModelProduct) => {
      if (!record?.updateStatus?.isRequest) {
        return '-'
      }
      const { id } = record
      const { useModalProps } = useModal()
      return (
        <>
          <MTextButton onClick={() => useModalProps.toggleModal()}>[보기]</MTextButton>
          <MModal
            useModalProps={useModalProps}
            ComponentContent={
              <MQuery queryParams={{ gqlKey: 'productUpdateInfo', variables: { id } }} loader>
                {({ data }) => {
                  console.log(data, 'data')
                  return <></>
                }}
              </MQuery>
            }
            onAction={() => {
              console.log('onAction')
            }}
          />
        </>
      )
    },
  }
}

const rewardRateText = ({
  title,
  dataIndex,
  fixed,
  width = 100,
}: ITableColumnTitleDataIndexProps): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: Array.isArray(dataIndex) ? dataIndex[dataIndex.length - 1] : dataIndex!,
    fixed,
    width,
    render: (value: number) => {
      if (value === null) {
        return <Text14Normal>기본 적립률</Text14Normal>
      }
      return <Text14Normal>{`${utilData.tableNumberText((value * 100).toFixed(2))}%`}</Text14Normal>
    },
  }
}

export {
  productEdit, // 상품 상세 수정
  productName, // 상품명
  productCustomerPrice, // 상품 판매가(기간 할인 포함)
  productCopy, // 상품 복사
  productCode, // 상품 번호
  // productSoldQuantity, // 상품 건수
  productCreatedAtModifiedAt, // 상품 등록일, 수정일
  productSeller, // 상품 셀러
  suspendSale, // 판매 중지
  productMainImage, // 메인 thumbnail 이미지
  stockQuantity, // 재고 수량
  openProductInfoChangePage, // 정보 변경 신청 페이지로 이동
  openProductInfoChangeModal, // 정보 변경 상세 확인 모달
  rewardRateText,
}
