import { localUserRole, localUserToken } from '@libs/utils/utilLocalStorage'

export const getRedirectManageProductUrlV3 = (pathname: string) => {
  const host =
    process.env.FITPETMALL_ENV === 'production' ? 'https://admin.fitpetmall.com' : 'https://admin-stage.fitpetmall.com'

  switch (pathname) {
    case '/products/request-product-list':
      return `${host}/product/request-list`
    case '/products/product-list':
      return `${host}/product/product-list`
    case '/products/delete-product-list':
      return `${host}/product/wait-delete-list`
    case '/products/products/add':
      return `${host}/product/register`
    case '/products/excel-upload':
      return `${host}/product/unregister-list`
    case '/products/category':
      return `${host}/product/category`
    default:
      return ''
  }
}

export const redirectManageProductUrlV3 = (pathname: string) => {
  const token = localUserToken.load()
  const redirectUrl = getRedirectManageProductUrlV3(pathname)

  if (redirectUrl) {
    window.open(`${redirectUrl}?token=${token}`, '_blank')
  }
}
