import { IModelProductPromotionDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelProductPromotionDefault } from 'containers/models/base/defaultModels'
import * as utilData from '@libs/utils/utilData'

export interface IModelProductPromotion extends IModelProductPromotionDefault {
  maxOrderCountText: string
  minOrderAmountText: string
}

export default class ModelProductPromotion extends ModelProductPromotionDefault {
  maxOrderCountText = ''
  minOrderAmountText = ''
  constructor(data: IModelProductPromotion) {
    super(data)

    this.maxOrderCountText =
      data.maxOrderCount && data.maxOrderCount > 0 ? `${utilData.numberWithCommas(data.maxOrderCount)}` : '-'
    this.minOrderAmountText =
      data.minOrderAmount && data.minOrderAmount > 0 ? `${utilData.numberWithCommas(data.minOrderAmount)}` : '-'
  }
}
