import MFormItemWrapper, { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { Input } from 'antd'

type MFormItemHiddenProps = {} & MFormItemWrapperProps

const MFormItemHidden: React.FC<MFormItemHiddenProps> = ({ ...formItemWrapper }) => {
  return (
    <MFormItemWrapper {...formItemWrapper} hidden>
      <Input />
    </MFormItemWrapper>
  )
}

export default MFormItemHidden
