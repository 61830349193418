import { IModelAsInfoDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelAsInfoDefault } from 'containers/models/base/defaultModels'

export interface IModelAsInfo extends IModelAsInfoDefault {}

export default class ModelAsInfo extends ModelAsInfoDefault {
  constructor(data: IModelAsInfo) {
    super(data)
  }
}
