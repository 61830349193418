import { gql } from '@apollo/client'

export const BILLING_MUTATION_GQLS = {
  CONFIRM_SELLER_BILLING: gql`
    mutation confirmSellerBilling($id: ID!, $input: ConfirmBillingInput!) {
      confirmSellerBilling(id: $id, input: $input) {
        sellerBilling {
          id
          confirmedAt
        }
      }
    }
  `,
}

export default BILLING_MUTATION_GQLS
