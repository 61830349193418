import { gql } from '@apollo/client'

const ORDER_MUTATION_GQLS = {
  ACCEPT_EXCHANGE_RETURN_INFOS: gql`
    mutation acceptExchangeReturnInfos($input: ReturnInfosInput!) {
      acceptExchangeReturnInfos(input: $input) {
        returnInfos {
          id
        }
      }
    }
  `,
  ACCEPT_RETURN_INFOS: gql`
    mutation acceptReturnInfos($input: ReturnInfosInput!) {
      acceptReturnInfos(input: $input) {
        returnInfos {
          id
        }
      }
    }
  `,
  CANCEL_ORDER_ITEMS: gql`
    mutation cancelOrderItems($input: CancelOrderItemsInput!) {
      cancelOrderItems(input: $input) {
        orderItems {
          id
        }
      }
    }
  `,
  CHECK_ORDER_ITEMS: gql`
    mutation checkOrderItems($input: OrderItemsInput!) {
      checkOrderItems(input: $input) {
        orderItems {
          id
        }
      }
    }
  `,
  CONFIRM_CANCEL_ORDER_ITEM: gql`
    mutation confirmCancelOrderItem($id: ID!) {
      confirmCancelOrderItem(id: $id) {
        orderItem {
          id
        }
      }
    }
  `,
  CONFIRM_EXCHANGE_RETURN_INFOS: gql`
    mutation confirmExchangeReturnInfos($input: ConfirmExchangeReturnInfosInput!) {
      confirmExchangeReturnInfos(input: $input) {
        returnInfos {
          id
        }
      }
    }
  `,
  CONFIRM_RETURN_INFOS: gql`
    mutation confirmReturnInfos($input: ConfirmReturnInfosInput!) {
      confirmReturnInfos(input: $input) {
        returnInfos {
          id
        }
      }
    }
  `,
  REJECT_EXCHANGE_RETURN_INFOS: gql`
    mutation rejectExchangeReturnInfos($input: RejectExchangeReturnInfosInput!) {
      rejectExchangeReturnInfos(input: $input) {
        returnInfos {
          id
        }
      }
    }
  `,
  REJECT_RETURN_INFOS: gql`
    mutation rejectReturnInfos($input: RejectReturnInfosInput!) {
      rejectReturnInfos(input: $input) {
        returnInfos {
          id
        }
      }
    }
  `,
  RETURN_ORDER_ITEMS: gql`
    mutation returnOrderItems($input: ReturnOrderItemsInput!) {
      returnOrderItems(input: $input) {
        orderItems {
          id
        }
      }
    }
  `,
  RETURNED_RETURN_INFOS: gql`
    mutation returnedReturnInfos($input: ReturnedReturnInfosInput!) {
      returnedReturnInfos(input: $input) {
        returnInfos {
          id
        }
      }
    }
  `,
  CHECK_UNCONFIRMED_TRANSACTION: gql`
    mutation checkUnconfirmedTransactionDefault($id: ID!) {
      checkUnconfirmedTransaction(id: $id) {
        isSuccess
      }
    }
  `,
  COMPLETE_SHIPPING_ORDER_ITEMS: gql`
    mutation completeShippingOrderItemsMutation($input: OrderItemsInput!) {
      completeShippingOrderItems(input: $input) {
        __typename
      }
    }
  `,
}

export default ORDER_MUTATION_GQLS
