import { Row, Col } from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Text14A65 } from 'components/@atomics/texts/TextBase'
import { themes } from '@constants'
import { MButton } from 'components/@atomics/buttons'

type MFormImageUploadProps = {
  index: number
  id?: string
  imageSrc?: any
  onImageLoad: (base64: string | ArrayBuffer | null, file: any) => void
  onImageDelete: () => void
  disabled?: boolean
}

const MFormImageUpload: React.FC<MFormImageUploadProps> = ({
  index,
  imageSrc,
  onImageLoad,
  id = 'imageUpload',
  onImageDelete,
  disabled = false,
}) => {
  const onButtonPress = () => {
    if (disabled) return
    if (document.getElementById(`${id}_${index}`) !== null) {
      document.getElementById(`${id}_${index}`)?.click()
    }
  }

  const handleChange = (e: any) => {
    const reader = new FileReader()
    const file = e.target.files[0]
    if (!file || file === null) return

    const types = ['image/png', 'image/jpeg']
    if (types.every((type) => file.type !== type)) {
      return
    }

    reader.onloadend = () => {
      onImageLoad(reader.result, file)
    }

    reader.readAsDataURL(file)
  }

  return (
    <Container>
      <Button onClick={onButtonPress}>
        {imageSrc && <Image src={imageSrc} />}
        <ButtonInside>
          {!imageSrc && (
            <>
              <Row justify="center">
                <PlusIcon />
              </Row>
              <Row justify="center">
                <Text14A65>업로드</Text14A65>
              </Row>
            </>
          )}
        </ButtonInside>
        <input type="file" accept=".jpg,.jpeg,.png" id={`${id}_${index}`} onChange={handleChange} />
      </Button>
      {imageSrc && (
        <EditButtonRow justify="center" gutter={16}>
          <Col>
            <MButton type="primary" onClick={onButtonPress}>
              <EditOutlined />
              수정
            </MButton>
          </Col>
          <Col>
            <MButton type="primary" onClick={onImageDelete}>
              <DeleteOutlined />
              삭제
            </MButton>
          </Col>
        </EditButtonRow>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 200px;
  margin-bottom: 10px;
`

const Button = styled.div`
  width: 200px;
  height: 250px;
  border: dashed 1px #acacac;
  cursor: pointer;
  flex: 1;
  position: relative;
  background-color: #f6f6f6;

  :hover {
    border: dashed 1px ${themes.colors.blue};
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ButtonInside = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PlusIcon = styled(PlusOutlined)`
  font-size: 20px;
  color: ${themes.colors.blackAlpha65};
`

const EditButtonRow = styled(Row)`
  margin-top: 5px;
`

export default MFormImageUpload
