import React, { ReactElement, useRef } from 'react'
import { MMainTable, MMutation } from 'components'
import { MMainTableProps } from 'components/MMainTable'
import tableCols from 'domains/admin/tableCols'
import { queryParamsProps } from 'components/MQuery'
import { MutationFunction } from '@apollo/client'
import { ButtonSelectFromTable } from 'domains/common'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { IModelReviewGroup } from 'containers/models/modelReviewGroup'
import { IModelProduct } from 'containers/models/modelProduct'
import { message } from 'antd'

const tableColumns = () => {
  return [
    tableCols.common.baseText({ title: '번호', dataIndex: '_id', width: 50, fixed: true }),
    tableCols.review.reviewGroupName(),
    tableCols.common.baseText({ title: '총 상품 개수', dataIndex: 'productCount', width: 50 }),
    tableCols.common.baseText({ title: '총 리뷰 개수', dataIndex: 'totalCount', width: 50 }),
    tableCols.common.baseText({ title: '리뷰 평균 점수', dataIndex: 'averageScore', width: 50 }),
    {
      title: '수정/삭제',
      key: '',
      width: 140,
      render: (reviewGroup: IModelReviewGroup) => {
        return (
          <MMutation
            gqlKey="updateReviewGroupProducts"
            onAPISuccess={async () => {
              await message.success('변경되었습니다.')
            }}
          >
            {(mutation: MutationFunction) => {
              return (
                <>
                  <ButtonSelectFromTable
                    modelType="product"
                    title="상품 선택"
                    isPlural
                    data={reviewGroup.reviewGroupProducts}
                    onAction={(selectedValues: IModelProduct[]) => {
                      const variables = MUTATION_VARIABLES.UPDATE_REVIEW_GROUP_PRODUCTS({
                        id: reviewGroup.id,
                        products: selectedValues.map((product) => product.id),
                      })
                      mutation({ variables })
                    }}
                  />
                </>
              )
            }}
          </MMutation>
        )
      },
    },
  ]
}

const MainTableQnaReviewGroupList = (): ReactElement<MMainTableProps> => {
  const refetchRef = useRef<any>(null)
  const queryParams: queryParamsProps = {
    gqlKey: 'reviewGroupList',
    dataKey: 'reviewGroups',
    variables: { orderBy: '-id' },
  }

  return (
    <MMainTable
      TitleSet={{ title: '전체 리뷰 그룹 목록', unit: '개' }}
      columns={tableColumns()}
      queryParams={queryParams}
      refetchRef={refetchRef}
    />
  )
}

export default MainTableQnaReviewGroupList
