// @ts-nocheck

import { useQuery } from '@apollo/client'

import * as utilData from '@libs/utils/utilData'
import * as utilApi from '@libs/utils/utilApi'
import { getResultFromData } from '@libs/utils/utilApi'
import { QUERY_KEY2GQLS } from 'containers/gqls'
import { onCompletedType } from 'containers/hooks/useMMutation'

const useMQuery = (
  gqlKey: string,
  variables: object | undefined,
  onAPISuccess?: onCompletedType,
  option?: {
    fetchPolicy?: 'cache-first' | 'network-only' | 'cache-only' | 'no-cache' | 'standby'
  }
) => {
  // const _variables = { variables: { filter: variables } }
  // console.log(variables)

  const gqlQuery = QUERY_KEY2GQLS[gqlKey]
  const _variables = variables ? { variables: utilData.deepcopy(variables) } : undefined
  // console.log(_variables)
  const { loading, error, data, refetch, called } = useQuery(gqlQuery, {
    ...option,
    ..._variables,
    onCompleted(resultData) {
      if (!onAPISuccess) return

      const hasDataWithoutError = utilApi.maybe(() => !!Object.keys(resultData).length, false) && called && !error
      if (!hasDataWithoutError) return
      const result: any = getResultFromData(resultData)
      onAPISuccess(result)
    },
  })
  // data manipulation
  const result = getResultFromData(data)
  return { loading, error, data: result, refetch }
}

export default useMQuery
