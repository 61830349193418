import { NamePath, Rule, StoreValue } from 'rc-field-form/lib/interface'

import { FIELD_KEYS, FIELD_VALUES, POLICY_DATA } from '@constants/constData'
import * as utilForm from '@libs/utils/utilForm'
import * as utilFormValidators from '@libs/utils/utilFormValidators'
import { validateTextAreaLimit } from '@libs/utils/utilFormValidators'
import { COUPON_ISSUE_TYPE, DISPLAY_COLLECTION_TYPE } from '@constants/constDomain'
import { FormInstance } from 'antd/lib/form'
import { DisplayCollectionFormType } from 'domains/admin/forms/FormDisplayCollection'
import { DisplayCollectionTextLimit } from 'constants/display-collection-text-limit.enum'

/**
 * async-validator: Antd Form에서 사용하는 form validator library
 *  - usages와 API 정의를 확인할 수 있음
 *  - URL: https://github.com/yiminghe/async-validator
 *  - Type 참고: rc-field-form/lib/interface.d.ts
 * pattern에 정규식 사용하기:
 *  - URL: https://stackoverflow.com/questions/61040985/regex-pattern-match-validation-for-form-does-not-work-in-ant-design
 */

type validatorRuleType = Rule & {
  field?: string
  fullField?: string
  required?: boolean
}

/**
 * Rule은 FormInstance 타입을 가져야함
 * @{link} https://ant.design/components/form/#Rule
 */
export const FORM_ITEM_RULES = {
  FORM_PRODUCT_NAME_RULE: {
    pattern: /^[ㄱ-힣\d\w\s~!%&*()\-+<>\[\]/.,]+$/,
    message: '상품명은 한글, 영문, 숫자, 띄어쓰기와 다음 특수문자만 입력 가능합니다. ~ ! % & * () - _ + <> [] / . ,',
  },
  FORM_ITEM_RULE_BACKGROUND_IMAGE: {
    required: true,
    validator: async (rule: validatorRuleType, value: File) => {
      /** 이미지 사이즈 2MB로 제한 */
      const imageLimit = 2000000
      return value.size > imageLimit
        ? utilFormValidators.fail('이미지 크기는 2MB를 초과할 수 없습니다.')
        : utilFormValidators.pass()
    },
  },
  FORM_ITEM_RULE_TEXTAREA_DISPLAY_COLLECTION_NAME: (form: FormInstance<DisplayCollectionFormType>) => ({
    required: true,
    validator: async (rule: validatorRuleType, value: StoreValue) => {
      const type = form.getFieldValue('collectType')
      await validateTextAreaLimit(value, DisplayCollectionTextLimit.DefaultTextAreaTitle)

      return utilFormValidators.pass()
    },
  }),
  FORM_ITEM_RULE_MAGAZINE_BACKGROUND_IMAGE_NAME: (form: FormInstance<DisplayCollectionFormType>) => ({
    required: true,
    validator: async (rule: validatorRuleType, value: StoreValue) => {
      const type = form.getFieldValue('collectionType')
      if (type === DISPLAY_COLLECTION_TYPE.MAGAZINE_BACKGROUND_IMAGE) {
        await validateTextAreaLimit(value, DisplayCollectionTextLimit.MagazineBackgroundImageTitle)
      }

      return utilFormValidators.pass()
    },
  }),
  FORM_ITEM_RULE_DESCRIPTION: (form: FormInstance<DisplayCollectionFormType>) => ({
    required: true,
    validator: async (rule: validatorRuleType, value: StoreValue) => {
      const type = form.getFieldValue('collectionType')
      if (type === DISPLAY_COLLECTION_TYPE.MAGAZINE_BACKGROUND_IMAGE) {
        await validateTextAreaLimit(value, DisplayCollectionTextLimit.MagazineBackgroundImageDescription)
      }

      if (type === DISPLAY_COLLECTION_TYPE.MAGAZINE_IMAGE) {
        await validateTextAreaLimit(value, DisplayCollectionTextLimit.MagazineImageDescription)
      }

      return utilFormValidators.pass()
    },
  }),
  FORM_ITEM_RULE_REQUIRED: {
    required: true,
    validator: async (rule: validatorRuleType, value: StoreValue) => {
      const fieldValuesKey = Object.keys(FIELD_KEYS).find((key: string) => {
        const fieldKey = (FIELD_KEYS as any)[key]
        if (fieldKey) {
          return fieldKey === rule.field
        }
        return false
      })

      if (value === undefined || value === null || value === '' || value.length === 0) {
        let failMessage = '이 항목을 반드시 입력해주세요'
        if (fieldValuesKey) {
          const fieldValue = (FIELD_VALUES as any)[fieldValuesKey]
          if (fieldValue) {
            failMessage = `${fieldValue}을/를 입력해주세요`
          }
        }
        await utilFormValidators.fail(failMessage)
      }
      return utilFormValidators.pass()
    },
  },
  FORM_ITEM_TEXT_AREA_RULE_REQUIRED: {
    required: true,
    validator: async (rule: validatorRuleType, value: StoreValue) => {
      if (!value?.length) {
        const failMessage = '쿠폰설명을 입력해주세요.'
        await utilFormValidators.fail(failMessage)
      }
      return utilFormValidators.pass()
    },
  },
  FORM_ITEM_RULE_NOT_DISABLED: ({ disabled, message }: { disabled: boolean; message: string }) => ({
    validator: async (_: any, value?: string) => {
      if (!disabled) {
        // TODO : error text와 아래 comment가 겹침
        if (!value) {
          await utilFormValidators.fail(message)
        }
      }
      return utilFormValidators.pass()
    },
  }),
  FORM_COUPONTYPE_ITEM_RULE_NOT_DISABLED: ({
    disabled,
    message,
    issueType,
  }: {
    disabled: boolean
    message: string
    issueType: COUPON_ISSUE_TYPE
  }) => ({
    validator: async (_: any, value: string) => {
      const valueNumber = Number(value)
      if (!disabled) {
        if (issueType === COUPON_ISSUE_TYPE.PAPER_SKT && valueNumber < 0) {
          await utilFormValidators.fail(message)
        }
        if (issueType !== COUPON_ISSUE_TYPE.PAPER_SKT && !valueNumber) {
          await utilFormValidators.fail(message)
        }
      }
      return utilFormValidators.pass()
    },
  }),
  FORM_ITEM_RULE_NOT_EMPTY_LIST: ({ message }: { message: string }) => ({
    validator: async (_: Rule, value: string) => {
      if (!value || value?.length === 0) {
        await utilFormValidators.fail(message)
      }
      return utilFormValidators.pass()
    },
  }),
  FORM_ITEM_RULE_EMAIL: { type: 'email', message: '올바른 이메일 형식을 입력해 주세요' } as { [key: string]: Rule },
  FORM_ITEM_RULE_EMAIL_ID: { type: 'email', message: '올바른 형식의 이메일 아이디를 입력해 주세요' } as {
    [key: string]: Rule
  },
  FORM_ITEM_RULE_URL: { type: 'url', message: '올바른 URL 주소 형식을 입력해 주세요' } as { [key: string]: Rule },
  FORM_ITEM_RULE_PASSWORD: { required: true, message: '비밀번호를 입력해 주세요' },
  FORM_ITEM_RULE_PASSWORD_FORMAT: {
    validator: async (_: validatorRuleType, value: StoreValue) => {
      const { isValid, errorMsg } = utilFormValidators.isValidPasswordFormat(value)
      if (!isValid) {
        await utilFormValidators.fail(errorMsg)
      }
      return utilFormValidators.pass()
    },
  },
  FORM_ITEM_RULE_COVER_BACKGROUND_COLOR: { required: true, message: '커버 배경색을 입력해 주세요' },
  FORM_ITEM_RULE_BANNER_NAME: { required: true, message: '배너 이름을 입력해 주세요' },
  FORM_ITEM_RULE_DISPLAY_ORDER: { required: true, message: '순서를 입력해 주세요' },
  FORM_ITEM_RULE_EVENT_NAME: { required: true, message: '이벤트명을 입력해 주세요' },
  FORM_ITEM_RULE_EVENT_DESCRIPTION: { required: true, message: '이벤트 한 줄 설명을 입력해 주세요' },
  FORM_ITEM_RULE_AD_PERIOD: { required: true, message: '진행 기간을 입력해주세요' },
  FORM_ITEM_RULE_AD_DISPLAY_PERIOD: { required: true, message: '노출 기간을 입력해주세요' },
  FORM_ITEM_RULE_AD_PRODUCT_INFOS_NAME: { required: true, message: '섹션명을 입력해주세요' },
  FORM_ITEM_RULE_PET_TYPE: { required: true, message: '펫타입을 선택해주세요' },
  FORM_ITEM_RULE_BIRTHDAY: {
    pattern: /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
    message: '입력하신 생년월일을 확인해주세요',
  },
  FORM_ITEM_RULE_APP_VERSION_CODE: {
    pattern: /^(?:(?:[0-9]?[0-9][0-9]?)\.){2}(?:[0-9]?[0-9][0-9]?)$/,
    message: '앱 버전 코드를 정확히 입력해주세요.',
  },
  FORM_ITEM_RULE_ALLOWED_SPECIAL_CHARACTER: {
    pattern: /^[ \n\u3131-\uD79Da-zA-Z0-9!@#$%^&*()_+=`.~-]+$/g,
    message: '특수문자는 !@#$%^&*()_+=`.~-만 허용됩니다',
  },
  FORM_ITEM_RULE_MOBILE_NUMBER: {
    validator: (_: any, value: string) => {
      return utilFormValidators.mobilNumberValidator(value)
    },
  },
  FORM_ITEM_RULE_PHONE_NUMBER: {
    validator: (_: any, value: string) => {
      return utilFormValidators.phoneNumberValidator(value)
    },
  },
  FORM_ITEM_RULE_PHONE_NUMBER_LIST: {
    validator: (_: any, value: string) => {
      return utilFormValidators.phoneNumberListValidator(value, ',')
    },
  },
  FORM_ITEM_RULE_BANK_NUMBER: {
    validator: (_: any, value: string) => {
      return utilFormValidators.numberWithHyphenValidator(value)
    },
  },
  FORM_ITEM_RULE_AGREE_AGE: {
    required: true,
    validator: (_: any, value?: boolean) => {
      return utilFormValidators.checkboxRequiredValidator(value, '만 14세 이상 확인 동의에 동의해주세요')
    },
  },
  FORM_ITEM_RULE_AGREE_AGREEMENT: {
    required: true,
    validator: (_: any, value?: boolean) => {
      return utilFormValidators.checkboxRequiredValidator(value, '이용약관 동의에 동의해주세요')
    },
  },
  FORM_ITEM_RULE_AGREE_PRIVACY_POLICY: {
    required: true,
    validator: (_: any, value?: boolean) => {
      return utilFormValidators.checkboxRequiredValidator(value, '개인정보 수집 및 이용 동의에 동의해주세요')
    },
  },
  FORM_ITEM_RULE_AGREE_ESSENTIAL_POLICY: {
    required: true,
    validator: (_: any, value?: boolean) => {
      return utilFormValidators.checkboxRequiredValidator(value, '필수 정보 수집에 동의해주세요')
    },
  },
  FORM_ITEM_RULE_INSTAGRAM_FEED_URL: {
    pattern: /^(?:https?:\/\/)?(?:www\.)?instagram\.com.*\/p\/([\d\w\-_]+)\/?(\?.*)?$/,
    message: '입력하신 SNS 링크 주소를 확인해주세요',
  },
  FORM_ITEM_RULE_STARTS_HTTPS: {
    required: true,
    validator: (_: any, value: string) => {
      if (/^https/.test(value)) {
        return utilFormValidators.fail('https 주소는 입력할 수 없습니다.')
      }
      return utilFormValidators.pass()
    },
  },
  FORM_ITEM_RULE_VALUE_CONDITIONAL_REQUIRED: ({
    getFieldValue,
    key,
    msg,
  }: {
    getFieldValue: (name: NamePath) => StoreValue
    key: string
    msg: string
  }) => ({
    validator(_: any, value: string) {
      if (!value && getFieldValue(key)) {
        return utilFormValidators.fail(msg)
      }
      return utilFormValidators.pass()
    },
  }),
  FORM_ITEM_RULE_CONFIRM_PASSWORD: ({ getFieldValue }: { getFieldValue: (name: NamePath) => StoreValue }) => ({
    validator(_: any, value: string) {
      if (
        !value ||
        (getFieldValue(FIELD_KEYS.FIELD_PASSWORD) || getFieldValue(FIELD_KEYS.FIELD_NEW_PASSWORD)) === value
      ) {
        return utilFormValidators.pass()
      }
      return utilFormValidators.fail('비밀번호와 다릅니다. 다시 확인해 주세요')
    },
  }),
  FORM_ITEM_RULE_IS_CERT_NUMBER: ({ getFieldValue }: { getFieldValue: (name: NamePath) => StoreValue }) => ({
    validator() {
      if (getFieldValue(FIELD_KEYS.FIELD_IS_CERT_NUMBER)) {
        return utilFormValidators.pass()
      }
      return utilFormValidators.fail('핸드폰 번호 인증을 진행해주세요')
    },
  }),
  FORM_ITEM_RULE_NOTICE_GUIDE: {
    validator(_: any, value: string) {
      if (!value || value.length <= 150) {
        return utilFormValidators.pass()
      }
      return utilFormValidators.fail('150자 까지만 입력 가능합니다.')
    },
  },
  FORM_ITEM_RULE_IMAGE_FILESIZE: {
    validator(_: any, value?: File) {
      return utilFormValidators.checkFilesizeValidator(value, 0, POLICY_DATA.IMAGE_FILE_SIZE_BYTES)
    },
  },
  FORM_ITEM_RULE_REGISTRATION_NUMBER: {
    validator(_: any, value?: string) {
      return utilFormValidators.registrationNumberValidator(value)
    },
  },
  FORM_ITEM_RULE_NUMBER_VALUE: ({ minNumber, maxNumber }: { minNumber: number; maxNumber: number }) => ({
    validator(_: any, value?: number) {
      return utilFormValidators.checkNumberRangeValidator(value, minNumber, maxNumber, true)
    },
  }),
  FORM_ITEM_RULE_DISCOUNT_PRICE: ({ minNumber, maxNumber }: { minNumber: number; maxNumber: number }) => ({
    validator(_: any, value?: number) {
      return utilFormValidators.checkNumberRangeValidator(value, minNumber, maxNumber, false)
    },
  }),
  FORM_ITEM_RULE_EXCEL_ROW_COUNT: ({
    excelFileMaxRowCount = POLICY_DATA.DEFAULT_EXCEL_FILE_MAX_ROW_COUNT,
  }: {
    excelFileMaxRowCount?: number
  }) => ({
    validator(_: any, value?: number) {
      return utilFormValidators.checkNumberRangeValidator(value, 0, excelFileMaxRowCount)
    },
  }),
  FORM_ITEM_RULE_REVIEW_WEIGHT: {
    validator(_: any, value?: number) {
      return utilFormValidators.checkNumberRangeValidator(value, 0, POLICY_DATA.REVIEW_WEIGHT_MAX_COUNT)
    },
  },
  FORM_ITEM_RULE_SKU: {
    validator(_: any, value?: string) {
      if (value) {
        if (value.length <= 0) {
          return utilFormValidators.fail('SKU를 선택해주세요.')
        }
      }
      return utilFormValidators.pass()
    },
  },
  FORM_ITEM_RULE_CORRESPONDENT: {
    validator(_: any, value?: string) {
      return utilFormValidators.correspondentValidator(value)
    },
  },
  FORM_ITEM_RULE_URL_SLUG: {
    pattern: /^[-a-zA-Z0-9_]+$/,
    message: '영어 대/소문자, 숫자, -, _ 만 입력할 수 있습니다.',
  },
  FORM_ITEM_MILEAGE_BENEFIT: ({ getFieldValue }: { getFieldValue: (name: NamePath) => StoreValue }) => ({
    validator(_: any, value?: number) {
      if (value) {
        if (value <= 0) {
          return utilFormValidators.fail('0 이상의 값을 입력해주세요!')
        }
        const inputMethod = getFieldValue('inputMethod')
        if (inputMethod === 'rate') {
          if (value > 100) {
            return utilFormValidators.fail('비율의 경우 0부터 100 사이의 값을 입력해주세요!')
          }
        }
      }
      return utilFormValidators.pass()
    },
  }),
  FORM_ITEM_RULE_KEYWORD: {
    required: true,
    validator: async (rule: validatorRuleType, value: StoreValue) => {
      const isValid = !!value.length
      return isValid ? true : utilFormValidators.fail('이 항목을 반드시 입력해주세요')
    },
  },
  FORM_ITEM_RULE_PERCENT_RATE: {
    validator(_: any, value?: number) {
      if (value) {
        if (value <= 0) {
          return utilFormValidators.fail('0 이상의 값을 입력해주세요!')
        }
        if (value > 100) {
          return utilFormValidators.fail('비율의 경우 0부터 100 사이의 값을 입력해주세요!')
        }
      }
      return utilFormValidators.pass()
    },
  },
}

export const RECENT_DATE_DEFAULT_OPTIONS = utilForm.makeSelectOptions([
  'FIELD_TODAY',
  'FIELD_ONE_WEEK',
  'FIELD_ONE_MONTH',
  'FIELD_THREE_MONTH',
  'FIELD_ONE_YEAR',
  'FIELD_ALL',
])

export const RECENT_DATE_WITH_ALL_OPTIONS = utilForm.makeSelectOptions([
  'FIELD_TODAY',
  'FIELD_ONE_WEEK',
  'FIELD_ONE_MONTH',
  'FIELD_THREE_MONTH',
  'FIELD_ALL',
])

export const RECENT_DATE_BILLING_OPTIONS = utilForm.makeSelectOptions([
  'FIELD_ONE_MONTH',
  'FIELD_TWO_MONTH',
  'FIELD_THREE_MONTH',
  'FIELD_SIX_MONTH',
  'FIELD_ONE_YEAR',
  'FIELD_ALL',
])

export enum FORM_ITEM_TYPES {
  CHECKBOX = 'checkbox',
  IMAGE = 'image',
  INPUT = 'input',
  NUMBER = 'number',
  RADIO_GROUP = 'radioGroup',
  RANGE_DATE = 'rangeDate',
  TEXTAREA = 'textarea',
}
