import { gql } from '@apollo/client'
import CATEGORY_FRAGMENTS from 'containers/gqls/products/category/fragments'

export const CATEGORY_QUERY_GQLS = {
  CATEGORIES_LIST: gql`
    query categoriesList($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      categories(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            id
            name
            code
            text
            isVisible
            description
            listImage
          }
        }
      }
    }
  `,
  CATEGORIES_FOR_FILTER: gql`
    query categoriesForFilter($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      categories(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            id
            name
            code
            text
          }
        }
      }
    }
  `,
  CATEGORIES_FOR_TREE_SELECT: gql`
    query categoriesForTreeSelect($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      categories(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            id
            name
            code
            text
            parentCategory {
              id
            }
          }
        }
      }
    }
  `,
  CATEGORIES: gql`
    query categories($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      categories(pageInfo: $pageInfo, filter: $filter) {
        totalCount
        ...categoriesWithParent
      }
    }
    ${CATEGORY_FRAGMENTS.categoriesWithParent}
  `,
}

export default CATEGORY_QUERY_GQLS
