import { FC } from 'react'
import { DatePicker } from 'antd'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { antdPickerLocale, MFormItemWrapper } from 'components/formItems'
import { DatePickerProps } from 'antd/lib/date-picker'
import { DATE_FORMAT, DATETIME_SECOND_FORMAT } from '@libs/utils/utilDate'

type MFormItemDatePickerProps = {
  formatTime?: boolean
  showTime?: boolean
} & MFormItemWrapperProps &
  Pick<DatePickerProps, 'picker'>

const MFormItemDatePicker: FC<MFormItemDatePickerProps> = (props) => {
  const { formatTime, showTime, picker, name, disabled, ...wrapperProps } = props
  return (
    <MFormItemWrapper name={name} {...wrapperProps}>
      <DatePicker
        locale={antdPickerLocale}
        format={formatTime ? DATETIME_SECOND_FORMAT : DATE_FORMAT}
        picker={picker}
        showTime={showTime}
        disabled={disabled}
      />
    </MFormItemWrapper>
  )
}

export default MFormItemDatePicker
