import { ModelTermStatisticColumnDefault } from 'containers/models/base/defaultModels'
import { IModelTermStatisticColumnDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelTermStatisticColumn extends IModelTermStatisticColumnDefault {}

export default class ModelTermStatisticColumn extends ModelTermStatisticColumnDefault {
  constructor(data: IModelTermStatisticColumn) {
    super(data)
  }
}
