import { TypeUseModalProps } from 'containers/hooks/useModal'
import { IModelDisplayProductInfo } from 'containers/models/modelDisplayProductInfo'
import React, { MutableRefObject, useEffect, useState } from 'react'
import { Checkbox, Col, Form, message, Row } from 'antd'
import { rateToNumber } from '@libs/utils/utilData'
import { FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { MutationFunction } from '@apollo/client'
import { MFormItemInput, MFormItemInputNumber, MFormItemWrapper, MLabeledField, MModal, MMutation } from 'components'
import { FORM_ITEM_RULES } from '@constants/constForm'

const FORM_ITEM_PROPS_NAME = {
  name: FIELD_KEYS.FIELD_NAME,
  label: FIELD_VALUES.FIELD_NAME,
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
  placeholder: '기획전 섹션에 보여지는 상품명입니다. 입력하지 않는 경우, 상품의 이름이 보여집니다.',
}

const FORM_ITEM_PROPS_DISCOUNT_PRICE = {
  name: FIELD_KEYS.FIELD_DISCOUNT_PRICE,
  label: '정액 할인(원)',
  suffix: '원',
  type: 'number',
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
  rules: [
    FORM_ITEM_RULES.FORM_ITEM_RULE_DISCOUNT_PRICE({ minNumber: 0, maxNumber: 1000000000 }),
  ],
}

/*
정률 할인 (정액보다 정률 할인이 우선)
front: discountRate -> 18
back: discount_rate -> 0.18
*/
const FORM_ITEM_PROPS_DISCOUNT_RATE = {
  name: FIELD_KEYS.FIELD_DISCOUNT_RATE,
  // label: '정률 할인(%)',
  suffix: '%',
  type: 'number',
}

const FORM_ITEM_PROPS_FITPET_FEE_RATE = {
  name: FIELD_KEYS.FIELD_FITPET_FEE_RATE,
  inputNumberProps: {
    min: 0,
    max: 100,
    step: 0.1,
  },
  suffix: '%',
}

const FORM_ITEM_PROPS_MILEAGE_REWARD_RATE = {
  name: FIELD_KEYS.FIELD_MILEAGE_REWARD_RATE,
  labelCol: { span: 4 },
  inputNumberProps: {
    min: 0,
    max: 100,
    step: 0.1,
  },
  suffix: '%',
}

const FORM_ITEM_PROPS_MAX_ORDER_COUNT = {
  name: FIELD_KEYS.FIELD_MAX_ORDER_COUNT,
  labelCol: { span: 4 },
  type: 'number',
  suffix: '건',
}

const FORM_ITEM_PROPS_MIN_ORDER_AMOUNT = {
  name: FIELD_KEYS.FIELD_MIN_ORDER_AMOUNT,
  labelCol: { span: 4 },
  type: 'number',
  suffix: '원',
}

const ModalUpdateTodaySpecialProduct = ({
  useModalProps,
  displayProductInfo,
  refetchRef,
  onAction,
}: {
  useModalProps: TypeUseModalProps<any>
  displayProductInfo: IModelDisplayProductInfo
  refetchRef?: MutableRefObject<Function | null>
  onAction?: () => void
}) => {
  const [form] = Form.useForm()
  const { hideModal } = useModalProps
  const gqlKey = 'updateProductPromotion'
  const [usingDefaultFeeRate, setUsingDefaultFeeRate] = useState<boolean>(
    displayProductInfo ? displayProductInfo.productpromotion.fitpetFeeRate === null : false
  )
  const [usingDefaultMileageRewardRate] = useState<boolean>(
    displayProductInfo ? displayProductInfo.productpromotion.mileageRewardRate <= 0 : false
  )
  const [isMaxOrderCountNone, setIsMaxOrderCountNone] = useState<boolean>(
    displayProductInfo ? displayProductInfo.productpromotion.maxOrderCount <= 0 : false
  )
  const [isMinOrderAmountNone, setIsMinOrderAmountNone] = useState<boolean>(
    displayProductInfo ? displayProductInfo.productpromotion.minOrderAmount <= 0 : false
  )
  // 정액, 정률 checkbox
  const [useDiscountRate, setUseDiscountRate] = useState(!!displayProductInfo?.productpromotion?.discountRate)

  const getNumberValueToUpdate = (isNotUsingValue: boolean, value: any): number | null => {
    if (isNotUsingValue || Number.isNaN(Number(value))) {
      return null
    }
    return value
  }

  const getFeeRateToUpdate = (values: any): number | null => {
    const result = getNumberValueToUpdate(usingDefaultFeeRate, values[FORM_ITEM_PROPS_FITPET_FEE_RATE.name])
    return result ? result / 100 : result
  }
  const getMileageRewardRateToUpdate = (values: any): number | null => {
    const result = getNumberValueToUpdate(
      usingDefaultMileageRewardRate,
      values[FORM_ITEM_PROPS_MILEAGE_REWARD_RATE.name]
    )
    return result ? result / 100 : result
  }
  const getMaxOrderCountToUpdate = (values: any): number | null => {
    return getNumberValueToUpdate(isMaxOrderCountNone, values[FORM_ITEM_PROPS_MAX_ORDER_COUNT.name])
  }
  const getMinOrderAmountToUpdate = (values: any): number | null => {
    const minOrderAmount = getNumberValueToUpdate(isMinOrderAmountNone, values[FORM_ITEM_PROPS_MIN_ORDER_AMOUNT.name])
    // minOrderAmount는 0인 경우, 최소 주문 금액이 없는 것으로 판단함
    return minOrderAmount !== null ? minOrderAmount : 0
  }

  const removeFormItemErrors = (_name: string) => {
    form.setFields([{ name: [_name], errors: [] }])
  }

  const getVariables = (values: any): {} => {
    if (usingDefaultFeeRate) {
      values.isDefaultFitpetFeeRate = true
      delete values[FORM_ITEM_PROPS_FITPET_FEE_RATE.name]
    } else {
      values[FORM_ITEM_PROPS_FITPET_FEE_RATE.name] = getFeeRateToUpdate(values)
    }
    if (usingDefaultMileageRewardRate) {
      values.isDefaultMileageRewardRate = true
      delete values[FORM_ITEM_PROPS_MILEAGE_REWARD_RATE.name]
    } else {
      values[FORM_ITEM_PROPS_MILEAGE_REWARD_RATE.name] = getMileageRewardRateToUpdate(values)
    }
    values[FORM_ITEM_PROPS_MAX_ORDER_COUNT.name] = getMaxOrderCountToUpdate(values)
    values[FORM_ITEM_PROPS_MIN_ORDER_AMOUNT.name] = getMinOrderAmountToUpdate(values)

    // 정액, 정률 둘다 없을 경우, err
    if (values[FIELD_KEYS.FIELD_DISCOUNT_PRICE] === null && values[FIELD_KEYS.FIELD_DISCOUNT_RATE] === null) {
      throw Error('할인가, 할인율 중 한가지 항목만 입력해주세요.')
    }
    // null일 경우 property delete
    if (values[FIELD_KEYS.FIELD_DISCOUNT_PRICE] === null) {
      delete values[FIELD_KEYS.FIELD_DISCOUNT_PRICE]
    }
    if (values[FIELD_KEYS.FIELD_DISCOUNT_RATE] === null) {
      delete values[FIELD_KEYS.FIELD_DISCOUNT_RATE]
    }
    // 정액, 정률 둘다 있을 경우, err
    if (values[FIELD_KEYS.FIELD_DISCOUNT_PRICE] && values[FIELD_KEYS.FIELD_DISCOUNT_RATE]) {
      throw Error('할인가, 할인율 중 한가지 항목만 입력해주세요.')
    }
    // 정률할인이 우선, 정률할인이 있으면 정액할인을 제거
    if (values[FIELD_KEYS.FIELD_DISCOUNT_RATE]) {
      delete values[FIELD_KEYS.FIELD_DISCOUNT_PRICE]
      values[FIELD_KEYS.FIELD_DISCOUNT_RATE] = values[FIELD_KEYS.FIELD_DISCOUNT_RATE] / 100
    }

    return {
      id: displayProductInfo!.productpromotion.id,
      input: { ...values },
    }
  }

  const initialValues = () => {
    if (displayProductInfo) {
      const fitpetFeeRate = rateToNumber(
        displayProductInfo.productpromotion.fitpetFeeRate
          ? displayProductInfo.productpromotion.fitpetFeeRate
          : displayProductInfo.productpromotion.defaultFitpetFeeRate
      )
      const discountRateDecimal = displayProductInfo?.productpromotion?.discountRate || 0
      const discountRatePercent = discountRateDecimal * 100
      const discountPrice = discountRatePercent ? 0 : displayProductInfo?.productpromotion?.discountPrice || 0
      return {
        [FORM_ITEM_PROPS_NAME.name]: displayProductInfo.productpromotion.name,
        [FORM_ITEM_PROPS_DISCOUNT_PRICE.name]: discountPrice,
        [FORM_ITEM_PROPS_DISCOUNT_RATE.name]: discountRatePercent,
        [FORM_ITEM_PROPS_FITPET_FEE_RATE.name]: fitpetFeeRate,
        [FORM_ITEM_PROPS_MILEAGE_REWARD_RATE.name]: rateToNumber(displayProductInfo.productpromotion.mileageRewardRate),
        [FORM_ITEM_PROPS_MAX_ORDER_COUNT.name]: displayProductInfo.productpromotion.maxOrderCount,
        [FORM_ITEM_PROPS_MIN_ORDER_AMOUNT.name]: displayProductInfo.productpromotion.minOrderAmount,
      }
    }
    return {
      [FORM_ITEM_PROPS_DISCOUNT_PRICE.name]: undefined,
      [FORM_ITEM_PROPS_DISCOUNT_RATE.name]: undefined,
      [FORM_ITEM_PROPS_DISCOUNT_PRICE.name]: undefined,
      [FORM_ITEM_PROPS_FITPET_FEE_RATE.name]: undefined,
      [FORM_ITEM_PROPS_MILEAGE_REWARD_RATE.name]: undefined,
      [FORM_ITEM_PROPS_MAX_ORDER_COUNT.name]: undefined,
      [FORM_ITEM_PROPS_MIN_ORDER_AMOUNT.name]: undefined,
    }
  }

  useEffect(() => {
    form.setFieldsValue(initialValues())
  }, [displayProductInfo])

  const _onAction = async (mutation: MutationFunction) => {
    const values = await form.validateFields()
    const variables = getVariables(values)
    await mutation({ variables })
    hideModal()
  }

  const Content = () => {
    return (
      <Form
        form={form}
        // initialValues={initialValues()}
      >
        <MFormItemInput {...FORM_ITEM_PROPS_NAME} />
        <MFormItemInputNumber
          {...FORM_ITEM_PROPS_DISCOUNT_PRICE}
          disabled={useDiscountRate}
        />
        <MFormItemWrapper
          style={{ margin: 0 }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          label="정률 할인(%)"
        >
          <Row>
            <Col span={16}>
              <MFormItemInputNumber
                {...FORM_ITEM_PROPS_DISCOUNT_RATE}
                disabled={!useDiscountRate}
              />
            </Col>
            <Col>
              <MFormItemWrapper>
                <Checkbox
                  checked={useDiscountRate}
                  onChange={(e) => {
                    setUseDiscountRate(e.target.checked)
                    if (e.target.checked) {
                      form.setFieldsValue({[FIELD_KEYS.FIELD_DISCOUNT_PRICE]: 0})
                    } else {
                      form.setFieldsValue({[FIELD_KEYS.FIELD_DISCOUNT_RATE]: 0})
                    }
                  }}
                  style={{ marginLeft: 12, marginBottom: 0 }}
                >정률할인 사용</Checkbox>
              </MFormItemWrapper>
            </Col>
          </Row>
        </MFormItemWrapper>
        <MFormItemWrapper
          style={{ margin: 0 }}
          label={FIELD_VALUES.FIELD_FITPET_FEE_RATE}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          required={!usingDefaultFeeRate}
        >
          <Row>
            <Col span={16}>
              <MFormItemInputNumber
                {...FORM_ITEM_PROPS_FITPET_FEE_RATE}
                disabled={usingDefaultFeeRate}
                required={!usingDefaultFeeRate}
              />
            </Col>
            <Col>
              <MFormItemWrapper>
                <Checkbox
                  defaultChecked={usingDefaultFeeRate}
                  onChange={(e) => {
                    setUsingDefaultFeeRate(e.target.checked)
                    removeFormItemErrors(FORM_ITEM_PROPS_FITPET_FEE_RATE.name)
                  }}
                  style={{ marginLeft: 12, marginBottom: 0 }}
                >
                  기본 수수료율 적용
                </Checkbox>
              </MFormItemWrapper>
            </Col>
          </Row>
        </MFormItemWrapper>
        <MFormItemWrapper
          style={{ margin: 0 }}
          label={FIELD_VALUES.FIELD_MAX_ORDER_COUNT}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          required={!isMaxOrderCountNone}
        >
          <Row>
            <Col span={16}>
              <MFormItemInputNumber
                {...FORM_ITEM_PROPS_MAX_ORDER_COUNT}
                disabled={isMaxOrderCountNone}
                required={!isMaxOrderCountNone}
              />
            </Col>
            <Col>
              <MFormItemWrapper>
                <Checkbox
                  defaultChecked={isMaxOrderCountNone}
                  onChange={(e) => {
                    setIsMaxOrderCountNone(e.target.checked)
                    removeFormItemErrors(FORM_ITEM_PROPS_MAX_ORDER_COUNT.name)
                  }}
                  style={{ marginLeft: 12, marginBottom: 0 }}
                >
                  구매 수량 제한 없음
                </Checkbox>
              </MFormItemWrapper>
            </Col>
          </Row>
        </MFormItemWrapper>
        <MFormItemWrapper
          style={{ margin: 0 }}
          label={FIELD_VALUES.FIELD_MIN_ORDER_AMOUNT}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          required={!isMinOrderAmountNone}
        >
          <Row>
            <Col span={16}>
              <MFormItemInputNumber
                {...FORM_ITEM_PROPS_MIN_ORDER_AMOUNT}
                disabled={isMinOrderAmountNone}
                required={!isMinOrderAmountNone}
              />
            </Col>
            <Col>
              <MFormItemWrapper>
                <Checkbox
                  defaultChecked={isMinOrderAmountNone}
                  onChange={(e) => {
                    setIsMinOrderAmountNone(e.target.checked)
                    removeFormItemErrors(FORM_ITEM_PROPS_MIN_ORDER_AMOUNT.name)
                  }}
                  style={{ marginLeft: 12, marginBottom: 0 }}
                >
                  최소 주문 금액 없음
                </Checkbox>
              </MFormItemWrapper>
            </Col>
          </Row>
        </MFormItemWrapper>
      </Form>
    )
  }
  return (
    <MMutation
      gqlKey={gqlKey}
      onAPISuccess={() => {
        if (refetchRef && refetchRef.current) {
          refetchRef.current()
        }
        form.resetFields()
        useModalProps.hideModal()
        message.success('할인 설정이 완료되었습니다.')
        if (onAction) {
          onAction()
        }
      }}
    >
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <MModal
            useModalProps={useModalProps}
            title="할인 설정"
            width={900}
            loading={loading}
            onAction={() => _onAction(mutation)}
            onCancelAction={() => form.setFieldsValue(initialValues())}
            ComponentContent={<Content />}
          />
        )
      }}
    </MMutation>
  )
}

export default ModalUpdateTodaySpecialProduct
