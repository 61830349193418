import { ApolloError } from '@apollo/client'
import { DataBase, DataResult, DataResults } from 'containers/gqls/data'
// import axios from 'axios'
import * as XLSX from 'xlsx'
import { hasData } from '@libs/utils/utilData'

const fileDownload = require('js-file-download')

export function maybe<T>(exp: () => T, d?: T) {
  try {
    const result = exp()
    return result === undefined ? d : result
  } catch {
    return d
  }
}

export function getErrorMessage(error?: ApolloError | string): string {
  if (!error) {
    return ''
  }
  const _message = typeof error === 'string' ? error : error.message
  try {
    const _error = JSON.parse(_message)
    return _error.message
  } catch (e) {
    return _message
  }
}

export function decodeId(_encodeId: string): { typename: string; id: string } {
  /*
    서버에서 scalar ID는 base64로 encoding해서 보내줌
    ex) ID: "U2VsbGVyUm9sZVN0YXR1c1R5cGU6MQ==" => SellerRoleStatusType:1
    참고: https://docs.graphene-python.org/en/latest/relay/nodes/#quick-example
   */

  // Why is window undefined?
  // NextJS is a framework that allows you to build Static and Server Side Rendered Apps.
  // It uses NodeJS to render your application and window is not defined in NodeJS.
  // That means that we need to be careful that our code that accesses the window object is not run in NodeJS.
  const infoId = Buffer.from(_encodeId, 'base64').toString().split(':')
  return { typename: infoId[0], id: infoId[1] }
}

export function encodeId(typename: string, id: string | number): string {
  return Buffer.from(`${typename}:${id.toString()}`).toString('base64')
}

function getDataFromObject(obj: object) {
  if (DataBase.isListData(obj) || Array.isArray(obj)) {
    return new DataResults().setData(obj)
  }
  return new DataResult().setData(obj)
}

// function isMutation(query: any) {
//   const { definitions = [] } = query
//   definitions.forEach((x: any) => {
//     if ('operation' in x) {
//       return x.operation === 'mutation'
//     }
//   })
//   // return query.definitions[0].operation !== 'query'
//   return false
// }

// export function getDataFromResponse(queryOrMutation: DocumentNode, data: object) {
//   // this function called when declared so return if data is undefined
//   if (!data) return
//   // query data can have 2 nodes
//   // array node have __typename with '-Connection', with edges key
//   // nested if value is object, and that value act as key with __typename, value
//
//   if (isMutation(queryOrMutation)) {
//     // eslint-disable-next-line no-param-reassign,prefer-destructuring
//     data = Object.values(data)[0]
//   }
//
//   const res: { [index: string]: DataResult | DataResults } = {}
//   Object.entries(data).forEach((row: any) => {
//     const key: string = row[0]
//     const value = row[1]
//     if (key !== '__typename') {
//       res[key] = getDataFromObject(value)
//     }
//   })
//   return res
// }

export function getResultFromData(
  data: object,
  isQuery: boolean = true
): { [index: string]: DataResult | DataResults } | undefined {
  // this function called when declared so return if data is undefined
  if (!data) return
  // query data can have 2 nodes
  // array node have __typename with '-Connection', with edges key
  // nested if value is object, and that value act as key with __typename, value

  // // For CacheMutations
  // if (data?.__typename?.includes('CacheMutation')) return data.result

  if (!isQuery) {
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    data = Object.values(data)[0]
  }

  const res: { [index: string]: DataResult | DataResults } = {}
  Object.entries(data).forEach(([key, value]) => {
    if (key !== '__typename' && value !== undefined) {
      if (['excelLink', 'isSuccess', 'isTask', 'failedReasons', 'count'].includes(key)) {
        // TODO yoon: Bulk Create Mutation 경우, count와 해당 mutation response의 model type(?) key에 null을 전달 받음.
        // sungsoo: RequestExcel인 경우, isSuccess로 요청 성공 여부를 전달 받음.
        //  model type(?)을 안받을 수 있는지 확인 필요
        res[key] = value
      } else if (hasData(value)) {
        res[key] = getDataFromObject(value)
      }
    }
  })
  return res
}

// if need styling refer below lib
// https://www.npmjs.com/package/xlsx-style#cell-styles
export const downloadTableExcel = (document: Document, tableId: string) => {
  function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length) // convert s to arrayBuffer
    const view = new Uint8Array(buf) // create uint8array as viewer
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff // convert to octet
    return buf
  }

  const wb = XLSX.utils.book_new()
  const newWorksheet = XLSX.utils.table_to_sheet(document.getElementById(tableId))

  Object.keys(newWorksheet).forEach((s) => {
    const cell = newWorksheet[s]
    // for orderNumber, orderItemNumber make string
    if (cell.t === 'n' && cell?.z === undefined) {
      newWorksheet[s].z = '0'
      newWorksheet[s].t = 's'
    }
  })

  XLSX.utils.book_append_sheet(wb, newWorksheet, 'sheet1')
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  // saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${tableId}.xlsx`)
  fileDownload(new Blob([s2ab(wbout)]), `${tableId}.xlsx`)
}
