import { FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { ExpandableConfig } from 'antd/lib/table/interface'

import * as utilData from '@libs/utils/utilData'
import { COUPON_ISSUE_TYPE, TRANSFER_TYPE } from '@constants/constDomain'
import { MButton, MTable, MTextButton } from 'components/@atomics'
import TagsSelected from 'domains/common/TagsSelected'
import ModalTableTransferSelect, {
  modalTableTransferContentProps,
} from 'domains/common/modals/ModalTableTransferSelect'
import useModal from 'containers/hooks/useModal'
import { ModalTableSelect } from 'domains/common/modals'
import tableCols from 'domains/admin/tableCols'
import { modalContentProps } from 'domains/common/modals/ModalTableSelect'
import { IModelProduct } from 'containers/models/modelProduct'

type ButtonSelectFromTableProps = {
  isPlural?: boolean
  displayTag?: boolean
  displayTable?: boolean
  buttonType?: 'primary' | 'text' | 'link' | 'ghost' | 'default' | 'dashed'
  disabled?: boolean
  title: string
  modelType: 'user' | 'seller' | 'product' | 'brand' | 'category' | 'couponGroupExcludeGrade'
  onAction?: (selectedValues: any[]) => void
  data?: any
  doClear?: number
  tagClosable?: boolean
  expandable?: ExpandableConfig<any>
}

const modalTableSelectPluralSeller: modalTableTransferContentProps = {
  transferType: TRANSFER_TYPE.SELLER,
  disableCheckedNumber: 5,
  disableContent: '*셀러는 5개까지 선택 가능합니다.',
}

const modalTableSelectSingleSeller: modalContentProps = {
  title: '셀러 선택',
  queryParams: {
    gqlKey: 'sellersMinimumInfo',
    dataKey: 'sellers',
  },
  searchInputs: [{ label: '셀러명', name: 'shopName' }],
  tableColumns: [
    tableCols.common.ID(),
    tableCols.common.baseText({ title: '셀러명', dataIndex: 'shopName' }),
    tableCols.date.approvedAtText({ title: '등록일' }),
  ],
}

// need to make if want to use USER (not exist yet)
const modalTableSelectPluralUser: modalTableTransferContentProps = {
  transferType: TRANSFER_TYPE.USER,
  disableCheckedNumber: 5,
  disableContent: '*유저는 5개까지 선택 가능합니다.',
}

const modalTableSelectPluralProduct: modalTableTransferContentProps = {
  transferType: TRANSFER_TYPE.PRODUCT,
}

const modalTableSelectPluralBrand: modalTableTransferContentProps = {
  transferType: TRANSFER_TYPE.BRAND,
}

const modalTableSelectPluralCategory: modalTableTransferContentProps = {
  transferType: TRANSFER_TYPE.CATEGORY,
}

const modalTableSingleSelectUser: modalContentProps = {
  title: '사용자 선택',
  queryParams: {
    gqlKey: 'userListForManager',
    dataKey: 'users',
  },
  searchInputs: [{ label: '이메일 ID', name: 'email' }],
  tableColumns: [
    tableCols.common.ID(),
    tableCols.common.baseText({ title: '이메일', dataIndex: 'email', width: 160 }),
    tableCols.common.baseText({ title: '이름', dataIndex: 'name' }),
    tableCols.common.baseText({ title: '연락처', dataIndex: 'mobileNumber' }),
  ],
}

const modalTableSingleSelectCouponGroupExcludeGrade: modalContentProps = {
  title: '쿠폰 선택',
  searchInputs: [{ label: '쿠폰명', name: 'name' }],
  queryParams: {
    gqlKey: 'couponGroups',
    variables: { filter: { excludeIssueType: COUPON_ISSUE_TYPE.GRADE }, orderBy: '-createdAt' },
  },
  tableColumns: [
    tableCols.common.baseText({ title: '쿠폰명', dataIndex: 'name' }),
    tableCols.couponGroup.issueType(),
    tableCols.date.createdAtText({ title: '등록일', width: 120 }),
    tableCols.couponGroup.usingPeriod(),
  ],
}

const modalTableSingleSelectBrand: modalContentProps = {
  title: '브랜드 선택',
  queryParams: {
    gqlKey: 'brands',
  },
  searchInputs: [{ label: '브랜드명', name: 'name' }],
  tableColumns: [
    tableCols.common.baseText({ title: '브랜드 이름', dataIndex: 'name' }),
    tableCols.common.baseTextFromModel({ title: '등록 상태', dataIndex: 'status', key: 'text' }),
    tableCols.date.createdAtText({ title: '등록일', width: 120 }),
  ],
}

const modalTableSingleSelectProduct: modalContentProps = {
  title: '상품 선택',
  width: 1080,
  totalCountSize: 16,
  okText: '선택 완료',
  queryParams: {
    gqlKey: 'productSelectModal',
    dataKey: 'products',
    variables: { filter: { hasOption: true } },
  },
  dateOptions: [
    { label: FIELD_VALUES.FIELD_CREATED_AT, name: FIELD_KEYS.FIELD_CREATED_AT },
    { label: FIELD_VALUES.FIELD_UPDATE_APPROVED_AT, name: FIELD_KEYS.FIELD_UPDATE_APPROVED_AT },
  ],
  searchInputs: [
    { label: FIELD_VALUES.FIELD_PRODUCT_NAME, name: FIELD_KEYS.FIELD_PRODUCT_NAME },
    { label: FIELD_VALUES.FIELD_PRODUCT_CODE, name: FIELD_KEYS.FIELD_PRODUCT_CODE },
  ],
  selectBrands: true,
  selectCategories: true,
  tableColumns: [
    tableCols.common.baseText({ title: '상품코드', dataIndex: '_id', width: 90, fixed: true }),
    tableCols.product.productMainImage(),
    tableCols.product.productName({ title: '상품명', dataIndex: 'name', width: 200 }),
    tableCols.currency.currencyText({ title: '판매가', dataIndex: 'customerPrice' }),
    tableCols.common.baseTextFromModel({ title: '셀러명', dataIndex: 'seller', key: 'shopName' }),
    tableCols.status.statusText({ title: '판매상태' }),
    // for expandable
    { title: '', dataIndex: 'expandIdx', key: 'expandable', width: 1 },
  ],
  expandable: {
    expandIconColumnIndex: 7,
    expandedRowRender: (record: IModelProduct) => {
      return (
        <MTable
          className="nestedTable"
          // dataSource={options}
          dataSource={record.productOptions}
          columns={[
            tableCols.common.baseText({ title: '순서', dataIndex: 'displayOrder', width: 180 }),
            tableCols.common.baseText({ title: 'COLOR/SIZE', dataIndex: 'simpleProductOptionKinds', width: 200 }),
            tableCols.currency.currencyText({ title: '옵션가', dataIndex: 'price', width: 220 }),
            tableCols.common.baseText({ title: '수량', dataIndex: 'stockQuantity', suffix: '개' }),
            {
              title: '사용여부',
              dataIndex: 'isUse',
              render: (value: boolean) => (value ? '사용' : '사용 안함'),
              width: 200,
            },
          ]}
        />
      )
    },
    rowExpandable: (record: IModelProduct) => record.hasOption,
    expandIcon: ({ expanded, onExpand, record }) => {
      if (record.hasOption) {
        return <MButton onClick={(e) => onExpand(record, e)}>옵션 보기 {expanded ? '⋀' : '⋁'}</MButton>
      }
      return (
        <MButton disabled type="text">
          사용 안함
        </MButton>
      )
    },
  },
}

const model2text = {
  seller: '셀러',
  user: '사용자',
  couponGroupExcludeGrade: '쿠폰 그룹',
  product: '상품',
  brand: '브랜드',
  category: '카테고리',
}

const model2pluralTableModalProps = {
  seller: modalTableSelectPluralSeller,
  user: modalTableSelectPluralUser,
  product: modalTableSelectPluralProduct,
  couponGroupExcludeGrade: null,
  brand: modalTableSelectPluralBrand,
  category: modalTableSelectPluralCategory,
}

const model2singleTableModalProps = {
  seller: modalTableSelectSingleSeller,
  user: modalTableSingleSelectUser,
  product: modalTableSingleSelectProduct,
  couponGroupExcludeGrade: modalTableSingleSelectCouponGroupExcludeGrade,
  brand: modalTableSingleSelectBrand,
  category: null,
}

const ButtonSelectFromTable = ({
  isPlural = false,
  displayTag = false,
  displayTable = false,
  buttonType = 'primary',
  disabled,
  title,
  modelType,
  onAction,
  doClear,
  tagClosable = true,
  data = [],
}: ButtonSelectFromTableProps) => {
  // const didMountRef = useRef<boolean>(false)
  const [values, setValues] = useState<any[]>([])
  const { useModalProps } = useModal()
  const modelText = model2text[modelType]
  const singleTableModalProps = model2singleTableModalProps[modelType]
  const pluralTableModalProps = model2pluralTableModalProps[modelType]

  const clear = () => {
    setValues([])
  }
  useEffect(() => {
    clear()
  }, [doClear])

  useEffect(() => {
    if (utilData.hasData(data)) {
      setValues(data)
    }
  }, [])

  useEffect(() => {
    setValues(data)
  }, [JSON.stringify(data)])

  const onSingleItemSelect = async (item: any) => {
    await setValues([item])
    if (onAction) {
      onAction([item])
    }
  }

  const onItemRemoved = (id: string) => {
    const items = values?.filter((ele) => ele.id !== id) || []
    setValues(items)
    if (onAction) {
      onAction(items)
    }
  }

  const onAllItemRemoved = () => {
    const items: any[] = []
    setValues(items)
    if (onAction) {
      onAction(items)
    }
  }

  const ButtonComponent = () => {
    if (buttonType === 'text') {
      return <MTextButton onClick={() => useModalProps.toggleModal()}>[{title}]</MTextButton>
    }
    return (
      <MButton type={buttonType} disabled={disabled} onClick={() => useModalProps.toggleModal()}>
        {title}
      </MButton>
    )
  }

  const DisplayTagComponent = () => {
    return (
      <TagsSelected
        type={modelType}
        label={modelText}
        selectedValues={values}
        marginLeftOffset={20}
        onItemRemoved={onItemRemoved}
        tagClosable={tagClosable}
      />
    )
  }

  const DisplayTableComponent = () => {
    if (displayTable && modelType === 'product') {
      return (
        <>
          <Row>
            <MTable
              dataSource={values}
              pagination={false}
              columns={[
                tableCols.common.ID(),
                tableCols.product.productMainImage(),
                tableCols.product.productName({ width: 300 }),
                {
                  title: '삭제',
                  key: 'deleteButton',
                  render: (record: IModelProduct) => {
                    return <MButton onClick={() => onItemRemoved(record.id)}>삭제</MButton>
                  },
                },
              ]}
            />
          </Row>
          <Row>
            <MButton onClick={onAllItemRemoved}>전체 삭제</MButton>
          </Row>
        </>
      )
    }
    return null
  }
  return (
    <>
      <Row align="middle">
        <Col>
          <ButtonComponent />
        </Col>
        {displayTag && values.length > 0 ? (
          <Col>
            <DisplayTagComponent />
          </Col>
        ) : null}
      </Row>
      {displayTable && values.length > 0 ? <DisplayTableComponent /> : null}
      {isPlural && pluralTableModalProps && (
        <ModalTableTransferSelect
          useModalProps={useModalProps}
          onTransferAction={(selectedValues: any[]) => {
            const newValues = [...values, ...selectedValues]
            setValues(newValues)
            if (onAction) {
              onAction(newValues)
            }
          }}
          chosenValues={values}
          {...pluralTableModalProps}
        />
      )}
      {!isPlural && singleTableModalProps && (
        <ModalTableSelect useModalProps={useModalProps} onItemSelect={onSingleItemSelect} {...singleTableModalProps} />
      )}
    </>
  )
}

export default ButtonSelectFromTable
