import { IModelBankDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelBankDefault } from 'containers/models/base/defaultModels'

export interface IModelBank extends IModelBankDefault {}

export default class ModelBank extends ModelBankDefault {
  constructor(data: IModelBank) {
    super(data)
  }
}
