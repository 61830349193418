import { S3_HOST_URL } from '@constants/constData'
import { keysToCamel } from '@libs/utils/utilData'
import { INFORM_PUSH_QUEUE_STATUS, INFORM_PUSH_TARGET_TYPE_INFO_TEXT } from '@constants/constDomain'
import { IModelInformPushQueueDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelInformPushQueueDefault } from 'containers/models/base/defaultModels'

type targetInfoObjType = {
  userType: {
    normal: number
    styleWaver: number
  }
  device: {
    ios: number
    android: number
    etc: number
  }
  gender: {
    male: number
    female: number
    etc: number
  }
}

export interface IModelInformPushQueue extends IModelInformPushQueueDefault {
  imageUrl: string
  targetText: string
  targetInfoObj: targetInfoObjType
  targetDeviceInfo: string
  targetGenderInfo: string
  targetUserTypeInfo: string
  targetGradeInfo: string
  isSent: boolean
  isReserved: boolean
}

export default class ModelInformPushQueue extends ModelInformPushQueueDefault {
  imageUrl: string = ''
  targetText: string = ''
  targetInfoObj: targetInfoObjType
  targetDeviceInfo: string = ''
  targetGenderInfo: string = ''
  targetUserTypeInfo: string = ''
  targetGradeInfo: string = ''
  isSent: boolean = false
  isReserved: boolean = false

  constructor(data: IModelInformPushQueue) {
    super(data)

    this.imageUrl = `${S3_HOST_URL}/${this.push.image}`
    this.targetInfoObj = keysToCamel(JSON.parse(this.targetInfo || 'null'))
    this.targetUserTypeInfo =
      this.targetInfoObj &&
      Object.entries(this.targetInfoObj.userType)
        .map(([key, num]) => {
          return `${INFORM_PUSH_TARGET_TYPE_INFO_TEXT[key as string]}: ${num}명`
        })
        .join('\n')
    this.targetDeviceInfo =
      this.targetInfoObj &&
      Object.entries(this.targetInfoObj.device)
        .map(([key, num]) => {
          return `${INFORM_PUSH_TARGET_TYPE_INFO_TEXT[key as string]}: ${num}명`
        })
        .join('\n')
    this.targetGenderInfo =
      this.targetInfoObj &&
      Object.entries(this.targetInfoObj.gender)
        .map(([key, num]) => {
          return `${INFORM_PUSH_TARGET_TYPE_INFO_TEXT[key as string]}: ${num}명`
        })
        .join('\n')
    this.targetGradeInfo =
      this.grades &&
      this.grades
        .map((grade) => {
          return grade.name
        })
        .join(',')
    this.isSent = this.pushQueueStatus === INFORM_PUSH_QUEUE_STATUS.SUCCESS
    this.isReserved = this.pushQueueStatus === INFORM_PUSH_QUEUE_STATUS.SEND
  }
}
