import { ModelServiceQnaDefault } from 'containers/models/base/defaultModels'
import { IModelServiceQnaDefault } from 'containers/models/base/defaultModelInterfaces'
import { IModelOrderItem } from 'containers/models/modelOrderItem'

export interface IModelServiceQna extends IModelServiceQnaDefault {
  orderItem: IModelOrderItem
}

export default class ModelServiceQna extends ModelServiceQnaDefault {
  constructor(data: IModelServiceQna) {
    super(data)
  }
}
