import * as utilData from '@libs/utils/utilData'
import { IModelReturnInfo } from 'containers/models/modelReturnInfo'
import { FC, MutableRefObject, useState } from 'react'
import { Row, Col, Typography, Divider, Descriptions, message } from 'antd'
import { DomainModalProps } from 'containers/hooks/useModal'
import { MCheckboxSingle, Text12Normal, Text14Normal, Text14Red, Text16Normal } from 'components/@atomics/index'
import { ModalConfirm } from 'domains/common/modals/index'
import { MConfirmModalProps } from 'components/modals/MConfirmModal'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { TableOrderItemReturn } from 'domains/common/tables/index'
import { DescItemReturnPrice } from 'domains/common/descriptions/index'
import { LinkButtonSellerSupport } from 'domains/common'

const { Paragraph } = Typography

type ConfirmModalProps = MConfirmModalProps & {
  returnInfos?: IModelReturnInfo[]
}

type ActionParamsProps = {
  returnInfo: IModelReturnInfo
  orderItems: IModelOrderItem[]
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}

const ModalAcceptReturnOrder: FC<DomainModalProps<ConfirmModalProps, undefined>> = ({ useModalProps }) => {
  if (!useModalProps.actionParams) return null

  const { returnInfo, orderItems, refetchRef, statusFilterRefetchRef }: ActionParamsProps = useModalProps.actionParams
  const [hasInformedUser, setHasInformedUser] = useState<boolean>(false)

  if (!utilData.hasData(orderItems)) return null

  const paymentAmount = orderItems!.reduce((prev, next) => {
    return prev + next.amount
  }, 0)
  const returnShippingFee =
    returnInfo.responsibility === 'CUSTOMER'
      ? orderItems!.reduce((prev, next) => {
          return prev > next.returnShippingFee ? prev : next.returnShippingFee
        }, 0)
      : 0

  const RenderContent = () => {
    const { requestMessage = '-' } = returnInfo
    return (
      <>
        <Row>
          <Col>
            <Text14Normal>
              상품회수는 판매자님께서 직접 택배사에 접수해 주세요.
              <br />
              구매자 사유에 의한&nbsp;
              <Text14Normal bold>모든 반품 배송비는 핏펫에서 정산&nbsp;</Text14Normal>
              해드립니다.
              <br />
              <Text14Red bold>셀러는 고객에게 배송비를 직접 청구하지 않습니다.</Text14Red>
            </Text14Normal>
          </Col>
        </Row>

        <Divider />
        <TableOrderItemReturn orderItems={orderItems!} />

        <Row gutter={[8, 8]} style={{ marginTop: 20 }}>
          <Col>
            <Text16Normal bold>잠깐! 아래 주의사항을 고객에게 안내하셨나요?</Text16Normal>
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          <Col>
            <Paragraph>
              <ul>
                <li>
                  <Row>
                    <Col>
                      <Text14Normal>
                        반품의 경우 구매자 책임일 경우 반품비는 자동적으로 결제금액에서 차감되니,&nbsp;
                        <Text14Normal bold>구매자에게 반품비를 요구하지 말아주세요.</Text14Normal>
                      </Text14Normal>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Text14Normal bold>고객님께 정확한 반품배송지를 안내해주세요. </Text14Normal>
                  <ul>
                    <li>
                      <Text14Normal>
                        반품배송지로 보내지 않을 경우 고객님께 택배비 부담이 있음을 안내해야 합니다.
                      </Text14Normal>
                    </li>
                    <li>
                      <Text14Normal>
                        이를 제대로 안내하지 않은 경우 잘못된 배송지로 반품이 되면 그 배송비는 판매자님이 부담해야
                        합니다.
                      </Text14Normal>
                    </li>
                  </ul>
                </li>
              </ul>
            </Paragraph>
          </Col>
        </Row>

        <Row>
          <Col>
            <MCheckboxSingle text="네, 안내하였습니다." onChange={setHasInformedUser} checked={hasInformedUser} />
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col>
            <Descriptions bordered column={1} size="small">
              <Descriptions.Item label="주문자 정보" labelStyle={{ fontWeight: 'bold' }}>
                {returnInfo.customerAddress
                  ? `${returnInfo.customerAddress?.name} / ${returnInfo.customerAddress?.mobileNumber}`
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="수거지 정보" labelStyle={{ fontWeight: 'bold' }}>
                {returnInfo.customerAddress
                  ? `${returnInfo.customerAddress?.basicAddress} ${returnInfo.customerAddress?.detailedAddress}`
                  : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="반품 사유" labelStyle={{ fontWeight: 'bold' }}>
                {requestMessage}
              </Descriptions.Item>
              <Descriptions.Item label="환불 금액 정보" labelStyle={{ fontWeight: 'bold' }}>
                <DescItemReturnPrice title="" paymentAmount={paymentAmount} returnShippingFee={returnShippingFee} />
                {returnShippingFee > paymentAmount && (
                  <>
                    <Row>
                      <Col>
                        <Text14Red bold>
                          만약 반품 배송비가 결제금액보다 높아서 배송비를 별도로 청구해야 하는 경우에는 핏펫 셀러
                          지원센터로 문의 부탁드립니다.
                        </Text14Red>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <LinkButtonSellerSupport />
                      </Col>
                    </Row>
                  </>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Divider />
        <Row>
          <Col>
            <Paragraph>
              <ul>
                <li>
                  <Text12Normal>확인 버튼 클릭시 반품 수거중 상태로 변경됩니다.</Text12Normal>
                </li>
                <li>
                  <Text12Normal>
                    반품 사유 및 반품배송비는 상품 검수 후 반품 완료 단계에서 변경하실 수 있습니다.
                  </Text12Normal>
                </li>
              </ul>
            </Paragraph>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <ModalConfirm
      onAPISuccess={async () => {
        if (refetchRef && refetchRef.current) {
          refetchRef.current()
        }
        if (statusFilterRefetchRef && statusFilterRefetchRef.current) {
          statusFilterRefetchRef.current()
        }
        await message.success('반품 접수 처리에 성공하였습니다.')
      }}
      useModalProps={{ width: 800, maskClosable: false, ...useModalProps }}
      fixedProps={{
        mutationKey: {
          gqlKey: 'acceptReturnInfos',
          variableKey: 'acceptReturnInfosRequest',
        },
        okButtonDisable: !hasInformedUser,
        disableContent: '주의사항을 고객에게 안내하셨나요?',
        title: '반품 접수 처리',
        description: '',
        componentOption: {
          Component: <RenderContent />,
        },
      }}
    />
  )
}

export default ModalAcceptReturnOrder
