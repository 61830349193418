import { useState, useRef, FC } from 'react'
import { Image } from 'antd'
import { MFormModalFilter } from 'components'
import { FilterOptionsCommon } from 'components/MFormModalFilter'
import { MTableTransfer } from 'components/@atomics'
import MQuery, { queryParamsProps } from 'components/MQuery'
import { IModelProduct } from 'containers/models/modelProduct'
import { onTransferProps } from 'domains/common/modals/ModalTableTransferSelect'
import { FIELD_KEYS, TRANSFER_TABLE_PAGE_SIZE } from '@constants/constData'
import _ from 'lodash'

type TableTransferProductProps = {
  onTransfer: (values: onTransferProps) => void
  chosenValues?: IModelProduct[]
  colType?: 'products' | 'productEvents'
}

// FilterOptions --------
const formInputOptions: FilterOptionsCommon[] = [
  { label: '상품명', name: 'productName', labelCol: { span: 6 } },
  { label: '상품코드', name: 'productCode', labelCol: { span: 6 } },
  { label: '브랜드명', name: 'brandName', labelCol: { span: 6 } },
  { label: '리뷰그룹명', name: 'reviewGroupName', labelCol: { span: 6 } },
]
const formDateOptions: FilterOptionsCommon[] = [
  {
    name: FIELD_KEYS.FIELD_CREATED_AT,
    label: '등록일',
    labelCol: { span: 6 },
  },
  {
    name: FIELD_KEYS.FIELD_UPDATE_APPROVED_AT,
    label: '수정일',
    labelCol: { span: 6 },
  },
]

// TableCols -------
const rightTableColumns = [
  { dataIndex: '_id', title: '상품코드' },
  { dataIndex: 'image', title: '이미지' },
  { dataIndex: 'realName', title: '상품명' },
  { dataIndex: 'isRealSoldOut', title: '품절여부' },
  { dataIndex: 'brand', title: '브랜드명' },
  { dataIndex: 'reviewGroup', title: '리뷰그룹명' },
]
const leftTableColumns = [
  ...rightTableColumns,
  { dataIndex: 'customerPrice', title: '판매가' },
  // { dataIndex: 'status', title: '상품 상태' },
  // { dataIndex: 'createdAt', title: '등록일' },
]

const productEventsColumns = [
  { dataIndex: '_id', title: '상품코드' },
  { dataIndex: 'image', title: '이미지' },
  { dataIndex: 'name', title: '상품명' },
  { dataIndex: 'brand', title: '브랜드' },
  { dataIndex: 'isRealSoldOut', title: '품절여부' },
]

const TableTransferProduct: FC<TableTransferProductProps> = ({
  onTransfer,
  colType = 'products',
  chosenValues = [],
}) => {
  const [filterVariables, setFilterVariables] = useState<{}>({})
  const productsRef = useRef<IModelProduct[]>([])
  const targetProductsRef = useRef<IModelProduct[]>(chosenValues)
  const refetchRef = useRef<any>(null)
  const isProductEvent: boolean = colType === 'productEvents'
  const excludeProducts = chosenValues.map((x) => x.id)

  const queryParams: queryParamsProps = {
    gqlKey: 'productsByTransferTable',
    variables: { filter: { ...filterVariables, excludeProducts }, pageInfo: { first: TRANSFER_TABLE_PAGE_SIZE } },
  }

  const _onTransfer = (nextTargetKeys: string[]) => {
    targetProductsRef.current = productsRef.current.filter((product) => nextTargetKeys.includes(product.id))
    onTransfer({ values: targetProductsRef.current })
  }

  const onSearch = (inputValue: string, option: any) => {
    return option?.name?.indexOf(inputValue) > -1 || option?.brand?.indexOf(inputValue) > -1
  }

  return (
    <>
      <MFormModalFilter
        formInputOptions={formInputOptions}
        formDateOptions={formDateOptions}
        formCategory
        setFilterVariables={setFilterVariables}
        refetchRef={refetchRef}
      />
      <MQuery queryParams={queryParams}>
        {({ data, refetch }: any) => {
          if (refetchRef) {
            refetchRef.current = refetch
          }
          productsRef.current = _.unionBy(data?.products?.data, targetProductsRef.current, 'id')
          const products = productsRef.current
          const totalCount = data?.products?.totalCount || products.length
          const dataSource = products.map((product) => ({
            key: product.id,
            _id: product._id,
            name: product.realName,
            customerPrice: product.customerPriceText,
            image: <Image src={product.mainImage.thumbnailUrl} width={40} height={40} />,
            brand: product.brand?.name,
            reviewGroup: product.reviewGroup?.name,
            price: product.priceText,
            status: product.status?.text,
            createdAt: product.createdAtText,
            productCode: product._id,
            isRealSoldOut: product.isRealSoldOut ? '품절됨' : '품절아님',
          }))
          return (
            <MTableTransfer
              totalCount={totalCount}
              dataSource={dataSource}
              initialTargetKeys={targetProductsRef.current.map((p) => p.id)}
              showSelectAll={false}
              onTransfer={_onTransfer}
              listStyle={{}}
              locale={{ itemUnit: '개', itemsUnit: '개' }}
              filterOption={onSearch}
              leftColumns={isProductEvent ? productEventsColumns : leftTableColumns}
              rightColumns={isProductEvent ? productEventsColumns : rightTableColumns}
            />
          )
        }}
      </MQuery>
    </>
  )
}

export default TableTransferProduct
