import { useState, useRef, useEffect } from 'react'
import { MutationFunction } from '@apollo/client'
import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'
import styled, { StyledComponent } from 'styled-components'

import { Text14Normal } from 'components/@atomics/texts/TextBase'

interface IInputValue {
  id: string
  value: string
}

type Props = InputProps & {
  TextComponent?: StyledComponent<'span', any, {}, never>
  setValue: IInputValue
  children: string
  handleEdited?: (props: any) => void
  mutation: MutationFunction
  variables: any
}

function MEditableText(props: Props) {
  const { TextComponent = Text14Normal, setValue, children, handleEdited, mutation, variables } = props
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState(children)
  const inputRef = useRef<Input>(null)

  // Input 값이 변경된 경우 rerender
  useEffect(() => {
    setInputValue(children)
  }, [children])

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = async () => {
    await mutation({
      variables: variables({ id: setValue.id, name: inputValue }),
    })
    await setInputValue(inputValue)
    await setInputVisible(false)

    // 부모 컴포넌트에 input 내용이 수정됨을 알림
    if (handleEdited) {
      handleEdited({ isEdited: true })
    }
  }

  return (
    <>
      {!inputVisible && (
        <TextComponent>
          <span
            onDoubleClick={() => {
              setInputVisible(true)
              if (inputRef.current) {
                inputRef.current.focus()
              }
            }}
          >
            {inputValue}
          </span>
        </TextComponent>
      )}
      {inputVisible && (
        <StyledInput
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
    </>
  )
}

MEditableText.defaultProps = {
  TextComponent: Text14Normal,
  handleEdited: undefined,
}

const StyledInput = styled(Input)`
  max-width: 180px;
`

export default MEditableText
