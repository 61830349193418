import { ModelReturnInfoImageDefault } from 'containers/models/base/defaultModels'
import { IModelReturnInfoImageDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelReturnImageInfo extends IModelReturnInfoImageDefault {}

export default class ModelReturnInfoImage extends ModelReturnInfoImageDefault {
  constructor(data: IModelReturnImageInfo) {
    super(data)
  }
}
