import { rangeBillingDate, rangeDate } from '@libs/utils/utilDate'
import { NamePath } from 'rc-field-form/lib/interface'
import React, { useState } from 'react'
import { Button, Col, Form, Row, Space } from 'antd'
import { CheckboxOptionType } from 'antd/lib/checkbox'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { DATE_OPTION_VALUE } from '@constants/constData'
import ButtonSelectFromTable from 'domains/common/ButtonSelectFromTable'
import {
  IFilterOptions,
  MFormItemCheckboxGroup,
  MFormItemCustomerGrades,
  MFormItemFilterDate,
  MFormItemHidden,
  MFormItemInput,
  MFormItemProductCategories,
  MFormItemRadio,
  MFormItemSearch,
  MFormItemSelect,
  MFormItemWrapper,
} from 'components/formItems'
import { MButton, MOuterCard } from 'components/@atomics'
import useMFormFilter from 'containers/hooks/useMFormFilter'

export interface hasName {
  name: string
}

export interface hasIncludeFilter {
  isIncludeFilter?: boolean
}

export interface hasAllowedValueAll {
  isAllowedValueAll?: boolean
}

export interface hasSelectOptions {
  selectOptions: IFilterOptions
}

export enum FILTER_TYPES {
  BUTTON = 'button',
  SELECTS = 'selects',
  DATE_RANGE = 'dateRange',
  BILLING_DATE_RANGE = 'billingDateRange',
  SEARCH = 'search',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  MULTIPLE_SEARCH = 'multipleSearch',
  PRODUCT_CATEGORIES = 'productCategories',
  CUSTOMER_GRADES = 'customerGrades',
  RADIO_CHECKBOX = 'radioCheckbox',
}

export type buttonFilterProps = {
  type: FILTER_TYPES.BUTTON
  name: string
  label: string
  modelType:
    | 'user'
    | 'seller'
    | 'couponGroup'
    | 'product'
    | 'brand'
    | 'category'
    | 'couponGroupExcludeGrade'
    | 'userType'
} & hasName
export type selectsFilterProps = {
  type: FILTER_TYPES.SELECTS
  label: string
} & hasName &
  hasSelectOptions &
  hasIncludeFilter &
  hasAllowedValueAll
export type dateRangeFilterProps = {
  type: FILTER_TYPES.DATE_RANGE
  recentDateOptions: IFilterOptions
  label?: string
} & hasSelectOptions
export type billingDateRangeFilterProps = {
  type: FILTER_TYPES.BILLING_DATE_RANGE
  label?: string
  recentDateOptions: IFilterOptions
} & hasSelectOptions
export type searchFilterProps = {
  type: FILTER_TYPES.SEARCH
  name?: NamePath
  label?: string
} & hasSelectOptions
export type radioFilterProps = {
  type: FILTER_TYPES.RADIO
  label?: string
  options: CheckboxOptionType[]
} & hasName &
  hasAllowedValueAll
export type checkboxFilterProps = {
  type: FILTER_TYPES.CHECKBOX
  label?: string
  hasAllCheck?: boolean
  options: CheckboxOptionType[]
} & hasName &
  hasIncludeFilter &
  hasAllowedValueAll
export type multipleSearchFilterProps = {
  type: FILTER_TYPES.MULTIPLE_SEARCH
  label?: string
} & hasSelectOptions
export type productCategoriesFilterProps = {
  type: FILTER_TYPES.PRODUCT_CATEGORIES
  label?: string
} & hasName
export type customerGradesFilterProps = {
  type: FILTER_TYPES.CUSTOMER_GRADES
  hasAllCheck?: boolean
  name?: string
}

export type radioCheckboxFilterProps = {
  type: FILTER_TYPES.RADIO_CHECKBOX
  name?: string
  label?: string
  option: CheckboxOptionType
}

export type filterItemProps = (
  | buttonFilterProps
  | dateRangeFilterProps
  | billingDateRangeFilterProps
  | searchFilterProps
  | multipleSearchFilterProps
  | selectsFilterProps
  | productCategoriesFilterProps
  | customerGradesFilterProps
  | radioFilterProps
  | checkboxFilterProps
  | radioCheckboxFilterProps
) & {
  required?: boolean
  initialValue?: any
}

export type MFormFilterProps = {
  initial: filterItemProps[]
  additional?: filterItemProps[]
}

export const makeFormFilter2InitialValues = (initialFilterFields: filterItemProps[]) => {
  const initialValues: { [key: string]: any } = {}
  initialFilterFields.forEach((field) => {
    const { type, initialValue } = field
    if (type === FILTER_TYPES.DATE_RANGE) {
      const { selectOptions, recentDateOptions } = field as dateRangeFilterProps
      const name = selectOptions[0].value
      const index = recentDateOptions?.findIndex((v) => v.value === initialValue?.recentDateOptions)
      const recentValue = recentDateOptions[index >= 0 ? index : recentDateOptions.length - 1].value
      initialValues[name as string] = rangeDate(recentValue as DATE_OPTION_VALUE)
    } else if (type === FILTER_TYPES.BILLING_DATE_RANGE) {
      const { selectOptions, recentDateOptions } = field as billingDateRangeFilterProps
      const name = selectOptions[0].value
      const recentValue = recentDateOptions[recentDateOptions.length - 1].value
      initialValues[name as string] = rangeBillingDate(recentValue as DATE_OPTION_VALUE)
    } else if (type === FILTER_TYPES.SELECTS) {
      const { selectOptions, name } = field as selectsFilterProps
      initialValues[name] = selectOptions[0].value
    }
  })
  return initialValues
}

const MFormFilter = ({ initial, additional = [] }: MFormFilterProps) => {
  const [form] = Form.useForm()
  const [includeAdditional, setIncludeAdditional] = useState<boolean>(false)
  const initialFilterFields: filterItemProps[] = [...initial, ...(includeAdditional ? additional : [])]
  const { values, setValues, resetValues } = useMFormFilter(initialFilterFields)
  return (
    <MOuterCard>
      <Form
        name="form-filter"
        form={form}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        initialValues={values}
        onFinish={(formValues) => {
          setValues(formValues)
        }}
      >
        {initialFilterFields.map((props: filterItemProps, index) => {
          const { type } = props
          const key = `${type}-${index.toString()}`

          if (type === FILTER_TYPES.BUTTON) {
            const { name, label, ...otherProps } = props as buttonFilterProps
            // TODO : make MFormItemButtonSelectFromTable
            return (
              <MFormItemWrapper label={label} key={key}>
                <MFormItemHidden name={name} />
                <ButtonSelectFromTable
                  {...otherProps}
                  title={`${label} 선택`}
                  displayTag
                  onAction={(selectedValues) => {
                    form.setFieldsValue({ [name]: selectedValues })
                  }}
                />
              </MFormItemWrapper>
            )
          }
          if (type === FILTER_TYPES.RADIO) return <MFormItemRadio key={key} {...(props as radioFilterProps)} />
          if (type === FILTER_TYPES.CHECKBOX)
            return <MFormItemCheckboxGroup key={key} {...(props as checkboxFilterProps)} />
          if (type === FILTER_TYPES.DATE_RANGE)
            return <MFormItemFilterDate key={key} {...(props as dateRangeFilterProps)} />
          if (type === FILTER_TYPES.BILLING_DATE_RANGE) {
            return <MFormItemFilterDate key={key} dateTypeBilling {...(props as billingDateRangeFilterProps)} />
          }
          if (type === FILTER_TYPES.SEARCH)
            return <MFormItemSearch key={key} label="검색" {...(props as searchFilterProps)} />
          if (type === FILTER_TYPES.MULTIPLE_SEARCH) {
            return <MFormItemSearch key={key} label="다중 검색" {...(props as multipleSearchFilterProps)} />
          }
          if (type === FILTER_TYPES.SELECTS)
            return <MFormItemSelect key={key} selectWidth={200} {...(props as selectsFilterProps)} />
          if (type === FILTER_TYPES.PRODUCT_CATEGORIES) return <MFormItemProductCategories key={key} form={form} />
          if (type === FILTER_TYPES.CUSTOMER_GRADES)
            return <MFormItemCustomerGrades key={key} {...(props as customerGradesFilterProps)} />
          return <MFormItemInput key={key} {...props} />
        })}

        <Row>
          <Col>
            {additional.length > 0 ? (
              <Space>
                {includeAdditional ? (
                  <Button type="link" onClick={() => setIncludeAdditional(false)} style={{ padding: 0 }}>
                    상세 검색 조건 닫기 <UpOutlined />
                  </Button>
                ) : (
                  <Button type="link" onClick={() => setIncludeAdditional(true)} style={{ padding: 0 }}>
                    상세 검색 조건 열기 <DownOutlined />
                  </Button>
                )}
              </Space>
            ) : null}
          </Col>
        </Row>

        <Row justify="center" style={{ marginTop: 28 }}>
          <Space size="middle">
            <MButton type="primary" htmlType="submit" width={68}>
              검색
            </MButton>
            <MButton
              onClick={async () => {
                resetValues().then(() => {
                  form.resetFields()
                })
              }}
              width={68}
            >
              초기화
            </MButton>
          </Space>
        </Row>
      </Form>
    </MOuterCard>
  )
}

export default MFormFilter
