import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const STATUS_MUTATION_GQLS = {
  APPROVE_REQUEST: gql`
    mutation approveRequest($id: ID!) {
      approveRequest(id: $id) {
        status {
          ...status
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
  `,
  APPROVE_UPDATE_REQUEST: gql`
    mutation approveUpdateRequest($id: ID!) {
      approveUpdateRequest(id: $id) {
        status {
          ...status
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
  `,
  APPROVE_REQUESTS: gql`
    mutation approveRequests($input: StatusesInput!) {
      approveRequests(input: $input) {
        statuses {
          ...status
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
  `,
  DELETE_REQUESTS: gql`
    mutation deleteRequests($input: StatusesInput!) {
      deleteRequests(input: $input) {
        statuses {
          ...status
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
  `,
  REJECT_REQUEST: gql`
    mutation rejectRequest($id: ID!, $input: StatusInput!) {
      rejectRequest(id: $id, input: $input) {
        status {
          ...status
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
  `,
  SUSPEND_REQUEST: gql`
    mutation suspendRequest($id: ID!) {
      suspendRequest(id: $id) {
        status {
          ...status
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
  `,
  SUSPEND_REQUESTS: gql`
    mutation suspendRequests($input: StatusesInput!) {
      suspendRequests(input: $input) {
        statuses {
          ...status
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
  `,
  REJECT_UPDATE_REQUEST: gql`
    mutation rejectUpdateRequest($id: ID!, $input: StatusInput!) {
      rejectUpdateRequest(id: $id, input: $input) {
        status {
          ...status
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
  `,
}

export default STATUS_MUTATION_GQLS
