import { IModelProductCategoryDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelProductCategoryDefault } from 'containers/models/base/defaultModels'

export interface IModelProductCategory extends IModelProductCategoryDefault {}

export default class ModelProductCategory extends ModelProductCategoryDefault {
  constructor(data: IModelProductCategory) {
    super(data)
  }
}
