import { IModelShippingCompanyDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelShippingCompanyDefault } from 'containers/models/base/defaultModels'

export interface IModelShippingCompany extends IModelShippingCompanyDefault {}

export default class ModelShippingCompany extends ModelShippingCompanyDefault {
  constructor(data: IModelShippingCompany) {
    super(data)
  }
}
