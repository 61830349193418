import { Tag } from 'antd'
import { MTextButton } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelNotice } from 'containers/models/modelNotice'

const receiverTypeText = (): ITableColumn<any> => {
  return {
    title: '대상',
    dataIndex: 'receiverType',
    key: 'receiverType',
    width: 50,
    render: (_: string, record: IModelNotice) => {
      return <p>{record.receiverTypeText}</p>
    },
  }
}

const kindText = (): ITableColumn<any> => {
  return {
    title: '구분',
    dataIndex: 'kind',
    key: 'kind',
    width: 30,
    render: (_: string, record: IModelNotice) => {
      return <p>{record.kindText}</p>
    },
  }
}

const title = ({ setNoticeDetail }: { setNoticeDetail: Function }): ITableColumn<any> => {
  return {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    width: 180,
    render: (_: string, record: IModelNotice) => {
      return (
        <MTextButton
          onClick={async () => {
            await setNoticeDetail(record)
          }}
        >
          <>
            {record.isImportant && <Tag color="red">중요</Tag>}
            {record.title}
          </>
        </MTextButton>
      )
    },
  }
}

export {
  title, // 공지사항 제목
  receiverTypeText, // 대상
  kindText, // 구분
}
