import { IModelShippingPresetDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelShippingPresetDefault } from 'containers/models/base/defaultModels'
import { IModelSellerAddress } from 'containers/models/modelSellerAddress'

export interface IModelShippingPreset extends IModelShippingPresetDefault {
  isDefault: boolean
  warehouseAddress: IModelSellerAddress
  returnAddress: IModelSellerAddress
  applyRemoteAreaFeeText: string
  ablePackingText: string
}

export default class ModelShippingPreset extends ModelShippingPresetDefault {
  applyRemoteAreaFeeText: string
  isDefault: boolean = false
  ablePackingText: string

  constructor(data: IModelShippingPreset) {
    super(data)

    this.applyRemoteAreaFeeText = this.applyRemoteAreaFee ? '적용' : '미적용'
    this.ablePackingText = this.isPackage ? '가능' : '불가능'
  }
}
