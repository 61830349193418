import { MStatusFilter } from 'components'
import MQuery, { queryVariables } from 'components/MQuery'
import SELLER_QUERY_GQLS from 'containers/gqls/accounts/seller/queries'
import ORDER_QUERY_GQLS from 'containers/gqls/orders/order/queries'
import PRODUCT_QUERY_GQLS from 'containers/gqls/products/product/queries'
import { MutableRefObject } from 'react'

enum DSTATUS_FILTER_KEYS {
  APPROVED = 'approved',
  CANCELED = 'canceled',
  CANCEL_REQUESTED = 'cancelRequested',
  CONFIRMED = 'confirmed',
  NEW_ORDER = 'newOrder',
  PRODUCT_STATUS_END_SELLING = 'endSelling',
  PRODUCT_STATUS_OUT_OF_STOCK = 'soldOut',
  PRODUCT_STATUS_PRE_SELLING = 'preSelling',
  PRODUCT_STATUS_REJECTED = 'rejected',
  PRODUCT_STATUS_REQUEST = 'request',
  PRODUCT_STATUS_SELLING = 'selling',
  PRODUCT_STATUS_SUSPEND = 'suspend',
  READY_FOR_SHIPPING = 'readyForShipping',
  REJECTED = 'rejected',
  EXCHANGE_REQUESTED = 'exchangeRequested',
  EXCHANGE_UNDER_RETURN = 'exchangeUnderReturn',
  EXCHANGE_CONFIRMED = 'exchangeConfirmed',
  RETURN_CONFIRMED = 'returnConfirmed',
  RETURN_REQUESTED = 'returnRequested',
  RETURN_RETURNED = 'returnReturned',
  RETURN_UNDER_RETURN = 'returnUnderReturn',
  SHIPPED = 'shipped',
  SHIPPING = 'shipping',
  SUSPEND = 'suspend',
  TOTAL = 'total',
  UNCONFIRMED_PAYMENT = 'unconfirmedPayment',
  UPDATE_REJECTED = 'updateRejected',
  UPDATE_REQUEST = 'updateRequest',
}

enum DSTATUS_FILTER_TEXT {
  APPROVED = '사용',
  CANCELED = '취소완료',
  CANCEL_REQUESTED = '취소요청',
  CONFIRMED = '구매확정',
  NEW_ORDER = '신규주문',
  PRODUCT_STATUS_END_SELLING = '판매종료',
  PRODUCT_STATUS_OUT_OF_STOCK = '품절',
  PRODUCT_STATUS_PRE_SELLING = '판매대기',
  PRODUCT_STATUS_REJECTED = '등록신청반려',
  PRODUCT_STATUS_REQUEST = '등록신청',
  PRODUCT_STATUS_SELLING = '판매중',
  PRODUCT_STATUS_SUSPEND = '판매 중지',
  READY_FOR_SHIPPING = '배송준비중',
  REJECTED = '등록 반려',
  EXCHANGE_REQUESTED = '교환요청',
  EXCHANGE_UNDER_RETURN = '교환수거중',
  EXCHANGE_CONFIRMED = '교환반품완료',
  RETURN_CONFIRMED = '반품완료',
  RETURN_REQUESTED = '반품요청',
  RETURN_RETURNED = '환불처리중',
  RETURN_UNDER_RETURN = '반품수거중',
  SHIPPED = '배송완료',
  SHIPPING = '배송중',
  SUSPEND = '사용 중지',
  TOTAL = '전체',
  UNCONFIRMED_PAYMENT = '미결제',
  UPDATE_REJECTED = '정보변경 반려',
  UPDATE_REQUEST = '정보변경 신청',
}

const route2statusFilterData: { [index: string]: any } = {
  get ADMIN_SELLER_LIST() {
    return {
      query: SELLER_QUERY_GQLS.SELLER_COUNT,
      gqlKey: 'sellerCount',
      kinds: ['TOTAL', 'APPROVED', 'SUSPEND', 'REJECTED', 'UPDATE_REQUEST', 'UPDATE_REJECTED'],
    }
  },
  get ADMIN_PRODUCT_LIST() {
    return {
      query: PRODUCT_QUERY_GQLS.PRODUCT_COUNT,
      gqlKey: 'productCount',
      kinds: [
        'TOTAL',
        'PRODUCT_STATUS_REJECTED',
        'PRODUCT_STATUS_SELLING',
        'PRODUCT_STATUS_PRE_SELLING',
        'PRODUCT_STATUS_OUT_OF_STOCK',
        'PRODUCT_STATUS_SUSPEND',
        'PRODUCT_STATUS_END_SELLING',
        'UPDATE_REQUEST',
        'UPDATE_REJECTED',
      ],
    }
  },
  get SELLER_PRODUCT_LIST() {
    return {
      query: PRODUCT_QUERY_GQLS.PRODUCT_COUNT,
      gqlKey: 'productCount',
      kinds: [
        'TOTAL',
        'PRODUCT_STATUS_REQUEST',
        'PRODUCT_STATUS_REJECTED',
        'PRODUCT_STATUS_SELLING',
        'PRODUCT_STATUS_PRE_SELLING',
        'PRODUCT_STATUS_OUT_OF_STOCK',
        'PRODUCT_STATUS_SUSPEND',
        'PRODUCT_STATUS_END_SELLING',
        'UPDATE_REQUEST',
        'UPDATE_REJECTED',
      ],
    }
  },
  get ALL_ORDER_LIST() {
    return {
      query: ORDER_QUERY_GQLS.ORDER_ITEM_COUNT,
      gqlKey: 'orderItemCount',
      kinds: [
        'TOTAL',
        'NEW_ORDER',
        'READY_FOR_SHIPPING',
        'SHIPPING',
        'SHIPPED',
        'CONFIRMED',
        'UNCONFIRMED_PAYMENT',
        'CANCEL_REQUESTED',
        'CANCELED',
        'RETURN_REQUESTED',
        'RETURN_UNDER_RETURN',
        'EXCHANGE_REQUESTED',
        'EXCHANGE_UNDER_RETURN',
      ],
      statusKey: 'orderStatus',
    }
  },
  get NEW_ORDER_LIST() {
    return {
      ...this.ALL_ORDER_LIST,
      kinds: ['TOTAL', 'NEW_ORDER', 'READY_FOR_SHIPPING'],
    }
  },
  get SELLER_NEW_ORDER_LIST() {
    return this.NEW_ORDER_LIST
  },
  get SHIPPING_ORDER_LIST() {
    return {
      ...this.NEW_ORDER_LIST,
      kinds: ['TOTAL', 'SHIPPING', 'SHIPPED'],
    }
  },
  get CANCEL_ORDER_LIST() {
    return {
      ...this.NEW_ORDER_LIST,
      kinds: ['TOTAL', 'CANCEL_REQUESTED', 'CANCELED'],
    }
  },
  get RETURN_ORDER_LIST() {
    return {
      ...this.NEW_ORDER_LIST,
      kinds: ['TOTAL', 'RETURN_REQUESTED', 'RETURN_UNDER_RETURN', 'RETURN_RETURNED', 'RETURN_CONFIRMED'],
    }
  },
  get EXCHANGE_ORDER_LIST() {
    return {
      ...this.NEW_ORDER_LIST,
      kinds: ['TOTAL', 'EXCHANGE_REQUESTED', 'EXCHANGE_UNDER_RETURN', 'EXCHANGE_CONFIRMED'],
    }
  },
}

const key2countFromResponse = (data: { [index: string]: any }) => {
  const _data: { [index: string]: any } = {}
  Object.entries(data).forEach(([k, v]) => {
    if (k.includes('Count')) {
      const _key = k.replace('Count', '')
      _data[_key] = v
    }
  })
  return _data
}

const keyFromKind = (kind: string) => DSTATUS_FILTER_KEYS[kind as keyof typeof DSTATUS_FILTER_KEYS]
const keysFromKinds = (kinds: string[]) => kinds.map((x) => DSTATUS_FILTER_KEYS[x as keyof typeof DSTATUS_FILTER_KEYS])
const textFromKind = (kind: string) => DSTATUS_FILTER_TEXT[kind as keyof typeof DSTATUS_FILTER_TEXT]
const dataInArray = (obj: { [index: string]: number }, array: string[]) =>
  Object.keys(obj)
    .filter((key) => array.includes(key))
    .reduce((filteredObj: { [index: string]: number }, key) => {
      filteredObj[key] = obj[key]
      return filteredObj
    }, {})

const updateStatusFilterCount = (kinds: string[], key2Count: { [index: string]: any }) => {
  const keys = keysFromKinds(kinds)
  return kinds.map((x) => {
    const key = keyFromKind(x)
    let count = 0
    // check if FIELD_ALL exist in kinds but not 'total' in key2Count
    if (key === 'total' && !Object.keys(key2Count).includes('total')) {
      const filteredKey2Count: { [index: string]: number } = dataInArray(key2Count, keys)
      count = Object.values(filteredKey2Count).reduce((sum, _count) => sum + _count)
    } else {
      count = key2Count[key]
    }
    return { title: textFromKind(x), key, count }
  })
}

type DStatusFilterProps = {
  filterType: string
  refetchRef?: MutableRefObject<Function | null>
  unit?: '건' | '명'
  variables?: queryVariables
}

const DStatusFilter = ({ filterType, refetchRef, unit, variables }: DStatusFilterProps) => {
  const statusFilterData = route2statusFilterData[filterType]
  const { gqlKey, kinds, statusKey = 'status' } = statusFilterData
  const queryParams = { gqlKey, variables }
  return (
    <MQuery queryParams={queryParams} fetchPolicy="network-only">
      {({ data, refetch }: any) => {
        if (refetchRef) {
          refetchRef.current = refetch
        }

        const { data: _data } = data[gqlKey]
        const key2count = key2countFromResponse(_data)
        const _ = updateStatusFilterCount(kinds, key2count)
        return <MStatusFilter data={_} spaceSize={108} unit={unit} statusKey={statusKey} />
      }}
    </MQuery>
  )
}

export default DStatusFilter
