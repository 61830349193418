import { ThemeProvider, ThemeContext } from 'styled-components'

// define our colors, and anything related to theme here

// usage in styled component
// color: ${(props) => props.theme.colors.primary};
const themes = {
  colors: {
    blackAlpha100: '#000000',
    black: 'rgba(0, 0, 0, 0.85)', // MAIN BLACK
    blackAlpha85: 'rgba(0, 0, 0, 0.85)',
    blackAlpha65: 'rgba(0, 0, 0, 0.65)',
    blackAlpha45: 'rgba(0, 0, 0, 0.45)',
    blackAlpha25: 'rgba(0, 0, 0, 0.25)',
    blackAlpha15: 'rgba(0, 0, 0, 0.15)',
    blackAlpha6: 'rgba(0, 0, 0, 0.06)',
    blackAlpha4: 'rgba(0, 0, 0, 0.04)',
    blackAlpha2: 'rgba(0, 0, 0, 0.02)',
    black33: '#333333',

    white: '#ffffff',
    whitef0: '#f0f0f0',
    whitefa: '#fafafa',

    blue1: '#e6f7ff',
    blue2: '#bae7ff',
    blue3: '#91d5ff',
    blue4: '#69c0ff',
    blue5: '#40a9ff',
    blue: '#1890ff', // MAIN BLUE
    blue7: '#096dd9',
    blue8: '#0050b3',
    blue9: '#003a8c',
    blue10: '#002766',

    purple: '#A32CC3',

    green: '#52c41a',
    orange: '#faad14',
    red: '#ff4d4f',

    grey55: '#555555',
    grey81: '#818181',
    grey97: '#979797',
    grey99: '#999999',
    greyb2: '#b2b2b2',
    greyb5: '#b5b5b5',
    greyc0: '#c0c0c0',
    greyd4: '#d4d4d4',
    greyd5: '#d5d5d5',
    greyd9: '#d9d9d9',
    greye4: '#e4e4e4',
    greyf1: '#f1f1f1',
    greyfa: '#fafafa',
    greyf9: '#f9f9f9',
    greyed: '#ededed',

    border: '#d9d9d9',
  },
  fonts: {
    font40: {
      'font-size': '40px',
      'line-height': '48px',
    },
    font30: {
      'font-size': '30px',
      'line-height': '38px',
    },
    font24: {
      'font-size': '24px',
      'line-height': '32px',
    },
    font20: {
      'font-size': '20px',
      'line-height': '28px',
    },
    font18: {
      'font-size': '18px',
      'line-height': '26px',
    },
    font16: {
      'font-size': '16px',
      'line-height': '24px',
    },
    font14: {
      'font-size': '14px',
      'line-height': '22px',
    },
    font12: {
      'font-size': '12px',
      'line-height': '20px',
    },
    font10: {
      'font-size': '10px',
      'line-height': '18px',
    },
  },
  sider: {
    width: 220,
  },
  header: {
    height: 64,
  },
  margins: {
    contentHorizontal: '24px',
  },
}

export { themes, ThemeProvider, ThemeContext }
