import _ from 'lodash'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, store } from '@libs/redux/store'
import { listVariablesProps } from 'components/MQuery'
import { TABLE_PAGINATION_CONFIG } from '@constants/constData'
import usePageInfoRef from 'containers/hooks/usePageInfoRef'
import { TablePaginationConfig } from 'antd/lib/table'
import { doSetMainTableInitialVariables, doSetMainTableVariables } from '@libs/redux/reduxUI'

type initialMainTableProps = {
  initialPageSize: number
}

const useMainTable = ({ initialPageSize }: initialMainTableProps) => {
  // console.log('! : useMainTable')
  const { getTablePageInfoVariables, getPage } = usePageInfoRef() // to use 'getTablePageInfoVariables' function statically
  const refetchRef = useRef<Function | null>(null)
  const dispatch = useDispatch()

  const setInitVariablesAndReturn = (variables?: listVariablesProps) => {
    const _variables: listVariablesProps = { filter: {}, orderBy: '', pageInfo: {} }

    if (variables) {
      _.merge(_variables, variables)
    }

    _.merge(_variables, { ...getTablePageInfoVariables(1, initialPageSize) })
    dispatch(doSetMainTableInitialVariables(_variables))
    return _variables
  }

  const setRefetch = (refetch: Function) => {
    refetchRef.current = refetch
  }

  const getVariables = () => store.getState().reduxUIReducers.mainTableVariables

  const getPageSize = () => {
    const variables = getVariables()
    return variables?.pageInfo?.first || initialPageSize
  }

  const getCurrentPage = () => {
    const variables = getVariables()
    const page = getPage(variables?.pageInfo)
    return page
  }

  const onPageSizeChange = async (pageSize: number) => {
    dispatch(
      doSetMainTableVariables({
        ...getVariables(),
        ...getTablePageInfoVariables(1, pageSize),
      })
    )
  }

  const onPageChange = async (page: number) => {
    // console.log('! : onPageChange', page)
    const variables = getVariables()
    await dispatch(
      doSetMainTableVariables({
        ...variables,
        ...getTablePageInfoVariables(page, getPageSize()),
      })
    )
  }

  const getTablePagination = (pagination: false | TablePaginationConfig, totalCount?: number) => {
    if (!pagination) return false
    const total = totalCount || 0
    const current = getCurrentPage()
    const onChange = (page: number) => onPageChange(page)
    const pageSize = getPageSize()
    const calcIndexDescNumber = (index: number) => total - (current - 1) * pageSize - index
    const calcIndexAscNumber = (index: number) => (current - 1) * pageSize + index

    return {
      ...TABLE_PAGINATION_CONFIG,
      ...pagination,
      pageSize,
      onChange,
      current,
      total,
      calcIndexDescNumber,
      calcIndexAscNumber,
    }
  }

  const useVariables = () => {
    // console.log('! : useVariables')
    const mainTableVariables = useSelector((state: RootState) => state.reduxUIReducers.mainTableVariables)
    return mainTableVariables
  }

  return {
    getTablePagination,
    onPageSizeChange,
    useVariables,
    setRefetch,
    setInitVariablesAndReturn,
  }
}

export default useMainTable
