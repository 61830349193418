import React, { ReactNode } from 'react'
import moment from 'moment'
import * as utilData from '@libs/utils/utilData'
import { DATETIME_FORMAT } from '@libs/utils/utilDate'
import ROUTES from '@constants/constRoutes'
import { ITableColumn } from 'components/@atomics/MTable'
import { Text14A45, Text14Normal, MLinkRouterButton } from 'components/@atomics'
import { IModelOrder } from 'containers/models/modelOrder'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { IModelBillingItem } from 'containers/models/modelBillingItem'
import { IModelServiceQna } from 'containers/models/modelServiceQna'
import { IModelCustomerAddress } from 'containers/models/modelCustomerAddress'
import _ from 'lodash'

export const makeMergeRow = ({ children, rowSize }: { children: ReactNode; rowSize: number }) => {
  return {
    children,
    props: { rowSpan: rowSize },
  }
}

const daysPassedSinceOrderCreated = () => {
  return {
    title: '경과일',
    dataIndex: 'createdAt',
    width: 70,
    render: (createdAt: string) => {
      const now = moment()
      const daysPassed = now.diff(moment(createdAt), 'days')
      return <Text14Normal>{daysPassed}</Text14Normal>
    },
  }
}

const paidAt = () => {
  return {
    title: '결제확인일시',
    dataIndex: ['approvedTransaction', 'approvedAt'],
    width: 100,
    render: (_paidAt: string, record: IModelOrderItem) => {
      const children = utilData.dateTimeFormatText(_paidAt, DATETIME_FORMAT)
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const orderShippingInfo = () => {
  return {
    title: '연락처 및 수거지',
    dataIndex: ['returnInfo', 'customerAddress'],
    width: 200,
    render: (customerAddress: IModelCustomerAddress) => {
      if (_.isEmpty(customerAddress)) return
      const { mobileNumber, fullAddress } = customerAddress
      return <Text14Normal>{`${utilData.formatMobileNumber(mobileNumber)}/\n${fullAddress}`}</Text14Normal>
    },
  }
}

const orderUser = (): any => {
  return {
    title: '주문자',
    dataIndex: 'order',
    width: 200,
    render: (order: IModelOrder, record: IModelOrderItem) => {
      const { _id, name, email, grade } = order.user
      const { name: gradeName } = grade
      const children = (
        <MLinkRouterButton href={`${ROUTES.ACCOUNTS.USER_LIST}/${_id}`} openNewWindow>
          {`${name} / ${email}\n(${gradeName})`}
        </MLinkRouterButton>
      )
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const productOptionNumber = () => {
  return {
    title: '상품옵션번호',
    width: 150,
    render: (record: IModelOrderItem) => {
      return record?.productOption?._id ?? ''
    },
  }
}

const orderProduct = () => {
  return {
    title: '주문상품',
    width: 300,
    render: (record: IModelOrderItem) => {
      // TODO: 상품 옵션 추가
      const {
        productOption: { product, name: optionName },
      } = record
      if (!product) {
        return null
      }
      const { name } = product
      return (
        <>
          <MLinkRouterButton href={`${ROUTES.PRODUCTS.BASE}/${product._id}/edit`} openNewWindow>
            {name}
          </MLinkRouterButton>
          <Text14A45>{`:${optionName}`}</Text14A45>
        </>
      )
    },
  }
}

const orderStatusText = (): ITableColumn<any> => {
  return {
    title: '주문상태',
    dataIndex: 'orderStatusText',
    key: 'orderStatusText',
    width: 100,
    render: (_orderStatusText: string) => <Text14Normal>{_orderStatusText}</Text14Normal>,
  }
}

const orderNumber = ({ dataIndex = 'orderNumber' as string | string[], hasLink = true }): ITableColumn<any> => {
  return {
    title: '주문번호',
    dataIndex,
    key: 'orderNumber',
    width: 110,
    fixed: 'left',
    render: (order: IModelOrder, record: IModelOrderItem) => {
      const children = hasLink ? (
        <MLinkRouterButton href={ROUTES.ORDERS.ORDER_DETAIL} as={`${ROUTES.ORDERS.BASE}/${order._id}`} openNewWindow>
          {order.orderNumber}
        </MLinkRouterButton>
      ) : (
        utilData.tableText(order.orderNumber)
      )

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const orderNumberLink = ({
  dataIndex = 'order',
  title = '상품 주문번호',
  fixed = false,
  hasLink = true,
}: ITableColumn<IModelServiceQna | IModelBillingItem> = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    fixed,
    hasLink,
    width: 160,
    render: (orderItem: IModelOrderItem) => {
      const order = orderItem?.order
      const productId = orderItem?.productOption?.product?.id
      if (!hasLink) return <Text14Normal>{(order as IModelOrder)?.orderNumber}</Text14Normal>
      return (
        <MLinkRouterButton href={`${ROUTES.PRODUCTS.PRODUCT_LIST}/${productId}`} openNewWindow>
          {(order as IModelOrder)?.orderNumber}
        </MLinkRouterButton>
      )
    },
  }
}

const customer = ({ title = '', hasLink = false }): ITableColumn<any> => {
  return {
    title,
    dataIndex: 'order',
    width: 100,
    render: (order: IModelOrder) => {
      if (hasLink) {
        return (
          <MLinkRouterButton href={`${ROUTES.ACCOUNTS.USER_LIST}/${order.user._id}`} openNewWindow>
            {order.user.name}
          </MLinkRouterButton>
        )
      }
      return order.user.name
    },
  }
}

const customerEmail = (): ITableColumn<any> => {
  return {
    title: '회원 이메일',
    dataIndex: ['order', 'user', 'email'],
    width: 200,
    render: (email: string) => {
      return email
    },
  }
}

const customerGrade = (): ITableColumn<any> => {
  return {
    title: '회원 등급명',
    key: 'customerGrade',
    dataIndex: ['order', 'user', 'grade', 'name'],
    width: 200,
    render: (name: string) => {
      return name
    },
  }
}

const customerMobileNumber = (): ITableColumn<any> => {
  return {
    title: '주문자 핸드폰 번호',
    key: 'customerMobileNumber',
    dataIndex: ['order', 'user', 'mobileNumber'],
    width: 200,
    render: (mobileNumber: string, record: IModelOrderItem) => {
      const children = <Text14Normal>{mobileNumber}</Text14Normal>
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const customerAddressUser = (): ITableColumn<any> => {
  return {
    title: '수취인명',
    key: 'customerAddressUser',
    dataIndex: ['order', 'customerAddress', 'name'],
    width: 120,
    render: (name: string, record: IModelOrderItem) => {
      const children = <Text14Normal>{name}</Text14Normal>
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const customerAddressMobileNumber = (): ITableColumn<any> => {
  return {
    title: '수취인 핸드폰 번호',
    key: 'customerAddressMobileNumber',
    dataIndex: ['order', 'customerAddress', 'mobileNumber'],
    width: 160,
    render: (mobileNumber: string, record: IModelOrderItem) => {
      const children = <Text14Normal>{mobileNumber}</Text14Normal>
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const customerAddressPostNumber = (): ITableColumn<any> => {
  return {
    title: '수취인 우편번호',
    key: 'customerAddressPostNumber',
    dataIndex: ['order', 'customerAddress', 'postNumber'],
    width: 120,
    render: (postNumber: string, record: IModelOrderItem) => {
      const children = <Text14Normal>{postNumber}</Text14Normal>
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const customerAddressMessage = (): ITableColumn<any> => {
  return {
    title: '배송 메시지',
    key: 'customerAddressMessage',
    dataIndex: ['order', 'customerAddress', 'message'],
    width: 120,
    render: (message: string, record: IModelOrderItem) => {
      const children = !message || message === '선택 안함' ? '-' : message
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const customerAddress = (): ITableColumn<any> => {
  return {
    title: '수취인 주소 전체',
    key: 'customerAddress',
    dataIndex: ['order', 'customerAddress', 'fullAddress'],
    width: 180,
    render: (fullAddress: string, record: IModelOrderItem) => {
      const children = <Text14Normal>{fullAddress}</Text14Normal>
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

export {
  productOptionNumber, // 상품옵션번호
  orderProduct, // 주문상품
  orderUser, // 주문자
  paidAt, // 결제확인일시
  orderStatusText, // 주문 상태
  orderNumber, // 주문 번호,
  orderNumberLink, // 주문 번호 링크 (qna),
  orderShippingInfo,
  daysPassedSinceOrderCreated,
  customer, // 회원명
  customerEmail, // 회원아이디
  customerGrade, // 회원등급명
  customerMobileNumber, // 주문자 핸드폰 번호
  customerAddressUser, // 수취인명
  customerAddressMobileNumber, // 수취인 핸드폰 번호
  customerAddressPostNumber, // 수취인 우편 번호
  customerAddress, // 수취인 주소
  customerAddressMessage, // 배송 메시지
}
