import _ from 'lodash'
import * as utilData from '@libs/utils/utilData'
import * as utilDate from '@libs/utils/utilDate'
import { ModelProductDefault } from 'containers/models/base/defaultModels'
import { IModelProductDefault } from 'containers/models/base/defaultModelInterfaces'
import { IModelProductImage } from 'containers/models/modelProductImage'
import { IModelProductOption } from 'containers/models/modelProductOption'
import { IModelStatus } from 'containers/models/modelStatus'
import { IModelUpdatedProduct } from 'containers/models/modelUpdatedProduct'

export interface IModelProduct extends IModelProductDefault {
  createdAtText: string
  customerPriceText: string
  defaultProductOption: IModelProductOption
  endedAtText: string
  hasOption: boolean
  hasSellingPeriod: boolean
  hasUpdateInfo: boolean
  mainImage: IModelProductImage
  priceText: string
  productOptions: IModelProductOption[] // 기본 옵션(isDefault=True) 제외한 상품 옵션들
  productProductImages: IModelProductImage[]
  productProductOptions: IModelProductOption[]
  specialCustomerPrice: number
  startedAtText: string
  status: IModelStatus
  updateApprovedAtText: string
  updateInfo: IModelUpdatedProduct
  updateStatus: IModelStatus
  useOption: boolean
  shownText: string
  soldOutText: string
  searchKeywords: string
  reviewMileageRewardRate: number
  confirmMileageRewardRate: number
}

export default class ModelProduct extends ModelProductDefault {
  createdAtText: string = ''
  customerPriceText: string = ''
  endedAtText: string = ''
  hasOption: boolean = false
  hasSellingPeriod: boolean = false
  hasUpdateInfo: boolean = false
  priceText: string = ''
  productOptions: IModelProductOption[] = [] as IModelProductOption[] // 기본 옵션(isDefault=True) 제외한 상품 옵션들
  specialCustomerPrice: number = 0
  startedAtText: string = ''
  useOption: boolean = false
  updateApprovedAtText: string = ''
  shownText: string = '-'
  soldOutText: string = '-'

  constructor(data: IModelProduct) {
    super(data)

    this.createdAtText = utilDate.date2string(this.createdAt)
    this.customerPriceText = utilData.currencyText(this.customerPrice)
    this.endedAtText = utilDate.date2string(this.endedAt)
    this.hasOption = this.productProductOptions?.length > 0
    this.hasSellingPeriod = !!data.startedAt || !!data.endedAt
    this.hasUpdateInfo = !_.isEmpty(this.updateInfo)
    this.priceText = utilData.currencyText(this.price)
    this.productOptions = this.productProductOptions?.filter((x) => !x.isDefault) as IModelProductOption[]
    this.productProductOptions = this.productProductOptions?.sort((a, b) => a.series - b.series)
    this.startedAtText = utilDate.date2string(this.startedAt)
    this.stockQuantity = this.stockQuantity > -1 ? this.stockQuantity : -1
    this.useOption = this.doesUseOption()
    this.updateApprovedAtText = utilDate.date2string(this.updateApprovedAt)
    this.shownText = this.isShown ? '노출' : '노출 안함'
    this.soldOutText = this.isRealSoldOut ? '품절' : '품절 아님'
  }

  private doesUseOption = () => {
    const productOptions = this.hasUpdateInfo ? this.updateInfo.productOptions : this.productProductOptions
    return (
      !!productOptions &&
      productOptions.filter((productOption) => !productOption.isDefault && !productOption.isDelete).length > 0
    )
  }
}
