import { ITableColumn } from 'components/@atomics/MTable'
import { MTextButton } from 'components/@atomics'
import useModal from 'containers/hooks/useModal'
import { ModalConfirm } from 'domains/common/modals'
import { ModelCoupon } from 'containers/models'
import { ModelExcelImportRequest } from 'containers/models/modelExcelImportRequest'

const deleteButton = (refetchRef: { current: Function | null }): ITableColumn<any> => {
  return {
    title: '삭제',
    dataIndex: 'id',
    key: 'delete',
    width: 100,
    render: (_: number, record: ModelCoupon) => {
      const { useModalProps } = useModal()
      const handleClick = () => {
        useModalProps.setData({ coupon: record })
        useModalProps.showModal()
      }

      return record.usedStatus === 'UNUSED' ? (
        <>
          <MTextButton onClick={handleClick}>[삭제]</MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: {
                gqlKey: 'deleteCoupon',
                variableKey: 'deleteCoupon',
              },
              title: '쿠폰 삭제',
              description: '해당 발급 쿠폰을 삭제하시겠습니까?',
            }}
            onAPISuccess={() => {
              if (refetchRef && refetchRef.current) refetchRef.current()
            }}
          />
        </>
      ) : null
    },
  }
}

const cancelIssuingManualCoupons = (refetchRef: { current: Function | null }): ITableColumn<any> => {
  return {
    title: '생성 요청 취소',
    dataIndex: 'id',
    key: 'delete',
    width: 100,
    render: (_: number, record: ModelExcelImportRequest) => {
      if (record.status !== 'IN_PROGRESS') {
        return null
      }
      const { useModalProps } = useModal()
      const handleClick = () => {
        useModalProps.setData({ id: record.id })
        useModalProps.showModal()
      }

      return (
        <>
          <MTextButton onClick={handleClick}>[삭제]</MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: {
                gqlKey: 'cancelIssuingManualCoupons',
                variableKey: 'id',
              },
              title: '쿠폰생성 취소요청',
              description: '해당 쿠폰의 업로드 생성을 취소하시겠습니까?',
            }}
            onAPISuccess={() => {
              if (refetchRef && refetchRef.current) refetchRef.current()
            }}
          />
        </>
      )
    },
  }
}

export { deleteButton, cancelIssuingManualCoupons }
