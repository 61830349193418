import { MFormItemWrapper } from 'components/formItems'
import { MUploadButton, Text12Blue } from 'components/@atomics'
import { Rule } from 'rc-field-form/lib/interface'

type MFormItemFileUploadProps = {
  label?: string
  name: string
  buttonText?: string
  comment?: string
  required?: boolean
  disabled?: boolean
  maxCount?: number
  initUrls?: string[]
  labelCol?: { span: number }
  accept?: string
  rules?: Rule[]
}

const MFormItemFileUpload = (props: MFormItemFileUploadProps) => {
  const {
    maxCount = 1,
    buttonText: _buttonText,
    comment,
    disabled,
    required = false,
    name,
    accept,
    ...restProps
  } = props
  const buttonText = _buttonText || '업로드'
  return (
    <MFormItemWrapper
      name={name}
      valuePropName="fileList"
      getValueFromEvent={(e) => {
        if (maxCount <= 1) {
          const file = e.fileList.slice(-1)[0]
          if (file) {
            if (file.originFileObj) {
              return file.originFileObj
            }
            return file
          }
          return ''
        }
        return e.fileList.slice(-maxCount).map((x: any) => (x.originFileObj ? x.originFileObj : x))
      }}
      required={required}
      extra={<Text12Blue>{comment}</Text12Blue>}
      {...restProps}
    >
      <MUploadButton name={name?.toString()} title={buttonText} disabled={disabled} accept={accept} />
    </MFormItemWrapper>
  )
}

export default MFormItemFileUpload
