import { MutationFunction } from '@apollo/client'
import { MutableRefObject, useRef, useState } from 'react'
import { message } from 'antd'
import styled from 'styled-components'

import { MConfirmModal, MListSortSelect } from 'components'
import { MButton, MLinkRouterButton, MTextButton, Text14Normal, Text14A45, Text14Red } from 'components/@atomics'
import * as utilData from '@libs/utils/utilData'
import MMainTable from 'components/MMainTable'
import MMutation from 'components/MMutation'
import { IModelAd } from 'containers/models/modelAd'
import tableCols from 'domains/admin/tableCols'
import ROUTES from '@constants/constRoutes'
import { queryParamsProps } from 'components/MQuery'
import { AD_STATUS } from '@constants/constDomain'
import useModal from 'containers/hooks/useModal'
import { FIELD_SORT_KEYS, FIELD_VALUES } from '@constants/constData'

const tableColumns = (refetchRef: MutableRefObject<Function | null>) => {
  return [
    tableCols.common.ID(),
    // TODO yoon: series 수정 방식이 drag & drop 방식에서 input으로 바뀜. 다시 drag & drop 변경되면 아래 주석 제거
    // tableCols.common.sort({ width: 100, dataIndex: 'series', key: 'series' }),
    tableCols.common.editableSort({ refetchRef }),
    {
      title: '이벤트명',
      width: 200,
      render: (record: IModelAd) => {
        return (
          <MLinkRouterButton
            sider={false}
            openNewWindow
            href={{
              pathname: ROUTES.LAYOUTS.EVENT_DETAIL,
              query: { id: record._id },
            }}
          >
            {record.name}
          </MLinkRouterButton>
        )
      },
    },
    tableCols.common.baseText({ title: FIELD_VALUES.FIELD_AD_EVENT_SLUG, dataIndex: 'slug' }),
    tableCols.common.petTypeText({ dataIndex: 'petType' }),
    {
      title: '진행 상태',
      width: 80,
      render: (record: IModelAd) => {
        switch (record.adStatus) {
          case AD_STATUS.AD_BEFORE:
            return <Text14A45>{record.adStatusText}</Text14A45>
          case AD_STATUS.AD_AFTER:
            return <Text14Red>{record.adStatusText}</Text14Red>
          case AD_STATUS.AD_ONGOING:
          default:
            return <Text14Normal>{record.adStatusText}</Text14Normal>
        }
      },
    },
    tableCols.common.baseText({ title: '노출 상태', dataIndex: 'isShownText', width: 80 }),
    {
      title: '종료하기',
      width: 80,
      render: (record: IModelAd) => {
        if (record.adStatus !== AD_STATUS.AD_ONGOING) {
          return <Text14Normal>-</Text14Normal>
        }

        const { useModalProps } = useModal()
        return (
          <MMutation
            gqlKey="finishAd"
            onAPISuccess={() => {
              if (refetchRef.current) {
                message.success('이벤트가 종료되었습니다.')
                refetchRef.current()
              }
            }}
          >
            {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
              return (
                <>
                  <MTextButton onClick={() => useModalProps.toggleModal()}>[종료]</MTextButton>
                  {/* TODO yoon: ModalConfirm 으로 변경 필요 */}
                  <MConfirmModal
                    useModalProps={useModalProps}
                    fixedProps={{
                      title: '이벤트 종료',
                      description: `${record.name} 이벤트를 종료하시겠습니까?`,
                    }}
                    onAction={async () => {
                      await mutation({ variables: { id: record.id } })
                    }}
                    loading={loading}
                  />
                </>
              )
            }}
          </MMutation>
        )
      },
    },
    tableCols.common.baseText({ title: '진행일', dataIndex: 'adProgressPeriodText', width: 280 }),
    {
      title: '링크 복사',
      width: 80,
      render: (record: IModelAd) => {
        return (
          <MTextButton
            onClick={() => {
              utilData.copy2Clipboard(`${process.env.FRONT_WEB_DOMAIN}/events/${record.slug}`)
            }}
          >
            복사
          </MTextButton>
        )
      },
    },
  ]
}

const MainTableAdList = () => {
  const refetchRef = useRef<Function | null>(null)
  const [selectAds, setSelectAds] = useState<IModelAd[]>([])
  const { useModalProps } = useModal()
  const queryParams: queryParamsProps = {
    gqlKey: 'ads',
    variables: {
      orderBy: FIELD_SORT_KEYS.FIELD_SORT_CREATED_AT_DESC,
    },
  }

  return (
    <MMainTable
      queryParams={queryParams}
      TitleSet={{ title: FIELD_VALUES.FIELD_AD_EVENT_SLUG, unit: '건' }}
      columns={tableColumns(refetchRef)}
      HeaderRightButtons={[
        <MListSortSelect
          filterOptions={[
            'FIELD_SORT_CREATED_AT_DESC',
            'FIELD_SORT_CREATED_AT',
            'FIELD_SORT_STARTED_AT_DESC',
            'FIELD_SORT_STARTED_AT',
            'FIELD_SORT_DISPLAY_ORDER',
            'FIELD_SORT_DISPLAY_ORDER_DESC',
          ]}
        />,
      ]}
      refetchRef={refetchRef}
      rowSelectionType="checkbox"
      onRowSelect={(rows: IModelAd[]) => {
        setSelectAds(rows)
      }}
      HeaderLeftButtons={[
        <MMutation
          gqlKey="deleteAds"
          onAPISuccess={() => {
            setSelectAds([])
            if (refetchRef.current) {
              message.success('이벤트가 삭제되었습니다.')
              refetchRef.current()
            }
          }}
        >
          {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
            return (
              <>
                <MButton
                  disabled={!selectAds.length}
                  onClick={() => {
                    useModalProps.toggleModal()
                  }}
                >
                  선택 삭제
                </MButton>
                {/* TODO yoon: ModalConfirm 으로 변경 필요 */}
                <MConfirmModal
                  useModalProps={useModalProps}
                  fixedProps={{
                    title: '이벤트 삭제',
                    description: '이벤트를 삭제하시겠습니까?',
                  }}
                  onAction={async () => {
                    const ids = selectAds.map((row: IModelAd) => row.id)
                    await mutation({ variables: { input: { ids } } })
                  }}
                  loading={loading}
                />
              </>
            )
          }}
        </MMutation>,
        <MLinkRouterButton type="primary" buttonType="button" href={ROUTES.LAYOUTS.EVENT_ADD}>
          이벤트 등록
        </MLinkRouterButton>,
      ]}
      // TODO yoon: series 수정 방식이 drag & drop 방식에서 input으로 바뀜. 다시 drag & drop 변경되면 아래 주석 제거
      // onDragRowAction={(newData, movedData) => {
      //   // @ts-ignore
      //   moveAdMutation({
      //     variables: {
      //       id: newData.id,
      //       input: { to: movedData.series },
      //     },
      //   }).then(() => {
      //     if (refetchRef.current) {
      //       refetchRef.current()
      //     }
      //   })
      // }}
    />
  )
}

export default MainTableAdList
