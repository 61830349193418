import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'
import USER_FRAGMENTS from 'containers/gqls/accounts/user/fragments'
import SHIPPING_FRAGMENTS from 'containers/gqls/orders/shipping/fragments'
import PRODUCT_OPTION_FRAGMENT from 'containers/gqls/products/productOption/fragments'

const ORDER_FRAGMENTS = {
  get orderDetail() {
    return gql`
      fragment orderDetail on OrderType {
        ...order
        orderRefundInfo {
          ...orderRefundInfo
        }
        user {
          id
          email
          isStaff
          dateJoined
          username
          name
          mobileNumber
          grade {
            id
            name
          }
        }
        orderOrderItems {
          pageInfo {
            ...pageInfo
          }
          edges {
            node {
              ...orderItem
              productImage {
                id
                thumbnail
              }
              productOption {
                id
                product {
                  id
                  name
                  brand {
                    id
                    name
                  }
                }
                productOptionKinds {
                  key
                  value
                }
              }
              brand {
                id
                name
              }
              order {
                id
                couponInfo
                couponDiscountAmount
                orderCouponDiscountAmount
              }
              shipping {
                id
                shippingPreset {
                  id
                  name
                }
                shippingCompany {
                  id
                  name
                  sweetTrackerCode
                  iamportCode
                }
                shippingNumbers
                shippingCompanyAndNumbers
                sentAt
                receivedAt
                couponInfo
                shippingType
                shippingTypeText
                isTrackable
                untrackedReason
                couponDiscountPrice
              }
              approvedCancelTransaction {
                ...cancelTransaction
              }
              refundInfo {
                id
                returnShippingFee
              }
              refundShippingInfo {
                id
                amount
                fee
                extraFee
                discountPrice
                couponDiscountPrice
                mileageDiscountPrice
                returnShippingFee
              }
              returnInfo {
                id
                returnShippingFee
                requestMessage
                rejectMessage
                guideMessage
                returnInfoReturnInfoImages {
                  edges {
                    node {
                      image
                      id
                      thumbnail
                    }
                  }
                }
              }
              exchangedOrderItem {
                id
                orderItemNumber
              }
            }
            cursor
          }
          totalCount
        }
        shippings {
          edges {
            node {
              id
              couponInfo
              shippingCoupons {
                edges {
                  node {
                    couponGroup {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        customerAddress {
          id
          postNumber
          basicAddress
          detailedAddress
          name
          mobileNumber
          message
        }
        approvedTransaction {
          ...transaction
          transactionCancelTransactions {
            edges {
              node {
                ...cancelTransaction
              }
            }
          }
        }
      }
      ${DEFAULT_FRAGMENTS.cancelTransaction}
      ${DEFAULT_FRAGMENTS.orderRefundInfo}
      ${DEFAULT_FRAGMENTS.order}
      ${DEFAULT_FRAGMENTS.orderItem}
      ${DEFAULT_FRAGMENTS.pageInfo}
      ${DEFAULT_FRAGMENTS.transaction}
    `
  },
  get orderItemWithoutOrder() {
    return gql`
      fragment orderItemWithoutOrder on OrderItemType {
        ...orderItem
        productImage {
          id
          thumbnail
        }
        productOption {
          ...productOptionOnly
        }
        brand {
          id
          name
        }
        shipping {
          ...shippingOnly
          shippingPreset {
            id
            name
            returnFee
          }
        }
        returnInfo {
          ...returnInfo
          returnInfoReturnInfoImages {
            edges {
              node {
                image
                id
                thumbnail
              }
            }
          }
        }
        approvedCancelTransaction {
          ...cancelTransaction
        }
      }
      ${DEFAULT_FRAGMENTS.cancelTransaction}
      ${DEFAULT_FRAGMENTS.orderItem}
      ${DEFAULT_FRAGMENTS.returnInfo}
      ${PRODUCT_OPTION_FRAGMENT.productOptionOnly}
      ${SHIPPING_FRAGMENTS.shippingOnly}
    `
  },
  get orderOnly() {
    return gql`
      fragment orderOnly on OrderType {
        id
        orderNumber
        user {
          ...userOnly
        }
        originalAmount
        productDiscountAmount
        couponDiscountAmount
        shippingAmount
        createdAt
      }
      ${USER_FRAGMENTS.userOnly}
    `
  },
}

export default ORDER_FRAGMENTS
