import { FunctionComponent } from 'react'
import { Form, Space } from 'antd'
import styled from 'styled-components'
import { Store, ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { MFormItemInput } from 'components/formItems'
import { MModal } from 'components/modals'
import { DomainModalWithExtraDataProps } from 'containers/hooks/useModal'
import { Text14Normal } from 'components/@atomics'

type ModalConfirmInputTableProps = {
  key: string
}

type MConfirmTableInputModalContentProps = {
  placeholder: string
  description: string
  ComponentTable: JSX.Element
}

type MConfirmTableInputModalDataProps = MConfirmTableInputModalContentProps & {
  title: string
}

const Content: FunctionComponent<MConfirmTableInputModalContentProps> = ({
  placeholder,
  description,
  ComponentTable,
}) => {
  return (
    <StyledSpace direction="vertical" size="middle">
      <Text14Normal>{description}</Text14Normal>
      {ComponentTable}
      <MFormItemInput placeholder={placeholder} name="message" />
    </StyledSpace>
  )
}

const MConfirmTableInputModal = ({
  useModalProps,
  extraData,
  onFormAction,
}: DomainModalWithExtraDataProps<ModalConfirmInputTableProps, MConfirmTableInputModalDataProps>) => {
  const { hideModal } = useModalProps
  const { title, placeholder, description, ComponentTable } = extraData

  const [form] = Form.useForm()

  const onFinish = (values: Store) => {
    if (onFormAction) {
      onFormAction(values)
    }
    hideModal()
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form form={form} onFinish={(values) => onFinish(values)} onFinishFailed={onFinishFailed}>
      <MModal
        title={title}
        useModalProps={useModalProps}
        ComponentContent={
          <Content placeholder={placeholder} description={description} ComponentTable={ComponentTable} />
        }
        form={form}
      />
    </Form>
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default MConfirmTableInputModal
