import { Radio, Space } from 'antd'
import { RadioProps } from 'antd/lib/radio'

import MRadioButton from 'components/@atomics/buttons/MRadioButton'
import { IFilterOptions } from 'components/formItems'

export type RadioGroupProps = RadioProps & {
  filterOptions: IFilterOptions
  spaceSize?: 'small' | 'middle' | 'large' | number
  buttonwidth?: number
}

const MGroupButton = (props: RadioGroupProps) => {
  const { filterOptions, spaceSize, buttonwidth, ...radioProps } = props

  return (
    <Radio.Group buttonStyle="solid" {...radioProps}>
      <Space size={spaceSize}>
        {filterOptions.map((option) => {
          return (
            <MRadioButton key={option.value.toString()} value={option.value} width={buttonwidth}>
              {option.title}
            </MRadioButton>
          )
        })}
      </Space>
    </Radio.Group>
  )
}

export default MGroupButton
