import React, { FC, memo, MutableRefObject, ReactElement, ReactNode, useMemo } from 'react'
import { Row, Space } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

import { MOuterCard, MTable, MDivider, MSelect, MTotalCountText, MLinkRouterButton } from 'components/@atomics'
import { MTableProps } from 'components/@atomics/MTable'
import useMainTable from 'containers/hooks/useMainTable'
import MQuery, { listVariablesProps, queryParamsProps } from 'components/MQuery'
import { DataResult, DataResults } from 'containers/gqls/data'
import MTableRefetch from 'components/MTableRefetch'
import { DEFAULT_PAGE_SIZE } from '@constants/constData'
import { MFormFilterProps } from 'components/MFormFilter'
import { useSelector } from 'react-redux'
import { RootState } from '@libs/redux/store'
import { hasData } from '@libs/utils/utilData'

type SortObjSeries = { id: string; series: number | null }
type SortObjIsImportant = { id: string; isImportant: boolean }

export type MMainTableProps = MTableProps & {
  HeaderLeftButtons?: ReactElement<any>[]
  HeaderRightButtons?: ReactElement<any>[]
  Title?: ReactNode
  TitleSet?: { title: string; unit: '건' | '개' | '명' }
  onRowSelect?: Function
  size?: SizeType
  pageSizing?: boolean
  initialPageSize?: number
  pageSizeOptions?: number[]
  queryParams: queryParamsProps
  refetchRef?: MutableRefObject<Function | null>
  dataRef?: MutableRefObject<{ [index: string]: DataResults | DataResult } | null>
  onTableDataLoaded?: (data: any) => void
  multipleHeaderRow?: boolean
  formFilterData?: MFormFilterProps
  listButton?: boolean
  sortBySeries?: boolean // MainTableCustomerGradeList에서만 쓰고 있음
  sortByIsImportant?: boolean
  noTotalCount?: boolean
  onDragRowAction?: (newData: any, movedData: any) => void
}

const ListButton = () => (
  <Row justify="center" style={{ marginTop: 20 }}>
    <MLinkRouterButton buttonType="backButton">목록으로</MLinkRouterButton>
  </Row>
)

const SelectPageSize = ({
  pageSizing,
  pageSizeOptions,
  initialPageSize,
  onPageSizeChange,
}: {
  pageSizing: boolean
  pageSizeOptions: number[]
  initialPageSize: number
  onPageSizeChange: (pageSize: number) => void
}) => {
  const mainTableVariables = useSelector((state: RootState) => state.reduxUIReducers.mainTableVariables)
  if (!pageSizing) return null

  const updatedValue = mainTableVariables?.pageInfo?.first
  return (
    <MSelect
      filterOptions={makePageSizeOptions(pageSizeOptions)}
      selectWidth={100}
      defaultValue={initialPageSize}
      value={updatedValue}
      onChange={(value: number) => {
        onPageSizeChange(value)
      }}
    />
  )
}

const DEFAULT_PAGE_SIZE_OPTIONS = [3, 10, 20, 100]
const makePageSizeOptions = (arr: number[]) => arr.map((num) => ({ value: num, title: `${num}개씩` }))

const MMainTable: FC<MMainTableProps> = ({
  rowSelectionType,
  columns,
  pagination = { position: ['bottomCenter'] },
  HeaderLeftButtons,
  HeaderRightButtons,
  scroll = 800,
  TitleSet,
  onRowSelect,
  size,
  pageSizing = true,
  rowIndex,
  initialPageSize = DEFAULT_PAGE_SIZE,
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
  queryParams,
  refetchRef,
  dataRef,
  onTableDataLoaded,
  multipleHeaderRow,
  listButton = false,
  sortBySeries = false,
  sortByIsImportant = false,
  noTotalCount = false,
  onDragRowAction,
}) => {
  const { gqlKey, variables, dataKey } = queryParams
  const tableId = dataKey || gqlKey
  const { setInitVariablesAndReturn, getTablePagination, onPageSizeChange, useVariables, setRefetch } = useMainTable({
    initialPageSize,
  })

  // variables, formFilterData가 바뀌는 상황은? 고려할 필요가 있을까?
  // queryParams은 한번 호출되고 난 후 더이상 사용하지 않고 refetch로 해결해야 될텐데
  queryParams.variables = useMemo(
    () => setInitVariablesAndReturn(variables as listVariablesProps),
    [JSON.stringify(variables)]
  )

  const headerStyleJustify = HeaderLeftButtons ? 'space-between' : 'end'
  const ableToShowRightButtons = pageSizing || HeaderRightButtons

  const sortObjBySeries = (firstObj: SortObjSeries, secondObj: SortObjSeries): number => {
    if (!firstObj.series) return 1
    if (!secondObj.series) return -1
    return firstObj.series - secondObj.series ? 1 : -1
  }

  const sortObjByIsImportant = (firstObj: SortObjIsImportant, secondObj: SortObjIsImportant): number => {
    if (firstObj.isImportant < secondObj.isImportant) return 1
    if (firstObj.isImportant > secondObj.isImportant) return -1
    return 0
  }

  const setSortData = (data: any[]) => {
    if (sortBySeries) return data?.sort(sortObjBySeries)
    if (sortByIsImportant) return data?.sort(sortObjByIsImportant)
    return data
  }
  return (
    <MQuery queryParams={queryParams} fetchPolicy="network-only">
      {({ data, refetch }) => {
        if (!hasData(data) || !(data[gqlKey] || (dataKey && data[dataKey]))) return null
        if (onTableDataLoaded) {
          onTableDataLoaded(data)
        }
        // console.log(data)
        const MemoizedMTableRefetch = memo(MTableRefetch)
        if (refetchRef) {
          refetchRef.current = refetch
        }
        setRefetch(refetch)
        if (dataRef) {
          dataRef.current = data
        }
        const _dataKey = dataKey || gqlKey
        const { totalCount, data: _data } = data[_dataKey] as DataResults
        const mainTablePagination = getTablePagination(pagination, totalCount)
        let dataResource = setSortData(_data)
        if (mainTablePagination !== false) {
          const { calcIndexDescNumber, calcIndexAscNumber } = mainTablePagination
          dataResource = dataResource.map((item, index) => {
            return {
              ...item,
              indexDescNumber: calcIndexDescNumber(index),
              indexAscNumber: calcIndexAscNumber(index),
            }
          })
        }

        return (
          <MOuterCard>
            {!noTotalCount && (
              <MTotalCountText totalCount={totalCount!} title={TitleSet?.title!} unit={TitleSet?.unit!} />
            )}

            <MDivider margin={14} />

            <Row justify={headerStyleJustify} align="middle" style={{ marginBottom: 16 }}>
              <Space size="small">
                {HeaderLeftButtons &&
                  HeaderLeftButtons.map((x, index) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <div key={index}>{x}</div>
                  })}
              </Space>
              {ableToShowRightButtons && (
                <Space size="small">
                  <>
                    {HeaderRightButtons &&
                      HeaderRightButtons.map((x, index) => {
                        // eslint-disable-next-line react/no-array-index-key
                        return <div key={index}>{x}</div>
                      })}
                  </>
                  <SelectPageSize
                    pageSizing={pageSizing}
                    pageSizeOptions={pageSizeOptions}
                    initialPageSize={initialPageSize}
                    onPageSizeChange={onPageSizeChange}
                  />
                </Space>
              )}
            </Row>
            <MTable
              rowSelectionType={rowSelectionType}
              size={size}
              columns={columns}
              dataSource={dataResource}
              pagination={mainTablePagination}
              scroll={scroll}
              rowIndex={rowIndex}
              onRowSelect={onRowSelect}
              multipleHeaderRow={multipleHeaderRow}
              tableId={tableId}
              onDragRowAction={onDragRowAction}
            />
            <MemoizedMTableRefetch refetch={refetch} useVariables={useVariables} />
            {!_data.length && listButton ? <ListButton /> : <></>}
          </MOuterCard>
        )
      }}
    </MQuery>
  )
}

export default MMainTable
