import { COUPON_GROUP_DISCOUNT_TYPE } from '@constants/constDomain'
import * as utilData from '@libs/utils/utilData'
import { hasData } from '@libs/utils/utilData'

export interface IModelCouponInfo {
  id: string
  name: string
  discountType: COUPON_GROUP_DISCOUNT_TYPE | string
  discountRate: number
  discountAmount: number
  minDiscountAmount: number
  maxDiscountAmount: number | null
  benefitText: string
  discountRateText: string
  maxDiscountAmountText: string
}

export default class ModelCouponInfo {
  id = ''
  name = ''
  discountType = ''
  discountRate = 0
  discountAmount = 0
  minDiscountAmount = 0
  maxDiscountAmount = null
  benefitText = ''
  discountRateText = ''
  maxDiscountAmountText = ''

  constructor(data: string, discountAmount: number) {
    Object.assign(this, utilData.keysToCamel(JSON.parse(data)))

    this.discountRateText = utilData.percentText(Math.round(this.discountRate * 100))
    this.maxDiscountAmountText = this.maxDiscountAmount ? `최대 ${utilData.currencyText(this.maxDiscountAmount!)}` : ''
    this.benefitText = this.getCouponBenefitText(discountAmount)
  }

  getCouponBenefitText = (discountAmount: number) => {
    if (hasData(this)) {
      let benefitText = ''
      switch (this.discountType) {
        case COUPON_GROUP_DISCOUNT_TYPE.RATE:
          benefitText = `${this.name} ${utilData.currencyText(discountAmount)}`
          benefitText += this.maxDiscountAmount
            ? `(${this.discountRateText}, ${this.maxDiscountAmountText})`
            : `(${this.discountRateText})`
          break
        case COUPON_GROUP_DISCOUNT_TYPE.AMOUNT:
        default:
          benefitText = `${this.name} ${utilData.currencyText(discountAmount)} (${utilData.currencyText(
            this.discountAmount
          )})`
          break
      }
      return benefitText
    }
    return '-'
  }
}
