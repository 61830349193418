import React, { MutableRefObject } from 'react'
import Link from 'next/link'
import * as utilData from '@libs/utils/utilData'
import { ORDER_STATUS_TYPE, USER_MODE } from '@constants/constDomain'
import { S3_HOST_URL } from '@constants/constData'
import { ITableColumn, ITableColumnFixedHasLinkProps, ITableColumnFixedProps } from 'components/@atomics/MTable'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { IModelBillingItem } from 'containers/models/modelBillingItem'
import { MTextButton, Text14Blue, Text14Normal } from 'components/@atomics'
import { MFormItemInput } from 'components/formItems'
import { MConfirmModal } from 'components/modals'
import { makeMergeRow } from 'domains/admin/tableCols/domain/TableColsOrder'
import useModal from 'containers/hooks/useModal'
import {
  ModalConfirmInput,
  ModalAcceptReturnOrder,
  ModalBankAccount,
  ModalConfirm,
  ModalConfirmReturnOrder,
  ModalPhoto,
  ModalRejectReturnOrder,
} from 'domains/common/modals'
import { DATE_FORMAT, datetime2stringWithFormat } from '@libs/utils/utilDate'
import useUserInfo from 'containers/hooks/useUserInfo'
import { IModelBrand } from 'containers/models/modelBrand'
import { MergeTypeProps } from 'domains/admin/mainTables/orders/MainTableOrder'
import { Image, message } from 'antd'
import { IModelProduct } from 'containers/models/modelProduct'
import ModalAcceptExchangeReturnOrder from 'domains/common/modals/ModalAcceptExchangeReturnOrder'
import { MFormItemSelect, MModal, MMutation } from 'components'
import { MutationFunction } from '@apollo/client'
import ROUTES from '@constants/constRoutes'
import { useSelector } from 'react-redux'
import { RootState } from '@libs/redux/store'

type MergedOrderItemsReturnType = {
  orderItem?: IModelOrderItem
  orderItems?: IModelOrderItem[]
}

const getMergedOrderItems = ({
  mergeType,
  orderItem,
  orderItemGroupMapRef,
}: {
  mergeType?: MergeTypeProps
  orderItem: IModelOrderItem
  orderItemGroupMapRef?: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
}): MergedOrderItemsReturnType => {
  if (!mergeType || !orderItemGroupMapRef) return { orderItem }
  const orderItemGroupMap = orderItemGroupMapRef.current || {}
  const key = orderItem.makeSameKeyByMergeType({ mergeType, orderItem })
  const orderItems = orderItemGroupMap[key]
  if (utilData.hasData(orderItems)) return { orderItems }
  return { orderItem }
}

const orderItemNumber = ({
  dataIndex = 'orderItemNumber',
  fixed = 'left',
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<any> => {
  return {
    title: '상품 주문번호',
    key: 'orderItemNumber',
    width: 90,
    dataIndex,
    fixed,
    render: (v: string | IModelOrderItem, record: IModelOrderItem | IModelBillingItem | any) => {
      let orderItem
      if (dataIndex === 'orderItem') {
        orderItem = v
      } else if (dataIndex === 'billingItem') {
        orderItem = (record as IModelBillingItem).orderItem
      } else {
        orderItem = record
      }
      const { orderItemNumber: _orderItemNumber } = orderItem
      return <Text14Normal>{utilData.tableText(_orderItemNumber)}</Text14Normal>
    },
  }
}

const exchangedOrderItemNumber = ({
  fixed = false,
}: { fixed?: 'left' | 'right' | boolean } = {}): ITableColumn<any> => {
  return {
    title: '원 상품 주문번호',
    key: 'exchangedOrderItemNumber',
    width: 90,
    fixed,
    render: (_: string, record: IModelOrderItem) => {
      const _exchangedOrderItemNumber = record.exchangedOrderItem?.orderItemNumber
      return <Text14Normal>{utilData.tableText(_exchangedOrderItemNumber)}</Text14Normal>
    },
  }
}

const formItemSelectShippingCompany = (disabled: boolean) => {
  return {
    title: '택배사',
    key: 'shippingCompanyId',
    width: 110,
    render: (_: string, record: IModelOrderItem) => {
      const initData = useSelector((state: RootState) => state.reduxDataReducers.initData)
      return (
        <MFormItemSelect
          name={[`${record.shipping.id}`, `${record.id}`, 'shippingCompanyId']}
          defaultValue={record.shippingCompanyId}
          selectWidth={100}
          selectOptions={initData.shippingCompanySelectOptions}
          disabled={disabled}
        />
      )
    },
  }
}

const formItemShippingNumber = (disabled: boolean) => {
  const getFormItemInputInvoiceNumberOptions = (orderItemId: string, shippingId: string) => ({
    name: [`${shippingId}`, `${orderItemId}`, 'shippingNumber'],
    width: 100,
    disabled,
    placeholder: '송장번호 구분은 쉼표(,)로 하시면 됩니다.',
  })
  return {
    title: '송장번호 입력',
    key: 'shippingNumber',
    width: 220,
    render: (record: IModelOrderItem) => {
      const selectOptions = getFormItemInputInvoiceNumberOptions(record.id, record.shipping.id)
      return <MFormItemInput {...selectOptions} />
    },
  }
}

const totalCouponDiscount = () => {
  return {
    title: '총 쿠폰할인',
    key: 'totalCouponDiscountPrice',
    width: 100,
    render: (record: IModelOrderItem) => {
      const children = (
        <Text14Normal>{utilData.currencyMinusText(record?.order?.totalCouponDiscountAmount)}</Text14Normal>
      )
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const totalOrderItemCouponDiscount = () => {
  return {
    title: '총 쿠폰할인',
    key: 'totalOrderItemCouponDiscount',
    width: 100,
    render: (record: IModelOrderItem) => {
      return (
        <Text14Normal>
          {utilData.currencyMinusText(record?.couponDiscountPrice + record?.orderCouponDiscountPrice)}
        </Text14Normal>
      )
    },
  }
}

const couponDiscount = () => {
  return {
    title: '상품 쿠폰할인',
    dataKey: 'couponInfoData',
    width: 100,
    render: (record: IModelOrderItem) => {
      return <Text14Normal>{utilData.currencyMinusText(record.couponDiscountPrice)}</Text14Normal>
    },
  }
}

const shippingCouponDiscount = ({
  orderItemGroupMapRef,
  mergeType,
}: {
  orderItemGroupMapRef?: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  mergeType?: MergeTypeProps
}) => {
  return {
    title: '배송비 쿠폰할인',
    dataKey: ['shipping', 'couponDiscountPrice'],
    width: 100,
    render: (record: IModelOrderItem) => {
      const { orderItem, orderItems } = getMergedOrderItems({
        mergeType,
        orderItem: record,
        orderItemGroupMapRef,
      })
      if (orderItem) return utilData.currencyMinusText(record.shipping.couponDiscountPrice)
      if (!utilData.hasData(orderItems)) return '0원'
      const groupedShippingCouponDiscountAmount = orderItems!.reduce((prev, next) => {
        return prev + next.shipping.couponDiscountPrice
      }, 0)

      const children =
        groupedShippingCouponDiscountAmount === 0
          ? '0원'
          : `-${utilData.currencyText(groupedShippingCouponDiscountAmount)}`

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const orderCouponDiscount = ({
  orderItemGroupMapRef,
  mergeType,
}: {
  orderItemGroupMapRef?: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  mergeType?: MergeTypeProps
} = {}) => {
  return {
    title: '주문 쿠폰할인',
    dataKey: ['order', 'orderCouponDiscountAmount'],
    width: 100,
    render: (record: IModelOrderItem) => {
      const { orderItem, orderItems } = getMergedOrderItems({
        mergeType,
        orderItem: record,
        orderItemGroupMapRef,
      })
      if (orderItem) return utilData.currencyMinusText(record.orderCouponDiscountPrice)
      if (!utilData.hasData(orderItems)) return '0원'
      const groupedCouponDiscountAmount = orderItems!.reduce((prev, next) => {
        return prev + next.orderCouponDiscountPrice
      }, 0)

      const children =
        groupedCouponDiscountAmount === 0 ? '0원' : `-${utilData.currencyText(groupedCouponDiscountAmount)}`

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const brandName = () => {
  return {
    title: '브랜드',
    dataIndex: 'brand',
    width: 120,
    render: (brand: IModelBrand) => {
      return <Text14Normal>{utilData.tableText(brand?.name)}</Text14Normal>
    },
  }
}

const productBrand = () => {
  return {
    title: '브랜드',
    dataIndex: ['productOption', 'product', 'brand'],
    width: 120,
    render: (brand: IModelBrand) => {
      return <Text14Normal>{utilData.tableText(brand?.name)}</Text14Normal>
    },
  }
}

const returnStatus = () => {
  return {
    title: '주문상태',
    dataIndex: 'orderStatus',
    key: 'orderStatus',
    width: 100,
    render: (_orderStatus: ORDER_STATUS_TYPE, record: IModelOrderItem) => {
      const children = <Text14Normal>{record.orderStatusText}</Text14Normal>

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const returnRequestedAt = ({ title = '반품요청일' } = {}) => {
  return {
    title,
    key: 'returnRequestedAt',
    width: 100,
    render: (record: IModelOrderItem) => {
      const children = datetime2stringWithFormat(record.returnRequestedAt, DATE_FORMAT)

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const mileageDiscount = ({
  orderItemGroupMapRef,
  mergeType,
}: {
  orderItemGroupMapRef?: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  mergeType?: MergeTypeProps
} = {}) => {
  return {
    title: '적립금 할인',
    key: 'mileageDiscount',
    width: 100,
    render: (record: IModelOrderItem) => {
      const { orderItem, orderItems } = getMergedOrderItems({
        mergeType,
        orderItem: record,
        orderItemGroupMapRef,
      })
      if (orderItem) return utilData.currencyMinusText(record.mileageDiscountPrice)
      if (!utilData.hasData(orderItems)) return '0원'
      const groupedMileageDiscountAmount = orderItems!.reduce((prev, next) => {
        return prev + next.mileageDiscountPrice
      }, 0)

      const children =
        groupedMileageDiscountAmount === 0 ? '0원' : `-${utilData.currencyText(groupedMileageDiscountAmount)}`

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}
const amount = ({
  orderItemGroupMapRef,
  mergeType,
  title = '결제금액',
}: {
  orderItemGroupMapRef?: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  title?: string
  mergeType?: MergeTypeProps
} = {}) => {
  return {
    title,
    key: 'amount',
    width: 100,
    render: (record: IModelOrderItem) => {
      const { orderItem, orderItems } = getMergedOrderItems({
        mergeType,
        orderItem: record,
        orderItemGroupMapRef,
      })
      if (orderItem) return utilData.currencyText(record.amount)
      if (!utilData.hasData(orderItems)) return '-'
      const totalAmount = orderItems!.reduce((prev, next) => {
        return prev + next.amount
      }, 0)
      const children = utilData.currencyText(totalAmount)
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const refundShippingFee = ({
  orderItemGroupMapRef,
  mergeType,
  title = '환불배송비',
}: {
  orderItemGroupMapRef?: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  title?: string
  mergeType?: MergeTypeProps
} = {}) => {
  return {
    title,
    key: ['refundShippingInfo', 'amount'],
    width: 100,
    render: (record: IModelOrderItem) => {
      const { orderItem, orderItems } = getMergedOrderItems({
        mergeType,
        orderItem: record,
        orderItemGroupMapRef,
      })
      if (orderItem) return utilData.currencyText(record.refundShippingInfo?.amount || 0)
      if (!utilData.hasData(orderItems)) return '-'
      const children = utilData.currencyText(record.refundShippingInfo?.amount || 0)
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const returnShippingFee = ({
  orderItemGroupMapRef,
  mergeType,
  title = '반품수수료',
}: {
  orderItemGroupMapRef?: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  title?: string
  mergeType?: MergeTypeProps
} = {}) => {
  return {
    title,
    key: ['refundShippingInfo', 'returnShippingFee'],
    width: 100,
    render: (record: IModelOrderItem) => {
      const { orderItem, orderItems } = getMergedOrderItems({
        mergeType,
        orderItem: record,
        orderItemGroupMapRef,
      })
      let _returnShippingFee = 0
      if (record.refundShippingInfo?.returnShippingFee) {
        _returnShippingFee = record.refundShippingInfo.returnShippingFee
      } else if (record.returnInfo?.returnShippingFee) {
        _returnShippingFee = record.returnInfo.returnShippingFee
      }
      if (orderItem) return utilData.currencyText(_returnShippingFee)
      if (!utilData.hasData(orderItems)) return '-'
      const children = utilData.currencyText(_returnShippingFee)
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const returnAmountWithFee = ({
  orderItemGroupMapRef,
  title = '최종 환불(예정) 금액',
  mergeType,
}: {
  orderItemGroupMapRef?: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  title?: string
  mergeType?: MergeTypeProps
} = {}) => {
  return {
    title,
    key: 'returnTotalPrice',
    width: 100,
    render: (record: IModelOrderItem) => {
      const { orderItem, orderItems } = getMergedOrderItems({
        mergeType,
        orderItem: record,
        orderItemGroupMapRef,
      })
      if (orderItem) return utilData.currencyText(record.refundAmount)
      if (!utilData.hasData(orderItems)) return '-'
      let totalRefundAmount = orderItems!.reduce((prev, next) => {
        return prev + next.refundAmount
      }, 0)
      const refundShippingInfoIdSet = new Set<string>()
      let realRefundShippingFee = 0
      let realReturnShippingFee = 0
      const shippingIdSet = new Set<string>()
      let expectedRefundShippingFee = 0
      let expectedReturnShippingFee = 0
      orderItems!.forEach((_orderItem) => {
        if (!shippingIdSet.has(_orderItem.shipping.id)) {
          if (_orderItem.shipping.isRefundable) {
            expectedRefundShippingFee += _orderItem.shipping.amount
          } else {
            expectedReturnShippingFee += _orderItem.returnInfo.returnShippingFee
          }
          shippingIdSet.add(_orderItem.shipping.id)
        }
        if (
          utilData.hasData(_orderItem.refundShippingInfo) &&
          !refundShippingInfoIdSet.has(_orderItem.refundShippingInfo.id)
        ) {
          realRefundShippingFee += _orderItem.refundShippingInfo.amount
          realReturnShippingFee += _orderItem.refundShippingInfo.returnShippingFee
          refundShippingInfoIdSet.add(_orderItem.refundShippingInfo.id)
        }
      })

      if (refundShippingInfoIdSet.size > 0) {
        // 반품 완료된 경우
        totalRefundAmount += realRefundShippingFee - realReturnShippingFee
      } else {
        // 반품 진행중인 경우
        totalRefundAmount += expectedRefundShippingFee - expectedReturnShippingFee
      }
      const children = utilData.currencyText(totalRefundAmount)
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const manualCancelForNotShipped = ({
  refetchRef,
  statusFilterRefetchRef,
}: {
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef: MutableRefObject<Function | null>
}) => {
  const userMode = useUserInfo.useUserMode()
  let title = userMode === USER_MODE.ADMIN ? '수동 환불' : '수동 환불 요청'
  let description = '직접 고객의 환불 계좌로 이체해야 합니다. 환불계좌정보를 확인하셔서 경영지원팀에 요청해주세요.'
  if (userMode === USER_MODE.SELLER) {
    title = '수동 환불 요청'
    description =
      '수동 환불은 핏펫 관리자만 처리할 수 있습니다. 수동환불이 필요할 경우  "확인" 버튼을 눌러 주세요. 수동처리 완료 시 취소 완료로 상태가 변경됩니다.'
  }
  return {
    title,
    key: 'manualCancelForNotShipped',
    width: 100,
    render: (record: IModelOrderItem) => {
      const { orderStatus } = record
      const { useModalProps } = useModal()

      return orderStatus === ORDER_STATUS_TYPE.CANCEL_REQUESTED ? (
        <MTextButton
          onClick={() => {
            useModalProps.setData({ orderItem: record })
            useModalProps.toggleModal()
          }}
        >
          <Text14Blue>[{title}]</Text14Blue>
          <ModalConfirm
            onAPISuccess={() => {
              if (refetchRef && refetchRef.current) {
                refetchRef.current()
              }
              if (statusFilterRefetchRef && statusFilterRefetchRef.current) {
                statusFilterRefetchRef.current()
              }
            }}
            fixedProps={{
              mutationKey: {
                gqlKey: 'confirmCancelOrderItem',
                variableKey: 'orderItemId',
              },
              title,
              description,
              confirmMessage: `${title} 처리되었습니다.`,
            }}
            useModalProps={useModalProps}
          />
        </MTextButton>
      ) : null
    },
  }
}

const manualCancelForReturned = ({
  orderItemGroupMapRef,
  mergeType,
  refetchRef,
  statusFilterRefetchRef,
}: {
  orderItemGroupMapRef?: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  mergeType?: MergeTypeProps
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
} = {}) => {
  const { useModalProps } = useModal()
  const userMode = useUserInfo.useUserMode()
  const title = userMode === USER_MODE.ADMIN ? '수동 환불' : '수동 환불 요청'

  return {
    title,
    key: 'canceledAt',
    width: 100,
    render: (record: IModelOrderItem) => {
      let children

      if (
        record.canceledAt ||
        record.orderStatus === ORDER_STATUS_TYPE.RETURN_REQUESTED ||
        record.orderStatus === ORDER_STATUS_TYPE.RETURN_UNDER_RETURN ||
        record.orderStatus === ORDER_STATUS_TYPE.RETURN_CONFIRMED ||
        record.orderStatus === ORDER_STATUS_TYPE.CANCELED
      ) {
        children = <Text14Normal>{datetime2stringWithFormat(record.canceledAt)}</Text14Normal>
      } else {
        children = (
          <>
            <MTextButton
              onClick={() => {
                if (!utilData.hasData(record.returnInfo)) {
                  message.warn('주문자의 환불 정보가 등록되어 있지 않습니다.')
                  return
                }

                const { orderItem, orderItems } = getMergedOrderItems({
                  mergeType,
                  orderItem: record,
                  orderItemGroupMapRef,
                })

                if (userMode === USER_MODE.ADMIN) {
                  useModalProps.setActionParams({
                    returnInfo: record.returnInfo,
                    user: record.order.user,
                    orderItems: orderItems || [orderItem],
                    isManual: true,
                    refetchRef,
                    statusFilterRefetchRef,
                  })
                } else {
                  useModalProps.setData({ returnInfos: [record.returnInfo] })
                }

                useModalProps.showModal()
              }}
            >
              <Text14Blue>[{title}]</Text14Blue>
            </MTextButton>
            {userMode === USER_MODE.ADMIN ? (
              <ModalConfirmReturnOrder useModalProps={useModalProps} />
            ) : (
              <ModalConfirmInput
                useModalProps={useModalProps}
                onAPISuccess={() => {
                  if (refetchRef && refetchRef.current) {
                    refetchRef.current()
                  }
                  if (statusFilterRefetchRef && statusFilterRefetchRef.current) {
                    statusFilterRefetchRef.current()
                  }
                }}
                fixedProps={{
                  mutationKey: {
                    gqlKey: 'confirmReturnInfos',
                    variableKey: 'confirmReturnInfosRequest',
                  },
                  title: '수동 환불 요청',
                  description:
                    '수동 환불은 핏펫 관리자만 처리할 수 있습니다. 수동환불이 필요할 경우 반품 배송비를 입력 후 "확인" 버튼을 눌러 주세요. 수동처리 완료 시 반품 완료로 상태가 변경됩니다.',
                  inputOption: {
                    name: 'returnShippingFee',
                    type: 'number',
                    label: '반품 배송비',
                    placeholder: '반영되어야 할 반품 배송비를 입력해 주세요.',
                  },
                  confirmMessage: '수동 환불 요청이 처리 되었습니다.',
                }}
              />
            )}
          </>
        )
      }
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const refundAccount = () => {
  return {
    title: '환불계좌정보',
    key: 'refundAccount',
    width: 200,
    render: (record: IModelOrderItem) => {
      const userMode = useUserInfo.useUserMode()
      const { useModalProps } = useModal()
      const {
        order: { user },
      } = record
      const { accountHolderName, accountNumber, bankCode, bankAccountText } = user
      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.setData({
                id: record.order.user.id,
                accountNumber,
                accountHolderName,
                bankCode,
              })
              useModalProps.toggleModal()
            }}
          >
            {bankAccountText || '입력'}
          </MTextButton>
          {userMode === USER_MODE.ADMIN ? (
            <ModalBankAccount useModalProps={useModalProps} />
          ) : (
            <MConfirmModal
              useModalProps={useModalProps}
              fixedProps={{
                title: '환불계좌 정보',
                description: '핏펫몰 담당자만 처리할 수 있습니다.',
                footerType: 'MODAL_FOOTER_OK',
              }}
            />
          )}
        </>
      )
    },
  }
}

const acceptReturnButton = ({
  orderItemGroupMapRef,
  mergeType,
  isExchange = false,
  refetchRef,
  statusFilterRefetchRef,
}: {
  orderItemGroupMapRef: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  mergeType?: MergeTypeProps
  isExchange?: boolean
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}) => {
  return {
    title: isExchange ? '교환접수처리일' : '반품접수처리일',
    dataIndex: 'returnAcceptedAt',
    key: 'acceptReturnButton',
    width: 100,
    render: (returnAcceptedAt: string, record: IModelOrderItem) => {
      const { useModalProps } = useModal()
      const { orderStatus } = record
      const hasReturnRequested = [ORDER_STATUS_TYPE.RETURN_REQUESTED, ORDER_STATUS_TYPE.EXCHANGE_REQUESTED].includes(
        orderStatus
      )

      const children = hasReturnRequested ? (
        <>
          <MTextButton
            onClick={() => {
              if (!utilData.hasData(record.returnInfo)) {
                message.warn('주문자의 환불 정보가 등록되어 있지 않습니다.')
                return
              }

              const { orderItem, orderItems } = getMergedOrderItems({
                mergeType,
                orderItem: record,
                orderItemGroupMapRef,
              })
              useModalProps.setActionParams({
                returnInfo: record.returnInfo,
                orderItems: orderItems || [orderItem],
                refetchRef,
                statusFilterRefetchRef,
              })
              useModalProps.setData({ returnInfos: [record.returnInfo] })
              useModalProps.toggleModal()
            }}
          >
            [접수처리]
          </MTextButton>
          {isExchange ? (
            <ModalAcceptExchangeReturnOrder useModalProps={useModalProps} />
          ) : (
            <ModalAcceptReturnOrder useModalProps={useModalProps} />
          )}
        </>
      ) : (
        utilData.dateTimeFormatText(returnAcceptedAt)
      )
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const rejectReturnButton = ({
  isExchange = false,
  orderItemGroupMapRef,
  mergeType,
  refetchRef,
  statusFilterRefetchRef,
}: {
  isExchange?: boolean
  orderItemGroupMapRef: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  mergeType?: MergeTypeProps
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}) => {
  const text = isExchange ? '교환' : '반품'
  return {
    title: `${text}요청철회`,
    dataIndex: 'id',
    key: 'rejectReturnButton',
    width: 70,
    render: (_value: string, record: IModelOrderItem) => {
      const { useModalProps: useMessageModalProps } = useModal()
      const { useModalProps } = useModal()

      const { orderStatus } = record
      const ableToReject = [ORDER_STATUS_TYPE.RETURN_REQUESTED, ORDER_STATUS_TYPE.EXCHANGE_REQUESTED].includes(
        orderStatus
      )

      const children = ableToReject ? (
        <>
          <MTextButton onClick={() => useMessageModalProps.toggleModal()}>[철회]</MTextButton>
          <MConfirmModal
            fixedProps={{
              title: `${text} 철회`,
              description: `${text} 철회시 구매확정 상태로 변경됩니다. 이후 상태변경이 안되니 신중하게 진행해주세요.`,
            }}
            useModalProps={useMessageModalProps}
            onAction={() => {
              const { orderItem, orderItems } = getMergedOrderItems({
                mergeType,
                orderItem: record,
                orderItemGroupMapRef,
              })
              useModalProps.setActionParams({
                orderItems: orderItems || [orderItem],
                isExchange,
                refetchRef,
                statusFilterRefetchRef,
              })
              useModalProps.setData({
                returnInfos: [record.returnInfo],
              })
              useModalProps.toggleModal()
            }}
          />
          <ModalRejectReturnOrder useModalProps={useModalProps} />
        </>
      ) : (
        <p>-</p>
      )

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const returnedReturnButton = ({
  orderItemGroupMapRef,
  mergeType,
  isExchange = false,
  refetchRef,
  statusFilterRefetchRef,
}: {
  orderItemGroupMapRef: MutableRefObject<{ [index: string]: IModelOrderItem[] }>
  mergeType?: MergeTypeProps
  isExchange?: boolean
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}) => {
  const text = isExchange ? '교환' : '반품'
  return {
    title: `${text} 완료`,
    dataIndex: 'returnConfirmedAt',
    key: 'returnedReturnButton',
    width: 100,
    render: (returnReturnedAt: string, record: IModelOrderItem) => {
      const { useModalProps } = useModal()
      const { orderStatus } = record
      const { orderItem, orderItems } = getMergedOrderItems({
        mergeType,
        orderItem: record,
        orderItemGroupMapRef,
      })
      const hasReturned = [ORDER_STATUS_TYPE.RETURN_UNDER_RETURN, ORDER_STATUS_TYPE.EXCHANGE_UNDER_RETURN].includes(
        orderStatus
      )
      const children = !hasReturned ? (
        utilData.dateTimeFormatText(returnReturnedAt)
      ) : (
        <>
          <MTextButton
            onClick={() => {
              if (!utilData.hasData(record.returnInfo)) {
                message.warn('주문자의 환불 정보가 등록되어 있지 않습니다.')
                return
              }

              useModalProps.setActionParams({
                returnInfo: record.returnInfo,
                orderItems: orderItems || [orderItem],
                user: record.order.user,
                isExchange,
                refetchRef,
                statusFilterRefetchRef,
              })
              useModalProps.showModal()
            }}
          >
            {`[${text}완료]`}
          </MTextButton>
          <ModalConfirmReturnOrder useModalProps={useModalProps} />
        </>
      )

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const returnReason = ({ dataIndex, title = '반품 사유' }: { dataIndex?: string | string[]; title?: string } = {}) => {
  return {
    title,
    dataIndex,
    key: 'returnReason',
    width: 100,
    render: (record: IModelOrderItem) => {
      if (!utilData.hasData(record.returnInfo)) {
        return '-'
      }

      const { requestMessage } = record.returnInfo
      const { useModalProps } = useModal()
      const images = record.returnInfo.returnInfoReturnInfoImages
      const children = (
        <>
          <Text14Normal>{`${record.returnResponsibilityText}(${requestMessage || '-'})`}</Text14Normal>
          {images && images.length > 0 ? (
            <>
              <MTextButton
                onClick={() => {
                  useModalProps.setData({
                    title: '첨부사진',
                    description: `총 ${images.length}장의 사진이 있습니다.`,
                    images: images.map(({ image }) => `${S3_HOST_URL}/${image}`),
                  })
                  useModalProps.toggleModal()
                }}
              >
                [첨부사진]
              </MTextButton>
              <ModalPhoto useModalProps={useModalProps} />
            </>
          ) : null}
        </>
      )

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const orderItemShippingFee = () => {
  return {
    title: '배송비',
    key: 'shippingFee',
    dataIndex: 'orderStatus',
    width: 120,
    render: (orderStatus: string, record: IModelOrderItem) => {
      if (orderStatus === 'RETURN_RETURNED') return `반품 배송비: ${record.returnReturnedAt}`
      const children = <Text14Normal>{utilData.currencyText(record.shipping.amount)}</Text14Normal>
      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

const navToOrderItemList = ({ fixed = 'left' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '상세',
    width: 60,
    fixed,
    render: (record: IModelOrderItem) => {
      return (
        <Link
          href={{
            pathname: record.routeByOrderStatus,
            query: { orderNumber: record.order.orderNumber },
          }}
        >
          <a target="_blank">[이동]</a>
        </Link>
      )
    },
  }
}

const productMainImage = ({ title = '이미지', fixed = false, width = 70 } = {}): ITableColumn<any> => {
  return {
    title,
    width,
    fixed,
    render: (record: IModelOrderItem) => {
      const product = record?.productOption?.product as IModelProduct
      if (product) {
        return (
          <div>
            <a href={`${process.env.FRONT_WEB_DOMAIN}/products/${product._id}`} rel="noreferrer" target="_blank">
              <Image preview={false} src={product.mainImage?.thumbnailUrl} width={50} height={50} />
            </a>
          </div>
        )
      }
      return <Text14Normal>-</Text14Normal>
    },
  }
}

const checkVBankPaid = ({ refetchRef }: { refetchRef?: MutableRefObject<Function | null> }) => {
  return {
    title: '입금 확인',
    width: 140,
    render: (record: IModelOrderItem) => {
      const transactionGid = record?.approvedTransaction?.id
      const orderId = record?.order?._id
      if (!transactionGid) {
        return null
      }

      const { useModalProps: successModalProps } = useModal()
      const { useModalProps: failModalProps } = useModal()

      const children = (
        <MMutation
          gqlKey="checkUnconfirmedTransaction"
          onAPISuccess={(result) => {
            if (result?.isSuccess) {
              successModalProps.showModal()
            } else {
              failModalProps.showModal()
            }
          }}
        >
          {(mutation: MutationFunction) => {
            return (
              <>
                <Text14Blue
                  onClick={async () => {
                    await mutation({
                      variables: { id: transactionGid },
                    })
                  }}
                >
                  [입금 확인]
                </Text14Blue>
                <MModal
                  useModalProps={successModalProps}
                  ComponentContent={<div>입금이 확인 되었습니다. 주문건이 [신규주문/출고관리] 메뉴로 이동됩니다.</div>}
                  footerType="MODAL_FOOTER_OK"
                  onAction={() => {
                    window.open(`${ROUTES.ORDERS.BASE}/${orderId}`)
                    if (refetchRef && refetchRef.current) {
                      refetchRef.current()
                    }
                  }}
                />
                <MModal
                  useModalProps={failModalProps}
                  ComponentContent={<div>입금이 확인 되지 않습니다. 나중에 다시 확인해 주세요.</div>}
                  footerType="MODAL_FOOTER_OK"
                />
              </>
            )
          }}
        </MMutation>
      )

      return makeMergeRow({ children, rowSize: record.rowSize })
    },
  }
}

export {
  orderItemNumber, // 상품 주문 번호
  exchangedOrderItemNumber, // 교환 상품 주문 번호
  formItemSelectShippingCompany, // 배송 회사 설정
  formItemShippingNumber,
  totalCouponDiscount, // 전체 주문 쿠폰 할인금액 + 상품의 쿠폰 할인금액
  totalOrderItemCouponDiscount, // 하나의 상품에 적용된 주문 쿠폰 할인금액 + 상품 쿠폰의 할인금액
  couponDiscount,
  shippingCouponDiscount,
  orderCouponDiscount,
  brandName,
  productBrand,
  returnStatus,
  returnRequestedAt,
  acceptReturnButton,
  returnedReturnButton,
  rejectReturnButton,
  refundShippingFee, // 환불 배송비
  returnShippingFee, // 반품 수수료
  returnAmountWithFee,
  mileageDiscount,
  amount,
  returnReason,
  manualCancelForNotShipped, // 취소건 수동 환불
  manualCancelForReturned, // 반품건 수동 환불
  refundAccount, // 환불계좌정보
  orderItemShippingFee, // 배송비
  navToOrderItemList,
  productMainImage,
  checkVBankPaid, // 가장계좌 입금확인
}
