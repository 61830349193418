import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const PRODUCT_OPTION_QUERY_GQLS = {
  PRODUCT_OPTIONS: gql`
    query productOptions($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      productOptions(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            id
            series
            product {
              id
              name
              productNumber
            }
            name
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default PRODUCT_OPTION_QUERY_GQLS
