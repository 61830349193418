import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const PUSH_MUTATION_GQLS = {
  SEND_PUSH: gql`
    mutation sendPushDefault($input: SendPushInput!) {
      sendPush(input: $input) {
        informPushQueue {
          id
        }
      }
    }
  `,
  UPDATE_PUSH: gql`
    mutation updatePushDefault($id: ID!, $input: SendPushInput!) {
      updatePush(id: $id, input: $input) {
        informPushQueue {
          ...informPushQueue
          push {
            ...push
          }
          users {
            ...userConnection
          }
          fitpetAdmin {
            ...user
          }
          grades {
            ...customerGradeConnection
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.informPushQueue}
    ${DEFAULT_FRAGMENTS.push}
  `,
  DELETE_PUSH: gql`
    mutation deletePushDefault($id: ID!) {
      deletePush(id: $id) {
        informPushQueue {
          ...informPushQueue
        }
      }
    }
    ${DEFAULT_FRAGMENTS.informPushQueue}
  `,
}

export default PUSH_MUTATION_GQLS
