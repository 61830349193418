import { IModelInitDataDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelInitDataDefault } from 'containers/models/base/defaultModels'
import { IModelBank } from 'containers/models/modelBank'
import { IModelShippingCompany } from 'containers/models/modelShippingCompany'
import { IModelCustomerGrade } from 'containers/models/modelCustomerGrade'
import { hasData } from '@libs/utils/utilData'

export type optionType = { value: string; title: string }

export interface IModelInitData extends IModelInitDataDefault {
  customerGradeSelectOptions: optionType[] | []
  defaultCustomerGradeSelectOptionValue: string
  shippingCompanySelectOptions: optionType[] | []
  bankSelectOptions: optionType[] | []
  customerGrades: IModelCustomerGrade[]
  getSweetTrackerCode: (shippingCompanyId?: string) => string
  getShippingCompanyName: (shippingCompanyId?: string) => string
}

export default class ModelInitData extends ModelInitDataDefault {
  customerGradeSelectOptions: optionType[] | [] = []
  defaultCustomerGradeSelectOptionValue: string | undefined = ''
  shippingCompanySelectOptions: optionType[] | [] = []
  bankSelectOptions: optionType[] | [] = []

  constructor(data: IModelInitData) {
    super(data)

    this.customerGradeSelectOptions = this.setCustomerGradeSelectOptions()
    this.defaultCustomerGradeSelectOptionValue = this.setDefaultCustomerGradeSelectOptionValue()
    this.shippingCompanySelectOptions = this.setShippingCompanySelectOptions()
    this.bankSelectOptions = this.setBankSelectOptions()
  }

  private setCustomerGradeSelectOptions = () => {
    if (this.customerGrades) {
      return this.customerGrades.map((x) => {
        return { value: x.id, title: x.name }
      })
    }
    return []
  }

  private setDefaultCustomerGradeSelectOptionValue = () => {
    if (this.customerGrades && hasData(this.customerGrades)) {
      return this.customerGrades[0].id
    }
  }

  private setShippingCompanySelectOptions = () => {
    if (this.shippingCompanies) {
      return this.shippingCompanies.map((x: IModelShippingCompany) => {
        return { value: x.id, title: x.name }
      })
    }
    return []
  }

  private setBankSelectOptions = () => {
    if (this.banks) {
      return this.banks.map((x: IModelBank) => {
        return { value: x.code, title: x.name }
      })
    }
    return []
  }

  getSweetTrackerCode = (shippingCompanyId?: string): string => {
    if (shippingCompanyId && this.shippingCompanies) {
      const shippingCompany = this.shippingCompanies.find((x) => x.id === shippingCompanyId)
      if (shippingCompany) {
        return String(shippingCompany.sweetTrackerCode).padStart(2, '0')
      }
    }
    return '00'
  }

  getShippingCompanyName = (shippingCompanyId?: string): string => {
    if (shippingCompanyId && this.shippingCompanies) {
      const shippingCompany = this.shippingCompanies.find((x) => x.id === shippingCompanyId)
      if (shippingCompany) {
        return shippingCompany.name
      }
    }
    return ''
  }
}
