import _ from 'lodash'
import { FunctionComponent, useState } from 'react'
import { Col, Row, Select } from 'antd'
import { MFormItemWrapper } from 'components/formItems'
import * as utilDate from '@libs/utils/utilDate'
import { FIELD_KEYS } from '@constants/constData'

const FORM_ITEM_PROPS_YEAR = {
  name: [FIELD_KEYS.FIELD_DATE, FIELD_KEYS.FIELD_YEAR],
  rules: [],
}

const FORM_ITEM_PROPS_MONTH = {
  name: [FIELD_KEYS.FIELD_DATE, FIELD_KEYS.FIELD_MONTH],
  rules: [],
}

const FORM_ITEM_PROPS_DAY = {
  name: [FIELD_KEYS.FIELD_DATE, FIELD_KEYS.FIELD_DAY],
  rules: [],
}

const getRangeNumbers = (startNumber: number, endNumber: number) => {
  const rangeNumbers: string[] = []
  for (let idx = 0; idx <= endNumber - startNumber; idx += 1) {
    const number = startNumber + idx
    let _number = '00'
    if (number < 10) {
      _number = `0${number}`
    } else {
      _number = number.toString()
    }
    rangeNumbers.push(_number)
  }
  return rangeNumbers
}

export type MFormItemSelectDateProps = {
  label: string
  startYear?: number
  endYear?: number
  defaultYear?: string
  defaultMonth?: string
  yearOrder?: 'ASC' | 'DESC'
}

const MFormItemSelectDate: FunctionComponent<MFormItemSelectDateProps> = ({
  label,
  startYear = 1907,
  endYear = 2007,
  defaultYear = '1960',
  defaultMonth = '1',
  yearOrder = 'DESC',
}) => {
  const [selectYear, setSelectYear] = useState(defaultYear)
  const [selectMonth, setSelectMonth] = useState(defaultMonth)
  const yearRangeNumbers =
    yearOrder === 'DESC' ? _.reverse(getRangeNumbers(startYear, endYear)) : getRangeNumbers(startYear, endYear)
  const monthRangeNumbers = getRangeNumbers(1, 12)
  const dayRangeNumbers = getRangeNumbers(1, utilDate.getDaysInMonth(selectYear, selectMonth))
  return (
    <MFormItemWrapper label={label}>
      <Row gutter={[10, 10]}>
        <Col span={10}>
          <MFormItemWrapper {...FORM_ITEM_PROPS_YEAR}>
            <Select
              placeholder="년"
              optionFilterProp="children"
              value={selectYear}
              onChange={(value) => {
                setSelectYear(value)
              }}
              virtual={false}
            >
              {yearRangeNumbers.map((number: string) => {
                return (
                  <Select.Option key={number} value={number}>
                    {number}
                  </Select.Option>
                )
              })}
            </Select>
          </MFormItemWrapper>
        </Col>
        <Col span={7}>
          <MFormItemWrapper {...FORM_ITEM_PROPS_MONTH}>
            <Select
              placeholder="월"
              optionFilterProp="children"
              value={selectMonth}
              onChange={(value) => {
                setSelectMonth(value)
              }}
              virtual={false}
            >
              {monthRangeNumbers.map((number: string) => {
                return (
                  <Select.Option key={number} value={number}>
                    {number}
                  </Select.Option>
                )
              })}
            </Select>
          </MFormItemWrapper>
        </Col>
        <Col span={7}>
          <MFormItemWrapper {...FORM_ITEM_PROPS_DAY}>
            <Select
              // showSearch
              placeholder="일"
              optionFilterProp="children"
              virtual={false}
            >
              {dayRangeNumbers.map((number: string) => {
                return (
                  <Select.Option key={number} value={number}>
                    {number}
                  </Select.Option>
                )
              })}
            </Select>
          </MFormItemWrapper>
        </Col>
      </Row>
    </MFormItemWrapper>
  )
}

export default MFormItemSelectDate
