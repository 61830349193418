import { FunctionComponent, ReactNode } from 'react'
import useMQuery from 'containers/hooks/useMQuery'
import { MError, MLoader } from 'components/index'
import { DataResult, DataResults } from 'containers/gqls/data'
import * as utilApi from '@libs/utils/utilApi'
import { ApolloQueryResult } from '@apollo/client'
import { onCompletedType } from 'containers/hooks/useMMutation'
import { pageInfoInputProps } from 'containers/gqls/query_variables'
import { QUERY_KEY2GQLS } from 'containers/gqls'

export type listVariablesProps = {
  filter?: object
  orderBy?: string
  pageInfo?: pageInfoInputProps
  seller?: boolean
}

export type roleProps = { seller: string }
type idProps = { id: string }

type variableProps = idProps

export type otherProps = { platform: string }

export type queryVariables = listVariablesProps | variableProps | roleProps | otherProps

export type queryParamsProps = {
  variables?: queryVariables
  dataKey?: string
  gqlKey: keyof typeof QUERY_KEY2GQLS
}

type queryChildrenProps = {
  data: { [index: string]: DataResult | DataResults }
  refetch: (variables?: Partial<any> | undefined) => Promise<ApolloQueryResult<any>>
}

export type MQueryProps = {
  queryParams: queryParamsProps
  onAPISuccess?: onCompletedType
  children?: ({ data, refetch }: queryChildrenProps) => ReactNode
  loader?: boolean
  fetchPolicy?: 'cache-first' | 'network-only' | 'cache-only' | 'no-cache' | 'standby'
}

export type MQueryDataConnection<T> = {
  data: T[] | T
  totalCount: number
}

export type MQueryData<QueryName extends string, T> = {
  data: {
    [key in QueryName]: MQueryDataConnection<T>
  }
  refetch?: Function
}

const MQuery: FunctionComponent<MQueryProps> = ({ queryParams, children, loader, fetchPolicy, onAPISuccess }) => {
  // const loaderFull = false
  const displayError = true
  const displayLoader = true
  const { gqlKey, variables } = queryParams
  const option = { fetchPolicy }
  const { loading, error, data, refetch } = useMQuery(gqlKey, variables, onAPISuccess, option)

  const hasDataWithoutError = utilApi.maybe(() => !!data && !!Object.keys(data).length, false)
  if (displayError && error && hasDataWithoutError) return <MError error={error.message} />
  if (displayLoader && loading && hasDataWithoutError) return <MLoader />
  if (!data) return loader ? <MLoader /> : null

  return <>{children && children({ data, refetch })}</>
  // const res = data[_dataKey]
  // if ('totalCount' in res) {
  //   return <>{children({ data: res.data, totalCount: res.totalCount, refetch })}</>
  // }
  // return <>{children({ data: res.data, refetch })}</>
}

export default MQuery
