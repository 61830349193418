import { Row, Col } from 'antd'
import { LockOutlined } from '@ant-design/icons'

import { MTextButton, Text14Normal, Text14Red } from 'components/@atomics'
import { ITableColumn, ITableColumnFixedProps } from 'components/@atomics/MTable'
import { IModelProductQna } from 'containers/models/modelProductQna'
import { IModelServiceQna } from 'containers/models/modelServiceQna'
import { IModelReview } from 'containers/models/modelReview'
import useModal from 'containers/hooks/useModal'
import { ModalPhoto } from 'domains/common/modals'
import { SERVICE_QNA_CATEGORY_TEXT } from '@constants/constDomain'
import { ConditionalWrapper, getFullUrl } from '@libs/utils/utilData'

type CurrentRecord = IModelProductQna | IModelReview | IModelServiceQna

const questionBody = ({
  dataIndex = 'questionBody',
  fixed = 'left',
  setCurrentData,
}: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '문의 내용',
    dataIndex,
    key: 'questionBody',
    width: 200,
    ellipsis: true,
    fixed,
    render: (_: string, record: CurrentRecord) => {
      let qna: any
      if (dataIndex === 'productQna') {
        qna = record as IModelProductQna
      }
      if (dataIndex === 'orderQna') {
        qna = record as IModelServiceQna
      }
      if (dataIndex === 'serviceQna') {
        qna = record as IModelServiceQna
      }

      return (
        <Row gutter={[12, 0]} wrap={false} align="middle">
          {record.isSecret && (
            <Col>
              <LockOutlined />
            </Col>
          )}
          <Col style={{ overflow: 'hidden' }}>
            <MTextButton style={{ alignItem: 'middle' }} onClick={() => setCurrentData!(record)} ellipsis>
              {qna?.questionBody || _}
            </MTextButton>
          </Col>
        </Row>
      )
    },
  }
}

const isAnswered = ({ dataIndex = 'isAnswered' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '처리상태',
    dataIndex,
    fixed: true,
    width: 100,
    render: (_: boolean) => {
      return (
        <ConditionalWrapper
          condition={_}
          wrapper={[<Text14Normal />, <Text14Red />]}
          children={_ ? '답변완료' : '미답변'}
        />
      )
    },
  }
}

const category = ({ dataIndex = 'category' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '문의유형',
    dataIndex,
    width: 140,
    render: (_: string) => {
      const SERVICE_QNA_CATEGORY_KEY = _ as keyof typeof SERVICE_QNA_CATEGORY_TEXT
      return <Text14Normal>{SERVICE_QNA_CATEGORY_TEXT[SERVICE_QNA_CATEGORY_KEY]}</Text14Normal>
    },
  }
}

const photo = ({ dataIndex = 'serviceQnaImage', fixed = 'left' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '사진',
    dataIndex,
    key: 'serviceQnaImage',
    width: 120,
    fixed,
    render: (_: string, record: IModelServiceQna) => {
      let images: any[]
      images = (record as IModelServiceQna)?.serviceQnaImages
      images = images?.map((image) => getFullUrl(image.image))
      const { useModalProps } = useModal()
      return (
        <>
          <MTextButton
            disable={images?.length === 0}
            onClick={() => {
              useModalProps.setData({ title: '첨부사진', images })
              useModalProps.toggleModal()
            }}
          >
            {images?.length > 0 ? '[보기]' : '-'}
          </MTextButton>
          <ModalPhoto useModalProps={useModalProps} />
        </>
      )
    },
  }
}

export {
  questionBody, // 문의 내용
  isAnswered, // 처리상태
  category, // 문의유형
  photo, // 이미지
}
