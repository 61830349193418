import { gql } from '@apollo/client'

export const CACHE_MUTATION_GQLS = {
  CLEAR_HOME_CACHE: gql`
    mutation clearHomeCache($id: ID) {
      clearHomeCache(id: $id) {
        result
      }
    }
  `,
  CLEAR_BANNER_CACHE: gql`
    mutation clearBannerCache($id: ID) {
      clearBannerCache(id: $id) {
        result
      }
    }
  `,
  CLEAR_BEST_CACHE: gql`
    mutation clearBestCache($id: ID) {
      clearBestCache(id: $id) {
        result
      }
    }
  `,
}

export default CACHE_MUTATION_GQLS
