import { IModelBannerImageDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelBannerImageDefault } from 'containers/models/base/defaultModels'

export interface IModelBannerImage extends IModelBannerImageDefault {}

export default class ModelBannerImage extends ModelBannerImageDefault {
  constructor(data: IModelBannerImage) {
    super(data)
  }
}
