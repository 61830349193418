import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import { Key, ExpandableConfig } from 'antd/lib/table/interface'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { useSelector } from 'react-redux'
import { RootState } from '@libs/redux/store'

type Fixed = boolean | 'left' | 'right'

type tableRenderProps<T> =
  | ((text: any) => JSX.Element)
  | ((record: T) => JSX.Element)
  | ((text: any, record: T) => JSX.Element)
  | ((text: any, record: T, index: number) => JSX.Element)
  | ((text: any, record: T) => any)
// fix for render children -> refer to TableColsOrder.tsx

export interface ITableColumn<T> {
  title?: string
  key?: string | string[]
  dataIndex?: string | string[]
  render?: tableRenderProps<T>
  width?: string | number
  ellipsis?: boolean
  fixed?: Fixed
  element?: JSX.Element
  type?: string
  sort?: boolean
  hasLink?: boolean
  sorter?: Function | null
  sortDirections?: Array<any> | null
  showSorterTooltip?: boolean
  children?: ITableColumn<T>[]
  suffix?: string
  align?: 'left' | 'center' | 'right'
  link?: boolean
}

export interface ITableDateColumn<T> extends ITableColumn<T> {
  dateFormat?: string
}

export interface ITableColumnTitleDataIndexKeyProps {
  title: string
  dataIndex: string | string[]
  key: string
  fixed?: Fixed
  width?: number
  suffix?: string
}

export interface ITableColumnTitleDataIndexProps {
  title: string
  dataIndex: string | string[]
  fixed?: Fixed
  element?: JSX.Element
  type?: string
  sort?: boolean
  width?: number
  option?: { [index: string]: string | boolean }
}

export interface ITableColumnCountTextProps extends ITableColumnTitleDataIndexProps {
  countType: string
}

export interface ITableColumnFixedOnlyProps {
  fixed?: Fixed
  query?: any
}

export interface ITableColumnTitleProps {
  title: string
  element?: JSX.Element
  fixed?: Fixed
  type?: string
  width?: string | number
}

export interface ITableColumnDateAtProps extends ITableColumnTitleProps {
  dateFormat?: string
}

export interface ITableColumnFixedProps {
  dataIndex?: string
  fixed?: Fixed
  key?: string
  setCurrentData?: Function
}

export interface ITableColumnFixedHasLinkProps {
  title?: string
  dataIndex?: string | string[]
  fixed?: Fixed
  key?: string
  hasLink?: boolean
  openNewWindow?: boolean
  query?: any
  width?: string | number
}

export type MTableProps = {
  rowSelectionType?: 'checkbox' | 'radio'
  rowSelectionHideSelectAll?: boolean
  columns: ITableColumn<any>[]
  dataSource?: any
  pagination?: false | TablePaginationConfig
  scroll?: number
  onRowSelect?: Function
  rowIndex?: string
  size?: SizeType
  bordered?: boolean
  multipleHeaderRow?: boolean
  dragRowKey?: string
  onDragRowAction?: (newData: any, movedData: any) => void
  expandable?: ExpandableConfig<any>
  className?: string
  tableId?: string
}

const getRowKey = (data: any, rowIndex?: string) => {
  if (!rowIndex) return data.id
  return data[rowIndex]?.id
}

const MTable = ({
  rowSelectionType,
  columns,
  dataSource,
  pagination,
  scroll,
  onRowSelect,
  rowIndex,
  size = 'middle',
  bordered,
  multipleHeaderRow,
  onDragRowAction,
  expandable,
  className,
  tableId = 'mtable',
}: MTableProps) => {
  const [rowKeys, setRowKeys] = useState<Key[]>()
  const initRowSelection = useSelector((state: RootState) => state.reduxUIReducers.initRowSelection)

  useEffect(() => {
    setRowKeys(undefined)
  }, [initRowSelection])

  useEffect((): void => {
    if (rowKeys) {
      const filteredSelectedRows = dataSource.filter((data: any) => {
        return rowKeys.includes(getRowKey(data, rowIndex))
      })
      onRowSelect!(filteredSelectedRows)
    }
  }, [rowKeys])

  const DraggableContainer = (props: any) => {
    return (
      <StyledSortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
          if (oldIndex !== newIndex) {
            onDragRowAction!(dataSource[oldIndex], dataSource[newIndex])
          }
        }}
        {...props}
      />
    )
  }

  const DraggableBodyRow = ({ ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource?.findIndex((x: { id: string; key: string }) => {
      if (x.id === restProps['data-row-key'] || x.key === restProps['data-row-key']) {
        return x
      }
      return null
    })
    return <StyledSortableElement index={index} {...restProps} />
  }
  // console.log('!', dataSource)

  return (
    <StyledTable
      multipleHeaderRow={multipleHeaderRow}
      tableId={tableId}
      rowSelection={
        rowSelectionType && {
          type: rowSelectionType,
          selectedRowKeys: rowKeys,
          onChange: (selectedRowKeys: Key[]) => {
            setRowKeys(selectedRowKeys)
          },
        }
      }
      size={size}
      bordered={bordered}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      rowKey={(row: any) => getRowKey(row, rowIndex) || row.key}
      scroll={{ x: scroll }}
      components={onDragRowAction && { body: { wrapper: DraggableContainer, row: DraggableBodyRow } }}
      expandable={expandable ?? {}}
      className={className}
    />
  )
}

const StyledTable = styled(({ multipleHeaderRow, tableId, ...rest }) => {
  return <Table id={tableId} multipleHeaderRow={multipleHeaderRow} {...rest} />
})<MTableProps>`
  th {
    vertical-align: ${(props) => props.multipleHeaderRow && 'middle'};
  }

  .nestedTable > div > div > div {
    margin: 10px 10px 4px 10px !important;
  }

  .ant-table-content {
    max-height: 80vh;
    overflow-y: scroll !important;
  }
`

const StyledSortableElement = styled(SortableElement((props: any) => <tr {...props} />))``

const StyledSortableContainer = styled(SortableContainer((props: any) => <tbody {...props} />))`
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
    visibility: hidden;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
`

export default MTable
