import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'
import USER_FRAGMENTS from 'containers/gqls/accounts/user/fragments'

const USER_QUERY_GQLS = {
  USER_LIST_TABLE: gql`
    query userListTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      users(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            id
            username
            email
            name
            mobileNumber
            mileagePoint
            orderCount
            orderAmount
            lastLogin
            dateJoined
            userStatusText
            userStatus
            userSocialServices {
              edges {
                node {
                  id
                  socialType
                }
              }
            }
            grade {
              id
              name
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  USER_LIST_FOR_MANAGER: gql`
    query userListForManager($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      users(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            id
            username
            email
            name
            mobileNumber
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  INACTIVATED_USER_LIST_TABLE: gql`
    query inactivatedUserListTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      users(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            id
            username
            email
            name
            inactivatedAt
            userStatusText
            userStatus
            grade {
              id
              name
            }
            inactiveuser {
              username
              email
              name
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  LEFT_USER_LIST_TABLE: gql`
    query leftUserListTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      users(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            id
            username
            email
            name
            leftAt
            leftReason
            leftMessage
            userStatusText
            userStatus
            grade {
              id
              name
            }
            inactiveuser {
              id
              username
              email
              name
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  USERS: gql`
    query users($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      users(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...user
            grade {
              id
              name
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  USERS_BY_TRANSFER_TABLE: gql`
    query usersByTransferTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      users(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            id
            username
            email
            mobileNumber
            name
            agreeMarketing
            grade {
              id
              name
            }
          }
        }
      }
    }
  `,
  USER_DETAIL: gql`
    query userDetail($id: ID!) {
      user(id: $id) {
        ...formUser
        userDevices {
          edges {
            node {
              osVersion
              appVersion
              platformType
              lastLoggedAt
            }
          }
        }
      }
    }
    ${USER_FRAGMENTS.formUser}
  `,
  USER_EMAIL: gql`
    query userEmail($id: ID!) {
      user(id: $id) {
        id
        email
      }
    }
  `,
  PARTNER_LIST_TABLE: gql`
    query partnerListTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      users(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            id
            username
            email
            name
            mobileNumber
            dateJoined
            isSeller
            isStaff
            sellerCount
            approvalSellerCount
            userSocialServices {
              edges {
                node {
                  id
                  socialType
                }
              }
            }
            sellers {
              edges {
                node {
                  id
                  shopName
                }
              }
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
}

export default USER_QUERY_GQLS
