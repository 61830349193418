import { FC, MutableRefObject, useState } from 'react'
import { Row, Col, Typography, Divider } from 'antd'

import { ModalConfirmInput } from 'domains/common/modals/index'
import { DomainModalProps } from 'containers/hooks/useModal'
import { MCheckboxSingle, Text14Normal, Text16Normal } from 'components/@atomics/index'
import { MConfirmModalProps } from 'components/modals/MConfirmModal'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { TableOrderItemReturn } from 'domains/common/tables/index'
import { MFormItemTextArea } from 'components/formItems/index'
import * as utilData from '@libs/utils/utilData'

const { Paragraph } = Typography

type ActionParamsProps = {
  orderItems: IModelOrderItem[]
  isExchange?: boolean
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}

const ModalRejectReturnOrder: FC<DomainModalProps<MConfirmModalProps, undefined>> = ({ useModalProps }) => {
  if (!useModalProps.actionParams) return null
  const [hasUserAgreed, setHasUserAgreed] = useState<boolean>(false)
  const {
    orderItems,
    isExchange = false,
    refetchRef,
    statusFilterRefetchRef,
  }: ActionParamsProps = useModalProps.actionParams

  if (!utilData.hasData(orderItems)) return null
  const gqlKey = isExchange ? 'rejectExchangeReturnInfos' : 'rejectReturnInfos'
  const variableKey = isExchange ? 'rejectExchangeReturnInfosRequest' : 'rejectReturnInfosRequest'
  const text = isExchange ? '교환' : '반품'

  const RenderContent = () => {
    return (
      <div style={{ marginTop: -20 }}>
        <Row>
          <Col>
            <Paragraph>
              <ul>
                <li>
                  <Text14Normal>{`고객의 요청 또는 재화의 가치가 현저히 손상된 경우, 판매자는 ${text} 진행을 철회할 수 있습니다.`}</Text14Normal>
                </li>
                <li>
                  <Row>
                    <Col>
                      <Text14Normal bold>{`${text} 철회 확정시 `}</Text14Normal>
                      <Text14Normal>주문 상태는</Text14Normal>
                      <Text14Normal bold>{' "구매확정" 상태로 변경 '}</Text14Normal>
                      <Text14Normal>되는 점 고객에게 안내해주시면 감사하겠습니다.</Text14Normal>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col>
                      <Text14Normal
                        bold
                      >{`${text} 철회를 하기 위해서는 반드시 구매 회원의 동의를 사전에 구해야 합니다.`}</Text14Normal>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Text14Normal>{`동의 없이 임의로 ${text} 철회한 경우 핏펫몰 이용약관에 따라 강제 퇴점 조치가 진행될 수 있습니다.`}</Text14Normal>
                </li>
              </ul>
            </Paragraph>
          </Col>
        </Row>

        <Divider />
        <TableOrderItemReturn orderItems={orderItems} />
        <Divider />
        <Text16Normal bold>{`${text} 철회 사유 입력`}</Text16Normal>
        <MFormItemTextArea
          name="message"
          textAreaProps={{
            maxLength: 140,
            style: { minHeight: '120px', verticalAlign: 'middle' },
          }}
        />
        <Divider />
        <Row gutter={[8, 8]}>
          <Col>
            <Text16Normal bold>구매 고객에게 동의를 받으셨나요?</Text16Normal>
          </Col>
        </Row>
        <Row>
          <Col>
            <MCheckboxSingle text="네, 안내하였습니다." onChange={setHasUserAgreed} checked={hasUserAgreed} />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col>
            <Text14Normal>* 확인 버튼 클릭시 구매확정 상태로 변경합니다.</Text14Normal>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <ModalConfirmInput
      onAPISuccess={() => {
        if (refetchRef && refetchRef.current) {
          refetchRef.current()
        }
        if (statusFilterRefetchRef && statusFilterRefetchRef.current) {
          statusFilterRefetchRef.current()
        }
      }}
      useModalProps={{ width: 800, maskClosable: false, ...useModalProps }}
      fixedProps={{
        mutationKey: {
          gqlKey,
          variableKey,
        },
        title: `${text} 철회`,
        okButtonDisable: !hasUserAgreed,
        description: '',
        disableContent: '철회 사유를 입력하시고, 확인 버튼 클릭시 구매확정 상태로 변경됩니다.',
        componentOption: {
          Component: <RenderContent />,
        },
        // inputOption: {
        //   name: 'requestMessage',
        //   type: 'input',
        //   label: '철회 사유',
        //   placeholder: '철회 사유를 입력해 주세요.',
        // },
      }}
    />
  )
}

export default ModalRejectReturnOrder
