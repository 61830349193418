import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const AD_QUERY_GQLS = {
  ADS: gql`
    query ads($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      ads(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            id
            isShown
            startedAt
            endedAt
            name
            description
            slug
            startedAt
            endedAt
            adType
            petType
            isFinished
            displayOrder
            adDisplayCollections {
              edges {
                node {
                  id
                  displayProductInfos {
                    totalCount
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  AD: gql`
    query ad($id: ID!) {
      ad(id: $id) {
        id
        displayOrder
        startedAt
        endedAt
        petType
        name
        description
        adType
        isShown
        style
        isFinished
        slug
        adImages {
          pageInfo {
            ...pageInfo
          }
          edges {
            node {
              ...adImage
            }
          }
        }
        mainImage {
          ...adImage
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.adImage}
  `,
}

export default AD_QUERY_GQLS
