import React, { MutableRefObject, useRef } from 'react'
import MMainTable from 'components/MMainTable'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelSellerBilling } from 'containers/models/modelSellerBilling'
import tableCols from 'domains/admin/tableCols'
import { queryParamsProps } from 'components/MQuery'
import { FIELD_KEYS, FIELD_SORT_KEYS, FIELD_VALUES } from '@constants/constData'
import { ButtonRequestExcel, ButtonRouteToExcelDownloadList } from 'domains/common/tableButtons'
import { CALENDAR_PICKER_TYPE } from 'domains/common/modals/ModalRequestExcelFile'

const tableColumns = (refetchRef: MutableRefObject<Function | null>): ITableColumn<IModelSellerBilling>[] => {
  return [
    tableCols.billing.targetMonth(),
    tableCols.date.dateText({ title: FIELD_VALUES.FIELD_BILLED_AT, dataIndex: 'nextBillingAt', key: 'nextBillingAt' }),
    tableCols.common.baseTextFromModel({ title: '셀러', dataIndex: 'seller', key: 'shopName' }),
    tableCols.currency.currencyText({
      title: `총 판매 금액 (A)`,
      dataIndex: 'customerAmount',
    }),
    tableCols.currency.currencyText({
      title: '판매 수수료 (B)',
      dataIndex: 'fitpetAmount',
    }),
    tableCols.currency.currencyText({
      title: '판매 정산 금액 (C=A-B)',
      dataIndex: 'sellerAmount',
    }),
    tableCols.currency.currencyText({
      title: '배송비 정산 금액 (D)',
      dataIndex: 'shippingBillingAmount',
    }),
    tableCols.currency.currencyText({
      title: '반품수수료 정산금액 (E)',
      dataIndex: 'customerReturnFees',
    }),
    tableCols.currency.currencyText({
      title: '정산 대상금액 (F=C+D+E)',
      dataIndex: 'sellerTotalAmount',
    }),
    tableCols.billing.confirmBilling({ refetchRef, width: 200 }),
    tableCols.billing.downloadBillingItemExcel({ title: '엑셀 다운', fixed: 'right' }),
  ]
}

const MainTableSellerBillingList = () => {
  const refetchRef: MutableRefObject<Function | null> = useRef(null)

  const queryParams: queryParamsProps = {
    gqlKey: 'sellerBillingsList',
    dataKey: 'sellerBillings',
    variables: { orderBy: FIELD_SORT_KEYS.FIELD_SORT_BILLED_AT_DESC },
  }

  return (
    <MMainTable
      TitleSet={{ title: '정산 목록', unit: '개' }}
      columns={tableColumns(refetchRef)}
      queryParams={queryParams}
      refetchRef={refetchRef}
      HeaderRightButtons={[
        <ButtonRequestExcel
          title="정산내역 엑셀 파일 요청"
          requestButtonText="전체 위탁 정산내역 엑셀 다운"
          requestExcelGqlKey="requestAdminBillingItemExcelFile"
          isFilterRange
          defaultRowCount={100000}
          rangeFieldName={FIELD_KEYS.FIELD_SELLER_BILLED_AT}
          filter={{ excludeFitpetSeller: true }}
          picker={CALENDAR_PICKER_TYPE.M}
          isBilling
        />,
        <ButtonRouteToExcelDownloadList />,
      ]}
    />
  )
}

export default MainTableSellerBillingList
