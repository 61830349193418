import { gql } from '@apollo/client'
import USER_FRAGMENTS from 'containers/gqls/accounts/user/fragments'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const NOTICE_FRAGMENTS = {
  get noticeOnly() {
    return gql`
      fragment noticeOnly on NoticeType {
        id
        title
        body
        createdAt
        updatedAt
        isImportant
        receiverType
        user {
          ...userOnly
        }
      }
      ${USER_FRAGMENTS.userOnly}
    `
  },
  get notices() {
    return gql`
      fragment notices on NoticeTypeConnection {
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...noticeOnly
          }
          cursor
        }
        totalCount
      }
      ${this.noticeOnly}
      ${DEFAULT_FRAGMENTS.pageInfo}
    `
  },
}

export default NOTICE_FRAGMENTS
