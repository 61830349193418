import { ModalAddressModification, ModalDaumPostcode } from 'domains/common/modals'
import { MutableRefObject } from 'react'
import * as utilData from '@libs/utils/utilData'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { MTextButton, Text14Normal } from 'components/@atomics'
import useModal from 'containers/hooks/useModal'
import { message } from 'antd'

const recipientName = (): ITableColumn<any> => {
  return {
    title: '수취인명',
    key: 'recipient',
    width: 140,
    render: (record: IModelOrderItem) => {
      return <Text14Normal>{utilData.tableText(record.order.customerAddress?.name)}</Text14Normal>
    },
  }
}

// currently no use
const editSellerAddress = () => {
  return {
    title: '수거지 정보수정',
    key: 'editSellerAddress',
    width: 140,
    render: () => {
      const { useModalProps } = useModal()
      const { useModalProps: useModalDaumPostcodeProps } = useModal()
      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.toggleModal()
              // useModalProps.setData({ addressType: 'sellerAddress', orderItem: record })
            }}
          >
            [수정]
          </MTextButton>
          <ModalAddressModification
            useModalProps={useModalProps}
            useModalDaumPostcodeProps={useModalDaumPostcodeProps}
          />
          <ModalDaumPostcode useModalProps={useModalDaumPostcodeProps} />
        </>
      )
    },
  }
}

const editCustomerAddress = ({
  addressType,
  refetchRef,
}: {
  addressType: string
  refetchRef?: MutableRefObject<Function | null>
}) => {
  const title = addressType === 'customerAddress' ? '배송지 정보수정' : '고객 반품지 정보수정'
  return {
    title,
    key: 'editCustomerAddress',
    width: 140,
    render: (record: IModelOrderItem) => {
      const { useModalProps: useModalDaumPostcodeProps } = useModal()
      const { useModalProps } = useModal()
      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.toggleModal()
              useModalProps.setData({ addressType, orderItem: record })
            }}
          >
            [수정]
          </MTextButton>
          <ModalAddressModification
            useModalProps={useModalProps}
            useModalDaumPostcodeProps={useModalDaumPostcodeProps}
            onAPISuccess={() => {
              if (refetchRef && refetchRef.current) refetchRef.current()
              message.success('정보가 수정되었습니다.')
            }}
          />
          <ModalDaumPostcode useModalProps={useModalDaumPostcodeProps} />
        </>
      )
    },
  }
}

export {
  recipientName, // 수취인명
  editCustomerAddress, // 주소 수정
  editSellerAddress, // 셀러 수거지 주소 수정
}
