import { MFormItemWrapper } from 'components/formItems'
import { Text14Normal, Text14Red } from 'components/@atomics'

const labelFieldStyle = { labelCol: { span: 4 }, wrapperCol: { span: 12 } }

const MLabeledField = ({ label, value, isWarning = false }: { label: string; value: string; isWarning?: boolean }) => {
  return (
    <MFormItemWrapper {...labelFieldStyle} label={label}>
      {isWarning ? <Text14Red>{value}</Text14Red> : <Text14Normal>{value}</Text14Normal>}
    </MFormItemWrapper>
  )
}

export default MLabeledField
