import { FunctionComponent } from 'react'
import { Form } from 'antd'
import styled from 'styled-components'

import { FormItemProps } from 'antd/lib/form/FormItem'
import { MButton } from 'components/@atomics'

const FormSubmit: FunctionComponent<{ defaultButtonWidth?: number }> = ({ defaultButtonWidth }) => {
  return (
    <StyledFormItem>
      <MButton type="primary" width={defaultButtonWidth} onClick={() => console.log('preview')}>
        미리보기
      </MButton>
      <MButton type="primary" width={defaultButtonWidth} onClick={() => console.log('submit!')} htmlType="submit">
        저장하기
      </MButton>
    </StyledFormItem>
  )
}

const StyledFormItem: React.FunctionComponent<FormItemProps> = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }

  .ant-btn-primary {
    margin-right: 26px;
  }
`

export default FormSubmit
