import { FIELD_KEYS } from '@constants/constData'
import { MutableRefObject } from 'react'
import { message } from 'antd'

import * as utilData from '@libs/utils/utilData'
import { IModelStatus } from 'containers/models/modelStatus'
import { IModelProduct } from 'containers/models/modelProduct'
import { MTextButton, Text14Blue, Text14Normal } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import useModal from 'containers/hooks/useModal'
import { ModalConfirm, ModalConfirmInputReject } from 'domains/common/modals'
import { IModelBrand } from 'containers/models/modelBrand'
import { IModelSeller } from 'containers/models/modelSeller'

type ModelKey = 'seller' | 'brand' | 'product' | 'status'
export type statusRequestProps = {
  modelKey: ModelKey
  variableKey: string
  descriptionComponentKey: string
  refetchRef?: MutableRefObject<Function | null>
}

const approveRequestFixedPropsByModelKey: { [index: string]: any } = {
  brand: {
    title: '브랜드 신청 승인',
    description: '아래 브랜드의 승인요청을 승인합니다.',
  },
  product: {
    title: '상품 신청 승인',
    description: '아래 상품의 상품등록을 승인합니다.',
  },
  seller: {
    title: '셀러 신청 승인',
    description: '아래 셀러요청을 승인합니다.',
  },
}

const setConfirmMessage = (titleKey: ModelKey) => {
  let title: '셀러' | '브랜드' | '상품' | '' = ''
  if (titleKey === 'seller') title = '셀러'
  if (titleKey === 'brand') title = '브랜드'
  if (titleKey === 'product') title = '상품'
  return `${title} 신청이 승인되었습니다. 승인된 ${title}는 '${title} 리스트에서 확인할 수 있습니다`
}

const approveRequest = ({ refetchRef, modelKey, variableKey, descriptionComponentKey }: statusRequestProps) => {
  const fixedProps = approveRequestFixedPropsByModelKey[modelKey]
  return {
    key: 'approveRequest',
    title: '승인',
    width: 70,
    render: (record: IModelProduct | IModelBrand | IModelSeller) => {
      const { useModalProps } = useModal()
      if (modelKey === 'brand') {
        const { status } = record
        if (status.value !== 'REQUEST') return <Text14Normal>{status.statusText}</Text14Normal>
      }
      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.toggleModal()
              useModalProps.setData({ [modelKey]: record })
            }}
          >
            <Text14Blue>[승인]</Text14Blue>
          </MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: {
                gqlKey: 'approveRequest',
                variableKey,
              },
              ...fixedProps,
              componentOption: { descriptionComponentKey },
              confirmMessage: setConfirmMessage(modelKey),
            }}
            onAPISuccess={() => {
              if (refetchRef && refetchRef.current) refetchRef.current()
            }}
          />
        </>
      )
    },
  }
}

const suspendApproveRequestFixedPropsByModelKey: { [index: string]: any } = {
  sellerSuspend: {
    title: '셀러 사용 승인',
    description: '해당 셀러 사용을 중지합니다.',
  },
  sellerApprove: {
    title: '셀러 사용 중지 해제',
    description: '해당 셀러 사용중지를 해제합니다.',
  },
}

const suspendApproveRequest = ({ modelKey, variableKey, descriptionComponentKey }: statusRequestProps) => {
  return {
    key: 'suspendRequest',
    title: '사용 중지',
    width: 100,
    render: (record: IModelProduct | IModelSeller) => {
      const isSuspend: boolean = record?.status?.value === 'SUSPEND'
      const messageText: '사용중지 해제' | '사용중지' = isSuspend ? '사용중지 해제' : '사용중지'
      const fixedProps = suspendApproveRequestFixedPropsByModelKey[isSuspend ? 'sellerApprove' : 'sellerSuspend']
      const { useModalProps } = useModal()
      if (record?.status.value === 'REJECTED') return <>-</>
      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.toggleModal()
              useModalProps.setData({ [modelKey]: record.status })
            }}
          >
            <Text14Blue>{isSuspend ? '[해제]' : '[사용 중지]'}</Text14Blue>
          </MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            onAPISuccess={() => message.success(`셀러가 ${messageText} 되었습니다.`)}
            fixedProps={{
              mutationKey: {
                gqlKey: isSuspend ? 'approveRequest' : 'suspendRequest',
                variableKey,
              },
              ...fixedProps,
              componentOption: {
                descriptionComponentKey,
              },
            }}
          />
        </>
      )
    },
  }
}

const rejectRequestInputOption = {
  label: '반려사유',
  placeholder: '반려사유를 입력하세요',
}

const rejectRequestFixedPropsByModelKey: { [index: string]: any } = {
  product: {
    title: '상품 신청 반려',
    description: '아래 상품의 상품등록을 반려합니다.',
    inputOption: rejectRequestInputOption,
  },
  seller: {
    title: '셀러 신청 반려',
    description: '아래 셀러의 승인요청을 반려합니다.',
    inputOption: rejectRequestInputOption,
  },
  brand: {
    title: '브랜드 신청 반려',
    description: '아래 브랜드의 승인요청을 반려합니다.',
    inputOption: rejectRequestInputOption,
  },
}

const rejectRequest = ({ refetchRef, modelKey, variableKey, descriptionComponentKey }: statusRequestProps) => {
  const fixedProps = rejectRequestFixedPropsByModelKey[modelKey]
  const modalProps = { refetchRef, modelKey, variableKey, descriptionComponentKey, fixedProps }
  return {
    title: '반려',
    key: 'rejectRequest',
    width: 70,
    render: (record: IModelProduct | IModelBrand) => {
      const { useModalProps } = useModal()
      if (modelKey === 'brand') {
        const { status } = record
        if (status.value !== 'REQUEST') return <>-</>
      }
      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.toggleModal()
              useModalProps.setData({ [modelKey]: record })
            }}
          >
            <Text14Blue>[반려]</Text14Blue>
          </MTextButton>
          <ModalConfirmInputReject useModalProps={useModalProps} {...modalProps} />
        </>
      )
    },
  }
}

const statusText = ({
  title = '상태',
  dataIndex = FIELD_KEYS.FIELD_STATUS,
  actionProps,
  statusFilterRefetchRef,
}: {
  title?: ITableColumn<any>['title']
  dataIndex?: ITableColumn<any>['dataIndex']
  actionProps?: statusRequestProps
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: dataIndex as ITableColumn<any>['key'],
    width: 100,
    render: (status: IModelStatus, record: IModelProduct | IModelBrand) => {
      if (!utilData.hasData(status)) return <Text14Normal>-</Text14Normal>

      const { isRequest, statusText: _statusText, text } = status
      const { useModalProps } = useModal()
      const withApplyButton = actionProps && isRequest

      return (
        <>
          <Text14Normal>{_statusText ?? text} </Text14Normal>
          {withApplyButton && (
            <>
              <MTextButton
                onClick={() => {
                  useModalProps.showModal()
                  useModalProps.setData({ [actionProps!.modelKey]: record })
                }}
              >
                <Text14Blue>[승인]</Text14Blue>
              </MTextButton>

              <ModalConfirm
                useModalProps={useModalProps}
                fixedProps={{
                  mutationKey: {
                    gqlKey: 'approveRequest',
                    variableKey: actionProps!.variableKey,
                  },
                  ...approveRequestFixedPropsByModelKey[actionProps!.modelKey],
                  componentOption: {
                    descriptionComponentKey: actionProps!.descriptionComponentKey,
                  },
                  confirmMessage: setConfirmMessage(actionProps!.modelKey),
                }}
                onAPISuccess={() => {
                  if (actionProps!.refetchRef && actionProps!.refetchRef.current) {
                    actionProps!.refetchRef.current()
                  }
                  if (statusFilterRefetchRef?.current) statusFilterRefetchRef.current()
                }}
              />
            </>
          )}
        </>
      )
    },
  }
}

export {
  statusText, // 상태 text 운영상태, 신청상태, 판매상태..?
  approveRequest, // 승인
  rejectRequest, // 승인 반려
  suspendApproveRequest, // 사용 중지
}
