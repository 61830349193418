import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const USER_FRAGMENTS: { [index: string]: any } = {
  get formUser() {
    return gql`
      fragment formUser on UserType {
        ...user
        inviteUser {
          id
          email
        }
        grade {
          id
          name
        }
        userSocialServices {
          edges {
            node {
              id
              socialType
              createdAt
            }
          }
        }
        sellers {
          edges {
            node {
              id
              shopName
              companyOwnerName
            }
          }
        }
      }
      ${DEFAULT_FRAGMENTS.user}
    `
  },
  get userOnly() {
    return gql`
      fragment userOnly on UserType {
        id
        email
        isStaff
        dateJoined
        username
        name
        mobileNumber
        createdAt
      }
    `
  },
  get userSignupSignin() {
    return gql`
      fragment userSignupSignin on UserType {
        ...user
        sellers {
          edges {
            node {
              createdAt
              updatedAt
              id
              companyName
              shopName
              companyOwnerName
              requestedAt
              approvedAt
              updateApprovedAt
              status {
                id
                value
              }
              chiefManager {
                ...user
              }
            }
          }
        }
      }
      ${DEFAULT_FRAGMENTS.user}
    `
  },
  get userBasicInfo() {
    return gql`
      fragment userBasicInfo on UserType {
        id
        lastLogin
        isSuperuser
        isSeller
        firstName
        lastName
        email
        isStaff
        isActive
        dateJoined
        username
        name
        inactivatedAt
        mobileNumber
        lastLogin
        mileagePoint
        approvalSellerCount
        agreeLocationBasedService
        agreeThirdPartyProvision
        grade {
          id
          name
        }
      }
    `
  },
}

export default USER_FRAGMENTS
