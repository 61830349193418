import { MutationFunction } from '@apollo/client'
import { COUPON_GROUP_STATUS_TYPE } from '@constants/constDomain'
import { message } from 'antd'

import { DomainModalProps } from 'containers/hooks/useModal'
import { MConfirmModal } from 'components/modals'
import MMutation from 'components/MMutation'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { IModelDisplayProductInfo } from 'containers/models/modelDisplayProductInfo'
import { IModelShippingPreset } from 'containers/models/modelShippingPreset'
import SubComponent, { componentOptionProps } from 'domains/common/SubComponentProvider'
import { MConfirmModalProps, MFixedProps } from 'components/modals/MConfirmModal'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { IModelCoupon } from 'containers/models/modelCoupon'
import { IModelBrand } from 'containers/models/modelBrand'
import { IModelCategory } from 'containers/models/modelCategory'
import { IModelCouponGroup } from 'containers/models/modelCouponGroup'
import { IModelCustomerGrade } from 'containers/models/modelCustomerGrade'
import { IModelProduct } from 'containers/models/modelProduct'
import { IModelSeller } from 'containers/models/modelSeller'
import { IModelStatus } from 'containers/models/modelStatus'
import { IModelUser } from 'containers/models/modelUser'
import { MUTATION_KEY2GQLS } from 'containers/gqls'
import { IModelReturnInfoDefault } from 'containers/models/base/defaultModelInterfaces'
import { IModelServerConfig } from 'containers/models/modelServerConfig'

type mutationOptionProps = {
  // variableKey 또는 getCustomVariables 는 반듯이 입력해야 합니다.
  gqlKey: keyof typeof MUTATION_KEY2GQLS
  variableKey?: string
  getCustomVariables?: () => Promise<any>
}

type ConfirmModalProps = MConfirmModalProps & {
  status?: IModelStatus
  user?: IModelUser
  seller?: IModelSeller
  product?: IModelProduct
  category?: IModelCategory
  brand?: IModelBrand
  orderItems?: IModelOrderItem[]
  orderItem?: IModelOrderItem
  couponGroup?: IModelCouponGroup
  customerGrade?: IModelCustomerGrade
  coupon?: IModelCoupon
  coupons?: IModelCoupon[]
  returnInfos?: IModelReturnInfoDefault[]
  serverConfig?: IModelServerConfig
  displayProductInfo?: IModelDisplayProductInfo
  shippingPreset?: IModelShippingPreset
}

type FixedProps = MFixedProps & {
  mutationKey: mutationOptionProps
  title: string
  componentOption?: componentOptionProps
  confirmMessage?: string
}

const ModalConfirm = ({
  useModalProps,
  fixedProps,
  onAction,
  onCancelAction,
  onAPISuccess,
}: DomainModalProps<ConfirmModalProps, FixedProps>) => {
  const { data } = useModalProps
  const {
    id,
    status,
    user,
    seller,
    product,
    category,
    brand,
    orderItems,
    orderItem,
    couponGroup,
    customerGrade,
    coupon,
    coupons,
    returnInfos,
    serverConfig,
    displayProductInfo,
    shippingPreset,
  } = data
  const { mutationKey, title, description, componentOption, okButtonDisable, okText, disableContent } = fixedProps
  const { gqlKey, variableKey, getCustomVariables } = mutationKey

  if (!gqlKey) return null

  const getVariables = () => {
    if (variableKey === 'id') {
      return { variables: { id } }
    }
    if (variableKey === 'statusId') {
      return { variables: { id: status!.id } }
    }
    if (variableKey === 'userId') {
      return { variables: { id: user!.id } }
    }
    if (variableKey === 'orderItemId') {
      return { variables: { id: orderItem!.id } }
    }
    if (variableKey === 'orderItemIds') {
      return {
        variables: MUTATION_VARIABLES.CONFIRM_RETURN_INFOS({
          orderItems: orderItems!.map((item) => ({ orderItem: item.id })),
        }),
      }
    }
    if (variableKey === 'productId') {
      return { variables: { id: product!.id } }
    }
    if (variableKey === 'productStatusId') {
      return { variables: { id: product!.status.id } }
    }
    if (variableKey === 'productUpdateStatusId') {
      return { variables: { id: product!.updateStatus.id } }
    }
    if (variableKey === 'sellerStatusId') {
      return { variables: { id: seller!.status?.id } }
    }
    if (variableKey === 'categoryId') {
      return { variables: { id: category!.id } }
    }
    if (variableKey === 'brandStatusId') {
      return { variables: { id: brand!.status.id } }
    }
    if (variableKey === 'deleteCoupon') {
      return { variables: { id: coupon!.id } }
    }
    if (variableKey === 'deleteCouponGroup' || variableKey === 'deleteCouponsByGroup') {
      return { variables: { id: couponGroup!.id } }
    }
    if (variableKey === 'deleteCoupons') {
      return {
        variables: MUTATION_VARIABLES.DELETE_COUPONS({ ids: coupons!.map((x) => x.id) }),
      }
    }
    if (variableKey === 'displayProductInfo') {
      return { variables: { id: displayProductInfo!.id } }
    }
    if (variableKey === 'activateCustomerGrade') {
      return {
        variables: MUTATION_VARIABLES.ACTIVATE_CUSTOMER_GRADE({
          id: customerGrade!.id,
          isActivated: customerGrade!.isActivated,
        }),
      }
    }
    if (variableKey === 'createSellerManager' || variableKey === 'deleteSellerManager') {
      return {
        variables: MUTATION_VARIABLES.CREATE_SELLER_MANAGER({ user: user!.id, seller: seller!.id }),
      }
    }
    if (variableKey === 'acceptReturnInfosRequest') {
      return {
        variables: MUTATION_VARIABLES.ACCEPT_RETURN_INFOS({
          returnInfos: returnInfos!.map((item) => ({ returnInfo: item.id })),
        }),
      }
    }
    if (variableKey === 'acceptExchangeReturnInfosRequest') {
      return {
        variables: MUTATION_VARIABLES.ACCEPT_EXCHANGE_RETURN_INFOS({
          returnInfos: returnInfos!.map((item) => ({ returnInfo: item.id })),
        }),
      }
    }
    if (variableKey === 'confirmReturnInfos') {
      return {
        variables: MUTATION_VARIABLES.CONFIRM_RETURN_INFOS({
          orderItems: orderItems!.map((item) => ({ orderItem: item.id })),
        }),
      }
    }
    if (variableKey === 'toggleMileageSuspend') {
      const { key, mileageConfigData } = serverConfig!
      return {
        variables: MUTATION_VARIABLES.UPDATE_MILEAGE_CONFIG({
          key,
          isSuspend: !mileageConfigData.isSuspend,
        }),
      }
    }
    if (variableKey === 'updateCouponGroupStatus') {
      const nextStatus =
        couponGroup!.couponGroupStatus === COUPON_GROUP_STATUS_TYPE.SUSPENDED
          ? COUPON_GROUP_STATUS_TYPE.REGISTERED
          : COUPON_GROUP_STATUS_TYPE.SUSPENDED
      return { variables: { id: couponGroup!.id, input: { couponGroupStatus: nextStatus } } }
    }
    if (variableKey === 'createShippingPreset') {
      return {
        variables: MUTATION_VARIABLES.CREATE_SHIPPING_PRESET({
          ...shippingPreset,
        }),
      }
    }
    if (variableKey === 'updateShippingPreset') {
      return {
        variables: MUTATION_VARIABLES.UPDATE_SHIPPING_PRESET({
          ...shippingPreset,
        }),
      }
    }
    return {}
  }

  return (
    <MMutation
      gqlKey={gqlKey}
      onAPISuccess={(res) => {
        if (onAction) {
          onAction()
        }
        if (onAPISuccess) {
          onAPISuccess(res)
        }
        if (fixedProps.confirmMessage) {
          message.success({ content: fixedProps.confirmMessage })
        }
      }}
    >
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <MConfirmModal
            useModalProps={useModalProps}
            onAction={async () => {
              const variables = getCustomVariables ? await getCustomVariables() : getVariables()
              await mutation(variables)
            }}
            onCancelAction={onCancelAction}
            fixedProps={{
              title,
              description,
              okButtonDisable,
              okText,
              disableContent,
              Component: SubComponent({ componentOption, targetModel: data }),
            }}
            loading={loading}
          />
        )
      }}
    </MMutation>
  )
}

export default ModalConfirm
