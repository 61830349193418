import { gql } from '@apollo/client'
import CATEGORY_FRAGMENTS from 'containers/gqls/products/category/fragments'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const CATEGORY_MUTATION_GQLS = {
  CREATE_CATEGORY: gql`
    ${CATEGORY_FRAGMENTS.categoryWithChildren}
    mutation createCategory($input: CategoryInput!) {
      createCategory(input: $input) {
        category {
          ...categoryWithChildren
        }
      }
    }
  `,
  UPDATE_CATEGORY: gql`
    ${CATEGORY_FRAGMENTS.categoryWithChildren}
    mutation updateCategory($id: ID!, $input: CategoryInput!) {
      updateCategory(id: $id, input: $input) {
        category {
          ...categoryWithChildren
        }
      }
    }
  `,
  UPDATE_CATEGORY_IMAGE: gql`
    mutation updateCategoryImage($id: ID!, $input: CategoryImageInput!) {
      updateCategoryImage(id: $id, input: $input) {
        category {
          id
          listImage
        }
      }
    }
  `,
  UPDATE_CATEGORY_SLUG: gql`
    mutation updateCategorySlug($id: ID!, $input: CategorySlugInput!) {
      updateCategorySlug(id: $id, input: $input) {
        category {
          id
        }
      }
    }
  `,
  TOGGLE_CATEGORY_VISIBLE: gql`
    mutation toggleCategoryVisible($id: ID!) {
      toggleCategoryVisible(id: $id) {
        category {
          ...category
        }
      }
    }
    ${DEFAULT_FRAGMENTS.category}
  `,
}

export default CATEGORY_MUTATION_GQLS
