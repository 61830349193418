import { gql } from '@apollo/client'

export const AD_MUTATION_GQLS = {
  CREATE_AD: gql`
    mutation createAd($input: CreateAdInput!) {
      createAd(input: $input) {
        ad {
          id
        }
      }
    }
  `,
  UPDATE_AD: gql`
    mutation updateAd($id: ID!, $input: UpdateAdInput!) {
      updateAd(id: $id, input: $input) {
        ad {
          id
          displayOrder
          startedAt
          endedAt
          petType
          name
          description
          adType
          isShown
        }
      }
    }
  `,
  DELETE_ADS: gql`
    mutation deleteAds($input: ModelDeletesInput!) {
      deleteAds(input: $input) {
        count
      }
    }
  `,
  FINISH_AD: gql`
    mutation finishAd($id: ID!) {
      finishAd(id: $id) {
        ad {
          id
        }
      }
    }
  `,
}

export default AD_MUTATION_GQLS
