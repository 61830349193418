import React from 'react'

import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { DownOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { Text14A65 } from 'components/@atomics'

interface MDropdownLikeButtonProps extends ButtonProps {
  wordProps?: { [index: string]: string }
}

const MDropdownLikeButton = (props: MDropdownLikeButtonProps) => {
  const { onClick = () => console.log('Dummy Clicked'), wordProps = {} } = props
  const { text = 'Dummy texts' } = wordProps
  return (
    <StyledButton type="link" onClick={onClick}>
      <Text14A65>
        {text} <DownOutlined />
      </Text14A65>
    </StyledButton>
  )
}

const StyledButton = styled(Button)<MDropdownLikeButtonProps>`
  padding: 4px 10px;
`

export default MDropdownLikeButton
