import { FunctionComponent } from 'react'
import { Space } from 'antd'
import styled from 'styled-components'

import { MModal } from 'components/modals'
import { DomainModalProps } from 'containers/hooks/useModal'
import { Text14Normal } from 'components/@atomics'

export type MConfirmTableModalProps = {
  selectedLength?: number
}

export type MConfirmTableModalFixedProps = ContentProps & {
  title: string
  loading?: boolean
}

type ContentProps = {
  description?: string
  Component?: JSX.Element
}

const Content: FunctionComponent<ContentProps> = ({ description, Component }) => {
  return (
    <>
      <StyledSpace direction="vertical" size="middle">
        {description && <Text14Normal>{description}</Text14Normal>}
        {Component && Component}
      </StyledSpace>
    </>
  )
}

const MConfirmTableModal = ({
  useModalProps,
  onAction,
  fixedProps,
}: DomainModalProps<MConfirmTableModalProps, MConfirmTableModalFixedProps>) => {
  const { title, description, Component, loading } = fixedProps
  const { hideModal } = useModalProps
  return (
    <MModal
      title={title}
      useModalProps={useModalProps}
      ComponentContent={<Content description={description} Component={Component} />}
      onAction={() => {
        if (onAction) {
          onAction()
          hideModal()
        }
      }}
      loading={loading}
    />
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default MConfirmTableModal
