import { Col, Image } from 'antd'
import { MButton } from 'components/@atomics'
import React from 'react'

const MSingleImage = ({ content }: { content: string }) => {
  return (
    <>
      <Col>
        <Image src={content} alt={`image-${content}`} width={200} height={200} />
      </Col>
      <div style={{ height: 8 }} />
      <MButton target="_blank" rel="noreferrer noopener" href={content} download>
        다운로드
      </MButton>
    </>
  )
}

export default MSingleImage
