import { MILEAGE_TYPE_TEXT, MILEAGE_TYPE } from '@constants/constDomain'
import * as utilData from '@libs/utils/utilData'
import * as utilDate from '@libs/utils/utilDate'
import { ModelMileageHistoryDefault } from 'containers/models/base/defaultModels'
import { IModelMileageHistoryDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelMileageHistory extends IModelMileageHistoryDefault {
  fitpetAdminName: string
  mileageTypeText: MILEAGE_TYPE_TEXT
  provideText: string
  deductionText: string
  createdAtText: string
  expiredAtText: string
}

export default class ModelMileageHistory extends ModelMileageHistoryDefault {
  public fitpetAdminName: string = ''
  public mileageTypeText: MILEAGE_TYPE_TEXT = MILEAGE_TYPE_TEXT.ETC
  public provideText: string = '' // 지급액
  public deductionText: string = '' // 차감액
  public createdAtText: string = ''
  public expiredAtText: string = ''

  constructor(data: IModelMileageHistory) {
    super(data)

    this.createdAtText = utilDate.datetime2stringWithFormat(this.createdAt)
    this.expiredAtText = utilDate.datetime2stringWithFormat(this.expiredAt)
    this.fitpetAdminName = this.fitpetAdmin.name ? `Fitpet(${this.fitpetAdmin.name})` : 'Fitpet'
    this.mileageTypeText = MILEAGE_TYPE_TEXT[this.mileageType as MILEAGE_TYPE]
    this.provideText = this.getProvideText()
    this.deductionText = this.getDeductionText()
  }

  private getProvideText = () => {
    const resultMileage = this.afterMileage - this.beforeMileage
    if (resultMileage > 0) {
      return `+${utilData.currencyText(resultMileage)}`
    }
    return ''
  }

  private getDeductionText = () => {
    const resultMileage = this.afterMileage - this.beforeMileage
    if (resultMileage < 0) {
      return `${utilData.currencyText(resultMileage)}`
    }
    return ''
  }
}
