import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const REVIEW_MUTATION_GQLS = {
  CREATE_BULK_REVIEW_BY_FITPET_ADMIN_EXCEL: gql`
    mutation createBulkReviewByFitpetAdminExcel($input: BaseImportByExcelInput!) {
      createBulkReviewByFitpetAdminExcel(input: $input) {
        isSuccess
        isTask
        failedReasons
        count
      }
    }
  `,
  CREATE_REVIEW: gql`
    mutation createReview($input: CreateReviewInput!) {
      createReview(input: $input) {
        review {
          ...review
        }
      }
    }
    ${DEFAULT_FRAGMENTS.review}
  `,
  UPDATE_REVIEW: gql`
    mutation updateReview($id: ID!, $input: UpdateReviewInput!) {
      updateReview(id: $id, input: $input) {
        review {
          ...review
        }
      }
    }
    ${DEFAULT_FRAGMENTS.review}
  `,
  UPDATE_REVIEW_BY_FITPET_ADMIN: gql`
    mutation updateReviewByFitpetAdmin($id: ID!, $input: UpdateReviewInput!) {
      updateReviewByFitpetAdmin(id: $id, input: $input) {
        review {
          ...review
        }
      }
    }
    ${DEFAULT_FRAGMENTS.review}
  `,
  DELETE_REVIEWS: gql`
    mutation deleteReviews($input: DeleteReviewsInput!) {
      deleteReviews(input: $input) {
        count
      }
    }
  `,
  UPDATE_REVIEW_GROUP_PRODUCTS: gql`
    mutation updateReviewGroupProducts($id: ID!, $input: UpdateReviewGroupProductsInput!) {
      updateReviewGroupProducts(id: $id, input: $input) {
        reviewGroup {
          ...reviewGroup
        }
      }
    }
    ${DEFAULT_FRAGMENTS.reviewGroup}
  `,
}

export default REVIEW_MUTATION_GQLS
