import { gql } from '@apollo/client'
import PRODUCT_FRAGMENTS from 'containers/gqls/products/product/fragments'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const PRODUCT_QUERY_GQLS = {
  PRODUCT_COUNT: gql`
    query productCount($seller: ID) {
      productCount(seller: $seller) {
        requestCount
        rejectedCount
        sellingCount
        preSellingCount
        soldOutCount
        suspendCount
        endSellingCount
        bannedCount
        deletedCount
        updateRequestCount
        updateRejectedCount
      }
    }
  `,
  PRODUCT: gql`
    query product($id: ID!) {
      product(id: $id) {
        ...productDetail
      }
    }
    ${PRODUCT_FRAGMENTS.productDetail}
  `,
  PRODUCTS: gql`
    query products($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      products(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        ...products
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${PRODUCT_FRAGMENTS.products}
  `,
  PRODUCT_LIST_TABLE: gql`
    query productListTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      products(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            id
            name
            realName
            price
            customerPrice
            seller {
              id
              shopName
              companyOwnerName
            }
            status {
              ...status
            }
            supplyType
            updateStatus {
              ...status
            }
            brand {
              id
              name
            }
            petType
            stockQuantity
            reviewMileageRewardRate
            confirmMileageRewardRate
            createdAt
            updateApprovedAt
            deletedAt
            isRealSoldOut
            isSoldOut
            isShown
            mainImage {
              ...productImage
            }
            productProductImages {
              edges {
                node {
                  image
                  id
                  displayType
                  thumbnail(productImageType: "SMALL")
                }
              }
            }
            productProductOptions {
              edges {
                node {
                  id
                  isDefault
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.productImage}
  `,
  INCOMPLETE_PRODUCTS_BY_ROLE: gql`
    query incompleteProducts($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      incompleteProductsByRole(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        ...products
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${PRODUCT_FRAGMENTS.products}
  `,
  PRODUCT_LIST: gql`
    query productList($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      products(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            id
            name
            price
            discountPrice
            discountRate
            customerPrice
            createdAt
            approvedAt
            updateApprovedAt
            isSoldOut
            productProductImages {
              edges {
                node {
                  id
                  image
                  thumbnail
                  displayType
                }
              }
            }
            brand {
              id
              name
            }
            categories {
              edges {
                node {
                  id
                  name
                }
              }
            }
            status {
              id
              text
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  PRODUCTS_BY_TRANSFER_TABLE: gql`
    query productsByTransferTable($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      products(pageInfo: $pageInfo, filter: $filter) {
        totalCount
        edges {
          node {
            id
            name
            realName
            price
            createdAt
            updateApprovedAt
            isRealSoldOut
            customerPrice
            discountPrice
            brand {
              id
              name
            }
            reviewGroup {
              id
              name
            }
            status {
              id
              text
            }
            mainImage {
              id
              thumbnail
            }
          }
        }
      }
    }
  `,
  PRODUCT_SELECT_MODAL: gql`
    query productSelectModal($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      products(pageInfo: $pageInfo, filter: $filter) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          node {
            id
            name
            customerPrice
            productOptionType
            status {
              id
              value
              text
            }
            seller {
              id
              shopName
            }
            productProductOptions {
              edges {
                node {
                  id
                  name
                  productOptionSkus {
                    pageInfo {
                      ...pageInfo
                    }
                    edges {
                      node {
                        id
                        productOption {
                          id
                        }
                        sku {
                          id
                          code
                          name
                          stockQuantity
                          purchasePrice
                        }
                        count
                      }
                    }
                  }
                  series
                  stockQuantity
                  price
                  productOptionPrice
                  purchasePrice
                  isUse
                  isDefault
                  productOptionKinds {
                    key
                    value
                  }
                }
              }
            }
            productProductImages {
              edges {
                node {
                  id
                  image
                  thumbnail
                  displayType
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  PRODUCT_UPDATE_INFO: gql`
    query productUpdateInfo($id: ID!) {
      product(id: $id) {
        id
        status {
          value
        }
        ...productUpdateInfo

        updateInfo {
          statusValue

          product {
            ...productUpdateInfo
          }
        }
      }
    }
    ${PRODUCT_FRAGMENTS.productUpdateInfo}
  `,
}

export default PRODUCT_QUERY_GQLS
