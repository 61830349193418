import { ModelShippingDefault } from 'containers/models/base/defaultModels'
import { IModelShippingDefault } from 'containers/models/base/defaultModelInterfaces'
import { IModelCoupon } from 'containers/models/modelCoupon'
import { IModelShippingCompany } from 'containers/models/modelShippingCompany'
import ModelCouponInfo, { IModelCouponInfo } from 'containers/models/modelCouponInfo'

export interface IModelShipping extends IModelShippingDefault {
  couponInfoData: IModelCouponInfo
  shippingCoupons: IModelCoupon[]
  shippingCompany: IModelShippingCompany
}

export default class ModelShipping extends ModelShippingDefault {
  couponInfoData: IModelCouponInfo = {} as IModelCouponInfo

  constructor(data: IModelShipping) {
    super(data)
    this.couponInfoData = this.couponInfo
      ? new ModelCouponInfo(this.couponInfo, this.couponDiscountPrice)
      : ({} as IModelCouponInfo)
  }
}
