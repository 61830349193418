import React, { useState, MutableRefObject, useRef, useEffect } from 'react'
import { MutationFunction } from '@apollo/client'

import * as utilData from '@libs/utils/utilData'
import * as utilCommon from '@libs/utils/utilCommon'
import { IModelSellerDefault } from 'containers/models/base/defaultModelInterfaces'
import { IModelUser } from 'containers/models/modelUser'
import { MConfirmModal } from 'components/modals'
import { MFormItemInfo } from 'components/formItems'
import { MButton, MTable, Text14Normal, Text14Blue } from 'components/@atomics'
import MMutation from 'components/MMutation'
import useModal from 'containers/hooks/useModal'
import tableCols from 'domains/admin/tableCols'
import { ModalFunctionalTable } from 'domains/common/modals'
import { modalContentProps } from 'domains/common/modals/ModalTableSelect'

const tableColumnsCurrentSellerInfo = (data?: IModelUser, refetchRef?: MutableRefObject<Function | null>) => {
  const columns = [
    tableCols.common.ID(),
    tableCols.common.baseText({ title: '셀러명', dataIndex: 'shopName' }),
    tableCols.common.baseText({ title: '대표자명', dataIndex: 'companyOwnerName' }),
  ]

  if (data && refetchRef) {
    columns.push(tableCols.seller.managerKindText(data), tableCols.seller.deleteManager(data, refetchRef))
  }

  return columns
}

const modalTableSingleSelectSeller = (
  data?: IModelUser,
  refetchRef?: MutableRefObject<Function | null>
): modalContentProps => {
  const tableColumnsCreateOrDeleteManagerFromUsers = [
    tableCols.common.ID(),
    tableCols.common.baseText({ title: '셀러명', dataIndex: 'shopName' }),
    tableCols.common.baseText({ title: '대표자명', dataIndex: 'companyOwnerName' }),
  ]

  if (data && refetchRef) {
    tableColumnsCreateOrDeleteManagerFromUsers.push(tableCols.seller.createOrDeleteManager(data, refetchRef))
  }

  return {
    title: '셀러 선택',
    queryParams: {
      gqlKey: 'sellersMinimumInfo',
      dataKey: 'sellers',
      variables: { filter: { status: { include: ['APPROVED'] } } },
    },
    searchInputs: [{ label: '셀러명', name: 'shopName' }],
    tableColumns: tableColumnsCreateOrDeleteManagerFromUsers,
  }
}

type FixedPropsType = {
  title: string
  description: string
}

type UserRoleInfoPropsType = {
  isEdit?: boolean
  data?: IModelUser
  refetch?: Function
}

const UserRoleInfo = ({ data, refetch }: UserRoleInfoPropsType) => {
  const { useModalProps: useModalPropsAdmin } = useModal()
  const [isAdmin, setIsAdmin] = useState(data ? data.isStaff : false)
  const [sellers, setSellers] = useState<IModelSellerDefault[]>(data ? data.sellers : [])
  const modalAdminFixedProps: FixedPropsType = { title: '', description: '' }
  let refetchRef: MutableRefObject<Function | null> | undefined

  if (refetch) {
    refetchRef = useRef<Function>(refetch)
  }

  if (data) {
    useEffect(() => {
      setIsAdmin(data.isStaff)
      setSellers(data.sellers)
    }, [data.isStaff, data.sellers])
    modalAdminFixedProps.title = `관리자 ${data.isStaff ? '해제' : '등록'}`
    modalAdminFixedProps.description = `${data.name}님을 관리자${data.isStaff ? '에서 해제' : '로 등록'}하시겠습니까?`
  }

  const ButtonCreateOrDeleteManager = () => {
    const { useModalProps: userModalPropsTableSingleSelectSeller } = useModal()
    return (
      <>
        <MButton
          onClick={() => {
            userModalPropsTableSingleSelectSeller.toggleModal()
          }}
        >
          <Text14Normal>셀러 매니저 등록/관리</Text14Normal>
        </MButton>
        <ModalFunctionalTable
          useModalProps={userModalPropsTableSingleSelectSeller}
          {...modalTableSingleSelectSeller(data, refetchRef)}
        />
      </>
    )
  }

  return (
    <>
      {utilCommon.isAdmin() && (
        <>
          <MFormItemInfo label="관리자 여부" labelCol={{ span: 3 }}>
            {/* 관리자 등록 */}
            <MFormItemInfo label={isAdmin ? '관리자' : '관리자 아님'} labelCol={{ span: 4 }}>
              <MButton
                onClick={() => {
                  useModalPropsAdmin.toggleModal()
                }}
              >
                {isAdmin ? '관리자 해제' : '관리자 등록'}
              </MButton>
              <MMutation
                gqlKey={isAdmin ? 'removeAdminPermission' : 'addAdminPermission'}
                onAPISuccess={() => setIsAdmin(!isAdmin)}
              >
                {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
                  return (
                    // TODO yoon: ModalConfirm 으로 변경 필요
                    <MConfirmModal
                      useModalProps={useModalPropsAdmin}
                      onAction={async () => {
                        await mutation({ variables: { id: data?.id } })
                      }}
                      fixedProps={{ ...modalAdminFixedProps }}
                      loading={loading}
                    />
                  )
                }}
              </MMutation>
            </MFormItemInfo>
          </MFormItemInfo>

          {/* 셀러 매니저 등록 */}
          <MFormItemInfo
            label="셀러 매니저 여부"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 18 }}
            help={
              <>
                <Text14Normal>
                  셀러를 검색 후 통합매니저를 변경하거나 주매니저로 등록해 주세요. 통합검색 (현재는 사업자 검색 가능)
                </Text14Normal>
                <br />
                <Text14Blue>
                  * 통합매니저 등록 안내: 회원은 1개의 셀러 통합매니저 역할만 가능하며, 통합매니저로 등록 및 변경은 셀러
                  상세 정보에서 할 수 있습니다.
                </Text14Blue>
              </>
            }
          >
            <MFormItemInfo label={!utilData.hasData(sellers) && '셀러 매니저 아님'}>
              <ButtonCreateOrDeleteManager />
            </MFormItemInfo>
            {utilData.hasData(sellers) && (
              <MFormItemInfo wrapperCol={{ span: 20 }}>
                <MTable
                  columns={tableColumnsCurrentSellerInfo(data, refetchRef)}
                  dataSource={sellers}
                  pagination={false}
                />
              </MFormItemInfo>
            )}
          </MFormItemInfo>
        </>
      )}
    </>
  )
}

export default UserRoleInfo
