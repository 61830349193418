import * as utilCommon from '@libs/utils/utilCommon'
import * as utilData from '@libs/utils/utilData'
import { hasData } from '@libs/utils/utilData'
import * as utilDate from '@libs/utils/utilDate'
import { PET_TYPE, PET_TYPE_TEXT, USER_MODE } from '@constants/constDomain'
import useUserInfo from 'containers/hooks/useUserInfo'
import { IModelUserDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelUserDefault } from 'containers/models/base/defaultModels'
import { IModelFakeWhoami } from 'containers/models/modelFakeWhoami'
import { IModelSeller } from 'containers/models/modelSeller'
import { IModelDevice } from 'containers/models/modelDevice'

export interface IModelUser extends IModelUserDefault {
  agreeMarketingAtText: string
  agreeNightMarketingAtText: string
  bankAccountText: string
  dateJoinedText: string
  gradeUpdatedAtText: string
  inviteCountText: string
  isMe: boolean
  lastLoginText: string
  mileagePointText: string
  orderAmountText: string
  sellers: IModelSeller[]
  userMode: string | null
  userDevices: IModelDevice[]
  lastUserDevice: IModelDevice
  displayPetTypeText: string
  fullBankAccount: string

  isChiefManager: (seller: IModelSeller) => boolean
  managerKindText: (seller: IModelSeller) => '통합매니저' | '담당자'
}

export default class ModelUser extends ModelUserDefault {
  isMe = false
  userMode: string | null = ''

  agreeNightMarketingAtText: string = ''
  agreeMarketingAtText: string = ''
  dateJoinedText: string = ''
  gradeUpdatedAtText: string = ''
  lastLoginText: string = ''
  mileagePointText: string = ''
  orderAmountText: string = ''
  inviteCountText: string = ''
  bankAccountText: string = ''
  lastUserDevice: IModelDevice
  displayPetTypeText: string = ''
  fullBankAccount: string = ''

  constructor(data: IModelUser) {
    // @ts-ignore
    super(data)

    this.isMe = this.setIsMe()
    this.userMode = this.setUserMode()

    this.agreeNightMarketingAtText = utilDate.date2string(this.agreeNightMarketingAt)
    this.agreeMarketingAtText = utilDate.date2string(this.agreeMarketingAt)
    this.dateJoinedText = utilDate.date2string(this.dateJoined)
    this.gradeUpdatedAtText = utilDate.date2string(this.gradeUpdatedAt)
    this.lastLoginText = utilDate.date2string(this.lastLogin)
    this.mileagePointText = utilData.currencyText(this.mileagePoint)
    this.orderAmountText = utilData.currencyText(this.orderAmount)
    this.inviteCountText = utilData.countTextSecond(this.inviteCount)
    this.bankAccountText = this.accountNumber
      ? `${this.bankName} / ${this.accountNumber} / ${this.accountHolderName}`
      : ''
    this.lastUserDevice = getLastUserDevice(this.userDevices as IModelDevice[])
    this.displayPetTypeText = getDisplayPetTypeText(this.displayPetType)
    this.fullBankAccount = utilCommon.getFullBankAccount(this.bankName, this.accountNumber, this.accountHolderName)
  }

  private setIsMe = () => {
    const { loadWhoamiData } = useUserInfo
    const whoamiData = loadWhoamiData() || {}
    const { user = {} } = whoamiData as IModelFakeWhoami
    const { id } = user as ModelUser
    return id === this.id
  }

  private setUserMode = () => {
    if (this.isStaff) {
      return USER_MODE.ADMIN
    }
    if (this.isSeller) {
      return USER_MODE.SELLER
    }
    return USER_MODE.CUSTOMER
  }

  isChiefManager = (seller: IModelSeller) => {
    return seller.chiefManager.id === this.id
  }

  managerKindText = (seller: IModelSeller) => {
    return this.isChiefManager(seller) ? '통합매니저' : '담당자'
  }
}

export const getLastUserDevice = (userDevices?: IModelDevice[]) => {
  if (!hasData(userDevices)) return {} as IModelDevice
  return userDevices!.reduce((prev, curr) => {
    return prev.lastLoggedAt > curr.lastLoggedAt ? prev : curr
  })
}

export const getDisplayPetTypeText = (displayPetType: string) => {
  if (displayPetType === PET_TYPE.ALL) return PET_TYPE_TEXT.ALL
  if (displayPetType === PET_TYPE.DOG) return PET_TYPE_TEXT.DOG
  if (displayPetType === PET_TYPE.CAT) return PET_TYPE_TEXT.CAT
  return PET_TYPE_TEXT.DOG
}
