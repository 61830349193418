import { useEffect, useRef } from 'react'

const MTableRefetch = ({ refetch, useVariables }: { refetch: Function; useVariables: Function }) => {
  const didMountRef = useRef<boolean>(false)
  const variables = useVariables()
  const variablesJsonString = JSON.stringify(variables)

  useEffect(() => {
    if (didMountRef.current) {
      // console.log('### RefetchComponent do JOB')
      // console.log('filter : ', variables.filter)
      // console.log('orderBy : ', variables.orderBy)
      // console.log('pageInfo : ', variables.pageInfo)
      if (refetch) refetch({ ...variables })
    } else {
      didMountRef.current = true
    }
  }, [variablesJsonString])
  return null
}

export default MTableRefetch
