import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const DEFAULT_QUERY_GQLS = {
  TOTAL_SALES_STATISTIC: gql`
    query totalSalesStatisticDefault {
      totalSalesStatistic {
        ...totalSalesStatistic
      }
    }
    ${DEFAULT_FRAGMENTS.totalSalesStatistic}
  `,
  DAILY_STATISTICS: gql`
    query dailyStatisticsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      dailyStatistics(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...termStatisticColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.termStatisticColumn}
  `,
  WEEKLY_STATISTICS: gql`
    query weeklyStatisticsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      weeklyStatistics(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...termStatisticColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.termStatisticColumn}
  `,
  MONTHLY_STATISTICS: gql`
    query monthlyStatisticsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      monthlyStatistics(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...termStatisticColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.termStatisticColumn}
  `,
  SELLER_STATISTICS_BY_ROLE: gql`
    query sellerStatisticsByRoleDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellerStatisticsByRole(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...productStatisticBySellerColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productStatisticBySellerColumn}
  `,
  INFLUENCER_STATISTICS_BY_ROLE: gql`
    query influencerStatisticsByRoleDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      influencerStatisticsByRole(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...productStatisticByInfluencerColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productStatisticByInfluencerColumn}
  `,
  TOTAL_SELLER_STATISTIC: gql`
    query totalSellerStatisticDefault {
      totalSellerStatistic {
        ...totalSellerStatistic
      }
    }
    ${DEFAULT_FRAGMENTS.totalSellerStatistic}
  `,
  SELLER_SALES_STATISTICS: gql`
    query sellerSalesStatisticsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellerSalesStatistics(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...sellerStatisticColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.sellerStatisticColumn}
  `,
  TOTAL_INFLUENCER_STATISTIC: gql`
    query totalInfluencerStatisticDefault {
      totalInfluencerStatistic {
        ...totalInfluencerStatistic
      }
    }
    ${DEFAULT_FRAGMENTS.totalInfluencerStatistic}
  `,
  INFLUENCER_SALES_STATISTICS: gql`
    query influencerSalesStatisticsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      influencerSalesStatistics(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...influencerStatisticColumn
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.influencerStatisticColumn}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  CART_ITEM: gql`
    query cartItemDefault($id: ID!) {
      cartItem(id: $id) {
        ...cartItem
        productOption {
          ...productOption
        }
        user {
          ...user
        }
        productPromotion {
          ...productPromotion
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItem}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CART_ITEMS: gql`
    query cartItemsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      cartItems(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...cartItem
            productOption {
              ...productOption
            }
            user {
              ...user
            }
            productPromotion {
              ...productPromotion
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.cartItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CART_ITEM_HISTORIES: gql`
    query cartItemHistoriesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      cartItemHistories(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...cartItemHistory
            productOption {
              ...productOption
            }
            user {
              ...user
            }
            productPromotion {
              ...productPromotion
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.cartItemHistory}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CUSTOMER_GRADE: gql`
    query customerGradeDefault($id: ID!) {
      customerGrade(id: $id) {
        ...customerGrade
        couponGroups {
          ...couponGroupConnection
        }
        fitpetAdmin {
          ...user
        }
        gradeUsers {
          ...userConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CUSTOMER_GRADES: gql`
    query customerGradesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      customerGrades(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...customerGrade
            couponGroups {
              ...couponGroupConnection
            }
            fitpetAdmin {
              ...user
            }
            gradeUsers {
              ...userConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  NOTICES: gql`
    query noticesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      notices(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...notice
            user {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.notice}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.user}
  `,
  NOTICE: gql`
    query noticeDefault($id: ID!) {
      notice(id: $id) {
        ...notice
        user {
          ...user
        }
      }
    }
    ${DEFAULT_FRAGMENTS.notice}
    ${DEFAULT_FRAGMENTS.user}
  `,
  FAQS: gql`
    query faqsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      faqs(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...faq
            user {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.faq}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.user}
  `,
  FAQ: gql`
    query faqDefault($id: ID!) {
      faq(id: $id) {
        ...faq
        user {
          ...user
        }
      }
    }
    ${DEFAULT_FRAGMENTS.faq}
    ${DEFAULT_FRAGMENTS.user}
  `,
  COUPONS: gql`
    query couponsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      coupons(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...coupon
            couponGroup {
              ...couponGroup
            }
            user {
              ...user
            }
            fitpetAdmin {
              ...user
            }
            order {
              ...order
            }
            orderItem {
              ...orderItem
            }
            shipping {
              ...shipping
            }
            usedOrderItem {
              ...orderItem
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.coupon}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.user}
  `,
  COUPON_GROUP: gql`
    query couponGroupDefault($id: ID!) {
      couponGroup(id: $id) {
        ...couponGroup
        fitpetAdmin {
          ...user
        }
        ad {
          ...ad
        }
        includedBrands {
          ...brandConnection
        }
        includedProducts {
          ...productConnection
        }
        includedCategories {
          ...categoryConnection
        }
        excludedBrands {
          ...brandConnection
        }
        excludedProducts {
          ...productConnection
        }
        excludedCategories {
          ...categoryConnection
        }
        informkakaoqueue {
          ...informKakaoQueue
        }
        couponGroupCoupons {
          ...couponConnection
        }
        couponGroupCustomerGrades {
          ...customerGradeConnection
        }
        informKakaoQueue {
          ...informKakaoQueue
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informKakaoQueue}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  COUPON_GROUPS: gql`
    query couponGroupsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      couponGroups(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...couponGroup
            fitpetAdmin {
              ...user
            }
            ad {
              ...ad
            }
            includedBrands {
              ...brandConnection
            }
            includedProducts {
              ...productConnection
            }
            includedCategories {
              ...categoryConnection
            }
            excludedBrands {
              ...brandConnection
            }
            excludedProducts {
              ...productConnection
            }
            excludedCategories {
              ...categoryConnection
            }
            informkakaoqueue {
              ...informKakaoQueue
            }
            couponGroupCoupons {
              ...couponConnection
            }
            couponGroupCustomerGrades {
              ...customerGradeConnection
            }
            informKakaoQueue {
              ...informKakaoQueue
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroup}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informKakaoQueue}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  AD: gql`
    query adDefault($id: ID!) {
      ad(id: $id) {
        ...ad
        adCouponGroups {
          ...couponGroupConnection
        }
        adImages {
          ...adImageConnection
        }
        adDisplayCollections {
          ...displayCollectionConnection
        }
        mainImage {
          ...adImage
        }
        couponGroups {
          ...couponGroupConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.adImage}
    ${DEFAULT_FRAGMENTS.adImageConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayCollectionConnection}
  `,
  AD_BY_SLUG: gql`
    query adBySlugDefault {
      adBySlug {
        ...ad
        adCouponGroups {
          ...couponGroupConnection
        }
        adImages {
          ...adImageConnection
        }
        adDisplayCollections {
          ...displayCollectionConnection
        }
        mainImage {
          ...adImage
        }
        couponGroups {
          ...couponGroupConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.adImage}
    ${DEFAULT_FRAGMENTS.adImageConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayCollectionConnection}
  `,
  ADS: gql`
    query adsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      ads(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...ad
            adCouponGroups {
              ...couponGroupConnection
            }
            adImages {
              ...adImageConnection
            }
            adDisplayCollections {
              ...displayCollectionConnection
            }
            mainImage {
              ...adImage
            }
            couponGroups {
              ...couponGroupConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.adImage}
    ${DEFAULT_FRAGMENTS.adImageConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayCollectionConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  ADS_DEAL100: gql`
    query adsDeal100Default($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      adsDeal100(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...ad
            adCouponGroups {
              ...couponGroupConnection
            }
            adImages {
              ...adImageConnection
            }
            adDisplayCollections {
              ...displayCollectionConnection
            }
            mainImage {
              ...adImage
            }
            couponGroups {
              ...couponGroupConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.adImage}
    ${DEFAULT_FRAGMENTS.adImageConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayCollectionConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  DISPLAY_COLLECTION: gql`
    query displayCollectionDefault($id: ID!) {
      displayCollection(id: $id) {
        ...displayCollection
        ad {
          ...ad
        }
        displayCollectionDisplayProductInfos {
          ...displayProductInfoConnection
        }
        displayProductInfos {
          ...displayProductInfoConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
  `,
  DISPLAY_COLLECTIONS: gql`
    query displayCollectionsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      displayCollections(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...displayCollection
            ad {
              ...ad
            }
            displayCollectionDisplayProductInfos {
              ...displayProductInfoConnection
            }
            displayProductInfos {
              ...displayProductInfoConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.ad}
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  DISPLAY_PRODUCT_INFO: gql`
    query displayProductInfoDefault($id: ID!) {
      displayProductInfo(id: $id) {
        ...displayProductInfo
        product {
          ...product
        }
        displayCollection {
          ...displayCollection
        }
        productpromotion {
          ...productPromotion
        }
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  DISPLAY_PRODUCT_INFOS: gql`
    query displayProductInfosDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      displayProductInfos(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...displayProductInfo
            product {
              ...product
            }
            displayCollection {
              ...displayCollection
            }
            productpromotion {
              ...productPromotion
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  PRODUCT_PROMOTION: gql`
    query productPromotionDefault($id: ID!) {
      productPromotion(id: $id) {
        ...productPromotion
        product {
          ...product
        }
        displayProductInfo {
          ...displayProductInfo
        }
        productPromotionOrderItems {
          ...orderItemConnection
        }
        productPromotionCartItems {
          ...cartItemConnection
        }
        productPromotionCartItemHistories {
          ...cartItemHistoryConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  PRODUCT_PROMOTIONS: gql`
    query productPromotionsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      productPromotions(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...productPromotion
            product {
              ...product
            }
            displayProductInfo {
              ...displayProductInfo
            }
            productPromotionOrderItems {
              ...orderItemConnection
            }
            productPromotionCartItems {
              ...cartItemConnection
            }
            productPromotionCartItemHistories {
              ...cartItemHistoryConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfo}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productPromotion}
  `,
  BANNERS: gql`
    query bannersDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      banners(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...banner
            bannerImages {
              ...bannerImageConnection
            }
            topSlimBannerInfo {
              ...slimBannerInfo
            }
            slimBannerInfo {
              ...slimBannerInfo
            }
            popupBannerInfo {
              ...popupBannerInfo
            }
            topRollBannerInfo {
              ...topRollBannerInfo
            }
            designersBannerInfo {
              ...designersBannerInfo
            }
            weeklyBestBannerInfo {
              ...weeklyBestBannerInfo
            }
            coverflowBannerInfo {
              ...coverflowBannerInfo
            }
            brandRollBannerInfo {
              ...subRollBannerInfo
            }
            apparelCoverflowBannerInfo {
              ...coverflowBannerInfo
            }
            apparelRollBannerInfo {
              ...subRollBannerInfo
            }
            accessoriesCoverflowBannerInfo {
              ...coverflowBannerInfo
            }
            accessoriesRollBannerInfo {
              ...subRollBannerInfo
            }
            homeMiddleBannerInfo {
              ...bannerInfo
            }
            homeBottomBannerInfo {
              ...bannerInfo
            }
            productTopBannerInfo {
              ...bannerInfo
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  BANNER: gql`
    query bannerDefault($id: ID!) {
      banner(id: $id) {
        ...banner
        bannerImages {
          ...bannerImageConnection
        }
        topSlimBannerInfo {
          ...slimBannerInfo
        }
        slimBannerInfo {
          ...slimBannerInfo
        }
        popupBannerInfo {
          ...popupBannerInfo
        }
        topRollBannerInfo {
          ...topRollBannerInfo
        }
        designersBannerInfo {
          ...designersBannerInfo
        }
        weeklyBestBannerInfo {
          ...weeklyBestBannerInfo
        }
        coverflowBannerInfo {
          ...coverflowBannerInfo
        }
        brandRollBannerInfo {
          ...subRollBannerInfo
        }
        apparelCoverflowBannerInfo {
          ...coverflowBannerInfo
        }
        apparelRollBannerInfo {
          ...subRollBannerInfo
        }
        accessoriesCoverflowBannerInfo {
          ...coverflowBannerInfo
        }
        accessoriesRollBannerInfo {
          ...subRollBannerInfo
        }
        homeMiddleBannerInfo {
          ...bannerInfo
        }
        homeBottomBannerInfo {
          ...bannerInfo
        }
        productTopBannerInfo {
          ...bannerInfo
        }
      }
    }
    ${DEFAULT_FRAGMENTS.banner}
    ${DEFAULT_FRAGMENTS.bannerImageConnection}
    ${DEFAULT_FRAGMENTS.bannerInfo}
    ${DEFAULT_FRAGMENTS.coverflowBannerInfo}
    ${DEFAULT_FRAGMENTS.designersBannerInfo}
    ${DEFAULT_FRAGMENTS.popupBannerInfo}
    ${DEFAULT_FRAGMENTS.slimBannerInfo}
    ${DEFAULT_FRAGMENTS.subRollBannerInfo}
    ${DEFAULT_FRAGMENTS.topRollBannerInfo}
    ${DEFAULT_FRAGMENTS.weeklyBestBannerInfo}
  `,
  HOME: gql`
    query homeDefault {
      home {
        ...home
      }
    }
    ${DEFAULT_FRAGMENTS.home}
  `,
  USER_SUBSCRIPTIONS: gql`
    query userSubscriptionsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      userSubscriptions(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...userSubscription
            user {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userSubscription}
  `,
  SELLER_BILLINGS: gql`
    query sellerBillingsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellerBillings(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...sellerBilling
            seller {
              ...seller
            }
            brand {
              ...brand
            }
            sellerBillingItems {
              ...billingItemConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBilling}
  `,
  BILLING_ITEMS: gql`
    query billingItemsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      billingItems(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...billingItem
            orderItem {
              ...orderItem
            }
            returnInfo {
              ...returnInfo
            }
            shipping {
              ...shipping
            }
            refundShippingInfo {
              ...refundShippingInfo
            }
            sellerBilling {
              ...sellerBilling
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.billingItem}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.sellerBilling}
    ${DEFAULT_FRAGMENTS.shipping}
  `,
  TRANSACTIONS: gql`
    query transactionsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      transactions(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...transaction
            order {
              ...order
            }
            fitpetAdmin {
              ...user
            }
            approvedTransactionOrder {
              ...order
            }
            transactionCancelTransactions {
              ...cancelTransactionConnection
            }
            cancelTransactions {
              ...cancelTransactionConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.user}
  `,
  TRANSACTION: gql`
    query transactionDefault($id: ID!) {
      transaction(id: $id) {
        ...transaction
        order {
          ...order
        }
        fitpetAdmin {
          ...user
        }
        approvedTransactionOrder {
          ...order
        }
        transactionCancelTransactions {
          ...cancelTransactionConnection
        }
        cancelTransactions {
          ...cancelTransactionConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SHIPPINGS: gql`
    query shippingsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      shippings(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...shipping
            shippingPreset {
              ...shippingPreset
            }
            shippingCompany {
              ...shippingCompany
            }
            shippingMileageHistories {
              ...mileageHistoryConnection
            }
            shippingCoupons {
              ...couponConnection
            }
            shippingOrderItems {
              ...orderItemConnection
            }
            shippingReturnInfos {
              ...returnInfoConnection
            }
            shippingRefundShippingInfos {
              ...refundShippingInfoConnection
            }
            billingitemSet {
              ...billingItemConnection
            }
            availableCoupons {
              ...couponConnection
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfoConnection}
    ${DEFAULT_FRAGMENTS.returnInfoConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  SHIPPING: gql`
    query shippingDefault($id: ID!) {
      shipping(id: $id) {
        ...shipping
        shippingPreset {
          ...shippingPreset
        }
        shippingCompany {
          ...shippingCompany
        }
        shippingMileageHistories {
          ...mileageHistoryConnection
        }
        shippingCoupons {
          ...couponConnection
        }
        shippingOrderItems {
          ...orderItemConnection
        }
        shippingReturnInfos {
          ...returnInfoConnection
        }
        shippingRefundShippingInfos {
          ...refundShippingInfoConnection
        }
        billingitemSet {
          ...billingItemConnection
        }
        availableCoupons {
          ...couponConnection
        }
        seller {
          ...seller
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.refundShippingInfoConnection}
    ${DEFAULT_FRAGMENTS.returnInfoConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  SHIPPING_COMPANIES: gql`
    query shippingCompaniesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      shippingCompanies(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...shippingCompany
            shippingCompanyShippings {
              ...shippingConnection
            }
            shippingCompanyPresets {
              ...shippingPresetConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
  `,
  SHIPPING_PRESETS: gql`
    query shippingPresetsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      shippingPresets(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...shippingPreset
            seller {
              ...seller
            }
            shippingCompany {
              ...shippingCompany
            }
            warehouseAddress {
              ...sellerAddress
            }
            returnAddress {
              ...sellerAddress
            }
            shippingPresetProducts {
              ...productConnection
            }
            shippingPresetOrderItems {
              ...shippingConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  ORDERS: gql`
    query ordersDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      orders(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...order
            user {
              ...user
            }
            device {
              ...device
            }
            customerAddress {
              ...customerAddress
            }
            approvedTransaction {
              ...transaction
            }
            orderMileageHistories {
              ...mileageHistoryConnection
            }
            orderCoupons {
              ...couponConnection
            }
            orderOrderItems {
              ...orderItemConnection
            }
            orderTransactions {
              ...transactionConnection
            }
            orderCancelTransactions {
              ...cancelTransactionConnection
            }
            orderRefundInfo {
              ...orderRefundInfo
            }
            shippings {
              ...shippingConnection
            }
            availableCoupons {
              ...couponConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.orderRefundInfo}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ORDER: gql`
    query orderDefault($id: ID!) {
      order(id: $id) {
        ...order
        user {
          ...user
        }
        device {
          ...device
        }
        customerAddress {
          ...customerAddress
        }
        approvedTransaction {
          ...transaction
        }
        orderMileageHistories {
          ...mileageHistoryConnection
        }
        orderCoupons {
          ...couponConnection
        }
        orderOrderItems {
          ...orderItemConnection
        }
        orderTransactions {
          ...transactionConnection
        }
        orderCancelTransactions {
          ...cancelTransactionConnection
        }
        orderRefundInfo {
          ...orderRefundInfo
        }
        shippings {
          ...shippingConnection
        }
        availableCoupons {
          ...couponConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.orderRefundInfo}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ORDER_ITEMS: gql`
    query orderItemsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      orderItems(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...orderItem
            order {
              ...order
            }
            seller {
              ...seller
            }
            brand {
              ...brand
            }
            productOption {
              ...productOption
            }
            shipping {
              ...shipping
            }
            productPromotion {
              ...productPromotion
            }
            exchangedOrderItem {
              ...orderItem
            }
            refundInfo {
              ...refundInfo
            }
            refundShippingInfo {
              ...refundShippingInfo
            }
            returnInfo {
              ...returnInfo
            }
            orderItemServiceQnas {
              ...serviceQnaConnection
            }
            orderItemReviews {
              ...reviewConnection
            }
            orderItemMileageHistories {
              ...mileageHistoryConnection
            }
            orderItemCoupons {
              ...couponConnection
            }
            orderItemOrderItemAdminNotes {
              ...orderItemAdminNoteConnection
            }
            orderitem {
              ...orderItem
            }
            canceltransaction {
              ...cancelTransaction
            }
            billingitemSet {
              ...billingItemConnection
            }
            productImage {
              ...productImage
            }
            returnAddress {
              ...sellerAddress
            }
            approvedTransaction {
              ...transaction
            }
            approvedCancelTransaction {
              ...cancelTransaction
            }
            adminNotes {
              ...orderItemAdminNoteConnection
            }
            availableCoupons {
              ...couponConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  ORDER_ITEM: gql`
    query orderItemDefault($id: ID!) {
      orderItem(id: $id) {
        ...orderItem
        order {
          ...order
        }
        seller {
          ...seller
        }
        brand {
          ...brand
        }
        productOption {
          ...productOption
        }
        shipping {
          ...shipping
        }
        productPromotion {
          ...productPromotion
        }
        exchangedOrderItem {
          ...orderItem
        }
        refundInfo {
          ...refundInfo
        }
        refundShippingInfo {
          ...refundShippingInfo
        }
        returnInfo {
          ...returnInfo
        }
        orderItemServiceQnas {
          ...serviceQnaConnection
        }
        orderItemReviews {
          ...reviewConnection
        }
        orderItemMileageHistories {
          ...mileageHistoryConnection
        }
        orderItemCoupons {
          ...couponConnection
        }
        orderItemOrderItemAdminNotes {
          ...orderItemAdminNoteConnection
        }
        orderitem {
          ...orderItem
        }
        canceltransaction {
          ...cancelTransaction
        }
        billingitemSet {
          ...billingItemConnection
        }
        productImage {
          ...productImage
        }
        returnAddress {
          ...sellerAddress
        }
        approvedTransaction {
          ...transaction
        }
        approvedCancelTransaction {
          ...cancelTransaction
        }
        adminNotes {
          ...orderItemAdminNoteConnection
        }
        availableCoupons {
          ...couponConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  ORDER_ITEM_COUNT: gql`
    query orderItemCountDefault {
      orderItemCount {
        ...orderItemCount
      }
    }
    ${DEFAULT_FRAGMENTS.orderItemCount}
  `,
  NON_USER_ORDER: gql`
    query nonUserOrderDefault {
      nonUserOrder {
        ...order
        user {
          ...user
        }
        device {
          ...device
        }
        customerAddress {
          ...customerAddress
        }
        approvedTransaction {
          ...transaction
        }
        orderMileageHistories {
          ...mileageHistoryConnection
        }
        orderCoupons {
          ...couponConnection
        }
        orderOrderItems {
          ...orderItemConnection
        }
        orderTransactions {
          ...transactionConnection
        }
        orderCancelTransactions {
          ...cancelTransactionConnection
        }
        orderRefundInfo {
          ...orderRefundInfo
        }
        shippings {
          ...shippingConnection
        }
        availableCoupons {
          ...couponConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.cancelTransactionConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.orderRefundInfo}
    ${DEFAULT_FRAGMENTS.shippingConnection}
    ${DEFAULT_FRAGMENTS.transaction}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SHIPPING_TRACK: gql`
    query shippingTrackDefault {
      shippingTrack {
        ...shippingTrack
        shipping {
          ...shipping
        }
      }
    }
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.shippingTrack}
  `,
  REVIEW_WRITABLE_ORDER_ITEMS: gql`
    query reviewWritableOrderItemsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      reviewWritableOrderItems(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...orderItem
            order {
              ...order
            }
            seller {
              ...seller
            }
            brand {
              ...brand
            }
            productOption {
              ...productOption
            }
            shipping {
              ...shipping
            }
            productPromotion {
              ...productPromotion
            }
            exchangedOrderItem {
              ...orderItem
            }
            refundInfo {
              ...refundInfo
            }
            refundShippingInfo {
              ...refundShippingInfo
            }
            returnInfo {
              ...returnInfo
            }
            orderItemServiceQnas {
              ...serviceQnaConnection
            }
            orderItemReviews {
              ...reviewConnection
            }
            orderItemMileageHistories {
              ...mileageHistoryConnection
            }
            orderItemCoupons {
              ...couponConnection
            }
            orderItemOrderItemAdminNotes {
              ...orderItemAdminNoteConnection
            }
            orderitem {
              ...orderItem
            }
            canceltransaction {
              ...cancelTransaction
            }
            billingitemSet {
              ...billingItemConnection
            }
            productImage {
              ...productImage
            }
            returnAddress {
              ...sellerAddress
            }
            approvedTransaction {
              ...transaction
            }
            approvedCancelTransaction {
              ...cancelTransaction
            }
            adminNotes {
              ...orderItemAdminNoteConnection
            }
            availableCoupons {
              ...couponConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.billingItemConnection}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.cancelTransaction}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.refundInfo}
    ${DEFAULT_FRAGMENTS.refundShippingInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.transaction}
  `,
  REMOTE_AREA: gql`
    query remoteAreaDefault($id: ID!) {
      remoteArea(id: $id) {
        ...remoteArea
      }
    }
    ${DEFAULT_FRAGMENTS.remoteArea}
  `,
  REMOTE_AREAS: gql`
    query remoteAreasDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      remoteAreas(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...remoteArea
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.remoteArea}
  `,
  PRODUCT_QNA: gql`
    query productQnaDefault($id: ID!) {
      productQna(id: $id) {
        ...productQna
        product {
          ...product
        }
        productOption {
          ...productOption
        }
        user {
          ...user
        }
        answeredUser {
          ...user
        }
        seller {
          ...seller
        }
      }
    }
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productQna}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
  `,
  PRODUCT_QNAS: gql`
    query productQnasDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      productQnas(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...productQna
            product {
              ...product
            }
            productOption {
              ...productOption
            }
            user {
              ...user
            }
            answeredUser {
              ...user
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productQna}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SERVICE_QNA: gql`
    query serviceQnaDefault($id: ID!) {
      serviceQna(id: $id) {
        ...serviceQna
        orderItem {
          ...orderItem
        }
        user {
          ...user
        }
        answeredUser {
          ...user
        }
        serviceQnaImages {
          ...serviceQnaImageConnection
        }
        seller {
          ...seller
        }
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SERVICE_QNAS: gql`
    query serviceQnasDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      serviceQnas(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...serviceQna
            orderItem {
              ...orderItem
            }
            user {
              ...user
            }
            answeredUser {
              ...user
            }
            serviceQnaImages {
              ...serviceQnaImageConnection
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SERVICE_QNAS_BY_ADMIN: gql`
    query serviceQnasByAdminDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      serviceQnasByAdmin(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...serviceQna
            orderItem {
              ...orderItem
            }
            user {
              ...user
            }
            answeredUser {
              ...user
            }
            serviceQnaImages {
              ...serviceQnaImageConnection
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ONE_ON_ONE_QNAS: gql`
    query oneOnOneQnasDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      oneOnOneQnas(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...serviceQna
            orderItem {
              ...orderItem
            }
            user {
              ...user
            }
            answeredUser {
              ...user
            }
            serviceQnaImages {
              ...serviceQnaImageConnection
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ONE_ON_ONE_QNAS_BY_ADMIN: gql`
    query oneOnOneQnasByAdminDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      oneOnOneQnasByAdmin(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...serviceQna
            orderItem {
              ...orderItem
            }
            user {
              ...user
            }
            answeredUser {
              ...user
            }
            serviceQnaImages {
              ...serviceQnaImageConnection
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ORDER_QNAS: gql`
    query orderQnasDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      orderQnas(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...serviceQna
            orderItem {
              ...orderItem
            }
            user {
              ...user
            }
            answeredUser {
              ...user
            }
            serviceQnaImages {
              ...serviceQnaImageConnection
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ORDER_QNAS_BY_SELLER: gql`
    query orderQnasBySellerDefault($id: ID!, $pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      orderQnasBySeller(id: $id, pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...serviceQna
            orderItem {
              ...orderItem
            }
            user {
              ...user
            }
            answeredUser {
              ...user
            }
            serviceQnaImages {
              ...serviceQnaImageConnection
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ORDER_QNAS_BY_ADMIN: gql`
    query orderQnasByAdminDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      orderQnasByAdmin(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...serviceQna
            orderItem {
              ...orderItem
            }
            user {
              ...user
            }
            answeredUser {
              ...user
            }
            serviceQnaImages {
              ...serviceQnaImageConnection
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.serviceQna}
    ${DEFAULT_FRAGMENTS.serviceQnaImageConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  REVIEW: gql`
    query reviewDefault($id: ID!) {
      review(id: $id) {
        ...review
        user {
          ...user
        }
        product {
          ...product
        }
        orderItem {
          ...orderItem
        }
        answeredUser {
          ...user
        }
        likedUsers {
          ...userConnection
        }
        reviewReviewImages {
          ...reviewImageConnection
        }
        reviewMileageHistories {
          ...mileageHistoryConnection
        }
        seller {
          ...seller
        }
      }
    }
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.review}
    ${DEFAULT_FRAGMENTS.reviewImageConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  REVIEWS: gql`
    query reviewsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      reviews(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...review
            user {
              ...user
            }
            product {
              ...product
            }
            orderItem {
              ...orderItem
            }
            answeredUser {
              ...user
            }
            likedUsers {
              ...userConnection
            }
            reviewReviewImages {
              ...reviewImageConnection
            }
            reviewMileageHistories {
              ...mileageHistoryConnection
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.review}
    ${DEFAULT_FRAGMENTS.reviewImageConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  REVIEW_GROUPS: gql`
    query reviewGroupsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      reviewGroups(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...reviewGroup
            reviewGroupProducts {
              ...productConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
  `,
  REVIEW_GROUPS_BY_SELLER: gql`
    query reviewGroupsBySellerDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      reviewGroupsBySeller(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...reviewGroup
            reviewGroupProducts {
              ...productConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
  `,
  REVIEW_IMAGES: gql`
    query reviewImagesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      reviewImages(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...reviewImage
            review {
              ...review
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.review}
    ${DEFAULT_FRAGMENTS.reviewImage}
  `,
  FITPET_LINKS: gql`
    query fitpetLinksDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      fitpetLinks(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...fitpetLink
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.fitpetLink}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  SERVER_CONFIGS: gql`
    query serverConfigsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      serverConfigs(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...serverConfig
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.serverConfig}
  `,
  EXCEL_EXPORT_REQUESTS: gql`
    query excelExportRequestsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      excelExportRequests(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...excelExportRequest
            requestUser {
              ...user
            }
            requestSeller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.excelExportRequest}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.user}
  `,
  MIN_APP_VERSION: gql`
    query minAppVersionDefault {
      minAppVersion {
        ...minAppVersion
      }
    }
    ${DEFAULT_FRAGMENTS.minAppVersion}
  `,
  CHECK_TOKEN_ASSOCIATION: gql`
    query checkTokenAssociationDefault($filter: GenericScalar) {
      checkTokenAssociation(filter: $filter) {
        ...checkTokenAssociation
      }
    }
    ${DEFAULT_FRAGMENTS.checkTokenAssociation}
  `,
  DEFAULT_RETURN_EXCHANGE_GUIDE: gql`
    query defaultReturnExchangeGuideDefault {
      defaultReturnExchangeGuide {
        ...defaultReturnChangeGuide
      }
    }
    ${DEFAULT_FRAGMENTS.defaultReturnChangeGuide}
  `,
  PRODUCT: gql`
    query productDefault($id: ID!) {
      product(id: $id) {
        ...product
        originalProduct {
          ...product
        }
        reviewGroup {
          ...reviewGroup
        }
        asInfo {
          ...asInfo
        }
        categories {
          ...categoryConnection
        }
        seller {
          ...seller
        }
        brand {
          ...brand
        }
        status {
          ...status
        }
        updateInfo {
          ...updatedProduct
        }
        updateStatus {
          ...status
        }
        defaultProductOption {
          ...productOption
        }
        shippingPreset {
          ...shippingPreset
        }
        wishedProductUsers {
          ...userConnection
        }
        productQnas {
          ...productQnaConnection
        }
        productReviews {
          ...reviewConnection
        }
        productProductCategories {
          ...productCategoryConnection
        }
        copiedProduct {
          ...productConnection
        }
        productProductImages {
          ...productImageConnection
        }
        productProductOptions {
          ...productOptionConnection
        }
        productInfluencers {
          ...influencerConnection
        }
        includedProductsCouponGroups {
          ...couponGroupConnection
        }
        excludedProductsCouponGroups {
          ...couponGroupConnection
        }
        displayproductinfoSet {
          ...displayProductInfoConnection
        }
        productProductPromotions {
          ...productPromotionConnection
        }
        reviews {
          ...reviewConnection
        }
        productPromotion {
          ...productPromotion
        }
        downloadCouponGroups {
          ...couponGroupConnection
        }
        productCoupons {
          ...couponConnection
        }
        brandCoupons {
          ...couponConnection
        }
        categoryCoupons {
          ...couponConnection
        }
        mainImage {
          ...productImage
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  PRODUCTS: gql`
    query productsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      products(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...product
            originalProduct {
              ...product
            }
            reviewGroup {
              ...reviewGroup
            }
            asInfo {
              ...asInfo
            }
            categories {
              ...categoryConnection
            }
            seller {
              ...seller
            }
            brand {
              ...brand
            }
            status {
              ...status
            }
            updateInfo {
              ...updatedProduct
            }
            updateStatus {
              ...status
            }
            defaultProductOption {
              ...productOption
            }
            shippingPreset {
              ...shippingPreset
            }
            wishedProductUsers {
              ...userConnection
            }
            productQnas {
              ...productQnaConnection
            }
            productReviews {
              ...reviewConnection
            }
            productProductCategories {
              ...productCategoryConnection
            }
            copiedProduct {
              ...productConnection
            }
            productProductImages {
              ...productImageConnection
            }
            productProductOptions {
              ...productOptionConnection
            }
            productInfluencers {
              ...influencerConnection
            }
            includedProductsCouponGroups {
              ...couponGroupConnection
            }
            excludedProductsCouponGroups {
              ...couponGroupConnection
            }
            displayproductinfoSet {
              ...displayProductInfoConnection
            }
            productProductPromotions {
              ...productPromotionConnection
            }
            reviews {
              ...reviewConnection
            }
            productPromotion {
              ...productPromotion
            }
            downloadCouponGroups {
              ...couponGroupConnection
            }
            productCoupons {
              ...couponConnection
            }
            brandCoupons {
              ...couponConnection
            }
            categoryCoupons {
              ...couponConnection
            }
            mainImage {
              ...productImage
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  RECOMMENDED_PRODUCTS: gql`
    query recommendedProductsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      recommendedProducts(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...product
            originalProduct {
              ...product
            }
            reviewGroup {
              ...reviewGroup
            }
            asInfo {
              ...asInfo
            }
            categories {
              ...categoryConnection
            }
            seller {
              ...seller
            }
            brand {
              ...brand
            }
            status {
              ...status
            }
            updateInfo {
              ...updatedProduct
            }
            updateStatus {
              ...status
            }
            defaultProductOption {
              ...productOption
            }
            shippingPreset {
              ...shippingPreset
            }
            wishedProductUsers {
              ...userConnection
            }
            productQnas {
              ...productQnaConnection
            }
            productReviews {
              ...reviewConnection
            }
            productProductCategories {
              ...productCategoryConnection
            }
            copiedProduct {
              ...productConnection
            }
            productProductImages {
              ...productImageConnection
            }
            productProductOptions {
              ...productOptionConnection
            }
            productInfluencers {
              ...influencerConnection
            }
            includedProductsCouponGroups {
              ...couponGroupConnection
            }
            excludedProductsCouponGroups {
              ...couponGroupConnection
            }
            displayproductinfoSet {
              ...displayProductInfoConnection
            }
            productProductPromotions {
              ...productPromotionConnection
            }
            reviews {
              ...reviewConnection
            }
            productPromotion {
              ...productPromotion
            }
            downloadCouponGroups {
              ...couponGroupConnection
            }
            productCoupons {
              ...couponConnection
            }
            brandCoupons {
              ...couponConnection
            }
            categoryCoupons {
              ...couponConnection
            }
            mainImage {
              ...productImage
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  PRODUCT_IMAGES: gql`
    query productImagesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      productImages(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...productImage
            product {
              ...product
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productImage}
  `,
  PRODUCT_IMAGE: gql`
    query productImageDefault($id: ID!) {
      productImage(id: $id) {
        ...productImage
        product {
          ...product
        }
      }
    }
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productImage}
  `,
  PRODUCT_COUNT: gql`
    query productCountDefault {
      productCount {
        ...productStatusCount
      }
    }
    ${DEFAULT_FRAGMENTS.productStatusCount}
  `,
  CATEGORIES: gql`
    query categoriesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      categories(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...category
            parentCategory {
              ...category
            }
            categoryChildren {
              ...categoryConnection
            }
            categoryProductCategories {
              ...productCategoryConnection
            }
            products {
              ...productConnection
            }
            includedCategoriesCouponGroups {
              ...couponGroupConnection
            }
            excludedCategoriesCouponGroups {
              ...couponGroupConnection
            }
            coupons {
              ...couponConnection
            }
            includedBrands {
              ...brandConnection
            }
            subCategories {
              ...categoryConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.category}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
  `,
  CATEGORY: gql`
    query categoryDefault($id: ID!) {
      category(id: $id) {
        ...category
        parentCategory {
          ...category
        }
        categoryChildren {
          ...categoryConnection
        }
        categoryProductCategories {
          ...productCategoryConnection
        }
        products {
          ...productConnection
        }
        includedCategoriesCouponGroups {
          ...couponGroupConnection
        }
        excludedCategoriesCouponGroups {
          ...couponGroupConnection
        }
        coupons {
          ...couponConnection
        }
        includedBrands {
          ...brandConnection
        }
        subCategories {
          ...categoryConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.category}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
  `,
  PRODUCT_OPTION_VALUES: gql`
    query productOptionValuesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      productOptionValues(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...productOptionValue
            productOptionGroup {
              ...productOptionGroup
            }
            productOptions {
              ...productOptionConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productOptionGroup}
    ${DEFAULT_FRAGMENTS.productOptionValue}
  `,
  PRODUCT_OPTION_VALUE: gql`
    query productOptionValueDefault {
      productOptionValue {
        ...productOptionValue
        productOptionGroup {
          ...productOptionGroup
        }
        productOptions {
          ...productOptionConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productOptionGroup}
    ${DEFAULT_FRAGMENTS.productOptionValue}
  `,
  PRODUCT_OPTIONS: gql`
    query productOptionsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      productOptions(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...productOption
            product {
              ...product
            }
            productOptionValues {
              ...productOptionValueConnection
            }
            skus {
              ...skuConnection
            }
            productOptionReviews {
              ...productQnaConnection
            }
            relatedProduct {
              ...product
            }
            productOptionProductOptionSkus {
              ...productOptionSkuConnection
            }
            productOptionOrderItems {
              ...orderItemConnection
            }
            productOptionCartItems {
              ...cartItemConnection
            }
            productOptionCartItemHistories {
              ...cartItemHistoryConnection
            }
            productOptionKinds {
              ...productOptionKind
            }
            productOptionSkus {
              ...productOptionSkuConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionKind}
    ${DEFAULT_FRAGMENTS.productOptionSkuConnection}
    ${DEFAULT_FRAGMENTS.productOptionValueConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
  `,
  AS_INFOS: gql`
    query asInfosDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      asInfos(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...asInfo
            seller {
              ...seller
            }
            productSet {
              ...productConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
  `,
  BRAND: gql`
    query brandDefault($id: ID!) {
      brand(id: $id) {
        ...brand
        sellers {
          ...sellerConnection
        }
        status {
          ...status
        }
        updateInfo {
          ...updatedBrand
        }
        updateStatus {
          ...status
        }
        wishedBrandUsers {
          ...userConnection
        }
        brandProducts {
          ...productConnection
        }
        includedBrandsCouponGroups {
          ...couponGroupConnection
        }
        excludedBrandsCouponGroups {
          ...couponGroupConnection
        }
        brandOrderItems {
          ...orderItemConnection
        }
        brandBillings {
          ...sellerBillingConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedBrand}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  BRANDS: gql`
    query brandsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      brands(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...brand
            sellers {
              ...sellerConnection
            }
            status {
              ...status
            }
            updateInfo {
              ...updatedBrand
            }
            updateStatus {
              ...status
            }
            wishedBrandUsers {
              ...userConnection
            }
            brandProducts {
              ...productConnection
            }
            includedBrandsCouponGroups {
              ...couponGroupConnection
            }
            excludedBrandsCouponGroups {
              ...couponGroupConnection
            }
            brandOrderItems {
              ...orderItemConnection
            }
            brandBillings {
              ...sellerBillingConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedBrand}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  POPULAR_SEARCH_TERMS: gql`
    query popularSearchTermsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      popularSearchTerms(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...popularSearchTerm
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.popularSearchTerm}
  `,
  INCOMPLETE_PRODUCTS_BY_ROLE: gql`
    query incompleteProductsByRoleDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      incompleteProductsByRole(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...product
            originalProduct {
              ...product
            }
            reviewGroup {
              ...reviewGroup
            }
            asInfo {
              ...asInfo
            }
            categories {
              ...categoryConnection
            }
            seller {
              ...seller
            }
            brand {
              ...brand
            }
            status {
              ...status
            }
            updateInfo {
              ...updatedProduct
            }
            updateStatus {
              ...status
            }
            defaultProductOption {
              ...productOption
            }
            shippingPreset {
              ...shippingPreset
            }
            wishedProductUsers {
              ...userConnection
            }
            productQnas {
              ...productQnaConnection
            }
            productReviews {
              ...reviewConnection
            }
            productProductCategories {
              ...productCategoryConnection
            }
            copiedProduct {
              ...productConnection
            }
            productProductImages {
              ...productImageConnection
            }
            productProductOptions {
              ...productOptionConnection
            }
            productInfluencers {
              ...influencerConnection
            }
            includedProductsCouponGroups {
              ...couponGroupConnection
            }
            excludedProductsCouponGroups {
              ...couponGroupConnection
            }
            displayproductinfoSet {
              ...displayProductInfoConnection
            }
            productProductPromotions {
              ...productPromotionConnection
            }
            reviews {
              ...reviewConnection
            }
            productPromotion {
              ...productPromotion
            }
            downloadCouponGroups {
              ...couponGroupConnection
            }
            productCoupons {
              ...couponConnection
            }
            brandCoupons {
              ...couponConnection
            }
            categoryCoupons {
              ...couponConnection
            }
            mainImage {
              ...productImage
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  CACHE_PRODUCTS: gql`
    query cacheProductsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      cacheProducts(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        ...cacheProduct
      }
    }
    ${DEFAULT_FRAGMENTS.cacheProduct}
  `,
  SEARCHED_PRODUCTS: gql`
    query searchedProductsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      searchedProducts(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...product
            originalProduct {
              ...product
            }
            reviewGroup {
              ...reviewGroup
            }
            asInfo {
              ...asInfo
            }
            categories {
              ...categoryConnection
            }
            seller {
              ...seller
            }
            brand {
              ...brand
            }
            status {
              ...status
            }
            updateInfo {
              ...updatedProduct
            }
            updateStatus {
              ...status
            }
            defaultProductOption {
              ...productOption
            }
            shippingPreset {
              ...shippingPreset
            }
            wishedProductUsers {
              ...userConnection
            }
            productQnas {
              ...productQnaConnection
            }
            productReviews {
              ...reviewConnection
            }
            productProductCategories {
              ...productCategoryConnection
            }
            copiedProduct {
              ...productConnection
            }
            productProductImages {
              ...productImageConnection
            }
            productProductOptions {
              ...productOptionConnection
            }
            productInfluencers {
              ...influencerConnection
            }
            includedProductsCouponGroups {
              ...couponGroupConnection
            }
            excludedProductsCouponGroups {
              ...couponGroupConnection
            }
            displayproductinfoSet {
              ...displayProductInfoConnection
            }
            productProductPromotions {
              ...productPromotionConnection
            }
            reviews {
              ...reviewConnection
            }
            productPromotion {
              ...productPromotion
            }
            downloadCouponGroups {
              ...couponGroupConnection
            }
            productCoupons {
              ...couponConnection
            }
            brandCoupons {
              ...couponConnection
            }
            categoryCoupons {
              ...couponConnection
            }
            mainImage {
              ...productImage
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  SKUS: gql`
    query skusDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      skus(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...sku
            seller {
              ...seller
            }
            createdUser {
              ...user
            }
            updatedUser {
              ...user
            }
            productOptions {
              ...productOptionConnection
            }
            skuSkuHistories {
              ...skuHistoryConnection
            }
            skuProductOptionSkus {
              ...productOptionSkuConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productOptionSkuConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sku}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SKU_HISTORY: gql`
    query skuHistoryDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      skuHistory(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...skuHistory
            sku {
              ...sku
            }
            fitpetAdmin {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.sku}
    ${DEFAULT_FRAGMENTS.skuHistory}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SKU: gql`
    query skuDefault($id: ID!) {
      sku(id: $id) {
        ...sku
        seller {
          ...seller
        }
        createdUser {
          ...user
        }
        updatedUser {
          ...user
        }
        productOptions {
          ...productOptionConnection
        }
        skuSkuHistories {
          ...skuHistoryConnection
        }
        skuProductOptionSkus {
          ...productOptionSkuConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productOptionSkuConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sku}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SEARCH_HISTORIES: gql`
    query searchHistoriesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      searchHistories(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...searchHistory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.searchHistory}
  `,
  USER: gql`
    query userDefault($id: ID!) {
      user(id: $id) {
        ...user
        influencer {
          ...influencer
        }
        grade {
          ...customerGrade
        }
        mileage {
          ...mileage
        }
        inviteUser {
          ...user
        }
        wishedProducts {
          ...productConnection
        }
        wishedBrands {
          ...brandConnection
        }
        invitedUser {
          ...userConnection
        }
        inactiveuser {
          ...inactiveUser
        }
        userProductQnas {
          ...productQnaConnection
        }
        answeredUserProductQnas {
          ...productQnaConnection
        }
        userServiceQnas {
          ...serviceQnaConnection
        }
        answeredUserServiceQnas {
          ...serviceQnaConnection
        }
        userReviews {
          ...reviewConnection
        }
        answeredUserReviews {
          ...reviewConnection
        }
        likedReviews {
          ...reviewConnection
        }
        createdUserSkus {
          ...skuConnection
        }
        updatedUserSkus {
          ...skuConnection
        }
        fitpetAdminSkuHistories {
          ...skuHistoryConnection
        }
        sellers {
          ...sellerConnection
        }
        userSellerUsers {
          ...sellerUserConnection
        }
        userDevices {
          ...deviceConnection
        }
        requestUserStatusHistories {
          ...statusHistoryConnection
        }
        fitpetAdminStatusHistories {
          ...statusHistoryConnection
        }
        fitpetAdminMileageHistories {
          ...mileageHistoryConnection
        }
        userCustomerTemplateAddresses {
          ...customerTemplateAddressConnection
        }
        userSocialServices {
          ...socialServiceConnection
        }
        fitpetAdminInformPushQueues {
          ...informPushQueueConnection
        }
        userPushUserHistories {
          ...pushUserHistoryConnection
        }
        userExcelRequests {
          ...excelExportRequestConnection
        }
        userOrders {
          ...orderConnection
        }
        fitpetAdminCouponGroups {
          ...couponGroupConnection
        }
        userCoupons {
          ...couponConnection
        }
        fitpetAdminCoupons {
          ...couponConnection
        }
        userSubscriptions {
          ...userSubscriptionConnection
        }
        fitpetAdminOrderItemAdminNotes {
          ...orderItemAdminNoteConnection
        }
        fitpetAdminTransactions {
          ...transactionConnection
        }
        adminCustomerGrade {
          ...customerGradeConnection
        }
        userNotice {
          ...noticeConnection
        }
        userFaqs {
          ...faqConnection
        }
        cartItems {
          ...cartItemConnection
        }
        cartItemHistories {
          ...cartItemHistoryConnection
        }
        coupons {
          ...couponConnection
        }
        customerTemplateAddresses {
          ...customerTemplateAddressConnection
        }
        recentOrderCustomerAddress {
          ...customerAddress
        }
        inactiveUser {
          ...inactiveUser
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  USER_WITH_PASSWORD: gql`
    query userWithPasswordDefault {
      userWithPassword {
        ...user
        influencer {
          ...influencer
        }
        grade {
          ...customerGrade
        }
        mileage {
          ...mileage
        }
        inviteUser {
          ...user
        }
        wishedProducts {
          ...productConnection
        }
        wishedBrands {
          ...brandConnection
        }
        invitedUser {
          ...userConnection
        }
        inactiveuser {
          ...inactiveUser
        }
        userProductQnas {
          ...productQnaConnection
        }
        answeredUserProductQnas {
          ...productQnaConnection
        }
        userServiceQnas {
          ...serviceQnaConnection
        }
        answeredUserServiceQnas {
          ...serviceQnaConnection
        }
        userReviews {
          ...reviewConnection
        }
        answeredUserReviews {
          ...reviewConnection
        }
        likedReviews {
          ...reviewConnection
        }
        createdUserSkus {
          ...skuConnection
        }
        updatedUserSkus {
          ...skuConnection
        }
        fitpetAdminSkuHistories {
          ...skuHistoryConnection
        }
        sellers {
          ...sellerConnection
        }
        userSellerUsers {
          ...sellerUserConnection
        }
        userDevices {
          ...deviceConnection
        }
        requestUserStatusHistories {
          ...statusHistoryConnection
        }
        fitpetAdminStatusHistories {
          ...statusHistoryConnection
        }
        fitpetAdminMileageHistories {
          ...mileageHistoryConnection
        }
        userCustomerTemplateAddresses {
          ...customerTemplateAddressConnection
        }
        userSocialServices {
          ...socialServiceConnection
        }
        fitpetAdminInformPushQueues {
          ...informPushQueueConnection
        }
        userPushUserHistories {
          ...pushUserHistoryConnection
        }
        userExcelRequests {
          ...excelExportRequestConnection
        }
        userOrders {
          ...orderConnection
        }
        fitpetAdminCouponGroups {
          ...couponGroupConnection
        }
        userCoupons {
          ...couponConnection
        }
        fitpetAdminCoupons {
          ...couponConnection
        }
        userSubscriptions {
          ...userSubscriptionConnection
        }
        fitpetAdminOrderItemAdminNotes {
          ...orderItemAdminNoteConnection
        }
        fitpetAdminTransactions {
          ...transactionConnection
        }
        adminCustomerGrade {
          ...customerGradeConnection
        }
        userNotice {
          ...noticeConnection
        }
        userFaqs {
          ...faqConnection
        }
        cartItems {
          ...cartItemConnection
        }
        cartItemHistories {
          ...cartItemHistoryConnection
        }
        coupons {
          ...couponConnection
        }
        customerTemplateAddresses {
          ...customerTemplateAddressConnection
        }
        recentOrderCustomerAddress {
          ...customerAddress
        }
        inactiveUser {
          ...inactiveUser
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  USER_WITH_SOCIAL: gql`
    query userWithSocialDefault {
      userWithSocial {
        ...user
        influencer {
          ...influencer
        }
        grade {
          ...customerGrade
        }
        mileage {
          ...mileage
        }
        inviteUser {
          ...user
        }
        wishedProducts {
          ...productConnection
        }
        wishedBrands {
          ...brandConnection
        }
        invitedUser {
          ...userConnection
        }
        inactiveuser {
          ...inactiveUser
        }
        userProductQnas {
          ...productQnaConnection
        }
        answeredUserProductQnas {
          ...productQnaConnection
        }
        userServiceQnas {
          ...serviceQnaConnection
        }
        answeredUserServiceQnas {
          ...serviceQnaConnection
        }
        userReviews {
          ...reviewConnection
        }
        answeredUserReviews {
          ...reviewConnection
        }
        likedReviews {
          ...reviewConnection
        }
        createdUserSkus {
          ...skuConnection
        }
        updatedUserSkus {
          ...skuConnection
        }
        fitpetAdminSkuHistories {
          ...skuHistoryConnection
        }
        sellers {
          ...sellerConnection
        }
        userSellerUsers {
          ...sellerUserConnection
        }
        userDevices {
          ...deviceConnection
        }
        requestUserStatusHistories {
          ...statusHistoryConnection
        }
        fitpetAdminStatusHistories {
          ...statusHistoryConnection
        }
        fitpetAdminMileageHistories {
          ...mileageHistoryConnection
        }
        userCustomerTemplateAddresses {
          ...customerTemplateAddressConnection
        }
        userSocialServices {
          ...socialServiceConnection
        }
        fitpetAdminInformPushQueues {
          ...informPushQueueConnection
        }
        userPushUserHistories {
          ...pushUserHistoryConnection
        }
        userExcelRequests {
          ...excelExportRequestConnection
        }
        userOrders {
          ...orderConnection
        }
        fitpetAdminCouponGroups {
          ...couponGroupConnection
        }
        userCoupons {
          ...couponConnection
        }
        fitpetAdminCoupons {
          ...couponConnection
        }
        userSubscriptions {
          ...userSubscriptionConnection
        }
        fitpetAdminOrderItemAdminNotes {
          ...orderItemAdminNoteConnection
        }
        fitpetAdminTransactions {
          ...transactionConnection
        }
        adminCustomerGrade {
          ...customerGradeConnection
        }
        userNotice {
          ...noticeConnection
        }
        userFaqs {
          ...faqConnection
        }
        cartItems {
          ...cartItemConnection
        }
        cartItemHistories {
          ...cartItemHistoryConnection
        }
        coupons {
          ...couponConnection
        }
        customerTemplateAddresses {
          ...customerTemplateAddressConnection
        }
        recentOrderCustomerAddress {
          ...customerAddress
        }
        inactiveUser {
          ...inactiveUser
        }
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  USERS: gql`
    query usersDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      users(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...user
            influencer {
              ...influencer
            }
            grade {
              ...customerGrade
            }
            mileage {
              ...mileage
            }
            inviteUser {
              ...user
            }
            wishedProducts {
              ...productConnection
            }
            wishedBrands {
              ...brandConnection
            }
            invitedUser {
              ...userConnection
            }
            inactiveuser {
              ...inactiveUser
            }
            userProductQnas {
              ...productQnaConnection
            }
            answeredUserProductQnas {
              ...productQnaConnection
            }
            userServiceQnas {
              ...serviceQnaConnection
            }
            answeredUserServiceQnas {
              ...serviceQnaConnection
            }
            userReviews {
              ...reviewConnection
            }
            answeredUserReviews {
              ...reviewConnection
            }
            likedReviews {
              ...reviewConnection
            }
            createdUserSkus {
              ...skuConnection
            }
            updatedUserSkus {
              ...skuConnection
            }
            fitpetAdminSkuHistories {
              ...skuHistoryConnection
            }
            sellers {
              ...sellerConnection
            }
            userSellerUsers {
              ...sellerUserConnection
            }
            userDevices {
              ...deviceConnection
            }
            requestUserStatusHistories {
              ...statusHistoryConnection
            }
            fitpetAdminStatusHistories {
              ...statusHistoryConnection
            }
            fitpetAdminMileageHistories {
              ...mileageHistoryConnection
            }
            userCustomerTemplateAddresses {
              ...customerTemplateAddressConnection
            }
            userSocialServices {
              ...socialServiceConnection
            }
            fitpetAdminInformPushQueues {
              ...informPushQueueConnection
            }
            userPushUserHistories {
              ...pushUserHistoryConnection
            }
            userExcelRequests {
              ...excelExportRequestConnection
            }
            userOrders {
              ...orderConnection
            }
            fitpetAdminCouponGroups {
              ...couponGroupConnection
            }
            userCoupons {
              ...couponConnection
            }
            fitpetAdminCoupons {
              ...couponConnection
            }
            userSubscriptions {
              ...userSubscriptionConnection
            }
            fitpetAdminOrderItemAdminNotes {
              ...orderItemAdminNoteConnection
            }
            fitpetAdminTransactions {
              ...transactionConnection
            }
            adminCustomerGrade {
              ...customerGradeConnection
            }
            userNotice {
              ...noticeConnection
            }
            userFaqs {
              ...faqConnection
            }
            cartItems {
              ...cartItemConnection
            }
            cartItemHistories {
              ...cartItemHistoryConnection
            }
            coupons {
              ...couponConnection
            }
            customerTemplateAddresses {
              ...customerTemplateAddressConnection
            }
            recentOrderCustomerAddress {
              ...customerAddress
            }
            inactiveUser {
              ...inactiveUser
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.cartItemConnection}
    ${DEFAULT_FRAGMENTS.cartItemHistoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.customerGrade}
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.customerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.deviceConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.faqConnection}
    ${DEFAULT_FRAGMENTS.inactiveUser}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.informPushQueueConnection}
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.noticeConnection}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.orderItemAdminNoteConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.pushUserHistoryConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.serviceQnaConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.skuHistoryConnection}
    ${DEFAULT_FRAGMENTS.socialServiceConnection}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.transactionConnection}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
    ${DEFAULT_FRAGMENTS.userSubscriptionConnection}
  `,
  INFLUENCER: gql`
    query influencerDefault($id: ID!) {
      influencer(id: $id) {
        ...influencer
        address {
          ...influencerAddress
        }
        status {
          ...status
        }
        followingSellers {
          ...sellerConnection
        }
        wishedProducts {
          ...productConnection
        }
        user {
          ...user
        }
        sellerSet {
          ...sellerConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.influencerAddress}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.user}
  `,
  INFLUENCERS: gql`
    query influencersDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      influencers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...influencer
            address {
              ...influencerAddress
            }
            status {
              ...status
            }
            followingSellers {
              ...sellerConnection
            }
            wishedProducts {
              ...productConnection
            }
            user {
              ...user
            }
            sellerSet {
              ...sellerConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.influencerAddress}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.user}
  `,
  STYLE_FITPETERS: gql`
    query styleFitpetersDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      styleFitpeters(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...influencer
            address {
              ...influencerAddress
            }
            status {
              ...status
            }
            followingSellers {
              ...sellerConnection
            }
            wishedProducts {
              ...productConnection
            }
            user {
              ...user
            }
            sellerSet {
              ...sellerConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.influencerAddress}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.user}
  `,
  STYLE_FITPETER: gql`
    query styleFitpeterDefault($id: ID!) {
      styleFitpeter(id: $id) {
        ...influencer
        address {
          ...influencerAddress
        }
        status {
          ...status
        }
        followingSellers {
          ...sellerConnection
        }
        wishedProducts {
          ...productConnection
        }
        user {
          ...user
        }
        sellerSet {
          ...sellerConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.influencerAddress}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.user}
  `,
  INFLUENCER_COUNT: gql`
    query influencerCountDefault {
      influencerCount {
        ...influencerStatusCount
      }
    }
    ${DEFAULT_FRAGMENTS.influencerStatusCount}
  `,
  SELLER: gql`
    query sellerDefault($id: ID!) {
      seller(id: $id) {
        ...seller
        users {
          ...userConnection
        }
        status {
          ...status
        }
        blockedInfluencers {
          ...influencerConnection
        }
        updateInfo {
          ...updatedSeller
        }
        updateStatus {
          ...status
        }
        sellerProducts {
          ...productConnection
        }
        sellerBrands {
          ...brandConnection
        }
        sellerSkus {
          ...skuConnection
        }
        sellerInfluencers {
          ...influencerConnection
        }
        sellerUsers {
          ...sellerUserConnection
        }
        sellerAsInfos {
          ...asInfoConnection
        }
        sellerAddresses {
          ...sellerTemplateAddressConnection
        }
        sellerExcelRequests {
          ...excelExportRequestConnection
        }
        sellerOrderItems {
          ...orderItemConnection
        }
        userShippingPresets {
          ...shippingPresetConnection
        }
        sellerBillings {
          ...sellerBillingConnection
        }
        chiefManager {
          ...user
        }
        sellerTemplateAddresses {
          ...sellerTemplateAddressConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.asInfoConnection}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedSeller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  SELLERS: gql`
    query sellersDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...seller
            users {
              ...userConnection
            }
            status {
              ...status
            }
            blockedInfluencers {
              ...influencerConnection
            }
            updateInfo {
              ...updatedSeller
            }
            updateStatus {
              ...status
            }
            sellerProducts {
              ...productConnection
            }
            sellerBrands {
              ...brandConnection
            }
            sellerSkus {
              ...skuConnection
            }
            sellerInfluencers {
              ...influencerConnection
            }
            sellerUsers {
              ...sellerUserConnection
            }
            sellerAsInfos {
              ...asInfoConnection
            }
            sellerAddresses {
              ...sellerTemplateAddressConnection
            }
            sellerExcelRequests {
              ...excelExportRequestConnection
            }
            sellerOrderItems {
              ...orderItemConnection
            }
            userShippingPresets {
              ...shippingPresetConnection
            }
            sellerBillings {
              ...sellerBillingConnection
            }
            chiefManager {
              ...user
            }
            sellerTemplateAddresses {
              ...sellerTemplateAddressConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.asInfoConnection}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedSeller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  INFLUENCERS_FAVORITE_SELLERS: gql`
    query influencersFavoriteSellersDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      influencersFavoriteSellers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...seller
            users {
              ...userConnection
            }
            status {
              ...status
            }
            blockedInfluencers {
              ...influencerConnection
            }
            updateInfo {
              ...updatedSeller
            }
            updateStatus {
              ...status
            }
            sellerProducts {
              ...productConnection
            }
            sellerBrands {
              ...brandConnection
            }
            sellerSkus {
              ...skuConnection
            }
            sellerInfluencers {
              ...influencerConnection
            }
            sellerUsers {
              ...sellerUserConnection
            }
            sellerAsInfos {
              ...asInfoConnection
            }
            sellerAddresses {
              ...sellerTemplateAddressConnection
            }
            sellerExcelRequests {
              ...excelExportRequestConnection
            }
            sellerOrderItems {
              ...orderItemConnection
            }
            userShippingPresets {
              ...shippingPresetConnection
            }
            sellerBillings {
              ...sellerBillingConnection
            }
            chiefManager {
              ...user
            }
            sellerTemplateAddresses {
              ...sellerTemplateAddressConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.asInfoConnection}
    ${DEFAULT_FRAGMENTS.brandConnection}
    ${DEFAULT_FRAGMENTS.excelExportRequestConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddressConnection}
    ${DEFAULT_FRAGMENTS.sellerUserConnection}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
    ${DEFAULT_FRAGMENTS.skuConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedSeller}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  INFLUENCERS_WISHED_PRODUCTS: gql`
    query influencersWishedProductsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      influencersWishedProducts(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...product
            originalProduct {
              ...product
            }
            reviewGroup {
              ...reviewGroup
            }
            asInfo {
              ...asInfo
            }
            categories {
              ...categoryConnection
            }
            seller {
              ...seller
            }
            brand {
              ...brand
            }
            status {
              ...status
            }
            updateInfo {
              ...updatedProduct
            }
            updateStatus {
              ...status
            }
            defaultProductOption {
              ...productOption
            }
            shippingPreset {
              ...shippingPreset
            }
            wishedProductUsers {
              ...userConnection
            }
            productQnas {
              ...productQnaConnection
            }
            productReviews {
              ...reviewConnection
            }
            productProductCategories {
              ...productCategoryConnection
            }
            copiedProduct {
              ...productConnection
            }
            productProductImages {
              ...productImageConnection
            }
            productProductOptions {
              ...productOptionConnection
            }
            productInfluencers {
              ...influencerConnection
            }
            includedProductsCouponGroups {
              ...couponGroupConnection
            }
            excludedProductsCouponGroups {
              ...couponGroupConnection
            }
            displayproductinfoSet {
              ...displayProductInfoConnection
            }
            productProductPromotions {
              ...productPromotionConnection
            }
            reviews {
              ...reviewConnection
            }
            productPromotion {
              ...productPromotion
            }
            downloadCouponGroups {
              ...couponGroupConnection
            }
            productCoupons {
              ...couponConnection
            }
            brandCoupons {
              ...couponConnection
            }
            categoryCoupons {
              ...couponConnection
            }
            mainImage {
              ...productImage
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  SELLER_COUNT: gql`
    query sellerCountDefault {
      sellerCount {
        ...sellerStatusCount
      }
    }
    ${DEFAULT_FRAGMENTS.sellerStatusCount}
  `,
  SELLER_USERS: gql`
    query sellerUsersDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      sellerUsers(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...sellerUser
            user {
              ...user
            }
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerUser}
    ${DEFAULT_FRAGMENTS.user}
  `,
  DEVICES: gql`
    query devicesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      devices(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...device
            user {
              ...user
            }
            socialService {
              ...socialService
            }
            deviceOrders {
              ...orderConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.device}
    ${DEFAULT_FRAGMENTS.orderConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.socialService}
    ${DEFAULT_FRAGMENTS.user}
  `,
  STATUSES: gql`
    query statusesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      statuses(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...status
            product {
              ...product
            }
            productUpdateStatus {
              ...product
            }
            brand {
              ...brand
            }
            brandUpdateStatus {
              ...brand
            }
            influencer {
              ...influencer
            }
            seller {
              ...seller
            }
            sellerUpdateStatus {
              ...seller
            }
            statusStatusHistories {
              ...statusHistoryConnection
            }
            sellerChiefManager {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.influencer}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistoryConnection}
    ${DEFAULT_FRAGMENTS.user}
  `,
  STATUS_HISTORIES: gql`
    query statusHistoriesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      statusHistories(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...statusHistory
            status {
              ...status
            }
            requestUser {
              ...user
            }
            fitpetAdmin {
              ...user
            }
            sellerChiefManager {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.statusHistory}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SELLER_ADDRESSES: gql`
    query sellerAddressesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      sellerAddresses(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...sellerAddress
            warehouseAddressShippingPreset {
              ...shippingPresetConnection
            }
            returnAddressShippingPreset {
              ...shippingPresetConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.sellerAddress}
    ${DEFAULT_FRAGMENTS.shippingPresetConnection}
  `,
  SELLER_TEMPLATE_ADDRESSES: gql`
    query sellerTemplateAddressesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      sellerTemplateAddresses(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...sellerTemplateAddress
            seller {
              ...seller
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.sellerTemplateAddress}
  `,
  CUSTOMER_ADDRESSES: gql`
    query customerAddressesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      customerAddresses(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...customerAddress
            order {
              ...order
            }
            returninfo {
              ...returnInfo
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.customerAddress}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
  `,
  CUSTOMER_TEMPLATE_ADDRESS: gql`
    query customerTemplateAddressDefault($id: ID!) {
      customerTemplateAddress(id: $id) {
        ...customerTemplateAddress
        user {
          ...user
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
    ${DEFAULT_FRAGMENTS.user}
  `,
  CUSTOMER_TEMPLATE_ADDRESSES: gql`
    query customerTemplateAddressesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      customerTemplateAddresses(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...customerTemplateAddress
            user {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.customerTemplateAddress}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.user}
  `,
  MILEAGES: gql`
    query mileagesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      mileages(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...mileage
            user {
              ...user
            }
            mileageMileageHistories {
              ...mileageHistoryConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistoryConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.user}
  `,
  MILEAGE_HISTORIES: gql`
    query mileageHistoriesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      mileageHistories(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...mileageHistory
            mileage {
              ...mileage
            }
            order {
              ...order
            }
            orderItem {
              ...orderItem
            }
            review {
              ...review
            }
            shipping {
              ...shipping
            }
            returnInfo {
              ...returnInfo
            }
            fitpetAdmin {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.mileage}
    ${DEFAULT_FRAGMENTS.mileageHistory}
    ${DEFAULT_FRAGMENTS.order}
    ${DEFAULT_FRAGMENTS.orderItem}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.returnInfo}
    ${DEFAULT_FRAGMENTS.review}
    ${DEFAULT_FRAGMENTS.shipping}
    ${DEFAULT_FRAGMENTS.user}
  `,
  MILEAGE_POLICY: gql`
    query mileagePolicyDefault {
      mileagePolicy {
        ...mileagePolicy
      }
    }
    ${DEFAULT_FRAGMENTS.mileagePolicy}
  `,
  SMS_QUEUES: gql`
    query smsQueuesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      smsQueues(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...informSmsQueue
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.informSmsQueue}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  EMAIL_QUEUES: gql`
    query emailQueuesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      emailQueues(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...informEmailQueue
            informEmailQueueInformEmailAttachments {
              ...informEmailAttachmentConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.informEmailAttachmentConnection}
    ${DEFAULT_FRAGMENTS.informEmailQueue}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  SMS_HISTORIES: gql`
    query smsHistoriesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      smsHistories(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...informSmsHistory
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.informSmsHistory}
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  WISHED_PRODUCTS: gql`
    query wishedProductsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      wishedProducts(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...product
            originalProduct {
              ...product
            }
            reviewGroup {
              ...reviewGroup
            }
            asInfo {
              ...asInfo
            }
            categories {
              ...categoryConnection
            }
            seller {
              ...seller
            }
            brand {
              ...brand
            }
            status {
              ...status
            }
            updateInfo {
              ...updatedProduct
            }
            updateStatus {
              ...status
            }
            defaultProductOption {
              ...productOption
            }
            shippingPreset {
              ...shippingPreset
            }
            wishedProductUsers {
              ...userConnection
            }
            productQnas {
              ...productQnaConnection
            }
            productReviews {
              ...reviewConnection
            }
            productProductCategories {
              ...productCategoryConnection
            }
            copiedProduct {
              ...productConnection
            }
            productProductImages {
              ...productImageConnection
            }
            productProductOptions {
              ...productOptionConnection
            }
            productInfluencers {
              ...influencerConnection
            }
            includedProductsCouponGroups {
              ...couponGroupConnection
            }
            excludedProductsCouponGroups {
              ...couponGroupConnection
            }
            displayproductinfoSet {
              ...displayProductInfoConnection
            }
            productProductPromotions {
              ...productPromotionConnection
            }
            reviews {
              ...reviewConnection
            }
            productPromotion {
              ...productPromotion
            }
            downloadCouponGroups {
              ...couponGroupConnection
            }
            productCoupons {
              ...couponConnection
            }
            brandCoupons {
              ...couponConnection
            }
            categoryCoupons {
              ...couponConnection
            }
            mainImage {
              ...productImage
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.categoryConnection}
    ${DEFAULT_FRAGMENTS.couponConnection}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.displayProductInfoConnection}
    ${DEFAULT_FRAGMENTS.influencerConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.product}
    ${DEFAULT_FRAGMENTS.productCategoryConnection}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.productImage}
    ${DEFAULT_FRAGMENTS.productImageConnection}
    ${DEFAULT_FRAGMENTS.productOption}
    ${DEFAULT_FRAGMENTS.productOptionConnection}
    ${DEFAULT_FRAGMENTS.productPromotion}
    ${DEFAULT_FRAGMENTS.productPromotionConnection}
    ${DEFAULT_FRAGMENTS.productQnaConnection}
    ${DEFAULT_FRAGMENTS.reviewConnection}
    ${DEFAULT_FRAGMENTS.reviewGroup}
    ${DEFAULT_FRAGMENTS.seller}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedProduct}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  WISHED_BRANDS: gql`
    query wishedBrandsDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      wishedBrands(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...brand
            sellers {
              ...sellerConnection
            }
            status {
              ...status
            }
            updateInfo {
              ...updatedBrand
            }
            updateStatus {
              ...status
            }
            wishedBrandUsers {
              ...userConnection
            }
            brandProducts {
              ...productConnection
            }
            includedBrandsCouponGroups {
              ...couponGroupConnection
            }
            excludedBrandsCouponGroups {
              ...couponGroupConnection
            }
            brandOrderItems {
              ...orderItemConnection
            }
            brandBillings {
              ...sellerBillingConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.brand}
    ${DEFAULT_FRAGMENTS.couponGroupConnection}
    ${DEFAULT_FRAGMENTS.orderItemConnection}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.productConnection}
    ${DEFAULT_FRAGMENTS.sellerBillingConnection}
    ${DEFAULT_FRAGMENTS.sellerConnection}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.updatedBrand}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  LEAVE_REASONS: gql`
    query leaveReasonsDefault {
      leaveReasons {
        ...leaveReason
      }
    }
    ${DEFAULT_FRAGMENTS.leaveReason}
  `,
  SOCIAL_SERVICES: gql`
    query socialServicesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      socialServices(pageInfo: $pageInfo, filter: $filter) {
        edges {
          node {
            ...socialService
            user {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.socialService}
    ${DEFAULT_FRAGMENTS.user}
  `,
  INFORM_PUSH_QUEUE: gql`
    query informPushQueueDefault($id: ID!) {
      informPushQueue(id: $id) {
        ...informPushQueue
        push {
          ...push
        }
        users {
          ...userConnection
        }
        fitpetAdmin {
          ...user
        }
        grades {
          ...customerGradeConnection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informPushQueue}
    ${DEFAULT_FRAGMENTS.push}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  INFORM_PUSH_QUEUES: gql`
    query informPushQueuesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      informPushQueues(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...informPushQueue
            push {
              ...push
            }
            users {
              ...userConnection
            }
            fitpetAdmin {
              ...user
            }
            grades {
              ...customerGradeConnection
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.customerGradeConnection}
    ${DEFAULT_FRAGMENTS.informPushQueue}
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.push}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.userConnection}
  `,
  PUSH_USER_HISTORIES: gql`
    query pushUserHistoriesDefault($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      pushUserHistories(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            ...pushUserHistory
            push {
              ...push
            }
            user {
              ...user
            }
          }
        }
        pageInfo {
          ...pageInfo
        }
        totalCount
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.push}
    ${DEFAULT_FRAGMENTS.pushUserHistory}
    ${DEFAULT_FRAGMENTS.user}
  `,
  ACTIVE_USER_COUNT: gql`
    query activeUserCountDefault {
      activeUserCount {
        ...userCount
      }
    }
    ${DEFAULT_FRAGMENTS.userCount}
  `,
  USER_INFO: gql`
    query userInfoDefault {
      userInfo {
        ...userInfo
        accounts {
          ...userInfoAccount
        }
      }
    }
    ${DEFAULT_FRAGMENTS.userInfo}
    ${DEFAULT_FRAGMENTS.userInfoAccount}
  `,
}

export default DEFAULT_QUERY_GQLS
