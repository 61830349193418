import { FC, useRef, useState, useEffect, MutableRefObject } from 'react'
import { message } from 'antd'
import { useRouter } from 'next/router'

import { MMainTable } from 'components'
import { ITableColumn } from 'components/@atomics/MTable'
import MQuery, { queryParamsProps } from 'components/MQuery'
import { MButton } from 'components/@atomics'
import { IModelNotice } from 'containers/models/modelNotice'
import FormNotice from 'domains/admin/forms/FormNotice'
import tableCols from 'domains/admin/tableCols'
import useModal from 'containers/hooks/useModal'
import { ModalConfirmTable } from 'domains/common/modals'
import { scrollToBottom } from '@libs/utils/utilCommon'

type MainTableSellerNoticeListProps = {
  kind: string[]
}

const tableColumns = (setNoticeDetail: Function): ITableColumn<IModelNotice>[] => {
  return [
    tableCols.common.ID(),
    tableCols.notice.receiverTypeText(),
    tableCols.notice.kindText(),
    tableCols.notice.title({ setNoticeDetail }),
    tableCols.date.createdAtText({ title: '등록일', width: 40 }),
  ]
}

const MainTableSellerNoticeList: FC<MainTableSellerNoticeListProps> = ({ kind }) => {
  const selected = useRef<any[]>([])
  const refetchRef = useRef<Function | null>(null)
  const router = useRouter()
  const [showForm, toggleShowForm] = useState<boolean>(false)
  const [showInitForm, toggleShowInitForm] = useState<boolean>(false)
  const [noticeDetail, setNoticeDetail] = useState<IModelNotice>()
  const noticeId: string | string[] = router.query.noticeId as string
  const onRowSelect = (rows: []) => {
    selected.current = rows
  }
  const queryParams: queryParamsProps = {
    gqlKey: 'partnerNoticeTable',
    dataKey: 'notices',
    variables: {
      filter: { kind: { include: kind } },
      orderBy: '-createdAt',
    },
  }

  useEffect(() => {
    if (noticeId) {
      toggleShowInitForm(true)
      scrollToBottom()
    }
  }, [])

  useEffect(() => {
    if (showForm) {
      toggleShowInitForm(false)
      scrollToBottom()
    }
  }, [showForm])

  useEffect(() => {
    if (noticeDetail) {
      toggleShowForm(true)
      scrollToBottom()
    } else {
      toggleShowForm(false)
    }
  }, [noticeDetail])

  const resetForm = () => {
    setNoticeDetail(undefined)
  }

  const initRegistrationForm = () => {
    resetForm()
    setTimeout(() => {
      toggleShowForm(true)
    })
  }

  const AddButton = () => {
    if (kind && kind?.length > 1) {
      return (
        <MButton
          type="primary"
          onClick={() => {
            initRegistrationForm()
          }}
        >
          공지사항 등록
        </MButton>
      )
    }
    return null
  }

  const DeleteButton = () => {
    const { useModalProps } = useModal()
    return (
      <>
        <MButton
          onClick={() => {
            useModalProps.toggleModal()
            useModalProps.setData({ notices: selected.current })
          }}
        >
          선택 삭제
        </MButton>
        <ModalConfirmTable
          useModalProps={useModalProps}
          fixedProps={{
            mutationKey: {
              gqlKey: 'deleteNotices',
              variableKey: 'noticeDeleteRequests',
            },
            title: '공지사항 삭제',
            description: '해당 공지를 삭제합니다. 삭제된 공지는 복구할 수 없습니다.',
            componentOption: {
              tableComponentKey: 'tableNoticeDefault',
            },
          }}
          onAPISuccess={() => {
            if (refetchRef.current) {
              message.warn('공지가 삭제되었습니다.')
              refetchRef.current()
            }
          }}
        />
      </>
    )
  }

  return (
    <>
      <MMainTable
        columns={tableColumns(setNoticeDetail)}
        TitleSet={{ title: '전체 목록', unit: '건' }}
        queryParams={queryParams}
        scroll={800}
        rowSelectionType="checkbox"
        onRowSelect={onRowSelect}
        HeaderLeftButtons={[<DeleteButton />, <AddButton />]}
        refetchRef={refetchRef}
        sortByIsImportant
      />

      {showInitForm && (
        <MQuery
          queryParams={{
            gqlKey: 'formNotice',
            dataKey: 'notice',
            variables: { id: noticeId },
          }}
        >
          {({ data }) => {
            return (
              <FormNotice
                onSubmitForm={() => refetchRef.current && refetchRef.current()}
                hideForm={() => toggleShowForm(false)}
                refetchRef={refetchRef as MutableRefObject<Function>}
                initialValues={data?.notice.data}
              />
            )
          }}
        </MQuery>
      )}

      {showForm && (
        <FormNotice
          onSubmitForm={() => refetchRef.current && refetchRef.current()}
          hideForm={() => toggleShowForm(false)}
          refetchRef={refetchRef as MutableRefObject<Function>}
          initialValues={noticeDetail}
        />
      )}
    </>
  )
}

export default MainTableSellerNoticeList
