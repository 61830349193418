import { MutableRefObject, useRef } from 'react'
import { MMainTable, MListSortSelect } from 'components'
import { ITableColumn } from 'components/@atomics/MTable'
import tableCols from 'domains/admin/tableCols'
import ROUTES from '@constants/constRoutes'
import { queryParamsProps } from 'components/MQuery'

const tableColumns = (refetchRef: MutableRefObject<Function | null>): Array<ITableColumn<any>> => {
  return [
    tableCols.common.ID(),
    tableCols.seller.sellerShop({ query: { from: ROUTES.PARTNERS.SELLER_REQUEST_LIST } }),
    tableCols.status.approveRequest({
      refetchRef,
      modelKey: 'seller',
      variableKey: 'sellerStatusId',
      descriptionComponentKey: 'descriptionSeller',
    }),
    tableCols.status.rejectRequest({
      refetchRef,
      modelKey: 'seller',
      variableKey: 'sellerRejectRequest',
      descriptionComponentKey: 'descriptionSeller',
    }),
    tableCols.common.baseTextFromModel({ title: '신청자명', dataIndex: 'chiefManager', key: 'name' }),
    tableCols.date.dateText({ title: '신청일', dataIndex: 'requestedAt', key: 'requestedAt' }),
    tableCols.common.baseTextFromModel({ title: '통합매니저 이메일', dataIndex: 'chiefManager', key: 'email' }),
    tableCols.common.baseTextFromModel({ title: '통합매니저 연락처', dataIndex: 'chiefManager', key: 'mobileNumber' }),
  ]
}

const MainTableSellerRequestList = () => {
  const refetchRef = useRef<any>(null)
  const queryParams: queryParamsProps = {
    gqlKey: 'sellerRequestListTable',
    dataKey: 'sellers',
    variables: {
      filter: { status: { include: ['REQUEST'] } },
      orderBy: '-requestedAt',
    },
  }
  return (
    <MMainTable
      TitleSet={{ title: '셀러 신청목록', unit: '명' }}
      columns={tableColumns(refetchRef)}
      HeaderRightButtons={[
        <MListSortSelect filterOptions={['FIELD_SORT_USER_REQUESTED_AT_DESC', 'FIELD_SORT_USER_REQUESTED_AT']} />,
      ]}
      queryParams={queryParams}
      refetchRef={refetchRef}
    />
  )
}

export default MainTableSellerRequestList
