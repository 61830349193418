import { SELLER_SUPPORT_CENTER_URL } from '@constants/constData'
import { MLinkRouterButton } from 'components/@atomics'

const LinkButtonSellerSupport = () => {
  return (
    <MLinkRouterButton href={SELLER_SUPPORT_CENTER_URL} openNewWindow>
      (핏펫 셀러 지원센터 바로가기)
    </MLinkRouterButton>
  )
}

export default LinkButtonSellerSupport
