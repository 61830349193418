import { MModal } from 'components/modals'
import { DomainModalProps } from 'containers/hooks/useModal'
import MMutation from 'components/MMutation'
import { MutationFunction } from '@apollo/client'
import { Form, message, Radio } from 'antd'
import { MFormItemInputNumber, MFormItemWrapper } from 'components'
import { FORM_ITEM_RULES } from '@constants/constForm'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { Store } from 'rc-field-form/es/interface'
import { numberToRate, rateToNumber } from '@libs/utils/utilData'
import useServerConfig from 'containers/hooks/useServerConfig'
import { ModelServerConfig } from 'containers/models'

type ModalMileageBenefitProps = {
  key: string
  description: string
  point: number
  rate: number
}

const ModalMileageBenefit = ({
  useModalProps,
  onAPISuccess,
}: DomainModalProps<ModalMileageBenefitProps, undefined>): JSX.Element | null => {
  const [form] = Form.useForm()
  const { hideModal } = useModalProps
  const { key, description, point, rate } = useModalProps.data
  const { serverConfigs } = useServerConfig('MILEAGE')

  if (!key) return null

  let inputMethod: string
  let inputNumber: number
  if (point) {
    inputMethod = 'point'
    inputNumber = point
  }
  if (rate) {
    inputMethod = 'rate'
    inputNumber = rateToNumber(rate)
  }
  const onFinish = async (values: Store, mutation: MutationFunction) => {
    if (values.inputMethod === 'rate' && (values.inputNumber < 0 || values.inputMethod > 100)) {
      message.error('적립율이 범위에 맞지 않습니다.')
      return
    }
    if (key === 'IMAGE_REVIEW') {
      const data: ModelServerConfig[] = serverConfigs
      const textMileage = data.find((serverConfig) => serverConfig.key === 'WRITE_REVIEW') || undefined
      if (
        textMileage &&
        values.inputMethod === 'rate' &&
        values.inputNumber < rateToNumber(textMileage.mileageConfigData?.rate)
      ) {
        message.error('포토리뷰 적립률은 텍스트리뷰 적립률 이상으로만 적용 가능 합니다.')
        return
      }
    }
    const variables = MUTATION_VARIABLES.UPDATE_MILEAGE_CONFIG({
      key,
      point: values.inputMethod === 'point' ? values.inputNumber : undefined,
      rate: values.inputMethod === 'rate' ? numberToRate(values.inputNumber) : undefined,
    })
    // console.log(variables)
    await mutation({ variables })
    hideModal()
  }

  return (
    <MMutation gqlKey="updateMileageConfig" onAPISuccess={onAPISuccess}>
      {(mutation: MutationFunction) => {
        const onModalAction = async () => {
          await form.validateFields()
          form.submit()
        }
        return (
          <Form
            form={form}
            initialValues={{ inputNumber, inputMethod }}
            onFinish={(values: Store) => onFinish(values, mutation)}
          >
            <MModal
              title="포인트 지급 설정 변경"
              useModalProps={useModalProps}
              ComponentContent={
                <>
                  <MFormItemWrapper label="지급 방법" labelCol={{ span: 8 }} name="inputMethod">
                    <Radio.Group onChange={() => form.validateFields()}>
                      <Radio value="point">금액</Radio>
                      <Radio value="rate">비율(0-100)</Radio>
                    </Radio.Group>
                  </MFormItemWrapper>
                  <MFormItemInputNumber
                    label={description}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 6 }}
                    name="inputNumber"
                    required
                    rules={[FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_MILEAGE_BENEFIT]}
                  />
                </>
              }
              onAction={onModalAction}
            />
          </Form>
        )
      }}
    </MMutation>
  )
}

export default ModalMileageBenefit
