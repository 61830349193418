import { Row, Col, Tag, Image } from 'antd'
import * as utilData from '@libs/utils/utilData'
import * as utilDate from '@libs/utils/utilDate'
import useModal from 'containers/hooks/useModal'
import { ModalConfirmInput } from 'domains/common/modals'
import { MTextButton, Text14Normal, Text14Blue, MLinkRouterButton } from 'components/@atomics'
import { ITableColumn, ITableColumnFixedProps } from 'components/@atomics/MTable'
import { IModelReview } from 'containers/models/modelReview'
import { IModelReviewGroup } from 'containers/models/modelReviewGroup'
import React from 'react'
import ROUTES from '@constants/constRoutes'

type ReviewBodyProps = {
  dataIndex: string
  fixed?: 'left' | 'right' | boolean
  setCurrentReview: Function
}

const reviewBody = ({
  dataIndex = 'reviewBody',
  fixed = 'left',
  setCurrentReview,
}: ReviewBodyProps): ITableColumn<any> => {
  return {
    title: '리뷰 내용',
    dataIndex,
    width: 300,
    ellipsis: true,
    fixed,
    render: (value: string, record: IModelReview) => {
      let review: any

      if (dataIndex === 'review') {
        review = record as IModelReview
      }

      return (
        <Row gutter={[12, 0]} wrap={false} align="middle">
          {review?.isFirstReviewProduct && (
            <Col>
              <Tag color="volcano" closable={false}>
                첫 리뷰
              </Tag>
            </Col>
          )}
          <Col>
            <MTextButton onClick={() => setCurrentReview!(record)} ellipsis>
              {review?.reviewBody || value}
            </MTextButton>
          </Col>
        </Row>
      )
    },
  }
}

const photo = ({ dataIndex = 'review', fixed = 'left' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '사진',
    dataIndex,
    key: 'review',
    width: 120,
    fixed,
    render: (_: string, review: IModelReview) => {
      let thumbnail = review?.reviewReviewImages[0]?.thumbnail
      if (thumbnail) {
        thumbnail = utilData.getFullUrl(thumbnail)
        return <Image preview={false} src={thumbnail} alt="review main image" width={40} height={40} />
      }
      return <Text14Normal>-</Text14Normal>
    },
  }
}

const isPhotoReview = ({ dataIndex = 'isPhotoReview' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '리뷰 구분',
    dataIndex,
    width: 80,
    render: (value: boolean) => <Text14Normal>{value ? '포토' : '일반'}</Text14Normal>,
  }
}

const createdAtUpdatedAt = ({ dataIndex = 'createdAt' }: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '등록일/수정일',
    dataIndex,
    width: 200,
    render: (value: Date, record: IModelReview) => {
      return (
        <Text14Normal>
          {utilDate.date2string(value)} / {utilDate.date2string(record.updatedAt)}
        </Text14Normal>
      )
    },
  }
}

const reviewGroupName = (): ITableColumn<any> => {
  return {
    title: '그룹이름',
    width: 80,
    render: (record: IModelReviewGroup) => {
      const { useModalProps } = useModal()

      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.setData({ reviewGroup: record })
              useModalProps.toggleModal()
            }}
          >
            <Text14Blue>{record.name || '[이름 없음]'}</Text14Blue>
          </MTextButton>
          <ModalConfirmInput
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: {
                gqlKey: 'updateReviewGroupProducts',
                variableKey: 'updateReviewGroupProducts',
              },
              title: '리뷰 그룹이름 변경',
              description: '리뷰그룹의 이름은 일반 사용자에게 보이지 않습니다.',
              inputOption: {
                name: 'reviewGroupName',
                type: 'input',
                label: '리뷰 그룹이름',
                placeholder: '이름을 입력해주세요.',
                defaultValue: record.name,
              },
              confirmMessage: '변경되었습니다.',
            }}
          />
        </>
      )
    },
  }
}

const productCode = ({ width = 150, link = false } = {}): ITableColumn<any> => {
  return {
    title: '상품코드',
    link,
    width,
    render: (recorde: IModelReview) => {
      if (link) {
        const productDetailUrl = `${process.env.FRONT_WEB_DOMAIN}/products/${recorde?.product?._id}`
        return (
          <MLinkRouterButton href={productDetailUrl} openNewWindow>
            {recorde?.product?._id}
          </MLinkRouterButton>
        )
      }
      return <Text14Normal>{utilData.tableText(recorde?.product?._id)}</Text14Normal>
    },
  }
}

const orderNumberLink = ({ title = '상품 주문번호', hasLink = true } = {}): ITableColumn<any> => {
  return {
    title,
    hasLink,
    width: 160,
    render: (record: IModelReview) => {
      const orderId = record?.orderItem?.order?._id
      const orderNumber = record?.orderItem?.order?.orderNumber
      if (hasLink && orderId && orderNumber) {
        return (
          <MLinkRouterButton href={ROUTES.ORDERS.ORDER_DETAIL} as={`${ROUTES.ORDERS.BASE}/${orderId}`} openNewWindow>
            {orderNumber}
          </MLinkRouterButton>
        )
      }
      return <Text14Normal>{orderNumber || '-'}</Text14Normal>
    },
  }
}

export {
  reviewBody, // 리뷰 내용
  photo, // 리뷰 사진
  createdAtUpdatedAt, // 등록일/수정일
  isPhotoReview, // 리뷰 구분
  reviewGroupName, // 리뷰 그룹이름
  productCode,
  orderNumberLink,
}
