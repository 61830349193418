import * as utilCommon from '@libs/utils/utilCommon'
import { ModelCustomerAddressDefault } from 'containers/models/base/defaultModels'
import { IModelCustomerAddressDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelCustomerAddress extends IModelCustomerAddressDefault {
  fullAddress: string
}

export default class ModelCustomerAddress extends ModelCustomerAddressDefault {
  fullAddress: string = ''

  constructor(data: IModelCustomerAddress) {
    super(data)
    this.fullAddress = utilCommon.getFullAddress(this.postNumber, this.basicAddress, this.detailedAddress)
  }
}
