import { STATUS, STATUS_REQUEST_TYPE } from '@constants/constDomain'
import * as utilDate from '@libs/utils/utilDate'
import { IModelStatusDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelStatusDefault } from 'containers/models/base/defaultModels'

export interface IModelStatus extends IModelStatusDefault {
  isSuspended: boolean
  isRequest: boolean
  isRejected: boolean
  isUpdateRequest: boolean
  statusColor: string
  statusText: '사용' | '사용 중지' | '승인 신청 중' | '등록 반려'
  updatedAtText: string
  updatedAtFieldText: string
  statusValueText: string
}

export default class ModelStatus extends ModelStatusDefault {
  isSuspended: boolean = false
  isRequest: boolean = false
  isRejected: boolean = false
  isUpdateRequest: boolean = false
  statusColor: string = ''
  statusText: '사용' | '사용 중지' | '승인 신청 중' | '등록 반려' | string = ''
  updatedAtText: string = ''
  updatedAtFieldText: string = ''
  statusValueText: string = '-'

  constructor(data: IModelStatus) {
    super(data)

    this.isSuspended = this.value === STATUS.SUSPEND
    this.isRequest = this.value === STATUS.REQUEST
    this.isRejected = this.value === STATUS.REJECTED
    this.isUpdateRequest = this.getIsUpdateRequest(this.requestType)
    this.statusColor = this.getStatusColor(this.value)
    this.statusText = this.getStatusText(this.requestType, this.value, this.text)
    this.updatedAtText = utilDate.date2string(this.updatedAt)
    this.updatedAtFieldText = this.getUpdatedAtFieldText(this.requestType, this.value)
    switch (this.value) {
      case 'APPROVED':
        this.statusValueText = '승인'
        break
      case 'REQUEST':
        this.statusValueText = '요청'
        break
      case 'REJECTED':
        this.statusValueText = '반려'
        break
      case 'SUSPEND':
        this.statusValueText = '중지'
        break
      case 'BANNED':
        this.statusValueText = '금지'
        break
      case 'DELETED':
        this.statusValueText = '삭제'
        break
      case 'INCOMPLETE':
        this.statusValueText = '미완료'
        break
      default:
        this.statusValueText = '-'
    }
  }

  private getIsUpdateRequest = (requestType: string) => {
    return requestType.endsWith('_UPDATE')
  }

  private getStatusColor = (value: string) => {
    if (value === STATUS.APPROVED) return 'green'
    if (value === STATUS.REJECTED) return 'yellow'
    if (value === STATUS.SUSPEND) return 'red'
    return 'grey'
  }

  private getStatusText = (requestType: string, value: string, text: string) => {
    if (requestType.endsWith('_UPDATE')) {
      if (value === STATUS.REQUEST) return '정보변경 신청'
      if (value === STATUS.APPROVED) return '정보변경 신청 승인'
      if (value === STATUS.REJECTED) return '정보변경 신청 반려'
    }
    if (requestType.endsWith('_ROLE')) {
      if (value === STATUS.APPROVED) return '사용'
      if (value === STATUS.SUSPEND) return '사용중지'
      // if (value === STATUS.REQUEST) return '등록신청'
      if (value === STATUS.REJECTED) return '등록반려'
    }
    if (requestType === STATUS_REQUEST_TYPE.BRAND_REGISTRATION) {
      if (value === STATUS.APPROVED) return '운영중'
      if (value === STATUS.SUSPEND) return '운영중지'
      if (value === STATUS.REQUEST) return '운영대기'
      if (value === STATUS.REJECTED) return '등록반려'
    }
    if (requestType === STATUS_REQUEST_TYPE.PRODUCT_REGISTRATION) return text

    if (value === STATUS.APPROVED) return '사용'
    if (value === STATUS.SUSPEND) return '사용 중지'
    if (value === STATUS.REQUEST) return '승인 신청 중'
    if (value === STATUS.REJECTED) return '사용'
    return '-'
  }

  private getUpdatedAtFieldText = (requestType: string, value: string) => {
    if (requestType === STATUS_REQUEST_TYPE.BRAND_REGISTRATION) {
      if (value === STATUS.REQUEST) return '등록 신청일'
      if (value === STATUS.APPROVED) return '등록 승인일'
      if (value === STATUS.REJECTED) return '등록 반려일'
      if (value === STATUS.SUSPEND) return '운영 중지일'
    } else if (requestType === STATUS_REQUEST_TYPE.PRODUCT_REGISTRATION) {
      if (value === STATUS.REQUEST) return '등록 요청일'
      if (value === STATUS.APPROVED) return '등록 승인일'
      if (value === STATUS.REJECTED) return '등록 반려일'
      if (value === STATUS.SUSPEND) return '등록 중지일'
    } else if (requestType === STATUS_REQUEST_TYPE.SELLER_ROLE) {
      if (value === STATUS.REQUEST) return '승인 신청일'
      if (value === STATUS.APPROVED) return '입점 승인일'
      if (value === STATUS.REJECTED) return '입점 반려일'
      if (value === STATUS.SUSPEND) return '사용 중지일'
    } else if (
      requestType === STATUS_REQUEST_TYPE.BRAND_UPDATE ||
      requestType === STATUS_REQUEST_TYPE.SELLER_UPDATE ||
      requestType === STATUS_REQUEST_TYPE.PRODUCT_UPDATE
    ) {
      if (value === STATUS.REQUEST) return '정보변경 신청일'
      if (value === STATUS.APPROVED) return '정보변경 승인일'
      if (value === STATUS.REJECTED) return '정보변경 신청 반려일'
    }
    return ''
  }
}
