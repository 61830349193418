import { gql } from '@apollo/client'

export const COMMON_MUTATION_GQLS = {
  UPDATE_DISPLAY_ORDER: gql`
    mutation updateDisplayOrder($id: ID!, $input: UpdateDisplayOrderInput!) {
      updateDisplayOrder(id: $id, input: $input) {
        id
        displayOrder
      }
    }
  `,
}

export default COMMON_MUTATION_GQLS
