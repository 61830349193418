import * as utilDate from '@libs/utils/utilDate'
import { COUPON_STATUS_TEXT, COUPON_STATUS } from '@constants/constDomain'
import { IModelCouponDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelCouponDefault } from 'containers/models/base/defaultModels'
import { IModelCouponGroup } from 'containers/models/modelCouponGroup'

export interface IModelCoupon extends IModelCouponDefault {
  usedStatusText: string
  couponStatusText: COUPON_STATUS_TEXT
  couponUsingPeriodText: string
  couponGroup: IModelCouponGroup
}

export default class ModelCoupon extends ModelCouponDefault {
  usedStatusText: string = ''
  couponStatusText: COUPON_STATUS_TEXT = COUPON_STATUS_TEXT.UNUSED
  couponUsingPeriodText: string = ''

  constructor(data: IModelCoupon) {
    super(data)

    this.usedStatusText = this.usedAt ? '사용' : '미사용'
    this.couponStatusText = COUPON_STATUS_TEXT[this.usedStatus as COUPON_STATUS]
    this.couponUsingPeriodText = this.getCouponUsingPeriodText()
  }

  getCouponUsingPeriodText = (): string => {
    if (!this.startedAt || !this.expiredAt) return '-'
    return `${utilDate.date2string(this.startedAt!)} ~ ${utilDate.date2string(this.expiredAt!)}`
  }
}
