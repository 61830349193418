import { IModelDeviceDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelDeviceDefault } from 'containers/models/base/defaultModels'

export interface IModelDevice extends IModelDeviceDefault {
  token: string
  clientType: string
}

export default class ModelDevice extends ModelDeviceDefault {
  constructor(data: IModelDevice) {
    super(data)
  }
}
