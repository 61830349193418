import { FunctionComponent, MutableRefObject, useRef } from 'react'
import { queryParamsProps } from 'components/MQuery'
import tableCols from 'domains/admin/tableCols'
import { IModelCoupon } from 'containers/models/modelCoupon'
import { MMainTable } from 'components'
import { ITableColumn } from 'components/@atomics/MTable'
import { ModelCouponGroup } from 'containers/models'
import { ButtonRequestExcel, ButtonRouteToExcelDownloadList } from 'domains/common/tableButtons'

type MainTablePaperCouponListProps = {
  selectedCouponGroup: ModelCouponGroup
}

const tableColumns = (refetchRef: MutableRefObject<Function | null>): Array<ITableColumn<IModelCoupon>> => {
  return [
    tableCols.common.ID(),
    tableCols.common.baseText({ title: '쿠폰인증번호', dataIndex: 'code', width: '220px' }),
    tableCols.user.username({ dataIndex: 'user' }),
    tableCols.common.baseText({ title: '이름', dataIndex: ['user', 'name'] }),
    tableCols.common.baseTextFromModel({ title: '등급', dataIndex: ['user', 'grade'], key: 'name' }),
    tableCols.date.createdAtText({ title: '발급일' }),
    tableCols.date.expiredAtText({ title: '만료일' }),
    tableCols.date.usedAtText({ title: '사용일' }),
    tableCols.common.baseText({ title: '상태', dataIndex: 'usedStatusText' }),
    tableCols.coupon.deleteButton(refetchRef),
  ]
}

const MainTablePaperCouponList: FunctionComponent<MainTablePaperCouponListProps> = ({ selectedCouponGroup }) => {
  const refetchRef = useRef<Function | null>(null)
  const variables = {
    filter: { couponGroup: selectedCouponGroup.id },
    orderBy: '-createdAt',
  }
  const queryParams: queryParamsProps = {
    gqlKey: 'coupons',
    variables,
  }

  return (
    <MMainTable
      TitleSet={{ title: '쿠폰 발급 목록', unit: '개' }}
      columns={tableColumns(refetchRef)}
      HeaderRightButtons={[
        <ButtonRequestExcel title="쿠폰 목록 엑셀 파일 요청" requestExcelGqlKey="requestCouponExcelFile" />,
        <ButtonRouteToExcelDownloadList />,
      ]}
      queryParams={queryParams}
      refetchRef={refetchRef}
    />
  )
}

export default MainTablePaperCouponList
