import { FIELD_KEYS, FILE_UPLOAD_EXCEL_ACCEPT } from '@constants/constData'
import styled from 'styled-components'
import { Form, Space, message as antdMessage } from 'antd'
import { MutationFunction } from '@apollo/client'
import { MFormItemFileUpload } from 'components/formItems'
import { MModal } from 'components/modals'
import { DomainModalProps } from 'containers/hooks/useModal'
import MMutation from 'components/MMutation'
import { EXCEL_UPLOAD_MUTATION_KEY2GQLS } from 'containers/gqls'
import { MConfirmModalProps } from 'components/modals/MConfirmModal'
import { componentOptionProps } from 'domains/common/SubComponentProvider'
import { MutableRefObject, useEffect, useState } from 'react'
import { Text14Normal, Text14Red, Text16Normal } from 'components/@atomics'

type FixedProps = {
  title: string
  uploadExcelGqlKey: keyof typeof EXCEL_UPLOAD_MUTATION_KEY2GQLS
  externalInputVariables?: {}
  onAPISuccess?: onUploadExcelCompletedType
  componentOption?: componentOptionProps
  confirmMessage?: string
  guideMessage?: string
  failedGuideMessage?: string
  refetchRef?: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}

export type uploadExcelResultType = {
  isSuccess: boolean
  isTask: boolean
  failedReasons?: string[]
  count: number
}
export type onUploadExcelCompletedType = (data: uploadExcelResultType) => void

const ModalUploadExcelFile = ({ useModalProps, fixedProps }: DomainModalProps<MConfirmModalProps, FixedProps>) => {
  const {
    title,
    uploadExcelGqlKey,
    externalInputVariables,
    onAPISuccess: onAPISuccess_,
    guideMessage,
    failedGuideMessage = '등록 실패 사유',
    refetchRef,
    statusFilterRefetchRef,
  } = fixedProps
  const [failReasons, setFailReasons] = useState<string[] | undefined>()
  const [form] = Form.useForm()
  const { hideModal, isShowing } = useModalProps

  useEffect(() => {
    if (!isShowing) {
      setFailReasons(undefined)
      form.resetFields()
    }
  }, [isShowing])

  const onFinish = async (values: { excel: File }, mutation: MutationFunction) => {
    const variables = {
      input: {
        excel: values.excel,
        ...externalInputVariables,
      },
    }
    await mutation({ variables })
  }

  const onAPISuccess = (data: any) => {
    const result = data as uploadExcelResultType
    // console.log(`onAPISuccess, data: ${data.isSuccess}, ${data.isTask}, ${data.failedReasons}, ${data.count}`)
    setFailReasons(undefined)
    form.resetFields()
    if (onAPISuccess_) {
      onAPISuccess_(result)
    } else {
      if (result.isSuccess) {
        if (result.isTask) {
          antdMessage.success('엑셀 업로드에 성공했습니다. 새로고침 혹은 잠시 후 미등록 목록을 다시 확인해주세요.')
        } else {
          if (refetchRef?.current) refetchRef?.current()
          if (statusFilterRefetchRef?.current) statusFilterRefetchRef?.current()
          antdMessage.success(`엑셀 업로드에 성공했습니다. ${result.count}개 등록에 성공했습니다.`)
        }
      } else {
        if (result.isTask) {
          antdMessage.success('엑셀 업로드에 실패했습니다.')
        } else {
          setFailReasons(result.failedReasons)
        }
        return
      }
      hideModal()
    }
  }

  return (
    <MMutation gqlKey={uploadExcelGqlKey} onAPISuccess={onAPISuccess}>
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <Form form={form} onFinish={(values) => onFinish(values, mutation)} layout="vertical">
            <MModal
              title={`${title}`}
              width={800}
              loading={loading}
              form={form}
              useModalProps={useModalProps}
              ComponentContent={
                <>
                  {guideMessage && <Text14Normal>{guideMessage}</Text14Normal>}
                  <StyledSpace direction="vertical" size="middle">
                    <MFormItemFileUpload
                      disabled={false}
                      name={FIELD_KEYS.FIELD_EXCEL}
                      buttonText="엑셀 파일 선택"
                      accept={FILE_UPLOAD_EXCEL_ACCEPT}
                      required
                    />
                  </StyledSpace>
                  {failReasons && (
                    <>
                      <Text16Normal>{`${failedGuideMessage}\n`}</Text16Normal>
                      <div style={{ maxHeight: 150, overflow: 'scroll' }}>
                        <Text14Red>{failReasons.join('\n')}</Text14Red>
                      </div>
                    </>
                  )}
                </>
              }
            />
          </Form>
        )
      }}
    </MMutation>
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default ModalUploadExcelFile
