import _ from 'lodash'
import { FC } from 'react'
import { useRouter } from 'next/router'
import { Col, Space, message } from 'antd'

import * as utilCommon from '@libs/utils/utilCommon'
import { ModalConfirm } from 'domains/common/modals'
import { MInfoCard } from 'components'
import { IModelStatus } from 'containers/models/modelStatus'
import { MButton, Text14Normal, Text14Orange, Text14Red, MLinkRouterButton } from 'components/@atomics'
import useModal from 'containers/hooks/useModal'
import ROUTES from '@constants/constRoutes'
import { MUTATION_KEY2GQLS } from 'containers/gqls'

type DInfoCardStatusProps = MainContentProps & {
  refetch?: Function
}

type MainContentProps = {
  status: IModelStatus
  updateStatus?: IModelStatus
  noStatus?: boolean
  disabled?: boolean
}

type RightContentProps = {
  status: IModelStatus
  refetch?: Function
  disabled?: boolean
}

const StatusTextCol: FC<{ color: string; text: string }> = ({ color, text }) => {
  return (
    <Col>
      {color === 'green' && <Text14Normal>상태: {text}</Text14Normal>}
      {color === 'grey' && <Text14Normal>상태: {text}</Text14Normal>}
      {color === 'yellow' && <Text14Orange>상태: {text}</Text14Orange>}
      {color === 'red' && <Text14Red>상태: {text}</Text14Red>}
    </Col>
  )
}

const MainContent: FC<MainContentProps> = ({ status, updateStatus, noStatus, disabled = false }) => {
  const router = useRouter()
  const _status = updateStatus && updateStatus.updatedAt > status.updatedAt ? updateStatus : status
  const { updatedAtFieldText, updatedAtText, recentMessage } = _status
  const { statusColor, statusText } = status
  const splitTitleText: string = `${updateStatus?.updatedAtText?.split('일')[0]}${
    updateStatus?.value === 'REQUEST' ? '중' : '됨'
  }`
  const isSellerDetail: boolean = router.pathname === '/accounts/seller-list/[id]'
  const needChangeInfo: boolean =
    !!updateStatus &&
    !!Object.keys(updateStatus).length &&
    isSellerDetail &&
    ['REQUEST', 'REJECTED'].includes(updateStatus.value)

  return (
    <Space size="middle">
      <StatusTextCol color={statusColor!} text={noStatus ? splitTitleText : (statusText as string)} />

      {!noStatus && updatedAtFieldText && (
        <Col span={24}>
          <Text14Normal>{`${updatedAtFieldText} : ${updatedAtText}`}</Text14Normal>
        </Col>
      )}

      {recentMessage && (
        <Col>
          <Text14Normal>반려사유: {recentMessage}</Text14Normal>
        </Col>
      )}

      {needChangeInfo && (
        <MLinkRouterButton
          disabled={disabled}
          sider={false}
          openNewWindow
          href={`${ROUTES.PARTNERS.SELLER_LIST}/${router.query.id}/change`}
          buttonType="button"
        >
          변경 신청 내역 보기
        </MLinkRouterButton>
      )}
    </Space>
  )
}

const RightContent: FC<RightContentProps> = ({ status, refetch, disabled = false }) => {
  const { useModalProps } = useModal()
  const { value, requestType, isUpdateRequest } = status
  const isProduct = requestType.includes('PRODUCT')
  const isBrand = requestType.includes('BRAND')
  if (value === 'REJECTED') return null // 반려 상태일 경우 사용/사용 중지 불가
  if (!isUpdateRequest && value === 'REQUEST') return null // 승인 + 신청일 때 사용/사용 중지 불가
  let gqlKey = 'suspendRequest'
  let title = '사용 중지'
  if (isProduct) {
    title = '판매 중지'
  } else if (isBrand) {
    title = '운영 중지'
  }
  let description = isProduct ? '판매 중지하시겠습니까?' : '사용 중지하시겠습니까?'
  if (value === 'SUSPEND') {
    gqlKey = 'approveRequest'
    title = isProduct ? '판매중지 해제' : '사용중지 해제'
    description = isProduct ? '판매 중지를 해제하시겠습니까?' : '사용 중지를 해제하시겠습니까?'
  }
  return (
    <>
      <MButton
        disabled={disabled}
        onClick={() => {
          useModalProps.toggleModal()
          useModalProps.setData({ status })
        }}
        width={100}
      >
        {title}
      </MButton>
      <ModalConfirm
        useModalProps={useModalProps}
        onAPISuccess={async () => {
          await message.success(value === 'APPROVED' ? '사용이 중지되었습니다.' : '사용 중지가 해제되었습니다.')
          if (refetch) {
            await refetch()
          }
        }}
        fixedProps={{
          mutationKey: {
            gqlKey: gqlKey as keyof typeof MUTATION_KEY2GQLS,
            variableKey: 'statusId',
          },
          title,
          description,
        }}
      />
    </>
  )
}

const DInfoCardStatus: FC<DInfoCardStatusProps> = ({ refetch, disabled = false, ...mainContentProps }) => {
  if (!mainContentProps.status || _.isEmpty(mainContentProps.status)) return <></>

  const { status } = mainContentProps
  const ComponentRightContent = utilCommon.isAdmin() ? (
    <RightContent status={status} refetch={refetch} disabled={disabled} />
  ) : (
    <></>
  )
  return (
    <MInfoCard
      mainContent={<MainContent disabled={disabled} {...mainContentProps} />}
      rightContent={ComponentRightContent}
    />
  )
}

export default DInfoCardStatus
