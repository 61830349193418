import { queryParamsProps } from 'components/MQuery'
import { MutableRefObject, useRef } from 'react'

import ROUTES from '@constants/constRoutes'
import { IModelUser } from 'containers/models/modelUser'
import { MMainTable, MListSortSelect } from 'components'
import { MTextButton, MLinkRouterButton, Text14Normal, Text14Blue } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import tableCols from 'domains/admin/tableCols'
import { ModalConfirm, ModalFunctionalTable } from 'domains/common/modals'
import useModal from 'containers/hooks/useModal'
import { modalContentProps } from 'domains/common/modals/ModalTableSelect'

const modalTableSingleSelectSeller = (
  userData: IModelUser,
  refetchRef?: MutableRefObject<Function | null>
): modalContentProps => {
  const tableColumnsCreateOrDeleteManagerFromUsers = [
    tableCols.common.ID(),
    tableCols.common.baseText({ title: '셀러명', dataIndex: 'shopName' }),
    tableCols.common.baseText({ title: '대표자명', dataIndex: 'companyOwnerName' }),
    tableCols.seller.createOrDeleteManager(userData, refetchRef),
  ]
  return {
    title: '셀러 선택',
    queryParams: {
      gqlKey: 'sellersByPartnerList',
      dataKey: 'sellers',
      variables: { filter: { status: { include: ['APPROVED'] } } },
    },
    searchInputs: [{ label: '셀러명', name: 'shopName' }],
    tableColumns: tableColumnsCreateOrDeleteManagerFromUsers,
    okText: '닫기',
  }
}

const tableColumns = (refetchRef: MutableRefObject<Function | null>): Array<ITableColumn<any>> => {
  return [
    tableCols.common.ID(),
    tableCols.user.email({ dataIndex: 'email' }),
    // tableCols.common.baseText({ title: '이메일', dataIndex: 'email', width: 160, fixed: 'left' }),
    tableCols.common.baseText({ title: '이름', dataIndex: 'name' }),
    tableCols.common.baseText({ title: '연락처', dataIndex: 'mobileNumber' }),
    tableCols.date.dateJoinedText({ title: '가입일' }),
    {
      title: '셀러 매니저 유무',
      dataIndex: 'approvalSellerCount',
      width: 160,
      render: (v: number) => {
        const sellerCount = v > 0 ? `${v}개` : 'X'
        return <Text14Normal>{sellerCount}</Text14Normal>
      },
    },
    {
      title: '셀러 매니저 등록 관리',
      width: 170,
      render: (record: IModelUser) => {
        const { useModalProps } = useModal()
        return (
          <>
            <MTextButton
              onClick={() => {
                useModalProps.toggleModal()
              }}
            >
              [등록/삭제]
            </MTextButton>
            <ModalFunctionalTable useModalProps={useModalProps} {...modalTableSingleSelectSeller(record, refetchRef)} />
          </>
        )
      },
    },
    tableCols.user.isStaff(),
    {
      title: '관리자 등록',
      dataIndex: 'isStaff',
      width: 120,
      render: (isStaff: boolean, record: IModelUser) => {
        const { useModalProps } = useModal()
        const buttonText = isStaff ? '해제' : '등록'
        const title = isStaff ? '관리자 해제 안내' : '관리자 등록 안내'
        const description = isStaff ? '해당 사용자를 관리자에서 해제합니다.' : '해당 사용자를 관리자로 등록합니다.'

        const handleClick = () => {
          useModalProps.setData({ user: record })
          useModalProps.toggleModal()
        }

        return (
          <>
            <MTextButton onClick={() => handleClick()}>[{buttonText}]</MTextButton>
            <ModalConfirm
              useModalProps={useModalProps}
              fixedProps={{
                mutationKey: {
                  gqlKey: isStaff ? 'removeAdminPermission' : 'addAdminPermission',
                  variableKey: 'userId',
                },
                title,
                description,
              }}
            />
          </>
        )
      },
    },
    {
      title: '수정',
      width: 100,
      render: (record: IModelUser) => {
        return (
          <MLinkRouterButton sider={false} href={`${ROUTES.PARTNERS.PARTNER_LIST}/${record._id}`} openNewWindow>
            <Text14Blue>[수정]</Text14Blue>
          </MLinkRouterButton>
        )
      },
    },
  ]
}

const MainTablePartnerList = () => {
  const refetchRef = useRef<Function | null>(null)
  const queryParams: queryParamsProps = {
    gqlKey: 'partnerListTable',
    dataKey: 'users',
    variables: {
      filter: { userStatus: { exclude: ['LEFT', 'ADMIN_LEFT'] } },
      orderBy: '-dateJoined',
    },
  }

  return (
    <MMainTable
      TitleSet={{ title: '전체 사용자 목록', unit: '명' }}
      HeaderRightButtons={[
        <MListSortSelect filterOptions={['FIELD_SORT_USER_DATE_JOINED_DESC', 'FIELD_SORT_USER_DATE_JOINED']} />,
      ]}
      columns={tableColumns(refetchRef)}
      queryParams={queryParams}
      refetchRef={refetchRef}
    />
  )
}

export default MainTablePartnerList
