import { IModelReviewGroupDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelReviewGroupDefault } from 'containers/models/base/defaultModels'

export interface IModelReviewGroup extends IModelReviewGroupDefault {}

export default class ModelReviewGroup extends ModelReviewGroupDefault {
  constructor(data: IModelReviewGroup) {
    super(data)
  }
}
