import { Col, Input, Row } from 'antd'
import styled from 'styled-components'

import { MButton, MSelect } from 'components/@atomics'
import { IFilterOptions, IFilterStringOptions } from 'components/formItems/index'
import MFormItemWrapper, { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { useState } from 'react'

export type MFormItemFilterSearch2Props = MFormItemWrapperProps & {
  selectItemName?: string | []
  inputItemName?: string | []
  selectOptions: IFilterOptions | IFilterStringOptions
  submitButton?: string | false
  initialValue?: { [key: string]: any } | null
}

function MFormItemSearch(props: MFormItemFilterSearch2Props) {
  const { selectOptions, submitButton, initialValue, ...formItemWrapperProps } = props
  const index = selectOptions?.findIndex((v) => v.value === initialValue?.selectOptions)
  const [name, setName] = useState<string>(selectOptions[index >= 0 ? index : 0].value)
  return (
    <StyledMFormItemWrapper {...formItemWrapperProps}>
      <Row gutter={24}>
        <Col span={5}>
          <MSelect
            value={name}
            onChange={(value: string) => {
              setName(value)
            }}
            filterOptions={selectOptions as IFilterOptions}
          />
        </Col>
        <Col span={5}>
          <StyledMFormItemWrapper name={name} noStyle>
            <Input.Search />
          </StyledMFormItemWrapper>
        </Col>
        {submitButton && (
          <Col span={5}>
            <MButton type="primary" htmlType="submit" width={68}>
              {submitButton}
            </MButton>
          </Col>
        )}
      </Row>
    </StyledMFormItemWrapper>
  )
}

const StyledMFormItemWrapper = styled(MFormItemWrapper)`
  margin-bottom: 16px;
`

export default MFormItemSearch
