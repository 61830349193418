import { gql } from '@apollo/client'

export const DISPLAY_PRODUCT_INFO_MUTATION_GQLS = {
  CREATE_DISPLAY_PRODUCT_INFOS: gql`
    mutation createDisplayProductInfos($input: CreateDisplayProductInfosInput!) {
      createDisplayProductInfos(input: $input) {
        count
      }
    }
  `,
  CREATE_DISPLAY_PRODUCT_INFOS_WITH_PRODUCT_PROMOTIONS: gql`
    mutation createDisplayProductInfosWithProductPromotions(
      $input: CreateDisplayProductInfosWithProductPromotionsInput!
    ) {
      createDisplayProductInfosWithProductPromotions(input: $input) {
        displayProductInfos {
          displayOrder
          startedAt
          endedAt
          id
          product {
            id
            name
            realName
            mainImage {
              id
              thumbnail
            }
            brand {
              id
              name
            }
            status {
              id
              value
              text
            }
            defaultProductOption {
              id
              price
              discountPrice
              customerPrice
            }
          }
          productpromotion {
            startedAt
            endedAt
            createdAt
            updatedAt
            id
            productPromotionType
            name
            description
            discountPrice
            fitpetFeeRate
            mileageRewardRate
            isDelete
            minOrderAmount
            maxOrderCount
            productPromotionCustomerPrice
            discountRate
          }
        }
      }
    }
  `,
  DELETE_DISPLAY_PRODUCT_INFO: gql`
    mutation deleteDisplayProductInfo($id: ID!) {
      deleteDisplayProductInfo(id: $id) {
        displayProductInfo {
          id
        }
      }
    }
  `,
  DELETE_DISPLAY_PRODUCT_INFOS: gql`
    mutation deleteDisplayProductInfos($input: ModelDeletesInput!) {
      deleteDisplayProductInfos(input: $input) {
        count
      }
    }
  `,
}

export default DISPLAY_PRODUCT_INFO_MUTATION_GQLS
