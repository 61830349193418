import { FunctionComponent, MouseEventHandler, ReactNode } from 'react'
import { Text14Blue, Text14Red, Text14Normal, TextEllipsis } from 'components/@atomics'

type MTextButtonProps = {
  onClick: MouseEventHandler<HTMLElement>
  children?: ReactNode
  color?: 'red' | 'black' | 'blue'
  disable?: boolean
  ellipsis?: boolean
}

const MTextButton: FunctionComponent<MTextButtonProps> = ({ children, onClick, color, disable, ellipsis }) => {
  if (ellipsis) {
    return <TextEllipsis onClick={onClick}>{children}</TextEllipsis>
  }
  if (color === 'red')
    return (
      <a>
        <Text14Red onClick={onClick}>{children}</Text14Red>
      </a>
    )
  if (color === 'black')
    return (
      <a>
        <Text14Normal onClick={onClick}>{children}</Text14Normal>
      </a>
    )
  if (disable) {
    return <Text14Normal>{children}</Text14Normal>
  }
  if (!children) {
    return <Text14Normal onClick={onClick} />
  }
  return (
    <a>
      <Text14Blue onClick={onClick}>{children}</Text14Blue>
    </a>
  )
}

export default MTextButton
