import { FC } from 'react'
import { Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import { MFormItemWrapper } from 'components/formItems'
import { Text12Blue } from 'components/@atomics'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import styled from 'styled-components'

type CustomTextAreaProps = Omit<TextAreaProps, 'name'> & {
  width?: number
}

export type MFormItemTextAreaProps = MFormItemWrapperProps & {
  width?: number
  comment?: string
  disabled?: boolean
  textAreaProps?: Omit<TextAreaProps, 'name'>
}

const MFormItemTextArea: FC<MFormItemTextAreaProps> = ({
  width,
  comment,
  disabled = false,
  textAreaProps,
  ...formItemWrapperProps
}) => {
  return (
    <MFormItemWrapper
      {...formItemWrapperProps}
      extra={comment ? <Text12Blue>{comment}</Text12Blue> : undefined}
      getValueProps={(_val) => {
        const value = typeof _val === 'number' ? _val.toString() : _val
        return { value }
      }}
    >
      <StyledTextArea disabled={disabled} width={width} {...textAreaProps} />
    </MFormItemWrapper>
  )
}

const StyledTextArea = styled(Input.TextArea)<CustomTextAreaProps>`
  width: ${(props) => `${props.width}px`};
`

export default MFormItemTextArea
