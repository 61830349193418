import { MTextButton, Text14Normal } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import useModal from 'containers/hooks/useModal'
import { ModalConfirm, ModalMileageBenefit } from 'domains/common/modals'
import { IModelServerConfig } from 'containers/models/modelServerConfig'
import * as utilData from '@libs/utils/utilData'

const mileageConfigTypeDisplay = () => {
  return {
    title: '적립금 지급 제목',
    dataIndex: 'description',
    render: (description: string) => {
      return <p>{description}</p>
    },
  }
}

const mileageValue = () => {
  return {
    title: '금액',
    dataIndex: 'value',
    key: 'mileageValue',
    render: (jsonValue: string) => {
      const value = JSON.parse(jsonValue)
      const { rate, point } = value
      if (rate) {
        return utilData.percentText(rate * 100)
      }
      return utilData.currencyText(point)
    },
  }
}

const pointEdit = (refetch: Function): ITableColumn<any> => ({
  title: '금액 수정',
  dataIndex: 'id',
  key: 'id',
  render: (_id: string, record: IModelServerConfig) => {
    const { useModalProps } = useModal()
    const handlePointEditClick = () => {
      const { key, description, value: jsonValue } = record
      const value = JSON.parse(jsonValue)
      useModalProps.showModal()
      useModalProps.setData({ key, description, point: value.point, rate: value.rate })
    }
    return (
      <>
        <MTextButton onClick={handlePointEditClick}>[수정]</MTextButton>
        <ModalMileageBenefit
          useModalProps={useModalProps}
          onAPISuccess={() => {
            refetch()
          }}
        />
      </>
    )
  },
})

const applyStatus = (): ITableColumn<any> => ({
  title: '지급 상태',
  render: (record: IModelServerConfig) => {
    const { mileageConfigData } = record
    return <Text14Normal> {mileageConfigData?.isSuspend ? '지급 중지' : '적용'} </Text14Normal>
  },
})

const suspendButton = (refetch: Function): ITableColumn<any> => {
  return {
    title: '지급 중지',
    key: 'id',
    render: (record: IModelServerConfig) => {
      const { mileageConfigData } = record
      const { useModalProps } = useModal()
      const titles = {
        mainText: mileageConfigData.isSuspend ? '해제' : '적용 중지',
        get modalTitle() {
          return `마일리지 지급 설정 ${this.mainText}`
        },
        get modalDescription() {
          return `${record.description}의 지급 설정을 ${this.mainText} 하시겠습니까?`
        },
      }

      const handleClick = () => {
        useModalProps.setData({ serverConfig: record })
        useModalProps.showModal()
      }

      return (
        <>
          <MTextButton onClick={handleClick}>[{titles.mainText}]</MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: {
                gqlKey: 'updateMileageConfig',
                variableKey: 'toggleMileageSuspend',
              },
              title: titles.modalTitle,
              description: titles.modalDescription,
            }}
            onAPISuccess={() => refetch()}
          />
        </>
      )
    },
  }
}

export {
  mileageConfigTypeDisplay, // 구분
  mileageValue,
  pointEdit,
  applyStatus,
  suspendButton,
}
