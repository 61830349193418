import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const DISPLAY_COLLECTION_MUTATION_GQLS = {
  CREATE_DISPLAY_COLLECTION: gql`
    mutation createDisplayCollection($input: CreateDisplayCollectionInput!) {
      createDisplayCollection(input: $input) {
        displayCollection {
          ...displayCollection
          displayProductInfos {
            totalCount
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
  `,
  UPDATE_DISPLAY_COLLECTION: gql`
    mutation updateDisplayCollection($id: ID!, $input: UpdateDisplayCollectionInput!) {
      updateDisplayCollection(id: $id, input: $input) {
        displayCollection {
          ...displayCollection
          displayProductInfos {
            totalCount
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
  `,
  UPDATE_DATE_OF_DISPLAY_COLLECTION: gql`
    mutation updateDateOfDisplayCollection($id: ID!, $input: UpdateDisplayCollectionInput!) {
      updateDateOfDisplayCollection(id: $id, input: $input) {
        displayCollection {
          ...displayCollection
          displayProductInfos {
            totalCount
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
  `,
  DELETE_DISPLAY_COLLECTION: gql`
    mutation deleteDisplayCollection($id: ID!) {
      deleteDisplayCollection(id: $id) {
        displayCollection {
          ...displayCollection
        }
      }
    }
    ${DEFAULT_FRAGMENTS.displayCollection}
  `,
  DELETE_DISPLAY_COLLECTIONS: gql`
    mutation deleteDisplayCollectionsDefault($input: ModelDeletesInput!) {
      deleteDisplayCollections(input: $input) {
        count
      }
    }
  `,
}

export default DISPLAY_COLLECTION_MUTATION_GQLS
