import { ReactElement, useState, useRef, MutableRefObject } from 'react'

import _ from 'lodash'

import { MMainTable } from 'components'
import { MMainTableProps } from 'components/MMainTable'
import { MOuterCard } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelProductQna } from 'containers/models/modelProductQna'
import tableCols from 'domains/admin/tableCols'
import { DescriptionsTableQnaProductList } from 'domains/admin/descriptions'
import { queryParamsProps } from 'components/MQuery'
import { ButtonDeleteProductQna } from 'domains/common/tableButtons'
import { DATETIME_SECOND_FORMAT } from '@libs/utils/utilDate'

const tableColumns = (setCurrentData: Function): ITableColumn<IModelProductQna>[] => {
  return [
    tableCols.common.baseText({ title: '번호', fixed: 'left', dataIndex: '_id', width: 60 }),
    tableCols.serviceQna.isAnswered(),
    tableCols.serviceQna.questionBody({ dataIndex: 'productQna', fixed: false, setCurrentData }),
    tableCols.user.writer(),
    tableCols.user.mobileNumber(),
    tableCols.date.createdAtText({ title: '접수일시', width: 180, dateFormat: DATETIME_SECOND_FORMAT }),
    tableCols.productQna.productCode({ link: true }),
    tableCols.product.productSeller(),
    tableCols.date.dateText({ title: '답변일시', key: 'answeredAt', width: 180, dateFormat: DATETIME_SECOND_FORMAT }),
  ]
}

const HeaderLeftButton = (
  selected: MutableRefObject<IModelProductQna[]>,
  refetchRef: MutableRefObject<Function | null>
) => {
  return [<ButtonDeleteProductQna selected={selected} refetchRef={refetchRef} />]
}

const MainTableQnaProductList = (): ReactElement<MMainTableProps> => {
  const [currentData, setCurrentData] = useState<IModelProductQna>({} as IModelProductQna)
  const refetchRef = useRef<any>(null)
  const selected = useRef<[]>([])
  const queryParams: queryParamsProps = {
    gqlKey: 'productQnaList',
    dataKey: 'productQnas',
    variables: { orderBy: '-createdAt' },
  }
  const onRowSelect = (rows: []) => {
    selected.current = rows
  }

  return (
    <>
      <MMainTable
        TitleSet={{ title: '전체 문의 목록', unit: '개' }}
        rowSelectionType="checkbox"
        onRowSelect={onRowSelect}
        columns={tableColumns(setCurrentData)}
        HeaderLeftButtons={HeaderLeftButton(selected, refetchRef)}
        queryParams={queryParams}
        refetchRef={refetchRef}
      />
      {!_.isEmpty(currentData) ? (
        <MOuterCard>
          <DescriptionsTableQnaProductList currentQna={currentData} refetchRef={refetchRef} />
        </MOuterCard>
      ) : null}
    </>
  )
}

export default MainTableQnaProductList
