import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'
import SELLER_FRAGMENTS from 'containers/gqls/accounts/seller/fragments'

export const SELLER_QUERY_GQLS = {
  SELLER_INFO: gql`
    query sellerInfo($id: ID!) {
      seller(id: $id) {
        id
        shopName
        companyName
        companyOwnerName
        businessRegistrationNumber
        postNumber
        basicAddress
        detailedAddress
        businessCondition
        businessItem
        businessRegistration
        mailOrderReportNumber
        mailOrderReportRegistration
        bankBookImage
        telephoneNumber
        bankName
        bankCode
        accountNumber
        accountHolderName
        accountManagerName
        accountManagerTelephoneNumber
        returnExchangeGuide
        taxEmail
        notificationEmail
        notificationManagerName
        notificationMobileNumber
        sabangnetToken
        chiefManager {
          id
          name
          email
          mobileNumber
        }
        users {
          edges {
            node {
              id
              name
              email
              mobileNumber
            }
          }
        }
        sellerAsInfos {
          edges {
            node {
              id
              telephoneNumber
            }
          }
        }
        status {
          ...status
        }
        updateStatus {
          ...status
        }
        userShippingPresets {
          edges {
            node {
              ...shippingPreset
              shippingCompany {
                ...shippingCompany
              }
              warehouseAddress {
                ...sellerAddress
              }
              returnAddress {
                ...sellerAddress
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.sellerAddress}
  `,
  SELLERS: gql`
    query sellers($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            ...sellerOnly
            status {
              ...status
            }
            updateStatus {
              ...status
            }
            updateInfo {
              seller {
                ...sellerOnly
              }
            }
            users {
              edges {
                node {
                  ...user
                }
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.status}
    ${SELLER_FRAGMENTS.sellerOnly}
  `,
  SELLER_REQUEST_LIST_TABLE: gql`
    query sellerRequestListTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            id
            shopName
            requestedAt
            status {
              id
              updatedAt
              value
            }
            updateStatus {
              id
              updatedAt
              value
            }
            updateInfo {
              seller {
                id
                shopName
              }
            }
            chiefManager {
              id
              name
              username
              email
              mobileNumber
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  SELLER_LIST_TABLE: gql`
    query sellerListTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            id
            shopName
            sabangnetToken
            approvedAt
            status {
              id
              updatedAt
              value
              text
              requestType
            }
            updateStatus {
              id
              updatedAt
              value
              text
              requestType
            }
            updateInfo {
              seller {
                id
                shopName
              }
            }
            chiefManager {
              id
              name
              username
              email
              mobileNumber
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
  `,
  SELLER_DETAIL_INFO: gql`
    query sellerDetailInfo($id: ID!) {
      seller(id: $id) {
        ...sellerOnly
        sabangnetToken
        chiefManager {
          id
          name
          email
          mobileNumber
        }
        status {
          ...status
        }
        updateStatus {
          ...status
        }
        users {
          edges {
            node {
              id
              name
              email
              mobileNumber
            }
          }
        }
        sellerAsInfos {
          edges {
            node {
              id
              telephoneNumber
            }
          }
        }
        sellerBrands {
          edges {
            node {
              id
              name
            }
          }
        }
        userShippingPresets {
          edges {
            node {
              id
              name
              shippingCompany {
                id
                name
              }
              shippingFee
              freeShippingBaseAmount
              returnFee
              warehouseAddress {
                id
                basicAddress
                detailedAddress
                postNumber
              }
              returnAddress {
                id
                basicAddress
                detailedAddress
                postNumber
              }
              isPackage
              isPrepayment
              guide
              notice
              applyRemoteAreaFee
              freeShippingBaseAmount
              shippingDeadline
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
    ${SELLER_FRAGMENTS.sellerOnly}
  `,
  SELLER_INFO_CHANGE: gql`
    query sellerInfoChange($id: ID!) {
      seller(id: $id) {
        ...sellerOnly
        status {
          ...status
        }
        updateStatus {
          ...status
        }
        updateInfo {
          seller {
            id
            companyName
            shopName
            companyOwnerName
            businessRegistrationNumber
            postNumber
            basicAddress
            detailedAddress
            businessCondition
            businessItem
            businessRegistration
            mailOrderReportNumber
            mailOrderReportRegistration
            bankBookImage
            telephoneNumber
            bankName
            bankCode
            accountNumber
            accountHolderName
            accountManagerName
            accountManagerTelephoneNumber
            taxEmail
            returnExchangeGuide
          }
          asInfo {
            id
            name
            telephoneNumber
          }
          businessRegistration
          mailOrderReportRegistration
          bankBookImage
        }
        users {
          edges {
            node {
              id
              name
              email
              mobileNumber
            }
          }
        }
        sellerAsInfos {
          edges {
            node {
              id
              telephoneNumber
            }
          }
        }
        sellerBrands {
          edges {
            node {
              id
              name
            }
          }
        }
        userShippingPresets {
          edges {
            node {
              id
              name
              returnFee
              shippingCompany {
                id
                name
              }
              returnAddress {
                id
                basicAddress
                detailedAddress
                postNumber
              }
              warehouseAddress {
                id
                basicAddress
                detailedAddress
                postNumber
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.status}
    ${SELLER_FRAGMENTS.sellerOnly}
  `,
  SEARCH_SELLERS: gql`
    query searchSellers($filter: GenericScalar) {
      sellers(filter: $filter) {
        edges {
          node {
            id
            shopName
            companyName
          }
        }
      }
    }
  `,
  SELLER_COUNT: gql`
    query sellerCount {
      sellerCount {
        approvedCount
        suspendCount
        rejectedCount
        updateRequestCount
        updateRejectedCount
      }
    }
  `,
  SELLERS_MINIMUM_INFO: gql`
    query sellersMinimumInfo($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            ...sellerMinimum
            status {
              ...status
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${SELLER_FRAGMENTS.sellerMinimum}
    ${DEFAULT_FRAGMENTS.status}
  `,
  SELLERS_BY_TRANSFER_TABLE: gql`
    query sellersByTransferTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        edges {
          node {
            id
            companyName
            shopName
            approvedAt
            status {
              text
            }
          }
        }
      }
    }
  `,
  SELLERS_BY_PARTNER_LIST: gql`
    query sellersByPartnerList($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            ...sellerOnly
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${SELLER_FRAGMENTS.sellerOnly}
  `,
  SELLERS_BY_MAIN_TABLE: gql`
    query sellersByMainTable($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      sellers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        pageInfo {
          ...pageInfo
        }
        totalCount
        edges {
          cursor
          node {
            ...sellerOnly
            chiefManager {
              ...user
            }
            status {
              ...status
            }
            updateStatus {
              ...status
            }
            updateInfo {
              seller {
                ...sellerOnly
              }
            }
          }
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.status}
    ${SELLER_FRAGMENTS.sellerOnly}
    ${DEFAULT_FRAGMENTS.user}
  `,
  SELLER_BY_PRODUCT_FORM: gql`
    query sellerByProductForm($id: ID!) {
      seller(id: $id) {
        ...sellerOnly
        sellerAsInfos {
          edges {
            node {
              ...asInfo
            }
          }
        }
        userShippingPresets {
          edges {
            node {
              ...shippingPreset
              shippingCompany {
                ...shippingCompany
              }
              warehouseAddress {
                ...sellerAddress
              }
              returnAddress {
                ...sellerAddress
              }
            }
          }
        }
      }
    }
    ${SELLER_FRAGMENTS.sellerOnly}
    ${DEFAULT_FRAGMENTS.asInfo}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.sellerAddress}
  `,
  SELLER_DETAIL: gql`
    query sellerDetail($id: ID!) {
      seller(id: $id) {
        ...sellerOnly
        status {
          ...status
        }
        updateStatus {
          ...status
        }
        users {
          edges {
            node {
              ...user
            }
          }
        }
        sellerBrands {
          edges {
            node {
              id
              name
            }
          }
        }
        userShippingPresets {
          edges {
            node {
              ...shippingPreset
              shippingCompany {
                ...shippingCompany
              }
              warehouseAddress {
                ...sellerAddress
              }
              returnAddress {
                ...sellerAddress
              }
            }
          }
        }
      }
    }
    ${SELLER_FRAGMENTS.sellerOnly}
    ${DEFAULT_FRAGMENTS.user}
    ${DEFAULT_FRAGMENTS.status}
    ${DEFAULT_FRAGMENTS.shippingPreset}
    ${DEFAULT_FRAGMENTS.shippingCompany}
    ${DEFAULT_FRAGMENTS.sellerAddress}
  `,
}

export default SELLER_QUERY_GQLS
