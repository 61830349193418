import { Form, Space } from 'antd'
import styled from 'styled-components'
import { MutationFunction } from '@apollo/client'

import { MFormItemInput } from 'components/formItems'
import { MModal } from 'components/modals'
import { MButton } from 'components/@atomics'
import { DomainModalProps, TypeUseModalProps } from 'containers/hooks/useModal'
import { Store, ValidateErrorEntity } from 'rc-field-form/lib/interface'
import MMutation from 'components/MMutation'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import * as utilData from '@libs/utils/utilData'
import { FORM_ITEM_RULES } from '@constants/constForm'

type ModalProps = {
  orderItem: IModelOrderItem
  addressType: string
}

const settings: { [index: string]: any } = {
  customerAddress: {
    title: '구매자 정보 수정',
    gqlKey: 'updateOrderCustomerAddress',
  },
  customerReturnAddress: {
    title: '구매자 반품지 수정',
    gqlKey: 'updateReturnOrderItemCustomerAddress',
  },
  // sellerAddress: {
  //   title: '수거지 정보 수정',
  //   gqlKey: 'updateReturnOrderItemSellerAddress',
  // },
}

type ModalAddressModificationProps<T = undefined> = DomainModalProps<T, undefined> & {
  useModalDaumPostcodeProps: TypeUseModalProps<T>
}

const ModalAddressModification = ({
  useModalProps,
  useModalDaumPostcodeProps,
  onAPISuccess,
}: ModalAddressModificationProps<ModalProps>) => {
  const { hideModal } = useModalProps
  const { addressType, orderItem } = useModalProps.data
  if (!addressType || !orderItem) return null
  const [form] = Form.useForm()

  const { setActionParams, actionParams } = useModalDaumPostcodeProps
  if (utilData.hasData(actionParams)) {
    form.setFieldsValue(actionParams)
    setActionParams({})
  }

  const setting = settings[addressType]
  const { title, gqlKey } = setting
  let initialValues = {}
  let targetId: string
  // const customerNameField = addressType === 'customerAddress' ? 'name' : 'senderName'
  if (addressType === 'customerAddress') {
    const {
      order: { id, customerAddress },
    } = orderItem
    const { postNumber, basicAddress, detailedAddress, name, mobileNumber } = customerAddress
    targetId = id
    initialValues = { postNumber, basicAddress, detailedAddress, name, mobileNumber }
  } else if (addressType === 'customerReturnAddress') {
    const {
      id,
      returnInfo: { customerAddress },
    } = orderItem
    const { postNumber, basicAddress, detailedAddress, name, mobileNumber } = customerAddress!
    targetId = id
    initialValues = { postNumber, basicAddress, detailedAddress, name, mobileNumber }
  } else {
    const {
      id,
      productOption: {
        product: {
          shippingPreset: { returnAddress },
        },
      },
    } = orderItem
    const { postNumber, basicAddress, detailedAddress } = returnAddress
    targetId = id
    initialValues = { postNumber, basicAddress, detailedAddress }
  }

  const _onFinish = async (values: Store, mutation: MutationFunction) => {
    let variables
    if (addressType !== 'sellerAddress') {
      let mutationVariablesFormat = MUTATION_VARIABLES.UPDATE_ORDER_CUSTOMER_ADDRESS
      if (addressType === 'customerReturnAddress') {
        mutationVariablesFormat = MUTATION_VARIABLES.UPDATE_RETURN_INFO_CUSTOMER_ADDRESS
      }
      variables = mutationVariablesFormat({
        id: targetId,
        // @ts-ignore - name도 필요함
        name: values.name,
        postNumber: values.postNumber,
        basicAddress: values.basicAddress,
        detailedAddress: values.detailedAddress,
        mobileNumber: values.mobileNumber,
      })
    }
    await mutation({ variables })
    hideModal()
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo)
  }

  const Content = () => {
    return (
      <MMutation
        gqlKey={gqlKey}
        onAPISuccess={(res) => {
          if (onAPISuccess) onAPISuccess(res)
        }}
      >
        {(mutation: MutationFunction) => {
          return (
            <Form
              form={form}
              onFinish={(values) => _onFinish(values, mutation)}
              onFinishFailed={onFinishFailed}
              initialValues={initialValues}
            >
              <StyledSpace direction="vertical" size="middle">
                <MButton
                  type="primary"
                  onClick={() => {
                    useModalDaumPostcodeProps.toggleModal()
                  }}
                >
                  도로명 주소 검색
                </MButton>
                <MFormItemInput placeholder="우편번호" name="postNumber" required />
                <MFormItemInput placeholder="주소" name="basicAddress" required />
                <MFormItemInput placeholder="상세주소" name="detailedAddress" />
                {addressType !== 'sellerAddress' && (
                  <>
                    <MFormItemInput
                      placeholder="연락처"
                      name="mobileNumber"
                      rules={[FORM_ITEM_RULES.FORM_ITEM_RULE_PHONE_NUMBER]}
                    />
                    <MFormItemInput
                      placeholder="수취인"
                      // name={customerNameField}
                      name="name"
                      required
                    />
                  </>
                )}
              </StyledSpace>
            </Form>
          )
        }}
      </MMutation>
    )
  }

  return (
    <>
      <MModal
        title={title}
        useModalProps={useModalProps}
        ComponentContent={<Content />}
        form={form}
        maskClosable={false}
      />
    </>
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default ModalAddressModification
