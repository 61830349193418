// @ts-nocheck

import { ReactNode } from 'react'
import { ApolloError, useMutation } from '@apollo/client'

import * as utilData from '@libs/utils/utilData'
import { getResultFromData } from '@libs/utils/utilApi'
import { IDataResult } from 'containers/gqls/data/dataResult'
import { IDataResults } from 'containers/gqls/data/dataResults'
import { MUTATION_KEY2GQLS } from 'containers/gqls'

type resultType = { [index: string]: IDataResult | IDataResults } | undefined
export type onCompletedType = (data: resultType) => void
export type onErrorType = (error: string | ApolloError | undefined) => void | ReactNode

const useMMutation = (gqlKey: string, onAPISuccess?: onCompletedType, onError?: onErrorType) => {
  const gqlMutation = MUTATION_KEY2GQLS[gqlKey]
  // todo: possible need update function
  // https://www.apollographql.com/docs/tutorial/mutations/
  const [mutation, { loading, error, data: _data, called }] = useMutation(gqlMutation, {
    onCompleted(data) {
      // console.log('called : ', called, loading, error)
      if (!onAPISuccess) {
        if (onError) {
          onError('failed to api')
        }
        return
      }
      const hasDataWithoutError = called && data && utilData.hasData(data[gqlKey]) && !error
      if (!hasDataWithoutError) {
        if (onError) {
          onError(error)
        }
        return
      }
      const result = getResultFromData(data[gqlKey])
      onAPISuccess!(result)
    },
    onError,
  })
  return [mutation, { loading, error, data: _data, called }]
}

export default useMMutation
