import * as utilData from '@libs/utils/utilData'
import { IModelCategoryDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelCategoryDefault } from 'containers/models/base/defaultModels'

export interface IModelCategory extends IModelCategoryDefault {
  subProductCountText: string
  visibleStatusText: string
}

export default class ModelCategory extends ModelCategoryDefault {
  subProductCountText: string = ''
  visibleStatusText: string = ''

  constructor(data: IModelCategory) {
    super(data)

    this.subProductCountText = utilData.numberCountText(this.subProductCount)
    this.visibleStatusText = this.isVisible ? '노출 중' : '노출 안함'
  }
}
