import { ChangeEvent, useState } from 'react'
import { Store } from 'rc-field-form/lib/interface'
import { onCompletedType } from 'containers/hooks/useMMutation'

// https://upmostly.com/tutorials/modal-components-react-custom-hooks
// https://sandstorm.de/de/blog/post/reusable-modal-with-client-and-portals.html
// set data
// https://medium.com/better-programming/create-a-custom-usemodal-react-hook-449b5909cc09

// export type ModalData = {
// }

// TS2322: Type 'Dispatch<SetStateAction<undefined>>' is not assignable to type '(func: Function) => void'.

export type TypeUseModalProps<T> = {
  isShowing: boolean
  data: T
  actionParams: any
  setData(modalData: T): void
  toggleModal(): void
  showModal(): void
  hideModal(): void
  setActionParams(params: any): void
  maskClosable?: boolean
  width?: number
}

// export type ModalData = object | undefined

// export type DomainModalProps<T extends ModalData> = {
//   useModalProps: TypeUseModalProps<T>;
//   onAction?: () => void;
// }

type DomainModalType<T> = {
  useModalProps: TypeUseModalProps<T>
  onAction?: () => void
  onCancelAction?: () => void
  onAPISuccess?: onCompletedType
  onFormAction?: (values: Store) => void
}

type DomainModalWithFixedPropsType<T, FixedProps> = {
  useModalProps: TypeUseModalProps<T>
  onAction?: () => void
  onCancelAction?: () => void
  onAPISuccess?: onCompletedType
  onFormAction?: (values: Store) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  loading?: boolean
  fixedProps: FixedProps
}

export type DomainModalProps<T, FixedProps> = FixedProps extends undefined
  ? DomainModalType<T>
  : DomainModalWithFixedPropsType<T, FixedProps>

export type DomainModalWithExtraDataProps<T = undefined, X = undefined> = {
  useModalProps: TypeUseModalProps<T>
  onAction?: () => void
  onFormAction?: (values: Store) => void
  extraData: X // need to set more data without state change in data of useModalProps
}

const useModal = (initIsShowing: boolean = false, initData = {}) => {
  const [isShowing, setIsShowing] = useState(initIsShowing)
  const [data, setData] = useState(initData)
  const [actionParams, setActionParams] = useState(undefined)
  const statusByShowing = (valIsShowing: boolean) => {
    if (!valIsShowing) setData({})
    setIsShowing(valIsShowing)
  }

  const toggleModal = () => {
    statusByShowing(!isShowing)
  }
  const showModal = () => {
    statusByShowing(true)
  }
  const hideModal = () => {
    statusByShowing(false)
  }

  const useModalProps: TypeUseModalProps<any> = {
    isShowing,
    data,
    setData,
    actionParams,
    setActionParams,
    toggleModal,
    showModal,
    hideModal,
  }

  return { useModalProps }
}

export default useModal
