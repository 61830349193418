import { Row, Col } from 'antd'
import styled, { ThemedStyledProps } from 'styled-components'
import { Text12Blue, Text12Red } from 'components/@atomics'

export type MFormBelowCommentProps = {
  comment?: string
  colOffset?: string | number
  color?: 'blue' | 'red'
}

const MFormBelowComment: React.FC<MFormBelowCommentProps> = ({ comment, colOffset, color = 'blue' }) => {
  if (!comment) {
    return null
  }

  return (
    <Container>
      <Col offset={colOffset}>
        {color === 'red' ? <Text12Red>{comment}</Text12Red> : <Text12Blue>{comment}</Text12Blue>}
      </Col>
    </Container>
  )
}

const Container = styled(Row)<ThemedStyledProps<any, any>>`
  margin-top: -25px;
`
export default MFormBelowComment
