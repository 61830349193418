import * as utilCommon from '@libs/utils/utilCommon'
import { ModelRefundInfoDefault } from 'containers/models/base/defaultModels'
import { IModelRefundInfoDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelRefundInfo extends IModelRefundInfoDefault {
  fullBankAccount: string
}

export default class ModelRefundInfo extends ModelRefundInfoDefault {
  fullBankAccount: string = ''

  constructor(data: IModelRefundInfo) {
    super(data)
    this.fullBankAccount = utilCommon.getFullBankAccount(this.bankName, this.accountNumber, this.accountHolderName)
  }
}
