import { FIELD_KEYS, FIELD_VALUES, POLICY_DATA } from '@constants/constData'
import { MLinkRouterButton, Text14Orange } from 'components/@atomics'
import React, { useRef } from 'react'

import MMainTable from 'components/MMainTable'
import { ITableColumn } from 'components/@atomics/MTable'
import ROUTES from '@constants/constRoutes'
import tableCols from 'domains/admin/tableCols'
import { ButtonLeaveUsers, ButtonRequestExcel, ButtonRouteToExcelDownloadList } from 'domains/common/tableButtons'
import { queryParamsProps } from 'components/MQuery'
import MFormItemInput from 'components/formItems/MFormItemInput'
import { FORM_ITEM_RULES } from '@constants/constForm'

const tableColumns: Array<ITableColumn<any>> = [
  tableCols.common.ID(),
  {
    title: '정보수정',
    dataIndex: '_id',
    key: 'editInfo',
    width: 100,
    fixed: 'left',
    render: (v: string) => {
      return (
        <MLinkRouterButton sider={false} href={`${ROUTES.ACCOUNTS.USER_LIST}/${v}`} openNewWindow>
          [수정]
        </MLinkRouterButton>
      )
    },
  },
  tableCols.user.username(),
  tableCols.user.email(),
  tableCols.common.baseText({ title: FIELD_VALUES.FIELD_NAME, dataIndex: FIELD_KEYS.FIELD_NAME }),
  tableCols.common.baseText({
    title: FIELD_VALUES.FIELD_GRADE,
    dataIndex: [FIELD_KEYS.FIELD_GRADE, FIELD_KEYS.FIELD_NAME],
  }),
  tableCols.count.currencyCountText({ title: '적립금(원)', dataIndex: FIELD_KEYS.FIELD_MILEAGE_POINT, width: 100 }),
  tableCols.user.orderCount(),
  tableCols.count.currencyCountText({ title: '결제금액(원)', dataIndex: FIELD_KEYS.FIELD_ORDER_AMOUNT, width: 100 }),
  tableCols.date.dateText({
    title: FIELD_VALUES.FIELD_LAST_LOGIN,
    dataIndex: FIELD_KEYS.FIELD_LAST_LOGIN,
    key: FIELD_KEYS.FIELD_LAST_LOGIN,
  }),
  tableCols.date.dateJoinedText({ title: '가입일' }),
]

const MainTableUserList = () => {
  const selected = useRef<[]>([])
  const refetchRef = useRef<Function | null>(null)
  const onRowSelect = (rows: []) => {
    selected.current = rows
  }
  const queryParams: queryParamsProps = {
    gqlKey: 'userListTable',
    dataKey: 'users',
    variables: {
      filter: {
        userStatus: { exclude: ['LEFT', 'ADMIN_LEFT'] },
      },
      orderBy: '-dateJoined',
    },
  }
  return (
    <MMainTable
      rowSelectionType="checkbox"
      HeaderLeftButtons={[
        <ButtonLeaveUsers selected={selected} refetchRef={refetchRef} />,
        <MLinkRouterButton buttonType="button" type="primary" href={ROUTES.ACCOUNTS.USER_ADD}>
          회원 등록
        </MLinkRouterButton>,
      ]}
      HeaderRightButtons={[
        <ButtonRequestExcel
          title="멤버십 회원 엑셀 파일 요청"
          requestExcelGqlKey="requestMembershipUserExcelFile"
          requestButtonText="멤버십 회원 엑셀 다운"
          maxRowCount={POLICY_DATA.USER_EXCEL_FILE_MAX_ROW_COUNT}
          formItemNodes={[
            <MFormItemInput
              name="telephoneNumbers"
              label="전화번호"
              placeholder="01012341234,01022221111"
              rules={[FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_PHONE_NUMBER_LIST]}
              extra={
                <Text14Orange>{'* 예제와 같은 형식으로 입력해주세요.\n예제) 01012341234,01022221111'}</Text14Orange>
              }
            />,
          ]}
          onFormItemsNodesToSubmitValues={(values) => {
            const phoneNumbersStr: string | undefined = values['telephoneNumbers']
            if (phoneNumbersStr) {
              return { filter: { mobileNumbers: phoneNumbersStr.replace('-', '').split(',') } }
            }
          }}
        />,
        <ButtonRequestExcel
          title="회원 목록 엑셀 파일 요청"
          requestExcelGqlKey="requestUserExcelFile"
          maxRowCount={POLICY_DATA.USER_EXCEL_FILE_MAX_ROW_COUNT}
        />,
        <ButtonRouteToExcelDownloadList />,
      ]}
      TitleSet={{ title: '회원 목록', unit: '명' }}
      columns={tableColumns}
      queryParams={queryParams}
      onRowSelect={onRowSelect}
      refetchRef={refetchRef}
    />
  )
}

export default MainTableUserList
