import * as utilApi from '@libs/utils/utilApi'

export interface IModelBase {
  id: string
  _id: string
  __typename: string
}

function isNumeric(str: string | number) {
  if (typeof str !== 'string') return false // we only process strings!
  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  // ...and ensure strings of whitespace fail
  return !Number.isNaN(parseFloat(str as string)) && !Number.isNaN(Number(str))
}

const nonNumbers = [
  'mobileNumber',
  'orderNumber',
  'orderItemNumber',
  'notificationMobileNumber',
  'postNumber',
  'accountManagerTelephoneNumber',
  'telephoneNumber',
  'accountNumber',
  'resultCode',
  'bankCode',
  'code',
  'pgTid'
]

export default class ModelBase implements IModelBase {
  id: string = ''
  _id: string = ''
  __typename: string = ''

  makeNumberIfAny = (data: any): { id: string } | any => {
    const _data: { [key: string]: any } = {}
    if (data && Object.keys(data).length > 0) {
      Object.entries(data).forEach(([k, v]) => {
        if (nonNumbers.includes(k)) {
          _data[k] = v
        } else if (isNumeric(v as string | number)) {
          _data[k] = Number(v)
        } else {
          _data[k] = v
        }
      })
    }
    return _data
  }

  constructor(data: any) {
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
    if (_data && _data.id) {
      this._id = utilApi.decodeId(_data.id).id
    }
  }
}
