import { gql } from '@apollo/client'
import USER_FRAGMENTS from 'containers/gqls/accounts/user/fragments'

const CUSTOMER_GRADE_FRAGMENTS = {
  get customerGradeOnly() {
    return gql`
      fragment customerGradeOnly on CustomerGradeType {
        id
        name
        minOrderAmount
        rewardRate
        isActivated
        gradeUsers {
          edges {
            node {
              ...userOnly
            }
          }
        }
      }
      ${USER_FRAGMENTS.userOnly}
    `
  },
}

export default CUSTOMER_GRADE_FRAGMENTS
