import { FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { FORM_ITEM_RULES } from '@constants/constForm'
import { IModelInitData } from 'containers/models/modelInitData'
import React from 'react'
import { Form, message as antdMessage } from 'antd'
import { MutationFunction } from '@apollo/client'
import { Store } from 'redux'
import { useSelector } from 'react-redux'
import { RootState } from '@libs/redux/store'
import { hasData } from '@libs/utils/utilData'
import { MFormItemHidden, MFormItemInput, MFormItemSelect } from 'components/formItems'
import { MModal } from 'components/modals'
import { DomainModalProps } from 'containers/hooks/useModal'
import MMutation from 'components/MMutation'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'

type ModalApprovalHistoryProps = {
  id: string
  accountNumber: string
  accountHolderName: string
  bankCode: string
}

const FORM_ITEM_PROPS_BANK_CODE = (initData: IModelInitData) => {
  return {
    label: FIELD_VALUES.FIELD_BANK_CODE,
    name: FIELD_KEYS.FIELD_BANK_CODE,
    selectOptions: initData.bankSelectOptions,
    required: true,
    rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
  }
}

const FORM_ITEM_PROPS_ACCOUNT_NUMBER = {
  label: FIELD_VALUES.FIELD_ACCOUNT_NUMBER,
  name: FIELD_KEYS.FIELD_ACCOUNT_NUMBER,
  required: true,
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
}

const FORM_ITEM_PROPS_ACCOUNT_HOLDER_NAME = {
  label: FIELD_VALUES.FIELD_ACCOUNT_HOLDER_NAME,
  name: FIELD_KEYS.FIELD_ACCOUNT_HOLDER_NAME,
  required: true,
  rules: [FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED],
}

const ModalBankAccount = ({ useModalProps }: DomainModalProps<ModalApprovalHistoryProps, undefined>) => {
  const initData = useSelector((state: RootState) => state.reduxDataReducers.initData)
  if (!hasData(initData)) return null
  const [form] = Form.useForm()

  const onFinish = async (values: Store, mutation: MutationFunction) => {
    // @ts-ignore
    const variables = MUTATION_VARIABLES.UPDATE_REFUND_ACCOUNT({ ...values })
    await mutation({ variables })
  }

  return (
    <MModal
      title="환불 계좌 수정"
      form={form}
      useModalProps={useModalProps}
      ComponentContent={
        <MMutation
          gqlKey="updateRefundAccount"
          onAPISuccess={() => {
            antdMessage.success('환불 계좌가 수정되었습니다.')
            useModalProps.hideModal()
          }}
        >
          {(mutation: MutationFunction) => {
            return (
              <Form
                form={form}
                labelCol={{ span: 6 }}
                initialValues={{
                  id: useModalProps.data.id,
                  accountNumber: useModalProps.data.accountNumber,
                  accountHolderName: useModalProps.data.accountHolderName,
                  bankCode: useModalProps.data.bankCode,
                }}
                onFinish={(values) => onFinish(values, mutation)}
              >
                <MFormItemHidden name="id" />
                <MFormItemSelect {...FORM_ITEM_PROPS_BANK_CODE(initData)} />
                <MFormItemInput {...FORM_ITEM_PROPS_ACCOUNT_NUMBER} />
                <MFormItemInput {...FORM_ITEM_PROPS_ACCOUNT_HOLDER_NAME} />
              </Form>
            )
          }}
        </MMutation>
      }
    />
  )
}

export default ModalBankAccount
