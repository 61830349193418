import React, { FC } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { UrlObject } from 'url'
import { themes } from '@constants'
import useUserInfo from 'containers/hooks/useUserInfo'

export type LinkButtonProps = {
  goBack?: boolean
  url?: string | UrlObject
  setAnchorHref?: boolean
  textUnderline?: boolean
  noHoverEffect?: boolean
  shouldLogin?: boolean
  downloadFilename?: string
  openNewWindow?: boolean
}

const MLinkButton: FC<LinkButtonProps> = ({
  url,
  goBack,
  setAnchorHref = true,
  textUnderline = true,
  noHoverEffect = false,
  shouldLogin = false,
  downloadFilename,
  openNewWindow = false,
  children,
}) => {
  const router = useRouter()
  const { checkLogin } = useUserInfo.useAuthenticatedRoute()

  const goToUrl = (e: React.MouseEvent | React.KeyboardEvent, _url: string | UrlObject | undefined) => {
    e.preventDefault()

    const hasLoggedIn = checkLogin(shouldLogin)
    if (!hasLoggedIn) return

    if (goBack) {
      router.back()
    } else {
      if (!_url) return
      router.push(_url)
    }
  }

  // Next/Link 쓸 경우 카루셀 드래그 상태에서 onClick 방지가 안됨
  let hrefString: string
  if (typeof url === 'string') {
    hrefString = url
  } else {
    hrefString = url?.pathname || ''
  }
  return (
    <StyledAnchor
      role="button"
      noHoverEffect={noHoverEffect}
      textUnderline={textUnderline}
      tabIndex={0}
      target={openNewWindow ? '_blank' : undefined}
      download={downloadFilename}
      href={setAnchorHref ? hrefString : undefined}
      onClick={downloadFilename === undefined ? (e) => goToUrl(e, url) : undefined}
      onKeyDown={downloadFilename === undefined ? (e) => goToUrl(e, url) : undefined}
    >
      {/* <div> */}
      {children}
      {/* </div> */}
    </StyledAnchor>
  )
}

type styledAnchorProps = {
  textUnderline: boolean
  noHoverEffect: boolean
}

const StyledAnchor = styled.a<styledAnchorProps>`
  color: ${themes.colors.blackAlpha100};
  text-decoration: ${({ textUnderline }) => {
    if (textUnderline) {
      return 'underline'
    }

    return 'none'
  }};

  :hover {
    text-decoration: ${({ noHoverEffect }) => {
      if (noHoverEffect) {
        return 'none'
      }

      return 'underline'
    }};
    text-decoration-color: ${themes.colors.blackAlpha100};
  }
`

export default MLinkButton
