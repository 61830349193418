import { Button, Upload } from 'antd'
import { UploadOutlined, PictureOutlined } from '@ant-design/icons'
import { UploadChangeParam, UploadProps } from 'antd/lib/upload'
import { setUploadFileList } from '@libs/utils/utilForm'
import { optionProps } from 'components/@atomics/MImageUpload'

type uploadConfigProps = {
  enableUploadAction?: boolean
  showUploadList?: boolean
}

// type initialValueProps = {
//   uid: string;
//   name: string;
//   status: string;
//   url: string;
// }

export type MFileUploadProps = UploadProps & {
  option?: optionProps
  buttonType?: string
  title?: string
  loading?: boolean
  uploadConfig?: uploadConfigProps
  onSelectedChange?: (info: UploadChangeParam) => void
}

// type MUploadButtonProps = {
//   buttonType?: string;
//   title?: string;
//   onSelectedChange?: (info: UploadChangeParam) => void;
//   // onChange is triggered at Antd Form.item
//   // onChange?: (info: UploadChangeParam) => void;
//   uploadConfig?: uploadConfigProps;
//   initialValues?: initialValueProps[];
//   name?: string;
//   maxCount?: number;
//   disabled?: boolean;
//   accept?: string;
// }

const MUploadButton = ({ name = 'file', disabled = false, fileList = [], loading, ...props }: MFileUploadProps) => {
  const { title, buttonType, onSelectedChange, uploadConfig = {}, ...restProps } = props
  const { showUploadList = true } = uploadConfig
  const _fileList = setUploadFileList(fileList)

  const isButtonImage: boolean = buttonType === 'image'
  return (
    <Upload
      name={name}
      fileList={_fileList}
      disabled={disabled}
      onChange={(info) => {
        if (info.file.status !== 'uploading') {
          if (onSelectedChange) onSelectedChange(info)
        }
      }}
      showUploadList={showUploadList}
      {...restProps}
    >
      <Button
        type={isButtonImage ? 'text' : 'primary'}
        icon={isButtonImage && <PictureOutlined style={{ fontSize: '22px' }} />}
        loading={loading}
      >
        {!isButtonImage && <UploadOutlined />} {title}
      </Button>
    </Upload>
  )
}

export default MUploadButton
