import { Col } from 'antd'
import { ColProps } from 'antd/lib/col'
import styled, { CSSObject } from 'styled-components'

type MCardWrapperColumnProps = ColProps & {
  children: JSX.Element
  style?: CSSObject
}

const MCardWrapperColumn = ({ children, ...rest }: MCardWrapperColumnProps) => {
  return (
    <StyledCol xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }} xxl={{ span: 4 }} {...rest}>
      {children}
    </StyledCol>
  )
}

const StyledCol = styled(({ ...rest }) => <Col {...rest} />)<MCardWrapperColumnProps>`
  ${(props) => props.style}
`

export { MCardWrapperColumn as default }
