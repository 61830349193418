import { ITableColumn } from 'components/@atomics/MTable'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import tableCols from 'domains/admin/tableCols'
import { MTable } from 'components/@atomics'

const TableOrderItemDefault = ({ orderItems }: { orderItems: IModelOrderItem[] }) => {
  const tableColumns: Array<ITableColumn<IModelOrderItem>> = [
    tableCols.common.baseTextFromModel({ title: '주문번호', dataIndex: 'order', key: 'orderNumber' }),
    tableCols.common.baseText({ title: '상품주문번호', dataIndex: 'orderItemNumber' }),
    // tableCols.product.productName({ dataIndex: 'productOption', hasLink: false }),
    tableCols.common.baseText({ title: '상품명', dataIndex: ['productOption', 'product', 'name'] }),
  ]
  return <MTable columns={tableColumns} dataSource={orderItems} scroll={600} pagination={false} />
}

export default TableOrderItemDefault
