import { ChangeEvent, CSSProperties, RefObject } from 'react'
import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'

import { MFormItemWrapper } from 'components'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { MFormBelowComment } from 'components/@atomics/forms'

export type MFormItemInputProps = MFormItemWrapperProps &
  Omit<InputProps, 'name'> & {
    inputStyle?: CSSProperties
    comment?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    inputRef?: RefObject<Input>
  }

const MFormItemInput = (props: MFormItemInputProps) => {
  const {
    id,
    labelCol,
    inputStyle,
    comment,
    disabled = false,
    prefix,
    suffix,
    type,
    placeholder,
    inputRef,
    accept,
    max,
    defaultValue,
    ...formItemProps
  } = props
  return (
    <>
      <MFormItemWrapper {...formItemProps} labelCol={labelCol}>
        <Input
          style={inputStyle}
          ref={inputRef}
          {...(type === 'number'
            ? {
                onWheel: (event) => (event.target as HTMLInputElement).blur(),
              }
            : {})}
          {...{ id, disabled, prefix, suffix, placeholder, type, accept, max, defaultValue }}
        />
      </MFormItemWrapper>
      <MFormBelowComment comment={comment} colOffset={labelCol?.span} />
    </>
  )
}

export default MFormItemInput
