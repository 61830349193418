import { useDispatch, useSelector } from 'react-redux'

import * as utilForm from '@libs/utils/utilForm'
import { RootState, store } from '@libs/redux/store'
import { MSelect } from 'components/@atomics'
import { doSetMainTableVariables } from '@libs/redux/reduxUI'

type MListSortSelectProps = {
  filterOptions: string[]
  selectWidth?: number
}

function MListSortSelect(props: MListSortSelectProps) {
  const dispatch = useDispatch()
  const mainTableVariables = useSelector((state: RootState) => state.reduxUIReducers.mainTableVariables)

  const { filterOptions = [], selectWidth } = props
  const options = utilForm.makeSelectOptions(filterOptions, true)
  const defaultValue = options.length > 0 ? options[0].value : ''
  const updatedValue = mainTableVariables?.orderBy
  return (
    <MSelect
      filterOptions={options}
      selectWidth={selectWidth || 130}
      onChange={(value: string) => {
        const variables = store.getState().reduxUIReducers.mainTableVariables
        // 전체 초기화 시키는 버전
        // dispatch(doSetMainTableSort(value))
        // orderBy만 바꾸는 버전
        dispatch(
          doSetMainTableVariables({
            ...variables,
            orderBy: value,
          })
        )
      }}
      defaultValue={defaultValue}
      value={updatedValue}
    />
  )
}

export default MListSortSelect
