import { FC, useEffect, MutableRefObject } from 'react'
import { Form, Row, Col, message } from 'antd'
import { MutationFunction } from '@apollo/client'

import { FIELD_KEYS, FIELD_VALUES } from '@constants/constData'
import { NOTICE_KIND_TYPE, NOTICE_KIND_TYPE_TEXT, NOTICE_RECEIVER_TYPE } from '@constants/constDomain'
import {
  MFormItemSwitch,
  MFormItemInput,
  MFormItemTextArea,
  MFormItemFileUpload,
  MFormItemRadio,
  MFormItemInfo,
} from 'components'
import { MButton, MOuterCard } from 'components/@atomics'
import MMutation from 'components/MMutation'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { IModelNotice } from 'containers/models/modelNotice'
import { ButtonDeleteNotice } from 'domains/common'

type FormNoticeProps = {
  hideForm: () => void
  onSubmitForm: () => void
  initialValues?: IModelNotice
  refetchRef: MutableRefObject<Function>
}

const FORM_NOTICE_OPTIONS = {
  KIND: {
    label: FIELD_VALUES.FIELD_KIND,
    name: FIELD_KEYS.FIELD_KIND,
    labelCol: { span: 2 },
    options: [
      { value: NOTICE_KIND_TYPE.NOTICE_KIND_GENERAL, label: NOTICE_KIND_TYPE_TEXT.NOTICE_KIND_GENERAL },
      { value: NOTICE_KIND_TYPE.NOTICE_KIND_BILLING, label: NOTICE_KIND_TYPE_TEXT.NOTICE_KIND_BILLING },
      { value: NOTICE_KIND_TYPE.NOTICE_KIND_MANUAL, label: NOTICE_KIND_TYPE_TEXT.NOTICE_KIND_MANUAL },
    ],
  },
  IS_IMPORTANT: {
    label: FIELD_VALUES.FIELD_IS_IMPORTANT,
    name: FIELD_KEYS.FIELD_IS_IMPORTANT,
    labelCol: { span: 2 },
    comment: '* 체크 시 등록일에 상관없이 목록 상단에 노출됩니다.',
  },
  TITLE: {
    label: FIELD_VALUES.FIELD_TITLE,
    name: FIELD_KEYS.FIELD_TITLE,
    labelCol: { span: 2 },
    wrapperCol: { span: 10 },
    style: { marginTop: 20 },
  },
  BODY: {
    label: FIELD_VALUES.FIELD_BODY,
    name: FIELD_KEYS.FIELD_BODY,
    rows: 10,
    labelCol: { span: 2 },
    wrapperCol: { span: 10 },
  },
  ATTACHMENT: {
    label: FIELD_VALUES.FIELD_ATTACHMENT,
    name: FIELD_KEYS.FIELD_ATTACHMENT,
    required: false,
    labelCol: { span: 2 },
    wrapperCol: { span: 10 },
  },
  AUTHOR: {
    key: FIELD_KEYS.FIELD_AUTHOR_WITH_DATE,
    label: FIELD_VALUES.FIELD_AUTHOR_WITH_DATE,
    labelCol: { span: 2 },
    wrapperCol: { span: 10 },
    style: { marginTop: 10 },
  },
}

const FormNotice: FC<FormNoticeProps> = ({ hideForm, onSubmitForm, initialValues, refetchRef }) => {
  const isEdit = !!initialValues
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    if (initialValues) {
      form.setFieldsValue(initialValues)
    }
  }, [initialValues])

  const onSubmit = (values: any, mutation: MutationFunction) => {
    values.receiverType = NOTICE_RECEIVER_TYPE.SELLER
    const variables = isEdit
      ? MUTATION_VARIABLES.UPDATE_NOTICE({ id: initialValues!.id, ...values })
      : MUTATION_VARIABLES.CREATE_NOTICE({ ...values })
    mutation({ variables })
  }

  const onCancel = () => {
    hideForm()
  }

  const onSubmitSucceed = () => {
    hideForm()
    onSubmitForm()
  }

  return (
    <MMutation gqlKey={isEdit ? 'updateNotice' : 'createNotice'} onAPISuccess={onSubmitSucceed}>
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => (
        <MOuterCard title="공지사항 상세">
          <Form
            form={form}
            name="noticeForm"
            initialValues={{
              title: '',
              body: '',
              isImportant: false,
              kind: NOTICE_KIND_TYPE.NOTICE_KIND_GENERAL,
            }}
            onFinish={(values) => {
              const { title, body } = values
              if (!title && !body) {
                return message.warn('제목과 내용을 입력해주세요.')
              }
              if (!title) {
                return message.warn('제목을 입력해 주세요.')
              }
              if (!body) {
                return message.warn('내용을 입력해 주세요.')
              }
              onSubmit(values, mutation)
            }}
          >
            <MFormItemRadio {...FORM_NOTICE_OPTIONS.KIND} />
            <MFormItemSwitch {...FORM_NOTICE_OPTIONS.IS_IMPORTANT} />
            <MFormItemInfo text={initialValues?.authorWithDateText} {...FORM_NOTICE_OPTIONS.AUTHOR} />
            <MFormItemInput {...FORM_NOTICE_OPTIONS.TITLE} />
            <MFormItemTextArea {...FORM_NOTICE_OPTIONS.BODY} />
            <MFormItemFileUpload {...FORM_NOTICE_OPTIONS.ATTACHMENT} />
          </Form>

          <Row justify="end" gutter={16} style={{ marginRight: '2%' }}>
            <Col>
              {isEdit ? (
                <ButtonDeleteNotice
                  noticeData={initialValues}
                  refetchRef={refetchRef}
                  title="삭제"
                  hideForm={hideForm}
                />
              ) : (
                <MButton width={80} onClick={onCancel}>
                  취소
                </MButton>
              )}
            </Col>
            <Col>
              <MButton type="primary" width={80} htmlType="submit" form="noticeForm" loading={loading}>
                {isEdit ? '수정' : '등록'}
              </MButton>
            </Col>
          </Row>
        </MOuterCard>
      )}
    </MMutation>
  )
}

export default FormNotice
