import { FIELD_KEYS } from '@constants/constData'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { Row, Col, Radio, Input, List, Space } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { RowProps } from 'antd/lib/row'
import { ColProps } from 'antd/lib/col'
import { RadioChangeEvent } from 'antd/lib/radio'
import useModal from 'containers/hooks/useModal'
import { IModelUser } from 'containers/models/modelUser'
import { MConfirmModal } from 'components/modals'
import { MFormItemCheckboxSingle, MFormItemHidden, MFormItemWrapper } from 'components/formItems'
import { MButton, MSelect, MTable, MTextButton, Text14Normal, Text14Red } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import tableCols from 'domains/admin/tableCols'
import ButtonSelectFromTable from 'domains/common/ButtonSelectFromTable'
import MQuery from 'components/MQuery'
import { useSelector } from 'react-redux'
import { RootState } from '@libs/redux/store'
import * as utilData from '@libs/utils/utilData'

type sendTargetStateProps = {
  targetKey: string
  grade: string
  agreeMarketingGrade: boolean
  agreeMarketingAll: boolean
  selectedUsers: IModelUser[]
}

type FormItemSendTargetProps = {
  form: FormInstance
  sendType: 'sms' | 'email'
  sendTargetState: sendTargetStateProps
  setSendTargetState: Function
  doClear?: number
}

const DEFAULT_ROW_VALUE: RowProps = {
  align: 'middle',
  gutter: [24, 12],
  wrap: false,
}

const DEFAULT_COL_VALUE: ColProps = { span: 10 }

const FORM_ITEM_OPTIONS_TARGET = {
  label: '대상 회원',
  name: 'targetKey',
  labelCol: { span: 3 },
  // required: true,
}

const tableColumns: Array<ITableColumn<IModelUser>> = [
  tableCols.common.ID(),
  tableCols.user.username(),
  tableCols.common.baseText({ title: '이름', dataIndex: 'name' }),
  tableCols.common.baseTextFromModel({ title: '등급', dataIndex: 'grade', key: 'name' }),
  tableCols.common.baseText({ title: '휴대폰번호', dataIndex: 'mobileNumber' }),
]

const directTableColumns = (setSendTargetState: Function, sendTargetState: sendTargetStateProps) => {
  const { selectedUsers } = sendTargetState
  return [
    ...tableColumns,
    {
      title: '삭제',
      width: 80,
      dataIndex: 'id',
      render: (v: string) => {
        return (
          <MTextButton
            onClick={() => {
              const _users = selectedUsers.filter((x) => x.id !== v)
              setSendTargetState({
                ...sendTargetState,
                selectedUsers: _users,
              })
            }}
          >
            [삭제]
          </MTextButton>
        )
      },
    },
  ]
}

type ShowSelectedUserProps = {
  targetKey: string
  grade: string
  agreeMarketingGrade: boolean
  agreeMarketingAll: boolean
  sendTargetState: sendTargetStateProps
  setSendTargetState: Function
}

const ShowSelectedUser = ({
  targetKey,
  grade,
  agreeMarketingGrade,
  agreeMarketingAll,
  sendTargetState,
  setSendTargetState,
}: ShowSelectedUserProps) => {
  const { useModalProps } = useModal()

  if (targetKey === 'manual') return null
  const { selectedUsers } = sendTargetState

  const getQueryFilters = (): object => {
    const filter: { [index: string]: any } = { userStatus: { exclude: ['LEFT', 'ADMIN_LEFT'] } }
    if (targetKey === 'grade') {
      filter.grade = grade
      filter.agreeMarketing = agreeMarketingGrade
    } else if (targetKey === 'all') {
      filter.agreeMarketing = agreeMarketingAll
    }
    return filter
  }

  const filters = getQueryFilters()

  const Show = ({ totalCount, users }: { totalCount: number; users?: IModelUser[] }) => {
    const tableUsers = targetKey === 'direct' ? selectedUsers : users
    let columns = tableColumns
    if (targetKey === 'direct') {
      columns = directTableColumns(setSendTargetState, sendTargetState)
    }
    return (
      <Space size="large">
        <Text14Normal>
          총 <Text14Red>{utilData.numberWithCommas(totalCount)}</Text14Red>명
        </Text14Normal>
        <MButton onClick={() => useModalProps.toggleModal()}>목록 보기</MButton>
        <MConfirmModal
          useModalProps={{
            width: 1200,
            ...useModalProps,
          }}
          fixedProps={{
            title: '회원 목록',
            description: <MTable columns={columns} dataSource={tableUsers} />,
          }}
        />
      </Space>
    )
  }

  const ShowByQuery = ({
    totalCount,
    users,
    refetch,
  }: {
    totalCount: number
    users: IModelUser[]
    refetch: Function
  }) => {
    if (targetKey !== 'direct' && refetch) refetch()
    return <Show totalCount={totalCount} users={users} />
  }

  if (targetKey === 'direct') {
    return <Show totalCount={selectedUsers.length} />
  }
  const variables = { filter: { ...filters } }
  return (
    <MQuery queryParams={{ gqlKey: 'usersByTransferTable', variables }}>
      {({ data, refetch }: any) => {
        if (!utilData.hasData(data)) return null
        return <ShowByQuery totalCount={data.users.totalCount} users={data.users.data} refetch={refetch} />
      }}
    </MQuery>
  )
}

// emails
//
// or
//
// targetKey - {direct | grade | all}
// grade - {gradeId}
// agreeMarketing - boolean

type ManualSmsListProps = {
  form: FormInstance
  sendTargetState: sendTargetStateProps
}

const ManualSmsList = ({ form, sendTargetState }: ManualSmsListProps) => {
  const [value, setValue] = useState<string>('')
  const [dataSource, setDataSource] = useState<Array<string>>([])
  const manualTableHeight = Math.min((dataSource.length + 1) * 50, 400)
  form.setFieldsValue({ manual: dataSource })
  if (sendTargetState.targetKey !== 'manual') return null
  return (
    <>
      <MFormItemHidden name="manual" />
      <Row justify="space-between">
        <Col span={18}>
          <Input value={value} style={{ marginBottom: 0 }} onChange={(e) => setValue(e.target.value)} />
        </Col>
        <Col>
          <MButton
            type="primary"
            onClick={() => {
              setDataSource((prevState) => prevState.concat(value))
              setValue('')
            }}
          >
            추가
          </MButton>
        </Col>
      </Row>
      <div style={{ height: 14 }} />
      <div style={{ height: manualTableHeight, overflow: 'auto' }}>
        <List
          size="small"
          bordered
          header={
            <Row justify="space-between">
              <Col style={{ marginTop: 8 }}>
                <Text14Normal>{`총 ${utilData.numberWithCommas(dataSource.length)}명`}</Text14Normal>
              </Col>
              <Col>
                <MButton onClick={() => setDataSource([])}>전체 삭제</MButton>
              </Col>
            </Row>
          }
          dataSource={dataSource}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta description={item} />
              <MButton
                onClick={() => {
                  setDataSource(dataSource.filter((x) => x !== item))
                }}
              >
                삭제
              </MButton>
            </List.Item>
          )}
        />
      </div>
    </>
  )
}

const FormItemSendTarget = ({
  form,
  sendType,
  sendTargetState,
  setSendTargetState,
  doClear,
}: FormItemSendTargetProps) => {
  const initData = useSelector((state: RootState) => state.reduxDataReducers.initData)
  if (!utilData.hasData(initData)) return null

  const { targetKey, grade, agreeMarketingGrade, agreeMarketingAll, selectedUsers } = sendTargetState
  const hiddenFormItemName: FIELD_KEYS.FIELD_EMAILS | FIELD_KEYS.FIELD_MOBILE_NUMBERS =
    sendType === 'email' ? FIELD_KEYS.FIELD_EMAILS : FIELD_KEYS.FIELD_MOBILE_NUMBERS

  useEffect(() => {
    form.setFieldsValue({ targetKey: 'direct' })
  }, [])

  useEffect(() => {
    if (form) {
      if (sendType === 'email') {
        form.setFieldsValue({ emails: targetKey === 'direct' ? _.map(selectedUsers, 'email') : null })
      }
      if (sendType === 'sms') {
        form.setFieldsValue({
          mobileNumbers: targetKey === 'direct' ? _.map(selectedUsers, (x) => x.mobileNumber) : null,
        })
      }
    }
  }, [JSON.stringify(selectedUsers)])

  useEffect(() => {
    setSendTargetState({
      ...sendTargetState,
      selectedUsers: [],
      targetKey: 'direct',
    })
  }, [doClear])

  const onChangeRadio = (e: RadioChangeEvent): void => {
    form.setFieldsValue({ targetKey: e.target.value })
    setSendTargetState({
      ...sendTargetState,
      targetKey: e.target.value,
    })
  }

  return (
    <>
      <MFormItemWrapper {...FORM_ITEM_OPTIONS_TARGET}>
        <Radio.Group onChange={onChangeRadio} value={targetKey}>
          <Row {...DEFAULT_ROW_VALUE}>
            <Col {...DEFAULT_COL_VALUE}>
              <Radio value="direct">회원 직접 선택</Radio>
            </Col>
            <Col {...DEFAULT_COL_VALUE}>
              <ButtonSelectFromTable
                disabled={targetKey !== 'direct'}
                modelType="user"
                title="회원 선택"
                isPlural
                doClear={doClear}
                data={sendTargetState.selectedUsers}
                onAction={(selectedValues) => {
                  setSendTargetState({
                    ...sendTargetState,
                    selectedUsers: selectedValues,
                  })
                }}
              />
            </Col>
          </Row>
          <Row {...DEFAULT_ROW_VALUE}>
            <Col {...DEFAULT_COL_VALUE}>
              <Radio value="grade">회원 등급 선택</Radio>
            </Col>
            <Col {...DEFAULT_COL_VALUE}>
              <MFormItemWrapper style={{ marginBottom: 0 }} name="grade">
                <MSelect
                  onChange={(value: string) => {
                    setSendTargetState({
                      ...sendTargetState,
                      grade: value,
                    })
                  }}
                  placeholder="등급 선택"
                  disabled={targetKey !== 'grade'}
                  style={{ width: '120px' }}
                  filterOptions={initData.customerGradeSelectOptions}
                />
              </MFormItemWrapper>
            </Col>
            <Col {...DEFAULT_COL_VALUE}>
              <MFormItemCheckboxSingle
                name="agreeMarketingGrade"
                text="수신 동의한 회원만"
                disabled={targetKey !== 'grade'}
                onChange={(checked) => {
                  setSendTargetState({
                    ...sendTargetState,
                    agreeMarketingGrade: checked || undefined,
                  })
                }}
                style={{ marginBottom: 0 }}
              />
            </Col>
          </Row>
          <Row {...DEFAULT_ROW_VALUE}>
            <Col {...DEFAULT_COL_VALUE}>
              <Radio value="all">회원 전체</Radio>
            </Col>
            <Col {...DEFAULT_COL_VALUE}>
              <MFormItemCheckboxSingle
                name="agreeMarketingAll"
                text="수신 동의한 회원만"
                disabled={targetKey !== 'all'}
                onChange={(checked) => {
                  setSendTargetState({
                    ...sendTargetState,
                    agreeMarketingAll: checked || undefined,
                  })
                }}
                style={{ marginBottom: 0 }}
              />
            </Col>
          </Row>
          {sendType === 'sms' && (
            <>
              <Row {...DEFAULT_ROW_VALUE}>
                <Col {...DEFAULT_COL_VALUE}>
                  <Radio value="manual">직접 입력</Radio>
                </Col>
              </Row>
              <Row {...DEFAULT_ROW_VALUE}>
                <Col span={16}>
                  <ManualSmsList form={form} sendTargetState={sendTargetState} />
                </Col>
              </Row>
            </>
          )}
        </Radio.Group>
        <ShowSelectedUser
          targetKey={targetKey}
          grade={grade}
          agreeMarketingGrade={agreeMarketingGrade}
          agreeMarketingAll={agreeMarketingAll}
          sendTargetState={sendTargetState}
          setSendTargetState={setSendTargetState}
        />
      </MFormItemWrapper>
      <MFormItemWrapper name={hiddenFormItemName} hidden>
        <Input />
      </MFormItemWrapper>
    </>
  )
}

export default FormItemSendTarget
