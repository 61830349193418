import { ModelRefundShippingInfoDefault } from 'containers/models/base/defaultModels'
import { IModelRefundShippingInfoDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelRefundShippingInfo extends IModelRefundShippingInfoDefault {}

export default class ModelRefundShippingInfo extends ModelRefundShippingInfoDefault {
  constructor(data: IModelRefundShippingInfo) {
    super(data)
  }
}
