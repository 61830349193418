import { ReactElement, MutableRefObject, CSSProperties } from 'react'
import { Descriptions } from 'antd'
import { DescriptionsProps } from 'antd/lib/descriptions'
import styled from 'styled-components'
import { FormInstance } from 'antd/es/form'
import { IModelProductQna } from 'containers/models/modelProductQna'

export type MDescriptionsItemOptions = {
  key?: string
  label?: string
  dataIndex?: string
  input?: boolean
  content?: any
  contents?: any
  span?: number
  form?: FormInstance
  refetchRef?: MutableRefObject<Function>
  withTime?: boolean
  setCurrentQna?: (qna: IModelProductQna) => void
}

export type MDescriptionsTableDataProps = {
  title?: string
  itemOptions: MDescriptionsItemOptions[]
  refetchRef?: MutableRefObject<Function>
}

export type MDescriptionsTableProps = {
  tableData?: MDescriptionsTableDataProps
  width?: string
  max?: boolean
  column?: number
}

type CustomDescriptionProps = DescriptionsProps & MDescriptionsTableProps

const MDescriptionsTable = ({
  width,
  tableData,
  max,
}: MDescriptionsTableProps): ReactElement<CustomDescriptionProps> => {
  const labelStyle: CSSProperties = { width: '120px' }
  return (
    <StyledDescriptions title={tableData?.title} column={1} width={width} max={max} bordered>
      {tableData?.itemOptions?.map((x) => {
        const { content, ...rest } = x
        return (
          <Descriptions.Item labelStyle={labelStyle} {...rest}>
            {content}
          </Descriptions.Item>
        )
      })}
    </StyledDescriptions>
  )
}

const setCustomWidth = (props: MDescriptionsTableProps): string => {
  if (props.max) {
    return '100%'
  }
  if (!props.max && props.width) {
    return props.width
  }
  return '580px'
}

const StyledDescriptions = styled(Descriptions)<CustomDescriptionProps>`
  width: ${setCustomWidth};

  .ant-descriptions-item-label {
    width: ${(props) => props.max && '20%'};
  }
`

export default MDescriptionsTable
