import { useState } from 'react'
import { Form, message as antdMessage, Row, Col } from 'antd'
import { MutationFunction } from '@apollo/client'

import { FORM_ITEM_RULES } from '@constants/constForm'
import MMutation from 'components/MMutation'
import { MButton } from 'components/@atomics'
import { MFormItemInput } from 'components/formItems'
import { MModal } from 'components/modals'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'
import { MModalProps } from 'components/modals/MModal'
import { DomainModalProps } from 'containers/hooks/useModal'
import useUserInfo from 'containers/hooks/useUserInfo'
import { onCompletedType } from 'containers/hooks/useMMutation'
import { ModelUserCertification } from 'containers/models'
import { CERT_TYPE } from '@constants/constData'

const ModalChangeMobileNumber = ({ useModalProps }: DomainModalProps<MModalProps<any>, undefined>) => {
  const { hideModal } = useModalProps
  const whoamiData = useUserInfo.useWhoamiData()
  const [initMobileNumber, setInitMobileNumber] = useState<string>('')
  const [userCertification, setUserCertification] = useState<ModelUserCertification>()

  const SendUserCertNumber = () => {
    const onAPISuccess: onCompletedType = (data) => {
      // @ts-ignore - resultType을 재정의 하기 위해서 ignore함
      // eslint-disable-next-line no-case-declarations
      const { userCertification: _userCertification }: { userCertification: { data: ModelUserCertification } } = data
      setUserCertification(_userCertification.data)
      antdMessage.success('인증번호가 발송되었습니다.')
    }
    const [disabled, setDisabled] = useState<boolean>(false)

    return (
      <MMutation gqlKey="sendUserCertNumber" onAPISuccess={onAPISuccess}>
        {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
          return (
            <Form
              initialValues={{ mobileNumber: initMobileNumber }}
              onValuesChange={(values) => {
                setDisabled(!values.mobileNumber)
              }}
              onFinish={(values) => {
                const user = whoamiData?.user?.data?.id
                const { mobileNumber } = values
                const variables = MUTATION_VARIABLES.SEND_USER_CERT_NUMBER({
                  user,
                  mobileNumber,
                  certType: CERT_TYPE.CHANGE_MOBILE_NUMBER,
                })
                setInitMobileNumber(mobileNumber)
                mutation({ variables })
              }}
            >
              <MFormItemInput
                name="mobileNumber"
                placeholder="휴대폰 번호"
                rules={[FORM_ITEM_RULES.FORM_ITEM_RULE_MOBILE_NUMBER]}
              />
              <MButton htmlType="submit" disabled={disabled} style={{ width: '100%' }} loading={loading}>
                인증번호 전송
              </MButton>
            </Form>
          )
        }}
      </MMutation>
    )
  }

  const ConfirmUserCertNumber = () => {
    const onAPISuccess = () => {
      antdMessage.success('번호 변경이 완료되었습니다.')
      hideModal()
    }
    const [disabled, setDisabled] = useState<boolean>(false)

    return (
      <MMutation gqlKey="updateMobileNumber" onAPISuccess={onAPISuccess}>
        {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
          return (
            <Form
              onValuesChange={(values) => {
                setDisabled(!values.confirmNumber)
              }}
              onFinish={(values) => {
                const { confirmNumber } = values
                const variables = MUTATION_VARIABLES.UPDATE_MOBILE_NUMBER({
                  id: whoamiData?.user?.data?.id,
                  userCertification: userCertification?.id,
                  confirmNumber,
                })
                mutation({ variables })
              }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <MFormItemInput name="confirmNumber" placeholder="인증번호 4자리" />
                </Col>
                <Col span={12}>
                  <MButton htmlType="submit" style={{ width: '100%' }} disabled={disabled} loading={loading}>
                    인증번호 확인
                  </MButton>
                </Col>
              </Row>
            </Form>
          )
        }}
      </MMutation>
    )
  }

  const Content = () => {
    return (
      <>
        <SendUserCertNumber />
        <div style={{ height: 30 }} />
        <ConfirmUserCertNumber />
      </>
    )
  }

  return (
    <MModal
      title="휴대폰 번호 변경"
      width={400}
      useModalProps={useModalProps}
      ComponentContent={<Content />}
      footerType="MODAL_FOOTER_CANCEL"
    />
  )
}

export default ModalChangeMobileNumber
