import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doSetMainTableFilters } from '@libs/redux/reduxUI'
import { RootState } from '@libs/redux/store'
import * as utilData from '@libs/utils/utilData'
import * as utilForm from '@libs/utils/utilForm'
import {
  FILTER_TYPES,
  filterItemProps,
  hasAllowedValueAll,
  hasIncludeFilter,
  hasName,
  hasSelectOptions,
  makeFormFilter2InitialValues,
} from 'components/MFormFilter'
import { FIELD_KEYS } from '@constants/constData'

export const useFilterVariables = (
  initialFilterFields: filterItemProps[] = [],
  values: { [key: string]: any } = {}
) => {
  const filterVariables: { [key: string]: any } = {}
  const typeMap: { [key: string]: string } = {}
  const isIncludeFilterList: string[] = []
  const isAllowedValueAllList: string[] = []

  /*
   * make type map (name or selectOptions)
   * TODO: useRef?
   * */
  initialFilterFields.forEach((field) => {
    // const { type } = field
    if ('name' in field) {
      const { name } = field as hasName
      typeMap[name] = field.type
      if ((field as hasIncludeFilter).isIncludeFilter) {
        isIncludeFilterList.push(name)
      }
      if ((field as hasAllowedValueAll).isAllowedValueAll) {
        isAllowedValueAllList.push(name)
      }
    } else if ('selectOptions' in field) {
      const { selectOptions } = field as hasSelectOptions
      selectOptions.forEach(({ value }) => {
        typeMap[value as string] = field.type
      })
    } else if (field.type === FILTER_TYPES.CUSTOMER_GRADES) {
      typeMap.grades = field.type
    }
  })

  const isIncludeFilter = (name: string) => {
    return isIncludeFilterList.indexOf(name) !== -1
  }

  const isAllowedValeAll = (name: string) => {
    return isAllowedValueAllList.indexOf(name) !== -1
  }

  const isAll = (value: string | string[]) => {
    if (typeof value === 'string') {
      return value === 'ALL' || value === FIELD_KEYS.FIELD_ALL
    }
    return value.indexOf('ALL') !== -1 || value.indexOf(FIELD_KEYS.FIELD_ALL) !== -1
  }
  Object.entries({ ...makeFormFilter2InitialValues(initialFilterFields), ...values }).forEach(([name, value]) => {
    const type = typeMap[name]

    if (value === undefined || value === null) return []
    // TODO split select singular and plural
    if (type === FILTER_TYPES.BUTTON) {
      filterVariables[name] = value[0].id
    } else if (type === FILTER_TYPES.DATE_RANGE || type === FILTER_TYPES.BILLING_DATE_RANGE) {
      const resultDatetime = utilForm.getAfterAndBeforeDatetime(name, value)
      filterVariables[`${name}After`] = resultDatetime[`${name}After`]
      filterVariables[`${name}Before`] = resultDatetime[`${name}Before`]
    } else if (type === FILTER_TYPES.CHECKBOX || type === FILTER_TYPES.CUSTOMER_GRADES) {
      if (typeof value.indexOf === 'function' && (!isAll(value) || isAllowedValeAll(name))) {
        if (isIncludeFilter(name)) {
          filterVariables[name] = { include: value }
        } else {
          filterVariables[name] = value
        }
      }
      if (typeof value.indexOf === 'function' && (!isAll(value) || isAllowedValeAll(name))) {
        if (isIncludeFilter(name)) {
          filterVariables[name] = { include: value }
        } else {
          filterVariables[name] = value
        }
      }
    } else if (type === FILTER_TYPES.RADIO) {
      if (!isAll(value) || isAllowedValeAll(name)) {
        filterVariables[name] = value
      }
    } else if (type === FILTER_TYPES.SELECTS) {
      // TODO: split use include or not
      if (typeof value === 'boolean') {
        filterVariables[name] = value
      } else if (!isAll(value) || isAllowedValeAll(name)) {
        if (isIncludeFilter(name)) {
          filterVariables[name] = { include: [utilData.toUpperSnake(value)] }
        } else {
          filterVariables[name] = value
        }
      }
    } else {
      filterVariables[name] = value
    }
  })
  return filterVariables
}

const useMFormFilter = (initialFilterFields: filterItemProps[] = []) => {
  const initialValues: { [key: string]: any } = makeFormFilter2InitialValues(initialFilterFields)
  const initialFilterVariables: { [key: string]: any } = useSelector(
    (state: RootState) => state.reduxUIReducers.mainTableInitialVariables.filter
  )
  const [values, setValues] = useState<{ [key: string]: any }>(initialValues)
  const dispatch = useDispatch()

  const resetValues = async () => {
    await setValues(initialValues)
  }

  useEffect(() => {
    const filterVariables: { [key: string]: any } = useFilterVariables(initialFilterFields, values)
    // redux에 저장
    dispatch(doSetMainTableFilters(utilData.cleanObject({ ...initialFilterVariables, ...filterVariables })))
  }, [JSON.stringify(initialFilterVariables), JSON.stringify(values)])

  return {
    values,
    setValues,
    resetValues,
  }
}
export default useMFormFilter
