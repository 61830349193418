import React, { FC, CSSProperties } from 'react'
import { Radio } from 'antd'
import { RadioProps } from 'antd/lib/radio'
import styled from 'styled-components'
import { MWrapperRow } from 'components/@atomics'

type MRadioProps = {
  style?: CSSProperties
  label: string
}

const MRadioGroupButton: FC<MRadioProps & RadioProps> = ({ label, style }) => {
  return (
    <MWrapperRow label={label}>
      <StyledRadio style={style} />
    </MWrapperRow>
  )
}

const StyledRadio = styled(Radio)<MRadioProps & RadioProps>`
  ${(props) => props.style};
`
export default MRadioGroupButton
