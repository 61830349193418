import * as utilDate from '@libs/utils/utilDate'
import { getDisplayPeriodText, getDisplayStatus } from '@libs/utils/utilDate'
import { IModelBannerDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelBannerDefault } from 'containers/models/base/defaultModels'
import { IModelSlimBannerInfo } from 'containers/models/modelSlimBannerInfo'
import { IModelTopRollBannerInfo } from 'containers/models/modelTopRollBannerInfo'
import { DISPLAY_STATUS, DISPLAY_STATUS_TEXT } from '@constants/constDomain'
import { IModelBannerInfo } from 'containers/models/modelBannerInfo'

export interface IModelBanner extends IModelBannerDefault {
  displayStatus: DISPLAY_STATUS
  displayStatusText: DISPLAY_STATUS_TEXT
  displayPeriodText: string
  isAlwaysDisplay: boolean
  createdAtText: string
  updatedAtText: string

  homeBottomBannerInfo: IModelBannerInfo
  homeMiddleBannerInfo: IModelBannerInfo
  productTopBannerInfo: IModelBannerInfo
  slimBannerInfo: IModelSlimBannerInfo
  topRollBannerInfo: IModelTopRollBannerInfo
}

export default class ModelBanner extends ModelBannerDefault {
  displayStatus = ''
  displayStatusText = ''
  displayPeriodText = ''
  isAlwaysDisplay = false
  createdAtText = ''
  updatedAtText = ''
  createdAtAndUpdatedAtText = ''

  constructor(data: IModelBanner) {
    super(data)

    this.displayStatus = getDisplayStatus(this.startedAt, this.endedAt)
    this.displayStatusText = DISPLAY_STATUS_TEXT[this.displayStatus as keyof typeof DISPLAY_STATUS]
    this.displayPeriodText = getDisplayPeriodText(this.startedAt, this.endedAt, true)
    this.createdAtText = utilDate.date2string(this.createdAt)
    this.updatedAtText = utilDate.date2string(this.updatedAt)
    this.createdAtAndUpdatedAtText = `${this.createdAtText}\n${this.createdAtText}`
    this.bannerTypeInfo = JSON.parse(this.bannerTypeInfo)
    this.isAlwaysDisplay = this.displayStatus === DISPLAY_STATUS.ALWAYS_DISPLAY
  }
}
