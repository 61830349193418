import { FC, MutableRefObject } from 'react'
import { Space } from 'antd'
import { useMutation } from '@apollo/client'

import { DescOrderItem } from 'domains/common/descriptions'
import MModal from 'components/modals/MModal'
import { TypeUseModalProps } from 'containers/hooks/useModal'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import { MUTATION_KEY2GQLS } from 'containers/gqls'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'

type OrderItems = IModelOrderItem[]

type ModalCompleteShippingOrderItemData = {
  orderItems: OrderItems
}

type ModalCompleteShippingOrderItemProps = {
  useModalProps: TypeUseModalProps<ModalCompleteShippingOrderItemData>
  clearSelected: Function
  refetchRef: MutableRefObject<Function | null>
  statusFilterRefetchRef: MutableRefObject<Function | null>
}

const settings = {
  completeShippingOrderItems: {
    title: '배송완료 처리',
    variablesFormat: MUTATION_VARIABLES.COMPLETE_SHIPPING_ORDER_ITEMS,
  },
}

const ComponentContent: FC<{ orderItems: OrderItems }> = ({ orderItems }) => {
  return (
    <Space direction="vertical" size="middle">
      {orderItems.map((orderItem) => (
        <DescOrderItem key={orderItem.id} orderItem={orderItem} />
      ))}
    </Space>
  )
}

const ModalCompleteShippingOrderItem: FC<ModalCompleteShippingOrderItemProps> = ({
  useModalProps,
  refetchRef,
  clearSelected,
  statusFilterRefetchRef,
}) => {
  const { orderItems } = useModalProps.data
  const gqlKey = 'completeShippingOrderItems'
  const { title, variablesFormat } = settings[gqlKey]
  const _afterMutationCompleted = () => {
    refetchRef?.current?.()
    clearSelected?.()
    statusFilterRefetchRef?.current?.()
  }
  const [completeShippingOrderItemsMutation] = useMutation(MUTATION_KEY2GQLS[gqlKey], {
    onCompleted: _afterMutationCompleted,
  })

  const okActionHandler = async () => {
    const variables = variablesFormat({
      orderItems: orderItems.map((orderItem) => ({
        orderItem: orderItem.id,
      })),
    })

    await completeShippingOrderItemsMutation({
      variables,
    })
  }

  if (!orderItems) return null

  return (
    <MModal
      title={title}
      useModalProps={useModalProps}
      ComponentContent={<ComponentContent orderItems={orderItems} />}
      onAction={okActionHandler}
    />
  )
}

export default ModalCompleteShippingOrderItem
