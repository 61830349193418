import React from 'react'
import { Image } from 'antd'

import { Text14Normal, Text14Red, MTextButton, MLinkRouterButton } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelProduct } from 'containers/models/modelProduct'
import useModal from 'containers/hooks/useModal'
import ROUTES from '@constants/constRoutes'
import * as utilData from '@libs/utils/utilData'
import { IModelDisplayProductInfo } from 'containers/models/modelDisplayProductInfo'
import { ModalConfirm } from 'domains/common/modals'

const productImage = ({ title = '이미지', width = 70 } = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex: 'product',
    key: 'productName',
    width,
    render: (product: IModelProduct) => {
      return <Image preview={false} width={60} src={product.mainImage?.thumbnailUrl} />
    },
  }
}

const productName = ({ title = '상품명', width = 200 } = {}): ITableColumn<any> => {
  return {
    title,
    width,
    render: (record: IModelDisplayProductInfo) => {
      return (
        <MLinkRouterButton href={`${ROUTES.PRODUCTS.BASE}/${record.product._id}/edit`} openNewWindow>
          {record.product.realName}
        </MLinkRouterButton>
      )
    },
  }
}

const productDiscountRate = ({ title = '정률할인(%)', width = 100 } = {}): ITableColumn<any> => {
  return {
    title,
    width,
    render: (record: IModelDisplayProductInfo) => {
      const discountRate = record.productpromotion.discountRate as number
      if (!discountRate) {
        return <Text14Normal>-</Text14Normal>
      }
      const discountRatePercent = `${utilData.tableNumberText((discountRate * 100).toFixed(2))}%`
      return discountRate > 0.5 ? (
        <Text14Red>{discountRatePercent}</Text14Red>
      ) : (
        <Text14Normal>{discountRatePercent}</Text14Normal>
      )
    },
  }
}

const fitpetFeeRate = ({ title = '핏펫 수수료(%)', width = 100 } = {}): ITableColumn<any> => {
  return {
    title,
    width,
    render: (record: IModelDisplayProductInfo) => {
      const _fitpetFeeRate = record.productpromotion.fitpetFeeRate as number
      if (_fitpetFeeRate === null) {
        return <Text14Normal>기본 수수료율</Text14Normal>
      }
      return <Text14Normal>{`${utilData.tableNumberText((_fitpetFeeRate * 100).toFixed(2))}%`}</Text14Normal>
    },
  }
}

const deleteProduct = ({ title = '삭제', width = 70, handleDeleted = () => {} } = {}): ITableColumn<any> => {
  return {
    title,
    width,
    render: (record: IModelDisplayProductInfo) => {
      const { useModalProps: useDeleteAdModalProps } = useModal()
      return (
        <>
          <MTextButton
            onClick={() => {
              useDeleteAdModalProps.showModal()
              useDeleteAdModalProps.setData({ displayProductInfo: record })
            }}
          >
            [삭제]
          </MTextButton>
          <ModalConfirm
            useModalProps={useDeleteAdModalProps}
            fixedProps={{
              title: '상품 삭제',
              description: `${record.product.name} 상품을 삭제하시겠습니까?`,
              mutationKey: {
                gqlKey: 'deleteDisplayProductInfo',
                variableKey: 'displayProductInfo',
              },
              confirmMessage: `${record.product.name} 상품이 삭제되었습니다.`,
            }}
            onAPISuccess={() => {
              if (handleDeleted) {
                handleDeleted()
              }
            }}
          />
        </>
      )
    },
  }
}

export {
  productImage, // 상품 이미지
  productName, // 상품명
  productDiscountRate, // 할인율
  fitpetFeeRate, // 핏펫 수수료
  deleteProduct, // 삭제
}
