import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const COUPON_MUTATION_GQLS = {
  DELETE_COUPON: gql`
    mutation deleteCoupon($id: ID!) {
      deleteCoupon(id: $id) {
        coupon {
          ...coupon
        }
      }
    }
    ${DEFAULT_FRAGMENTS.coupon}
  `,
  DELETE_COUPONS_BY_GROUP: gql`
    mutation deleteCouponsByGroup($id: ID!) {
      deleteCouponsByGroup(id: $id) {
        couponGroup {
          id
        }
      }
    }
  `,
}

export default COUPON_MUTATION_GQLS
