import moment, { Moment } from 'moment'
import 'moment/locale/ko'
import { DATE_OPTION_VALUE } from '@constants/constData'
import { DISPLAY_STATUS, DISPLAY_STATUS_TEXT } from '@constants/constDomain'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATETIME_SECOND_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_DISPLAY_FORMAT = DATE_FORMAT
export const DATETIME_DISPLAY_FORMAT = 'YYYY-MM-DD, hh:mm:ss a'
export const YEAR_DISPLAY_FORMAT = 'YY/MM/DD'
export const NON_YEAR_DATETIME_DISPLAY_FORMAT = 'MM-DD, hh:mm:ss a'
export const TIME_AM_PM_FORMAT = 'a hh:mm'
export const DATE_DAY_OF_WEEK_FORMAT = 'MM.DD(ddd)'
export const DATE_SIMPLE_FORMAT = 'M월 D일'
export const MONTH_SIMPLE_FORMAT = 'Y년 M월'
export const DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT = 'YYYY-MM-DDTHH:mm:ss+09:00'

const now = new Date()

export const setDateOfMonth = (date: string, option: 'first' | 'last'): string => {
  if (date && option) {
    if (option === 'first') {
      return moment(date).startOf('month').format(YEAR_DISPLAY_FORMAT)
    }
    return moment(date).endOf('month').format(YEAR_DISPLAY_FORMAT)
  }
  return moment().format(YEAR_DISPLAY_FORMAT)
}

export const getDateOfMonth = (date: Moment | string | Date, option: 'first' | 'last'): Moment => {
  if (option === 'first') {
    return moment(date).startOf('month')
  }
  return moment(date).endOf('month')
}

export const toMomentIfValid = (v: string | Moment | undefined | null): Moment | undefined => {
  const _moment = moment(v)
  return _moment.isValid() ? _moment : undefined
}

export const string2date = (str: string) => {
  return moment(str).toDate()
}

const getDate = (date?: string | Moment | Date) => {
  let _date
  if (!date) {
    _date = new Date()
  } else {
    _date = date
  }
  return _date
}

export const addDay = (addValue: number, date?: string | Moment | Date) => {
  return moment(getDate(date)).add(addValue, 'days')
}

export const addSecond = (addValue: number, date?: string | Moment | Date) => {
  return moment(getDate(date)).add(addValue, 'seconds')
}

export const date2string = (date: null | Moment | Date | string | '', withTime?: boolean): string => {
  if (date) return moment(date).format(withTime ? DATETIME_SECOND_FORMAT : DATE_DISPLAY_FORMAT)
  return '-'
}

export const datetime2string = (date: Date | string) => {
  if (date) return moment(date).format(DATETIME_DISPLAY_FORMAT)
  return '-'
}

export const datetime2utcString = (date: Moment | Date | string) => {
  if (date) return moment(date).format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT)
  return '-'
}

export const startedDate2utcString = (date: Moment | Date | string) => {
  if (date) return moment(date).hour(0).minutes(0).seconds(0).format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT)
  return '-'
}

export const endedDate2utcString = (date: Moment | Date | string) => {
  if (date) return moment(date).hour(23).minutes(59).seconds(59).format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT)
  return '-'
}

export const forFilterStartMonth = (date: Moment | Date | string) => {
  return moment(date).startOf('M').format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT)
}

export const forFilterEndMonth = (date: Moment | Date | string) => {
  return moment(date).endOf('M').hour(23).minutes(59).seconds(59).format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT)
}

export const date2stringSimple = (date: Date | string, type?: 'day' | 'month') => {
  const dateType = type === 'month' ? MONTH_SIMPLE_FORMAT : DATE_SIMPLE_FORMAT
  if (date) return moment(date).format(dateType)
  return '-'
}

export const datetime2ampm = (date: Date | string) => {
  if (date) return moment(date).format(TIME_AM_PM_FORMAT)
  return '-'
}

export const datetime2dayOfWeek = (date: Date | string) => {
  if (date) return moment(date).format(DATE_DAY_OF_WEEK_FORMAT)
  return '-'
}

export const datetime2stringWithFormat = (date: null | Moment | string | undefined, format = DATETIME_FORMAT) => {
  if (date) return moment(date).format(format)
  return '-'
}

export const stringDate2param2requestForm = (str: string) => {
  return moment(str).add(1, 'days').subtract(1, 'seconds').format()
}

export const stringDate2param = (str: string) => {
  return moment(str).format()
}

export const todayDate = () => {
  const today: any = Date.now()
  return date2string(today)
}

export const todayDatetime = () => {
  const today: any = Date.now()
  return datetime2utcString(today)
}

export const thisMonth = () => new Date().getMonth() + 1

export function rangeDate(
  recent: DATE_OPTION_VALUE,
  format = DATE_FORMAT
): [moment.Moment, moment.Moment] | [undefined, undefined] {
  let dateJoinedAfter = todayDate()
  let dateJoinedBefore = date2string(addDay(0))
  switch (recent) {
    case DATE_OPTION_VALUE.TODAY:
      dateJoinedBefore = date2string(addDay(0))
      break
    case DATE_OPTION_VALUE.ONE_WEEK:
      dateJoinedAfter = date2string(addDay(-7))
      break
    case DATE_OPTION_VALUE.ONE_MONTH:
      dateJoinedAfter = date2string(addDay(-31))
      break
    case DATE_OPTION_VALUE.TWO_MONTH:
      dateJoinedAfter = date2string(addDay(-61))
      break
    case DATE_OPTION_VALUE.THREE_MONTH:
      dateJoinedAfter = date2string(addDay(-91))
      break
    case DATE_OPTION_VALUE.SIX_MONTH:
      dateJoinedAfter = date2string(addDay(-181))
      break
    case DATE_OPTION_VALUE.ONE_YEAR:
      dateJoinedAfter = date2string(addDay(-366))
      break
    case DATE_OPTION_VALUE.ALL:
      return [undefined, undefined]
    default:
  }
  return [moment(dateJoinedAfter, format), moment(dateJoinedBefore, format)]
}

export function rangeBillingDate(
  recent: DATE_OPTION_VALUE,
  format = DATE_FORMAT
): [moment.Moment, moment.Moment] | [undefined, undefined] {
  const dateJoinedAfter = moment(todayDate(), format).startOf('month')
  const dateJoinedBefore = moment(dateJoinedAfter).endOf('month')
  switch (recent) {
    case DATE_OPTION_VALUE.TODAY:
      break
    case DATE_OPTION_VALUE.ONE_WEEK:
      break
    case DATE_OPTION_VALUE.ONE_MONTH:
      break
    case DATE_OPTION_VALUE.TWO_MONTH:
      dateJoinedAfter.add(-1, 'month')
      break
    case DATE_OPTION_VALUE.THREE_MONTH:
      dateJoinedAfter.add(-2, 'month')
      break
    case DATE_OPTION_VALUE.SIX_MONTH:
      dateJoinedAfter.add(-5, 'month')
      break
    case DATE_OPTION_VALUE.ONE_YEAR:
      dateJoinedAfter.add(-11, 'month')
      break
    case DATE_OPTION_VALUE.ALL:
      return [undefined, undefined]
    default:
  }
  return [dateJoinedAfter, dateJoinedBefore]
}

export const dateSort = (a: string, b: string): -1 | 0 | 1 => {
  const result = moment(a).diff(moment(b))
  if (result > 0) return 1
  if (result < 0) return -1
  return 0
}

export const getFifteenth = (date: string): string => {
  return moment(setDateOfMonth(date, 'first')).add(1, 'month').add(14, 'days').format(DATE_DISPLAY_FORMAT)
}

export const datetime2number = () => {
  const d1 = new Date()
  return d1.getTime()
}

export const getDaysInMonth = (year: number | string, month: number | string) => {
  return moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()
}

export const isDateBefore = (oneDate: string | Moment, otherDate: string | Moment | undefined | null) => {
  if (!otherDate) {
    return false
  }
  const oneDate_ = typeof oneDate === 'string' ? moment(oneDate) : oneDate
  const otherDate_ = typeof otherDate === 'string' ? moment(otherDate) : otherDate
  return oneDate_.diff(otherDate_) <= 0
}

export const isDateAfter = (oneDate: string | Moment, otherDate: string | Moment | undefined | null) => {
  if (!otherDate) {
    return false
  }
  const oneDate_ = typeof oneDate === 'string' ? moment(oneDate) : oneDate
  const otherDate_ = typeof otherDate === 'string' ? moment(otherDate) : otherDate
  return oneDate_.diff(otherDate_) >= 0
}

export const isDateBetween = (
  targetDate: string | Moment,
  startDate: string | Moment | undefined | null,
  endDate: string | Moment | undefined | null
) => {
  const targetDate_ = typeof targetDate === 'string' ? moment(targetDate) : targetDate
  const startDate_ = typeof startDate === 'string' ? moment(startDate) : startDate
  const endDate_ = typeof endDate === 'string' ? moment(endDate) : endDate
  if (!startDate_?.isValid() && !endDate_?.isValid()) {
    return false
  }
  if (!startDate_?.isValid()) {
    return isDateBefore(targetDate_, endDate_)
  }
  if (!endDate_?.isValid()) {
    return isDateAfter(targetDate_, startDate_)
  }
  return targetDate_.isBetween(startDate_, endDate_)
}

export const getDisplayStatus = (startedAt: string | Moment | null, endedAt: string | Moment | null) => {
  const today = moment(Date.now())
  const _startedAt = moment(startedAt)
  const _endedAt = moment(endedAt)
  if (!_startedAt.isValid() && !_endedAt.isValid()) {
    return DISPLAY_STATUS.ALWAYS_DISPLAY
  }
  if (_startedAt.isValid() && isDateBefore(today, _startedAt)) {
    return DISPLAY_STATUS.TO_BE_DISPLAY
  }
  if (_endedAt.isValid() && isDateAfter(today, _endedAt)) {
    return DISPLAY_STATUS.FINISHED_DISPLAY
  }
  return DISPLAY_STATUS.DISPLAY
}

export const getDisplayPeriodText = (
  startedAt: string | Moment | null,
  endedAt: string | Moment | null,
  withTime: boolean = false,
  alwaysDisplayText: string = DISPLAY_STATUS_TEXT.ALWAYS_DISPLAY
) => {
  if (startedAt || endedAt) {
    return `${date2string(startedAt, withTime)} ~ ${date2string(endedAt, withTime)}`
  }
  return alwaysDisplayText
}

export const string2moment = (str: string) => {
  return moment(str)
}

export const getToday = () => {
  return moment()
}

export const DATE_FORMAT_PARSER = {
  startDay: moment(now).hour(0).minute(0).second(0).format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT),
  endDay: moment(now).hour(23).minute(59).second(59).format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT),
  weekStartDay: moment(now).startOf('W').format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT),
  weekEndDay: moment(now).endOf('W').format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT),
  monthStartDay: moment(now).startOf('M').month(-1).format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT),
  monthEndDay: moment(now).endOf('M').month(-1).format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT),
  threeMonthStartDay: moment(now).format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT),
  threeMonthEndDay: moment(now)
    .month(now.getMonth() + 3)
    .format(DATETIME_SECOND_TZ_UTC_SEOUL_FORMAT),
}
