import _ from 'lodash'
import React from 'react'
import { message } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { FIELD_KEYS, FIELD_VALUES, S3_HOST_URL } from '@constants/constData'
import { DATE_FORMAT, datetime2number, datetime2stringWithFormat } from '@libs/utils/utilDate'
import { USER_MODE_TYPE } from '@constants/constDomain'
import { roleInfoProps } from 'containers/hooks/useUserInfo'

export const destruct = (obj: { [index: string]: any }, ...keys: string[]) =>
  keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {})
export const onlyDigit = (s: string) => s.replace(/\D/g, '')
export const isSameArray = (a: [], b: []) => JSON.stringify(a) === JSON.stringify(b)
export const isSameObject = (a: Object | null, b: Object | null) => {
  if (!a || !b) return false
  return _.isEqual(a, b)
}

export const isUrlWithWeakRule = (urlString: string) => {
  return urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0
}

export const convertHtmlDataUrlToFiles = async (html: string): Promise<{ html: string; files: File[] }> => {
  let _html = !html || false ? '' : html
  let loop = true
  const fileList: File[] = []
  const dataUrlList: { dataUrl: string; fileType: string; fileName: string }[] = []

  while (loop) {
    const imgIndexStart = _html.indexOf('data:') // html상의 data 파일만 반환하기 위함
    if (imgIndexStart === -1) {
      loop = false
      break
    }

    const imgIndexEnd = _html.substring(imgIndexStart).indexOf('">') + imgIndexStart
    const dataUrl = _html.substring(imgIndexStart, imgIndexEnd)
    const fileType = extractFileTypeFromDataUrl(dataUrl)
    const fileName = `file_${datetime2number()}_${imgIndexStart}.${extractFileExtensionFromDataUrlFileType(fileType)}`
    dataUrlList.push({ dataUrl, fileType, fileName })
    // _html = _html.replace(dataUrl, IMAGE_TAG_REPLACE)
    _html = _html.replace(dataUrl, `__${fileName}__`)
  }
  for (let i = 0; i < dataUrlList.length; i += 1) {
    const { dataUrl, fileName, fileType } = dataUrlList[i]
    // eslint-disable-next-line no-await-in-loop
    const file = await convertDataUrlToFile(dataUrl, fileName, fileType)
    fileList.push(file)
  }

  return { html: _html, files: fileList } as { html: string; files: File[] }
}

export const convertDataUrlToFile = async (dataUrl: string, fileName: string, type: string) => {
  const res = await fetch(dataUrl)
  const blob = await res.blob()
  return new File([blob], fileName, { type })
}

export const extractFileTypeFromDataUrl = (dataUrl: string) => {
  const subStrIndexStart = dataUrl.indexOf('data:') + 5
  return dataUrl.substring(subStrIndexStart, dataUrl.indexOf(';'))
}

export const extractFileExtensionFromDataUrlFileType = (dataUrlFileType: string) => {
  return dataUrlFileType.split('/')[1]
}

export const isFileType = (file: UploadFile, fileType: string) => {
  return file.type === fileType
}

export const isExcelFile = (file: UploadFile) => {
  return (
    isFileType(file, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
    (file.name.endsWith('xls') || file.name.endsWith('xlsx'))
  )
}

export const getQueryParamsFromUrl = (name: string, url: string) => {
  let _url: string = ''
  if (!url) {
    _url = window.location.href
  }
  const _name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${_name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(_url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const replaceLineBrake = (text: string | null) => {
  if (!text) return null
  return `${text}`
    .replace('<br>', '<br/>')
    .replace('<br />', '<br/>')
    .replace(/(?:\\r\\n|\\r|\\n)/g, '<br/>')
}

// export const deepcopy = (object: {}) => JSON.parse(JSON.stringify(object))
export const deepcopy = (object: any) => _.cloneDeep(object)

export const objectSize = (object: {}) => {
  return object ? Object.keys(object).length : 0
}

export const boolToNum = (value: boolean | 'none'): 0 | 1 | 2 => {
  if (value === false) {
    return 0
  }
  if (value === true) {
    return 1
  }
  return 2
}

export const hasData = (a?: object | []) => {
  if (!a) return false
  if (_.isObject(a)) {
    return objectSize(a) > 0
  }
  if (_.isArray(a)) {
    return (a as []).length > 0
  }
  return false
}

export const hasRole = (currentRoleInfo: roleInfoProps) => {
  return currentRoleInfo && currentRoleInfo.type && currentRoleInfo.type !== USER_MODE_TYPE.CUSTOMER
}

export const strToNum = (value: string): 0 | 1 => {
  if (value === '') return 0
  return 1
}

// export const haveEqualValues = (array: Array<any>): void => {
//   array.every((val, idx, arr) => {
//     return arr[0] === val
//   })
// }

export const array2object = (array: Array<Object>, key: string, value: string) => {
  return Object.assign({}, ...array.map((d: { [index: string]: any }) => ({ [d[key]]: d[value] })))
}
export const value2array = (array: Array<Object>, key: string) => Object.keys(array).map((x: any) => x[key])

export const objectValueInArray2array = (array: Array<Object>, key = 'id') => {
  if (array.length <= 0) return []
  return array.map((x: any) => x[key])
}

export const changeFieldInObjectOfArray = (array: []) => {
  array.forEach((row: { text: string; name: string }) => {
    const _row = row
    if (_row.text) _row.name = _row.text
    // row.name = row.text
  })
  return array
}

export const objectCamel2snake = (raw: Array<{ [index: string]: string }>) => {
  const newParams: { [index: string]: any } = {}
  // eslint-disable-next-line array-callback-return
  Object.keys(raw).map((key: any) => {
    const value = raw[key]
    const newKey = key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
    newParams[newKey] = value
  })
  return newParams
}

export const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const toSnake = (s: string): string => {
  return s.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
}

export const toUpperSnake = (s: string): string => {
  return s.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase()
}

export const keysToCamel: {} | Array<string> | any = (raw: any) => {
  if (_.isObject(raw as { [key: string]: any })) {
    const n: { [key: string]: any } = {}
    Object.keys(raw).forEach((k: any) => {
      n[toCamel(k)] = keysToCamel(raw[k])
    })
    return n
  }

  if (_.isArray(raw)) {
    return (raw as []).map((i: any) => keysToCamel(i))
  }
  return raw
}

export const keysToSnake = (raw: any) => {
  //  if (raw instanceof File) return raw
  if (_.isObject(raw as { [key: string]: any })) {
    const n: { [key: string]: any } = {}
    Object.keys(raw).forEach((k) => {
      n[toSnake(k)] = keysToSnake(raw[k])
    })
    return n
  }

  if (_.isArray(raw as [])) {
    return raw.map((i: string) => {
      return keysToSnake(i)
    })
  }
  return raw
}

export const floatToPercentageNumber = (x: number) => (x ? x * 100 : 0)
export const percentageNumberToFloat = (x: number) => (x ? x * 0.01 : 0)
export const numberWithCommas = (x?: string | number) => (x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0)
export const currencyMinusText = (x: number) => (x && x !== 0 ? `-${numberWithCommas(x)}원` : '0원')
export const currencyText = (x: number) => (x ? `${numberWithCommas(x)}원` : '0원')
export const tenThousandCurrencyText = (x: number) => (x ? `${x * 0.0001}만원` : '-')
export const minuteText = (x: number) => (x ? `${numberWithCommas(x)}분` : '0분')
export const percentText = (x: number) => (x ? `${numberWithCommas(round(x, 10))}%` : '0%')
export const percentFloatText = (x: number) =>
  x ? `${numberWithCommas(floatToPercentageNumber(x).toFixed(2))}%` : '0%'
export const tableNumberText = (x?: string | number) => {
  if (typeof x === 'number') return numberWithCommas(x)
  if (Number.isNaN(Number(x))) return '-'
  return x || '-'
}
export const tableText = (x?: string | number) => {
  return x || '-'
}
export const descriptionText = (x?: string) => x || ''
export const countText = (x: number) => x || 'X'
export const countTextSecond = (x: number) => (x ? `${numberWithCommas(x)}회` : '0회')
export const percentTextWithArrow = (x: number) => {
  const percentNumber = x ? `${numberWithCommas(x)}%` : '0%'
  let percentNumberWithArrow
  if (percentNumber.indexOf('-') > -1) {
    percentNumberWithArrow = percentNumber.replace('-', '')
    percentNumberWithArrow = `${percentNumberWithArrow}↓`
  } else {
    percentNumberWithArrow = percentNumber === '0%' ? percentNumber : `${percentNumber}↑`
  }
  return percentNumberWithArrow
}
export const monthPeriodText = (x: number) => (x ? `${numberWithCommas(x)}개월` : '0개월')
export const dayPeriodText = (x: number) => (x ? `${numberWithCommas(x)}일` : '0일')
export const personCountText = (x: number) => (x ? `${numberWithCommas(x)}명` : '0명')
export const numberCountText = (x: number) => (x ? `${numberWithCommas(x)}개` : '0개')
export const caseCountText = (x: number) => (x ? `${numberWithCommas(x)}건` : '0건')
export const ageCountText = (x: number) => (x ? `${numberWithCommas(x)}세` : '0세')
export const viewsText = (x: number) => (x ? `${numberWithCommas(x)} views` : '0 views')
export const agreeText = (x: boolean) => (x ? '동의' : '거부')
export const possibilityText = (x: boolean) => (x ? '가능' : '불가능')
export const dateTimeFormatText = (x?: string, format: string = DATE_FORMAT) =>
  x ? datetime2stringWithFormat(x, format) : '-'
export const kFormatter = (num: number) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`
  }
  return num
}

export const formatMobileNumber = (phoneNumber: string): string => {
  if (!phoneNumber?.length) {
    return '-'
  }

  const first: string = phoneNumber.slice(0, 3)
  let second: string
  let third: string
  if (phoneNumber.length === 10) {
    second = phoneNumber.slice(3, 6)
    third = phoneNumber.slice(6)
  } else {
    second = phoneNumber.slice(3, 7)
    third = phoneNumber.slice(7)
  }

  return `${first}-${second}-${third}`
}

export const upperSnakeCase = (value: string): string => {
  let convertedResult = _.snakeCase(value).toUpperCase()
  if (value.includes('_')) {
    convertedResult = `_${_.snakeCase(value).toUpperCase()}`
  }
  return convertedResult
}

const noDataYet = (data: {}, selArray: []) => objectSize(data) <= 0 || selArray.length <= 0

export const arrayObjectFilter = (array: [], selArray: [], keyName: string) => {
  if (noDataYet(array, selArray)) return []
  const newArray: [] = []
  // eslint-disable-next-line array-callback-return
  array.map((x) => {
    if (selArray.includes(x[keyName])) {
      newArray.push(x)
    }
  })
  return newArray
}

export const round = (num: number, decimal = 0) => {
  if (!num) return num
  if (decimal === 0) return Math.round(num)
  return Math.round(num * (decimal * 10)) / (decimal * 10)
}

export const dashOnTel = (tel: string) => (tel ? `${tel.slice(0, 3)}-${tel.slice(3, 7)}-${tel.slice(7)}` : tel)

export const removeDash = (text: string) => (text ? text.replace(/-/gi, '').replace(/ /, '').trim() : text)

export const formatComma = (num?: number) => {
  if (!num) {
    return 0
  }
  const reg = /(^[+-]?\d+)(\d{3})/
  let n = `${num}`

  while (reg.test(n)) n = n.replace(reg, '$1,$2')

  return n
}

export const checkEmptyValue = (target: {} | []): boolean => {
  if (target === undefined) return true
  return Object.keys(target).length === 0
}

export const removeComma = (str: string) => {
  const _str = str.replace(/\\,/g, '')
  return parseInt(_str, 10) || 0
}

export const flattenJSON = (nestedMessages: [], prefix = '') =>
  Object.keys(nestedMessages).reduce((json, key: any) => {
    const value = nestedMessages[key]
    const prefixedKey: string = prefix ? `${prefix}.${key}` : key
    const _json: { [index: string]: string } = json
    if (typeof value === 'string') {
      _json[prefixedKey] = value
    } else {
      Object.assign(_json, flattenJSON(value, prefixedKey))
    }
    return _json
  }, {})

export const subString = (string: string, limitLength: number) => {
  let _string = string
  if (_string.length > limitLength) {
    _string = `${_string.substr(0, limitLength)}...`
  }
  return _string
}

export function cleanObject(obj: { [key: string]: any }) {
  const cleanObj: { [key: string]: any } = {}
  const objectKeys = Object.keys(obj)

  // value가 FIELD_KEYS.FIELD_ALL, null, undefined 된 것들 제거
  for (let i = 0; i < objectKeys.length; i += 1) {
    const key = objectKeys[i]
    const value = obj[key]
    if (Array.isArray(value)) {
      if (hasData(value)) {
        cleanObj[key] = value
      }
    } else {
      if (value !== FIELD_KEYS.FIELD_ALL) {
        if (value !== null && value !== undefined) {
          cleanObj[key] = value
        }
      }
    }
  }
  return cleanObj
}

export const sumAmount = (arr: any[], idx: string) =>
  _.reduce(
    arr.map((val) => val[idx]),
    (sum: number, n: number) => {
      return sum + n
    },
    0
  )

export const decodeGid = (gid: string): { type: string; id: number } => {
  const decoded = atob(gid)
  const decodedSplit = decoded.split(':')

  if (decodedSplit.length !== 2) {
    return { type: '-', id: -1 }
  }
  return { type: decodedSplit[0], id: Number.parseFloat(decodedSplit[1]) }
}

type Wrapper = {
  condition: boolean
  wrapper: [JSX.Element, JSX.Element]
  children?: JSX.Element | string
}

export const ConditionalWrapper = ({ condition, wrapper, children }: Wrapper) => {
  return React.cloneElement(condition ? wrapper[0] : wrapper[1], [], children)
}

export const extractFileNameFromPath = (filePath: string): string => {
  if (!filePath || filePath === '') return ''

  const split = filePath.split('/')
  return split[split.length - 1]
}

export function convertObject2GqlText(obj: any) {
  // 사용 시 return 값 체크해서 잘 안 나올 경우 개선 필요
  // create an array that will later be joined into a string.
  const string = []

  if (typeof obj === 'object' && obj.join === undefined) {
    if (hasData(obj)) {
      string.push('{')
      Object.entries(obj).forEach(([k, v]) => {
        if (v !== null && v !== undefined) {
          string.push(k, ': ', convertObject2GqlText(v), ',')
        }
      })
      string.pop()
      string.push('}')
    }
  } else if (typeof obj === 'object' && !(obj.join === undefined)) {
    if (hasData(obj)) {
      string.push('[')
      obj.forEach((x: any) => {
        if (x !== null && x !== undefined) {
          string.push(convertObject2GqlText(obj[x]), ',')
        }
      })
      string.push(']')
    }
  } else if (typeof obj === 'function') {
    string.push(obj.toString())
  } else {
    if (typeof obj === 'number') {
      string.push(obj)
    } else {
      string.push(`"${obj}"`)
    }
  }
  return string.join('')
}

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '-'
  }

  if (phoneNumber.length === 11) {
    return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
  }
  if (phoneNumber.length === 8) {
    return phoneNumber.replace(/(\d{4})(\d{4})/, '$1-$2')
  }

  if (phoneNumber.indexOf('02') === 0) {
    return phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
  }

  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export const bytesToMB = (num: number) => {
  if (num === 0) {
    return 0
  }
  return (num / 1024 / 1024).toFixed(2)
}

export const getFieldValueByKey = (field: keyof typeof FIELD_VALUES) => {
  return FIELD_VALUES[field]
}

export const convertStrToNumArr = (val: string): number[] => {
  return val.split(',').map(Number)
}

export const setTags = (tags: string) => {
  if (tags === '') {
    return tags
  }

  return tags
    .replaceAll(', ', ',')
    .split(',')
    .filter((tag: string) => tag !== '')
}

export const filenameFromUrl = (url: string) => {
  if (!url) return ''
  return decodeURI(url.replace(/^.*[\\/]/, ''))
}

export const getFilename = (fileName: string) => {
  return filenameFromUrl(fileName)
}

export const getFullUrl = (fileName: string) => {
  return `${S3_HOST_URL}/${fileName}`
}

export function capitalizeString(s: string = '') {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function copy2Clipboard(text: string, successMessage?: string, e: React.MouseEvent<HTMLElement> | null = null) {
  const textField = document.createElement('textarea')
  textField.innerText = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
  message.success({
    content: successMessage || '링크가 복사되었습니다',
    duration: 1,
  })
  if (e) (e.target as HTMLTextAreaElement).focus()
}

export const rateToNumber = (rate?: number) => {
  if (rate === undefined || Number.isNaN(Number(rate))) {
    return 0
  }
  const _rate = `${String(rate).replace('-', '')}.00`
  const numberArr = _rate.split('.')
  numberArr[1] = `${numberArr[1].padEnd(2, '0').slice(0, 2)}.${numberArr[1].slice(2)}`
  const number = Number(numberArr.join(''))
  if (rate < 0) {
    return number * -1
  }
  return number
}

export const numberToRate = (number?: number) => {
  if (number === undefined || Number.isNaN(Number(number))) {
    return 0
  }
  const numberStr = String(number).replace('-', '')
  const numberArr = numberStr.split('.')
  numberArr[0] = numberArr[0].padStart(3, '0')
  numberArr[0] = `${numberArr[0].slice(0, -2)}.${numberArr[0].slice(-2)}`
  const rate = Number(numberArr.join(''))
  if (number < 0) {
    return rate * -1
  }
  return rate
}

export const maskingText = (text: string, start: number = 0, maskingChar: string = '*') => {
  let _maskingText = text.substring(0, start)
  for (let i = start; i < text.length; i += 1) {
    _maskingText += maskingChar
  }
  return _maskingText
}
