import {
  COUPON_GROUP_BENEFIT_TYPE,
  COUPON_ISSUE_STATUS,
  COUPON_ISSUE_STATUS_TEXT,
  COUPON_ISSUE_TYPE,
  COUPON_ISSUE_TYPE_TEXT,
  COUPON_GROUP_DISCOUNT_TYPE,
  COUPON_COVERAGE_TYPE,
  COUPON_COVERAGE_OS_TYPE,
  COUPON_TYPE_TEXT,
  COUPON_TYPE,
  COUPON_TYPE_NAME,
} from '@constants/constDomain'
import * as utilDate from '@libs/utils/utilDate'
import * as utilData from '@libs/utils/utilData'
import { IModelCouponGroupDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelCouponGroupDefault } from 'containers/models/base/defaultModels'

export interface IModelCouponGroup extends IModelCouponGroupDefault {
  benefitText: string
  benefitType: COUPON_GROUP_BENEFIT_TYPE
  createdAtText: string
  isForGrade: boolean
  isIssuableManual: boolean
  issueEndedAtText: string
  issueStartedAtText: string
  issueStatusText: string
  issueTypeText: string
  maxDuplicateIssueCountText: string
  maxIssueCountText: string
  minOrderAmountText: string
  usingPeriodText: string
  coverageOsTypeText: string
  couponTypeText: string
}

export default class ModelCouponGroup extends ModelCouponGroupDefault {
  benefitText: string = ''
  benefitType: COUPON_GROUP_BENEFIT_TYPE = COUPON_GROUP_BENEFIT_TYPE.DISCOUNT
  createdAtText: string = ''
  isIssuableManual: boolean = false
  issueEndedAtText: string = ''
  issuePeriodText: string = ''
  issueStartedAtText: string = ''
  issueStatusText: string = ''
  issueTypeText: string = ''
  maxDuplicateIssueCountText: string = ''
  maxIssueCountText: string = ''
  minOrderAmountText: string = ''
  usingPeriodText: string = ''
  coverageOsTypeText: string = ''
  couponTypeText: string = ''
  hasMaxDuplicateIssueCount

  constructor(data: IModelCouponGroup) {
    super(data)

    this.issueTypeText = COUPON_ISSUE_TYPE_TEXT[this.issueType as COUPON_ISSUE_TYPE]
    this.issueStatusText = COUPON_ISSUE_STATUS_TEXT[this.issueStatus as COUPON_ISSUE_STATUS]
    this.usingPeriodText = this.hasUsingPeriod
      ? `발급일로부터 ${this.usingPeriod}일까지 사용 가능`
      : `${utilDate.date2string(this.usingStartedAt)} ~ ${utilDate.date2string(this.usingEndedAt)}`
    this.createdAtText = utilDate.date2string(this.createdAt)
    this.issueStartedAtText = utilDate.date2string(this.issueStartedAt)
    this.issueEndedAtText = utilDate.date2string(this.issueEndedAt)
    this.issuePeriodText =
      this.issueStartedAt && this.issueEndedAt ? `${this.issueStartedAtText} ~ ${this.issueEndedAtText}` : ''
    const couponTypeName = COUPON_TYPE_NAME[this.couponType as keyof typeof COUPON_TYPE]
    const maxDiscountAmountText =
      this.maxDiscountAmount > 0 ? ` 최대 ${utilData.currencyText(this.maxDiscountAmount)}` : ''
    this.benefitText =
      this.discountType === COUPON_GROUP_DISCOUNT_TYPE.AMOUNT
        ? `${couponTypeName} 할인 ${utilData.currencyText(this.discountAmount)}`
        : `${couponTypeName} 할인 ${utilData.percentText(Math.round(this.discountRate * 100))}${maxDiscountAmountText}`
    this.minOrderAmountText = this.hasMinOrderAmount ? `최소 주문금액 ${this.minOrderAmount}원 이상` : '제한 없음'
    this.maxIssueCountText = this.hasMaxIssueCount ? `최대 ${this.maxIssueCount}회 발급 가능` : '제한 없음'
    this.maxDuplicateIssueCountText = `같은 아이디에 최대 ${this.maxDuplicateIssueCount}회 재발급 가능`
    this.hasMaxDuplicateIssueCount = this.maxDuplicateIssueCount > 1
    this.isIssuableManual =
      this.issueStatus === COUPON_ISSUE_STATUS.ISSUING && this.issueType === COUPON_ISSUE_TYPE.MANUAL

    this.coverageOsTypeText = this.coverageOsType === COUPON_COVERAGE_OS_TYPE.ALL ? 'WEB, APP' : this.coverageOsType
    this.couponTypeText = this.couponType ? COUPON_TYPE_TEXT[this.couponType as keyof typeof COUPON_TYPE] : ''
  }
  get infos() {
    return [
      { title: '발급 방식', content: this.issueTypeText },
      { title: '쿠폰 사용 기간', content: this.usingPeriodText },
      { title: '혜택 내용', content: this.benefitText },
      { title: '사용 범위', content: this.coverageOsTypeText },
      { title: '최소 구매 금액', content: this.minOrderAmountText },
      ...(this.issueType === COUPON_ISSUE_TYPE.AUTO
        ? [
            { title: '쿠폰 발급 기간', content: this.issuePeriodText },
            // { title: '발급 제한', content: this.maxIssueCountText },
            { title: '재발급 제한', content: this.maxDuplicateIssueCountText },
          ]
        : []),
      ...(this.issueType === COUPON_ISSUE_TYPE.DOWNLOAD
        ? [
            { title: '쿠폰 발급 기간', content: this.issuePeriodText },
            // { title: '발급 제한', content: this.maxIssueCountText },
            { title: '재발급 제한', content: this.maxDuplicateIssueCountText },
          ]
        : []),
    ]
  }
  get includeCoverageInfo() {
    if (this.coverageType === COUPON_COVERAGE_TYPE.SPECIFIC_PRODUCT) {
      return { title: '상품', objs: this.includedProducts }
    }
    if (this.coverageType === COUPON_COVERAGE_TYPE.SPECIFIC_CATEGORY) {
      return { title: '카테고리', objs: this.includedCategories }
    }
    if (this.coverageType === COUPON_COVERAGE_TYPE.SPECIFIC_BRAND) {
      return { title: '브랜드', objs: this.includedBrands }
    }
    return { title: undefined, objs: undefined }
  }
}
