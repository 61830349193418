import { Switch } from 'antd'
import { SwitchProps } from 'antd/lib/switch'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { MFormItemWrapper } from 'components/formItems'
import { MFormBelowComment } from 'components/@atomics/forms'

type MFormItemSwitchProps = SwitchProps &
  MFormItemWrapperProps & {
    comment?: string
  }

const MFormItemSwitch = ({ comment, ...mFormItemSwitchProps }: MFormItemSwitchProps) => {
  const { ...switchProps } = mFormItemSwitchProps as SwitchProps
  const { ...formItemWrapperProps } = mFormItemSwitchProps as MFormItemWrapperProps

  return (
    <>
      <MFormItemWrapper {...formItemWrapperProps} valuePropName="checked">
        <Switch {...switchProps} />
      </MFormItemWrapper>
      <MFormBelowComment comment={comment} colOffset={formItemWrapperProps.labelCol?.span} />
    </>
  )
}

export default MFormItemSwitch
