import MMainTable from 'components/MMainTable'
import { ITableColumn } from 'components/@atomics/MTable'
import { queryParamsProps } from 'components/MQuery'
import { IModelCoupon } from 'containers/models/modelCoupon'
import { IModelUser } from 'containers/models/modelUser'
import tableCols from 'domains/admin/tableCols'

const tableColumns: Array<ITableColumn<IModelCoupon>> = [
  tableCols.common.ID(),
  tableCols.common.baseText({ title: '쿠폰명', dataIndex: ['couponGroup', 'name'] }),
  tableCols.common.baseText({ title: '발급 방식', dataIndex: ['couponGroup', 'issueTypeText'], width: 120 }),
  tableCols.date.dateText({ title: '발급일', dataIndex: 'createdAt', key: 'createdAt' }),
  tableCols.common.baseText({ title: '사용 기간', dataIndex: 'couponUsingPeriodText', width: 210 }),
  tableCols.common.baseText({ title: '혜택', dataIndex: ['couponGroup', 'benefitText'] }),
  tableCols.common.baseText({ title: '사용 구분', dataIndex: 'couponStatusText' }),
]

const MainTableUserCouponList = ({ data }: { data: IModelUser }) => {
  const queryParams: queryParamsProps = {
    gqlKey: 'userCouponTable',
    dataKey: 'coupons',
    variables: {
      filter: {
        user: data.id,
        orderBy: '-createdAt',
      },
    },
  }
  return (
    <MMainTable
      TitleSet={{ title: '전체 목록', unit: '건' }}
      columns={tableColumns}
      queryParams={queryParams}
      // exportExcel={false}
      listButton
    />
  )
}

export default MainTableUserCouponList
