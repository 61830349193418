import { IModelUpdatedProductDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelUpdatedProductDefault } from 'containers/models/base/defaultModels'
import { IModelProduct } from 'containers/models/modelProduct'
import { IModelProductOption } from 'containers/models/modelProductOption'
import { IModelProductImage } from 'containers/models/modelProductImage'

export interface IModelUpdatedProduct extends IModelUpdatedProductDefault {
  product: IModelProduct
  productOptions: IModelProductOption[]
  productImages: IModelProductImage[]
}

export default class ModelUpdatedProduct extends ModelUpdatedProductDefault {
  constructor(data: IModelUpdatedProduct) {
    super(data)
  }
}
