import _ from 'lodash'
import { CSSProperties, MutableRefObject } from 'react'
import { Form, Row, Col } from 'antd'
import {
  MFormItemInput,
  MFormItemWrapper,
  MFormItemDateRangePicker,
  MFormItemProductCategories,
  IFilterOptions,
} from 'components/formItems'
import { MButton, MOuterCard, MSelect } from 'components/@atomics'
import * as utilDate from '@libs/utils/utilDate'

export type FilterOptionsCommon = {
  label: string
  name: string
  labelCol?: { span: number }
  multiple?: boolean
}

export type FilterOptionsSelect = FilterOptionsCommon & { selectOptions: IFilterOptions }

type MFormModalFilterProps = {
  refetchRef: MutableRefObject<Function>
  setFilterVariables: (values: any) => void
  formInputOptions: FilterOptionsCommon[]
  formSelectOptions?: FilterOptionsSelect[]
  formDateOptions?: FilterOptionsCommon[]
  formCategory?: boolean
  wrapperStyle?: CSSProperties
}

const MFormModalFilter = ({
  formInputOptions,
  formSelectOptions,
  formDateOptions,
  formCategory = false,
  refetchRef,
  setFilterVariables,
  wrapperStyle,
}: MFormModalFilterProps) => {
  const [form] = Form.useForm()
  const initialValues = formCategory
    ? { category: { first: undefined, second: undefined, third: undefined, forth: undefined } }
    : undefined
  return (
    <MOuterCard style={wrapperStyle || { margin: 0, marginBottom: 20 }}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={(values) => {
          const originValues = values

          // multiple filter logic
          const multipleSearchObj: FilterOptionsCommon[] = _.filter(formInputOptions, 'multiple')
          const mappedMultipleSearchObjNames: string[] = _.map(multipleSearchObj, 'name')
          if (!_.isEmpty(mappedMultipleSearchObjNames)) {
            _.forEach(mappedMultipleSearchObjNames, (val) => {
              originValues[val] = !values[val] ? undefined : [values[val]]
            })
          }

          // date filter logic
          const dateOptionKeys: string[] = [
            'createdAt',
            'updateApprovedAt',
            'dateJoined',
            'lastLogin',
            'requestedAt',
            'approvedAt',
          ]
          if (!_.isEmpty(formDateOptions)) {
            _.forEach(formDateOptions, (option) => {
              if (_.includes(dateOptionKeys, option.name)) {
                originValues[`${option.name}After`] = utilDate.date2string(originValues[option.name]?.[0])
                originValues[`${option.name}Before`] = utilDate.date2string(originValues[option.name]?.[1])
                delete originValues[option.name]
              }
            })
          }

          if (originValues?.category) {
            const { first, second, third, forth } = originValues.category
            originValues.subCategory = forth || third || second || first
            delete originValues.category
          }

          setFilterVariables(originValues)
          if (refetchRef.current) refetchRef.current()
        }}
      >
        <Row gutter={[24, 0]} wrap>
          {formInputOptions &&
            formInputOptions.map((formInputOption) => {
              return (
                <Col key={formInputOption.name} span={8}>
                  <MFormItemInput {...formInputOption} />
                </Col>
              )
            })}
          {formSelectOptions &&
            formSelectOptions.map((formSelectOption) => {
              return (
                <Col key={formSelectOption.name} span={8}>
                  <MFormItemWrapper name={formSelectOption.name} label={formSelectOption.label} labelCol={{ span: 6 }}>
                    <MSelect filterOptions={formSelectOption.selectOptions} />
                  </MFormItemWrapper>
                </Col>
              )
            })}
          {formDateOptions &&
            formDateOptions.map((formDateOption) => {
              return (
                <Col key={formDateOption.name} span={8}>
                  <MFormItemDateRangePicker {...formDateOption} />
                </Col>
              )
            })}
        </Row>
        {formCategory && (
          <Row>
            <Col span={24}>
              <MFormItemProductCategories form={form} labelCol={{ span: 2 }} />
            </Col>
          </Row>
        )}

        <Row justify="end" gutter={[12, 0]}>
          <Col>
            <MButton
              onClick={() => {
                form.resetFields()
                setFilterVariables({})
              }}
            >
              초기화
            </MButton>
          </Col>
          <Col>
            <MButton
              type="primary"
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              검색
            </MButton>
          </Col>
        </Row>
      </Form>
    </MOuterCard>
  )
}

export { MFormModalFilter as default }
