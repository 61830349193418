import { date2string } from '@libs/utils/utilDate'
import {
  NOTICE_KIND_TYPE,
  NOTICE_KIND_TYPE_TEXT,
  NOTICE_RECEIVER_TYPE,
  NOTICE_RECEIVER_TYPE_TEXT,
} from '@constants/constDomain'
import { IModelNoticeDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelNoticeDefault } from 'containers/models/base/defaultModels'

export interface IModelNotice extends IModelNoticeDefault {
  receiverTypeText: NOTICE_RECEIVER_TYPE_TEXT
  kindText: NOTICE_KIND_TYPE_TEXT
  authorWithDateText: string
}

export default class ModelNotice extends ModelNoticeDefault {
  receiverTypeText = NOTICE_RECEIVER_TYPE_TEXT.CUSTOMER
  kindText = NOTICE_KIND_TYPE_TEXT.NOTICE_KIND_BILLING
  authorWithDateText = ''

  constructor(data: IModelNotice) {
    super(data)

    this.receiverTypeText = NOTICE_RECEIVER_TYPE_TEXT[this.receiverType as NOTICE_RECEIVER_TYPE]
    this.kindText = NOTICE_KIND_TYPE_TEXT[this.kind as NOTICE_KIND_TYPE]
    this.authorWithDateText = this.getAuthorText()
  }

  getAuthorText = (): string => {
    if (!this.user) return '-'

    let userName: string = '-'
    if (this.user.name !== '') userName = this.user.name

    return `${date2string(this.createdAt)} / ${userName} (${this.user.email})`
  }
}
