import { gql } from '@apollo/client'

export const PRODUCT_PROMOTION_QUERY_GQLS = {
  PRODUCT_PROMOTIONS: gql`
    query productPromotions($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      productPromotions(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        edges {
          node {
            id
            isDelete
            name
            productPromotionCustomerPrice
            discountRate
            discountPrice
            startedAt
            endedAt
            displayProductInfo {
              displayCollection {
                name
                ad {
                  name
                }
              }
            }
          }
        }
      }
    }
  `,
}

export default PRODUCT_PROMOTION_QUERY_GQLS
