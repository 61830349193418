export const SCREEN_SIZES = {
  xs: 0, // screen < 576px
  sm: 576, // screen >= 576px
  md: 768, // screen >= 768px
  lg: 992, // screen >= 992px
  xl: 1200, // screen >= 1200px
  xxl: 1600, // screen >= 1600px
}

export const LAYOUT_MAX_WIDTH = 1460

export const FIXED_HEIGHT = {
  FITPET_TOP_BANNER: 70,
  FITPET_TOP_BANNER_MOBILE: 40,
  DESKTOP_HEADER: 136,
  MOBILE_HEADER_TOP: 52,
  MOBILE_HEADER_MENUBAR: 48,
  MOBILE_HEADER: 40 + 52 + 48,
  MOBILE_BOTTOM_TAB_NAVIGATOR: 60,
  MOBILE_PRODUCT_BOTTOM_BAR: 52,
}

export const SCREEN_MEDIA_QUERY = {
  xs: `(max-width: ${SCREEN_SIZES.sm}px)`,
  md: `(min-width: ${SCREEN_SIZES.md}px)`,
}

export const Z_INDEXES = {
  ORDER_BUTTON: 1,
  HOME_CATEGORY_SELECT_CIRCLE: 1,
  HOME_CATEGORY_SELECT: 2,
  PRODUCT_MOBILE_TAB: 2,
  SEARCH_RECOMMENDATIONS: 8,
  MOBILE_BOTTOM_TAB_NAVIGATOR: 9,
  MINIMIZED_HEADER: 10,
  MOBILE_HEADER: 10,
  HOME_POPUP_BANNER: 11,
  PRODUCT_MOBILE_OPTION_SELECT: 11,
  PRODUCT_MOBILE_BOTTOM_BUTTON: 12,
  IMAGE_ENLARGE: 100,
}
