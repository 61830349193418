import { ReactNode } from 'react'
import { ColProps } from 'antd/lib/col'
import { FormItemProps } from 'antd/lib/form'

export { default as MFormItemCascader } from 'components/formItems/MFormItemCascader'
export { default as MFormItemCheckboxGroup } from 'components/formItems/MFormItemCheckboxGroup'
export { default as MFormItemCheckboxSingle } from 'components/formItems/MFormItemCheckboxSingle'
export { default as MFormItemCustomerGrades } from 'components/formItems/MFormItemCustomerGrades'
export { default as MFormItemDatePicker } from 'components/formItems/MFormItemDatePicker'
export { default as MFormItemDateRangePicker } from 'components/formItems/MFormItemDateRangePicker'
export { default as MFormItemFileUpload } from 'components/formItems/MFormItemFileUpload'
export { default as MFormItemFilterDate } from 'components/formItems/MFormItemFilterDate'
export { default as MFormItemHidden } from 'components/formItems/MFormItemHidden'
export { default as MFormItemImageUpload } from 'components/formItems/MFormItemImageUpload'
export { default as MFormItemInfo } from 'components/formItems/MFormItemInfo'
export { default as MFormItemInput } from 'components/formItems/MFormItemInput'
export { default as MFormItemInputNumber } from 'components/formItems/MFormItemInputNumber'
export { default as MFormItemProductCategories } from 'components/formItems/MFormItemProductCategories'
export { default as MFormItemRadio } from 'components/formItems/MFormItemRadio'
export { default as MFormItemSearch } from 'components/formItems/MFormItemSearch'
export { default as MFormItemSelect } from 'components/formItems/MFormItemSelect'
export { default as MFormItemSelectDate } from 'components/formItems/MFormItemSelectDate'
export { default as MFormItemSingleImageUpload } from 'components/formItems/MFormItemSingleImageUpload'
export { default as MFormItemSwitch } from 'components/formItems/MFormItemSwitch'
export { default as MFormItemTextArea } from 'components/formItems/MFormItemTextArea'
export { default as MFormItemTreeSelect } from 'components/formItems/MFormItemTreeSelect'
export { default as MFormItemUpload } from 'components/formItems/MFormItemUpload'
export { default as MFormItemWrapper } from 'components/formItems/MFormItemWrapper'
export { default as MFormListAddField } from 'components/formItems/MFormListAddField'
export { default as MFormItemSelectKeyword } from 'components/formItems/MFormItemSelectKeyword'

export type MFormItemLabelProps = {
  label?: string | ReactNode
  labelAlign?: 'left' | 'right'
  labelCol?: ColProps
  styledLabelWidth?: number
  getValueProps?: (value: string) => any
} & Omit<FormItemProps, 'getValueProps'>

export type MFormItemProps = {
  label: string
  name: string
}

export type FilterOptionValueProps = string | number | boolean | string[]

export interface IFilterOption {
  value: FilterOptionValueProps
  title: string | number
}

export type IFilterOptions = IFilterOption[]

export type IFilterStringOptions = string[]

export type IRadioCheckboxOption = {
  field: string
  disabled?: boolean
}

export type IRadioCheckboxOptions = IRadioCheckboxOption[]

type LocaleType = {
  lang: {
    locale: string
    placeholder: string
    rangePlaceholder: [string, string]
    today: string
    now: string
    backToToday: string
    ok: string
    clear: string
    month: string
    year: string
    timeSelect: string
    dateSelect: string
    monthSelect: string
    yearSelect: string
    decadeSelect: string
    yearFormat: string
    dateFormat: string
    dayFormat: string
    dateTimeFormat: string
    monthFormat: string
    monthBeforeYear: boolean
    previousMonth: string
    nextMonth: string
    previousYear: string
    nextYear: string
    previousDecade: string
    nextDecade: string
    previousCentury: string
    nextCentury: string
  }
  timePickerLocale: {
    placeholder: string
  }
  dateFormat: string
  dateTimeFormat: string
  weekFormat: string
  monthFormat: string
}

export const antdPickerLocale: LocaleType = {
  lang: {
    locale: 'ko_KR',
    placeholder: '일시 선택',
    rangePlaceholder: ['시작일 선택', '종료일 선택'],
    today: '오늘',
    now: '현재 시간',
    backToToday: '오늘로 돌아가기',
    ok: 'Ok',
    clear: 'Clear',
    month: '월',
    year: '년',
    timeSelect: '시간 선택',
    dateSelect: '날짜 선택',
    monthSelect: '월 선택',
    yearSelect: '년도 선택',
    decadeSelect: 'Choose a decade',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: '이전 달 (PageUp)',
    nextMonth: '다음 달 (PageDown)',
    previousYear: '지난 년도 (Control + left)',
    nextYear: '다음 년도 (Control + right)',
    previousDecade: 'Last decade',
    nextDecade: 'Next decade',
    previousCentury: 'Last century',
    nextCentury: 'Next century',
  },
  timePickerLocale: {
    placeholder: '시간 선택',
  },
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY.MM.DD / HH:mm',
  weekFormat: 'YYYY-wo',
  monthFormat: 'YYYY-MM',
}
