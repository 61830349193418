import { IModelTopRollBannerInfoDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelTopRollBannerInfoDefault } from 'containers/models/base/defaultModels'

export interface IModelTopRollBannerInfo extends IModelTopRollBannerInfoDefault {}

export default class ModelTopRollBannerInfo extends ModelTopRollBannerInfoDefault {
  constructor(data: IModelTopRollBannerInfo) {
    super(data)
  }
}
