import { MutableRefObject } from 'react'
import { message } from 'antd'

import { MButton } from 'components/@atomics'
import { IModelUser } from 'containers/models/modelUser'
import { ModalConfirmTable } from 'domains/common/modals'
import useModal from 'containers/hooks/useModal'

const ButtonLeaveUsers = ({
  selected,
  refetchRef,
}: {
  selected: MutableRefObject<IModelUser[]>
  refetchRef: MutableRefObject<Function | null>
}) => {
  const { useModalProps } = useModal()

  return (
    <>
      <MButton
        onClick={() => {
          const selectedLength: number = selected.current.length
          if (!selectedLength) {
            message.warning('선택된 항목이 없습니다.')
          } else {
            useModalProps.toggleModal()
            useModalProps.setData({ users: selected.current })
          }
        }}
        width={100}
      >
        선택 삭제
      </MButton>
      <ModalConfirmTable
        useModalProps={useModalProps}
        fixedProps={{
          mutationKey: {
            gqlKey: 'leaveUsersByFitpetAdmin',
            variableKey: 'userDeleteRequests',
          },
          title: '유저 삭제',
          description: '해당 유저를 삭제합니다. 삭제된 유저는 복구할 수 없습니다.',
          componentOption: {
            tableComponentKey: 'tableUserDefault',
          },
        }}
        onAPISuccess={() => {
          if (refetchRef.current) {
            message.success('유저의 탈퇴 처리가 완료되었습니다.')
            refetchRef.current()
          }
        }}
      />
    </>
  )
}

export default ButtonLeaveUsers
