import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'
import MILEAGE_FRAGMENTS from 'containers/gqls/accounts/mileage/fragments'

export const MILEAGE_QUERY_GQLS = {
  MILEAGES: gql`
    query mileages($pageInfo: PageInfoInputType, $filter: GenericScalar) {
      mileages(pageInfo: $pageInfo, filter: $filter) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...mileageOnly
          }
          cursor
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${MILEAGE_FRAGMENTS.mileageOnly}
  `,
  MILEAGE_POLICY: gql`
    query mileagePolicy {
      mileagePolicy {
        ...mileagePolicy
      }
    }
    ${DEFAULT_FRAGMENTS.mileagePolicy}
  `,
  MILEAGE_HISTORIES: gql`
    query mileageHistories($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      mileageHistories(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        pageInfo {
          ...pageInfo
        }
        edges {
          node {
            ...mileageHistory
            mileage {
              id
              point
              user {
                id
                username
                name
                email
              }
            }
            order {
              id
            }
            orderItem {
              id
            }
            review {
              id
            }
            fitpetAdmin {
              id
              name
            }
          }
          cursor
        }
      }
    }
    ${DEFAULT_FRAGMENTS.pageInfo}
    ${DEFAULT_FRAGMENTS.mileageHistory}
  `,
}

export default MILEAGE_QUERY_GQLS
