import React, { useRef, useState } from 'react'
import { MTableTransfer } from 'components/@atomics'
import { IModelCategory } from 'containers/models/modelCategory'
import MQuery from 'components/MQuery'
import { onTransferProps } from 'domains/common/modals/ModalTableTransferSelect'
import _ from 'lodash'
import { FilterOptionsCommon } from 'components/MFormModalFilter'
import { MFormModalFilter } from 'components'

type TableTransferCategoryProps = {
  onTransfer: (values: onTransferProps) => void
  chosenValues?: IModelCategory[]
}

const formInputOptions: FilterOptionsCommon[] = [{ label: '카테고리명', name: 'text', labelCol: { span: 6 } }]

const TableTransferCategory: React.FC<TableTransferCategoryProps> = ({ onTransfer, chosenValues = [] }) => {
  const [filterVariables, setFilterVariables] = useState<{}>({})
  const categoriesRef = useRef<IModelCategory[]>([])
  const targetCategoriesRef = useRef<IModelCategory[]>([])
  const refetchRef = useRef<any>(null)

  const excludeCategories = chosenValues.map((x) => x.id)
  // const pageInfo = { first: TRANSFER_TABLE_PAGE_SIZE }
  const pageInfo = { isUnlimited: true }
  const leftTableColumns = [
    {
      dataIndex: '_id',
      title: 'no.',
    },
    {
      dataIndex: 'text',
      title: '카테고리명',
    },
  ]
  const rightTableColumns = leftTableColumns

  const handleTransfer = (nextTargetKeys: string[]) => {
    targetCategoriesRef.current = categoriesRef.current.filter((category) => nextTargetKeys.includes(category.id))
    onTransfer({ values: targetCategoriesRef.current })
  }

  const onSearch = (inputValue: string, option: any) => {
    return (
      option?.name?.indexOf(inputValue) > -1 ||
      option?.parentName?.indexOf(inputValue) > -1 ||
      option?.text?.indexOf(inputValue) > -1
    )
  }

  return (
    <>
      <MFormModalFilter
        refetchRef={refetchRef}
        setFilterVariables={setFilterVariables}
        formInputOptions={formInputOptions}
      />
      <MQuery
        queryParams={{
          gqlKey: 'categoriesList',
          variables: { filter: { ...filterVariables, excludeCategories }, pageInfo },
        }}
      >
        {({ data }: any) => {
          categoriesRef.current = _.unionBy(data?.categories?.data, targetCategoriesRef.current, 'id')
          const totalCount = data?.categories?.totalCount || categoriesRef.current.length
          const dataSource = categoriesRef.current.map((category) => ({
            key: category.id,
            _id: category._id,
            // parentName: category.parentCategory?.name || '-',
            text: category.text,
            // name: category.name,
          }))

          return (
            <MTableTransfer
              totalCount={totalCount}
              dataSource={dataSource}
              initialTargetKeys={targetCategoriesRef.current.map((c) => c.id)}
              onTransfer={handleTransfer}
              listStyle={{}}
              locale={{ itemUnit: '개', itemsUnit: '개' }}
              filterOption={onSearch}
              leftColumns={leftTableColumns}
              rightColumns={rightTableColumns}
            />
          )
        }}
      </MQuery>
    </>
  )
}

export default TableTransferCategory
