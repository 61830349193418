import React, { useState } from 'react'
import styled from 'styled-components'

type MIconButtonProps = {
  onClick: () => void
  Icon: any
}

const ICON_SIZE = 20

const MIconButton: React.FC<MIconButtonProps> = ({ onClick, Icon }) => {
  const [iconSize, setIconSize] = useState<number>(ICON_SIZE)
  return (
    <Button onClick={onClick} onMouseDown={() => setIconSize(ICON_SIZE + 2)} onMouseUp={() => setIconSize(ICON_SIZE)}>
      <Icon
        style={{
          color: '#484848',
          fontSize: iconSize,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Button>
  )
}

const Button = styled.div`
  width: 25px;
  /* height: 20px; */
`
export default MIconButton
