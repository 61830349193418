import React from 'react'
import { Text14Normal } from 'components/@atomics'
import { ITableColumn, ITableColumnTitleDataIndexProps, ITableColumnTitleProps } from 'components/@atomics/MTable'
import * as utilData from '@libs/utils/utilData'

const currencyText = ({
  title,
  dataIndex,
  element,
  width,
  option = { displayCurrency: false },
}: ITableColumnTitleDataIndexProps): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: Array.isArray(dataIndex) ? dataIndex[dataIndex.length - 1] : dataIndex!,
    width: width || 120,
    render: (v: number) => {
      const rounded = Math.round(v)
      if (element) return React.cloneElement(element, [], utilData.currencyText(rounded))
      if (option.displayCurrency) {
        return <Text14Normal>{utilData.currencyText(rounded)}</Text14Normal>
      }
      return <Text14Normal>{utilData.numberWithCommas(rounded)}</Text14Normal>
    },
  }
}

const paymentAmountText = ({ title }: ITableColumnTitleProps): ITableColumn<any> =>
  currencyText({ title, dataIndex: 'paymentAmount' })
const fitpetAmountText = ({ title }: ITableColumnTitleProps): ITableColumn<any> =>
  currencyText({ title, dataIndex: 'fitpetAmount' })
const sellerAmountText = ({ title }: ITableColumnTitleProps): ITableColumn<any> =>
  currencyText({ title, dataIndex: 'sellerAmount' })
const sellerFeeText = ({ title }: ITableColumnTitleProps): ITableColumn<any> =>
  currencyText({ title, dataIndex: 'sellerFee' })
const fitpetFeeRateText = ({ title }: ITableColumnTitleProps): ITableColumn<any> =>
  currencyText({ title, dataIndex: 'fitpetFeeRate' })
// const influencerAmountText = ({ title, element }: ITableColumnTitleProps)
// : ITableColumn<any> => currencyText({ title, element, dataIndex: 'influencerAmount' })
// const influencerFeeText = ({ title }: ITableColumnTitleProps)
// : ITableColumn<any> => currencyText({ title, dataIndex: 'influencerFee' })

export {
  currencyText, // currency text
  fitpetAmountText, // 핏펫몰 금액
  sellerAmountText, // 셀러 금액
  paymentAmountText, // 결제 금액
  sellerFeeText, // 셀러 수수료
  fitpetFeeRateText, // 핏펫몰 수수료
  // influencerAmountText, // 인플루언서 금액
  // influencerFeeText, // 인플루언서 수수료
}
