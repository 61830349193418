import { MutableRefObject } from 'react'
import { message } from 'antd'

import { MButton } from 'components/@atomics'
import { IModelProduct } from 'containers/models/modelProduct'
import { ModalConfirmTable } from 'domains/common/modals'
import useModal from 'containers/hooks/useModal'

const ButtonDeleteProducts = ({
  selected,
  refetchRef,
  statusFilterRefetchRef,
}: {
  selected: MutableRefObject<IModelProduct[]>
  refetchRef: MutableRefObject<Function | null>
  statusFilterRefetchRef?: MutableRefObject<Function | null>
}) => {
  const { useModalProps: useModalDeleteRequestProps } = useModal()

  return (
    <>
      <MButton
        type="primary"
        onClick={() => {
          if (!selected.current.length) {
            message.warning('선택된 항목이 없습니다.')
          } else {
            useModalDeleteRequestProps.toggleModal()
            useModalDeleteRequestProps.setData({
              products: selected.current,
              selectedLength: selected.current.length,
            })
          }
        }}
        width={90}
      >
        선택 삭제
      </MButton>
      <ModalConfirmTable
        useModalProps={useModalDeleteRequestProps}
        fixedProps={{
          mutationKey: {
            gqlKey: 'deleteRequests',
            variableKey: 'productDeleteRequests',
          },
          title: '상품 삭제',
          componentOption: { tableComponentKey: 'tableProductDefault' },
        }}
        onAPISuccess={() => {
          message.success('삭제가 완료되었습니다.')
          if (refetchRef.current) {
            refetchRef.current()
          }
          if (statusFilterRefetchRef && statusFilterRefetchRef.current) {
            statusFilterRefetchRef.current()
          }
        }}
      />
    </>
  )
}

export default ButtonDeleteProducts
