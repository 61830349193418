// @ts-nocheck

import React, { FunctionComponent, ReactNode } from 'react'
import { ApolloError } from '@apollo/client'

import * as utilData from '@libs/utils/utilData'
import { MUTATION_KEY2GQLS } from 'containers/gqls'
import useMMutation, { onCompletedType } from 'containers/hooks/useMMutation'
import { MError, MLoader } from 'components/index'

type MMutationProps = {
  gqlKey: keyof typeof MUTATION_KEY2GQLS
  onAPISuccess?: onCompletedType
  onAPIError?: (error: ApolloError) => void | ReactNode
  onError?: (error: ApolloError) => void | ReactNode
  children?: ReactNode
  displayLoader?: boolean
}

const MMutation: FunctionComponent<MMutationProps> = ({
  gqlKey,
  onAPISuccess,
  onAPIError,
  onError,
  children,
  displayLoader = true,
}): any => {
  // const loaderFull = true
  const displayError = false
  const [mutation, { loading, error, data, called }] = useMMutation(gqlKey, onAPISuccess, onError)

  const hasDataAfter = called && data && utilData.hasData(data[gqlKey])
  if (displayError && error && !hasDataAfter) {
    if (onAPIError) return onAPIError
    return <MError error={error.message} />
  }

  // if (loading) return <MLoader />

  if (displayLoader && loading && hasDataAfter) return <MLoader />

  // const responseData = getDataFromResponse(mutation, data)
  // return <>{children(mutation, { result: responseData })}</>
  return <>{children(mutation, { data, error, hasDataAfter, loading })}</>
}

export default MMutation
