import { FC } from 'react'
import styled from 'styled-components'

export type assetNameType =
  | 'logo_black'
  | 'logo_white'
  | 'welcome-coupon-5pct'
  | 'welcome-coupon-10pct'
  | 'thanks-coupon-10pct'
  | 'thanks-coupon-20pct'

type ImageAssetBaseProps = {
  name: assetNameType
  size?: number | string
  height?: number
}

const getAssetImage = (name: assetNameType): any => {
  switch (name) {
    case 'logo_black':
      return '/images/logos/logo_black.png'
    case 'logo_white':
      return '/images/logos/logo_white.png'
    case 'welcome-coupon-5pct':
      return '/images/customer_grade/welcome-coupon-5@3x.png'
    case 'welcome-coupon-10pct':
      return '/images/customer_grade/welcome-coupon-10@3x.png'
    case 'thanks-coupon-10pct':
      return '/images/customer_grade/thanks-coupon-10@3x.png'
    case 'thanks-coupon-20pct':
      return '/images/customer_grade/thanks-coupon-20@3x.png'
    default:
      return '/images/logos/logo_black.png'
  }
}

const MImageAssetBase: FC<ImageAssetBaseProps> = ({ name, height, size = 20 }) => {
  const _assetImage = getAssetImage(name)

  return <StyledImage src={_assetImage} alt={name} width={size} height={height} />
}

const StyledImage = styled.img`
  object-fit: contain;
  object-position: center;
`

export default MImageAssetBase
