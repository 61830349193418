import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const SHIPPING_PRESET_MUTATION_GQLS = {
  CREATE_SHIPPING_PRESET: gql`
    mutation createShippingPreset($input: CreateShippingPresetInput!) {
      createShippingPreset(input: $input) {
        shippingPreset {
          ...shippingPreset
        }
      }
    }
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  UPDATE_SHIPPING_PRESET: gql`
    mutation updateShippingPreset($id: ID!, $input: UpdateShippingPresetInput!) {
      updateShippingPreset(id: $id, input: $input) {
        shippingPreset {
          ...shippingPreset
        }
      }
    }
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
  DELETE_SHIPPING_PRESET: gql`
    mutation deleteShippingPreset($id: ID!) {
      deleteShippingPreset(id: $id) {
        shippingPreset {
          ...shippingPreset
        }
      }
    }
    ${DEFAULT_FRAGMENTS.shippingPreset}
  `,
}

export default SHIPPING_PRESET_MUTATION_GQLS
