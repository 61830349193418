import { Form, message } from 'antd'
import { Store, ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { MModal, MFormItemInput } from 'components'
import { DomainModalProps } from 'containers/hooks/useModal'
import { Text14Normal } from 'components/@atomics'
import { IModelOrderItem } from 'containers/models/modelOrderItem'
import MMutation from 'components/MMutation'
import { MutationFunction } from '@apollo/client'
import MUTATION_VARIABLES from 'containers/gqls/base/mutation_variables'

export type ModalProps = {
  orderItems: IModelOrderItem[]
  gqlKey: string
}

const SETTING = {
  title: 'SKU 정보수정',
  description: '* SKU 이름 및 재고 수량 입력 후 "변경" 버튼을 클릭하면 SKU정보가 업데이트 됩니다.',
  variablesFormat: MUTATION_VARIABLES.UPDATE_SKU,
}

type SkuInfoType = { id: string; code: string; name: string; stockQuantity: number }

type ModalUpdateSkuInfoProps<T = SkuInfoType> = DomainModalProps<T, undefined>

const ModalUpdateSkuInfo = ({ useModalProps, onAPISuccess }: ModalUpdateSkuInfoProps<ModalProps>) => {
  const { name, code, stockQuantity, id } = useModalProps.data as unknown as SkuInfoType
  const [form] = Form.useForm()
  const { title, description, variablesFormat } = SETTING

  const initialValues = {
    name,
    code,
    stockQuantity,
  }

  const onFinish = (values: Store, mutation: MutationFunction) => {
    const newValues = {
      name: values.name,
      stockQuantity: values.stockQuantity,
    }
    const variables = variablesFormat({
      id,
      ...newValues,
    })
    mutation({ variables })
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    message.error(`Failed:${errorInfo}`)
  }

  return (
    <MModal
      form={form}
      title={title}
      useModalProps={useModalProps}
      ComponentContent={
        <MMutation gqlKey="updateSku" onAPISuccess={onAPISuccess}>
          {(mutation: MutationFunction) => {
            return (
              <Form
                form={form}
                labelCol={{ span: 6 }}
                onFinish={(values) => onFinish(values, mutation)}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues}
              >
                <MFormItemInput label="SKU 코드" name="code" disabled />
                <MFormItemInput label="SKU 이름" name="name" />
                <MFormItemInput label="재고 수량" name="stockQuantity" type="number" />
                <Text14Normal>{description}</Text14Normal>
              </Form>
            )
          }}
        </MMutation>
      }
    />
  )
}

export default ModalUpdateSkuInfo
