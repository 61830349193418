import { gql } from '@apollo/client'

const memberships = {
  membershipUserList: gql`
    query membershipUsers($pageInfo: PageInfoInputType, $filter: GenericScalar, $orderBy: String) {
      membershipUsers(pageInfo: $pageInfo, filter: $filter, orderBy: $orderBy) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            username
            name
            membershipStatus
            membershipFirstSubscribedAt
            membershipLastUnsubscribedAt
            membershipCount
            membership {
              startedAt
              endedAt
              creditCard {
                cardName
              }
            }
            membershipFamilies {
              edges {
                node {
                  id
                  username
                  isOwner
                  userId
                }
              }
            }
          }
        }
      }
    }
  `,

  membershipSubscribeInfos: gql`
    query membershipSubscribeInfos($id: ID!, $orderBy: String, $pageInfo: PageInfoInputType) {
      membershipSubscribeInfos(userId: $id, orderBy: $orderBy, pageInfo: $pageInfo) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            subscribeSequence
            user {
              name # 이름
              username # 이메일
            }
            paidAt # 결제일
            amount # 결제 금액
            isOwner # 대표 여부
            membershipStartedAt # 멤버십 시작일
            membershipEndedAt # 멤버십 종료일, 현재 진행중 멤버쉽이면 null
            membershipConfirmMileageAmount # 적립금 멤버쉽 혜택
            shippingDiscountAmount # 배송비 멤버쉽 혜택
          }
        }
      }
    }
  `,
}

export default memberships
