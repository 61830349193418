import { ModelCustomerGradeDefault } from 'containers/models/base/defaultModels'
import { IModelCustomerGradeDefault } from 'containers/models/base/defaultModelInterfaces'
import * as utilDate from '@libs/utils/utilDate'
import { hasData } from '@libs/utils/utilData'

export const getDefaultGrade = (data: IModelCustomerGrade[]) => {
  if (hasData(data)) {
    const res = data.filter((x) => x.name === 'Rookie') || []
    if (hasData(res)) return res[0].id
  }
}

export interface IModelCustomerGrade extends IModelCustomerGradeDefault {
  isActivatedText: string
  createdAtText: string
}

export default class ModelCustomerGrade extends ModelCustomerGradeDefault {
  isActivatedText: string
  createdAtText: string

  constructor(data: IModelCustomerGrade) {
    super(data)

    this.isActivatedText = this.isActivated ? '적용 중' : '미적용'
    this.createdAtText = utilDate.date2string(this.createdAt!)
  }
}
