import { Select } from 'antd'
import styled from 'styled-components'

import { IFilterOption, IFilterOptions } from 'components/formItems'
import { SelectProps } from 'antd/lib/select'
import React from 'react'

export type SelectCustomProps = {
  selectWidth?: number
  margin?: string
  onChange?: (value: any) => void
  showSearch?: boolean
  defaultValue?: string | number
}

export type MSelectProps = SelectCustomProps &
  SelectProps<any> & {
    filterOptions?: IFilterOptions
    id?: string
  }

function MSelect(props: MSelectProps) {
  const { id, filterOptions, selectWidth, showSearch, defaultValue, ...selectProps } = props

  const filterOption = (input: string, option: any) => {
    return String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
  return (
    <StyledSelect
      id={id}
      selectWidth={selectWidth}
      showSearch={showSearch}
      filterOption={showSearch ? filterOption : undefined}
      defaultValue={defaultValue}
      {...selectProps}
    >
      {filterOptions?.map((option: IFilterOption, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Select.Option key={index} value={option.value as string}>
            {option.title}
          </Select.Option>
        )
      })}
    </StyledSelect>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledSelect = styled(({ selectWidth, ...props }) => <Select {...props} />)<SelectCustomProps>`
  width: ${(props) => props.selectWidth}px !important;
`

export default MSelect
