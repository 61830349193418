import styled from 'styled-components'

const BillingHelper = () => {
  return (
    <Container>
      <h1>정산</h1>
      <h2>정산 정책</h2>
      <ul>
        <li>핏펫은 구매확정/반품완료(구매자 책임) 주문 건을 기준으로 매출을 인식합니다. </li>
        <li>
          총 판매금액(A)에서 판매수수료(B)를 제하여 계산 된 판매 정산금액(C)에 배송비 정산금액(D) 혹은 반품수수료
          정산금액(E)의 합계 금액을 더하여 정산합니다.
          <ul>
            <li>즉 정산 대상금액(F)=판매금액(A) - 판매수수료(B) + 배송비 정산금액(D) + 반품수수료 정산금액(E)</li>
          </ul>
        </li>
        <li>
          핏펫은 <HighlightText>월 정산</HighlightText>을 진행합니다.
        </li>
        <li>
          셀러는 당월 구매확정 및 반품완료(구매자 책임)된 주문 건에 대하여 정산금액을 확인하고{' '}
          <HighlightText>익월 10일 오후 5시까지 세금계산서를 발행</HighlightText>해야 합니다.
        </li>
        <li>
          10일 오후 5시까지 세금계산서가 발행 된 건에 한해서 20일에 정산됩니다.
          <ul>
            <li>세금계산서에 발행 건에 한해서 20일에 정산 비용이 지급됩니다.</li>
            <li>10일 이후 발행되는 세금계산서에 대한 정산 건은 다음 정산일에 지급됩니다.</li>
          </ul>
        </li>
      </ul>
      <h2>정산 리스트</h2>
      <ul>
        <li>정산 {'>'} 정산 리스트 메뉴에서 정산 대상 금액 및 예정일을 한눈에 확인할 수 있습니다.</li>
        <li>
          매월 10일까지 세금계산서 발행이 필요한 대상 금액을 {'<'}세금계산서 발행 대상 구역{'>'}에서 바로 확인할 수
          있습니다.
        </li>
        <li>검색 조건을 설정하여 원하는 기간의 월 별 정산 내역을 확인할 수 있습니다.</li>
        <li>월 별 엑셀 다운 기능을 통하여 정산 대상 월의 상품 주문 상세 내역을 다운로드할 수 있습니다.</li>
        <h3>세금계산서 발행 대상</h3>
        <ul>
          <li>매월 10일까지 세금계산서 발행이 필요한 대상 금액을 확인할 수 있습니다.</li>
          <li>
            10일 오후 5시까지 세금계산서 발행 건에 한해 20일에 정산됩니다.
            <ul>
              <li>세금계산서에 발행 건에 한해 20일에 지급됩니다.</li>
              <li>10일 이후 발행되는 세금계산서에 대한 건은 다음 정산일에 지급됩니다.</li>
            </ul>
          </li>
          <li>해당 내용은 당월 10일까지 노출됩니다.</li>
        </ul>
        <h3>검색 조건</h3>
        <ol>
          <li>정산확정월: 정산 비용이 지급되는 월로, 정산 대상 월의 익월입니다.</li>
        </ol>
        <h3>정산 - 상세 내역</h3>
        <ul>
          <li>
            정산 리스트 {'>'} 정산 목록 {'>'} 엑셀 다운 버튼을 누르면 정산 대상 상품 주문의 상세 내역을 엑셀파일로
            내려받을 수 있습니다.
          </li>
          <li>
            내역 항목 설명
            <ul>
              <li>
                정산 타입
                <ul>
                  <li>상품 금액: 구매 확정 주문 건 기준입니다.</li>
                  <li>
                    배송비: 구매 확정 주문 건에 발생한 배송비를 배송비 정산 금액 열에 노출합니다. 금액이 있을 경우에만
                    별도 배송비 정산 타입 행이 생성 및 노출 됩니다.
                  </li>
                  <li>환불 상품 금액: 반품 완료된 주문 건 기준입니다.</li>
                  <li>
                    환불 배송비: 반품 완료된 주문 건의 환불 배송비를 배송비 정산 금액 열에 노출합니다. 금액이 있을
                    경우에만 별도 배송비 정산 타입 행이 생성 및 노출 됩니다.
                  </li>
                  <li>반품 수수료: 반품 수수료가 발생한 주문 건인 경우, 반품 수수료 정산 금액 열에 노출합니다.</li>
                </ul>
              </li>
              <li>기본 수수료율(%): 계약 시 체결한 브랜드의 판매 수수료율 입니다.</li>
              <li>행사 수수료율(%): 프로모션을 진행 할 경우 적용되는 수수료율 입니다.</li>
              <li>정산 대상금액(F) = 판매 정산금액(C) + 배송비 정산금액(D) + 반품수수료 정산금액(E)</li>
            </ul>
          </li>
        </ul>
      </ul>
    </Container>
  )
}

export default BillingHelper

const Container = styled.div`
  h1 {
    font-size: 40px;
  }

  h2 {
    margin: 20px 0 8px 0;
    font-size: 28px;
  }
  h3 {
    margin: 12px 0 4px 0;
  }
  ol {
    li {
      padding-left: 20px;
      list-style-type: decimal;
    }
  }

  li {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 8px;

    ul {
      padding-left: 40px;

      ul {
        padding-left: 40px;
      }
    }
  }
`

const HighlightText = styled.span`
  color: orange;
  font-weight: 700;
`
