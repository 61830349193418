import React, { useState } from 'react'
import { Divider, Row, Col } from 'antd'
import styled from 'styled-components'

import { MModal } from 'components/modals'
import { DomainModalProps } from 'containers/hooks/useModal'

type ModalPhotoProps = {
  title: string
  description?: string
  images: string[]
}

const ModalPhoto = ({ useModalProps }: DomainModalProps<ModalPhotoProps, undefined>) => {
  const [selectedImage, setSelectedImage] = useState<string>()
  const { data, hideModal } = useModalProps
  const { title, description = '', images = [] } = data

  const onAction = () => {
    hideModal()
    // dispatch!
  }

  const renderThumbnails = () => {
    return (
      <Row gutter={[8, 8]}>
        {images.map((image: string) => (
          <Col span={2}>
            <StyledImgThumbnail src={image} alt="Thumbnail" onClick={() => setSelectedImage(image)} />
          </Col>
        ))}
      </Row>
    )
  }

  const RenderContent = () => {
    return (
      <>
        <Row>{description}</Row>
        <Row>
          <Col span={24}>{renderThumbnails()}</Col>
        </Row>
        <Divider />

        <Row align="middle" justify="center">
          <Col span={12}>
            {selectedImage ? <StyledImg src={selectedImage} alt="Product Image" /> : <p>이미지를 선택해 주세요.</p>}
          </Col>
        </Row>
      </>
    )
  }

  return (
    <MModal
      title={title}
      useModalProps={useModalProps}
      ComponentContent={<RenderContent />}
      onAction={onAction}
      footerType="MODAL_FOOTER_OK"
    />
  )
}

const StyledImgThumbnail = styled.img`
  width: 100%;
  height: 70px;
  object-fit: cover;
`
const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
export default ModalPhoto
