import React from 'react'
import { DownCircleOutlined } from '@ant-design/icons'
import MIconButton from 'components/@atomics/buttons/MIconButton'

type MArrowDownButtonProps = {
  onClick: () => void
}

const MArrowDownButton: React.FC<MArrowDownButtonProps> = (props) => {
  return <MIconButton {...props} Icon={DownCircleOutlined} />
}

export default MArrowDownButton
