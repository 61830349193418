import { FC } from 'react'
import { COUPON_ISSUE_TYPE } from '@constants/constDomain'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelCouponGroup } from 'containers/models/modelCouponGroup'
import tableCols from 'domains/admin/tableCols'
import { queryParamsProps } from 'components/MQuery'
import { MMainTable } from 'components'

type MainTableCouponGroupGradeSelectListProps = {
  onCouponSelected: (coupon: IModelCouponGroup) => void
}

const MainTableCouponGroupGradeSelectList: FC<MainTableCouponGroupGradeSelectListProps> = ({ onCouponSelected }) => {
  const couponGroupGradeTableColumn: Array<ITableColumn<IModelCouponGroup>> = [
    tableCols.common.baseText({ title: '쿠폰명', dataIndex: 'name', width: 200 }),
    tableCols.date.createdAtText({ title: '등록일' }),
    tableCols.couponGroup.benefit(),
  ]

  const variables = { filter: { issueType: COUPON_ISSUE_TYPE.GRADE }, orderBy: '-createdAt' }
  const queryParams: queryParamsProps = {
    gqlKey: 'userCouponGroupModalTable',
    dataKey: 'couponGroups',
    variables,
  }

  const onRowSelect = (rows: IModelCouponGroup[]) => {
    onCouponSelected(rows[0])
  }
  return (
    <MMainTable
      TitleSet={{ title: '쿠폰 목록', unit: '개' }}
      columns={couponGroupGradeTableColumn}
      size="small"
      scroll={300}
      rowSelectionType="radio"
      onRowSelect={onRowSelect}
      queryParams={queryParams}
      // exportExcel={false}
    />
  )
}

export default MainTableCouponGroupGradeSelectList
