import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const MILEAGE_MUTATION_GQLS = {
  UPDATE_MILEAGE: gql`
    mutation updateMileage($id: ID!, $input: MileageInput!) {
      updateMileage(id: $id, input: $input) {
        mileage {
          ...mileage
        }
      }
    }
    ${DEFAULT_FRAGMENTS.mileage}
  `,
}

export default MILEAGE_MUTATION_GQLS
