import { IModelBannerInfoDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelBannerInfoDefault } from 'containers/models/base/defaultModels'

export interface IModelBannerInfo extends IModelBannerInfoDefault {}

export default class ModelBannerInfo extends ModelBannerInfoDefault {
  constructor(data: IModelBannerInfo) {
    super(data)
  }
}
