import { MutableRefObject, useState } from 'react'
import { message } from 'antd'
import Link from 'next/link'

import * as utilData from '@libs/utils/utilData'
import ROUTES from '@constants/constRoutes'
import { ITableColumn, ITableColumnFixedHasLinkProps } from 'components/@atomics/MTable'
import { IModelSellerBilling } from 'containers/models/modelSellerBilling'
import { IModelProduct } from 'containers/models/modelProduct'
import { IModelSeller } from 'containers/models/modelSeller'
import { Text14Blue, Text14Normal, MLinkRouterButton, MTextButton } from 'components/@atomics'
import useModal from 'containers/hooks/useModal'
import { IModelUser } from 'containers/models/modelUser'
import { IModelBillingItem } from 'containers/models/modelBillingItem'
import { ModalConfirm } from 'domains/common/modals'

const sellersShopName = () => {
  return {
    title: '셀러명',
    dataIndex: 'sellers',
    key: 'sellersShopName',
    width: 120,
    render: (v: IModelSeller[]) => {
      if (v && v.length > 0) {
        let _ = v[0].shopName
        if (v.length > 1) {
          _ += `외 ${v.length - 1}개`
        }
        return <Text14Normal>{_}</Text14Normal>
      }
      return <Text14Normal>-</Text14Normal>
    },
  }
}

const sellerShop = ({
  dataIndex = 'shopName',
  key = 'sellerShop',
  fixed = 'left',
  hasLink = true,
  openNewWindow = hasLink,
  query,
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<any> => {
  return {
    title: '셀러명',
    dataIndex,
    key,
    fixed,
    width: 100,
    render: (v: string | IModelSeller | IModelProduct | IModelSellerBilling, record: IModelSeller | any) => {
      let seller
      if (dataIndex === 'product') {
        seller = (v as IModelProduct).seller
      } else if (dataIndex === 'seller') {
        seller = v
      } else if (dataIndex === 'sellerBilling') {
        seller = (v as IModelSellerBilling).seller
      } else {
        seller = record
      }
      const { _id, shopName } = seller
      if (hasLink) {
        const pathname = `${ROUTES.PARTNERS.SELLER_LIST}/${_id}`
        const href = { pathname, query }
        return (
          <MLinkRouterButton href={href} openNewWindow={openNewWindow}>
            {shopName}
          </MLinkRouterButton>
        )
      }
      return <Text14Normal>{utilData.tableText(shopName)}</Text14Normal>
    },
  }
}

const sellerCompanyName = ({
  dataIndex = 'companyName',
  title = '사업자명',
  hasLink = true,
} = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'companyName',
    width: 100,
    render: (_: string, record: IModelSeller) => {
      const { _id, companyName } = record
      if (hasLink) {
        return (
          <Link href={`${ROUTES.PARTNERS.SELLER_LIST}/${_id}`}>
            <a>
              <Text14Blue>{companyName}</Text14Blue>
            </a>
          </Link>
        )
      }
      return <Text14Normal>{companyName}</Text14Normal>
    },
  }
}

const sellerCompanyOwnerName = ({ dataIndex = 'companyOwnerName', title = '대표명' } = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'sellerCompanyOwnerName',
    width: 70,
    render: (v: IModelProduct | IModelSeller, record: IModelSeller | any) => {
      let seller
      if (dataIndex === 'product') {
        seller = (v as IModelProduct).seller!
      } else if (dataIndex === 'seller') {
        seller = v
      } else {
        seller = record
      }
      const { companyOwnerName } = seller
      return <Text14Normal>{companyOwnerName}</Text14Normal>
    },
  }
}

const deleteManager = (
  userData: IModelUser,
  refetchRef?: MutableRefObject<Function | null>
): ITableColumn<IModelSeller> => {
  return {
    title: '삭제',
    width: 80,
    render: (record: IModelSeller): JSX.Element => {
      const { useModalProps } = useModal()
      if (userData.isChiefManager(record)) return <>-</>
      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.setData({ user: userData, seller: record })
              useModalProps.toggleModal()
            }}
          >
            [삭제]
          </MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: {
                gqlKey: 'deleteSellerManager',
                variableKey: 'deleteSellerManager',
              },
              title: '담당자 삭제',
              description: `${userData.name}님을 담당자에서 삭제하시겠습니까?`,
            }}
            onAPISuccess={() => {
              if (refetchRef && refetchRef.current) refetchRef.current()
            }}
          />
        </>
      )
    },
  }
}

const createOrDeleteManager = (
  data: IModelUser,
  refetchRef?: MutableRefObject<Function | null>
): ITableColumn<IModelSeller> => {
  return {
    title: '셀러 매니저 등록/관리',
    key: 'createOrDeleteManager',
    width: 80,
    render: (record: IModelSeller) => {
      const [isSelectedSeller, setIsSelectedSeller] = useState<boolean | undefined>(undefined)
      const { useModalProps } = useModal()
      const { sellers, name } = data
      if (isSelectedSeller === undefined && sellers) {
        const sellerIds = sellers.map((x) => x.id)
        if (sellerIds.includes(record.id)) {
          setIsSelectedSeller(true)
        }
      }
      const buttonText = isSelectedSeller ? '[제거]' : '[등록]'
      const gqlKey = isSelectedSeller ? 'deleteSellerManager' : 'createSellerManager'
      const variableKey = isSelectedSeller ? 'deleteSellerManager' : 'createSellerManager'
      const text = isSelectedSeller ? '제거' : '등록'
      return (
        <>
          <MTextButton
            onClick={() => {
              useModalProps.setData({ user: data, seller: record })
              useModalProps.toggleModal()
            }}
          >
            {buttonText}
          </MTextButton>
          <ModalConfirm
            useModalProps={useModalProps}
            fixedProps={{
              mutationKey: {
                gqlKey,
                variableKey,
              },
              title: `담당자 ${text}`,
              description: `${name}님을 담당자로 ${text}하시겠습니까?`,
            }}
            onAPISuccess={() => {
              setIsSelectedSeller(!isSelectedSeller)
              if (refetchRef && refetchRef.current) {
                refetchRef.current()
              }
              message.success(`담당자가 ${text}되었습니다.`)
            }}
          />
        </>
      )
    },
  }
}

const chiefManagerName = (): ITableColumn<IModelSellerBilling> => {
  return {
    title: '셀러 통합매니저',
    key: 'chiefManagerName',
    fixed: 'left',
    width: 130,
    render: (record: IModelSellerBilling) => <Text14Normal>{record.seller?.chiefManagerName || '-'}</Text14Normal>,
  }
}

const fitpetFeeRate = ({ dataIndex = 'sellerBilling' } = {}): ITableColumn<IModelBillingItem> => {
  return {
    title: '플랫폼 수수료(%)',
    key: 'fitpetFeeRate',
    dataIndex,
    width: 120,
    render: (_: IModelSellerBilling) => {
      return <Text14Normal>{_?.seller.fitpetFeeRate}%</Text14Normal>
    },
  }
}

const managerKindText = (userData: IModelUser): ITableColumn<IModelSeller> => {
  return {
    title: '매니저 구분',
    key: 'managerKindText',
    width: 120,
    render: (record: IModelSeller) => {
      return <Text14Normal>{userData.managerKindText(record)}</Text14Normal>
    },
  }
}

export {
  sellersShopName, // 셀러들의 샵 이름 (복수)
  sellerShop, // 셀러 샵 이름
  sellerCompanyName, // 셀러 사업자명
  sellerCompanyOwnerName, // 셀러 대표명
  chiefManagerName, // 셀러 통합매니저
  managerKindText, // 매니저 구분
  createOrDeleteManager, // 셀러 매니저 등록
  deleteManager, // 셀러 매니저 삭제
  fitpetFeeRate, // 플랫폼 수수료
}
