import { useState } from 'react'
import { Upload, Modal } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import { UploadProps } from 'antd/lib/upload'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'

import * as utilForm from '@libs/utils/utilForm'
import { Text14Normal } from 'components/@atomics/texts/TextBase'

export type optionProps = {
  width: string | number
  height: string | number
}

export type MImageUploadProps = UploadProps & {
  option?: optionProps
  maxCount?: number
}

type previewImageType = {
  previewVisible: boolean
  previewImage?: string
  previewTitle?: string
}

function getBase64(img?: File | Blob) {
  if (img) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(img)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
}

// TODO yoon: FILE check는 validation에서 하는게 좋을 듯 함
// function isUploadable(file: UploadFile) {
//   if (file.url) return true
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
//   if (!isJpgOrPng) {
//     message.error('You can only upload JPG/PNG file!')
//   }
//   const isLt5M = file.size / 1024 / 1024 < 5
//   if (!isLt5M) {
//     message.error('Image must smaller than 2MB!')
//   }
//   return isJpgOrPng && isLt5M
// }

const MImageUpload: React.FC<MImageUploadProps> = (props) => {
  const { name, option, maxCount = 1, listType = 'picture-card', fileList = [], accept, ...restProps } = props
  const [previewData, setPreviewData] = useState<previewImageType>({
    previewVisible: false,
    previewImage: undefined,
    previewTitle: undefined,
  })
  const { previewVisible, previewImage, previewTitle } = previewData
  const uploadFileList = utilForm.setUploadFileList(fileList)

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = (await getBase64(file.originFileObj)) as string
    }
    setPreviewData({
      previewVisible: true,
      previewImage: file.url || file.preview,
      previewTitle: file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1),
    })
  }

  const handleCancel = () =>
    setPreviewData({
      ...previewData,
      previewVisible: false,
    })

  return (
    <>
      <StyledUpload
        name={name}
        fileList={uploadFileList}
        listType={listType}
        onPreview={handlePreview}
        option={option}
        accept={accept}
        showUploadList
        {...restProps}
      >
        {uploadFileList.length >= maxCount ? null : (
          <>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>
              <Text14Normal>이미지 업로드</Text14Normal>
            </div>
          </>
        )}
      </StyledUpload>

      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="previewModal" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

const StyledUpload = styled(({ ...rest }) => <Upload {...rest} />)<{ option: optionProps }>`
  .ant-upload-select-picture-card {
    width: ${(props) => (props.option ? props.option.width : '300px')};
    height: ${(props) => (props.option ? props.option.height : '300px')};
  }

  .ant-upload-list-picture-card-container {
    width: ${(props) => (props.option ? props.option.width : '300px')};
    height: ${(props) => (props.option ? props.option.height : '300px')};
  }
`

export default MImageUpload
