import { ModalProps } from '@reboot/hooks/useModal'
import { FC } from 'react'
import styled from 'styled-components'
import BillingHelper from '../helper/BillingHelper'
import BaseModal from './BaseModal'

interface Props {
  modalProps: ModalProps
}

const HelperModal: FC<Props> = ({ modalProps }) => {
  // TODO: 기획 확정되면 컨텐츠 분리해야 함. 라우트->컨텐츠->헬퍼모달 형식으로 분리 예정
  return (
    <BaseModal modalProps={modalProps}>
      <Container>
        <BillingHelper />
      </Container>
    </BaseModal>
  )
}

export default HelperModal

const Container = styled.div`
  width: 50%;
  height: 70%;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  overflow: scroll;
  padding: 30px;

  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`
