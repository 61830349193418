import { TypeUseModalProps } from 'containers/hooks/useModal'
import { ModalConfirm } from 'domains/common/modals/index'

const ModalConfirmDeleteDisplayProductInfos = ({
  useModalProps,
  onAction,
}: {
  useModalProps: TypeUseModalProps<any>
  onAction: () => void
}) => {
  return (
    <ModalConfirm
      useModalProps={useModalProps}
      fixedProps={{
        title: '선택 상품 삭제',
        description: '선택한 상품(들)을 삭제하시겠습니까?',
        confirmMessage: '삭제가 완료 되었습니다.',
        mutationKey: {
          gqlKey: 'deleteDisplayProductInfos',
          getCustomVariables: async () => {
            return {
              variables: {
                input: { ids: useModalProps.data.ids },
              },
            }
          },
        },
      }}
      onAPISuccess={() => {
        onAction()
      }}
    />
  )
}

export default ModalConfirmDeleteDisplayProductInfos
