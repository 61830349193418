import { IModelSellerBillingDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelSellerBillingDefault } from 'containers/models/base/defaultModels'
import { IModelSeller } from 'containers/models/modelSeller'
import * as utilDate from '@libs/utils/utilDate'

export interface IModelSellerBilling extends IModelSellerBillingDefault {
  isBilled: boolean
  seller: IModelSeller
  nextBillingAtText: string
}

export default class ModelSellerBilling extends ModelSellerBillingDefault {
  isBilled: boolean = false
  nextBillingAtText: string = ''

  constructor(data: IModelSellerBilling) {
    super(data)
    this.isBilled = !!this.confirmedAt
    this.nextBillingAtText = utilDate.date2string(this.nextBillingAt)
  }
}
