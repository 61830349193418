import { IModelUserInfoDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelUserInfoDefault } from 'containers/models/base/defaultModels'

export interface IModelUserInfo extends IModelUserInfoDefault {}

export default class ModelUserInfo extends ModelUserInfoDefault {
  constructor(data: IModelUserInfo) {
    super(data)
  }
}
