import styled from 'styled-components'
import { Form, Space, message as antdMessage } from 'antd'
import { MutationFunction } from '@apollo/client'
import { FORM_ITEM_RULES } from '@constants/constForm'
import { FIELD_KEYS, FIELD_VALUES, POLICY_DATA } from '@constants/constData'
import { MFormItemDateRangePicker, MFormItemInput } from 'components/formItems'
import { MModal } from 'components/modals'
import { DomainModalProps } from 'containers/hooks/useModal'
import MMutation from 'components/MMutation'
import { EXCEL_DOWNLOAD_MUTATION_KEY2GQLS } from 'containers/gqls'
import { store } from '@libs/redux/store'
import { MConfirmModalProps } from 'components/modals/MConfirmModal'
import { componentOptionProps } from 'domains/common/SubComponentProvider'
import { Text14Orange } from 'components/@atomics'
import { onCompletedType } from 'containers/hooks/useMMutation'
import {
  endedDate2utcString,
  forFilterEndMonth,
  forFilterStartMonth,
  startedDate2utcString,
} from '@libs/utils/utilDate'
import React, { ReactNode, useCallback, useState } from 'react'

export enum CALENDAR_PICKER_TYPE {
  D = 'date',
  W = 'week',
  M = 'month',
  Y = 'year',
}

export interface FormValuesProps {
  [key: string]: any
}

interface FormModalRequestExcelFileProps extends FormValuesProps {
  password: string
  excelRowCount: number
}

type FixedProps = {
  title: string
  requestExcelGqlKey: keyof typeof EXCEL_DOWNLOAD_MUTATION_KEY2GQLS
  onAPISuccess?: onCompletedType
  componentOption?: componentOptionProps
  confirmMessage?: string
  filter?: {}
  defaultRowCount?: number
  maxRowCount?: number
  isFilterRange?: boolean
  rangeFieldName?: string
  picker?: CALENDAR_PICKER_TYPE
  isBilling?: boolean
  formItemNodes?: ReactNode[]
  onFormItemsNodesToSubmitValues?: (values: FormValuesProps) => FormValuesProps | undefined
}

const ModalRequestExcelFile = ({ useModalProps, fixedProps }: DomainModalProps<MConfirmModalProps, FixedProps>) => {
  const {
    title,
    requestExcelGqlKey,
    onAPISuccess: onAPISuccess_,
    filter,
    defaultRowCount = POLICY_DATA.DEFAULT_EXCEL_FILE_ROW_COUNT,
    maxRowCount = POLICY_DATA.DEFAULT_EXCEL_FILE_MAX_ROW_COUNT,
    isFilterRange = false,
    rangeFieldName = FIELD_KEYS.FIELD_CREATED_AT,
    picker,
    isBilling,
    formItemNodes,
    onFormItemsNodesToSubmitValues,
  } = fixedProps

  const rangeAfter = `${rangeFieldName}After`
  const rangeBefore = `${rangeFieldName}Before`

  const [form] = Form.useForm()
  const { hideModal } = useModalProps
  const [dateRange, setDateRange] = useState<any>(undefined)

  const { getFieldValue } = form
  const ruleCheckPassword = FORM_ITEM_RULES.FORM_ITEM_RULE_VALUE_CONDITIONAL_REQUIRED({
    getFieldValue,
    key: FIELD_KEYS.FIELD_PASSWORD,
    msg: '엑셀 파일 비밀번호를 다시 한번 입력해주세요',
  })

  const onFinish = async (values: FormModalRequestExcelFileProps, mutation: MutationFunction) => {
    let _filter = filter
    if (_filter === undefined) {
      const { mainTableVariables } = store.getState().reduxUIReducers
      _filter = mainTableVariables.filter
    }
    if (isFilterRange && dateRange) {
      _filter = { ..._filter, ...dateRange }
    }

    const externalFormValues = onFormItemsNodesToSubmitValues?.(values) || {}

    const variables = {
      input: {
        filter: _filter,
        pageInfo: {
          first: values.excelRowCount,
        },
        orderBy: 'id',
        password: values.password,
        ...externalFormValues,
      },
    }

    await mutation({ variables })
    hideModal()
  }

  const onAPISuccess = (data: any) => {
    form.resetFields()
    if (onAPISuccess_) {
      onAPISuccess_(data)
    } else {
      antdMessage.success('엑셀 다운로드가 요청되었습니다.')
    }
  }

  const handleSetRange = useCallback((value) => {
    const [after, before] = value

    if (picker === CALENDAR_PICKER_TYPE.M) {
      setDateRange({
        [rangeAfter]: forFilterStartMonth(after._d),
        [rangeBefore]: forFilterEndMonth(before._d),
      })
    } else {
      setDateRange({
        [rangeAfter]: startedDate2utcString(after),
        [rangeBefore]: endedDate2utcString(before),
      })
    }
  }, [])

  return (
    <MMutation gqlKey={requestExcelGqlKey} onAPISuccess={onAPISuccess}>
      {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
        return (
          <MModal
            title={`${title}`}
            width={400}
            form={form}
            loading={loading}
            useModalProps={useModalProps}
            ComponentContent={
              <Form form={form} onFinish={(values) => onFinish(values, mutation)} layout="vertical">
                <StyledSpace direction="vertical" size="middle">
                  {formItemNodes}
                  {isFilterRange && (
                    <div>
                      {isBilling && (
                        <p style={{ marginBottom: '6px' }}>
                          <span style={{ color: 'red' }}>* </span>정산확정월
                        </p>
                      )}
                      <MFormItemDateRangePicker
                        picker={picker}
                        onChange={(value) => {
                          if (value.length === 2) {
                            handleSetRange(value)
                          }
                        }}
                      />
                    </div>
                  )}
                  <MFormItemInput
                    name={FIELD_KEYS.FIELD_PASSWORD}
                    type="password"
                    label={FIELD_VALUES.FIELD_PASSWORD}
                    placeholder={FIELD_VALUES.FIELD_EXCEL_FILE_PASSWORD}
                    extra={
                      <Text14Orange>
                        * 영문대문자/영문소문자/숫자/특수문자 중 2개 이상 포함, 10~16자리 이하로 설정할 수 있습니다.
                      </Text14Orange>
                    }
                    rules={[FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED, FORM_ITEM_RULES.FORM_ITEM_RULE_PASSWORD_FORMAT]}
                  />
                  <MFormItemInput
                    name={FIELD_KEYS.FIELD_CONFIRM_PASSWORD}
                    type="password"
                    placeholder={FIELD_VALUES.FIELD_CONFIRM_EXCEL_FILE_PASSWORD}
                    rules={[
                      FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED,
                      FORM_ITEM_RULES.FORM_ITEM_RULE_CONFIRM_PASSWORD,
                      ruleCheckPassword,
                    ]}
                  />
                  <MFormItemInput
                    name={FIELD_KEYS.FIELD_EXCEL_ROW_COUNT}
                    type="number"
                    initialValue={defaultRowCount}
                    max={maxRowCount}
                    label={FIELD_VALUES.FIELD_EXCEL_ROW_COUNT}
                    placeholder={FIELD_VALUES.FIELD_EXCEL_ROW_COUNT}
                    rules={[
                      FORM_ITEM_RULES.FORM_ITEM_RULE_EXCEL_ROW_COUNT({ excelFileMaxRowCount: maxRowCount }),
                      FORM_ITEM_RULES.FORM_ITEM_RULE_REQUIRED,
                    ]}
                  />
                </StyledSpace>
              </Form>
            }
          />
        )
      }}
    </MMutation>
  )
}

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export default ModalRequestExcelFile
