import Link from 'next/link'
import { Text14Normal } from 'components/@atomics'
import { ITableColumn, ITableColumnFixedHasLinkProps } from 'components/@atomics/MTable'
import { IModelTermStatisticColumn } from 'containers/models/modelTermStatisticColumn'
import ROUTES from '@constants/constRoutes'
import * as utilData from '@libs/utils/utilData'
import { rateToNumber } from '@libs/utils/utilData'

const totalAmount = ({
  title = '결제 금액',
  dataIndex = 'totalAmount',
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<IModelTermStatisticColumn> => {
  return {
    title,
    dataIndex,
    key: 'totalAmount',
    width: 160,
    render: (_: number, record: IModelTermStatisticColumn) => {
      return <Text14Normal>{utilData.currencyText(record.totalAmount)}</Text14Normal>
    },
  }
}

const totalSoldAmount = ({
  title = '판매가',
  dataIndex = 'totalSoldAmount',
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<IModelTermStatisticColumn> => {
  return {
    title,
    dataIndex,
    key: 'totalSoldAmount',
    width: 150,
    render: (_: number, record: IModelTermStatisticColumn) => {
      return <Text14Normal>{utilData.currencyText(record.totalSoldAmount)}</Text14Normal>
    },
  }
}

const totalCouponDiscountAmount = ({
  title = '쿠폰',
  dataIndex = 'totalCouponDiscountAmount',
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'totalCouponDiscountAmount',
    width: 150,
    render: (_: number, record: IModelTermStatisticColumn) => {
      return <Text14Normal>{utilData.currencyText(record.totalCouponDiscountAmount)}</Text14Normal>
    },
  }
}

const totalMileageDiscountAmount = ({
  title = '적립금',
  dataIndex = 'totalMileageDiscountAmount',
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'totalMileageDiscountAmount',
    width: 150,
    render: (_: number, record: IModelTermStatisticColumn) => {
      return <Text14Normal>{utilData.currencyText(record.totalMileageDiscountAmount)}</Text14Normal>
    },
  }
}

const productName = ({
  dataIndex = 'productName',
  title = '상품명',
  hasLink = true,
  width = 120,
} = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'productName',
    width,
    render: (value: string, record: any) => {
      // TODO: remove fakeId when added productId
      const fakeProductId = value
      if (!hasLink) {
        return <Text14Normal>{value}</Text14Normal>
      }
      return (
        <Link
          href={ROUTES.PRODUCTS.PRODUCT_DETAIL}
          as={`${ROUTES.PRODUCTS.PRODUCT_LIST}/${record.productId || fakeProductId}`}
        >
          <a>{value}</a>
        </Link>
      )
    },
  }
}

const fitpetFeeRate = ({
  title = '플랫폼 수수료(%)',
  dataIndex = 'fitpetFeeRate',
}: ITableColumnFixedHasLinkProps = {}): ITableColumn<any> => {
  return {
    title,
    dataIndex,
    key: 'fitpetFeeRate',
    width: 120,
    render: (value: number) => {
      const percentText: string = !value ? '-' : `${rateToNumber(value)}%`
      return <Text14Normal>{percentText}</Text14Normal>
    },
  }
}

export {
  totalAmount, // 결제 금액
  totalSoldAmount, // 판매가
  totalCouponDiscountAmount, // 쿠폰 판매
  totalMileageDiscountAmount, // 적립금
  productName, // 상품명
  fitpetFeeRate, // 플랫폼 수수료
}
