import { useRef } from 'react'
import { pageInfoProps } from 'containers/gqls/data/dataResults'
import { decodeId, encodeId } from '@libs/utils/utilApi'
import { pageInfoInputProps } from 'containers/gqls/query_variables'

const usePageInfoRef = () => {
  const startCursorRef = useRef<string | null>(null)
  const endCursorRef = useRef<string | null>(null)
  const pageSizeRef = useRef<number>(10)

  const setCursor = (pageInfo: pageInfoProps) => {
    const { endCursor, hasNextPage, startCursor, hasPreviousPage } = pageInfo
    startCursorRef.current = hasPreviousPage ? startCursor : null
    endCursorRef.current = hasNextPage ? endCursor : null
  }

  const setPageSize = (pageSize: number) => {
    pageSizeRef.current = pageSize
  }

  const getTableCursor = (page?: number, pageSize?: number) => {
    if (!page || page === 1) return undefined
    const _pageSize = pageSize || pageSizeRef.current
    const prevRowIndex = (page - 1) * _pageSize - 1
    return encodeId('arrayconnection', prevRowIndex.toString())
  }

  const getPage = (pageInfo: pageInfoInputProps) => {
    if (!pageInfo || !pageInfo.after || !pageInfo.first) return 1
    const { id } = decodeId(pageInfo.after)
    const page = (parseInt(id, 10) + 1) / pageInfo.first + 1
    return page
  }

  const getTablePageInfoVariables = (page?: number, pageSize?: number): { pageInfo: pageInfoInputProps } => {
    const first = pageSize || pageSizeRef.current
    return {
      pageInfo: {
        first,
        after: getTableCursor(page, pageSize),
      },
    }
  }

  const getAfterPageInfoVariables = () => {
    return {
      pageInfo: {
        first: pageSizeRef.current,
        after: endCursorRef.current,
      },
    }
  }

  const getBeforePageInfoVariables = () => {
    return {
      pageInfo: {
        last: pageSizeRef.current,
        before: startCursorRef.current,
      },
    }
  }

  return {
    setCursor,
    getPage,
    setPageSize,
    getPageSizeRef: () => pageSizeRef,
    getTablePageInfoVariables,
    getAfterPageInfoVariables,
    getBeforePageInfoVariables,
  }
}

export default usePageInfoRef
