import { ReactElement } from 'react'
import { Menu } from 'antd'
import { MenuProps } from 'antd/lib/menu'
import { useRouter } from 'next/router'

import { MConfirmModal } from 'components/modals'
import { RcMenuInfo } from 'domains/common/menus'
import useModal from 'containers/hooks/useModal'
import ROUTES, { ROUTE_KEYS } from '@constants/constRoutes'
import * as utilCommon from '@libs/utils/utilCommon'

type MenuHeaderProfileProps = {
  setMenuVisible?: (val: boolean) => void
}

const MenuHeaderProfile = ({ setMenuVisible }: MenuHeaderProfileProps): ReactElement<MenuProps> => {
  const router = useRouter()
  const { useModalProps: useModalLogoutProps } = useModal()

  function handleMenuClick(e: RcMenuInfo): void {
    if (e.key === ROUTE_KEYS.MY_ACCOUNT) {
      router.push({ pathname: ROUTES.ACCOUNTS.MY_ACCOUNT })
    }
    if (e.key === ROUTE_KEYS.LOGOUT) useModalLogoutProps.toggleModal()
  }

  return (
    <Menu
      onClick={(e) => {
        setMenuVisible!(false)
        handleMenuClick(e)
      }}
    >
      <Menu.Item key={ROUTE_KEYS.MY_ACCOUNT}>사용자정보</Menu.Item>
      {/*<Menu.Item key={ROUTE_KEYS.NOTICE}>공지사항</Menu.Item>*/}
      {/*<Menu.Item key={ROUTE_KEYS.FAQ}>자주 묻는 질문</Menu.Item>*/}
      <Menu.Divider />
      <Menu.Item key={ROUTE_KEYS.LOGOUT}>로그아웃</Menu.Item>
      <MConfirmModal
        useModalProps={useModalLogoutProps}
        onAction={() => utilCommon.cleanAndGoToLogin(router)}
        fixedProps={{ title: '로그아웃', description: <>로그아웃 하시겠습니까?</> }}
      />
    </Menu>
  )
}

export default MenuHeaderProfile
