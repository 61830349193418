import { FunctionComponent, ReactElement, ReactNode } from 'react'
import { Row, Col } from 'antd'
import { ColProps } from 'antd/lib/col'
import styled from 'styled-components'

import { Text14Normal } from 'components/@atomics'
import * as utilData from '@libs/utils/utilData'
import * as utilDate from '@libs/utils/utilDate'

export type MWrapperRowProps = ColProps & {
  styledLabelWidth?: number
  children?: ReactElement<any> | ReactNode
  label: string
  name?: string
  tableText?: boolean
  currencyText?: boolean
  currencyDetailText?: boolean
  countText?: boolean
  dateText?: boolean
  dateDetailText?: boolean
  prefix?: ReactNode
}

const MWrapperRow: FunctionComponent<MWrapperRowProps> = ({
  styledLabelWidth,
  children,
  label,
  name,
  tableText,
  currencyText,
  dateText,
  dateDetailText,
  countText,
}) => {
  const setText = (text: any): string | number => {
    if (tableText) {
      return utilData.tableText(text as string)
    }
    if (currencyText) {
      return utilData.currencyText(text as number)
    }
    if (dateText) {
      return utilDate.date2string(text as Date)
    }
    if (dateDetailText) {
      return utilDate.date2string(text as Date, true)
    }
    if (countText) {
      return utilData.countText(text as number)
    }
    return text
  }

  return (
    <Row style={{ marginBottom: '24px' }} key={name}>
      <StyleColLabel styledLabelWidth={130 || styledLabelWidth}>
        <Text14Normal>{label}</Text14Normal>
      </StyleColLabel>
      <Col>
        <Text14Normal>{setText(children)}</Text14Normal>
      </Col>
    </Row>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyleColLabel = styled(({ styledLabelWidth, ...rest }) => <Col {...rest} />)<ColProps>`
  width: ${(props) => props.styledLabelWidth}px;
`

export default MWrapperRow
