import { gql } from '@apollo/client'
import SELLER_FRAGMENTS from 'containers/gqls/accounts/seller/fragments'
import USER_FRAGMENTS from 'containers/gqls/accounts/user/fragments'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const SELLER_MUTATION_GQLS = {
  CREATE_SELLER_MANAGER: gql`
    mutation createSellerManager($input: SellerManagerInput!) {
      createSellerManager(input: $input) {
        sellerUser {
          id
        }
      }
    }
  `,
  CREATE_SELLER_BY_FITPET_ADMIN: gql`
    mutation createSellerByFitpetAdmin($input: CreateSellerByFitpetAdminInput!) {
      createSellerByFitpetAdmin(input: $input) {
        seller {
          ...sellerOnly
        }
      }
    }
    ${SELLER_FRAGMENTS.sellerOnly}
  `,
  UPDATE_SELLER_BY_USER_REQUEST: gql`
    mutation updateSellerByUserRequest($id: ID!, $input: UpdateSellerInput!) {
      updateSellerByUserRequest(id: $id, input: $input) {
        seller {
          ...sellerOnly
          chiefManager {
            ...userBasicInfo
          }
          approvedAt
          status {
            ...status
          }
          updateInfo {
            seller {
              ...sellerOnly
            }
          }
          users {
            edges {
              node {
                ...userBasicInfo
              }
            }
          }
        }
      }
    }
    ${USER_FRAGMENTS.userBasicInfo}
    ${DEFAULT_FRAGMENTS.status}
    ${SELLER_FRAGMENTS.sellerOnly}
  `,
  UPDATE_SELLER_BY_FITPET_ADMIN: gql`
    mutation updateSellerByFitpetAdmin($id: ID!, $input: UpdateSellerByFitpetAdminInput!) {
      updateSellerByFitpetAdmin(id: $id, input: $input) {
        seller {
          ...sellerOnly
        }
      }
    }
    ${SELLER_FRAGMENTS.sellerOnly}
  `,
  UPDATE_SELLER_NOTIFICATION: gql`
    mutation updateSellerNotification($id: ID!, $input: SellerNotificationInput!) {
      updateSellerNotification(id: $id, input: $input) {
        seller {
          ...sellerOnly
        }
      }
    }
    ${SELLER_FRAGMENTS.sellerOnly}
  `,
  CHANGE_CHIEF_SELLER_MANAGER: gql`
    mutation changeChiefSellerManager($input: SellerManagerInput!) {
      changeChiefSellerManager(input: $input) {
        sellerUser {
          id
        }
      }
    }
  `,
  DELETE_SELLER_MANAGER: gql`
    mutation deleteSellerManager($input: SellerManagerInput!) {
      deleteSellerManager(input: $input) {
        sellerUser {
          id
        }
      }
    }
  `,
}

export default SELLER_MUTATION_GQLS
