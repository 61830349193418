import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

const CATEGORY_FRAGMENTS = {
  get categoryWithParent() {
    return gql`
      fragment categoryWithParent on CategoryType {
        ...category
        parentCategory {
          id
        }
      }
      ${DEFAULT_FRAGMENTS.category}
    `
  },
  get categoryWithChildren() {
    return gql`
      ${this.categoriesWithChildren}
      fragment categoryWithChildren on CategoryType {
        ...category
        categoryChildren {
          ...categoriesWithChildren
        }
      }
      ${DEFAULT_FRAGMENTS.category}
    `
  },
  get categoriesWithParent() {
    return gql`
      ${this.categoryWithParent}
      fragment categoriesWithParent on CategoryTypeConnection {
        edges {
          node {
            ...categoryWithParent
          }
        }
      }
    `
  },
  get categoriesWithChildren() {
    return gql`
      fragment categoriesWithChildren on CategoryTypeConnection {
        edges {
          node {
            ...category
          }
        }
      }
      ${DEFAULT_FRAGMENTS.category}
    `
  },
}

export default CATEGORY_FRAGMENTS
