import { EXCEL_DOWNLOAD_MUTATION_KEY2GQLS } from 'containers/gqls'
import React, { FC, ReactNode } from 'react'
import useModal from 'containers/hooks/useModal'
import { MButton } from 'components/@atomics'
import { DownloadOutlined } from '@ant-design/icons'
import ModalRequestExcelFile, {
  CALENDAR_PICKER_TYPE,
  FormValuesProps,
} from 'domains/common/modals/ModalRequestExcelFile'
import { message } from 'antd'
import { FIELD_KEYS } from '@constants/constData'

export type ButtonRequestExcelProps = {
  title: string
  requestExcelGqlKey: keyof typeof EXCEL_DOWNLOAD_MUTATION_KEY2GQLS
  requestButtonText?: string
  filter?: {}
  defaultRowCount?: number
  maxRowCount?: number
  isFilterRange?: boolean
  rangeFieldName?: string
  picker?: CALENDAR_PICKER_TYPE
  isBilling?: boolean
  formItemNodes?: ReactNode[]
  onFormItemsNodesToSubmitValues?: (values: FormValuesProps) => FormValuesProps | undefined
}

const ButtonRequestExcel: FC<ButtonRequestExcelProps> = ({
  title,
  requestExcelGqlKey,
  requestButtonText = '엑셀 다운',
  filter,
  defaultRowCount,
  maxRowCount,
  isFilterRange = false,
  rangeFieldName = FIELD_KEYS.FIELD_CREATED_AT,
  picker,
  isBilling,
  formItemNodes,
  onFormItemsNodesToSubmitValues,
}) => {
  const { useModalProps: requestExcelFileModalProps } = useModal()
  const handleExcelDownClick = () => {
    requestExcelFileModalProps.toggleModal()
  }
  return (
    <>
      <MButton onClick={() => handleExcelDownClick()} icon={<DownloadOutlined />}>
        {requestButtonText}
      </MButton>
      <div
        role="none"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <ModalRequestExcelFile
          useModalProps={requestExcelFileModalProps}
          onAPISuccess={async () => {
            await message.success('엑셀 파일 생성을 요청하였습니다.')
          }}
          fixedProps={{
            requestExcelGqlKey,
            isBilling,
            title,
            filter,
            defaultRowCount,
            maxRowCount,
            isFilterRange,
            rangeFieldName,
            picker,
            formItemNodes,
            onFormItemsNodesToSubmitValues,
          }}
        />
      </div>
    </>
  )
}

export default ButtonRequestExcel
