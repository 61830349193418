import { queryParamsProps } from 'components/MQuery'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@libs/redux/store'
import { MListSortSelect, MMainTable } from 'components'
import { MLinkRouterButton } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import { IModelSeller } from 'containers/models/modelSeller'
import tableCols from 'domains/admin/tableCols'
import ROUTES from '@constants/constRoutes'

const tableColumns: Array<ITableColumn<any>> = [
  tableCols.common.ID(),
  tableCols.seller.sellerShop(),
  tableCols.status.suspendApproveRequest({
    modelKey: 'status',
    variableKey: 'statusId',
    descriptionComponentKey: 'descriptionSeller',
  }),
  tableCols.status.statusText({}),
  tableCols.common.baseTextFromModel({ title: '통합 매니저명', dataIndex: 'chiefManager', key: 'name' }),
  tableCols.common.baseTextFromModel({
    title: '통합 매니저 이메일',
    dataIndex: 'chiefManager',
    key: 'email',
    width: 160,
  }),
  tableCols.date.dateText({ title: '셀러 등록일', key: 'approvedAt', dataIndex: 'approvedAt' }),
  {
    title: '셀러정보 변경 신청',
    width: 140,
    render: (record: IModelSeller) => {
      if (record.updateStatus?.value === 'REQUEST' || record.updateStatus?.value === 'REJECTED') {
        return (
          <MLinkRouterButton sider={false} openNewWindow href={`${ROUTES.PARTNERS.SELLER_LIST}/${record._id}/change`}>
            [보기]
          </MLinkRouterButton>
        )
      }
      return <>-</>
    },
  },
  tableCols.common.baseText({ title: '사방넷 API KEY', dataIndex: 'sabangnetToken' }),
]

const MainTableSellerList = () => {
  const refetchMainTableToggle = useSelector((state: RootState) => state.reduxUIReducers.refetchMainTableToggle)
  const refetchRef = useRef<any>(null)

  useEffect(() => {
    if (refetchRef && refetchRef.current) {
      refetchRef.current()
    }
  }, [refetchMainTableToggle])

  const queryParams: queryParamsProps = {
    gqlKey: 'sellerListTable',
    dataKey: 'sellers',
    variables: {
      filter: { status: { exclude: ['REQUEST'] } },
      orderBy: '-createdAt',
    },
  }
  return (
    <MMainTable
      TitleSet={{ title: '셀러 목록', unit: '명' }}
      columns={tableColumns}
      HeaderLeftButtons={[
        <MLinkRouterButton buttonType="button" type="primary" href={ROUTES.PARTNERS.SELLER_ADD}>
          셀러 등록
        </MLinkRouterButton>,
      ]}
      HeaderRightButtons={[
        <MListSortSelect filterOptions={['FIELD_SORT_USER_CREATED_AT_DESC', 'FIELD_SORT_USER_CREATED_AT']} />,
      ]}
      queryParams={queryParams}
    />
  )
}

export default MainTableSellerList
