import { ReactElement, MutableRefObject } from 'react'
import { Row, Col } from 'antd'
import { IModelProductQna } from 'containers/models/modelProductQna'
import MDescriptionsTable, {
  MDescriptionsTableDataProps,
  MDescriptionsTableProps,
} from 'components/descriptions/MDescriptionsTable'
import descriptionsTable from 'domains/admin/descriptions'

type DescriptionsQnaProductListProps = {
  currentQna: IModelProductQna
  refetchRef: MutableRefObject<Function>
  isSeller?: boolean
  setCurrentQna?: (currentQna: IModelProductQna) => void
}
type FnQnaQuestionData = (currentQna: IModelProductQna, isSeller: boolean) => MDescriptionsTableDataProps
type FnQnaAnswerData = (
  currentQna: IModelProductQna,
  refetchRef: MutableRefObject<Function>,
  isSeller: boolean,
  setCurrentQna?: (currentQna: IModelProductQna) => void
) => MDescriptionsTableDataProps

const QnaQuestionData: FnQnaQuestionData = (currentQna, isSeller) => {
  const itemOptions = [
    descriptionsTable.common.baseTextDate({ key: 'createdAt', label: '접수일시', content: currentQna?.createdAt }),
    descriptionsTable.common.writer({ content: currentQna?.user }),
    descriptionsTable.common.productCode({ contents: currentQna?.product }),
    descriptionsTable.common.baseText({ key: 'productName', label: '상품명', content: currentQna?.product?.realName }),
    descriptionsTable.common.baseText({
      key: 'shopName',
      label: '상품 셀러',
      content: currentQna?.product?.seller.shopName,
    }),
    descriptionsTable.common.questionBody({ contents: currentQna }),
  ]

  if (!isSeller) {
    itemOptions.splice(
      2,
      0,
      descriptionsTable.common.baseText({ key: 'userInfo', label: '연락처', content: currentQna?.user?.mobileNumber })
    )
  }

  return { title: '문의 내용', itemOptions }
}

const QnaAnswerData: FnQnaAnswerData = (
  currentQna,
  refetchRef,
  isSeller = false,
  setCurrentQna?: (currentQna: IModelProductQna) => void
) => {
  let formComment
  if (isSeller) {
    formComment = descriptionsTable.common.formComment({
      key: 'productQna',
      label: '답변 내용',
      content: currentQna,
      refetchRef,
      setCurrentQna,
    })
  } else {
    formComment = descriptionsTable.common.answerBody({ contents: currentQna })
  }
  return {
    title: '답변 내용',
    itemOptions: [
      descriptionsTable.common.baseTextDate({ key: 'answeredAt', label: '답변일', content: currentQna?.answeredAt }),
      descriptionsTable.common.isAnswered({ content: currentQna?.isAnswered }),
      descriptionsTable.common.answeredUser({ contents: currentQna }),
      formComment,
    ],
  }
}

const DescriptionsTableQnaProductList = ({
  currentQna,
  refetchRef,
  isSeller = false,
  setCurrentQna,
}: DescriptionsQnaProductListProps): ReactElement<MDescriptionsTableProps> => {
  return (
    <Row gutter={[12, 0]}>
      <Col>
        <MDescriptionsTable tableData={QnaQuestionData(currentQna, isSeller)} />
      </Col>
      <Col>
        <MDescriptionsTable tableData={QnaAnswerData(currentQna, refetchRef, isSeller, setCurrentQna)} />
      </Col>
    </Row>
  )
}

export { DescriptionsTableQnaProductList as default }
