import { gql } from '@apollo/client'

const APP_VERSION_MUTATION_GQLS = {
  UPDATE_MIN_APP_VERSION: gql`
    mutation updateMinAppVersion($input: MinAppVersionInput!) {
      updateMinAppVersion(input: $input) {
        minAppVersion {
          minAppVersion
          normalAppVersion
          latestAppVersion
          platform
        }
      }
    }
  `,
}

export default APP_VERSION_MUTATION_GQLS
