import { MFormItemWrapper } from 'components'
import { useState, useEffect, useRef, useContext, createContext } from 'react'
import { Input, Form, Row, Col } from 'antd'
import styled from 'styled-components'
import { MArrowUpButton, MArrowDownButton } from 'components/@atomics/buttons'
import { FIELD_KEYS } from '@constants/constData'

const EditableContext = createContext<any>({})

interface MFormTableEditableRowProps {
  index: number
}

const MFormTableEditableRow: React.FC<MFormTableEditableRowProps> = ({ ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

type MFormTableEditableCellProps = {
  index: number
  title: React.ReactNode
  editable: boolean
  sortable: boolean
  children: React.ReactNode
  dataIndex: string
  record: any
  handleSave: (record: any) => void
  handleSort: (currentOrder: number, targetOrder: number) => void
  EditableContext: React.Context<any>
}

const MFormTableEditableCell: React.FC<MFormTableEditableCellProps> = ({
  title,
  editable,
  sortable,
  children,
  dataIndex,
  record,
  handleSave,
  handleSort,
  ...restProps
}) => {
  const [editing, setEditing] = useState<boolean>(false)
  const inputRef = useRef<Input>(null)
  const form = useContext(EditableContext)

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus()
    }
  }, [editing])

  const toggleEdit = (toggle: boolean) => {
    setEditing(toggle)
    form.setFieldsValue({ [dataIndex]: record[dataIndex] })
  }

  const save = (e: any) => {
    toggleEdit(false)
    handleSave({ ...record, [dataIndex]: e?.target?.value })
  }

  const sort = (up: boolean) => {
    const currentSeries: number = record[FIELD_KEYS.FIELD_SERIES]
    const targetSeries: number = currentSeries + (up ? -1 : 1)
    handleSort(record[FIELD_KEYS.FIELD_SERIES], targetSeries)
  }

  let childNode = children
  if (editable) {
    childNode = editing ? (
      <MFormItemWrapper style={{ margin: 0 }} name={dataIndex}>
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </MFormItemWrapper>
    ) : (
      <MFormItemWrapper style={{ margin: 0 }}>
        <EditableCellDiv onClick={() => toggleEdit(true)}>{children}</EditableCellDiv>
      </MFormItemWrapper>
    )
  } else if (sortable) {
    childNode = (
      <SortCellRow justify="center" gutter={16}>
        <Col>
          <MArrowUpButton onClick={() => sort(true)} />
        </Col>
        <Col>
          <MArrowDownButton onClick={() => sort(false)} />
        </Col>
      </SortCellRow>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

const EditableCellDiv = styled.div`
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid #c8c8c8;
  height: 33px;
  margin-top: -10px;
  margin-bottom: -12px;
`

const SortCellRow = styled(Row)`
  position: absolute;
  top: 50%;
`

export { MFormTableEditableRow, MFormTableEditableCell }
