import { IModelCancelTransactionDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelCancelTransactionDefault } from 'containers/models/base/defaultModels'

export interface IModelCancelTransaction extends IModelCancelTransactionDefault {}

export default class ModelCancelTransaction extends ModelCancelTransactionDefault {
  constructor(data: IModelCancelTransaction) {
    super(data)
  }
}
