import { IModelUser } from 'containers/models/modelUser'
import { IModelDevice } from 'containers/models/modelDevice'

export interface IModelFakeWhoami {
  user: { data: IModelUser }
  device: { data: IModelDevice }
}

export default class ModelFakeWhoami {
  user: { data: IModelUser } = { data: {} as IModelUser }
  device: { data: IModelDevice } = { data: {} as IModelDevice }

  constructor(data: IModelFakeWhoami) {
    Object.assign(this, data)
  }
}
