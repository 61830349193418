import { USER_MODE } from '@constants/constDomain'
import useUserInfo from 'containers/hooks/useUserInfo'
import { ReactElement } from 'react'
import { Row, Col } from 'antd'
import { IModelReview } from 'containers/models/modelReview'
import MDescriptionsTable, {
  MDescriptionsTableDataProps,
  MDescriptionsTableProps,
} from 'components/descriptions/MDescriptionsTable'
import descriptionsTable from 'domains/admin/descriptions'

const QnaReviewData = (currentReview: IModelReview): MDescriptionsTableDataProps => {
  const itemOptions = [
    descriptionsTable.common.baseTextDate({ key: 'createdAt', label: '등록일시', content: currentReview.createdAt }),
    descriptionsTable.common.baseTextDate({ key: 'updatedAt', label: '수정일시', content: currentReview.updatedAt }),
    descriptionsTable.common.writer({ content: currentReview.user }),
    descriptionsTable.common.baseText({
      key: 'isPhotoReview',
      label: '리뷰 구분',
      content: currentReview.isPhotoReview ? '포토' : '일반',
    }),
    descriptionsTable.common.baseText({ key: 'score', label: '평점', content: currentReview.score }),
    descriptionsTable.common.productCodeToShop({ content: currentReview.product }),
    descriptionsTable.common.baseText({ key: 'productName', label: '상품명', content: currentReview.product.realName }),
    descriptionsTable.common.reviewBody({ content: currentReview }),
    descriptionsTable.common.reviewImage({ content: currentReview }),
  ]
  const userMode = useUserInfo.useUserMode()

  if (userMode === USER_MODE.ADMIN) {
    itemOptions.splice(
      2,
      0,
      descriptionsTable.common.baseText({
        key: 'mobileNumber',
        label: '연락처',
        content: currentReview.user.mobileNumber,
      })
    )
  }

  return { title: '리뷰 내용', itemOptions }
}

const DescriptionsTableQnaReviewList = ({
  currentReview,
}: {
  currentReview: IModelReview
}): ReactElement<MDescriptionsTableProps> => (
  <Row gutter={[12, 0]}>
    <Col>
      <MDescriptionsTable tableData={QnaReviewData(currentReview)} />
    </Col>
  </Row>
)

export default DescriptionsTableQnaReviewList
