import { DataBase } from 'containers/gqls/data'
import { ModelBase } from 'containers/models'
import {
  IModelAdDefault,
  IModelAdImageDefault,
  IModelAsInfoDefault,
  IModelBankDefault,
  IModelBannerDefault,
  IModelBannerImageDefault,
  IModelBannerInfoDefault,
  IModelBillingItemDefault,
  IModelBrandDefault,
  IModelCacheProductDefault,
  IModelCancelReasonDefault,
  IModelCancelTransactionDefault,
  IModelCartItemDefault,
  IModelCartItemHistoryDefault,
  IModelCategoryDefault,
  IModelCheckTokenAssociationDefault,
  IModelCouponByProductDefault,
  IModelCouponDefault,
  IModelCouponGroupDefault,
  IModelCouponKakaoTemplateDefault,
  IModelCoverflowBannerInfoDefault,
  IModelCoverflowDefault,
  IModelCustomerAddressDefault,
  IModelCustomerGradeDefault,
  IModelCustomerTemplateAddressDefault,
  IModelDashboardBillingDefault,
  IModelDashboardBoardDefault,
  IModelDashboardDefault,
  IModelDashboardNoticeDefault,
  IModelDashboardOrderDefault,
  IModelDashboardOrderTimeseriesDefault,
  IModelDashboardProductDefault,
  IModelDashboardUserDefault,
  IModelDefaultReturnChangeGuideDefault,
  IModelDesignersBannerInfoDefault,
  IModelDeviceDefault,
  IModelDisplayCollectionDefault,
  IModelDisplayProductInfoDefault,
  IModelExcelExportRequestDefault,
  IModelFaqDefault,
  IModelFitpetLinkDefault,
  IModelHomeDefault,
  IModelHomeSectionDefault,
  IModelInactiveUserDefault,
  IModelInfluencerAddressDefault,
  IModelInfluencerDefault,
  IModelInfluencerStatisticColumnDefault,
  IModelInfluencerStatusCountDefault,
  IModelInformEmailAttachmentDefault,
  IModelInformEmailQueueDefault,
  IModelInformKakaoQueueDefault,
  IModelInformPushQueueDefault,
  IModelInformSmsHistoryDefault,
  IModelInformSmsQueueDefault,
  IModelInitDataDefault,
  IModelLeaveReasonDefault,
  IModelMileageDefault,
  IModelMileageHistoryDefault,
  IModelMileagePolicyDefault,
  IModelMileageRewardPolicyDefault,
  IModelMinAppVersionDefault,
  IModelNoticeDefault,
  IModelOrderDefault,
  IModelOrderItemAdminNoteDefault,
  IModelOrderItemCancelReasonDefault,
  IModelOrderItemCountDefault,
  IModelOrderItemDefault,
  IModelOrderItemReturnReasonDefault,
  IModelOrderRefundInfoDefault,
  IModelPageInfoDefault,
  IModelPopularSearchTermDefault,
  IModelPopupBannerInfoDefault,
  IModelProductCategoryDefault,
  IModelProductDefault,
  IModelProductImageDefault,
  IModelProductOptionDefault,
  IModelProductOptionGroupDefault,
  IModelProductOptionKindDefault,
  IModelProductOptionSkuDefault,
  IModelProductOptionValueDefault,
  IModelProductPromotionDefault,
  IModelProductQnaDefault,
  IModelProductStatisticByInfluencerColumnDefault,
  IModelProductStatisticBySellerColumnDefault,
  IModelProductStatusCountDefault,
  IModelPushDefault,
  IModelPushUserHistoryDefault,
  IModelRefundInfoDefault,
  IModelRefundShippingInfoDefault,
  IModelRemoteAreaDefault,
  IModelReturnInfoDefault,
  IModelReturnInfoImageDefault,
  IModelReturnReasonDefault,
  IModelReviewDefault,
  IModelReviewGroupDefault,
  IModelReviewImageDefault,
  IModelSearchHistoryDefault,
  IModelSellerAddressDefault,
  IModelSellerBillingDefault,
  IModelSellerDefault,
  IModelSellerStatisticColumnDefault,
  IModelSellerStatusCountDefault,
  IModelSellerTemplateAddressDefault,
  IModelSellerUserDefault,
  IModelServerConfigDefault,
  IModelServiceQnaDefault,
  IModelServiceQnaImageDefault,
  IModelShippingCompanyDefault,
  IModelShippingDefault,
  IModelShippingPresetDefault,
  IModelShippingTrackDefault,
  IModelSkuDefault,
  IModelSkuHistoryDefault,
  IModelSlimBannerInfoDefault,
  IModelSocialServiceDefault,
  IModelStatusDefault,
  IModelStatusHistoryDefault,
  IModelSubRollBannerInfoDefault,
  IModelTermStatisticColumnDefault,
  IModelTimeseriesValueDefault,
  IModelTopRollBannerInfoDefault,
  IModelTotalInfluencerStatisticDefault,
  IModelTotalSalesStatisticDefault,
  IModelTotalSellerStatisticDefault,
  IModelTransactionDefault,
  IModelUpdatedBrandDefault,
  IModelUpdatedProductDefault,
  IModelUpdatedSellerDefault,
  IModelUserCertificationDefault,
  IModelUserCountDefault,
  IModelUserDefault,
  IModelUserInfoAccountDefault,
  IModelUserInfoDefault,
  IModelUserSubscriptionDefault,
  IModelWeeklyBestBannerInfoDefault,
} from 'containers/models/base/defaultModelInterfaces'

export class ModelPageInfoDefault extends ModelBase {
  hasNextPage = false
  hasPreviousPage = false
  startCursor = ''
  endCursor = ''
  constructor(data: IModelPageInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelAdImageDefault extends ModelBase {
  image = ''
  ad: IModelAdDefault = {} as IModelAdDefault
  displayType = ''
  constructor(data: IModelAdImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.ad = DataBase.getModel(this.ad)
  }
}

export class ModelAdDefault extends ModelBase {
  displayOrder = 0
  startedAt = null
  endedAt = null
  petType = ''
  createdAt = null
  updatedAt = null
  name = ''
  description = ''
  slug = ''
  adType = ''
  isShown = false
  style = ''
  adCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  adImages: IModelAdImageDefault[] = [] as IModelAdImageDefault[]
  adDisplayCollections: IModelDisplayCollectionDefault[] = [] as IModelDisplayCollectionDefault[]
  mainImage: IModelAdImageDefault = {} as IModelAdImageDefault
  isFinished = false
  isCouponDownloaded = false
  couponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  constructor(data: IModelAdDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.adCouponGroups = DataBase.getListModel(this.adCouponGroups)
    this.adImages = DataBase.getListModel(this.adImages)
    this.adDisplayCollections = DataBase.getListModel(this.adDisplayCollections)
    this.mainImage = DataBase.getModel(this.mainImage)
    this.couponGroups = DataBase.getListModel(this.couponGroups)
  }
}

export class ModelAsInfoDefault extends ModelBase {
  seller: IModelSellerDefault = {} as IModelSellerDefault
  name = ''
  telephoneNumber = ''
  productSet: IModelProductDefault[] = [] as IModelProductDefault[]
  constructor(data: IModelAsInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.seller = DataBase.getModel(this.seller)
    this.productSet = DataBase.getListModel(this.productSet)
  }
}

export class ModelBankDefault extends ModelBase {
  name = ''
  code = ''
  constructor(data: IModelBankDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelBannerImageDefault extends ModelBase {
  image = ''
  index = ''
  banner: IModelBannerDefault = {} as IModelBannerDefault
  displayDevice = ''
  link = ''
  constructor(data: IModelBannerImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.banner = DataBase.getModel(this.banner)
  }
}

export class ModelBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  mobileImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  constructor(data: IModelBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.mobileImage = DataBase.getModel(this.mobileImage)
  }
}

export class ModelBannerDefault extends ModelBase {
  displayOrder = 0
  petType = ''
  createdAt = null
  updatedAt = null
  isAlways = false
  startedAt = null
  endedAt = null
  name = ''
  bannerType = ''
  isPluralDisplay = false
  allowDisplay = false
  displayDevice = ''
  bannerTypeInfo = ''
  style = ''
  bannerImages: IModelBannerImageDefault[] = [] as IModelBannerImageDefault[]
  topSlimBannerInfo: IModelSlimBannerInfoDefault = {} as IModelSlimBannerInfoDefault
  slimBannerInfo: IModelSlimBannerInfoDefault = {} as IModelSlimBannerInfoDefault
  popupBannerInfo: IModelPopupBannerInfoDefault = {} as IModelPopupBannerInfoDefault
  topRollBannerInfo: IModelTopRollBannerInfoDefault = {} as IModelTopRollBannerInfoDefault
  designersBannerInfo: IModelDesignersBannerInfoDefault = {} as IModelDesignersBannerInfoDefault
  weeklyBestBannerInfo: IModelWeeklyBestBannerInfoDefault = {} as IModelWeeklyBestBannerInfoDefault
  coverflowBannerInfo: IModelCoverflowBannerInfoDefault = {} as IModelCoverflowBannerInfoDefault
  brandRollBannerInfo: IModelSubRollBannerInfoDefault = {} as IModelSubRollBannerInfoDefault
  apparelCoverflowBannerInfo: IModelCoverflowBannerInfoDefault = {} as IModelCoverflowBannerInfoDefault
  apparelRollBannerInfo: IModelSubRollBannerInfoDefault = {} as IModelSubRollBannerInfoDefault
  accessoriesCoverflowBannerInfo: IModelCoverflowBannerInfoDefault = {} as IModelCoverflowBannerInfoDefault
  accessoriesRollBannerInfo: IModelSubRollBannerInfoDefault = {} as IModelSubRollBannerInfoDefault
  homeMiddleBannerInfo: IModelBannerInfoDefault = {} as IModelBannerInfoDefault
  homeBottomBannerInfo: IModelBannerInfoDefault = {} as IModelBannerInfoDefault
  productTopBannerInfo: IModelBannerInfoDefault = {} as IModelBannerInfoDefault
  constructor(data: IModelBannerDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.bannerImages = DataBase.getListModel(this.bannerImages)
    this.topSlimBannerInfo = DataBase.getModel(this.topSlimBannerInfo)
    this.slimBannerInfo = DataBase.getModel(this.slimBannerInfo)
    this.popupBannerInfo = DataBase.getModel(this.popupBannerInfo)
    this.topRollBannerInfo = DataBase.getModel(this.topRollBannerInfo)
    this.designersBannerInfo = DataBase.getModel(this.designersBannerInfo)
    this.weeklyBestBannerInfo = DataBase.getModel(this.weeklyBestBannerInfo)
    this.coverflowBannerInfo = DataBase.getModel(this.coverflowBannerInfo)
    this.brandRollBannerInfo = DataBase.getModel(this.brandRollBannerInfo)
    this.apparelCoverflowBannerInfo = DataBase.getModel(this.apparelCoverflowBannerInfo)
    this.apparelRollBannerInfo = DataBase.getModel(this.apparelRollBannerInfo)
    this.accessoriesCoverflowBannerInfo = DataBase.getModel(this.accessoriesCoverflowBannerInfo)
    this.accessoriesRollBannerInfo = DataBase.getModel(this.accessoriesRollBannerInfo)
    this.homeMiddleBannerInfo = DataBase.getModel(this.homeMiddleBannerInfo)
    this.homeBottomBannerInfo = DataBase.getModel(this.homeBottomBannerInfo)
    this.productTopBannerInfo = DataBase.getModel(this.productTopBannerInfo)
  }
}

export class ModelBillingItemDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  quantity = 0
  fitpetFeeRate = 0
  mileageRewardRate = 0
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  returnInfo: IModelReturnInfoDefault = {} as IModelReturnInfoDefault
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  refundShippingInfo: IModelRefundShippingInfoDefault = {} as IModelRefundShippingInfoDefault
  sellerBilling: IModelSellerBillingDefault = {} as IModelSellerBillingDefault
  billingType = ''
  sellerFee = 0
  fitpetFee = 0
  memo = ''
  originalPrice = 0
  discountPrice = 0
  productPromotionDiscountPrice = 0
  orderCouponDiscountPrice = 0
  couponDiscountPrice = 0
  mileageDiscountPrice = 0
  isSellerBilled = false
  payMethodType = ''
  supplyAmount = 0
  promotionAmount = 0
  sellerSupplyAmount = 0
  sellerPromotionAmount = 0
  constructor(data: IModelBillingItemDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.orderItem = DataBase.getModel(this.orderItem)
    this.returnInfo = DataBase.getModel(this.returnInfo)
    this.shipping = DataBase.getModel(this.shipping)
    this.refundShippingInfo = DataBase.getModel(this.refundShippingInfo)
    this.sellerBilling = DataBase.getModel(this.sellerBilling)
  }
}

export class ModelBrandDefault extends ModelBase {
  displayOrder = 0
  petType = ''
  createdAt = null
  updatedAt = null
  sellers: IModelSellerDefault[] = [] as IModelSellerDefault[]
  code = ''
  name = ''
  requestedAt = null
  approvedAt = null
  updateApprovedAt = null
  status: IModelStatusDefault = {} as IModelStatusDefault
  updateInfo: IModelUpdatedBrandDefault = {} as IModelUpdatedBrandDefault
  updateStatus: IModelStatusDefault = {} as IModelStatusDefault
  listImage = ''
  backgroundImage = ''
  wishedBrandUsers: IModelUserDefault[] = [] as IModelUserDefault[]
  brandProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  includedBrandsCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  excludedBrandsCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  brandOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  brandBillings: IModelSellerBillingDefault[] = [] as IModelSellerBillingDefault[]
  wishedCount = 0
  isWished = false
  constructor(data: IModelBrandDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.sellers = DataBase.getListModel(this.sellers)
    this.status = DataBase.getModel(this.status)
    this.updateInfo = DataBase.getModel(this.updateInfo)
    this.updateStatus = DataBase.getModel(this.updateStatus)
    this.wishedBrandUsers = DataBase.getListModel(this.wishedBrandUsers)
    this.brandProducts = DataBase.getListModel(this.brandProducts)
    this.includedBrandsCouponGroups = DataBase.getListModel(this.includedBrandsCouponGroups)
    this.excludedBrandsCouponGroups = DataBase.getListModel(this.excludedBrandsCouponGroups)
    this.brandOrderItems = DataBase.getListModel(this.brandOrderItems)
    this.brandBillings = DataBase.getListModel(this.brandBillings)
  }
}

export class ModelCacheProductDefault extends ModelBase {
  totalCount = 0
  edges = ''
  pageInfo = ''
  constructor(data: IModelCacheProductDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelCancelReasonDefault extends ModelBase {
  requestType = ''
  requestMessage = ''
  isUserVisible = false
  constructor(data: IModelCancelReasonDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelCancelTransactionDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  accountNumber = ''
  accountHolderName = ''
  bankCode = ''
  bankName = ''
  cancelTransactionStatus = ''
  errorCode = ''
  errorMessage = ''
  cancelableAmount = 0
  cancelAmount = 0
  remainAmount = 0
  message = ''
  canceledAt = null
  transaction: IModelTransactionDefault = {} as IModelTransactionDefault
  responseData = ''
  order: IModelOrderDefault = {} as IModelOrderDefault
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  constructor(data: IModelCancelTransactionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.transaction = DataBase.getModel(this.transaction)
    this.order = DataBase.getModel(this.order)
    this.orderItem = DataBase.getModel(this.orderItem)
  }
}

export class ModelCartItemHistoryDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  quantity = 0
  productOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  user: IModelUserDefault = {} as IModelUserDefault
  productPromotion: IModelProductPromotionDefault = {} as IModelProductPromotionDefault
  constructor(data: IModelCartItemHistoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.productOption = DataBase.getModel(this.productOption)
    this.user = DataBase.getModel(this.user)
    this.productPromotion = DataBase.getModel(this.productPromotion)
  }
}

export class ModelCartItemDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  quantity = 0
  productOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  user: IModelUserDefault = {} as IModelUserDefault
  productPromotion: IModelProductPromotionDefault = {} as IModelProductPromotionDefault
  constructor(data: IModelCartItemDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.productOption = DataBase.getModel(this.productOption)
    this.user = DataBase.getModel(this.user)
    this.productPromotion = DataBase.getModel(this.productPromotion)
  }
}

export class ModelCategoryDefault extends ModelBase {
  displayOrder = 0
  parentCategory: IModelCategoryDefault = {} as IModelCategoryDefault
  name = ''
  code = ''
  text = ''
  slug = ''
  isVisible = false
  description = ''
  listImage = ''
  categoryChildren: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  categoryProductCategories: IModelProductCategoryDefault[] = [] as IModelProductCategoryDefault[]
  products: IModelProductDefault[] = [] as IModelProductDefault[]
  includedCategoriesCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  excludedCategoriesCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  coupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  includedBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  subProductCount = 0
  subCategories: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  constructor(data: IModelCategoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.parentCategory = DataBase.getModel(this.parentCategory)
    this.categoryChildren = DataBase.getListModel(this.categoryChildren)
    this.categoryProductCategories = DataBase.getListModel(this.categoryProductCategories)
    this.products = DataBase.getListModel(this.products)
    this.includedCategoriesCouponGroups = DataBase.getListModel(this.includedCategoriesCouponGroups)
    this.excludedCategoriesCouponGroups = DataBase.getListModel(this.excludedCategoriesCouponGroups)
    this.coupons = DataBase.getListModel(this.coupons)
    this.includedBrands = DataBase.getListModel(this.includedBrands)
    this.subCategories = DataBase.getListModel(this.subCategories)
  }
}

export class ModelCheckTokenAssociationDefault extends ModelBase {
  text = ''
  tokenType = ''
  constructor(data: IModelCheckTokenAssociationDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelCouponByProductDefault extends ModelBase {
  coupon: IModelCouponDefault = {} as IModelCouponDefault
  products: IModelProductDefault[] = [] as IModelProductDefault[]
  constructor(data: IModelCouponByProductDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.coupon = DataBase.getModel(this.coupon)
    this.products = DataBase.getListModel(this.products)
  }
}

export class ModelCouponGroupDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  couponGroupStatus = ''
  eventType = ''
  couponType = ''
  name = ''
  description = ''
  canConjunctUse = false
  hasEventSms = false
  ad: IModelAdDefault = {} as IModelAdDefault
  issueType = ''
  issueCount = 0
  maxIssueCount = 0
  maxDuplicateIssueCount = 0
  issueStartedAt = null
  issueEndedAt = null
  hasDuplicateIssueFirst = false
  usingPeriod = 0
  usingStartedAt = null
  usingEndedAt = null
  expiredAlertDay = 0
  discountType = ''
  discountRate = 0
  discountAmount = 0
  minOrderAmount = 0
  maxDiscountAmount = 0
  coverageType = ''
  coverageOsType = ''
  includedBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  includedProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  includedCategories: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  excludedBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  excludedProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  excludedCategories: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  code = ''
  informkakaoqueue: IModelInformKakaoQueueDefault = {} as IModelInformKakaoQueueDefault
  couponGroupCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  couponGroupCustomerGrades: IModelCustomerGradeDefault[] = [] as IModelCustomerGradeDefault[]
  issueStatus = ''
  eventTypeDisplay = ''
  isDeletable = false
  hasMaxIssueCount = false
  hasUsingPeriod = false
  hasExpiredAlert = false
  hasMinOrderAmount = false
  coverageExcludeType = ''
  informKakaoQueue: IModelInformKakaoQueueDefault = {} as IModelInformKakaoQueueDefault
  canIssue = false
  constructor(data: IModelCouponGroupDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.ad = DataBase.getModel(this.ad)
    this.includedBrands = DataBase.getListModel(this.includedBrands)
    this.includedProducts = DataBase.getListModel(this.includedProducts)
    this.includedCategories = DataBase.getListModel(this.includedCategories)
    this.excludedBrands = DataBase.getListModel(this.excludedBrands)
    this.excludedProducts = DataBase.getListModel(this.excludedProducts)
    this.excludedCategories = DataBase.getListModel(this.excludedCategories)
    this.informkakaoqueue = DataBase.getModel(this.informkakaoqueue)
    this.couponGroupCoupons = DataBase.getListModel(this.couponGroupCoupons)
    this.couponGroupCustomerGrades = DataBase.getListModel(this.couponGroupCustomerGrades)
    this.informKakaoQueue = DataBase.getModel(this.informKakaoQueue)
  }
}

export class ModelCouponKakaoTemplateDefault extends ModelBase {
  name = ''
  title = ''
  message = ''
  parameters = ''
  constructor(data: IModelCouponKakaoTemplateDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelCouponDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  couponGroup: IModelCouponGroupDefault = {} as IModelCouponGroupDefault
  code = ''
  user: IModelUserDefault = {} as IModelUserDefault
  registeredAt = null
  startedAt = null
  expiredAt = null
  usedAt = null
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  message = ''
  order: IModelOrderDefault = {} as IModelOrderDefault
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  usedOrderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  usedStatus = ''
  issuedFrom = ''
  issueCount = 0
  constructor(data: IModelCouponDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.couponGroup = DataBase.getModel(this.couponGroup)
    this.user = DataBase.getModel(this.user)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.order = DataBase.getModel(this.order)
    this.orderItem = DataBase.getModel(this.orderItem)
    this.shipping = DataBase.getModel(this.shipping)
    this.usedOrderItem = DataBase.getModel(this.usedOrderItem)
  }
}

export class ModelCoverflowBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  kindText = ''
  coverflows: IModelCoverflowDefault[] = [] as IModelCoverflowDefault[]
  constructor(data: IModelCoverflowBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.coverflows = DataBase.getListModel(this.coverflows)
  }
}

export class ModelCoverflowDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  pcTitle = ''
  pcSubtitle = ''
  pcDescription = ''
  pcProductNameText = ''
  pcBottomText = ''
  mobileTitle = ''
  mobileSubtitle = ''
  mobileDescription = ''
  mobileProductNameText = ''
  mobileBottomText = ''
  pcImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  mobileImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  constructor(data: IModelCoverflowDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.pcImage = DataBase.getModel(this.pcImage)
    this.mobileImage = DataBase.getModel(this.mobileImage)
  }
}

export class ModelCustomerAddressDefault extends ModelBase {
  postNumber = ''
  basicAddress = ''
  detailedAddress = ''
  name = ''
  mobileNumber = ''
  message = ''
  order: IModelOrderDefault = {} as IModelOrderDefault
  returninfo: IModelReturnInfoDefault = {} as IModelReturnInfoDefault
  constructor(data: IModelCustomerAddressDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.order = DataBase.getModel(this.order)
    this.returninfo = DataBase.getModel(this.returninfo)
  }
}

export class ModelCustomerGradeDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  series = 0
  name = ''
  minOrderAmount = 0
  maxOrderAmount = 0
  minOrderCount = 0
  maxOrderCount = 0
  rewardRate = 0
  couponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  isActivated = false
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  gradeType = ''
  code = ''
  gradeUsers: IModelUserDefault[] = [] as IModelUserDefault[]
  userCount = 0
  conditionText = ''
  pushUserCount = 0
  pushMarketingUserCount = 0
  constructor(data: IModelCustomerGradeDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.couponGroups = DataBase.getListModel(this.couponGroups)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.gradeUsers = DataBase.getListModel(this.gradeUsers)
  }
}

export class ModelCustomerTemplateAddressDefault extends ModelBase {
  postNumber = ''
  basicAddress = ''
  detailedAddress = ''
  isDefault = false
  templateName = ''
  name = ''
  mobileNumber = ''
  user: IModelUserDefault = {} as IModelUserDefault
  message = ''
  constructor(data: IModelCustomerTemplateAddressDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
  }
}

export class ModelDashboardBillingDefault extends ModelBase {
  totalSellerAmount = 0
  constructor(data: IModelDashboardBillingDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelDashboardBoardDefault extends ModelBase {
  productQnaCount = 0
  orderQnaCount = 0
  serviceQnaCount = 0
  reviewCount = 0
  constructor(data: IModelDashboardBoardDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelDashboardNoticeDefault extends ModelBase {
  partnerNotices: IModelNoticeDefault[] = [] as IModelNoticeDefault[]
  customerNotices: IModelNoticeDefault[] = [] as IModelNoticeDefault[]
  partnerImportantNotice: IModelNoticeDefault = {} as IModelNoticeDefault
  constructor(data: IModelDashboardNoticeDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.partnerNotices = DataBase.getListModel(this.partnerNotices)
    this.customerNotices = DataBase.getListModel(this.customerNotices)
    this.partnerImportantNotice = DataBase.getModel(this.partnerImportantNotice)
  }
}

export class ModelDashboardOrderTimeseriesDefault extends ModelBase {
  label = ''
  data: IModelTimeseriesValueDefault[] = [] as IModelTimeseriesValueDefault[]
  constructor(data: IModelDashboardOrderTimeseriesDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.data = DataBase.getListModel(this.data)
  }
}

export class ModelDashboardOrderDefault extends ModelBase {
  unconfirmedCount = 0
  newOrderCount = 0
  readyForShippingCount = 0
  shippingCount = 0
  shippedCount = 0
  cancelRequestedCount = 0
  returnRequestedCount = 0
  confirmedCount = 0
  timeseries: IModelDashboardOrderTimeseriesDefault[] = [] as IModelDashboardOrderTimeseriesDefault[]
  constructor(data: IModelDashboardOrderDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.timeseries = DataBase.getListModel(this.timeseries)
  }
}

export class ModelDashboardProductDefault extends ModelBase {
  sellerCount = 0
  brandCount = 0
  productCount = 0
  constructor(data: IModelDashboardProductDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelDashboardDefault extends ModelBase {
  dashboardOrder: IModelDashboardOrderDefault = {} as IModelDashboardOrderDefault
  dashboardBoard: IModelDashboardBoardDefault = {} as IModelDashboardBoardDefault
  dashboardProduct: IModelDashboardProductDefault = {} as IModelDashboardProductDefault
  dashboardNotice: IModelDashboardNoticeDefault = {} as IModelDashboardNoticeDefault
  dashboardUser: IModelDashboardUserDefault = {} as IModelDashboardUserDefault
  dashboardBilling: IModelDashboardBillingDefault = {} as IModelDashboardBillingDefault
  constructor(data: IModelDashboardDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.dashboardOrder = DataBase.getModel(this.dashboardOrder)
    this.dashboardBoard = DataBase.getModel(this.dashboardBoard)
    this.dashboardProduct = DataBase.getModel(this.dashboardProduct)
    this.dashboardNotice = DataBase.getModel(this.dashboardNotice)
    this.dashboardUser = DataBase.getModel(this.dashboardUser)
    this.dashboardBilling = DataBase.getModel(this.dashboardBilling)
  }
}

export class ModelDashboardUserDefault extends ModelBase {
  wishedProductCount = 0
  wishedBrandCount = 0
  constructor(data: IModelDashboardUserDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelDefaultReturnChangeGuideDefault extends ModelBase {
  value = ''
  constructor(data: IModelDefaultReturnChangeGuideDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelDesignersBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  brandText = ''
  images: IModelBannerImageDefault[] = [] as IModelBannerImageDefault[]
  constructor(data: IModelDesignersBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.images = DataBase.getListModel(this.images)
  }
}

export class ModelDeviceDefault extends ModelBase {
  user: IModelUserDefault = {} as IModelUserDefault
  uuid = ''
  token = ''
  refreshToken = ''
  pushToken = ''
  platformType = ''
  osVersion = ''
  modelNumber = ''
  appVersion = ''
  lastLoggedAt = null
  keepSignIn = false
  socialService: IModelSocialServiceDefault = {} as IModelSocialServiceDefault
  deviceOrders: IModelOrderDefault[] = [] as IModelOrderDefault[]
  expiredAt = null
  constructor(data: IModelDeviceDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
    this.socialService = DataBase.getModel(this.socialService)
    this.deviceOrders = DataBase.getListModel(this.deviceOrders)
  }
}

export class ModelDisplayCollectionDefault extends ModelBase {
  displayOrder = 0
  startedAt = null
  endedAt = null
  petType = ''
  createdAt = null
  updatedAt = null
  name = ''
  ad: IModelAdDefault = {} as IModelAdDefault
  collectionType = ''
  isShown = false
  displayCollectionDisplayProductInfos: IModelDisplayProductInfoDefault[] = [] as IModelDisplayProductInfoDefault[]
  displayProductInfos: IModelDisplayProductInfoDefault[] = [] as IModelDisplayProductInfoDefault[]
  description: string = ''
  backgroundImage: File | undefined = undefined
  constructor(data: IModelDisplayCollectionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.ad = DataBase.getModel(this.ad)
    this.displayCollectionDisplayProductInfos = DataBase.getListModel(this.displayCollectionDisplayProductInfos)
    this.displayProductInfos = DataBase.getListModel(this.displayProductInfos)
    this.backgroundImage = data.backgroundImage
    this.description = data.description
  }
}

export class ModelDisplayProductInfoDefault extends ModelBase {
  displayOrder = 0
  startedAt = null
  endedAt = null
  product: IModelProductDefault = {} as IModelProductDefault
  displayCollection: IModelDisplayCollectionDefault = {} as IModelDisplayCollectionDefault
  productpromotion: IModelProductPromotionDefault = {} as IModelProductPromotionDefault
  constructor(data: IModelDisplayProductInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.displayCollection = DataBase.getModel(this.displayCollection)
    this.productpromotion = DataBase.getModel(this.productpromotion)
  }
}

export class ModelExcelExportRequestDefault extends ModelBase {
  requestName = ''
  requestUserType = ''
  requestUser: IModelUserDefault = {} as IModelUserDefault
  requestSeller: IModelSellerDefault = {} as IModelSellerDefault
  createdAt = null
  completedAt = null
  excelFile = ''
  status = ''
  constructor(data: IModelExcelExportRequestDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.requestUser = DataBase.getModel(this.requestUser)
    this.requestSeller = DataBase.getModel(this.requestSeller)
  }
}

export class ModelFaqDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  title = ''
  body = ''
  category = ''
  receiverType = ''
  user: IModelUserDefault = {} as IModelUserDefault
  constructor(data: IModelFaqDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
  }
}

export class ModelFitpetLinkDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  fullUrl = ''
  shortenUrl = ''
  clicks = 0
  constructor(data: IModelFitpetLinkDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelHomeSectionDefault extends ModelBase {
  name = ''
  homeSectionType = ''
  isShown = false
  bannerType = ''
  banners = ''
  displayCollection = ''
  weeklyBestCollections = ''
  brands = ''
  constructor(data: IModelHomeSectionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelHomeDefault extends ModelBase {
  topSlimBanners = ''
  topRollBanners = ''
  homeMiddleBanners = ''
  homeBottomBanners = ''
  brands = ''
  displayCollections = ''
  constructor(data: IModelHomeDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelInactiveUserDefault extends ModelBase {
  accountNumber = ''
  accountHolderName = ''
  bankCode = ''
  bankName = ''
  user: IModelUserDefault = {} as IModelUserDefault
  username = ''
  email = ''
  mobileNumber = ''
  name = ''
  agreeNightMarketing = false
  agreeMarketing = false
  inactiveType = ''
  constructor(data: IModelInactiveUserDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
  }
}

export class ModelInfluencerAddressDefault extends ModelBase {
  postNumber = ''
  basicAddress = ''
  detailedAddress = ''
  influencer: IModelInfluencerDefault = {} as IModelInfluencerDefault
  constructor(data: IModelInfluencerAddressDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.influencer = DataBase.getModel(this.influencer)
  }
}

export class ModelInfluencerStatisticColumnDefault extends ModelBase {
  totalAmount = 0
  totalSoldAmount = 0
  totalCouponDiscountAmount = 0
  totalMileageDiscountAmount = 0
  totalCustomerCount = 0
  totalOrderCount = 0
  totalSoldCount = 0
  guestUnitPrice = 0
  avgProductPrice = 0
  influencerName = ''
  constructor(data: IModelInfluencerStatisticColumnDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelInfluencerStatusCountDefault extends ModelBase {
  totalCount = 0
  requestCount = 0
  approvedCount = 0
  rejectedCount = 0
  suspendCount = 0
  bannedCount = 0
  deletedCount = 0
  constructor(data: IModelInfluencerStatusCountDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelInfluencerDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  accountNumber = ''
  accountHolderName = ''
  bankCode = ''
  bankName = ''
  nickname = ''
  homepageUrl = ''
  youtubeUrl = ''
  instagramUrl = ''
  facebookUrl = ''
  twitterUrl = ''
  twitchUrl = ''
  afreecatvUrl = ''
  blogUrl = ''
  etcUrl = ''
  profileImage = ''
  followerLevel = ''
  address: IModelInfluencerAddressDefault = {} as IModelInfluencerAddressDefault
  status: IModelStatusDefault = {} as IModelStatusDefault
  followingSellers: IModelSellerDefault[] = [] as IModelSellerDefault[]
  wishedProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  registrationNumber = ''
  bankBookImage = ''
  influencerType = ''
  fitHeight = 0
  fitWeight = 0
  fitShoesSize = 0
  requestedAt = null
  approvedAt = null
  updateApprovedAt = null
  user: IModelUserDefault = {} as IModelUserDefault
  sellerSet: IModelSellerDefault[] = [] as IModelSellerDefault[]
  constructor(data: IModelInfluencerDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.address = DataBase.getModel(this.address)
    this.status = DataBase.getModel(this.status)
    this.followingSellers = DataBase.getListModel(this.followingSellers)
    this.wishedProducts = DataBase.getListModel(this.wishedProducts)
    this.user = DataBase.getModel(this.user)
    this.sellerSet = DataBase.getListModel(this.sellerSet)
  }
}

export class ModelInformEmailAttachmentDefault extends ModelBase {
  file = ''
  informEmailQueue: IModelInformEmailQueueDefault = {} as IModelInformEmailQueueDefault
  constructor(data: IModelInformEmailAttachmentDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.informEmailQueue = DataBase.getModel(this.informEmailQueue)
  }
}

export class ModelInformEmailQueueDefault extends ModelBase {
  emails = ''
  subject = ''
  title = ''
  text = ''
  sendAt = null
  templateName = ''
  informEmailQueueInformEmailAttachments: IModelInformEmailAttachmentDefault[] =
    [] as IModelInformEmailAttachmentDefault[]
  constructor(data: IModelInformEmailQueueDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.informEmailQueueInformEmailAttachments = DataBase.getListModel(this.informEmailQueueInformEmailAttachments)
  }
}

export class ModelInformKakaoQueueDefault extends ModelBase {
  template = ''
  data = ''
  sendAt = null
  isSent = false
  sentSuccessCount = 0
  sentFailCount = 0
  couponGroup: IModelCouponGroupDefault = {} as IModelCouponGroupDefault
  constructor(data: IModelInformKakaoQueueDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.couponGroup = DataBase.getModel(this.couponGroup)
  }
}

export class ModelInformPushQueueDefault extends ModelBase {
  push: IModelPushDefault = {} as IModelPushDefault
  users: IModelUserDefault[] = [] as IModelUserDefault[]
  sendAt = null
  pushQueueStatus = ''
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  targetKey = ''
  target = ''
  memo = ''
  targetInfo = ''
  sentSuccessCount = 0
  grades: IModelCustomerGradeDefault[] = [] as IModelCustomerGradeDefault[]
  totalUserCount = 0
  readCount = 0
  totalReadCount = 0
  pushQueueStatusDisplay = ''
  constructor(data: IModelInformPushQueueDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.push = DataBase.getModel(this.push)
    this.users = DataBase.getListModel(this.users)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.grades = DataBase.getListModel(this.grades)
  }
}

export class ModelInformSmsHistoryDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  mobileNumbers = ''
  text = ''
  resultStatus = ''
  resultMessage = ''
  constructor(data: IModelInformSmsHistoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelInformSmsQueueDefault extends ModelBase {
  mobileNumbers = ''
  text = ''
  sendAt = null
  constructor(data: IModelInformSmsQueueDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelInitDataDefault extends ModelBase {
  banks: IModelBankDefault[] = [] as IModelBankDefault[]
  shippingCompanies: IModelShippingCompanyDefault[] = [] as IModelShippingCompanyDefault[]
  customerGrades: IModelCustomerGradeDefault[] = [] as IModelCustomerGradeDefault[]
  mileageRewardPolicy: IModelMileageRewardPolicyDefault = {} as IModelMileageRewardPolicyDefault
  constructor(data: IModelInitDataDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.banks = DataBase.getListModel(this.banks)
    this.shippingCompanies = DataBase.getListModel(this.shippingCompanies)
    this.customerGrades = DataBase.getListModel(this.customerGrades)
    this.mileageRewardPolicy = DataBase.getModel(this.mileageRewardPolicy)
  }
}

export class ModelLeaveReasonDefault extends ModelBase {
  leaveReasons = ''
  constructor(data: IModelLeaveReasonDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelMileageHistoryDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  mileage: IModelMileageDefault = {} as IModelMileageDefault
  mileageType = ''
  order: IModelOrderDefault = {} as IModelOrderDefault
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  review: IModelReviewDefault = {} as IModelReviewDefault
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  returnInfo: IModelReturnInfoDefault = {} as IModelReturnInfoDefault
  message = ''
  subMessage = ''
  beforeMileage = 0
  afterMileage = 0
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  mileageDiff = 0
  expiredAt = null
  constructor(data: IModelMileageHistoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.mileage = DataBase.getModel(this.mileage)
    this.order = DataBase.getModel(this.order)
    this.orderItem = DataBase.getModel(this.orderItem)
    this.review = DataBase.getModel(this.review)
    this.shipping = DataBase.getModel(this.shipping)
    this.returnInfo = DataBase.getModel(this.returnInfo)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
  }
}

export class ModelMileagePolicyDefault extends ModelBase {
  mileageUnusedDay = 0
  mileageMinHoldingAmount = 0
  mileageMinOrderAmount = 0
  mileageMinUsageAmount = 0
  mileageMaxDiscountRate = 0
  constructor(data: IModelMileagePolicyDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelMileageRewardPolicyDefault extends ModelBase {
  reviewRate = 0
  orderConfirmRate = 0
  imageReviewRate = 0
  constructor(data: IModelMileageRewardPolicyDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelMileageDefault extends ModelBase {
  point = 0
  user: IModelUserDefault = {} as IModelUserDefault
  mileageMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  constructor(data: IModelMileageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
    this.mileageMileageHistories = DataBase.getListModel(this.mileageMileageHistories)
  }
}

export class ModelMinAppVersionDefault extends ModelBase {
  minAppVersion = ''
  normalAppVersion = ''
  latestAppVersion = ''
  platform = ''
  constructor(data: IModelMinAppVersionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelNoticeDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  title = ''
  body = ''
  isImportant = false
  receiverType = ''
  user: IModelUserDefault = {} as IModelUserDefault
  kind = ''
  attachment = ''
  constructor(data: IModelNoticeDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
  }
}

export class ModelOrderItemAdminNoteDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  note = ''
  constructor(data: IModelOrderItemAdminNoteDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.orderItem = DataBase.getModel(this.orderItem)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
  }
}

export class ModelOrderItemCancelReasonDefault extends ModelBase {
  cancelReasons: IModelCancelReasonDefault[] = [] as IModelCancelReasonDefault[]
  constructor(data: IModelOrderItemCancelReasonDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.cancelReasons = DataBase.getListModel(this.cancelReasons)
  }
}

export class ModelOrderItemCountDefault extends ModelBase {
  unconfirmedCount = 0
  newOrderCount = 0
  readyForShippingCount = 0
  shippingCount = 0
  shippedCount = 0
  cancelRequestedCount = 0
  canceledCount = 0
  confirmedCount = 0
  returnCount = 0
  returnRequestedCount = 0
  returnUnderReturnCount = 0
  returnReturnedCount = 0
  returnConfirmedCount = 0
  exchangeRequestedCount = 0
  exchangeUnderReturnCount = 0
  exchangeConfirmedCount = 0
  constructor(data: IModelOrderItemCountDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelOrderItemReturnReasonDefault extends ModelBase {
  returnReasons: IModelReturnReasonDefault[] = [] as IModelReturnReasonDefault[]
  constructor(data: IModelOrderItemReturnReasonDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.returnReasons = DataBase.getListModel(this.returnReasons)
  }
}

export class ModelOrderItemDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  quantity = 0
  originalPrice = 0
  discountPrice = 0
  productPromotionDiscountPrice = 0
  couponDiscountPrice = 0
  orderCouponDiscountPrice = 0
  mileageDiscountPrice = 0
  fitpetFeeRate = 0
  mileageRewardRate = 0
  orderItemNumber = ''
  productName = ''
  orderStatus = ''
  order: IModelOrderDefault = {} as IModelOrderDefault
  seller: IModelSellerDefault = {} as IModelSellerDefault
  brand: IModelBrandDefault = {} as IModelBrandDefault
  productOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  productPromotion: IModelProductPromotionDefault = {} as IModelProductPromotionDefault
  exchangedOrderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  couponInfo = ''
  checkedAt = null
  sentAt = null
  receivedAt = null
  confirmedAt = null
  cancelResponsibility = ''
  cancelUser = ''
  cancelRequestType = ''
  cancelRequestMessage = ''
  cancelRejectMessage = ''
  cancelGuideMessage = ''
  cancelRequestedAt = null
  cancelRejectedAt = null
  canceledAt = null
  refundInfo: IModelRefundInfoDefault = {} as IModelRefundInfoDefault
  refundShippingInfo: IModelRefundShippingInfoDefault = {} as IModelRefundShippingInfoDefault
  returnInfo: IModelReturnInfoDefault = {} as IModelReturnInfoDefault
  orderItemServiceQnas: IModelServiceQnaDefault[] = [] as IModelServiceQnaDefault[]
  orderItemReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  orderItemMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  orderItemCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  orderItemOrderItemAdminNotes: IModelOrderItemAdminNoteDefault[] = [] as IModelOrderItemAdminNoteDefault[]
  orderitem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  canceltransaction: IModelCancelTransactionDefault = {} as IModelCancelTransactionDefault
  billingitemSet: IModelBillingItemDefault[] = [] as IModelBillingItemDefault[]
  productImage: IModelProductImageDefault = {} as IModelProductImageDefault
  refundAmount = 0
  customerPrice = 0
  originalAmount = 0
  productDiscountPrice = 0
  productDiscountAmount = 0
  customerAmount = 0
  orderStatusText = ''
  productPromotionCustomerPrice = 0
  productPromotionCustomerAmount = 0
  productPromotionCouponCustomerAmount = 0
  productPromotionCustomerPriceExists = 0
  productPromotionCustomerAmountExists = 0
  amountWithoutOrderCoupon = 0
  amount = 0
  returnRequestedAt = null
  returnAcceptedAt = null
  returnReturnedAt = null
  returnConfirmedAt = null
  returnRejectedAt = null
  returnRejectMessage = ''
  returnRequestMessage = ''
  returnAddress: IModelSellerAddressDefault = {} as IModelSellerAddressDefault
  returnResponsibility = ''
  returnGuideMessage = ''
  mileage = 0
  reviewMileage = 0
  imageReviewMileage = 0
  orderConfirmMileage = 0
  returnShippingFee = 0
  exchangeShippingFee = 0
  hasUserReview = false
  vbankName = ''
  vbankAccountHolderName = ''
  vbankAccountNumber = ''
  approvedTransaction: IModelTransactionDefault = {} as IModelTransactionDefault
  approvedCancelTransaction: IModelCancelTransactionDefault = {} as IModelCancelTransactionDefault
  adminNotes: IModelOrderItemAdminNoteDefault[] = [] as IModelOrderItemAdminNoteDefault[]
  availableCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  refundable = false
  reviewWritableDatetime = null
  canPartialCancel = false
  constructor(data: IModelOrderItemDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.order = DataBase.getModel(this.order)
    this.seller = DataBase.getModel(this.seller)
    this.brand = DataBase.getModel(this.brand)
    this.productOption = DataBase.getModel(this.productOption)
    this.shipping = DataBase.getModel(this.shipping)
    this.productPromotion = DataBase.getModel(this.productPromotion)
    this.exchangedOrderItem = DataBase.getModel(this.exchangedOrderItem)
    this.refundInfo = DataBase.getModel(this.refundInfo)
    this.refundShippingInfo = DataBase.getModel(this.refundShippingInfo)
    this.returnInfo = DataBase.getModel(this.returnInfo)
    this.orderItemServiceQnas = DataBase.getListModel(this.orderItemServiceQnas)
    this.orderItemReviews = DataBase.getListModel(this.orderItemReviews)
    this.orderItemMileageHistories = DataBase.getListModel(this.orderItemMileageHistories)
    this.orderItemCoupons = DataBase.getListModel(this.orderItemCoupons)
    this.orderItemOrderItemAdminNotes = DataBase.getListModel(this.orderItemOrderItemAdminNotes)
    this.orderitem = DataBase.getModel(this.orderitem)
    this.canceltransaction = DataBase.getModel(this.canceltransaction)
    this.billingitemSet = DataBase.getListModel(this.billingitemSet)
    this.productImage = DataBase.getModel(this.productImage)
    this.returnAddress = DataBase.getModel(this.returnAddress)
    this.approvedTransaction = DataBase.getModel(this.approvedTransaction)
    this.approvedCancelTransaction = DataBase.getModel(this.approvedCancelTransaction)
    this.adminNotes = DataBase.getListModel(this.adminNotes)
    this.availableCoupons = DataBase.getListModel(this.availableCoupons)
  }
}

export class ModelOrderRefundInfoDefault extends ModelBase {
  originalAmount = 0
  productDiscountAmount = 0
  productPromotionDiscountAmount = 0
  couponDiscountAmount = 0
  mileageDiscountAmount = 0
  orderCouponDiscountAmount = 0
  soldAmount = 0
  totalRefundAmount = 0
  returnShippingFeeAmount = 0
  refundShippingFeeAmount = 0
  constructor(data: IModelOrderRefundInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelOrderDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  orderNumber = ''
  user: IModelUserDefault = {} as IModelUserDefault
  device: IModelDeviceDefault = {} as IModelDeviceDefault
  customerAddress: IModelCustomerAddressDefault = {} as IModelCustomerAddressDefault
  approvedTransaction: IModelTransactionDefault = {} as IModelTransactionDefault
  originalAmount = 0
  productDiscountAmount = 0
  productPromotionDiscountAmount = 0
  couponDiscountAmount = 0
  orderCouponDiscountAmount = 0
  mileageDiscountAmount = 0
  shippingFees = 0
  shippingExtraFees = 0
  shippingDiscountAmount = 0
  shippingMileageDiscountAmount = 0
  shippingCouponDiscountAmount = 0
  couponInfo = ''
  orderMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  orderCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  orderOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  orderTransactions: IModelTransactionDefault[] = [] as IModelTransactionDefault[]
  orderCancelTransactions: IModelCancelTransactionDefault[] = [] as IModelCancelTransactionDefault[]
  productPromotionCustomerAmount = 0
  amount = 0
  shippingAmount = 0
  mileage = 0
  imageReviewMileage = 0
  reviewMileage = 0
  orderConfirmMileage = 0
  orderRefundInfo: IModelOrderRefundInfoDefault = {} as IModelOrderRefundInfoDefault
  shippings: IModelShippingDefault[] = [] as IModelShippingDefault[]
  totalItemQuantity = 0
  availableCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  canPartialCancel = false
  constructor(data: IModelOrderDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
    this.device = DataBase.getModel(this.device)
    this.customerAddress = DataBase.getModel(this.customerAddress)
    this.approvedTransaction = DataBase.getModel(this.approvedTransaction)
    this.orderMileageHistories = DataBase.getListModel(this.orderMileageHistories)
    this.orderCoupons = DataBase.getListModel(this.orderCoupons)
    this.orderOrderItems = DataBase.getListModel(this.orderOrderItems)
    this.orderTransactions = DataBase.getListModel(this.orderTransactions)
    this.orderCancelTransactions = DataBase.getListModel(this.orderCancelTransactions)
    this.orderRefundInfo = DataBase.getModel(this.orderRefundInfo)
    this.shippings = DataBase.getListModel(this.shippings)
    this.availableCoupons = DataBase.getListModel(this.availableCoupons)
  }
}

export class ModelPopularSearchTermDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  series = 0
  term = ''
  constructor(data: IModelPopularSearchTermDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelPopupBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  pcImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  mobileImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  mobileTitle = ''
  mobileSubtitle = ''
  constructor(data: IModelPopupBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.pcImage = DataBase.getModel(this.pcImage)
    this.mobileImage = DataBase.getModel(this.mobileImage)
  }
}

export class ModelProductCategoryDefault extends ModelBase {
  category: IModelCategoryDefault = {} as IModelCategoryDefault
  product: IModelProductDefault = {} as IModelProductDefault
  constructor(data: IModelProductCategoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.category = DataBase.getModel(this.category)
    this.product = DataBase.getModel(this.product)
  }
}

export class ModelProductImageDefault extends ModelBase {
  image = ''
  product: IModelProductDefault = {} as IModelProductDefault
  displayType = ''
  thumbnail = ''
  constructor(data: IModelProductImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
  }
}

export class ModelProductOptionGroupDefault extends ModelBase {
  name = ''
  isCustomGroup = false
  productOptionGroupValues: IModelProductOptionValueDefault[] = [] as IModelProductOptionValueDefault[]
  realName = ''
  isShownProductName = false
  constructor(data: IModelProductOptionGroupDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.productOptionGroupValues = DataBase.getListModel(this.productOptionGroupValues)
  }
}

export class ModelProductOptionKindDefault extends ModelBase {
  key = ''
  value = ''
  constructor(data: IModelProductOptionKindDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelProductOptionSkuDefault extends ModelBase {
  productOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  sku: IModelSkuDefault = {} as IModelSkuDefault
  count = 0
  constructor(data: IModelProductOptionSkuDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.productOption = DataBase.getModel(this.productOption)
    this.sku = DataBase.getModel(this.sku)
  }
}

export class ModelProductOptionDefault extends ModelBase {
  product: IModelProductDefault = {} as IModelProductDefault
  productOptionValues: IModelProductOptionValueDefault[] = [] as IModelProductOptionValueDefault[]
  series = 0
  price = 0
  discountPrice = 0
  isDefault = false
  isUse = false
  isDelete = false
  skus: IModelSkuDefault[] = [] as IModelSkuDefault[]
  name = ''
  productOptionReviews: IModelProductQnaDefault[] = [] as IModelProductQnaDefault[]
  relatedProduct: IModelProductDefault = {} as IModelProductDefault
  productOptionProductOptionSkus: IModelProductOptionSkuDefault[] = [] as IModelProductOptionSkuDefault[]
  productOptionOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  productOptionCartItems: IModelCartItemDefault[] = [] as IModelCartItemDefault[]
  productOptionCartItemHistories: IModelCartItemHistoryDefault[] = [] as IModelCartItemHistoryDefault[]
  customerPrice = 0
  difference = 0
  productPromotionCustomerPrice = 0
  productPromotionDiscountPrice = 0
  productOptionPrice = 0
  productOptionKinds: IModelProductOptionKindDefault[] = [] as IModelProductOptionKindDefault[]
  stockQuantity = 0
  purchasePrice = 0
  productOptionSkus: IModelProductOptionSkuDefault[] = [] as IModelProductOptionSkuDefault[]
  productId = 0
  constructor(data: IModelProductOptionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.productOptionValues = DataBase.getListModel(this.productOptionValues)
    this.skus = DataBase.getListModel(this.skus)
    this.productOptionReviews = DataBase.getListModel(this.productOptionReviews)
    this.relatedProduct = DataBase.getModel(this.relatedProduct)
    this.productOptionProductOptionSkus = DataBase.getListModel(this.productOptionProductOptionSkus)
    this.productOptionOrderItems = DataBase.getListModel(this.productOptionOrderItems)
    this.productOptionCartItems = DataBase.getListModel(this.productOptionCartItems)
    this.productOptionCartItemHistories = DataBase.getListModel(this.productOptionCartItemHistories)
    this.productOptionKinds = DataBase.getListModel(this.productOptionKinds)
    this.productOptionSkus = DataBase.getListModel(this.productOptionSkus)
  }
}

export class ModelProductOptionValueDefault extends ModelBase {
  name = ''
  productOptionGroup: IModelProductOptionGroupDefault = {} as IModelProductOptionGroupDefault
  productOptions: IModelProductOptionDefault[] = [] as IModelProductOptionDefault[]
  constructor(data: IModelProductOptionValueDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.productOptionGroup = DataBase.getModel(this.productOptionGroup)
    this.productOptions = DataBase.getListModel(this.productOptions)
  }
}

export class ModelProductPromotionDefault extends ModelBase {
  startedAt = null
  endedAt = null
  createdAt = null
  updatedAt = null
  product: IModelProductDefault = {} as IModelProductDefault
  displayProductInfo: IModelDisplayProductInfoDefault = {} as IModelDisplayProductInfoDefault
  productPromotionType = ''
  name = ''
  description = ''
  discountPrice = 0
  fitpetFeeRate = 0
  mileageRewardRate = 0
  isDelete = false
  minOrderAmount = 0
  maxOrderCount = 0
  productPromotionOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  productPromotionCartItems: IModelCartItemDefault[] = [] as IModelCartItemDefault[]
  productPromotionCartItemHistories: IModelCartItemHistoryDefault[] = [] as IModelCartItemHistoryDefault[]
  productPromotionCustomerPrice = 0
  discountRate = 0
  defaultFitpetFeeRate = 0
  boughtCount = 0
  constructor(data: IModelProductPromotionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.displayProductInfo = DataBase.getModel(this.displayProductInfo)
    this.productPromotionOrderItems = DataBase.getListModel(this.productPromotionOrderItems)
    this.productPromotionCartItems = DataBase.getListModel(this.productPromotionCartItems)
    this.productPromotionCartItemHistories = DataBase.getListModel(this.productPromotionCartItemHistories)
  }
}

export class ModelProductQnaDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  questionBody = ''
  answerBody = ''
  answeredAt = null
  product: IModelProductDefault = {} as IModelProductDefault
  productOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  isSecret = false
  user: IModelUserDefault = {} as IModelUserDefault
  answeredUser: IModelUserDefault = {} as IModelUserDefault
  answeredUserType = ''
  zendeskTicketId = 0
  isAnswered = false
  isAdmin = false
  seller: IModelSellerDefault = {} as IModelSellerDefault
  username = ''
  constructor(data: IModelProductQnaDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.productOption = DataBase.getModel(this.productOption)
    this.user = DataBase.getModel(this.user)
    this.answeredUser = DataBase.getModel(this.answeredUser)
    this.seller = DataBase.getModel(this.seller)
  }
}

export class ModelProductStatisticByInfluencerColumnDefault extends ModelBase {
  influencerName = ''
  influencerFeeRate = 0
  influencerAmount = 0
  brandName = ''
  thumbnail = ''
  productName = ''
  productId = ''
  totalCustomerCount = 0
  totalSoldCount = 0
  totalSoldAmount = 0
  constructor(data: IModelProductStatisticByInfluencerColumnDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelProductStatisticBySellerColumnDefault extends ModelBase {
  sellerName = ''
  fitpetFeeRate = 0
  fitpetAmount = 0
  brandName = ''
  thumbnail = ''
  productName = ''
  productId = ''
  totalCustomerCount = 0
  totalSoldCount = 0
  totalSoldAmount = 0
  constructor(data: IModelProductStatisticBySellerColumnDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelProductStatusCountDefault extends ModelBase {
  updateRequestCount = 0
  updateRejectedCount = 0
  totalCount = 0
  requestCount = 0
  rejectedCount = 0
  sellingCount = 0
  preSellingCount = 0
  soldOutCount = 0
  suspendCount = 0
  endSellingCount = 0
  bannedCount = 0
  deletedCount = 0
  constructor(data: IModelProductStatusCountDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelProductDefault extends ModelBase {
  displayOrder = 0
  startedAt = null
  endedAt = null
  petType = ''
  createdAt = null
  updatedAt = null
  isShownNaver = false
  isShownDaum = false
  isShownFacebook = false
  isShownGoogle = false
  isShown = false
  isSoldOut = false
  isStockSoldOut = false
  name = ''
  productNumber = ''
  supplyType = ''
  description = ''
  correspondent = ''
  fitpetFeeRate = 0
  originalProduct: IModelProductDefault = {} as IModelProductDefault
  reviewGroup: IModelReviewGroupDefault = {} as IModelReviewGroupDefault
  asInfo: IModelAsInfoDefault = {} as IModelAsInfoDefault
  categories: IModelCategoryDefault[] = [] as IModelCategoryDefault[]
  seller: IModelSellerDefault = {} as IModelSellerDefault
  brand: IModelBrandDefault = {} as IModelBrandDefault
  weight = 0
  point = 0
  searchKeywords = ''
  status: IModelStatusDefault = {} as IModelStatusDefault
  updateInfo: IModelUpdatedProductDefault = {} as IModelUpdatedProductDefault
  updateStatus: IModelStatusDefault = {} as IModelStatusDefault
  productOptionType = ''
  defaultProductOption: IModelProductOptionDefault = {} as IModelProductOptionDefault
  shippingPreset: IModelShippingPresetDefault = {} as IModelShippingPresetDefault
  shippingGuide = ''
  shippingNotice = ''
  requestedAt = null
  approvedAt = null
  updateApprovedAt = null
  deletedAt = null
  wishedProductUsers: IModelUserDefault[] = [] as IModelUserDefault[]
  productQnas: IModelProductQnaDefault[] = [] as IModelProductQnaDefault[]
  productReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  productProductCategories: IModelProductCategoryDefault[] = [] as IModelProductCategoryDefault[]
  copiedProduct: IModelProductDefault[] = [] as IModelProductDefault[]
  productProductImages: IModelProductImageDefault[] = [] as IModelProductImageDefault[]
  productProductOptions: IModelProductOptionDefault[] = [] as IModelProductOptionDefault[]
  productInfluencers: IModelInfluencerDefault[] = [] as IModelInfluencerDefault[]
  includedProductsCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  excludedProductsCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  displayproductinfoSet: IModelDisplayProductInfoDefault[] = [] as IModelDisplayProductInfoDefault[]
  productProductPromotions: IModelProductPromotionDefault[] = [] as IModelProductPromotionDefault[]
  realName = ''
  reviewCount = 0
  reviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  averageReviewScore = 0
  stockQuantity = 0
  price = 0
  customerPrice = 0
  productPromotion: IModelProductPromotionDefault = {} as IModelProductPromotionDefault
  productPromotionStartedAt = null
  productPromotionEndedAt = null
  productPromotionCustomerPrice = 0
  productPromotionDiscountPrice = 0
  productPromotionDiscountRate = 0
  finalDiscountPrice = 0
  finalDiscountRate = 0
  discountRate = 0
  discountPrice = 0
  canDownloadCoupon = false
  downloadCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  couponDiscountPrice = 0
  couponDiscountRate = 0
  soldQuantity = 0
  purchasePrice = 0
  productCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  brandCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  categoryCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  wishedCount = 0
  mainImage: IModelProductImageDefault = {} as IModelProductImageDefault
  isWished = false
  isTimesale = false
  mileage = 0
  imageReviewMileage = 0
  reviewMileage = 0
  orderConfirmMileage = 0
  isRealSoldOut = false
  isSingleOption = false
  constructor(data: IModelProductDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.originalProduct = DataBase.getModel(this.originalProduct)
    this.reviewGroup = DataBase.getModel(this.reviewGroup)
    this.asInfo = DataBase.getModel(this.asInfo)
    this.categories = DataBase.getListModel(this.categories)
    this.seller = DataBase.getModel(this.seller)
    this.brand = DataBase.getModel(this.brand)
    this.status = DataBase.getModel(this.status)
    this.updateInfo = DataBase.getModel(this.updateInfo)
    this.updateStatus = DataBase.getModel(this.updateStatus)
    this.defaultProductOption = DataBase.getModel(this.defaultProductOption)
    this.shippingPreset = DataBase.getModel(this.shippingPreset)
    this.wishedProductUsers = DataBase.getListModel(this.wishedProductUsers)
    this.productQnas = DataBase.getListModel(this.productQnas)
    this.productReviews = DataBase.getListModel(this.productReviews)
    this.productProductCategories = DataBase.getListModel(this.productProductCategories)
    this.copiedProduct = DataBase.getListModel(this.copiedProduct)
    this.productProductImages = DataBase.getListModel(this.productProductImages)
    this.productProductOptions = DataBase.getListModel(this.productProductOptions)
    this.productInfluencers = DataBase.getListModel(this.productInfluencers)
    this.includedProductsCouponGroups = DataBase.getListModel(this.includedProductsCouponGroups)
    this.excludedProductsCouponGroups = DataBase.getListModel(this.excludedProductsCouponGroups)
    this.displayproductinfoSet = DataBase.getListModel(this.displayproductinfoSet)
    this.productProductPromotions = DataBase.getListModel(this.productProductPromotions)
    this.reviews = DataBase.getListModel(this.reviews)
    this.productPromotion = DataBase.getModel(this.productPromotion)
    this.downloadCouponGroups = DataBase.getListModel(this.downloadCouponGroups)
    this.productCoupons = DataBase.getListModel(this.productCoupons)
    this.brandCoupons = DataBase.getListModel(this.brandCoupons)
    this.categoryCoupons = DataBase.getListModel(this.categoryCoupons)
    this.mainImage = DataBase.getModel(this.mainImage)
  }
}

export class ModelPushDefault extends ModelBase {
  title = ''
  text = ''
  data = ''
  pushType = ''
  image = ''
  informpushqueue: IModelInformPushQueueDefault = {} as IModelInformPushQueueDefault
  pushPushUserHistories: IModelPushUserHistoryDefault[] = [] as IModelPushUserHistoryDefault[]
  url = ''
  appUrl = ''
  appData = ''
  pushTypeDisplay = ''
  constructor(data: IModelPushDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.informpushqueue = DataBase.getModel(this.informpushqueue)
    this.pushPushUserHistories = DataBase.getListModel(this.pushPushUserHistories)
  }
}

export class ModelPushUserHistoryDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  push: IModelPushDefault = {} as IModelPushDefault
  user: IModelUserDefault = {} as IModelUserDefault
  deviceInfo = ''
  isSuccess = false
  isRead = false
  readAt = null
  constructor(data: IModelPushUserHistoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.push = DataBase.getModel(this.push)
    this.user = DataBase.getModel(this.user)
  }
}

export class ModelRefundInfoDefault extends ModelBase {
  accountNumber = ''
  accountHolderName = ''
  bankCode = ''
  bankName = ''
  originalPrice = 0
  productDiscountPrice = 0
  productPromotionDiscountPrice = 0
  couponDiscountPrice = 0
  mileageDiscountPrice = 0
  orderCouponDiscountPrice = 0
  returnShippingFee = 0
  orderitem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  refundAmount = 0
  constructor(data: IModelRefundInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.orderitem = DataBase.getModel(this.orderitem)
  }
}

export class ModelRefundShippingInfoDefault extends ModelBase {
  fee = 0
  extraFee = 0
  discountPrice = 0
  couponDiscountPrice = 0
  mileageDiscountPrice = 0
  accountNumber = ''
  accountHolderName = ''
  bankCode = ''
  bankName = ''
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  returnShippingFee = 0
  refundShippingInfoOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  billingitemSet: IModelBillingItemDefault[] = [] as IModelBillingItemDefault[]
  sumFee = 0
  amountWithoutMileage = 0
  amount = 0
  constructor(data: IModelRefundShippingInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.shipping = DataBase.getModel(this.shipping)
    this.refundShippingInfoOrderItems = DataBase.getListModel(this.refundShippingInfoOrderItems)
    this.billingitemSet = DataBase.getListModel(this.billingitemSet)
  }
}

export class ModelRemoteAreaDefault extends ModelBase {
  address = ''
  extraFee = 0
  constructor(data: IModelRemoteAreaDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelReturnInfoImageDefault extends ModelBase {
  image = ''
  returnInfo: IModelReturnInfoDefault = {} as IModelReturnInfoDefault
  thumbnail = ''
  constructor(data: IModelReturnInfoImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.returnInfo = DataBase.getModel(this.returnInfo)
  }
}

export class ModelReturnInfoDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  customerAddress: IModelCustomerAddressDefault = {} as IModelCustomerAddressDefault
  responsibility = ''
  returnUser = ''
  returnShippingFee = 0
  fitpetAdminName = ''
  guideMessage = ''
  requestType = ''
  requestMessage = ''
  rejectMessage = ''
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  acceptedAt = null
  returnedAt = null
  confirmedAt = null
  rejectedAt = null
  returnInfoMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  returnInfoOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  returnInfoReturnInfoImages: IModelReturnInfoImageDefault[] = [] as IModelReturnInfoImageDefault[]
  billingitemSet: IModelBillingItemDefault[] = [] as IModelBillingItemDefault[]
  constructor(data: IModelReturnInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.customerAddress = DataBase.getModel(this.customerAddress)
    this.shipping = DataBase.getModel(this.shipping)
    this.returnInfoMileageHistories = DataBase.getListModel(this.returnInfoMileageHistories)
    this.returnInfoOrderItems = DataBase.getListModel(this.returnInfoOrderItems)
    this.returnInfoReturnInfoImages = DataBase.getListModel(this.returnInfoReturnInfoImages)
    this.billingitemSet = DataBase.getListModel(this.billingitemSet)
  }
}

export class ModelReturnReasonDefault extends ModelBase {
  requestType = ''
  requestMessage = ''
  responsibility = ''
  isUserVisible = false
  constructor(data: IModelReturnReasonDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelReviewGroupDefault extends ModelBase {
  name = ''
  averageScore = 0
  totalCount = 0
  reviewGroupProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  productCount = 0
  constructor(data: IModelReviewGroupDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.reviewGroupProducts = DataBase.getListModel(this.reviewGroupProducts)
  }
}

export class ModelReviewImageDefault extends ModelBase {
  image = ''
  review: IModelReviewDefault = {} as IModelReviewDefault
  thumbnail = ''
  constructor(data: IModelReviewImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.review = DataBase.getModel(this.review)
  }
}

export class ModelReviewDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  checkAdminAt = null
  user: IModelUserDefault = {} as IModelUserDefault
  product: IModelProductDefault = {} as IModelProductDefault
  productOptionName = ''
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  reviewBody = ''
  answerBody = ''
  answeredAt = null
  answeredUser: IModelUserDefault = {} as IModelUserDefault
  score = 0
  weight = 0
  isDelete = false
  isPhotoReview = false
  bestAt = null
  bestSeries = 0
  likedUsers: IModelUserDefault[] = [] as IModelUserDefault[]
  mileage = 0
  reviewReviewImages: IModelReviewImageDefault[] = [] as IModelReviewImageDefault[]
  reviewMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  isAnswered = false
  seller: IModelSellerDefault = {} as IModelSellerDefault
  isFirstReviewProduct = false
  username = ''
  isLiked = false
  mileageText = ''
  likedCount = 0
  constructor(data: IModelReviewDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
    this.product = DataBase.getModel(this.product)
    this.orderItem = DataBase.getModel(this.orderItem)
    this.answeredUser = DataBase.getModel(this.answeredUser)
    this.likedUsers = DataBase.getListModel(this.likedUsers)
    this.reviewReviewImages = DataBase.getListModel(this.reviewReviewImages)
    this.reviewMileageHistories = DataBase.getListModel(this.reviewMileageHistories)
    this.seller = DataBase.getModel(this.seller)
  }
}

export class ModelSearchHistoryDefault extends ModelBase {
  petType = ''
  createdAt = null
  updatedAt = null
  searchKeyword = ''
  constructor(data: IModelSearchHistoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelSellerAddressDefault extends ModelBase {
  postNumber = ''
  basicAddress = ''
  detailedAddress = ''
  warehouseAddressShippingPreset: IModelShippingPresetDefault[] = [] as IModelShippingPresetDefault[]
  returnAddressShippingPreset: IModelShippingPresetDefault[] = [] as IModelShippingPresetDefault[]
  constructor(data: IModelSellerAddressDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.warehouseAddressShippingPreset = DataBase.getListModel(this.warehouseAddressShippingPreset)
    this.returnAddressShippingPreset = DataBase.getListModel(this.returnAddressShippingPreset)
  }
}

export class ModelSellerBillingDefault extends ModelBase {
  billedAt = null
  baseAt = null
  confirmedAt = null
  billingType = ''
  customerAmount = 0
  couponDiscountAmount = 0
  mileageDiscountAmount = 0
  sellerAmount = 0
  fitpetAmount = 0
  seller: IModelSellerDefault = {} as IModelSellerDefault
  brand: IModelBrandDefault = {} as IModelBrandDefault
  fitpetSupplyAmount = 0
  fitpetPromotionAmount = 0
  sellerSupplyAmount = 0
  customerReturnFees = 0
  customerShippingFees = 0
  shippingDiscountAmount = 0
  shippingCouponDiscountAmount = 0
  shippingMileageDiscountAmount = 0
  sellerBillingItems: IModelBillingItemDefault[] = [] as IModelBillingItemDefault[]
  nextBillingAt = null
  billingStartedAt = null
  billingEndedAt = null
  sellerTotalAmount = 0
  constructor(data: IModelSellerBillingDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.seller = DataBase.getModel(this.seller)
    this.brand = DataBase.getModel(this.brand)
    this.sellerBillingItems = DataBase.getListModel(this.sellerBillingItems)
  }
}

export class ModelSellerStatisticColumnDefault extends ModelBase {
  totalAmount = 0
  totalSoldAmount = 0
  totalCouponDiscountAmount = 0
  totalMileageDiscountAmount = 0
  totalCustomerCount = 0
  totalOrderCount = 0
  totalSoldCount = 0
  guestUnitPrice = 0
  avgProductPrice = 0
  sellerName = ''
  brandName = ''
  constructor(data: IModelSellerStatisticColumnDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelSellerStatusCountDefault extends ModelBase {
  totalCount = 0
  requestCount = 0
  approvedCount = 0
  rejectedCount = 0
  suspendCount = 0
  bannedCount = 0
  deletedCount = 0
  updateRequestCount = 0
  updateRejectedCount = 0
  constructor(data: IModelSellerStatusCountDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelSellerTemplateAddressDefault extends ModelBase {
  postNumber = ''
  basicAddress = ''
  detailedAddress = ''
  isDefault = false
  seller: IModelSellerDefault = {} as IModelSellerDefault
  constructor(data: IModelSellerTemplateAddressDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.seller = DataBase.getModel(this.seller)
  }
}

export class ModelSellerDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  accountNumber = ''
  accountHolderName = ''
  bankCode = ''
  bankName = ''
  users: IModelUserDefault[] = [] as IModelUserDefault[]
  companyName = ''
  shopName = ''
  companyOwnerName = ''
  businessRegistrationNumber = ''
  companyId = ''
  postNumber = ''
  basicAddress = ''
  detailedAddress = ''
  homepage = ''
  businessCondition = ''
  businessItem = ''
  businessRegistration = ''
  mailOrderReportNumber = ''
  mailOrderReportRegistration = ''
  bankBookImage = ''
  telephoneNumber = ''
  status: IModelStatusDefault = {} as IModelStatusDefault
  blockedInfluencers: IModelInfluencerDefault[] = [] as IModelInfluencerDefault[]
  updateInfo: IModelUpdatedSellerDefault = {} as IModelUpdatedSellerDefault
  updateStatus: IModelStatusDefault = {} as IModelStatusDefault
  fitpetFeeRate = 0
  accountManagerName = ''
  accountManagerTelephoneNumber = ''
  taxEmail = ''
  notificationManagerName = ''
  notificationEmail = ''
  notificationMobileNumber = ''
  returnExchangeGuide = ''
  requestedAt = null
  approvedAt = null
  updateApprovedAt = null
  sellerProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  sellerBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  sellerSkus: IModelSkuDefault[] = [] as IModelSkuDefault[]
  sellerInfluencers: IModelInfluencerDefault[] = [] as IModelInfluencerDefault[]
  sellerUsers: IModelSellerUserDefault[] = [] as IModelSellerUserDefault[]
  sellerAsInfos: IModelAsInfoDefault[] = [] as IModelAsInfoDefault[]
  sellerAddresses: IModelSellerTemplateAddressDefault[] = [] as IModelSellerTemplateAddressDefault[]
  sellerExcelRequests: IModelExcelExportRequestDefault[] = [] as IModelExcelExportRequestDefault[]
  sellerOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  userShippingPresets: IModelShippingPresetDefault[] = [] as IModelShippingPresetDefault[]
  sellerBillings: IModelSellerBillingDefault[] = [] as IModelSellerBillingDefault[]
  isFollowed = false
  chiefManager: IModelUserDefault = {} as IModelUserDefault
  soldQuantity = 0
  soldQuantityLastMonth = 0
  soldAmount = 0
  productCount = 0
  sellerTemplateAddresses: IModelSellerTemplateAddressDefault[] = [] as IModelSellerTemplateAddressDefault[]
  isFitpet = false
  constructor(data: IModelSellerDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.users = DataBase.getListModel(this.users)
    this.status = DataBase.getModel(this.status)
    this.blockedInfluencers = DataBase.getListModel(this.blockedInfluencers)
    this.updateInfo = DataBase.getModel(this.updateInfo)
    this.updateStatus = DataBase.getModel(this.updateStatus)
    this.sellerProducts = DataBase.getListModel(this.sellerProducts)
    this.sellerBrands = DataBase.getListModel(this.sellerBrands)
    this.sellerSkus = DataBase.getListModel(this.sellerSkus)
    this.sellerInfluencers = DataBase.getListModel(this.sellerInfluencers)
    this.sellerUsers = DataBase.getListModel(this.sellerUsers)
    this.sellerAsInfos = DataBase.getListModel(this.sellerAsInfos)
    this.sellerAddresses = DataBase.getListModel(this.sellerAddresses)
    this.sellerExcelRequests = DataBase.getListModel(this.sellerExcelRequests)
    this.sellerOrderItems = DataBase.getListModel(this.sellerOrderItems)
    this.userShippingPresets = DataBase.getListModel(this.userShippingPresets)
    this.sellerBillings = DataBase.getListModel(this.sellerBillings)
    this.chiefManager = DataBase.getModel(this.chiefManager)
    this.sellerTemplateAddresses = DataBase.getListModel(this.sellerTemplateAddresses)
  }
}

export class ModelSellerUserDefault extends ModelBase {
  user: IModelUserDefault = {} as IModelUserDefault
  seller: IModelSellerDefault = {} as IModelSellerDefault
  authType = ''
  constructor(data: IModelSellerUserDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
    this.seller = DataBase.getModel(this.seller)
  }
}

export class ModelServerConfigDefault extends ModelBase {
  startedAt = null
  endedAt = null
  createdAt = null
  updatedAt = null
  configType = ''
  key = ''
  value = ''
  description = ''
  constructor(data: IModelServerConfigDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelServiceQnaImageDefault extends ModelBase {
  image = ''
  serviceQna: IModelServiceQnaDefault = {} as IModelServiceQnaDefault
  thumbnail = ''
  constructor(data: IModelServiceQnaImageDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.serviceQna = DataBase.getModel(this.serviceQna)
  }
}

export class ModelServiceQnaDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  questionBody = ''
  answerBody = ''
  answeredAt = null
  orderItem: IModelOrderItemDefault = {} as IModelOrderItemDefault
  incomingEmail = ''
  mobileNumber = ''
  category = ''
  questionType = ''
  user: IModelUserDefault = {} as IModelUserDefault
  answeredUser: IModelUserDefault = {} as IModelUserDefault
  answeredUserType = ''
  serviceQnaImages: IModelServiceQnaImageDefault[] = [] as IModelServiceQnaImageDefault[]
  isAnswered = false
  isAdmin = false
  seller: IModelSellerDefault = {} as IModelSellerDefault
  isPhoto = ''
  constructor(data: IModelServiceQnaDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.orderItem = DataBase.getModel(this.orderItem)
    this.user = DataBase.getModel(this.user)
    this.answeredUser = DataBase.getModel(this.answeredUser)
    this.serviceQnaImages = DataBase.getListModel(this.serviceQnaImages)
    this.seller = DataBase.getModel(this.seller)
  }
}

export class ModelShippingCompanyDefault extends ModelBase {
  name = ''
  sweetTrackerCode = ''
  iamportCode = ''
  shippingCompanyShippings: IModelShippingDefault[] = [] as IModelShippingDefault[]
  shippingCompanyPresets: IModelShippingPresetDefault[] = [] as IModelShippingPresetDefault[]
  isDelete = false
  constructor(data: IModelShippingCompanyDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.shippingCompanyShippings = DataBase.getListModel(this.shippingCompanyShippings)
    this.shippingCompanyPresets = DataBase.getListModel(this.shippingCompanyPresets)
  }
}

export class ModelShippingPresetDefault extends ModelBase {
  seller: IModelSellerDefault = {} as IModelSellerDefault
  name = ''
  shippingCompany: IModelShippingCompanyDefault = {} as IModelShippingCompanyDefault
  shippingFee = 0
  freeShippingBaseAmount = 0
  returnFee = 0
  warehouseAddress: IModelSellerAddressDefault = {} as IModelSellerAddressDefault
  returnAddress: IModelSellerAddressDefault = {} as IModelSellerAddressDefault
  isPackage = false
  isPrepayment = false
  guide = ''
  notice = ''
  applyRemoteAreaFee = false
  shippingPresetProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  shippingPresetOrderItems: IModelShippingDefault[] = [] as IModelShippingDefault[]
  constructor(data: IModelShippingPresetDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.seller = DataBase.getModel(this.seller)
    this.shippingCompany = DataBase.getModel(this.shippingCompany)
    this.warehouseAddress = DataBase.getModel(this.warehouseAddress)
    this.returnAddress = DataBase.getModel(this.returnAddress)
    this.shippingPresetProducts = DataBase.getListModel(this.shippingPresetProducts)
    this.shippingPresetOrderItems = DataBase.getListModel(this.shippingPresetOrderItems)
  }
}

export class ModelShippingTrackDefault extends ModelBase {
  shippingTrack = ''
  shipping: IModelShippingDefault = {} as IModelShippingDefault
  constructor(data: IModelShippingTrackDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.shipping = DataBase.getModel(this.shipping)
  }
}

export class ModelShippingDefault extends ModelBase {
  fee = 0
  extraFee = 0
  discountPrice = 0
  couponDiscountPrice = 0
  mileageDiscountPrice = 0
  shippingPreset: IModelShippingPresetDefault = {} as IModelShippingPresetDefault
  shippingCompany: IModelShippingCompanyDefault = {} as IModelShippingCompanyDefault
  shippingType = ''
  shippingNumbers = ''
  sentAt = null
  receivedAt = null
  couponInfo = ''
  shippingMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  shippingCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  shippingOrderItems: IModelOrderItemDefault[] = [] as IModelOrderItemDefault[]
  shippingReturnInfos: IModelReturnInfoDefault[] = [] as IModelReturnInfoDefault[]
  shippingRefundShippingInfos: IModelRefundShippingInfoDefault[] = [] as IModelRefundShippingInfoDefault[]
  billingitemSet: IModelBillingItemDefault[] = [] as IModelBillingItemDefault[]
  shippingTypeText = ''
  isTrackable = false
  untrackedReason = ''
  amount = 0
  availableCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  seller: IModelSellerDefault = {} as IModelSellerDefault
  sumFee = 0
  isFitpet = false
  isRefundable = false
  shippingCompanyAndNumbers = ''
  constructor(data: IModelShippingDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.shippingPreset = DataBase.getModel(this.shippingPreset)
    this.shippingCompany = DataBase.getModel(this.shippingCompany)
    this.shippingMileageHistories = DataBase.getListModel(this.shippingMileageHistories)
    this.shippingCoupons = DataBase.getListModel(this.shippingCoupons)
    this.shippingOrderItems = DataBase.getListModel(this.shippingOrderItems)
    this.shippingReturnInfos = DataBase.getListModel(this.shippingReturnInfos)
    this.shippingRefundShippingInfos = DataBase.getListModel(this.shippingRefundShippingInfos)
    this.billingitemSet = DataBase.getListModel(this.billingitemSet)
    this.availableCoupons = DataBase.getListModel(this.availableCoupons)
    this.seller = DataBase.getModel(this.seller)
  }
}

export class ModelSkuHistoryDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  sku: IModelSkuDefault = {} as IModelSkuDefault
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  beforeChanges = ''
  afterChanges = ''
  constructor(data: IModelSkuHistoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.sku = DataBase.getModel(this.sku)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
  }
}

export class ModelSkuDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  seller: IModelSellerDefault = {} as IModelSellerDefault
  code = ''
  name = ''
  stockQuantity = 0
  purchasePrice = 0
  price = 0
  discountPrice = 0
  barcodeNumber = ''
  sabangnetProductNumber = ''
  createdUser: IModelUserDefault = {} as IModelUserDefault
  updatedUser: IModelUserDefault = {} as IModelUserDefault
  productOptions: IModelProductOptionDefault[] = [] as IModelProductOptionDefault[]
  skuSkuHistories: IModelSkuHistoryDefault[] = [] as IModelSkuHistoryDefault[]
  skuProductOptionSkus: IModelProductOptionSkuDefault[] = [] as IModelProductOptionSkuDefault[]
  customerPrice = 0
  constructor(data: IModelSkuDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.seller = DataBase.getModel(this.seller)
    this.createdUser = DataBase.getModel(this.createdUser)
    this.updatedUser = DataBase.getModel(this.updatedUser)
    this.productOptions = DataBase.getListModel(this.productOptions)
    this.skuSkuHistories = DataBase.getListModel(this.skuSkuHistories)
    this.skuProductOptionSkus = DataBase.getListModel(this.skuProductOptionSkus)
  }
}

export class ModelSlimBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  pcImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  mobileImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  constructor(data: IModelSlimBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.pcImage = DataBase.getModel(this.pcImage)
    this.mobileImage = DataBase.getModel(this.mobileImage)
  }
}

export class ModelSocialServiceDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  user: IModelUserDefault = {} as IModelUserDefault
  socialType = ''
  accessTokenIssuedAt = null
  lastLoggedAt = null
  constructor(data: IModelSocialServiceDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
  }
}

export class ModelStatusHistoryDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  status: IModelStatusDefault = {} as IModelStatusDefault
  requestUser: IModelUserDefault = {} as IModelUserDefault
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  requestType = ''
  value = ''
  message = ''
  sellerChiefManager: IModelUserDefault = {} as IModelUserDefault
  constructor(data: IModelStatusHistoryDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.status = DataBase.getModel(this.status)
    this.requestUser = DataBase.getModel(this.requestUser)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.sellerChiefManager = DataBase.getModel(this.sellerChiefManager)
  }
}

export class ModelStatusDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  requestType = ''
  value = ''
  product: IModelProductDefault = {} as IModelProductDefault
  productUpdateStatus: IModelProductDefault = {} as IModelProductDefault
  brand: IModelBrandDefault = {} as IModelBrandDefault
  brandUpdateStatus: IModelBrandDefault = {} as IModelBrandDefault
  influencer: IModelInfluencerDefault = {} as IModelInfluencerDefault
  seller: IModelSellerDefault = {} as IModelSellerDefault
  sellerUpdateStatus: IModelSellerDefault = {} as IModelSellerDefault
  statusStatusHistories: IModelStatusHistoryDefault[] = [] as IModelStatusHistoryDefault[]
  sellerChiefManager: IModelUserDefault = {} as IModelUserDefault
  text = ''
  recentMessage = ''
  constructor(data: IModelStatusDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.productUpdateStatus = DataBase.getModel(this.productUpdateStatus)
    this.brand = DataBase.getModel(this.brand)
    this.brandUpdateStatus = DataBase.getModel(this.brandUpdateStatus)
    this.influencer = DataBase.getModel(this.influencer)
    this.seller = DataBase.getModel(this.seller)
    this.sellerUpdateStatus = DataBase.getModel(this.sellerUpdateStatus)
    this.statusStatusHistories = DataBase.getListModel(this.statusStatusHistories)
    this.sellerChiefManager = DataBase.getModel(this.sellerChiefManager)
  }
}

export class ModelSubRollBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  title = ''
  pcDescription = ''
  mobileDescription = ''
  pcImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  mobileImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  constructor(data: IModelSubRollBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.pcImage = DataBase.getModel(this.pcImage)
    this.mobileImage = DataBase.getModel(this.mobileImage)
  }
}

export class ModelTermStatisticColumnDefault extends ModelBase {
  totalUserCount = 0
  signInUserCount = 0
  leftUserCount = 0
  totalAmount = 0
  totalSoldAmount = 0
  totalCouponDiscountAmount = 0
  totalMileageDiscountAmount = 0
  totalCustomerCount = 0
  totalOrderCount = 0
  totalSoldCount = 0
  guestUnitPrice = 0
  avgProductPrice = 0
  truncatedAt = null
  constructor(data: IModelTermStatisticColumnDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelTimeseriesValueDefault extends ModelBase {
  x = null
  y = 0
  constructor(data: IModelTimeseriesValueDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelTopRollBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  pcImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  mobileImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  eventTypeText = ''
  description = ''
  subjectText = ''
  benefitText = ''
  constructor(data: IModelTopRollBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.pcImage = DataBase.getModel(this.pcImage)
    this.mobileImage = DataBase.getModel(this.mobileImage)
  }
}

export class ModelTotalInfluencerStatisticDefault extends ModelBase {
  totalInfluencerCount = 0
  totalSoldCount = 0
  totalSoldAmount = 0
  constructor(data: IModelTotalInfluencerStatisticDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelTotalSalesStatisticDefault extends ModelBase {
  totalAmount = 0
  totalSoldAmount = 0
  totalCouponDiscountAmount = 0
  totalMileageDiscountAmount = 0
  totalCustomerCount = 0
  totalOrderCount = 0
  totalSoldCount = 0
  guestUnitPrice = 0
  avgProductPrice = 0
  constructor(data: IModelTotalSalesStatisticDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelTotalSellerStatisticDefault extends ModelBase {
  totalSellerCount = 0
  totalOrderCount = 0
  totalSoldCount = 0
  totalSoldAmount = 0
  constructor(data: IModelTotalSellerStatisticDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelTransactionDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  order: IModelOrderDefault = {} as IModelOrderDefault
  device = ''
  payMethodType = ''
  isEscrow = false
  merchantCode = ''
  merchantUid = ''
  iamportUid = ''
  pgTid = ''
  pgApprovedTid = ''
  name = ''
  amount = 0
  taxFreeAmount = 0
  cardQuota = 0
  errorCode = ''
  errorMessage = ''
  transactionStatus = ''
  pgProviderType = ''
  payAppType = ''
  startedAt = null
  approvedAt = null
  receiptUrl = ''
  applyNumber = ''
  vbankAccountNumber = ''
  vbankBankCode = ''
  vbankBankName = ''
  vbankAccountHolderName = ''
  vbankIssuedAt = null
  vbankEndedAt = null
  bankAccountNumber = ''
  bankCode = ''
  bankName = ''
  bankAccountHolderName = ''
  responseData = ''
  webhookData = ''
  fitpetAdmin: IModelUserDefault = {} as IModelUserDefault
  approvedTransactionOrder: IModelOrderDefault = {} as IModelOrderDefault
  transactionCancelTransactions: IModelCancelTransactionDefault[] = [] as IModelCancelTransactionDefault[]
  createdAtMillis = ''
  transactionStatusText = ''
  cancelTransactions: IModelCancelTransactionDefault[] = [] as IModelCancelTransactionDefault[]
  paymentText = ''
  constructor(data: IModelTransactionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.order = DataBase.getModel(this.order)
    this.fitpetAdmin = DataBase.getModel(this.fitpetAdmin)
    this.approvedTransactionOrder = DataBase.getModel(this.approvedTransactionOrder)
    this.transactionCancelTransactions = DataBase.getListModel(this.transactionCancelTransactions)
    this.cancelTransactions = DataBase.getListModel(this.cancelTransactions)
  }
}

export class ModelUpdatedBrandDefault extends ModelBase {
  brand: IModelBrandDefault = {} as IModelBrandDefault
  listImage = ''
  backgroundImage = ''
  constructor(data: IModelUpdatedBrandDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.brand = DataBase.getModel(this.brand)
  }
}

export class ModelUpdatedProductDefault extends ModelBase {
  product: IModelProductDefault = {} as IModelProductDefault
  productOptions: any[] = []
  productImages: IModelProductImageDefault[] = [] as IModelProductImageDefault[]
  statusValue = ''
  constructor(data: IModelUpdatedProductDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.product = DataBase.getModel(this.product)
    this.productOptions = DataBase.getListModel(this.productOptions)
    this.productImages = DataBase.getListModel(this.productImages)
  }
}

export class ModelUpdatedSellerDefault extends ModelBase {
  seller: IModelSellerDefault = {} as IModelSellerDefault
  asInfo: IModelAsInfoDefault = {} as IModelAsInfoDefault
  businessRegistration = ''
  mailOrderReportRegistration = ''
  bankBookImage = ''
  constructor(data: IModelUpdatedSellerDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.seller = DataBase.getModel(this.seller)
    this.asInfo = DataBase.getModel(this.asInfo)
  }
}

export class ModelUserCertificationDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  mobileNumber = ''
  email = ''
  confirmedAt = null
  certType = ''
  expiredAt = null
  constructor(data: IModelUserCertificationDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelUserCountDefault extends ModelBase {
  count = 0
  constructor(data: IModelUserCountDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelUserInfoAccountDefault extends ModelBase {
  loginAccountType = ''
  lastLoggedAt = null
  createdAt = null
  constructor(data: IModelUserInfoAccountDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)
  }
}

export class ModelUserInfoDefault extends ModelBase {
  email = ''
  userStatus = ''
  accounts: IModelUserInfoAccountDefault[] = [] as IModelUserInfoAccountDefault[]
  constructor(data: IModelUserInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.accounts = DataBase.getListModel(this.accounts)
  }
}

export class ModelUserSubscriptionDefault extends ModelBase {
  startedAt = null
  endedAt = null
  createdAt = null
  updatedAt = null
  user: IModelUserDefault = {} as IModelUserDefault
  totalRound = 0
  subscriptionStatus = ''
  subscriptionInfo = ''
  constructor(data: IModelUserSubscriptionDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.user = DataBase.getModel(this.user)
  }
}

export class ModelUserDefault extends ModelBase {
  createdAt = null
  updatedAt = null
  accountNumber = ''
  accountHolderName = ''
  bankCode = ''
  bankName = ''
  lastLogin = null
  isSuperuser = false
  firstName = ''
  lastName = ''
  email = ''
  isStaff = false
  isActive = false
  dateJoined = null
  username = ''
  name = ''
  inactivatedAt = null
  activatedAt = null
  leftAt = null
  mobileNumber = ''
  influencer: IModelInfluencerDefault = {} as IModelInfluencerDefault
  grade: IModelCustomerGradeDefault = {} as IModelCustomerGradeDefault
  gradeUpdatedAt = null
  mileage: IModelMileageDefault = {} as IModelMileageDefault
  inviteUser: IModelUserDefault = {} as IModelUserDefault
  invitationCode = ''
  inviteCount = 0
  birthday = null
  gender = ''
  wishedProducts: IModelProductDefault[] = [] as IModelProductDefault[]
  wishedBrands: IModelBrandDefault[] = [] as IModelBrandDefault[]
  updateApprovedAt = null
  passwordUpdatedAt = null
  godoId = ''
  leftReason = ''
  leftMessage = ''
  agreeNightMarketing = false
  agreeNightMarketingAt = null
  agreeMarketing = false
  agreeMarketingAt = null
  agreeLocationBasedService = false
  agreeLocationBasedServiceAt = null
  agreeThirdPartyProvision = false
  agreeThirdPartyProvisionAt = null
  userStatus = ''
  signInCount = 0
  signInFailCount = 0
  lastSignInFailedAt = null
  joinedPlatform = ''
  displayPetType = ''
  boughtDeal100 = false
  invitedUser: IModelUserDefault[] = [] as IModelUserDefault[]
  inactiveuser: IModelInactiveUserDefault = {} as IModelInactiveUserDefault
  userProductQnas: IModelProductQnaDefault[] = [] as IModelProductQnaDefault[]
  answeredUserProductQnas: IModelProductQnaDefault[] = [] as IModelProductQnaDefault[]
  userServiceQnas: IModelServiceQnaDefault[] = [] as IModelServiceQnaDefault[]
  answeredUserServiceQnas: IModelServiceQnaDefault[] = [] as IModelServiceQnaDefault[]
  userReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  answeredUserReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  likedReviews: IModelReviewDefault[] = [] as IModelReviewDefault[]
  createdUserSkus: IModelSkuDefault[] = [] as IModelSkuDefault[]
  updatedUserSkus: IModelSkuDefault[] = [] as IModelSkuDefault[]
  fitpetAdminSkuHistories: IModelSkuHistoryDefault[] = [] as IModelSkuHistoryDefault[]
  sellers: IModelSellerDefault[] = [] as IModelSellerDefault[]
  userSellerUsers: IModelSellerUserDefault[] = [] as IModelSellerUserDefault[]
  userDevices: IModelDeviceDefault[] = [] as IModelDeviceDefault[]
  requestUserStatusHistories: IModelStatusHistoryDefault[] = [] as IModelStatusHistoryDefault[]
  fitpetAdminStatusHistories: IModelStatusHistoryDefault[] = [] as IModelStatusHistoryDefault[]
  fitpetAdminMileageHistories: IModelMileageHistoryDefault[] = [] as IModelMileageHistoryDefault[]
  userCustomerTemplateAddresses: IModelCustomerTemplateAddressDefault[] = [] as IModelCustomerTemplateAddressDefault[]
  userSocialServices: IModelSocialServiceDefault[] = [] as IModelSocialServiceDefault[]
  fitpetAdminInformPushQueues: IModelInformPushQueueDefault[] = [] as IModelInformPushQueueDefault[]
  userPushUserHistories: IModelPushUserHistoryDefault[] = [] as IModelPushUserHistoryDefault[]
  userExcelRequests: IModelExcelExportRequestDefault[] = [] as IModelExcelExportRequestDefault[]
  userOrders: IModelOrderDefault[] = [] as IModelOrderDefault[]
  fitpetAdminCouponGroups: IModelCouponGroupDefault[] = [] as IModelCouponGroupDefault[]
  userCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  fitpetAdminCoupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  userSubscriptions: IModelUserSubscriptionDefault[] = [] as IModelUserSubscriptionDefault[]
  fitpetAdminOrderItemAdminNotes: IModelOrderItemAdminNoteDefault[] = [] as IModelOrderItemAdminNoteDefault[]
  fitpetAdminTransactions: IModelTransactionDefault[] = [] as IModelTransactionDefault[]
  adminCustomerGrade: IModelCustomerGradeDefault[] = [] as IModelCustomerGradeDefault[]
  userNotice: IModelNoticeDefault[] = [] as IModelNoticeDefault[]
  userFaqs: IModelFaqDefault[] = [] as IModelFaqDefault[]
  cartItems: IModelCartItemDefault[] = [] as IModelCartItemDefault[]
  cartItemHistories: IModelCartItemHistoryDefault[] = [] as IModelCartItemHistoryDefault[]
  sellerCount = 0
  approvalSellerCount = 0
  isSeller = false
  isInfluencer = false
  orderCount = 0
  ongoingOrderCount = 0
  reviewCount = 0
  writableReviewCount = 0
  cartItemCount = 0
  cartItemQuantity = 0
  orderAmount = 0
  coupons: IModelCouponDefault[] = [] as IModelCouponDefault[]
  mileagePoint = 0
  customerTemplateAddresses: IModelCustomerTemplateAddressDefault[] = [] as IModelCustomerTemplateAddressDefault[]
  recentOrderCustomerAddress: IModelCustomerAddressDefault = {} as IModelCustomerAddressDefault
  couponCount = 0
  userStatusText = ''
  hasEmailAccount = false
  inactiveUser: IModelInactiveUserDefault = {} as IModelInactiveUserDefault
  lastOrderedAt = null
  unusedMileage = 0
  constructor(data: IModelUserDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.influencer = DataBase.getModel(this.influencer)
    this.grade = DataBase.getModel(this.grade)
    this.mileage = DataBase.getModel(this.mileage)
    this.inviteUser = DataBase.getModel(this.inviteUser)
    this.wishedProducts = DataBase.getListModel(this.wishedProducts)
    this.wishedBrands = DataBase.getListModel(this.wishedBrands)
    this.invitedUser = DataBase.getListModel(this.invitedUser)
    this.inactiveuser = DataBase.getModel(this.inactiveuser)
    this.userProductQnas = DataBase.getListModel(this.userProductQnas)
    this.answeredUserProductQnas = DataBase.getListModel(this.answeredUserProductQnas)
    this.userServiceQnas = DataBase.getListModel(this.userServiceQnas)
    this.answeredUserServiceQnas = DataBase.getListModel(this.answeredUserServiceQnas)
    this.userReviews = DataBase.getListModel(this.userReviews)
    this.answeredUserReviews = DataBase.getListModel(this.answeredUserReviews)
    this.likedReviews = DataBase.getListModel(this.likedReviews)
    this.createdUserSkus = DataBase.getListModel(this.createdUserSkus)
    this.updatedUserSkus = DataBase.getListModel(this.updatedUserSkus)
    this.fitpetAdminSkuHistories = DataBase.getListModel(this.fitpetAdminSkuHistories)
    this.sellers = DataBase.getListModel(this.sellers)
    this.userSellerUsers = DataBase.getListModel(this.userSellerUsers)
    this.userDevices = DataBase.getListModel(this.userDevices)
    this.requestUserStatusHistories = DataBase.getListModel(this.requestUserStatusHistories)
    this.fitpetAdminStatusHistories = DataBase.getListModel(this.fitpetAdminStatusHistories)
    this.fitpetAdminMileageHistories = DataBase.getListModel(this.fitpetAdminMileageHistories)
    this.userCustomerTemplateAddresses = DataBase.getListModel(this.userCustomerTemplateAddresses)
    this.userSocialServices = DataBase.getListModel(this.userSocialServices)
    this.fitpetAdminInformPushQueues = DataBase.getListModel(this.fitpetAdminInformPushQueues)
    this.userPushUserHistories = DataBase.getListModel(this.userPushUserHistories)
    this.userExcelRequests = DataBase.getListModel(this.userExcelRequests)
    this.userOrders = DataBase.getListModel(this.userOrders)
    this.fitpetAdminCouponGroups = DataBase.getListModel(this.fitpetAdminCouponGroups)
    this.userCoupons = DataBase.getListModel(this.userCoupons)
    this.fitpetAdminCoupons = DataBase.getListModel(this.fitpetAdminCoupons)
    this.userSubscriptions = DataBase.getListModel(this.userSubscriptions)
    this.fitpetAdminOrderItemAdminNotes = DataBase.getListModel(this.fitpetAdminOrderItemAdminNotes)
    this.fitpetAdminTransactions = DataBase.getListModel(this.fitpetAdminTransactions)
    this.adminCustomerGrade = DataBase.getListModel(this.adminCustomerGrade)
    this.userNotice = DataBase.getListModel(this.userNotice)
    this.userFaqs = DataBase.getListModel(this.userFaqs)
    this.cartItems = DataBase.getListModel(this.cartItems)
    this.cartItemHistories = DataBase.getListModel(this.cartItemHistories)
    this.coupons = DataBase.getListModel(this.coupons)
    this.customerTemplateAddresses = DataBase.getListModel(this.customerTemplateAddresses)
    this.recentOrderCustomerAddress = DataBase.getModel(this.recentOrderCustomerAddress)
    this.inactiveUser = DataBase.getModel(this.inactiveUser)
  }
}

export class ModelWeeklyBestBannerInfoDefault extends ModelBase {
  adId = ''
  adName = ''
  adSlug = ''
  pcImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  mobileImage: IModelBannerImageDefault = {} as IModelBannerImageDefault
  kindText = ''
  constructor(data: IModelWeeklyBestBannerInfoDefault) {
    super(data)
    const _data = this.makeNumberIfAny(data)
    // noinspection TypeScriptValidateTypes
    Object.assign(this, _data)

    this.pcImage = DataBase.getModel(this.pcImage)
    this.mobileImage = DataBase.getModel(this.mobileImage)
  }
}
