import { MLinkRouterButton, MTextButton, Text14Normal } from 'components/@atomics'
import { ITableColumn, ITableColumnFixedProps } from 'components/@atomics/MTable'
import { IModelProductQna } from 'containers/models/modelProductQna'
import { IModelServiceQna } from 'containers/models/modelServiceQna'
import { IModelReview } from 'containers/models/modelReview'
import * as utilData from '@libs/utils/utilData'
import React from 'react'

type CurrentRecord = IModelProductQna | IModelReview | IModelServiceQna

const questionBody = ({
  dataIndex = 'questionBody',
  fixed = 'left',
  setCurrentData,
}: ITableColumnFixedProps = {}): ITableColumn<any> => {
  return {
    title: '문의 내용',
    dataIndex,
    key: 'questionBody',
    width: 150,
    fixed,
    render: (_: string, record: CurrentRecord) => {
      let qna: any
      if (dataIndex === 'productQna') {
        qna = record as IModelProductQna
      }
      if (dataIndex === 'orderQna') {
        qna = record as IModelServiceQna
      }
      if (dataIndex === 'serviceQna') {
        qna = record as IModelServiceQna
      }
      return <MTextButton onClick={() => setCurrentData!(record)}>{qna?.questionBody || _}</MTextButton>
    },
  }
}

const productCode = ({ width = 150, link = false } = {}): ITableColumn<any> => {
  return {
    title: '상품코드',
    link,
    width,
    render: (recorde: IModelProductQna) => {
      if (link) {
        const productDetailUrl = `${process.env.FRONT_WEB_DOMAIN}/products/${recorde?.product?._id}`
        return (
          <MLinkRouterButton href={productDetailUrl} openNewWindow>
            {recorde?.product?._id}
          </MLinkRouterButton>
        )
      }
      return <Text14Normal>{utilData.tableText(recorde?.product?._id)}</Text14Normal>
    },
  }
}

export {
  questionBody, // 문의 내용
  productCode, // 문의 내용
}
