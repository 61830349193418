import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faHeart, faStar } from '@fortawesome/free-regular-svg-icons'
import {
  faHeart as faHeartSolid,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faStarHalfAlt,
  faStar as faStarSolid,
  faPlus,
  faMinus,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { themes } from '@constants'

export type iconNameType =
  | 'instagram'
  | 'facebook'
  | 'search'
  | 'heart'
  | 'heart-solid'
  | 'star'
  | 'star-solid'
  | 'star-half'
  | 'plus'
  | 'minus'
  | 'chevron-right'
  | 'circle-notch'
  | 'chevron-left'

export type iconSizeType = 'xs' | 'lg' | 'sm' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x'

type IconBaseProps = {
  icon: iconNameType
  size?: iconSizeType
  color?: string
  height?: number
  spin?: boolean
}

const getIcon = (name: iconNameType): any => {
  switch (name) {
    case 'instagram':
      return faInstagram
    case 'facebook':
      return faFacebookF
    case 'heart':
      return faHeart
    case 'heart-solid':
      return faHeartSolid
    case 'star':
      return faStar
    case 'star-solid':
      return faStarSolid
    case 'star-half':
      return faStarHalfAlt
    case 'plus':
      return faPlus
    case 'minus':
      return faMinus
    case 'search':
      return faSearch
    case 'chevron-right':
      return faChevronRight
    case 'chevron-left':
      return faChevronLeft
    case 'circle-notch':
      return faCircleNotch
    default:
      return faCircle
  }
}

const Icon: FC<IconBaseProps> = ({ icon, height, spin, size = '1x', color = themes.colors.blackAlpha100 }) => {
  const _icon = getIcon(icon)

  return <FontAwesomeIcon height={height} icon={_icon} size={size} color={color} spin={spin} />
}

export default Icon
