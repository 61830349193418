import * as utilData from '@libs/utils/utilData'
import { DisplayCollectionTextLimit } from 'constants/display-collection-text-limit.enum'

export const isValidMobileNumberFormat = (mobileNumber: string) => {
  const _mobileNumber = mobileNumber.replace(/-/gi, '').replace(/ /, '').trim()
  return /^(010|011|016|018|019)\d{7,8}$/.test(_mobileNumber)
}

export function isValidEmailFormat(email: string | number) {
  if (typeof email !== 'string') return false
  if (email.length < 4 || email.length > 50 || email.indexOf(' ') > -1) return false

  const splitEmail = email.split('@')
  if (splitEmail.length !== 2) return false

  const [localPart, domainPart] = splitEmail

  const dotRule = new RegExp('\\.{2}|^\\.|\\.$') // eslint-disable-line
  if (dotRule.test(localPart)) return false

  const localPartRule = new RegExp('^[a-zA-Z0-9!#$%^&*-_.]+$')
  if (!localPartRule.test(localPart)) return false

  const domainRule = new RegExp('^-|-$')
  return !domainRule.test(domainPart)
}

// eslint-disable-next-line max-len
// 참고: https://ioerror.tistory.com/entry/%EC%9E%90%EC%A3%BC-%EC%82%AC%EC%9A%A9%ED%95%98%EB%8A%94-%EC%A0%95%EA%B7%9C%ED%91%9C%ED%98%84%EC%8B%9D-%EB%AA%A8%EC%9D%8C
export const isValidPhoneNumberFormat = (phoneNumber: string) => {
  const replacePhoneNumber = phoneNumber.replace(/-/gi, '').replace(/ /, '').trim()
  const isPhoneNumber = /^(0[2-8][0-5]?|01[01346-9])([1-9]{1}[0-9]{2,3})([0-9]{4})$/.test(replacePhoneNumber)
  const is15xx = /^(1544|1566|1577|1588|1644|1688)([0-9]{4})$/.test(replacePhoneNumber)
  return isPhoneNumber || is15xx
}

export const isValidNumberWithHyphen = (numberWithHyphen: string) => {
  return /^\d(\d|-)*\d$/.test(numberWithHyphen)
}

export const isValidRegistrationNumber = (registrationNumber: string) => {
  return /^\d{6}-\d{7}$/.test(registrationNumber)
}

export const isValidNumberWithSpecialCharacter = (str: string) => {
  return /(^[!@#$%^&*()_+=`~\-|0-9]+$)/.test(str)
}

export const isOptionValidNumberWithSpecialCharacter = (str: string) => {
  return /(^[!@#$%^&*()_+=,`~\-|0-9]+$)/.test(str)
}

export const isValidCorrespondent = (correspondent: string) => {
  return /(^[!@#$%^&*()_+=~`,./\-|0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣 \n]+$)/.test(correspondent)
}

export const isValidSearchKeywords = (stringValue: string) => {
  return /(^[.,/\-|0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣\n]+$)/.test(stringValue)
}

export const isValidPasswordFormat = (value: string) => {
  if (!value || value.length === 0) {
    return { isValid: true, errorMsg: '' }
  }
  if (value.length < 10) {
    return { isValid: false, errorMsg: '10자리 이상 입력해주세요' }
  }
  if (value.length > 16) {
    return { isValid: false, errorMsg: '16자리 이하으로 입력해주세요' }
  }

  let passCount = 0

  const pwRegexEng = new RegExp('[a-zA-Z]')
  const pwRegexNum = new RegExp('[0-9]')
  const pwRegexSpc = new RegExp('[!@#$%^&*]')

  passCount += pwRegexEng.test(value) ? 1 : 0
  passCount += pwRegexNum.test(value) ? 1 : 0
  passCount += pwRegexSpc.test(value) ? 1 : 0

  if (passCount < 2) {
    return { isValid: false, errorMsg: '영문대/소문자,숫자,특수문자 중 2가지 이상 조합으로 입력해주세요' }
  }
  return { isValid: true, errorMsg: '' }
}

export const isValidProductNameFormat = (productName: string) => {
  return /^[ㄱ-힣\d\w\s~!%&*()\-+<>\[\]/.,]+$/.test(productName)
}

export const pass = () => Promise.resolve()
export const fail = (msg: string) => Promise.reject(new Error(msg))

export function emailValidator(email: string) {
  if (isValidEmailFormat(email)) {
    return pass()
  }
  return fail('올바른 이메일 형식을 입력해 주세요')
}

export function mobilNumberValidator(mobileNumber?: string) {
  if (!mobileNumber || mobileNumber.length === 0 || isValidMobileNumberFormat(mobileNumber)) {
    return pass()
  }
  return fail('입력하신 연락처를 확인해주세요.')
}

export function phoneNumberValidator(phoneNumber?: string) {
  if (!phoneNumber || phoneNumber.length === 0 || isValidPhoneNumberFormat(phoneNumber)) {
    return pass()
  }
  return fail('입력하신 연락처를 확인해주세요.')
}

export function phoneNumberListValidator(phoneNumbers?: string, separator: string = ',') {
  if (!phoneNumbers || phoneNumbers.length === 0) {
    return pass()
  }

  const phoneNumberList = phoneNumbers.replace('-', '').split(separator)
  if (phoneNumberList.every((phoneNumber) => isValidPhoneNumberFormat(phoneNumber))) {
    return pass()
  }

  return fail('입력하신 연락처를 확인해주세요.')
}

export function numberWithHyphenValidator(numberWithHyphen?: string) {
  if (!numberWithHyphen || numberWithHyphen.length === 0 || isValidNumberWithHyphen(numberWithHyphen)) {
    return pass()
  }
  return fail('입력하신 값을 확인해주세요.')
}

export function registrationNumberValidator(registrationNumber?: string) {
  if (!registrationNumber || registrationNumber.length === 0 || isValidRegistrationNumber(registrationNumber)) {
    return pass()
  }
  return fail('입력하신 주민등록 번호를 확인해주세요.')
}

export function numberWithSpecialCharacterValidator(str?: string, message?: string) {
  if (!str || str.length === 0 || isValidNumberWithSpecialCharacter(str)) {
    return pass()
  }
  return fail(message || '숫자와 !@#$%^&*()_+=`~ 만 입력할 수 있습니다.')
}

export function correspondentValidator(correspondent?: string) {
  if (!correspondent || correspondent.length === 0 || isValidCorrespondent(correspondent)) {
    return pass()
  }
  return fail('한글, 영문, 숫자와 !@#$%^&*()_+=~`,./ 만 입력할 수 있습니다.')
}

export function checkboxRequiredValidator(checked?: boolean, message?: string) {
  if (checked) {
    return pass()
  }
  return fail(message || '항목을 체크해주세요')
}

export function checkFilesizeValidator(
  file?: File | string,
  minSizeByte = 0,
  maxSizeByte = 3 * 1024 * 1024,
  message?: string
) {
  if (!file || typeof file === 'string' || (minSizeByte <= file.size && file.size <= maxSizeByte)) {
    return pass()
  }
  return fail(
    message ||
      `${minSizeByte ? `${utilData.bytesToMB(minSizeByte)} MB 이상, ` : ''}
    ${utilData.bytesToMB(maxSizeByte)} MB 이하 파일을 업로드 주세요`
  )
}

export function checkNumberRangeValidator(
  num?: number,
  minNum = 1,
  maxNum = 1000,
  numRequired?: boolean,
  message?: string
) {
  if ((!num && !numRequired) || (num && minNum <= num && num <= maxNum)) {
    return pass()
  }
  return fail(message || `${minNum} ~ ${maxNum} 숫자를 입력해주세요.`)
}

export const validateTextAreaLimit = (value: string, limitLength: DisplayCollectionTextLimit) => {
  /** limitLength가 16이라면 첫줄은 1~16글자, 두번째 줄이 있는 경우는 1~16글자 */
  const range = `{1,${limitLength}}`
  const underLimit = new RegExp(`^.${range}(\n.${range})?$`)

  // 3번째 줄까지 줄바꿈을 시도해도 validation이 true로 반환되어 버리는 이슈로 인해 줄 수를 먼저 유효성 체크 진행
  if (value.split('\n').length > 2) {
    return fail('최대 두 줄까지 입력 가능합니다.')
  }

  if (!underLimit.test(value)) {
    return fail(`한 줄 당 띄어쓰기 포함 ${limitLength}글자까지 입력 가능합니다.`)
  }

  return pass()
}
