import { IModelServerConfigDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelServerConfigDefault } from 'containers/models/base/defaultModels'
import { IModelServiceQna } from 'containers/models/modelServiceQna'
import { camelize } from '@ridi/object-case-converter'

type MileageServerConfigType = {
  point?: number
  isSuspend: boolean
  rate?: number
}

export interface IModelServerConfig extends IModelServerConfigDefault {
  mileageConfigData: MileageServerConfigType
}

export default class ModelServerConfig extends ModelServerConfigDefault {
  mileageConfigData?: MileageServerConfigType

  constructor(data: IModelServiceQna) {
    // @ts-ignore
    super(data)
    this.mileageConfigData =
      this.configType === 'MILEAGE'
        ? (camelize(JSON.parse(this.value)) as unknown as MileageServerConfigType)
        : undefined
  }
}
