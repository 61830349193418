import _ from 'lodash'
import { useRouter } from 'next/router'
import { Menu } from 'antd'
import { MenuProps } from 'antd/lib/menu'
import { MLinkRouterButton } from 'components/@atomics'
import { ROUTE_KEYS } from '@constants/constRoutes'

type MenuConfig = {
  id: string
  title: string
  icon: any
  children: { id: string; title: string; url: string }[]
}

type MMenuSiderProps = MenuProps & {
  curSelectedKeys: string
  menuConfig: MenuConfig[]
}

function MMenuSider({ curSelectedKeys, menuConfig, ...menuProps }: MMenuSiderProps) {
  const router = useRouter()
  const [openKeys, selectedKeys] = _.split(router.pathname, '/').slice(1)

  return (
    <Menu
      mode="inline"
      theme="dark"
      defaultOpenKeys={[!selectedKeys ? ROUTE_KEYS.ACCOUNTS : openKeys]}
      selectedKeys={[curSelectedKeys || selectedKeys]}
      {...menuProps}
    >
      {menuConfig.map((menu) => {
        return (
          <Menu.SubMenu key={menu.id} icon={menu.icon} title={menu.title}>
            {menu.children.map((item) => {
              return (
                <Menu.Item key={item.id}>
                  <MLinkRouterButton href={item.url}>{item.title}</MLinkRouterButton>
                </Menu.Item>
              )
            })}
          </Menu.SubMenu>
        )
      })}
    </Menu>
  )
}

export default MMenuSider
