import { IModelRefundShippingInfo } from 'containers/models/modelRefundShippingInfo'
import { ORDER_STATUS_TYPE, RETURN_RESPONSIBILITY_TYPE, RETURN_RESPONSIBILITY_TYPE_TEXT } from '@constants/constDomain'
import { IModelOrderItemDefault } from 'containers/models/base/defaultModelInterfaces'
import { ModelOrderItemDefault } from 'containers/models/base/defaultModels'
import constRoutes from '@constants/constRoutes'
import ModelCouponInfo, { IModelCouponInfo } from 'containers/models/modelCouponInfo'
import { IModelReturnInfo } from 'containers/models/modelReturnInfo'
import { IModelShipping } from 'containers/models/modelShipping'
import { IModelOrder } from 'containers/models/modelOrder'
import { IModelCoupon } from 'containers/models/modelCoupon'
import { IModelRefundInfo } from 'containers/models/modelRefundInfo'
import { encodeId } from '@libs/utils/utilApi'

export const orderStatus2Route = {
  UNCONFIRMED_PAYMENT: constRoutes.ORDERS.ORDER_LIST_UNCONFIRMED_PAYMENT,
  NEW_ORDER: constRoutes.ORDERS.ORDER_LIST_NEW_ORDER,
  READY_FOR_SHIPPING: constRoutes.ORDERS.ORDER_LIST_NEW_ORDER,
  SHIPPING: constRoutes.ORDERS.ORDER_LIST_SHIPPING,
  SHIPPED: constRoutes.ORDERS.ORDER_LIST_SHIPPING,
  CONFIRMED: constRoutes.ORDERS.ORDER_LIST_CONFIRMED,
  CANCEL_REQUESTED: constRoutes.ORDERS.ORDER_LIST_CANCELED,
  CANCELED: constRoutes.ORDERS.ORDER_LIST_CANCELED,
  RETURN_REQUESTED: constRoutes.ORDERS.ORDER_LIST_RETURN,
  RETURN_UNDER_RETURN: constRoutes.ORDERS.ORDER_LIST_RETURN,
  RETURN_RETURNED: constRoutes.ORDERS.ORDER_LIST_RETURN,
  RETURN_CONFIRMED: constRoutes.ORDERS.ORDER_LIST_RETURN,
  EXCHANGE_REQUESTED: constRoutes.ORDERS.ORDER_LIST_EXCHANGE,
  EXCHANGE_UNDER_RETURN: constRoutes.ORDERS.ORDER_LIST_EXCHANGE,
  EXCHANGE_CONFIRMED: constRoutes.ORDERS.ORDER_LIST_EXCHANGE,
}

export interface IModelOrderItem extends IModelOrderItemDefault {
  returnResponsibilityText: RETURN_RESPONSIBILITY_TYPE_TEXT
  routeByOrderStatus: string
  shipping: IModelShipping
  orderStatus: ORDER_STATUS_TYPE
  couponInfoData: IModelCouponInfo
  returnInfo: IModelReturnInfo
  refundInfo: IModelRefundInfo
  refundShippingInfo: IModelRefundShippingInfo
  shippingCompanyId?: string
  shippingNumbers: string[]
  shippingNumbersText: string

  rowSize: number

  makeSameReturnInfoKey: ({ order, orderStatus, returnInfo }: IModelOrderItem) => string
  makeSameRefundShippingInfoKey: ({ order, orderStatus, refundShippingInfo }: IModelOrderItem) => string
  makeSameShippingKey: ({ order, orderStatus, shipping }: IModelOrderItem) => string
  makeSameKeyByMergeType: ({
    mergeType,
    orderItem,
  }: {
    mergeType: 'SAME_ORDER' | 'SAME_RETURN_INFO' | 'SAME_SHIPPING' | 'SAME_REFUND_SHIPPING_INFO'
    orderItem: IModelOrderItem
  }) => string
  order: IModelOrder
  orderItemCoupons: IModelCoupon[]
}

export default class ModelOrderItem extends ModelOrderItemDefault {
  returnResponsibilityText: RETURN_RESPONSIBILITY_TYPE_TEXT = RETURN_RESPONSIBILITY_TYPE_TEXT.ALL
  routeByOrderStatus: string = ''
  couponInfoData: IModelCouponInfo = {} as IModelCouponInfo
  shippingCompanyId?: string = undefined
  shippingNumbers: string[] = []
  shippingNumbersText: string = ''

  rowSize: number = 0

  constructor(data: IModelOrderItem) {
    super(data)

    this.returnResponsibilityText =
      RETURN_RESPONSIBILITY_TYPE_TEXT[this.returnResponsibility as RETURN_RESPONSIBILITY_TYPE]
    this.routeByOrderStatus = orderStatus2Route[this.orderStatus as keyof typeof orderStatus2Route]
    this.couponInfoData = this.couponInfo
      ? new ModelCouponInfo(this.couponInfo, this.couponDiscountPrice)
      : ({} as IModelCouponInfo)
    this.shippingCompanyId = this.getShippingCompanyId()
    this.shippingNumbers = this.getShippingNumbers()
    this.shippingNumbersText = this.getShippingNumbersText()
  }

  private getShippingCompanyId = () => {
    if (this.shipping?.shippingCompanyAndNumbers) {
      const shippingCompanyAndNumbers = JSON.parse(this.shipping.shippingCompanyAndNumbers)
      if (shippingCompanyAndNumbers[this._id]) {
        const { shippingCompanyId } = shippingCompanyAndNumbers[this._id]
        return encodeId('ShippingCompanyType', shippingCompanyId)
      }
    }
    return this.shipping?.shippingCompany?.id
  }

  private getShippingNumbers = () => {
    if (this.shipping?.shippingCompanyAndNumbers) {
      const shippingCompanyAndNumbers = JSON.parse(this.shipping.shippingCompanyAndNumbers)
      if (shippingCompanyAndNumbers[this._id]) {
        const { shippingNumbers } = shippingCompanyAndNumbers[this._id]
        return shippingNumbers || []
      }
    }
    return []
  }

  private getShippingNumbersText = () => {
    return this.shippingNumbers.join(', ')
  }

  public makeSameReturnInfoKey = ({ order, orderStatus, returnInfo }: IModelOrderItem) => {
    return JSON.stringify([order.id, orderStatus, returnInfo.id])
  }

  public makeSameRefundShippingInfoKey = ({ order, orderStatus, refundShippingInfo }: IModelOrderItem) => {
    return JSON.stringify([order.id, orderStatus, refundShippingInfo.id])
  }

  public makeSameShippingKey = ({ order, orderStatus, shipping }: IModelOrderItem) => {
    return JSON.stringify([order.id, orderStatus, shipping.id])
  }

  public makeSameKeyByMergeType = ({
    mergeType,
    orderItem,
  }: {
    mergeType: 'SAME_ORDER' | 'SAME_RETURN_INFO' | 'SAME_SHIPPING' | 'SAME_REFUND_SHIPPING_INFO'
    orderItem: IModelOrderItem
  }) => {
    if (mergeType === 'SAME_ORDER') return orderItem.order.id
    if (mergeType === 'SAME_REFUND_SHIPPING_INFO') return this.makeSameRefundShippingInfoKey(orderItem)
    if (mergeType === 'SAME_RETURN_INFO') return this.makeSameReturnInfoKey(orderItem)
    if (mergeType === 'SAME_SHIPPING') return this.makeSameShippingKey(orderItem)
  }
}
