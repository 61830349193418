import { ModelFaqDefault } from 'containers/models/base/defaultModels'
import { IModelFaqDefault } from 'containers/models/base/defaultModelInterfaces'

export interface IModelFaq extends IModelFaqDefault {}

export default class ModelFaq extends ModelFaqDefault {
  constructor(data: IModelFaq) {
    super(data)
  }
}
