import { FC, MutableRefObject } from 'react'
import { message } from 'antd'

import { MButton } from 'components/@atomics'
import { IModelNotice } from 'containers/models/modelNotice'
import { ModalConfirmTable } from 'domains/common/modals'
import useModal from 'containers/hooks/useModal'

export type ButtonDeleteNoticeProps = {
  noticeData?: IModelNotice
  noticeDataRef?: MutableRefObject<IModelNotice[]>
  refetchRef: MutableRefObject<Function>
  title: '선택 삭제' | '삭제'
  hideForm?: () => void
}

const ButtonDeleteNotice: FC<ButtonDeleteNoticeProps> = ({
  noticeData,
  noticeDataRef,
  refetchRef,
  title,
  hideForm,
}) => {
  const { useModalProps } = useModal()

  const getCurrentData = (): IModelNotice[] => {
    if (noticeDataRef) return noticeDataRef.current
    if (noticeData) return [noticeData]
    return []
  }

  return (
    <>
      <MButton
        onClick={() => {
          if (noticeDataRef?.current.length || noticeData) {
            useModalProps.toggleModal()
            useModalProps.setData({ notices: getCurrentData() })
          } else {
            message.warn('선택된 공지사항이 없습니다.')
          }
        }}
        width={title === '삭제' ? 80 : undefined}
      >
        {title}
      </MButton>
      <ModalConfirmTable
        useModalProps={useModalProps}
        fixedProps={{
          mutationKey: {
            gqlKey: 'deleteNotices',
            variableKey: 'noticeDeleteRequests',
          },
          title: '공지사항 삭제',
          description: '해당 공지사항을 삭제합니다. 삭제된 공지사항은 복구할 수 없습니다.',
          componentOption: { tableComponentKey: 'tableNoticeDefault' },
        }}
        onAPISuccess={() => {
          if (refetchRef.current) {
            if (hideForm) hideForm()
            message.warn('공지사항이 삭제되었습니다.')
            refetchRef.current()
          }
        }}
      />
    </>
  )
}

export default ButtonDeleteNotice
