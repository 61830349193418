import { ModelReviewDefault } from 'containers/models/base/defaultModels'
import { IModelReviewDefault } from 'containers/models/base/defaultModelInterfaces'
import { IModelMileageHistory } from 'containers/models/modelMileageHistory'
import { IModelReviewImage } from 'containers/models/modelReviewImage'

export interface IModelReview extends IModelReviewDefault {
  reviewMileageHistories: IModelMileageHistory[]
  reviewReviewImages: IModelReviewImage[]
}

export default class ModelReview extends ModelReviewDefault {
  constructor(data: IModelReview) {
    super(data)
  }
}
