import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Link from 'next/link'
import { Router, withRouter } from 'next/router'
import styled from 'styled-components'
import { Breadcrumb, Col, Row } from 'antd'
import { HomeOutlined } from '@ant-design/icons'

import ROUTES from '@constants/constRoutes'
import { RootState } from '@libs/redux/store'
import { deepcopy } from '@libs/utils/utilData'
import { doChangePageListData, listTypes } from '@libs/redux/reduxUI'
import { Text14A45 } from 'components/@atomics/texts/TextBase'
import { themes } from '@constants'
import { useModal } from '@reboot/hooks/useModal'
import HelperModal from '@reboot/components/modals/HelperModal'
import { Fragment } from 'react'

const routeNames = {
  [ROUTES.HOME]: '홈',

  // 1st depth
  [ROUTES.ACCOUNTS.BASE]: '회원 관리',
  [ROUTES.BILLINGS.BASE]: '정산 관리',
  [ROUTES.BOARDS.BASE]: '문의/리뷰 관리',
  [ROUTES.CONFIGURATIONS.BASE]: '환경설정',
  [ROUTES.DASHBOARD]: '대쉬보드',
  [ROUTES.ETC.BASE]: '기타',
  [ROUTES.LAYOUTS.BASE]: '홈 관리',
  [ROUTES.NOTICES.BASE]: '게시판 관리',
  [ROUTES.ORDERS.BASE]: '주문 관리',
  [ROUTES.PARTNERS.BASE]: '파트너 관리',
  [ROUTES.PRODUCTS.BASE]: '상품 관리',
  [ROUTES.PROMOTIONS.BASE]: '프로모션',
  [ROUTES.STATISTICS.BASE]: '통계',
  [ROUTES.MEMBERSHIP.BASE]: '멤버십 관리',

  // 2nd depth
  [ROUTES.ACCOUNTS.CUSTOMER_GRADE]: '회원 등급 관리',
  [ROUTES.ACCOUNTS.CUSTOMER_GRADE_ADD]: '회원 등급 등록',
  [ROUTES.ACCOUNTS.CUSTOMER_GRADE_EDIT]: '회원 등급 수정 및 상세',
  [ROUTES.ACCOUNTS.EMAIL]: '메일 발송',
  [ROUTES.ACCOUNTS.INACTIVATED_USER_LIST]: '휴면 회원 리스트',
  [ROUTES.ACCOUNTS.LEFT_USER_LIST]: '탈퇴 회원 리스트',
  [ROUTES.ACCOUNTS.PUSH_DETAIL]: '발송내역 상세페이지',
  [ROUTES.ETC.EXCEL_REQUEST_ORDER]: '재무용 주문내역 다운로드',
  [ROUTES.ACCOUNTS.SMS]: 'SMS 발송',
  [ROUTES.ACCOUNTS.USER_ADD]: '회원 등록',
  [ROUTES.ACCOUNTS.USER_DETAIL]: '회원 정보 상세',
  [ROUTES.ACCOUNTS.USER_LIST]: '회원 리스트',
  [ROUTES.MEMBERSHIP.MEMBERSHIP_USER_LIST]: '회원 리스트',
  [ROUTES.BILLINGS.BILLING_LIST]: '정산 리스트',
  [ROUTES.BILLINGS.SELLER_BILLING_LIST]: '셀러 정산 리스트',
  [ROUTES.BOARDS.BOARD_LIST_REVIEW]: '리뷰 리스트',
  [ROUTES.CONFIGURATIONS.BRAND_INFORMATION]: '브랜드정보',
  [ROUTES.CONFIGURATIONS.BRAND_INFORMATION_ADD]: '브랜드 등록',
  [ROUTES.CONFIGURATIONS.BRAND_INFORMATION_EDIT]: '브랜드정보 수정',
  [ROUTES.CONFIGURATIONS.SELLER_INFORMATION]: '셀러 정보',
  [ROUTES.CONFIGURATIONS.SELLER_INFORMATION_CHANGE]: '셀러정보 변경신청',
  [ROUTES.CONFIGURATIONS.SELLER_NOTICE]: '셀러 공지사항',
  [ROUTES.CONFIGURATIONS.SELLER_SHOP_INFORMATION]: '셀러샵정보',
  [ROUTES.ETC.EXCEL_REQUEST_LIST]: '엑셀 다운로드',
  [ROUTES.ETC.MANAGE_SEARCH_TOKEN]: '검색 사전 관리',
  [ROUTES.ETC.REMOTE_AREA_LIST]: '도서 산간 배송비',
  [ROUTES.ETC.SKU_STOCK_DETAIL]: 'SKU 재고 상세',
  [ROUTES.ETC.SKU_STOCK_LIST]: 'SKU 재고 관리',
  [ROUTES.LAYOUTS.EVENT_ADD]: '이벤트 등록',
  [ROUTES.LAYOUTS.EVENT_DETAIL]: '이벤트 상세 및 수정',
  [ROUTES.LAYOUTS.EVENT_LIST]: '이벤트 관리',
  [ROUTES.LAYOUTS.LAYOUT_BRAND_LIST]: '브랜드 관리',
  [ROUTES.LAYOUTS.LAYOUT_BANNER_DETAIL]: '배너 상세',
  [ROUTES.LAYOUTS.LAYOUT_BANNER_LIST]: '배너 리스트',
  [ROUTES.LAYOUTS.LAYOUT_CATEGORY_EXPOSE_LIST]: '카테고리 노출 관리',
  [ROUTES.LAYOUTS.LAYOUT_DISPLAY_COLLECTION_ADD]: '섹션 추가',
  [ROUTES.LAYOUTS.LAYOUT_DISPLAY_COLLECTION_DETAIL]: '섹션 상세',
  [ROUTES.LAYOUTS.LAYOUT_DISPLAY_COLLECTION_LIST]: '섹션 관리',
  [ROUTES.LAYOUTS.LAYOUT_TODAY_SPECIAL_PRICE_LIST]: 'Today 특가 관리',
  [ROUTES.NOTICES.NOTICE_LIST_SELLER]: '셀러 공지사항 관리',
  [ROUTES.ORDERS.ORDER_DETAIL]: '주문 상세',
  [ROUTES.ORDERS.ORDER_LIST_ALL]: '전체 주문 조회',
  [ROUTES.ORDERS.ORDER_LIST_CANCELED]: '취소 관리',
  [ROUTES.ORDERS.ORDER_LIST_CONFIRMED]: '구매확정 내역',
  [ROUTES.ORDERS.ORDER_LIST_EXCHANGE]: '교환 관리',
  [ROUTES.ORDERS.ORDER_LIST_NEW_ORDER]: '신규 주문 / 출고 관리',
  [ROUTES.ORDERS.ORDER_LIST_RETURN]: '반품 관리',
  [ROUTES.ORDERS.ORDER_LIST_SHIPPING]: '배송현황 관리',
  [ROUTES.ORDERS.ORDER_LIST_UNCONFIRMED_PAYMENT]: '미결제 확인',
  [ROUTES.PARTNERS.BRAND_ADD]: '브랜드 등록',
  [ROUTES.PARTNERS.BRAND_DETAIL]: '브랜드 상세',
  [ROUTES.PARTNERS.BRAND_LIST]: '브랜드 정보 관리',
  [ROUTES.PARTNERS.MY_SELLER_LIST]: '내 셀러 리스트',
  [ROUTES.PARTNERS.MY_SELLER_LIST_DETAIL]: '셀러 상세',
  [ROUTES.PARTNERS.PARTNER_DETAIL]: '파트너 정보 상세',
  [ROUTES.PARTNERS.PARTNER_LIST]: '전체 사용자 리스트',
  [ROUTES.PARTNERS.SELLER_ADD]: '셀러 등록',
  [ROUTES.PRODUCTS.SELLER_CATEGORY]: '카테고리 보기',
  [ROUTES.PARTNERS.SELLER_DETAIL]: '셀러 정보 상세',
  [ROUTES.PARTNERS.SELLER_DETAIL_CHANGE]: '셀러 정보 변경 신청',
  [ROUTES.PARTNERS.SELLER_LIST]: '셀러 리스트',
  [ROUTES.PARTNERS.SELLER_REQUEST_DETAIL]: '셀러 정보 상세',
  [ROUTES.PARTNERS.SELLER_REQUEST_LIST]: '셀러 신청 리스트',
  [ROUTES.PARTNERS.SELLER_REQUEST_LIST]: '셀러 신청 리스트',
  [ROUTES.PARTNERS.SELLER_SHIPPING_PRESET]: '도서 산간 지역 추가 배송비 관리',
  [ROUTES.PRODUCTS.CATEGORY]: '카테고리 관리',
  [ROUTES.PRODUCTS.PRODUCT_ADD]: '상품 등록',
  [ROUTES.PRODUCTS.PRODUCT_DETAIL]: '상품 상세',
  [ROUTES.PRODUCTS.PRODUCT_EDIT]: '상품 상세 및 수정',
  [ROUTES.PRODUCTS.PRODUCT_EXCEL_UPLOAD]: '엑셀 업로드/임시 등록 관리',
  [ROUTES.PRODUCTS.PRODUCT_INFO_CHANGE]: '상품 정보 변경 신청 상세',
  [ROUTES.PRODUCTS.PRODUCT_LIST]: '상품 리스트',
  [ROUTES.PRODUCTS.PRODUCT_LIST_DELETE]: '삭제 상품 리스트',
  [ROUTES.PRODUCTS.PRODUCT_LIST_REQUEST]: '상품 신청 리스트',
  [ROUTES.PROMOTIONS.COUPON_ADD]: '쿠폰 등록',
  [ROUTES.PROMOTIONS.COUPON_DETAIL]: '쿠폰 상세',
  [ROUTES.PROMOTIONS.COUPON_ISSUE]: '쿠폰 수동 발급하기',
  [ROUTES.PROMOTIONS.COUPON_LIST]: '쿠폰 리스트',
  [ROUTES.PROMOTIONS.COUPON_LIST_ISSUED]: '쿠폰 발급 리스트',
  [ROUTES.PROMOTIONS.MILEAGE_CONFIG]: '적립금 설정 관리',
  [ROUTES.PROMOTIONS.MILEAGE_HISTORY_LIST]: '적립금 내역 관리',
  [ROUTES.STATISTICS.ANALYSIS_PRODUCT]: '상품 분석',
  [ROUTES.STATISTICS.ANALYSIS_PRODUCT_BY_SELLER]: '상품 분석',
  [ROUTES.STATISTICS.ANALYSIS_SELLER]: '셀러 분석',
  [ROUTES.STATISTICS.DAILY_REPORT]: '일간 리포트',
  [ROUTES.STATISTICS.MONTHLY_REPORT]: '월간 리포트',
  [ROUTES.STATISTICS.WEEKLY_REPORT]: '주간 리포트',
}

const getRouteBreadcrumb = (depth: number, url: string, router: Router) => {
  const dispatch = useDispatch()
  const href = { pathname: url, query: {} }
  const link = url

  if (url.includes('[id]')) {
    _.set(href.query, 'id', router.query.id as string)
  }

  const handleClick = () => {
    if ([ROUTES.PRODUCTS.PRODUCT_LIST, ROUTES.PARTNERS.SELLER_LIST, ROUTES.PARTNERS.MY_SELLER_LIST].includes(url)) {
      dispatch(doChangePageListData({ listType: listTypes.DEFAULT }))
    }
  }

  return (
    <Breadcrumb.Item key={url}>
      {depth === 0 ? (
        <Text14A45>{routeNames[link]}</Text14A45>
      ) : (
        <Link href={href}>
          <a tabIndex={0} role="button" onClick={() => handleClick()} onKeyDown={() => {}}>
            {routeNames[link]}
          </a>
        </Link>
      )}
    </Breadcrumb.Item>
  )
}

const getExtraBreadcrumbs = (url: string, routeInfo: any) => {
  let text
  if ([ROUTES.PRODUCTS.PRODUCT_LIST, ROUTES.PARTNERS.SELLER_LIST, ROUTES.PARTNERS.MY_SELLER_LIST].includes(url)) {
    const { listType, title } = routeInfo.listData
    if (listType === listTypes.BY_TITLE) {
      text = title
    } else if (listType === listTypes.BY_SEARCH) {
      text = '검색 결과'
    }
  }
  if (text) {
    return (
      <Breadcrumb.Item key={url}>
        <Link href={url}>
          <a>{text}</a>
        </Link>
      </Breadcrumb.Item>
    )
  }
  return null
}

const MBreadcrumb = (props: { router: Router }) => {
  const { router } = props
  const listData = useSelector((state: RootState) => state.reduxUIReducers.listData)
  const routeInfo = { listData }
  const strUrl = router.pathname.toString()
  let pathSnippets = strUrl.split('/').filter((i: any) => i)

  let hasSuffixWithId = false
  pathSnippets = pathSnippets.filter((_v: any, index: number) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    if (url.endsWith('[id]') && index < pathSnippets.length - 1) {
      // [id]/add 등 뒤에 url이 더 붙을 경우 [id] breadcrumb는 스킵
      // 현재 구조는 [id]의 반복이 1회보다 많이 없다고 가정함
      hasSuffixWithId = true
      return false
    }
    return true
  })

  const homeBreadcrumb = (
    <Breadcrumb.Item key="home">
      <HomeOutlined />
      &nbsp;
      <Link href={ROUTES.HOME}>
        <a>{routeNames[ROUTES.HOME]}</a>
      </Link>
    </Breadcrumb.Item>
  )

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const routeBreadcrumbs = pathSnippets.map((_v: any, index: number) => {
    const hasIdBefore = pathSnippets.length - 1 === index && hasSuffixWithId
    const _snippets = deepcopy(pathSnippets)
    if (hasIdBefore) {
      _snippets.splice(pathSnippets.length - 1, 0, '[id]')
    }
    const indexAdd = hasIdBefore ? 2 : 1
    const url = `/${_snippets.slice(0, index + indexAdd).join('/')}`
    return getRouteBreadcrumb(index, url, router)
  })

  const breadcrumbs = [homeBreadcrumb].concat(routeBreadcrumbs)

  const helpModalProps = useModal()

  const extraBreadcrumbs = getExtraBreadcrumbs(strUrl, routeInfo)
  if (extraBreadcrumbs) breadcrumbs.push(extraBreadcrumbs)

  return (
    <>
      <StyledRow>
        <StyledCol>
          <StyledBreadcrumb>{breadcrumbs}</StyledBreadcrumb>
          {/* TODO: 도움말이 표기될 페이지가 확정되면 컴포넌트로 분리하여 관리 */}
          {router.pathname === ROUTES.BILLINGS.BILLING_LIST && (
            <Fragment>
              <div>
                <HelpTextWrapper onClick={() => helpModalProps.showModal()}>
                  <HelpText>도움말 보기</HelpText>
                </HelpTextWrapper>
              </div>
              {helpModalProps.modalShowing && <HelperModal modalProps={helpModalProps} />}
            </Fragment>
          )}
        </StyledCol>
      </StyledRow>
    </>
  )
}

const StyledBreadcrumb = styled(Breadcrumb)`
  line-height: inherit;
`

const StyledRow = styled(Row)`
  margin-top: ${(props) => props.theme.header.height}px;
  background-color: #ffffff;
`

const StyledCol = styled(Col)`
  position: relative;
  height: ${(props) => props.theme.header.height - 10}px;
  line-height: ${(props) => props.theme.header.height - 10}px;
  padding: 0 ${(props) => props.theme.margins.contentHorizontal};
`

const HelpTextWrapper = styled.div`
  position: absolute;
  top: 1px;
  bottom: 0;
  right: -33px;
  cursor: pointer;
`

const HelpText = styled.p`
  color: ${themes.colors.blue};
  font-size: 11px;
`

export default withRouter(MBreadcrumb)
