import { gql } from '@apollo/client'
import DEFAULT_FRAGMENTS from 'containers/gqls/base/defaultFragments'

export const INFORM_MUTATION_GQLS = {
  SEND_EMAIL: gql`
    mutation sendEmail($input: SendEmailInput!) {
      sendEmail(input: $input) {
        informEmailQueue {
          ...informEmailQueue
        }
      }
    }
    ${DEFAULT_FRAGMENTS.informEmailQueue}
  `,
  SEND_SMS: gql`
    mutation sendSms($input: SendSmsInput!) {
      sendSms(input: $input) {
        informSmsQueue {
          ...informSmsQueue
        }
      }
    }
    ${DEFAULT_FRAGMENTS.informSmsQueue}
  `,
}

export default INFORM_MUTATION_GQLS
