import { FC } from 'react'
import { Cascader } from 'antd'
import { MFormItemWrapper } from 'components/formItems'
import { MFormItemWrapperProps } from 'components/formItems/MFormItemWrapper'
import { DataNode } from 'antd/lib/tree'

export type MFormItemCascaderProps = {
  onChange?: (value: any) => void
  placeholder?: string
  options: DataNode[]
  disabled?: boolean
  changeOnSelect?: boolean
} & MFormItemWrapperProps

const MFormItemCascader: FC<MFormItemCascaderProps> = ({
  options,
  placeholder,
  disabled = false,
  changeOnSelect = false,
  ...formItemWrapper
}) => {
  return (
    <MFormItemWrapper {...formItemWrapper}>
      <Cascader options={options} placeholder={placeholder} disabled={disabled} changeOnSelect={changeOnSelect} />
    </MFormItemWrapper>
  )
}

export default MFormItemCascader
