import { MutationFunction, useLazyQuery } from '@apollo/client'
import { FIELD_SORT_KEYS, FIELD_VALUES } from '@constants/constData'
import { DISPLAY_COLLECTION_TYPE, DISPLAY_STATUS } from '@constants/constDomain'
import * as utilApi from '@libs/utils/utilApi'
import * as utilData from '@libs/utils/utilData'
import { message } from 'antd'
import { MConfirmModal, MListSortSelect, MMainTable } from 'components'
import { MButton, MTextButton, Text14A45, Text14Normal } from 'components/@atomics'
import { ITableColumn } from 'components/@atomics/MTable'
import MMutation from 'components/MMutation'
import DISPLAY_COLLECTION_QUERY_GQLS from 'containers/gqls/layouts/displayCollection/queries'
import useModal from 'containers/hooks/useModal'
import { IModelAd } from 'containers/models/modelAd'
import { IModelDisplayCollection } from 'containers/models/modelDisplayCollection'
import tableCols from 'domains/admin/tableCols'
import React, { Dispatch, MutableRefObject, ReactElement, SetStateAction, useRef } from 'react'
import { DataResult, DataResults } from 'containers/gqls/data'
import { IModelDisplayProductInfo } from 'containers/models/modelDisplayProductInfo'

const tableColumns = (
  refetchRef: MutableRefObject<Function | null>,
  displayCollectionQuery: Function
): Array<ITableColumn<any>> => {
  return [
    tableCols.common.ID(),
    // TODO yoon: series 수정 방식이 drag & drop 방식에서 input으로 바뀜. 다시 drag & drop 변경되면 아래 주석 제거
    // tableCols.common.sort({ width: 100, dataIndex: 'series', key: 'series' }),
    tableCols.common.editableSort({ refetchRef }),
    {
      title: FIELD_VALUES.FIELD_DISPLAY_COLLECTION_NAME,
      fixed: 'left',
      render: (record: IModelDisplayCollection) => {
        return (
          <MTextButton
            onClick={() => {
              displayCollectionQuery({ variables: { id: record.id } })
            }}
          >
            {record.name}
          </MTextButton>
        )
      },
    },
    tableCols.common.petTypeText({ dataIndex: 'petType' }),
    tableCols.common.baseText({ title: '섹션 유형', width: 150, dataIndex: 'collectionTypeText' }),
    {
      title: FIELD_VALUES.FIELD_DATE_STATUS,
      width: 100,
      render: (record: IModelDisplayCollection) => {
        if (record.displayStatus === DISPLAY_STATUS.DISPLAY) {
          return <Text14Normal>{record.displayStatusText}</Text14Normal>
        }
        return <Text14A45>{record.displayStatusText}</Text14A45>
      },
    },
    tableCols.common.baseText({ title: FIELD_VALUES.FIELD_DISPLAY_PERIOD, width: 240, dataIndex: 'displayPeriodText' }),
    tableCols.common.baseText({ title: '등록일', width: 140, dataIndex: 'createdAtText' }),
    tableCols.common.baseText({ title: '수정일', width: 140, dataIndex: 'updatedAtText' }),
    {
      title: '삭제',
      width: 100,
      render: (record: IModelDisplayCollection) => {
        const { useModalProps } = useModal()
        return (
          <MMutation
            gqlKey="deleteDisplayCollection"
            onAPISuccess={() => {
              if (refetchRef.current) {
                refetchRef.current()
              }
            }}
          >
            {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
              return (
                <>
                  <MTextButton onClick={() => useModalProps.toggleModal()}>[삭제]</MTextButton>
                  {/* TODO yoon: ModalConfirm 으로 변경 필요 */}
                  <MConfirmModal
                    useModalProps={useModalProps}
                    fixedProps={{
                      title: '섹션 삭제',
                      description: `${record.name} 을(를) 삭제하시겠습니까?`,
                    }}
                    onAction={async () => {
                      await mutation({ variables: { id: record.id } })
                    }}
                    loading={loading}
                  />
                </>
              )
            }}
          </MMutation>
        )
      },
    },
  ]
}

const adTableColumns = (
  refetchRef: MutableRefObject<Function | null>,
  displayCollectionQuery: Function,
  selectedDisplayCollectionRef: MutableRefObject<IModelDisplayCollection | undefined>,
  setSelectedDisplayCollection: Dispatch<SetStateAction<IModelDisplayCollection | undefined>>
): Array<ITableColumn<any>> => {
  return [
    tableCols.common.ID(),
    // TODO yoon: series 수정 방식이 drag & drop 방식에서 input으로 바뀜. 다시 drag & drop 변경되면 아래 주석 제거
    // tableCols.common.sort({ width: 100, dataIndex: 'series', key: 'series' }),
    tableCols.common.editableSort({ refetchRef }),
    {
      title: '그룹명',
      render: (record: IModelDisplayCollection) => {
        return (
          <MTextButton
            onClick={() => {
              displayCollectionQuery({ variables: { id: record.id } })
            }}
          >
            {record.name}
          </MTextButton>
        )
      },
    },
    {
      title: '상품수',
      align: 'right',
      render: (record: IModelDisplayCollection) => {
        return <Text14Normal>{utilData.numberCountText(record.displayProductInfos.length)}</Text14Normal>
      },
    },
    {
      title: '삭제',
      width: 100,
      align: 'center',
      render: (record: IModelDisplayCollection) => {
        const { useModalProps } = useModal()
        return (
          <MMutation
            gqlKey="deleteDisplayCollection"
            onAPISuccess={() => {
              // 선택한 displayCollection을 삭제한 경우, 해당 정보도 삭제
              if (selectedDisplayCollectionRef.current?.id === record.id) {
                selectedDisplayCollectionRef.current = undefined
                setSelectedDisplayCollection(undefined)
              }

              if (refetchRef.current) {
                refetchRef.current()
              }

              message.success('섹션이 삭제되었습니다.')
            }}
          >
            {(mutation: MutationFunction, { loading }: { loading: boolean }) => {
              return (
                <>
                  <MTextButton onClick={() => useModalProps.toggleModal()}>[삭제]</MTextButton>
                  {/* TODO yoon: ModalConfirm 으로 변경 필요 */}
                  <MConfirmModal
                    useModalProps={useModalProps}
                    fixedProps={{
                      title: '상품 그룹 삭제',
                      description: `${record.name} 섹션을 삭제하시겠습니까?`,
                    }}
                    onAction={async () => {
                      await mutation({ variables: { id: record.id } })
                    }}
                    loading={loading}
                  />
                </>
              )
            }}
          </MMutation>
        )
      },
    },
  ]
}

const headerLeftButtons = (
  setSelectedDisplayCollection: Dispatch<SetStateAction<IModelDisplayCollection | undefined>>,
  adData?: IModelAd
): ReactElement<any>[] => {
  const isAdDisplayCollection = !!adData
  const defaultLeftButtons = [
    <MButton
      type="primary"
      onClick={() => {
        if (isAdDisplayCollection) {
          setSelectedDisplayCollection({
            name: undefined,
            collectionType: DISPLAY_COLLECTION_TYPE.AD,
            ad: {
              id: adData?.id,
              startedAt: adData?.startedAt,
              endedAt: adData?.endedAt,
            },
          } as unknown as IModelDisplayCollection)
        } else {
          // @ts-ignore - FormDisplayCollection에서 DisplayCollection 생성 여부를 판단하는 기준
          setSelectedDisplayCollection({ name: undefined })
        }
      }}
    >
      섹션 추가
    </MButton>,
  ]

  if (!isAdDisplayCollection) {
    defaultLeftButtons.push(
      <MMutation
        gqlKey="clearBestCache"
        onAPISuccess={(data) => {
          if (data) {
            message.success('섹션 리프레시가 완료되었습니다.')
          }
        }}
      >
        {(mutation: MutationFunction, { loading }: { loading: boolean }) => (
          <MButton
            type="primary"
            onClick={() => {
              mutation({ variables: { id: 'test' } })
            }}
            loading={loading}
          >
            상품 그룹 리프레시
          </MButton>
        )}
      </MMutation>
    )
  }

  return defaultLeftButtons
}

/*
  이벤트 관리 페이지와 섹션 관리 페이지에서 쓰고 있음
  이벤트 관리에서 사용하는 경우, adData, refetchRef, setSelectedDisplayCollection 필수 값입니다.
 */
const MainTableDisplayCollection = ({
  adData,
  setSelectedDisplayCollection,
  getDisplayCollectionRefetch,
  getDisplayCollectionsRefetchRef,
}: {
  adData?: IModelAd
  setSelectedDisplayCollection: Dispatch<SetStateAction<IModelDisplayCollection | undefined>>
  getDisplayCollectionRefetch: (refetch: Function) => void
  getDisplayCollectionsRefetchRef: (refetchRef?: MutableRefObject<Function | null>) => void
}) => {
  const isAdDisplayCollection = !!adData
  const refetchRef = useRef<Function | null>(null)
  const dataRef = useRef<{ [index: string]: DataResults | DataResult } | null>(null)
  const selectedDisplayCollectionRef = useRef<IModelDisplayCollection | undefined>(undefined)
  const [displayCollectionQuery, { refetch }] = useLazyQuery(DISPLAY_COLLECTION_QUERY_GQLS.DISPLAY_COLLECTION, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      // @ts-ignore
      const { displayCollection }: { data: IModelDisplayCollection } = utilApi.getResultFromData(data)
      if (displayCollection) {
        const _displayProductInfos =
          displayCollection?.data?.displayProductInfos?.filter(
            (item: IModelDisplayProductInfo) => !item?.productpromotion?.isDelete
          ) || []
        const _data = { ...displayCollection?.data, displayProductInfos: _displayProductInfos }
        setSelectedDisplayCollection(_data)
        selectedDisplayCollectionRef.current = _data
      }
    },
  })

  if (getDisplayCollectionsRefetchRef) {
    if (refetchRef.current) {
      getDisplayCollectionsRefetchRef(refetchRef)
    }
  }

  if (refetch) {
    getDisplayCollectionRefetch(refetch)
  }

  return (
    <MMainTable
      queryParams={{
        gqlKey: 'displayCollections',
        variables: {
          filter: {
            ad: isAdDisplayCollection ? adData?.id : undefined,
            collectionType: isAdDisplayCollection
              ? [DISPLAY_COLLECTION_TYPE.AD]
              : [
                  DISPLAY_COLLECTION_TYPE.BEST,
                  DISPLAY_COLLECTION_TYPE.NORMAL,
                  DISPLAY_COLLECTION_TYPE.NEW_ARRIVALS,
                  DISPLAY_COLLECTION_TYPE.TODAY_SPECIAL_PRICE,
                  DISPLAY_COLLECTION_TYPE.BEST_REVIEW,
                  DISPLAY_COLLECTION_TYPE.MAGAZINE_BACKGROUND_IMAGE,
                  DISPLAY_COLLECTION_TYPE.MAGAZINE_IMAGE,
                ],
          },
          orderBy: FIELD_SORT_KEYS.FIELD_SORT_CREATED_AT_DESC,
        },
      }}
      TitleSet={{ title: '섹션 목록', unit: '건' }}
      scroll={isAdDisplayCollection ? undefined : 1300}
      dataRef={dataRef}
      refetchRef={refetchRef}
      HeaderLeftButtons={headerLeftButtons(setSelectedDisplayCollection, adData)}
      HeaderRightButtons={[
        <MListSortSelect
          filterOptions={[
            'FIELD_SORT_CREATED_AT_DESC',
            'FIELD_SORT_CREATED_AT',
            'FIELD_SORT_DISPLAY_ORDER',
            'FIELD_SORT_DISPLAY_ORDER_DESC',
          ]}
        />,
      ]}
      columns={
        isAdDisplayCollection
          ? adTableColumns(
              refetchRef,
              displayCollectionQuery,
              selectedDisplayCollectionRef,
              setSelectedDisplayCollection
            )
          : tableColumns(refetchRef, displayCollectionQuery)
      }
      onTableDataLoaded={(data) => {
        if (isAdDisplayCollection) {
          /**
           FormDisplayCollection 에서 displayCollection 새로 생성한 경우,
           새로운 displayCollection list data의 첫 번째 데이터를 새로 생성된 displayCollection 이라 보고,
           FormDisplayCollection과 TableDisplayProductInfos에서 사용할 displayCollectionRefetchRef를 위해 displayCollectionQuery를 실행
          */
          if (dataRef && dataRef.current) {
            const newData = data.displayCollections.data
            // @ts-ignore mainTableDataRef.current는 displayCollections를 가지고 있음
            const oldData = dataRef.current!.displayCollections.data
            if (newData.length > oldData.length) {
              displayCollectionQuery({ variables: { id: newData[0].id } })
            }
          }
        }
      }}
      // TODO yoon: series 수정 방식이 drag & drop 방식에서 input으로 바뀜. 다시 drag & drop 변경되면 아래 주석 제거
      // onDragRowAction={(movedItem: IModelDisplayProductInfo, oldItem: IModelDisplayProductInfo) => {
      //   // @ts-ignore
      //   moveDisplayCollectionMutation({
      //     variables: { id: movedItem.id, input: { to: oldItem.series } },
      //   }).then(() => {
      //     if (refetchRef.current) {
      //       refetchRef.current()
      //     }
      //   })
      // }}
    />
  )
}

export default MainTableDisplayCollection
