import { FunctionComponent } from 'react'
import { FormItemProps } from 'antd/lib/form'

import { MFormItemWrapper, IFilterOptions, MFormItemLabelProps } from 'components/formItems'
import { MSelect } from 'components/@atomics'
import { SelectCustomProps } from 'components/@atomics/MSelect'

export type MFormItemSelectProps = SelectCustomProps &
  MFormItemLabelProps & {
    selectOptions?: IFilterOptions
    placeholder?: string
    formItemProps?: FormItemProps
    disabled?: boolean
  }

const MFormItemSelectKeyword: FunctionComponent<MFormItemSelectProps> = ({
  label,
  name,
  selectOptions,
  selectWidth,
  required,
  placeholder,
  onChange,
  disabled,
  showSearch,
  rules,
  defaultValue,
  ...formItemProps
}) => {
  return (
    <MFormItemWrapper label={label} name={name} required={required} rules={rules} {...formItemProps}>
      <MSelect
        mode="tags"
        // tokenSeparators={[',']}
        filterOptions={selectOptions}
        selectWidth={selectWidth}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        showSearch={showSearch}
        defaultValue={defaultValue}
      />
      <span>상품과 연관된 키워드를 입력 후 선택해주세요.</span>
    </MFormItemWrapper>
  )
}

export default MFormItemSelectKeyword
